import React from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link } from "react-router-dom";
import $ from "jquery";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminDiscountAndTips() {


  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  
  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
               
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="row g-5 g-xl-8 justify-content-center">
                  <h1 className="my-3">Discount & Tips Management</h1>
                    <div className="col-sm-4">
                      <Link
                        to="/group_admin/settings/discount_and_tips_management/reasons_management"
                        className="card bg-white hoverable mb-xl-8"
                        style={{
                          boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                        }}
                      >
                        <div className="card-body p-2">
                          <center>
                            <div className="my-4">
                              <i className="fa-light fa-badge-percent text-dark fs-4x"></i>
                            </div>
                            <div className="mb-2">
                              <h2 className="fw-normal">Discount</h2>
                            </div>
                          </center>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4">
                      <Link
                        // to="/group_admin/settings/voucher_management/voucher_list"
                        className="card bg-white hoverable mb-xl-8"
                        style={{
                          boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                        }}
                      >
                        <div className="card-body p-2">
                          <center>
                            <div className="my-3">
                              {/* <i className="fa-regular fa-gift-card text-dark fs-5x"></i> */}
                              <img style={{height : 60}} src="/assets/media/avatars/Person Money.png"  alt="" />
                            </div>
                            <div className="mb-2">
                              <h2 className="fw-normal">Tips</h2>
                            </div>
                          </center>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

