import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import CommonVoucher from "./CommonVoucher";
import useDrawer from "../../../../context/DrawerContext";
import useError from "../../../Comman/Error/useError";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { SubmitformButton } from "../../../Comman/Error/SubmitformButton";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { getCurrencyLabel } from "../../../Comman/currencyUtils/currencyUtils";
import { connect } from "react-redux"
import { getBranchList } from "../../../../Store";
import NoteState from "../../Component/Setting/CommanBranch/NoteState";
window.jquery = window.$ = $;

function GroupAdminVoucherSetting(props) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  let history = useHistory();

  const { error, showError, clearError } = useError();
  useEffect(() => {
    getBranchList();
  }, []);

  const { branchID, branchData } = props;

  const currencyLabel = getCurrencyLabel(branchData && branchData?.currency);

 
  const [min_voucher_amount, setmin_voucher_amount] = useState("");

  useEffect(() => {
    voucherSettingGet();
  }, []);

  function voucherSettingGet() {
    api.get(`/groupAdmin/voucher_settings`, { headers }).then((res) => {
      const tableData = res?.data?.data?.min_voucher_amount;
      setmin_voucher_amount(Number(tableData) > 0 ? Number(tableData) : "")
    });
  }

  console.log("min_voucher_amount" ,min_voucher_amount)


  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);

    if (branchID === "") {
      showError("Please select branch");
      return;
    }
    else if (min_voucher_amount === "") {
      showError("Please enter minimum voucher amount");
      return;
    }
    else if (Number(min_voucher_amount) === 0) {
      showError("Amount must be greater than zero");
      return;
    }
    else {
      $(".loader").show();
      let item = {
        min_voucher_amount: Number(min_voucher_amount),
        branch_id : branchID

      };
      api
        .post(`/groupAdmin/voucher_settings`, item, { headers })
        .then((res) => {
          voucherSettingGet();
          showSuccessNotification("Voucher amount has been saved succesfully.")
          $(".loader").hide();
          //   const tableData = res.data.data;

          // console.log("d", tableData);
        })
        .catch((err) => {
          if (err.response) {
            $(".loader").hide();
            console.log(err.response?.data?.error?.message);
          }
        });
    }


  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();



  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack mb-1">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Voucher Management - Voucher Settings
                        </h1>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() =>
                              history.push(
                                "/group_admin/coupon_voucher_management/voucher_management/voucher_templates"
                              )
                            }
                            className="btn btn-primary"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={isMobile ? "" : "rounded border p-10"}>
                      <CommonVoucher />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className={isMobile ? "" : "card card-docs mb-2"}>
                            <div className={isMobile ? "" : "card-body"}>
                              <div className="rounded border p-10">
                                <form
                                  onSubmit={handleSubmit}
                                >
                                  <div className="row">
                                    <div className="col-md-6 mb-5">
                                      <label className="required form-label my-1 ">
                                        <strong>
                                          Select Branch
                                        </strong>

                                      </label>
                                      <div style={{ marginTop: "-6px" }}>
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                    <NoteState />
                                    </div>
                                    <div className="col-md-6 mb-10">
                                      <label className="required form-label my-1 ">
                                        <strong>
                                          Minimum Voucher amount
                                        </strong>

                                      </label>
                                      <div style={{ marginTop: "-6px" }}>
                                        Please enter the minimum voucher amount
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-10">
                                      <div className="input-group mb-3">
                                        <div className="input-group-append">
                                          <span className="input-group-text" id="basic-addon2">
                                            {currencyLabel}
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="filterme form-control"
                                          placeholder="Enter Amount"
                                          value={min_voucher_amount}
                                          onChange={(e) => setmin_voucher_amount(e.target.value.replace(/[^0-9]/g, ""))}
                                          aria-describedby="basic-addon2"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 text-center">
                                    <br />
                                    <div
                                      className="loader"
                                      style={{ display: "none" }}
                                    >
                                      <img src="/assets/loader.gif" />
                                    </div>
                                  </div>
                                  <ErrorMessage error={error} />
                                  <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>

    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
  branchData: state.branch.branchData,
});

export default connect(mapStateToProps)(GroupAdminVoucherSetting);


