import { BRANCHID } from "../../actions";



const branchDetaislByIDReducer = (state = {}, action) => {
    switch (action.type) {
        case BRANCHID.CLEAR_BRANCHDETAILS_BY_ID:
            return {};
        case BRANCHID.SET_BRANCHDETAILS_BY_ID:
            return {...action?.branchDetails};
        default:
            return state;
    }
}

export default branchDetaislByIDReducer