import React from 'react'
import {Link} from "react-router-dom"

export default function CommonVoucher() {

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];

  return (
    <>
    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
            <li className="nav-item">
                        <Link
                          className={
                            url === "voucher_templates"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/coupon_voucher_management/voucher_management/voucher_templates",
                          }}
                        >
                          Voucher Templates
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            url === "purchased_vouchers"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/coupon_voucher_management/voucher_management/purchased_vouchers",
                          }}
                        >
                          Purchased Vouchers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            url === "voucher_settings"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/coupon_voucher_management/voucher_management/voucher_settings",
                          }}
                        >
                           Voucher Settings
                        </Link>
                      </li>
                    </ul>
    </>
  )
}
