import React, { useEffect, useState } from "react";
import {  useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import Select from "react-select";
import "./ProductDetailsInformation.css"
import useDrawer from "../../../../context/DrawerContext";
import CommonDetailsPage from "./CommonDetailsPage";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { getCurrencyLabel } from "../../../Comman/currencyUtils/currencyUtils";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import useError from "../../../Comman/Error/useError";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BackButtonForProdctu from "./BackButtonForProdctu";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";
window.jquery = window.$ = $;

export default function GroupAdminProductDetailsInformation() {
  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const { error, showError, clearError } = useError();
  const {productid ,catagoryid ,menuId ,branchID } = useParams();

  let history = useHistory();
  let location = useLocation();
  const [GetProperty, GetSetProperty] = useState([]);
  const [userType, setuserType] = useState([]);
  const [PosOptionValue, setPosOptionValue] = useState("");
  const [Option_, setOption_] = useState("");
  const [isShown, setIsShown] = useState(false);

  const [pos_product_name, setpos_product_name] = useState("");
  const [pos_product_sku_no, setpos_product_sku_no] = useState("");

  const words = PosOptionValue?.split("-");

  useEffect(() => {
    groupAdminProductListById();
  }, [location]);

  // console.log('ss' ,location)

  useEffect(() => {
    CustomeProductPropertyGet();
    OrderProductPropertyGet();
  }, []);

  function CustomeProductPropertyGet() {
    api
      .get(`/groupAdmin/product_property`, { headers })
      .then((res) => {
        const tableData = res?.data?.data?.properties;
        let arr = tableData?.map((item) => {
          return { name: item?.name, isAtive: false };
        });
        GetSetProperty(arr);
        // console.log("tableData", arr);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  function OrderProductPropertyGet() {
    api
      .get(`/groupAdmin/product_order_type`, { headers })
      .then((res) => {
        const tableData = res?.data?.data?.order_type;
        let arr = tableData?.map((item) => {
          return { name: item?.name, isAtive: false };
        });
        setuserType(arr);
        // console.log("tableData" ,tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const [PrinterList_ , setPrinterList_] = useState([])

  useEffect(() => {
    PrinterList();
  }, [branchID]);

  function PrinterList() {
    if(branchID){
    api
      .get(`/groupAdmin/branchPrinter/branch-wise-printer-category/${branchID}`, { headers })
      .then((res) => {
        const tableData = res?.data?.data
      //   GetSetProperty(arr);
        // console.log("tableData", tableData);
        const options = res?.data?.map((item) => ({
          label: item.name,
          value: item._id,
        }));

        if(options.length > 0)
        {
          options.push( 
            {label: 'No select', value: 'No select'});
        }
        setPrinterList_(options)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
    }
  }

  ///////////////////////// Prducts Details By ID /////////////////////////

  const [user1, setuser1] = useState([]);
  const [user1Type, setuser1Type] = useState([]);


  const [name_DE, setname_DE] = useState("");
  const [name_EN, setname_EN] = useState("");
  const [name_FR, setname_FR] = useState("");
  const [name_IT, setname_IT] = useState("");
  const [name_AR, setname_AR] = useState("");
  const [min_order_qty, setmin_order_qty] = useState("");
  const [max_order_qty, setmax_order_qty] = useState("");
  const [printer_text, setprinter_text] = useState("");
  const [VAT, setVAT] = useState("");
  const [VAT_category, setVAT_category] = useState("");
  const [base_price, setbase_price] = useState("");
  const [discount, setdiscount] = useState("");
  const [stock_qty, setstock_qty] = useState("");
  const [image_url, setimage_url] = useState("");
  const [is_active, setis_active] = useState();
  const [seq_no, setseq_no] = useState("");
  const [editPropety, seteditPropety] = useState([]);
  const [PrinterEditTimeGet_ , setPrinterEditTimeGet_] = useState([])


  

  function groupAdminProductListById() {
    api
      .get(`/groupadmin/product/${productid}`, {
        headers: headers,
      })
      .then((res) => {
        const tableData = res?.data?.data?.[0];
        // console.log("sss" ,tableData)
        let arr = tableData?.properties?.map((item) => {
          return { name: item, isAtive: true };
        });

        let arrType = tableData?.order_type?.map((item) => {
          return { name: item, isAtive: true };
        });
        setuser1(arr);
        setuser1Type(arrType);

        // console.log("FF", tableData);

        setname_DE(tableData?.name_DE);
        setname_EN(tableData?.name_EN);
        setname_FR(tableData?.name_FR);
        setname_IT(tableData?.name_IT);
        setname_AR(tableData?.name_AR);
        setimage_url(tableData.image_url ? tableData.image_url : "");
        setmin_order_qty(tableData.min_order_qty);
        setmax_order_qty(tableData.max_order_qty);
        // setprinter_text(tableData.printer_text);
        setPrinterEditTimeGet_({
          label: tableData.printer_category_name,
          value: tableData.printer_text,
        })
        setdiscount(tableData.discount);
        setstock_qty(tableData.stock_qty);
        setbase_price(tableData.base_price);
        setis_active(tableData.is_active);
        setVAT(tableData.VAT);
        setVAT_category(tableData?.VAT_category ? tableData?.VAT_category : "");

        setseq_no(tableData.seq_no);
        setpos_product_name(
          tableData?.pos_product_name ? tableData?.pos_product_name : ""
        );
        setpos_product_sku_no(
          tableData?.pos_product_sku_no ? tableData?.pos_product_sku_no : ""
        );
      });
  }

  const handleCheckboxList = (index, e) => {
    let custom_ = res.slice();
    custom_[index].isAtive = !custom_[index].isAtive;
    seteditPropety(custom_);
  };

  const handleCheckboxListType = (index, e) => {
    let custom_ = resType.slice();
    custom_[index].isAtive = !custom_[index].isAtive;
    seteditPropety(custom_);
  };

  const array3 = user1?.concat(GetProperty);
  let res = array3?.reduce(
    (ac, a) => (ac?.find((x) => x?.name === a?.name) ? [...ac] : [...ac, a]),
    []
  );


  const array3Type = user1Type?.concat(userType);
  let resType = array3Type?.reduce(
    (ac, a) => (ac?.find((x) => x?.name === a?.name) ? [...ac] : [...ac, a]),
    []
  );

  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setImgData(e.target?.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setimage_url(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //// ///////////////////// Prducts Details By ID /////////////////////////

  function EdithandleSubmit(event) {
    event.preventDefault();
    clearError(null);
    $(".loader").show();

    var item = {};
    const formData = new FormData();
    formData.append("is_active", is_active);
    // formData.append("image", imgData);
   
    formData.append("menu_id", menuId);

    let properties = res
      ?.filter((item) => item?.isAtive)
      ?.map((main) => main.name);


      let propertiesType = resType
      ?.filter((item) => item?.isAtive)
      ?.map((main) => main.name);


    if (words?.[0] ? words?.[0] : pos_product_name) {
      formData.append(
        "pos_product_name",
        words?.[0] ? words?.[0] : pos_product_name
      );
    }

    if (words?.[1] ? words?.[1] : pos_product_sku_no) {
      formData.append(
        "pos_product_sku_no",
        words?.[1] ? words?.[1] : pos_product_sku_no
      );
    }

    if (words?.[2]) {
      formData.append("pos_product_price", words?.[2]);
    }

    formData.append("properties", JSON.stringify(properties));
    // formData.append("order_type", JSON.stringify(propertiesType));



    if (propertiesType.length <= 0) {
      showError("Please select at least one order type");
      $(".loader").hide();
      return
    } else {
      formData.append(
        "order_type",
        JSON.stringify(propertiesType))
    }

    formData.append("seq_no", Number(seq_no));

    if (name_EN) {
      formData.append("name_EN", name_EN);
    }
    if (imgData) {
      if (
          imgData.type !== "image/png" &&
          imgData.type !== "image/jpeg" &&
          imgData.type !== "image/jpg"
      ) {
          showError("Please select a PNG, JPEG, or JPG image for the edit");
          $(".loader").hide();
          return; // Stop processing if the image type is not valid
      }
      formData.append("image", imgData);
      }
    if (name_FR) {
      formData.append("name_FR", name_FR);
    }
    if (name_IT) {
      formData.append("name_IT", name_IT);
    }
    if (name_AR) {
      formData.append("name_AR", name_AR);
    }
    if (base_price) {
      formData.append("base_price", base_price);
    }
    if (name_DE) {
      formData.append("name_DE", name_DE);
    }
    if (max_order_qty) {
      formData.append("max_order_qty", max_order_qty);
    }
    if (min_order_qty) {
      formData.append("min_order_qty", min_order_qty);
    }
    if (printer_text?.value) {
      formData.append("printer_text", printer_text?.value);
    }
    if (discount) {
      formData.append("discount", discount);
    }
    if (stock_qty) {
      formData.append("stock_qty", stock_qty);
    }
    if (VAT_category) {
      formData.append("VAT_category", VAT_category);
    }
    if (Number(seq_no) == 0) {
      showError(
        `The entered does not support the priority, please kindly add a proper value`
      );
    }

    // console.log("krunal", Object.fromEntries(formData.entries()));
    // return;

    api
      .patch(`/groupadmin/product/${productid}`, formData, {
        headers,
      })
      .then((res) => {
        $(".loader").hide();
        showSuccessNotification("Product details has been edited successfully.")
        groupAdminProductListById();
        OrderProductPropertyGet();
        CustomeProductPropertyGet();
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          showError(err.response?.data?.error?.message);
        }
      });
  }

  function EdithandleSubmitImage(event) {
    event.preventDefault();

    setimage_url("");
    const formData = new FormData();
    formData.append("image", "");

    api
      .patch(
        `/groupadmin/product/removeImage/${productid}`,
        formData,
        {
          headers,
        }
      )
      .then((res) => {
        // Swal.fire({
        //   position: "center-center",
        //   icon: "success",
        //   title: "Product image has been edited successfully.",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        //  window.location.reload(true);
      })
      .catch((err) => {
        if (err.response) {
          showError(err.response?.data?.error?.message);
        }
      });
  }

  const [Newlanguange, setNewlanguange] = useState([]);

  useEffect(() => {
    groupAdminBranchDetilasByID();
  }, [branchID]);

  function groupAdminBranchDetilasByID() {
    api
      .get(`/groupadmin/branch/${branchID}`, { headers })
      .then((res) => {
        const tableData = res.data.data.resData;
        setNewlanguange(tableData.languange);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data);
        }
      });
  }

  ///////////////////////// Pos ID Details Get ////////////////////////////

  useEffect(() => {
    GetPosIDDetails();
  }, []);

  const [POSTable, setPOSTable] = useState([]);

  function GetPosIDDetails() {
    api
      .get(`/groupadmin/settings/POS/product_list/${branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setPOSTable(tableData ? tableData : []);
      })
      .catch((error) => {
        console.log(error?.response?.data?.error?.message);
      });
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  const currencyLabel = getCurrencyLabel("CHF");


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Product - Product Information
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <BackButtonForProdctu
                            name={"Go Back"}
                          />
                      </div>
                    </div>
                  </div>
                    <div className="rounded border p-5">
                     <CommonDetailsPage/>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <form onSubmit={EdithandleSubmit}>
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6>Name</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="English"
                                          required
                                          style={
                                            Newlanguange.includes("English")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            Newlanguange.includes("English")
                                              ? false
                                              : true
                                          }
                                          value={name_EN}
                                          onChange={(e) =>
                                            setname_EN(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mb-10">
                                        <label className="form-label">AR</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Arabic"
                                          style={
                                            Newlanguange.includes("arabic")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            Newlanguange.includes("arabic")
                                              ? false
                                              : true
                                          }
                                          value={name_AR}
                                          onChange={(e) =>
                                            setname_AR(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="form-label">DE</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="German"
                                          value={name_DE}
                                          style={
                                            Newlanguange.includes("Deutsch")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            Newlanguange.includes("Deutsch")
                                              ? false
                                              : true
                                          }
                                          onChange={(e) =>
                                            setname_DE(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="form-label">FR</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="French"
                                          value={name_FR}
                                          style={
                                            Newlanguange.includes("français")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            Newlanguange.includes("français")
                                              ? false
                                              : true
                                          }
                                          onChange={(e) =>
                                            setname_FR(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="form-label">IT</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Italian"
                                          value={name_IT}
                                          style={
                                            Newlanguange.includes("Italiano")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            Newlanguange.includes("Italiano")
                                              ? false
                                              : true
                                          }
                                          onChange={(e) =>
                                            setname_IT(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <br />

                                    <div className="rounded border p-10">
                                      <h6>Image</h6>
                                      <br />
                                      <div
                                        className="image-input image-input-outline mb-15"
                                        data-kt-image-input="true"
                                        // style={{
                                        //     backgroundImage: "url(/../assets/media/avatars/blank.png)"
                                        // }}
                                      >
                                        <img
                                          className="image-input-wrapper w-125px h-125px"
                                          // style={{
                                          //     backgroundImage:
                                          //         "url(../assets/media/svg/3377061_food_hor_mok_thai_icon.png)"
                                          // }}
                                          // accept=".png, .jpg, .jpeg"
                                          src={
                                            image_url
                                              ? image_url
                                              : "/assets/media/svg/3377061_food_hor_mok_thai_icon.png"
                                          }
                                          alt=""
                                        ></img>
                                        <label
                                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                          data-kt-image-input-action="change"
                                          data-bs-toggle="tooltip"
                                          data-bs-dismiss="click"
                                          // title="Change avatar"
                                        >
                                          <i className="bi bi-pencil-fill fs-7" />
                                          <input
                                            type="file"
                                            name="avatar"
                                            onChange={onChangePicture}
                                            accept=".png, .jpg, .jpeg"
                                          />
                                        </label>
                                        {image_url ? (
                                          <React.Fragment>
                                            <span
                                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                              data-kt-image-input-action="cancel"
                                              data-bs-toggle="tooltip"
                                              data-bs-dismiss="click"
                                            >
                                              <i
                                                onClick={EdithandleSubmitImage}
                                                className="bi bi-x fs-2"
                                              />
                                            </span>
                                            <span
                                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                              data-kt-image-input-action="remove"
                                              data-bs-toggle="tooltip"
                                              data-bs-dismiss="click"
                                              title="Remove"
                                            >
                                              <i
                                                onClick={EdithandleSubmitImage}
                                                className="bi bi-x fs-2"
                                              />
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div className="rounded border p-10 my-2 mt-7 ">
                                      <div className="mb-10">
                                        <h6>Max/Min Order Quantity</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Min. Order Quantity
                                        </label>
                                        <input
                                          type="text"
                                          required
                                          className="filterme form-control form-control-solid"
                                          placeholder="Min. Order Quantity"
                                          value={min_order_qty}
                                          onChange={(e) =>
                                            setmin_order_qty(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Max. Order Quantity
                                        </label>
                                        <input
                                          type="text"
                                          required
                                          className="filterme form-control form-control-solid"
                                          placeholder="Max. Order Quantity"
                                          value={max_order_qty}
                                          onChange={(e) =>
                                            setmax_order_qty(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={is_active}
                                          onChange={(e) =>
                                            setis_active(!is_active)
                                          }
                                        />
                                        <label className="form-check-label mx-3">
                                          <h6>Active</h6>
                                        </label>
                                      </div>
                                    </div>
                                    <br />
                                    <div
                                      className="rounded border p-10"
                                      onMouseLeave={() => setIsShown(false)}
                                    >
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          Lightspeed POS ID
                                        </label>
                                      </div>
                                      <div className="mb-10">
                                        <div className="mb-10">
                                          {isShown == true ? (
                                            <div
                                              onMouseEnter={() =>
                                                setIsShown(true)
                                              }
                                            >
                                              <Select
                                                id="select2"
                                                required
                                                className="search-line"
                                                placeholder="Select... POS"
                                                options={
                                                  POSTable?.length > 0
                                                    ? POSTable?.map((main) => ({
                                                        label: `${main?.product_name} - ${main?.product_sku_number}`,
                                                        value: ` ${
                                                          main?.product_name
                                                        }-${
                                                          main?.product_sku_number
                                                        }-${
                                                          main?.product_price
                                                            ? main?.product_price
                                                            : ""
                                                        }`,
                                                      }))
                                                    : "No Option"
                                                }
                                                onChange={(opt) => {
                                                  setpos_product_name("");
                                                  setpos_product_sku_no("");
                                                  setPosOptionValue(opt.value);
                                                  setOption_(opt);
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              onMouseEnter={() =>
                                                setIsShown(true)
                                              }
                                              onMouseLeave={() =>
                                                setIsShown(false)
                                              }
                                            >
                                              <select
                                                className="form-select"
                                                // data-control="select2"
                                                data-placeholder="Select an option"
                                                value={`${
                                                  pos_product_name
                                                    ? pos_product_name
                                                    : words?.[0]
                                                }-${
                                                  pos_product_sku_no
                                                    ? pos_product_sku_no
                                                    : words?.[1]
                                                }`}
                                              >
                                                {pos_product_name == "" ? (
                                                  <option
                                                    defaultValue=""
                                                    value=""
                                                  >
                                                    Select POS
                                                  </option>
                                                ) : (
                                                  ""
                                                )}
                                                {POSTable.length > 0 ? (
                                                  POSTable?.map(
                                                    (main, index) => (
                                                      <option
                                                        key={index}
                                                        value={`${main?.product_name}-${main?.product_sku_number}`}
                                                      >
                                                        {`${main?.product_name} - ${main?.product_sku_number}`}
                                                      </option>
                                                    )
                                                  )
                                                ) : (
                                                  <option selected="">
                                                    No option available
                                                  </option>
                                                )}
                                              </select>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          ID
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          readOnly
                                          value={
                                            words?.[1]
                                              ? words?.[1]
                                              : pos_product_sku_no
                                          }
                                        />
                                      </div>
                                    </div>
                                    <br />
                                    <div className="rounded border p-10">
                                      <div className="mb-7">
                                        <label className="form-label">
                                          Printer
                                        </label>
                                        <Select
                                                className="search-line"
                                                placeholder="Select Printer ..."
                                                options={PrinterList_}
                                                value={printer_text ? printer_text : PrinterEditTimeGet_  }
                                                onChange={(opt) => {
                                                  setprinter_text(opt);
                                                  setPrinterEditTimeGet_([])
                                                }}
                                          />
                                      </div>
                                    </div>
                                    <br />
                                    <div className="rounded border p-10">
                                      <div className="mb-8">
                                        <h6>Base Price</h6>
                                      </div>
                                      <div className="mb-6">
                                        <label className="required form-label">
                                          Amount
                                        </label>
                                        <input
                                          type="text"
                                          className="filterme form-control form-control-solid"
                                          placeholder={`Enter Amount`}
                                          required
                                          value={base_price}
                                          onChange={(e) =>
                                            setbase_price(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-6">
                                        <label className=" orm-label">
                                          Display Discount
                                        </label>
                                        <input
                                          type="text"
                                          className="filterme form-control form-control-solid"
                                          placeholder="Discount"
                                          value={discount}
                                          onChange={(e) =>
                                            setdiscount(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <br />

                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6 className="required">Stock</h6>
                                      </div>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Quantity On Stock
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="filterme form-control"
                                          placeholder="Stock"
                                          required
                                          value={stock_qty}
                                          onChange={(e) =>
                                            setstock_qty(e.target.value)
                                          }
                                        />
                                      </div>
                                      {/* <span className="form-text text-muted">Some help content goes here</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="card-body fs-6 p-0 px-10 pb-15 px-lg-15 text-gray-700">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          VAT Categories
                                        </label>
                                        <select
                                          onChange={(e) =>
                                            setVAT_category(e.target.value)
                                          }
                                          value={VAT_category}
                                          required
                                          className="form-select"
                                          // data-control="select2"
                                          data-placeholder="Select an option"
                                        >
                                          {VAT_category == "" ? (
                                            <option>Select VAT Category</option>
                                          ) : (
                                            ""
                                          )}
                                          <option value="food">Food</option>
                                          <option value="softdrinks">
                                            Soft Drinks
                                          </option>
                                          <option value="alcoholicdrinks">
                                            Alcoholic drinks
                                          </option>
                                        </select>

                                        {/* <input
                                          type="text"
                                          required
                                          className="filterme form-control form-control-solid"
                                          placeholder="Vat"
                                          value={VAT}
                                          onChange={(e) =>
                                            setVAT(e.target.value)
                                          }
                                        /> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Product sequence number
                                        </label>
                                        <input
                                          type="text"
                                          className="filterme form-control form-control-solid"
                                          placeholder="Enter product sequence number"
                                          value={seq_no}
                                          required
                                          onChange={(e) =>
                                            setseq_no(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                  <div className="row">
                                  
                                  <div className="col-md-6">
                                    <div
                                      className="rounded border p-10"
                                      style={{ marginTop: "-81px" }}
                                     >
                                    <div className="col-md-10">
                                      <h3>Custom Properties</h3>
                                    </div>
                                    <div className="col-md-2">
                                    </div>
                                    <br />
                                  {res
                                    ? res?.map((main, index) => (
                                      <React.Fragment>
                                        <div className="row">
                                        <div className="mb-4 col-md-12" key={index}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={main?.isAtive}
                                            onClick={(e) =>
                                              handleCheckboxList(index, e)
                                            }
                                          />
                                          <label className="mx-3 form-check-label">
                                            {main?.name}
                                          </label>
                                        </div>
                                        {/* <div className="mb-4 col-md-11">
                                          <label className="form-check-label">
                                            {main?.name}
                                          </label>
                                        </div> */}
                                      </div>
                                      </React.Fragment>
                                        
                                      ))
                                    : ""}
                                  </div>
                                  </div>


                                  <div className="col-md-6">
                                    <div
                                      className="rounded border p-10"
                                      style={{ marginTop: "-81px" }}
                                     >
                                    <div className="col-md-10">
                                      <h3 className="required">Order Type Properties</h3>
                                    </div> 
                                    <br />
                                  {resType
                                    ? resType?.map((main, index) => (
                                      <React.Fragment>
                                        <div className="row">
                                        <div className="mb-4 col-md-1" key={index}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={main?.isAtive}
                                            onClick={(e) =>
                                              handleCheckboxListType(index, e)
                                            }
                                          />
                                        </div>
                                        <div className="mb-4 col-md-11">
                                          <label style={{textTransform : "capitalize"}}  className="form-check-label">
                                            {main?.name}
                                          </label>
                                        </div>
                                      </div>
                                      </React.Fragment>
                                      ))
                                    : ""}
                                  </div>
                                  </div>
                                </div>
                                </div>
                                <div className="col-md-12 text-center">
                                  <br />
                                  <div
                                    className="loader"
                                    style={{ display: "none" }}
                                  >
                                    <img src="/assets/loader.gif" />
                                    {/* <img src="" alt="logo" /> */}
                                  </div>
                                </div>
                                <ErrorMessage error={error} />
                                <div className="modal-footer flex-center">
                                {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("edit")) &&  (
                                  <button
                                    type="submit"
                                    id="kt_modal_new_address_submit"
                                    className="btn btn-primary"
                                  >
                                    <span className="indicator-label">
                                      Save
                                    </span>
                                  </button>)}
                                  <button
                                    type="button"
                                    className="btn btn-danger m-2"
                                    onClick={() =>
                                      history.push(
                                        `/group_admin/menu_management/menu_category/menu_products/${catagoryid}/${menuId}/${branchID}` ,
                                        
                                      )
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
