import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import headers from "../../../../CommonApi/headers";
import { styled, alpha } from "@mui/material/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InputBase from "@mui/material/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "../../../Comman/TableuseStyles";

import $ from "jquery";
import { useMediaQuery } from "react-responsive";
import { setBranch } from "../../../../Store";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function GroupAdminPendingEnquiryList() {
  /////////////////////////////// Branch List /////////////////////////

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  useEffect(() => {
    groupAdminBranchList();
  }, []);

  const [user1, setuser1] = useState([]);

  function groupAdminBranchList() {
    api
      .get(`/groupadmin/branch/restaurantwise`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        // console.log("tableData" ,tableData)
        setuser1(tableData);
      })
      .catch((err) => {});
  }

  ////////////////////// Branch List ///////////////////////////////////

  const [passengersList, setPassengersList] = useState([]);
  const [passengersList2, setPassengersList2] = useState([]);
  const [passengersCount, setPassengersCount] = useState(0);
  // const [passengersCount2, setPassengersCount2] = useState(0);
  const [filter, setFilter] = useState([]);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20,
    isLoading: false,
  });

  let history = useHistory();

  useEffect(() => {
    PendingEnquiryList();
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = passengersList2.filter((row) => {
      return (
        row?.order_no
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.first_name
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.phone_number
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.order_type
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.email
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase())
      );
    });
    if (searchedVal.length < 1) {
      setPassengersList(passengersList2);
    } else {
      setPassengersList(filteredRows);
    }
  };

  // const [branchID, setbranchID] = useState('');

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [BranchNameHide ,setBranchNameHide] = useState(true)

  function PendingEnquiryList(id) {
    let item = {
      branch_id: id,
    };
    setBranchNameHide(true)
    let url = `/groupAdmin/inquiry/pending/list?page=${controller.page}&per_page=${controller.rowsPerPage}`;
    api
      .get(`${url}`, { headers })
      .then((res) => {
        const tableData = res.data.data.inquiryData;

        // const tableData = res.data.data?.orderData;
        // console.log(tableData)
        setPassengersList(tableData);
        setPassengersList2(tableData);
        setPassengersCount(res.data.data?.totalInquiries);
        setFilter(tableData);
        // setuser(tableData);
        if (res.data.data) {
          setError(null);
        }
        // $("#kt_tab_pane_1").show();
        // setBranchWith([]);
        // console.log("Branch without ID Vadu", tableData);
      })
      .catch((err) => {
        if (err.response) {
          // console.log("Branch without ID Vadu", err.response.data.error.message)
          if (err.response?.data?.error?.message) {
            setPassengersList([]);
            setError(err.response?.data?.error?.message);
          }
        }
      });
  }



  function PendingEnquiryListByBranchID(id) {
    setError(null);

    let item = {
      branch_id: id,
    };

    let url = `/groupAdmin/inquiry/pending/list?page=${controller.page}&per_page=${controller.rowsPerPage}`;

    api
      .post(`${url}`, item, { headers })
      .then((res) => {
        // const tableData = res.data.data;

        const tableData = res.data.data?.inquiryData;
        setPassengersList(tableData);
        setPassengersList2(tableData);
        setPassengersCount(res.data.data?.totalInquiries);
        setFilter(tableData);
        $("#kt_tab_pane_1").hide();
        // $("#BranchIDWith").hide();
        setBranchNameHide(false);
        // console.log("Branch with ID Vadu krunal", tableData);
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.messag);
        if (err.response) {
          if (err.response?.data?.error?.message) {
            // BranchWithOut([]);
            setPassengersList([]);
            setError(err.response?.data?.error?.message);
            // $("#BranchIDWith").hide();
            setBranchNameHide(false);
          }
        }
      });
  }

  const classes = useStyles();

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...passengersList].sort((a, b) => {
        return (
          a[sortField]
            ?.toString()
            ?.localeCompare(b[sortField]?.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setPassengersList(sorted);
    }
  };

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                           Pending Enquiry List
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                  
                  <hr />
                  <div className="py-5 mt-4">
                    <div className="card card-docs mb-2">
                      <div
                        className={
                          isMobile
                            ? "card-body fs-6 py-15 px-2 py-lg-15 text-gray-700"
                            : "card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700"
                        }
                      >
                        <div
                          className={
                            isMobile
                              ? "rounded border p-3"
                              : "rounded border p-10"
                          }
                        >
                          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                data-bs-toggle="tab"
                                onClick={(e) => PendingEnquiryList()}
                                to="#kt_tab_pane_1"
                              >
                                All Location
                              </Link>
                            </li>
                            {user1?.length > 0 &&
                            user1?.map((main, index) => (
                                  <li className="nav-item" key={index}>
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      // value={main._id}

                                      onClick={(e) =>
                                        PendingEnquiryListByBranchID(main._id)
                                      }
                                      // to={`#kt_tab_pane_${index}`}
                                      to="#kt_tab_pane_2"
                                    >
                                      {main.branch_name_EN}
                                    </Link>
                                  </li>
                                ))
                            }
                          </ul>
                          <div className="col-md-12"> {errorDiv} </div>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              // id="kt_tab_pane_1"
                              role="tabpanel"
                            >
                              <div className={classes.root}>
                                <Card style={{ overflowX: "auto" }}>
                                  {error ? (
                                    ""
                                  ) : (
                                    <TablePagination
                                      component="div"
                                      rowsPerPageOptions={[]}
                                      backIconButtonProps={{
                                        "aria-label": "Previous Page",
                                      }}
                                      nextIconButtonProps={{
                                        "aria-label": "Next Page",
                                      }}
                                      onPageChange={handlePageChange}
                                      page={controller.page}
                                      count={passengersCount}
                                      rowsPerPage={controller.rowsPerPage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  )}
                                  <hr />
                                  {error ? (
                                    ""
                                  ) : (
                                    <Search className="d-flex justify-content-end">
                                      <SearchIconWrapper>
                                        <SearchIcon style={{ fontSize: 22 }} />
                                      </SearchIconWrapper>
                                      <StyledInputBase
                                        style={{ fontSize: 22 }}
                                        placeholder="Search…"
                                        onChange={(e) =>
                                          requestSearch(e.target.value)
                                        }
                                        inputProps={{ "aria-label": "search" }}
                                      />
                                    </Search>
                                  )}
                                  <Table>
                                    <TableHead
                                      style={{
                                        background: "darkgray",
                                        color: "#fff",
                                        fontSize: 30,
                                      }}
                                    >
                                      <TableRow>
                                        <TableCell style={{ fontSize: "16px" }}>
                                          Sr.No.
                                        </TableCell>
                                        {BranchNameHide ? 
                                        <TableCell  style={{ fontSize: "16px" }}>
                                          Branch Name{" "}
                                        </TableCell> : null}
                                        <TableCell
                                          onClick={() =>
                                            handleSortingChange("name")
                                          }
                                          style={{ fontSize: "16px" }}
                                        >
                                          Name{" "}
                                          {order === "asc" &&
                                          sortField === "name" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          onClick={() =>
                                            handleSortingChange("phone_number")
                                          }
                                          style={{ fontSize: "16px" }}
                                        >
                                          Phone{" "}
                                          {order === "asc" &&
                                          sortField === "phone_number" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          onClick={() =>
                                            handleSortingChange("email")
                                          }
                                          style={{ fontSize: "16px" }}
                                        >
                                          Email{" "}
                                          {order === "asc" &&
                                          sortField === "email" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>

                                        <TableCell
                                          onClick={() =>
                                            handleSortingChange("created_at")
                                          }
                                          style={{ fontSize: "16px" }}
                                        >
                                          Enquiry Date 
                                          {order === "asc" &&
                                          sortField === "created_at" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "16px" }}>
                                          Actions
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {passengersList?.length > 0 && 
                                      passengersList.map(
                                        (passenger, index) => (
                                          <TableRow key={index}>
                                            <TableCell
                                              style={{ fontSize: "14px" }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            {BranchNameHide ? 
                                            <TableCell
                                            
                                              style={{ fontSize: "14px" }}
                                            >
                                              {
                                                passenger?.branch
                                                  ?.branch_name_EN
                                              }
                                            </TableCell> : null }
                                            <TableCell
                                              style={{ fontSize: "14px" }}
                                            >
                                              {passenger.name}
                                            </TableCell>
                                            <TableCell
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              <div>
                                                {passenger.phone_number}
                                              </div>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "14px",
                                                textAlign: "start",
                                              }}
                                            >
                                              <div>{passenger.email}</div>
                                            </TableCell>

                                            <TableCell
                                              style={{
                                                fontSize: "14px",
                                                textAlign: "start",
                                              }}
                                            >
                                              <div>{moment(passenger.created_at).format("DD/MM/YYYY") }</div>
                                            </TableCell>
                                            <TableCell
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              <Tooltip title="View Details">
                                                <IconButton
                                                  aria-label="Details"
                                                  onClick={() =>
                                                    history.push(
                                                      `/group_admin/enquiry/pending_enquiry_view/${passenger._id}`
                                                    )
                                                  }
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                  {error ? (
                                    ""
                                  ) : (
                                    <TablePagination
                                      component="div"
                                      rowsPerPageOptions={[]}
                                      onPageChange={handlePageChange}
                                      page={controller.page}
                                      count={passengersCount}
                                      rowsPerPage={controller.rowsPerPage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  )}
                                </Card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
