import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import BarChart from "../../Charts/BarChart";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import "./Dashboard2.css";
import { userPermissionType } from "../../Comman/UserPermission";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDrawer from "../../../context/DrawerContext";
import { getBranchList, getDashboardCount } from "../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../Comman/BranchList";
import QRCodeDownload from ".././QRCodeDownload";
import RedirectLink from ".././RedirectLink";
import BarChat3 from "../../Charts/BarChat3";
import BarChart4 from "../../Charts/BarChart4";
import { generateUrl } from ".././generateUrl";
import { getDateRange, selectStyles } from "../../Comman/CommonFunction/CommonFunction";
import { LoadingBackdrop } from "../../Comman/LoadingBackdrop/LoadingBackdrop";
import { useIsMobile } from "../../Comman/MediaQueries/mediaQueries";
import moment from "moment";
import DashboardCards2 from "./DashBoardCard2";
import { Bestsellers2, BusinessSummary, StaffDetails, Top5Tender, TopCustomers, TopHours } from "./CountFile2";
import { CalendarDateRegular } from '@fluentui/react-icons'
import TabNavigation2 from "./TabNavigation";
import { getCurrencyLabel } from "../../Comman/currencyUtils/currencyUtils";



function DashboardNew2({ getBranchList, BranchList_, getDashboardCount, dashboardCountNew_ }) {

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var urlDaynamic = pathnamesplits[2];
      const isMobile = useIsMobile()
      const storedBranchId = sessionStorage.getItem(`${urlDaynamic}_branch`)

      let history = useHistory();
      useEffect(() => {
            if (userPermissionType === "TGA") {
                  history.push("/group_admin/tour");
            }
      }, [userPermissionType === "TGA"])
      const dashboardURl = sessionStorage.getItem("dashboardURl");

      // console.log("dashboardURl" ,dashboardURl)

      useEffect(() => {
            getBranchList();
      }, []);


      useEffect(() => {
            getBranchList();
            if (dashboardURl === null) {
                  DashboardCountAppi();
            }
      }, [dashboardURl === null]);

      function DashboardCountAppi() {
            api
                  .get(`/groupadmin/dashboard/count`, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        sessionStorage.setItem('dashboardURl', tableData?.url);
                  })
                  .catch((err) => {
                        if (err.response) {
                              // console.log(err.response?.data?.error?.message);
                        }
                  });
      }

      const [branch_id2, setbranch_id2] = useState(storedBranchId ? JSON.parse(storedBranchId) : []);
      const [Type, setType] = useState("Today")
      const [TypeShow, setTypeShow] = useState("Today");
      // const [TabCheck, setTabCheck] = useState(false);
      const [dateRange, setDateRange] = useState(null);
      const [startDate, setStartDate] = useState("");
      const [endDate, setEndDate] = useState("");

      const filteredOptions = branchfilterAndMapData(BranchList_);

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      const storedNavDate = sessionStorage.getItem(`${urlDaynamic}_Nav_Date`);
      const Date11 = storedNavDate ? JSON.parse(storedNavDate) : null

      useEffect(() => {
            const validTypes = ["Today", "Yesterday", "Week", "Month", "Year", "LastMonth"];
            if (startDate || endDate || validTypes.includes(Type)) {
                  let Type_ = Type === "FormTo" ? { startDate: startDate, endDate: endDate ? endDate : moment(new Date()).format('YYYY-MM-DD') } : Type
                  getSalesChart();
                  getDashboardCount(Type_, branch_id2.value);
                  // setTabCheck(false)
            }
      }, [branch_id2.value, startDate, endDate , Type]);

      const [ChartData1, setChartData1] = useState({
            labels: [],
            datasets: [],
            totals: { totalSales: 0, totalOrders: 0 }
      })

      const [ChartData3, setChartData3] = useState({
            labels: [],
            datasets: [],
            totals: { DeliveryOrderDataTotal: 0, PickupOrderDataTotal: 0, DineInOrderDataTotal: 0 }
      })

      const [ChartData4_, setChartData4_] = useState({
            labels: [],
            datasets: [],
            totals: { DeliveryOrderDataTotal: 0, PickupOrderDataTotal: 0, DineInOrderDataTotal: 0 }
      })

      const handleTabClick = (label) => {
            // setTabCheck(true)
            setType(label === "Current Month" ? "Month" : label === "Current Year" ? "Year" : label === "From - To" ? "FormTo" : label === "Last Month" ? "LastMonth" : label);
            if (label === "From - To") {
                  if (Date11 === null) {
                        setChartData1([]);
                        setChartData3([]);
                        setChartData4_([]);
                        getDashboardCount();
                        setStartDate(startDate);
                        setEndDate(endDate);
                  }
            } else {
                  setStartDate("");
                  setEndDate("");
            }
      };

      useEffect(() => {
            const dateResult = getDateRange(Type);
            setDateRange(dateResult);
      }, [Type]);

      // console.log("type" ,Type)

      const handleTabClickShow = (label) => {
            setTypeShow(label === "From - To" ? "Period" : label)
      };

      const YearData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const [MonthData, setMonthData] = useState(["Week1", "Week2", "Week3", "Week4"]);
      // Function to get the week of the month for a given date
      const getWeekOfMonth = (date) => {
            const startWeekDayIndex = 1; // Start of the week from Monday (0 for Sunday, 1 for Monday, etc.)
            const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
            const firstDay = (firstDate.getDay() - startWeekDayIndex + 7) % 7;
            return Math.ceil((date.getDate() + firstDay) / 7);
      };

      useEffect(() => {
            const currentDate = new Date();
            const currentWeek = getWeekOfMonth(currentDate);
            const weekLabel = `Week${currentWeek}`;

            // Update monthData if the current week is not already included
            setMonthData(prevData => {
                  if (!prevData.includes(weekLabel)) {
                        return [...prevData, weekLabel];
                  }
                  return prevData;
            });
      }, [TypeShow]);

      const DayData = ["00-02", "02-04", "04-06", "06-08", "08-10", "10-12", "12-14", "14-16", "16-18", "18-20", "20-22", "22-24"]
      const Weekdata = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOrder = { "Sunday": 1, "Monday": 2, "Tuesday": 3, "Wednesday": 4, "Thursday": 5, "Friday": 6, "Saturday": 7 };

      const getSalesChart = () => {

            let Type_ = Type === "FormTo" ? { startDate: startDate, endDate: endDate ? endDate : moment(new Date()).format('YYYY-MM-DD') } : Type

            const url = generateUrl(Type_, branch_id2.value);
            api
                  .get(url, { headers })
                  .then((res) => {
                        let datas = res?.data?.data;

                        //const lastElement = datas[datas.length - 1];
                        let data =  datas.filter(obj => !obj.hasOwnProperty('voucherSales'));
                        const lastElement = datas.find(obj => obj.hasOwnProperty('voucherSales'));
                        // console.log("data" ,data)

                        if (TypeShow === "Week") {
                              const completeData = Object.keys(dayOrder).map(day => {
                                    const dayData = data.find(d => d.dayOfWeek === day);
                                    return dayData || {
                                          "totalOrders": 0,
                                          "deliveryCount": 0,
                                          "pickupCount": 0,
                                          "dineInCount": 0,
                                          "total_additional_discount": 0,
                                          "total_coupon_and_voucher_discount": 0,
                                          "dayOfWeek": day,
                                          "totalSales": 0,
                                          "deliverySales": 0,
                                          "pickupSales": 0,
                                          "dineInSales": 0
                                    };
                              });
                              // Sort the complete dataset by the specified day order
                              completeData.sort((a, b) => dayOrder[a.dayOfWeek] - dayOrder[b.dayOfWeek]);
                              data = completeData;
                        }

                        const salesData = new Array(12).fill(0);
                        const orderData = new Array(12).fill(0);

                        const DeliveryOrderData = new Array(12).fill(0);
                        const PickupOrderData = new Array(12).fill(0);
                        const DineInOrderData = new Array(12).fill(0);

                        const DeliverySalesData = new Array(12).fill(0);
                        const PickupSalesData = new Array(12).fill(0);
                        const DineInSalesData = new Array(12).fill(0);

                        const From_ToData = new Array(12).fill(0);

                        data?.forEach((entry, index1) => {
                              let index;
                              switch (TypeShow) {
                                    case "Today":
                                          index = index1;
                                          break;
                                    case "Yesterday":
                                          index = index1;
                                          break;
                                    case "Week":
                                          index = index1; // Assuming dayOfWeek is 0 for Sunday, 1 for Monday, etc.
                                          break;
                                    case "Current Month":
                                          index = entry.week - 1; // Assuming week is 1-based
                                          break;
                                    case "Last Month":
                                          index = entry.week - 1; // Assuming week is 1-based
                                          break;
                                    case "Current Year":
                                          index = entry.month - 1; // Assuming month is 1-based
                                          break;
                                    case "Period":
                                          index = index1; // Assuming month is 1-based
                                          break;
                                    default:
                                          index = entry.month - 1;
                              }
                              salesData[index] = entry.totalSales;
                              orderData[index] = entry.totalOrders;
                              // {console.log(entry.totalSales)}

                              DeliveryOrderData[index] = entry.deliveryCount;
                              PickupOrderData[index] = entry.pickupCount;
                              DineInOrderData[index] = entry.dineInCount;


                              DeliverySalesData[index] = entry.deliverySales;
                              PickupSalesData[index] = entry.pickupSales;
                              DineInSalesData[index] = entry.dineInSales;
                              From_ToData[index] = entry.date
                              // Weekdata[index] = entry.dayOfWeek
                              // console.log("index" ,index)

                        });

                        const totalSales = salesData?.reduce((acc, curr) => acc + curr, 0) + Number(lastElement?.voucherSales || 0) ;
                        const totalOrders = orderData?.reduce((acc, curr) => acc + curr, 0);

                        const DeliveryOrderDataTotal = DeliveryOrderData?.reduce((acc, curr) => acc + curr, 0);
                        const PickupOrderDataTotal = PickupOrderData?.reduce((acc, curr) => acc + curr, 0);
                        const DineInOrderDataTotal = DineInOrderData?.reduce((acc, curr) => acc + curr, 0);


                        const DeliverySalesDataTotal = DeliverySalesData?.reduce((acc, curr) => acc + curr, 0);
                        const PickupSalesDataTotal = PickupSalesData?.reduce((acc, curr) => acc + curr, 0);
                        const DineInSalesDataTotal = DineInSalesData?.reduce((acc, curr) => acc + curr, 0);

                        // console.log('totalSales' ,totalSales)
                        let labels;
                        switch (TypeShow) {
                              case "Today":
                                    labels = DayData;
                                    break;
                              case "Yesterday":
                                    labels = DayData;
                                    break;
                              case "Week":
                                    labels = Weekdata;
                                    break;
                              case "Current Month":
                                    labels = MonthData;
                                    break;
                              case "Last Month":
                                    labels = MonthData;
                                    break;
                              case "Current Year":
                                    labels = YearData;
                                    break;
                              case "Period":
                                    labels = From_ToData; // Assuming month is 1-based
                                    break;
                              default:
                                    labels = YearData;
                        }

                        setChartData1({
                              labels: labels,
                              datasets: [
                                    {
                                          label: 'Total Orders',
                                          data: orderData,
                                          backgroundColor: "#FB923C",
                                          borderWidth: 1,
                                          borderRadius: 9,
                                          barThickness: 37
                                    },
                                    {
                                          label: 'Total Sales',
                                          data: salesData,
                                          backgroundColor: "#9A3412",
                                          borderWidth: 1,
                                          borderRadius: 9,
                                          barThickness: 37
                                    }
                              ],
                              totals: { totalSales, totalOrders }
                        });

                        setChartData3({
                              labels: labels,
                              datasets: [
                                    {
                                          label: 'Delivery Orders',
                                          data: DeliveryOrderData,
                                          borderWidth: 1,
                                          backgroundColor: "#619CFF",
                                          barThickness: 37,
                                          borderRadius: 9
                                    },
                                    {
                                          label: 'Pickup Orders',
                                          data: PickupOrderData,
                                          borderWidth: 1,
                                          backgroundColor: "#FEC84B",
                                          barThickness: 37,
                                          borderRadius: 9
                                    },
                                    {
                                          label: 'Dine-In Orders',
                                          data: DineInOrderData,
                                          borderWidth: 1,
                                          backgroundColor: "#F8766D",
                                          barThickness: 37,
                                          borderRadius: 9
                                    },
                              ],
                              totals: { DeliveryOrderDataTotal, PickupOrderDataTotal, DineInOrderDataTotal }
                        });

                        setChartData4_({
                              labels: labels,
                              datasets: [
                                    {
                                          label: 'Delivery Revenue',
                                          data: DeliverySalesData,
                                          borderWidth: 1,
                                          backgroundColor: "#619CFF",
                                          barThickness: 37,
                                          borderRadius: 9
                                    },
                                    {
                                          label: 'Pickup Revenue',
                                          data: PickupSalesData,
                                          borderWidth: 1,
                                          backgroundColor: "#FEC84B",
                                          barThickness: 37,
                                          borderRadius: 9
                                    },
                                    {
                                          label: 'Dine-In Revenue',
                                          data: DineInSalesData,
                                          borderWidth: 1,
                                          backgroundColor: "#F8766D",
                                          barThickness: 37,
                                          borderRadius: 9
                                    },
                              ],
                              totals: { DeliverySalesDataTotal, PickupSalesDataTotal, DineInSalesDataTotal }
                        })
                  })
                  .catch((err) => {
                        console.error("Error fetching sales chart data", err);
                  });
      };

      // console.log("branch_id2" ,branch_id2)

      const CurrencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);


      const dashboardCountNew = dashboardCountNew_?.dashboardCount
      const formatDate = (date) => moment(date).format('DD-MM-YYYY');
      

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content" style={{ marginTop: '-30px' }}
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <div className="row ">
                                                                  <div className="col-md-6 col-xs-12">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Dashboard
                                                                              </h1>
                                                                              <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                                                                              <Select
                                                                                    className="search-line mx-3"
                                                                                    placeholder={getPlaceholder(filteredOptions)}
                                                                                    options={getSelectOptions(filteredOptions)}
                                                                                    value={branch_id2}
                                                                                    onChange={(opt) => {
                                                                                          setbranch_id2(opt);
                                                                                          sessionStorage.setItem(`${urlDaynamic}_branch`, JSON.stringify(opt));
                                                                                    }}
                                                                                    styles={selectStyles}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end col-xs-12">
                                                                        <div
                                                                              className={!isMobile && "d-flex justify-content-end"}
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <RedirectLink dashboardURl={dashboardURl} />
                                                                              <QRCodeDownload dashboardURl={dashboardURl} />
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <TabNavigation2
                                                                  handleTabClick={handleTabClick}
                                                                  handleTabClickShow={handleTabClickShow}
                                                            />
                                                            <div className="tab-contentDash">
                                                                  <Row className="text-start row g-xl-12 mx-1">
                                                                        <Col className="date_">
                                                                              {Type === "FormTo" ? "" : <CalendarDateRegular style={{ fontSize: 32 }} />}
                                                                              {Type === "FormTo" ?
                                                                                    <div className="row mb-2">
                                                                                          <div className="col-md-2 my-1">
                                                                                                <label className="required form-label">
                                                                                                      From Date
                                                                                                </label>
                                                                                                <input
                                                                                                      type="date"
                                                                                                      className="form-control"
                                                                                                      defaultValue=""
                                                                                                      value={startDate}
                                                                                                      onChange={(e) => setStartDate(e.target.value)}
                                                                                                      required
                                                                                                      placeholder="DD-MM-YYYY"
                                                                                                />
                                                                                          </div>
                                                                                          <div className="col-md-2 my-1">
                                                                                                <label className="required form-label">
                                                                                                      To Date
                                                                                                </label>
                                                                                                <input
                                                                                                      type="date"
                                                                                                      className="form-control"
                                                                                                      value={endDate}
                                                                                                      onChange={(e) => setEndDate(e.target.value)}
                                                                                                      required
                                                                                                />
                                                                                          </div>
                                                                                    </div>
                                                                                    : <span className="mx-2">{dateRange}</span>}
                                                                        </Col>
                                                                  </Row>

                                                                  <DashboardCards2 salesData={dashboardCountNew}
                                                                        Type={TypeShow} startDate={startDate} endDate={endDate}
                                                                        drawerPos={drawerPos}
                                                                        CurrencyLabel={CurrencyLabel}
                                                                  />
                                                                  <div className="row g-xl-12 mx-1">
                                                                        <div className="col-xl-4" >
                                                                              <BusinessSummary CurrencyLabel={CurrencyLabel} salesData={dashboardCountNew} />
                                                                        </div>
                                                                        <div className="col-xl-4">
                                                                              <Top5Tender CurrencyLabel={CurrencyLabel} salesData={dashboardCountNew} />
                                                                              <TopHours CurrencyLabel={CurrencyLabel} salesData={dashboardCountNew} TypeShow={TypeShow} />
                                                                        </div>
                                                                        <div className="col-xl-4">
                                                                              <TopCustomers CurrencyLabel={CurrencyLabel} drawerPos={drawerPos} salesData={dashboardCountNew} />
                                                                              <StaffDetails  drawerPos={drawerPos}  salesData={dashboardCountNew}  TypeShow={TypeShow}/>
                                                                        </div>

                                                                        <div className="col-xl-4 my-8">
                                                                              <Bestsellers2  salesData={dashboardCountNew} />
                                                                        </div>

                                                                        <div className="col-xl-8 my-8">
                                                                              {ChartData1 !== undefined &&
                                                                                    <BarChart
                                                                                          chartData={ChartData1}
                                                                                          chartName={`${TypeShow} Sales`}
                                                                                          sidebarOpen={drawerPos}
                                                                                          Type={Type}
                                                                                          CurrencyLabel={CurrencyLabel}
                                                                                    />}
                                                                        </div>


                                                                        <div className="col-xl-6 mb-10">
                                                                              {ChartData3 !== undefined &&
                                                                                    <BarChat3
                                                                                          ChartData3={ChartData3}
                                                                                          Type={Type}
                                                                                          chartName={`${TypeShow} Orders By Type`}
                                                                                    />
                                                                              }
                                                                        </div>
                                                                        <div className="col-xl-6 mb-10">
                                                                              {ChartData4_ !== undefined &&
                                                                                    <BarChart4
                                                                                          ChartData4_={ChartData4_}
                                                                                          Type={Type}
                                                                                          chartName={`${TypeShow} Revenue By Type`}
                                                                                          CurrencyLabel={CurrencyLabel}
                                                                                    />}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <LoadingBackdrop open={dashboardCountNew_?.loading} />
                        <GroupAdminUperSide />
                  </div>
            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
      dashboardCountNew_: state?.dashboardCount
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
            getDashboardCount: (Type, branch_id) => dispatch(getDashboardCount(Type, branch_id)),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNew2);