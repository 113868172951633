import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import api from "../../../../../../CommonApi/axios";
import headers from "../../../../../../CommonApi/headers";
import Swal from "sweetalert2";
import $ from "jquery";
import useDrawer from "../../../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminEditAdditionChargesHeadingDineIn() {
  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  let history = useHistory();
  const { id } = useParams();

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );
  //////////////////// Edit Edition Chargis /////////////////////

  const [charges_heading_name_DE, setcharges_heading_name_DE] = useState("");
  const [charges_heading_name_EN, setcharges_heading_name_EN] = useState("");
  const [charges_heading_name_FR, setcharges_heading_name_FR] = useState("");
  const [charges_heading_name_IT, setcharges_heading_name_IT] = useState("");
  const [charges_heading_name_AR, setcharges_heading_name_AR] = useState("");

  useEffect(() => {
    ManagerDetailsGet();
  }, []);

  function ManagerDetailsGet() {
    api.get(`/groupadmin/charges/dinein/${id}`, { headers }).then((res) => {
      const tableData = res.data.data;

      setcharges_heading_name_DE(tableData.charges_heading_name_DE);
      setcharges_heading_name_EN(tableData.charges_heading_name_EN);
      setcharges_heading_name_FR(tableData.charges_heading_name_FR);
      setcharges_heading_name_IT(tableData.charges_heading_name_IT);
      setcharges_heading_name_AR(tableData.charges_heading_name_AR);
    });
  }

  function EdithandleSubmit(event) {
    event.preventDefault();
    setError(null);
    // if (pos_id === "") {
    //     setError('Pos ID is required');
    // }
    // else if (price === "") {
    //     setError('Price ID is required');
    // }
    // else {
    let item = {
      type: "dineIn",
      charges_heading_name_DE: charges_heading_name_DE,
      charges_heading_name_EN: charges_heading_name_EN,
      charges_heading_name_FR: charges_heading_name_FR,
      charges_heading_name_IT: charges_heading_name_IT,
      charges_heading_name_AR: charges_heading_name_AR,
    };

    // var item = {};
    // item['type'] = "delivery";
    // item['charges_heading_name_EN'] = charges_heading_name_EN
    // if (charges_heading_name_EN) {
    //     item['charges_heading_name_EN'] = charges_heading_name_EN
    // }
    // if (charges_heading_name_DE) {
    //     item['charges_heading_name_DE'] = charges_heading_name_DE
    // }
    // if (charges_heading_name_FR) {
    //     item['charges_heading_name_FR'] = charges_heading_name_FR
    // }
    // if (charges_heading_name_IT) {
    //     item['charges_heading_name_IT'] = charges_heading_name_IT
    // }

    api
      .patch(`/groupadmin/charges/headings/${id}`, item, { headers })
      .then((res) => {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Additional charges has been edited successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/group_admin/settings/dinein/dinein_additional_charges");
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.error.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });

    // }
  }
  //////////////////////// Edit Edition Chargis /////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div>
                    <h1 className="anchor fw-bolder mb-7" id="custom-form-control">
                      Edit Additional Charges
                    </h1>
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={EdithandleSubmit}>
                              <div className="row col-md-12">
                                <div className="mb-10">
                                  <h6>Additional Details Edit</h6>
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Name EN
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    required
                                    value={charges_heading_name_EN}
                                    onChange={(e) =>
                                      setcharges_heading_name_EN(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name AR</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={charges_heading_name_AR}
                                    onChange={(e) =>
                                      setcharges_heading_name_AR(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name GE</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={charges_heading_name_DE}
                                    onChange={(e) =>
                                      setcharges_heading_name_DE(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name IT</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={charges_heading_name_IT}
                                    onChange={(e) =>
                                      setcharges_heading_name_IT(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name FR</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={charges_heading_name_FR}
                                    onChange={(e) =>
                                      setcharges_heading_name_FR(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="col-md-12"> {errorDiv} </div>

                                <div className="modal-footer flex-center">
                                  <button
                                    type="submit"
                                    id="kt_modal_new_address_submit"
                                    className="btn btn-primary"
                                  >
                                    <span className="indicator-label">
                                      Save
                                    </span>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger m-2"
                                    onClick={() =>
                                      history.push(
                                        "/group_admin/settings/dinein/dinein_additional_charges"
                                      )
                                    }
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                  </div>
                </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
