import React from 'react';
import { Link } from "react-router-dom";

export default function CommonStock({ Type ,handleChangeType }) {
      const pathname = window.location.pathname;
      const pathnamesplits = pathname.split("/");
      const url = pathnamesplits[3];

      // Define an array of nav items
      const navItems = [
            { value : "view_stock_list",  name: "Stock List", path: "/group_admin/coupon_voucher_management/voucher_management/voucher_templates" },
            {  value : "view_stock_used", name: "Stock Used", path: "/group_admin/coupon_voucher_management/voucher_management/purchased_vouchers" },
      ];

      return (
            <>
                  <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        {navItems.map((item, index) => (
                              <li className="nav-item" key={index}>
                                    <Link

                                          onClick={() => {
                                                handleChangeType(item.value)
                                          }}
                                          className={Type === item.value ? "nav-link active" : "nav-link"}
                                          // to={item.path}
                                    >
                                          {item.name}
                                    </Link>
                              </li>
                        ))}
                  </ul>
            </>
      );
}
