import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { tableOption } from "../../NewTableCommon/tableOption";
import moment from "moment/moment";
import { HeaderStyle } from "./OrderManagement/TableCommonModule";
import { getMenuList } from "../../../Store/actions/menu/menu-action";
import { connect } from "react-redux";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../Comman/BranchList";
import { getBranchList } from "../../../Store";
import { Modal } from 'react-bootstrap';
import { MenuItem, Popover, Typography, Chip, ListItemIcon } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ColumPopoverCommon from "../../NewTableCommon/ColumPopoverCommon";
import { FileCopyOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import TableFilter from "../../NewTableCommon/tableFilter";
import useWindowHeight from "../../NewTableCommon/useWindowsPosition";
import "./menuManagmnet.css"
import renderStatusChip from "../../NewTableCommon/renderStatusChip";
import useDrawer from "../../../context/DrawerContext";
import Addnewdetails from "../../Comman/AddNewDetails/Addnewdetails";
import { MenuPermissions, userPermissionType } from "../../Comman/UserPermission";
import { selectStyles } from "../../Comman/CommonFunction/CommonFunction";

function GroupAdminMenuManagmentNewUi({ getMenuList, menuList, getBranchList, BranchList_ }) {
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[2];

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

      const userPermissionType_ = userPermissionType === "groupadmin"

      // console.log("userPermissionType_", userPermissionType_)


      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const history = useHistory();
      const [BranchId, setBranchID] = useState(branchName || "");
      const [CopyBranchId, setCopyBranchID] = useState("");
      const [menu_name, setmenu_name] = useState("");
      const [menuId, setmenuId] = useState("");
      const [error, setError] = useState(null);
      const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover


      const [FilterData, SetFilterData] = useState("");

      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };


      // console.log("FilterData", FilterData);
      const filteredMenuList = useMemo(() => {
            const status = FilterData?.Status?.value;
            if (BranchId && BranchId.value !== "All" && status) {
                  return menuList.filter(menu => menu.branch_id === BranchId.value &&
                        menu.is_active === (status === "Active"));
            } else if (BranchId && BranchId.value !== "All") {
                  return menuList.filter(menu => menu.branch_id === BranchId.value);
            } else if (status) {
                  return menuList.filter(menu => menu.is_active === (status === "Active"));
            }
            return menuList;
      }, [menuList, BranchId, FilterData?.Status?.value]);

      useEffect(() => {
            getMenuList();
            getBranchList();
      }, []);

      const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
      };

      const handleCloseMoreOption = (event) => {
            setAnchorEl(null);
      };

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };


      function handleSubmitCopyMenu(event) {
            event.preventDefault();
            setError(null);

            if (!CopyBranchId) {
                  setError("Please select branch.");
            } else if (menuId === "") {
                  setError("Please select menu.");
            } else if (!menu_name) {
                  setError("Please enter menu name.");
            } else {
                  let item = {
                        branch_id: CopyBranchId?.value,
                        menu_name: menu_name,
                        menu_id: menuId,
                  };

                  api
                        .post(`/groupadmin/menu/copy`, item, { headers })
                        .then((res) => {
                              handleClose()
                              Swal.fire({
                                    position: "top-center",
                                    icon: "success",
                                    title: "Menu details has been copied succesfully.",
                                    showConfirmButton: false,
                                    timer: 1500,
                              });
                              getMenuList();
                              // window.location.reload(true);
                              // setmenuId([]);
                        })
                        .catch((err) => {
                              if (err.response) {
                                    setError(err.response?.data?.error?.message);
                              }
                        });
            }
      }

      function handleSubmitActive(id, value) {

            let item = {
                  is_active: value,
            };

            api
                  .patch(`/groupadmin/menu/updateStatus/${id}`, item, { headers })
                  .then((res) => {
                        if (res.data.error) {
                        }
                        Swal.fire({
                              position: "top-center",
                              icon: "success",
                              title: `Menu status has been ${value ? "active" : "inactive"
                                    } succesfully.`,
                              showConfirmButton: false,
                              timer: 1500,
                        });
                        getMenuList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              toast.error(err?.response?.data?.error?.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                              });
                        }
                  });
      }


      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'menu_name',
                        header: 'Menu Name',
                        size: 300,
                        Cell: ({ row }) => {
                              const hasViewPermission = userPermissionType_ || MenuPermissions?.access?.includes("view");
                              return hasViewPermission ? (
                                    <Link
                                          to={{
                                                pathname: "/group_admin/menu_management/menu_information",
                                                state: { menuId: row?.original?._id, branchID: row?.original?.branch?._id },
                                          }}
                                    >
                                          <span style={HeaderStyle?.uniqueCodeHeader}>
                                                {row?.original?.menu_name}
                                          </span>
                                    </Link>
                              ) : (
                                    <span style={HeaderStyle?.uniqueCodeHeader}>
                                          {row?.original?.menu_name}
                                    </span>
                              );
                        },
                  }
                  ,

                  {
                        accessorKey: 'branchname',
                        header: 'Branch',
                        size: 270,
                        Cell: ({ row }) => row?.original?.branch?.branch_name_EN,
                        sortingFn: (rowA, rowB) => {
                              const dateA = rowA?.original?.branch?.branch_name_EN;
                              const dateB = rowB?.original?.branch?.branch_name_EN;

                              if (dateA < dateB) {
                                    return -1;
                              }
                              if (dateA > dateB) {
                                    return 1;
                              }
                              return 0;
                        }
                  },
                  {
                        accessorKey: 'created_at',
                        header: 'Created Date',
                        size: 270,
                        Cell: ({ row }) => {
                              // moment?.utc(row?.original?.created_at)?.format("DD/MM/YYYY h:mm A")
                              const formattedDate = moment?.utc(row?.original?.created_at)?.format("DD/MM/YYYY")
                              const formattedTime = moment?.utc(row?.original?.created_at)?.format("h:mm A")
                              return `${formattedDate}, ${formattedTime}`;
                        }
                  },
                  {
                        accessorKey: 'is_active',
                        header: 'Status',
                        size: 270,
                        Cell: ({ row }) => renderStatusChip(row?.original?.is_active),
                        // Cell: ({ row }) => (
                        // <div className="d-flex justify-content-start">
                        //       <button
                        //             type="button"
                        //             className={`btn btn-${row?.original?.is_active ? 'success' : 'Inactive'}`}
                        //             style={{ float: "right" }}
                        //             onClick={() => handleSubmitActive(row?.original?._id, !row?.original?.is_active)}
                        //       >
                        // {row?.original?.is_active ? 'Active' : 'Inactive'}
                        //       </button>
                        // </div>
                        // )
                  }
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const table = useMaterialReactTable({
            columns: Columns,
            data: filteredMenuList,
            ...tableOption({
                  getMenuList,
                  url23,
                  handleClickColum_,
                  handleSubmitActive,
                  handleFilterClick,
                  windowHeight,
                  filterData,
                  DisplayCol
            }),
      });


      const [show, setShow] = useState(false);

      const handleClose = () => {
            setmenu_name("");
            setBranchID("");
            setmenuId("");
            setShow(false)
      };

      const handleShow = () => setShow(true);

      const customStyles = {
            fontSize: '20px',
            letterSpacing: '0.1px',
            fontFamily: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
      };

      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


      return (
            <React.Fragment>
                  <ToastContainer
                        toastStyle={{
                              border: "1px solid black",
                              fontSize: "17px",
                        }}
                  />
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                {/* <div style={{height : windowHeight}}  id="kt_content_container" className="container-xxl"> */}
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Menus
                                                                              </h1>
                                                                              <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                                                                              <Select
                                                                                    className="search-line mx-3"
                                                                                    value={BranchId}
                                                                                    placeholder={getPlaceholder(filteredOptions)}
                                                                                    options={getSelectOptions(filteredOptions)}
                                                                                    onChange={(opt) => {
                                                                                          setBranchID(opt);
                                                                                          sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                                                                                    }}
                                                                                    styles={selectStyles}
                                                                              />



                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >

                                                                              {(userPermissionType_ || MenuPermissions?.access?.includes("create")) &&
                                                                                    <button onClick={handleClick} className="btn btn-danger mx-4">
                                                                                          <span className="me-2"> More Actions </span><KeyboardArrowDownOutlinedIcon />
                                                                                    </button>}

                                                                            
                                                                              <Popover
                                                                                    open={anchorEl}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleCloseMoreOption}
                                                                                    anchorOrigin={{
                                                                                          vertical: 'bottom',
                                                                                          horizontal: 'left',
                                                                                    }}
                                                                              >
                                                                                    <Typography sx={{ p: 1 }}>
                                                                                          <MenuItem className="email_" onClick={() => {
                                                                                                history.push("/group_admin/menu_management/generate_from_csv");
                                                                                                handleCloseMoreOption();
                                                                                          }}><ListItemIcon>
                                                                                                      <InsertDriveFileOutlined fontSize="small" />
                                                                                                </ListItemIcon>Generate from CSV</MenuItem>
                                                                                          <MenuItem className="email_" onClick={() => {
                                                                                                handleShow()
                                                                                                setError(null)
                                                                                                handleCloseMoreOption();
                                                                                          }}>
                                                                                                <ListItemIcon>
                                                                                                      <FileCopyOutlined fontSize="small" />
                                                                                                </ListItemIcon>Copy Menu</MenuItem>
                                                                                    </Typography>
                                                                              </Popover>
                                                                              {(userPermissionType_ || MenuPermissions?.access?.includes("create")) &&
                                                                                    <Addnewdetails
                                                                                          url={"/group_admin/menu_management/add_new_menu"}
                                                                                          Name="Add New Menu" />
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="mt-5">
                                                                  <MaterialReactTable
                                                                        table={table}
                                                                  /></div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>

                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={url23}
                  />

                  <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                  />

                  <Modal dialogClassName="modal-30w"
                        aria-labelledby="example-custom-modal-styling-title"
                        show={show} centered onHide={handleClose}>
                        <Modal.Body>
                              <Modal.Title className="text-center mb-2" style={customStyles} >Copy Menu</Modal.Title>
                              <form onSubmit={handleSubmitCopyMenu}>
                                    <div className="mb-5">
                                          <label

                                                className="required form-label"
                                          >
                                                Select Branch
                                          </label>
                                          <Select
                                                placeholder="Select Branch..."
                                                options={filteredOptions}
                                                onChange={(opt) => setCopyBranchID(opt)}
                                          />
                                    </div>
                                    <div className="mb-5">
                                          <label

                                                className="required form-label"
                                          >
                                                Select Menu
                                          </label>
                                          <Select
                                                className="search-line"
                                                placeholder="Select Menu ..."
                                                options={
                                                      menuList?.map((sponsor) => ({
                                                            label: sponsor.menu_name,
                                                            value: sponsor._id,
                                                      }))
                                                }
                                                onChange={(opt) => setmenuId(opt.value)}
                                          />
                                    </div>

                                    <div className="mb-5">
                                          <label

                                                className="required form-label"
                                          >
                                                Menu Name
                                          </label>
                                          <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Menu Name"
                                                value={menu_name}
                                                onChange={(e) => setmenu_name(e.target.value)}
                                          />
                                    </div>
                                    <div className="col-md-12"> {error && <div className="text-center alert alert-danger">{error}</div>} </div>
                                    <div className="flex-center d-flex">
                                          <button
                                                type="submit"
                                                id="kt_modal_new_address_submit"
                                                className="btn btn-primary"
                                          >
                                                <span className="indicator-label">Copy Menu</span>
                                          </button>
                                          <button
                                                type="button"
                                                onClick={handleClose}
                                                className="modal-footer btn btn-danger m-2"
                                          >
                                                Cancel{" "}
                                          </button>
                                    </div>
                              </form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      menuList: state.menuList,
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getMenuList: () => dispatch(getMenuList()),
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminMenuManagmentNewUi);

