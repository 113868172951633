import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import { Editor } from "@tinymce/tinymce-react";
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import api from "../../../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../../../CommonApi/headers";
import { ToastContainer, toast } from 'react-toastify';
import TyniAMC from "../../../../../../CommonApi/TyniAmc";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDrawer from "../../../../../../context/DrawerContext";
import CommonTourSetupSetting from "../CommonTourSetupSetting";
const TinyMCE_Api_Key = TyniAMC.TyniAMCAPIKEy;

window.jquery = window.$ = $;

function GroupAdminTourSetupInvoiceSetup() {
    let history = useHistory();
    let location = useLocation();
    var pathname = window.location.pathname;
    var pathnamesplits = pathname.split("/");
    var url = pathnamesplits[pathnamesplits.length - 1];

    const [errorForm, setErrorForm] = useState({});

    const [error, setError] = useState(null);


    const [formData, setFormData] = useState({
        invoice_prefix: '',
        invoice_header: '',
        invoice_footer_company: '',
        invoice_footer_payment: '',
    });
    const editorRefHeader = useRef(null);
    const editorRefCompany = useRef(null);
    const editorRefPayment = useRef(null);


    const handleInputChange = (e, fieldName) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Effect to fetch invoice setup details when location changes
    useEffect(() => {
        groupAdminTourInvoiceSetupDetails();
    }, [location]);

    // Function to fetch invoice setup details
    async function groupAdminTourInvoiceSetupDetails() {
        try {
            const invoiceDetails = await api.post(`/groupadmin/tourgroup/detail_invoice_setup`, {}, { headers });

            const invoiceDetailsData = invoiceDetails?.data?.data;
            setFormData(invoiceDetailsData);
        } catch (err) {
            if (err.response) {
                setError(err.response?.data?.error?.message);
            } else if (err.request) {
                setError(err.request);
            } else {
                setError(err.message);
            }
        }
    }

    // Function to handle editor change
    const handleEditorChange = (content, editor, fieldName) => {
        setFormData(prevState => ({ ...prevState, [fieldName]: content }));
    };

    // Function to handle form submission
    async function handleSubmit(event) {
        event.preventDefault();

        const errors = {};

        // Check for validation errors for each field
        if (!formData.invoice_prefix.trim()) {
            errors.invoice_prefix = 'Invoice Prefix is required.';
        }

        // Check if invoice_header is empty
        if (!formData.invoice_header.trim()) {
            errors.invoice_header = 'Header Details are required.';
        }

        // Check if invoice_footer_company is empty
        if (!formData.invoice_footer_company.trim()) {
            errors.invoice_footer_company = 'Company Details are required.';
        }

        // Check if invoice_footer_payment is empty
        if (!formData.invoice_footer_payment.trim()) {
            errors.invoice_footer_payment = 'Payment Details are required.';
        }

        // Update the error state with validation errors
        setErrorForm(errors);

        // If there are any errors, prevent form submission
        if (Object.keys(errors).length > 0) {
            return;
        }

        // Show loader
        Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const invoiceNewDetails = await api.post(`/groupadmin/tourgroup/add_update_invoice_setup`, formData, { headers });
            const invoiceNewDetailsData = invoiceNewDetails?.data?.data;
            setFormData(invoiceNewDetailsData);

            Swal.fire({
                position: "top-center",
                icon: 'success',
                title: 'Update Success',
                text: 'Invoice Details Update successfully!',
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
            })

            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1300);

        } catch (err) {
            if (err.response) {
                Swal.close()
                toast.error(err?.response?.data?.error?.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (err.request) {
                setError(err.request);
            } else {
                setError(err.message);
            }
            Swal.close();
        }
    }


    const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                    <h1
                                        className="anchor fw-bolder mb-5"
                                        id="vertical-horizontal-scroll"
                                    >
                                        Settings
                                    </h1>
                                    <hr />

                                    <div className="row col-md-12 mb-10" style={{ alignItems: 'center', }}>
                                        <div
                                            className="col-md-9 "
                                            data-kt-docs-table-toolbar="base"
                                        >
                                            <h3
                                                className="anchor fw-bolder mb-5 d-inline"
                                                id="custom-form-control"
                                            >
                                                Tour Setup - Invoice Setup
                                            </h3>
                                        </div>

                                    </div>

                                    <div className="py-5">
                                        <div className="rounded border p-10">
                                        <CommonTourSetupSetting />
                                            <div className="tab-content" id="myTabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="kt_tab_pane_1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card card-docs mb-2">


                                                        <div className="row card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">

                                                            <div className="col-12 mb-10">
                                                                <label htmlFor="invoice_prefix" className="required form-label fs-2">
                                                                    Invoice Prefix
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Invoice Prefix"
                                                                    name="invoice_prefix"
                                                                    value={formData.invoice_prefix}
                                                                    onChange={(e) => handleInputChange(e, 'invoice_prefix')}
                                                                    required
                                                                />
                                                                {errorForm && errorForm.invoice_prefix && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.invoice_prefix}</div>
                                                                )}

                                                            </div>
                                                            <div className="col-12 mb-10">
                                                                <label htmlFor="invoice_header" className="required form-label fs-2">
                                                                    Header Details
                                                                </label>
                                                                {/* TinyMCE editor for header */}
                                                                <div className="tinymce form-control form-control-lg border-0 px-0 pb-0">
                                                                    <Editor
                                                                        apiKey={TinyMCE_Api_Key}
                                                                        name="invoice_header"
                                                                        value={formData.invoice_header}
                                                                        onEditorChange={(content, editor) => handleEditorChange(content, editor, 'invoice_header')}
                                                                        onInit={(evt, editor) => (editorRefHeader.current = editor)}
                                                                        init={{
                                                                            height: 500,
                                                                            statusbar: false,
                                                                            toolbar: "bold italic forecolor | underline | removeformat",
                                                                            content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                                                                            auto_focus: "main_editor",
                                                                            relative_urls: false,
                                                                            entity_encoding: "raw",
                                                                            menubar: false,
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorForm && errorForm.invoice_header && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.invoice_header}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-12 mb-10">
                                                                <label htmlFor="invoice_footer_company" className="required form-label fs-2">
                                                                    Company Details
                                                                </label>
                                                                <div className="tinymce form-control form-control-lg border-0 px-0 pb-0">
                                                                    <Editor
                                                                        apiKey={TinyMCE_Api_Key}
                                                                        name="invoice_footer_company"
                                                                        value={formData.invoice_footer_company}
                                                                        onEditorChange={(content, editor) => handleEditorChange(content, editor, 'invoice_footer_company')}
                                                                        onInit={(evt, editor) => (editorRefCompany.current = editor)}
                                                                        required
                                                                        init={{
                                                                            height: 500,
                                                                            selector: ".tinymce",
                                                                            statusbar: false,
                                                                            toolbar: "bold italic forecolor | underline  | removeformat | testBTN | ",
                                                                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                                            auto_focus: "main_editor",
                                                                            relative_urls: false,
                                                                            entity_encoding: "raw",
                                                                            menubar: false,
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorForm && errorForm.invoice_footer_company && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.invoice_footer_company}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-12">
                                                                <label htmlFor="invoice_footer_payment" className="required form-label fs-2">
                                                                    Payment Details
                                                                </label>
                                                                <div className="tinymce form-control form-control-lg border-0 px-0 pb-0">
                                                                    <Editor
                                                                        apiKey={TinyMCE_Api_Key}
                                                                        name="invoice_footer_payment"
                                                                        value={formData.invoice_footer_payment}
                                                                        onEditorChange={(content, editor) => handleEditorChange(content, editor, 'invoice_footer_payment')}
                                                                        onInit={(evt, editor) => (editorRefPayment.current = editor)}
                                                                        required
                                                                        init={{
                                                                            height: 500,
                                                                            selector: ".tinymce",
                                                                            statusbar: false,
                                                                            toolbar: "bold italic forecolor | underline  | removeformat | testBTN | ",
                                                                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                                            auto_focus: "main_editor",
                                                                            relative_urls: false,
                                                                            entity_encoding: "raw",
                                                                            menubar: false,
                                                                        }}
                                                                    />
                                                                </div>
                                                                {errorForm && errorForm.invoice_footer_payment && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.invoice_footer_payment}</div>
                                                                )}
                                                            </div>

                                                            {error && (
                                                                <div className="col-12">
                                                                    <div className="text-center alert alert-danger">{error}</div>
                                                                </div>
                                                            )}
                                                            <div className="col-12 mt-15">

                                                                <div className="border-top text-center">

                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary mt-15 me-2"
                                                                        onClick={handleSubmit}
                                                                    >
                                                                        Save
                                                                    </button>

                                                                    <button
                                                                        type="button"
                                                                        onClick={() => history.goBack()}
                                                                        id="kt_modal_new_max_discount_value_submit"
                                                                        className="btn btn-danger mt-15 ml-2"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                        <GroupAdminUperSide />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}



export default GroupAdminTourSetupInvoiceSetup;

