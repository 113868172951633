import React, { useEffect, useMemo, useRef, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { tableOptionStockManagment } from "../../../NewTableCommon/tableOption";
import { getInventoryCatList, getStockList } from "../../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../../Comman/BranchList";
import { getBranchList } from "../../../../Store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../NewTableCommon/tableFilter";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import "../menuManagmnet.css"
import useDrawer from "../../../../context/DrawerContext";
import Addnewdetails from "../../../Comman/AddNewDetails/Addnewdetails";
import { StockPermissions, userPermissionType } from "../../../Comman/UserPermission";
import { selectStyles } from "../../../Comman/CommonFunction/CommonFunction";
import { HeaderStyle } from "../OrderManagement/TableCommonModule";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { commonTableOptions } from "../OrderManagement/commonTableOptions";
import { Modal } from "react-bootstrap";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { showToastCommon } from "../../../Comman/Toastify/Toastify";
import { event } from "jquery";



function StockManagement({ getBranchList, BranchList_, getInventoryCatList, inventoryCategoryList_ }) {
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[2];

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

      // console.log("branchName" ,branchName)

      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const [BranchId, setBranchID] = useState(branchName || "");

      const [isError, setIsError] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [isRefetching, setIsRefetching] = useState(false);
      const [columnFilters, setColumnFilters] = useState([]);
      const [globalFilter, setGlobalFilter] = useState('');
      const [sorting, setSorting] = useState([]);
      const [rowCount, setRowCount] = useState(0);
      const [pagination, setPagination] = useState("");
      const [stockList, setstockList] = useState([])
      const prevSorting = useRef([]);

      const [alertThreshold, setalertThreshold] = useState("");
      const [error, setError] = useState(null);
      const [EditData, setEditData] = useState("")
      // console.log("BranchId" ,BranchId)
      const [FilterData, SetFilterData] = useState("");

      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };

      const storedState = sessionStorage.getItem(`${url23}_paginationState`);

      useEffect(() => {
            if (storedState !== null) {
                  setPagination(JSON.parse(storedState));
            } else {
                  setPagination({ pageIndex: 0, pageSize: 10 });
            }
      }, [storedState]);


      const getStockList = async () => {
            setstockList([]);
            setIsLoading(true);
            setIsError(false)
            let url = `/groupadmin/branch_stock/listProduct?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let data = JSON.parse(sessionStorage.getItem(`${url23}_dineinfilterData`));
            if (BranchId?.value) {
                  url += `&branch_id=${BranchId?.value}`;
            }
            if (globalFilter) {
                  url += `&search=${globalFilter ?? ''}`;
            }

            if (FilterData) {
                  url += `&category_id=${FilterData?.Status?.value ?? ''}`;
            }

            if (sorting?.length > 0 && sorting[0]?.id) {
                  switch (sorting[0]?.id) {
                        case "branch":
                              url += `&sort_field=branch.branch_name_EN`;
                              break;
                        case "datetime":
                              url += `&sort_field=order_date`;
                              break;
                        // Add additional cases as needed
                        default:
                              url += `&sort_field=${sorting[0]?.id}`;
                              break;
                  }
            }

            if (sorting?.length > 0 && sorting[0]?.desc === true) {
                  url += `&sort_type=${'-1'}`;
            }
            if (sorting?.length > 0 && sorting[0]?.desc === false) {
                  url += `&sort_type=${'1'}`;
            }
            try {
                  const res = await api.get(url, { headers });
                  setRowCount(res.data.data?.count || 0);
                  setstockList(res.data.data?.stockData);
                  // console.log("ddd" ,res.data.data)
            } catch (err) {
                  if (err) {
                        setRowCount(0);
                        setIsError(true);
                  }
            } finally {
                  setIsLoading(false);
            }
      };

      const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
      prevSorting.current = sorting;

      useEffect(() => {
            if (pagination.pageIndex, pagination.pageSize) {
                  getStockList();
            }
      }, [pagination.pageIndex, pagination.pageSize, globalFilter ?? '', BranchId?.value,
            sortingChanged, FilterData?.Status?.value]);


      function arraysAreEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                  if (arr1[i] !== arr2[i]) return false;
            }
            return true;
      }

      const prevPaginationRef = useRef();

      useEffect(() => {
            if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
                  sessionStorage.setItem(`${url23}_paginationState`, JSON.stringify(pagination));
            }
            prevPaginationRef.current = pagination;
      }, [pagination]);


      useEffect(() => {
            getBranchList();
            getInventoryCatList();
      }, []);

      const inventoryCategoryList = inventoryCategoryList_?.inventoryCatagotyList

      // console.log("inventoryCategoryList" ,inventoryCategoryList)
      function handleSubmitEdit(data) {
            handleShow();
            // console.log("ss" ,data)
            setError("")
            setEditData(data)
            setalertThreshold(data?.alert_threshold_qty);
            // setcategory({
            //       value
            //             : data?._id,
            //       label: data?.category_name
            // });
      }

      const [show, setShow] = useState(false);

      const handleClose = () => {
            setalertThreshold("");
            setShow(false)
            setEditData("");
      };

      const handleShow = () => setShow(true);

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };


      const branchCurrency = (data) => {

            // console.log("filteredOptions" ,filteredOptions)
            // console.log("data" ,data)
            const result = filteredOptions?.find(item => item?.value === data)
            // console.log("result" ,result)
            return  result && result?.currency
      }

      let history = useHistory();

      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'inv_product_name',
                        header: 'Product',
                        size: 250,
                        Cell: ({ row }) => <span onClick={() =>
                              (userPermissionType === "groupadmin" || StockPermissions?.access?.includes("view")) &&
                              history.push(`/group_admin/stock_management/view_stock/${row?.original?._id}/${row?.original?.branch?._id}/${row?.original?.inv_category_id}/${row?.original?.inv_product_id}/${row?.original?.inv_product_name}/${row?.original?.unit}/${branchCurrency(row?.original?.branch?._id)}`)}
                              style={HeaderStyle?.uniqueCodeHeader}>
                              {row?.original?.inv_product_name}
                        </span>,
                  },
                  {
                        accessorKey: 'inventory',
                        header: 'Category',
                        size: 250,
                        Cell: ({ row }) => (row?.original?.inventory?.category_name),
                  },
                  {
                        accessorKey: 'total_quantity',
                        header: 'Total Quantity',
                        size: 250,
                        Cell: ({ row }) => `${row?.original?.total_quantity} ${row?.original?.unit}`,
                        // Cell: ({ row }) => `${row?.original?.unit === "gm" ? Number(row?.original?.total_quantity / 1000) : Number(row?.original?.total_quantity)} ${row?.original?.unit === "gm" ? "kg" : row?.original?.unit}`,
                  },
                  // {
                  //       accessorKey: 'price',
                  //       header: 'Price',
                  //       size: 250,
                  //       Cell: ({ row }) => Number(row?.original?.price).toFixed(2),
                  // },
                  {
                        accessorKey: 'branch',
                        header: 'Branch',
                        size: 250,
                        Cell: ({ row }) => (row?.original?.branch?.branch_name_EN),
                  },
                  {
                        accessorKey: 'alert_threshold_qty',
                        header: 'Minimum Quantity',
                        size: 250,
                        Cell: ({ row }) => `${row?.original?.alert_threshold_qty} ${row?.original?.unit}`
                        // Cell: ({ row }) => `${row?.original?.unit === "gm" ? Number(row?.original?.alert_threshold_qty / 1000) : Number(row?.original?.alert_threshold_qty)} ${row?.original?.unit === "gm" ? "kg" : row?.original?.unit}`,
                  },



            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      // const commonOptions = {
      //       isError,
      //       isRefetching,
      //       columnFilters,
      //       globalFilter,
      //       sorting,
      //       setColumnFilters,
      //       setGlobalFilter,
      //       setPagination,
      //       setSorting,
      //       rowCount,
      //       isLoading,
      //       pagination,
      //       handleClickColum,
      //       handleFilterClick,
      //       windowHeight,
      //       filterButtonColor,
      //       handleFilterColor,
      //       Type, handleShow, OrdermanagementList, handleShowMenu, showMenu
      //     };

      const table = useMaterialReactTable({
            columns: Columns,
            data: stockList,
            ...tableOptionStockManagment({
                  getStockList,
                  url23,
                  handleClickColum_,
                  handleFilterClick,
                  windowHeight,
                  filterData,
                  DisplayCol,
                  isError,
                  isRefetching,
                  columnFilters,
                  globalFilter,
                  sorting,
                  setColumnFilters,
                  setGlobalFilter,
                  setPagination,
                  setSorting,
                  rowCount,
                  isLoading,
                  pagination,
                  windowHeight,
                  handleSubmitEdit

            }),

            // ...commonTableOptions(commonOptions)
      });


      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

      function handleSubmitEditForm(event) {
            event.preventDefault()
            // console.log("data", data);
            // return

            const item = { alert_threshold_qty:alertThreshold  };
            api
                  .patch(`/groupadmin/branch_stock/update/${EditData?._id}`, item, { headers })
                  .then((res) => {
                              setError(null)
                        if (res.data.error) {
                        }
                        showSuccessNotification(`Products has been edited succesfully.`)
                        getStockList();
                        handleClose()
                  })
                  .catch((err) => {
                        if (err.response) {
                              setError(err?.response?.data?.error?.message)
                        }
                  });
      }



      return (
            <React.Fragment>
                  <ToastContainer
                        toastStyle={{
                              border: "1px solid black",
                              fontSize: "17px",
                        }}
                  />
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                {/* <div style={{height : windowHeight}}  id="kt_content_container" className="container-xxl"> */}
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Stock Management
                                                                              </h1>
                                                                              <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                                                                              <Select
                                                                                    className="search-line mx-3"
                                                                                    value={BranchId}
                                                                                    placeholder={getPlaceholder(filteredOptions)}
                                                                                    options={getSelectOptions(filteredOptions)}
                                                                                    onChange={(opt) => {
                                                                                          if (opt.label === "All Locations") {
                                                                                                getStockList();
                                                                                                setBranchID("");
                                                                                                sessionStorage.removeItem(`${url23}_branchName`)
                                                                                          } else {
                                                                                                setBranchID(opt);
                                                                                                sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                                                                                          }
                                                                                    }}

                                                                                    styles={selectStyles}
                                                                              />



                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >



                                                                              {(userPermissionType === "groupadmin" || StockPermissions?.access?.includes("create")) && (
                                                                                    <Addnewdetails
                                                                                          url={"/group_admin/stock_management/add_product"}
                                                                                          Name="Add Product" />
                                                                              )}

                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="mt-5">
                                                                  <MaterialReactTable
                                                                        table={table}
                                                                  /></div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>

                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={url23}
                  />

                  <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                        StockCategoryList={inventoryCategoryList}
                  />


                  <Modal dialogClassName="modal-30w"
                        aria-labelledby="example-custom-modal-styling-title"
                        show={show} centered onHide={handleClose}>
                        <Modal.Body>
                              <Modal.Title className="mb-2 heading">
                                    Edit Product</Modal.Title>
                              <form onSubmit={handleSubmitEditForm}>
                                    <div className="mb-5 mt-3">
                                          <label className="required form-label">Minimum Quantity</label>
                                          <div className="d-flex align-items-center">
                                                <input
                                                      type="number"
                                                      className="form-control form-control"
                                                      placeholder="Enter Minimum Quantity"
                                                      value={alertThreshold}
                                                      required
                                                      onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Prevent zero and allow only positive numbers
                                                            if (value !== "0") {
                                                                  setalertThreshold(value);
                                                            }
                                                          }}
                                                      // onChange={(e) => setalertThreshold(e.target.value)}
                                                />
                                                <span style={{ marginLeft: "-3rem", color: "#667085", fontSize: '14px', fontWeight: 400 }}
                                                      className="">{EditData?.unit}</span>
                                          </div>
                                    </div>
                                    <div className="col-md-12"> {error && <div className="text-center alert alert-danger">{error}</div>} </div>
                                    <div className="row flex-center d-flex">
                                          <div className="col-md-6">
                                                <button type="submit" className="btn btn-primary w-100">
                                                      Save
                                                </button>
                                          </div>
                                          <div className="col-md-6">
                                                <button
                                                      type="button"
                                                      onClick={handleClose}
                                                      className="btn btn-danger w-100"
                                                >
                                                      Cancel
                                                </button>
                                          </div>
                                    </div>

                              </form>
                        </Modal.Body>
                  </Modal>

            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
      stockList: state.stockList,
      inventoryCategoryList_: state
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
            // getStockList: () => dispatch(getStockList()),
            getInventoryCatList: () => dispatch(getInventoryCatList())
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockManagement);

