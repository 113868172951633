
export const HeaderSection = ({ title, goBackHandler }) => (
  <div className="d-flex flex-stack my-5">
    <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
      <h1 className="anchor fw-bolder" id="custom-form-control">{title}</h1>
    </div>
    <div className="d-flex flex-stack">
      <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
        <button onClick={goBackHandler} className="btn btn-primary">
          Go Back
        </button>
      </div>
    </div>
  </div>
);


