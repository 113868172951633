import React, { useState, useEffect } from "react";
import api from "../../../../../CommonApi/axios";
import Select from "react-select";
import { connect } from "react-redux";
import headers from "../../../../../CommonApi/headers";
import { setBranch, setBranchDetail } from "../../../../../Store";
import GroupAdminHeader from "../../../../Comman/Header";

function NoteState(props) {
  const [user, setuser] = useState();
  // const [branch_id, setbranch_id] = useState([]);
  // const [branch_id2, setbranch_id2] = useState([]);
  // console.log("branch_id", branch_id)
  // console.log("branch_id2", branch_id2)

  const { setBranch, branchID, setBranchDetail } = props;
  const [branch_, setBranch_] = useState(null);

  // console.log("branchID323", branchID);

  useEffect(() => {
    groupAdminBranchList();
  }, []);

  function groupAdminBranchList() {
    api
      .get(`/groupadmin/branch/restaurantwise`, { headers })
      .then((res) => {
        const filterData = res.data.data?.filter(branch => branch?.is_active);
        const options = filterData.map((item) => ({
          label: item.branch_name_EN,
          // label: item.address,
          value: item._id,
          branch_lat: item.lat,
          branch_long: item.long,
          currency : item?.currency
        }));
        setuser(options);
        // setbranch_id(res.data.data[0]._id);
        // setbranch_id2(options[0]);
        if (branchID == null) {
          setBranch_(options[0]);
          setBranchDetail(options[0]);
          setBranch(options[0].value);
        } else {
          let val = options.find((options) => options.value === branchID);
          setBranch_(val);
          setBranchDetail(val);
        }
        // console.log("res.data.data", res.data.data);
        // groupAdminDeliveryHolidayList();
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data);
        }
      });
  }

  return (
    <React.Fragment>
      <Select
        className="search-line"
        placeholder="Search..."
        options={
          !user == ""
            ? user.map((sponsor) => ({
                label: sponsor.label,
                value: sponsor.value,
              }))
            : "No Option"
        }
        // defaultValue={0}
        value={branch_}
        onChange={(opt) => {
          // setbranch_id(opt.value);
          // setbranch_id2(opt);
          setBranch(opt.value);
          setBranch_(opt);
          // console.log("sdfsdewrwe", opt);
          let val = user.find((user) => user.value === opt.value);
          // console.log("erwews", val);
          setBranchDetail(val);
        }}
        // onChange={setbranch_id}
      />
      <GroupAdminHeader data={branchID} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setBranch: (branch) => dispatch(setBranch(branch)),
    setBranchDetail: (data) => dispatch(setBranchDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteState);
