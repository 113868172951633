import { CMS } from "../../actions";

const headerListReducer = (state = [], action) => {
    switch (action.type) {
        case CMS.CLEAR_HEADER_LIST:
            return [];
        case CMS.SET_HEADER_LIST:
            return [...action?.headerlist];
        default:
            return state;
    }
}

const footerListReducer = (state = [], action) => {
    switch (action.type) {
        case CMS.CLEAR_FOOTER_LIST:
            return [];
        case CMS.SET_FOOTER_LIST:
            return [...action?.footerlist];
        default:
            return state;
    }
}

const colorDetailsReducer = (state = {}, action) => {
      switch (action.type) {
          case CMS.CLEAR_COLOR:
              return {};
          case CMS.SET_COLOR:
              return {...action?.colordetails};
          default:
              return state;
      }
  }

export { headerListReducer , footerListReducer ,colorDetailsReducer }