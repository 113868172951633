import React from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminFooter from "../../../Comman/Footer";
import GroupAdminUperSide from "../../../Comman/UperSide";
import GroupAdminSidebar from "../../../Comman/Sidebar";

import $ from "jquery";
window.jquery = window.$ = $;

export default function GroupAdminPOS_Lightspeed() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  var url2 = pathnamesplits[3];

  $(document).ready(function () {
    addProvisionalDiagnosis();
    function addProvisionalDiagnosis() {
      $("#sdsad").append(
        '<tr><td><select class="form-select" data-control="select2" data-placeholder="Select an option"><option selected disabled>Week Days</option><option >Week End</option><option >Sunday</option><option >Monday</option><option >Tuesday</option><option >Wednesday</option><option >Thursday</option><option >Friday</option><option >Saturday</option></select></td><td><input type="time" class="form-control form-control-solid" /></td><td><input type="time" class="form-control form-control-solid" /></td><td><a  id="deletebtn" class="btn btn-danger1 remCF" ><i class="fa fa-trash"></i></a></td></tr>'
      );
    }

    $(document).on("click", ".addtimes", function () {
      addProvisionalDiagnosis();
    });

    $("#sdsad").on("click", ".remCF", function () {
      $(this).parent().parent().remove();
    });

    addexholidays();

    function addexholidays() {
      $("#dev1").append(
        '<tr><td><input type="time" class="form-control form-control-solid" /></td><td><input type="time" class="form-control form-control-solid" /></td><td><a  id="deletebtn" class="btn btn-danger1 remCF1" ><i class="fa fa-trash" ></i></a></td></tr>'
      );
    }

    $(document).on("click", ".addex", function () {
      addexholidays();
    });

    $("#dev1").on("click", ".remCF1", function () {
      $(this).parent().parent().remove();
    });
  });

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div
                    className="d-inline justify-content-end"
                    data-kt-docs-table-toolbar="base"
                  >
                    <h3
                      className="anchor fw-bolder mb-5 d-inline"
                      id="custom-form-control"
                    >
                      POS/ Lightspeed K
                    </h3>
                  </div>
                  <div
                    className="d-inline justify-content-end"
                    data-kt-docs-table-toolbar="base"
                  >
                    <a
                      href=""
                      className="btn btn-primary ps-7"
                      data-kt-menu-trigger="click"
                      data-kt-menu-attach="parent"
                      data-kt-menu-placement="bottom-end"
                      style={{ float: "right" }}
                    >
                      Lausanne
                      <span className="svg-icon svg-icon-2 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </a>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-4 w-250px fs-6"
                      data-kt-menu="true"
                    ></div>
                  </div>
                  <br />
                  <br />
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="py-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="rounded border p-10">
                                  <div className="mb-10">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Auth URL
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Access Token URL
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      API URL
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                    />
                                  </div>
                                  <button
                                    id="kt_docs_formvalidation_text_submit"
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    <span className="indicator-label">
                                      Auth
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="rounded border p-10">
                                  <div className="mb-10">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Client ID
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Redirect
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Access Token(comes from API)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
    </React.Fragment>
  );
}
