import React from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import useDrawer from "../../../context/DrawerContext";

export default function GroupAdminSubscriptionManagement() {

const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack mb-5">
                      <h1
                        className="anchor fw-bolder mb-5"
                        id="vertical-horizontal-scroll"
                      >
                        Subscription Management
                      </h1>
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <a
                          href="javascript:void(0)"
                          className="btn btn-primary"
                        >
                          Pay
                        </a>
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="mb-10">
                            <label className="required form-label">
                              Select Plan
                            </label>
                            <select
                              className="form-select"
                              data-control="select2"
                              data-placeholder="Select an option"
                            >
                              <option selected disabled>
                                Select Your Plan
                              </option>
                              <option value="1">Option 1</option>
                              <option value="2">Option 2</option>
                            </select>
                          </div>

                          <h3
                            className="anchor fw-bolder mb-5"
                            id="vertical-horizontal-scroll"
                          >
                            Subscription History
                          </h3>
                          <br />
                          <br />
                          <br />

                          <div style={{ overflowX: "auto" }}>
                            <table
                              id="kt_datatable_example_5"
                              className="table table-striped table-row-bordered gy-5 gs-7"
                            >
                              <thead>
                                <tr
                                  className="fw-bold fs-6 text-gray-800"
                                  style={{ backgroundColor: "darkgray" }}
                                >
                                  <th className="min-w-25px">Sr No.</th>
                                  <th className="min-w-30px">Plan</th>
                                  <th className="min-w-30px">Purchased Date</th>
                                  <th className="min-w-30px">End Date</th>
                                  <th className="min-w-15px">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>3 months</td>
                                  <td>2022/06/21</td>
                                  <td>2022/09/21</td>
                                  <td>
                                    <i className="bi bi-trash fs-2"></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>

          <GroupAdminUperSide />
          <div className="modal fade" tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">Message</h5>
                  <p>Looking forward to visting you !</p>
                  <h5 className="modal-title">Submitted By</h5>
                  <p>June 21, 2022 4:30 PM</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
