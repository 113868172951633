import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import {STOCK, setStockList } from "../../actions";

function* callStockList() { 
  try {
      const response = yield call(api.get, "/groupadmin/branch_stock/list" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setStockList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type:STOCK.CLEAR_STOCK_LIST });
      }
    }
}

export function* watchStockList() {
  yield takeEvery(STOCK.GET_STOCK_LIST, callStockList);
}



