import { ORDER } from "../../actions";

const orderDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER.CLEAR_ORDER_DETAILS:
            return {};
        case ORDER.SET_ORDER_DETAILS:
            return {...action?.orderdetails};
        default:
            return state;
    }
}

export default orderDetailsReducer