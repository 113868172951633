import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { EMAILMARKETING, setEmailMarketingList,  } from "../../actions";

function* callEmailMarketingList() { 
  let item = {

  }
  try {
      const response = yield call(api.post, "/groupadmin/offers/listUserswithOffers" , item ,{ headers });
      const  tableData = response?.data;
      // console.log("tableData" ,tableData)
      yield put(setEmailMarketingList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: EMAILMARKETING.CLEAR_EMAILMARKETING_LIST });
      }
    }
}


export function* watchEmailMarketingList() {
  yield takeEvery(EMAILMARKETING.GET_EMAILMARKETING_LIST, callEmailMarketingList);
}

