import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import headers from "../../../../../CommonApi/headers";
import { useStyles } from "../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../Comman/TableOption";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import { getPrinterList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { BranchesPermissions, UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import { generateTableActions } from "../../../../Comman/TableActions";
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import Addnewdetails from "../../../../Comman/AddNewDetails/Addnewdetails";
function GroupAdminPrinterList({ getPrinterList, printerList }) {

  useEffect(() => {
    getPrinterList();
  }, []);

  const userPermissionType_ = userPermissionType === "groupadmin"


  let history = useHistory();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];


  const classes = useStyles();

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Branch Name",
      field: "branch_name_EN",
    },
    {
      title: "Printer Name",
      field: "printer_name",
    },
    {
      title: "Printer Category",
      // field: "printer_category_name",
      render: (rowData) => {
        return <div className="text-center">{rowData.printer_category_name ? rowData.printer_category_name : "--"}</div>;
      },
    },
    {
      title: "Ip Address",
      field: "ip_address",
    },

    {
      title: "Default Printer",
      render: (rowData) => {
        return (
          <div>
            {rowData.default_status === true ? (
              <button type="button" className="btn btn-success">
                Yes
              </button>
            ) : (
              <button type="button" className="btn btn-Inactive">
                No
              </button>
            )}
          </div>
        );
      },
    },
    {
      title: "Print Receipt",
      render: (rowData) => {
        return (
          <div>
            {rowData.enable_invoice === true ? (
              <button type="button" className="btn btn-success">
                Yes
              </button>
            ) : (
              <button type="button" className="btn btn-Inactive">
                No
              </button>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      render: (rowData) => {
        return (
          <div>
            {rowData.is_active == true ? (
              <button type="button" className="btn btn-success">
                Active
              </button>
            ) : (
              <button type="button" className="btn btn-Inactive">
                Inactive
              </button>
            )}
          </div>
        );
      },
    },

  ];

  function deleteBranch(_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/groupAdmin/branchPrinter/${_id}`, { headers })
          .then((result) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Printer has been deleted successfully.",
              showConfirmButton: false,
              timer: 1500,
            });
            getPrinterList();
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err?.response?.data?.error?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
      }
    });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const VIEW =  UserPermissionAllData?.role_details?.settings?.find(permission =>
    permission?.key === "printer")?.access?.includes("view")

  const DELETE =  UserPermissionAllData?.role_details?.settings?.find(permission =>
      permission?.key === "printer")?.access?.includes("delete")  

  const EDIT =  UserPermissionAllData?.role_details?.settings?.find(permission =>
        permission?.key === "printer")?.access?.includes("edit")    

  // console.log("EDIT" ,EDIT)

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Printer - Printer List
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >


                        {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                          permission?.key === "printer" &&
                          permission?.access?.includes("create")
                        )) && (
                            <Addnewdetails url="/group_admin/settings/printer/printerSettings" Name="Add Printer" />
                          )}
                      </div>
                    </div>

                    <div className="rounded border p-5">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerList",
                            }}
                            className={
                              url === "printerList"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Printer List
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerSettings",
                              
                            }}
                            className={
                              url === "printerSettings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                          Printer Settings
                          </Link>
                        </li> */}

                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerCategory",
                            }}
                            className={
                              url === "printerCategory"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Printer Category
                          </Link>
                        </li>

                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">

                              <div className={classes.root}>
                                <MaterialTable
                                  title=""
                                  data={printerList}
                                  columns={columns}
                                  options={Tableoptions}
                                  actions={
                                    userPermissionType === "groupadmin" ?
                                      [
                                        {
                                          icon: () => <VisibilityIcon />,
                                          tooltip: "View Details",
                                          onClick: (event, rowData) =>
                                            history.push(
                                              `/group_admin/settings/printer/printerDetails/${rowData._id}`
                                            ),
                                        },
                                        (rowData) => ({
                                          icon: "edit",
                                          tooltip: "Edit Printer",
                                          onClick: (event, rowData) =>
                                            history.push(
                                              `/group_admin/settings/printer/printerEdit/${rowData._id}`
                                            ),
                                          hidden: rowData.default_status === true,
                                        }),
                                        {
                                          icon: "delete",
                                          tooltip: "Delete Printer",
                                          onClick: (event, rowData) =>
                                            deleteBranch(rowData._id),
                                        },
                                      ] :
                                      [
                                        {
                                          icon: () => <VisibilityIcon />,
                                          tooltip: "View Details",
                                          hidden : VIEW ? false : true,
                                          onClick: (event, rowData) =>
                                            history.push(`/group_admin/settings/printer/printerDetails/${rowData._id}`),
                                        },
                                        (rowData) => (
                                          EDIT && {
                                          icon: () =>  <EditIcon /> ,
                                          tooltip: "Edit Printer",
                                          onClick: (event, rowData) =>
                                            history.push(`/group_admin/settings/printer/printerEdit/${rowData._id}`),
                                          hidden: rowData.default_status === true,
                                        }),
                                        {
                                          icon: () => <DeleteIcon /> ,
                                          tooltip: "Delete Printer",
                                          hidden : DELETE ? false : true,
                                          onClick: (event, rowData) =>
                                            deleteBranch(rowData._id),
                                        },
                                      ]
                                  }
                                />
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  printerList: state.printerList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPrinterList: () => dispatch(getPrinterList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPrinterList);


