import { tab } from "@testing-library/user-event/dist/tab";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import headers from "../../../../CommonApi/headers";
import GroupAdminUperSide from "../../../Comman/UperSide";
import moment from "moment";
import useDrawer from "../../../../context/DrawerContext";

export default function GroupAdminPendingEnquiryDetails() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  const history = useHistory();
  const { id } = useParams();

  const [response, setresponse] = useState("");
  const [InquryID, setInquryID] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [user, setUser] = useState("");

  useEffect(() => {
    PendingEnquriyDetilsGet();
  }, []);

  function PendingEnquriyDetilsGet() {
    api
      .get(`/groupAdmin/inquiry/${id}`, { headers })
      .then((res) => {
        const tableData = res.data.data?.inquiryData;
        setUser(tableData);
        setInquryID(res.data.data?.inquiryData?._id);
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.message);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    let item = {
      response: response,
    };
    if (InquryID != "") {
      api
        .patch(`/groupAdmin/inquiry/updateStatus/${InquryID}`, item, {
          headers,
        })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Response has been sent successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/group_admin/enquiry/pending_enquiry");
        })
        .catch((err) => {
          if (err.response) {
            setError(err?.response?.data?.error?.message);
          }
        });
    }
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header pt-5 border-1">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                       <h1 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder fs-3 mb-1">
                              Pending Enquiry Details
                            </span>
                          </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                        </div>
                        </div>
                        <div className="card-body pt-1 pb-0">
                          <form onSubmit={handleSubmit}>
                            <div className="modal-body pt-10 px-lg-17">
                              <div
                                className="scroll-y me-n7 pe-7"
                                id="kt_modal_new_address_scroll"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_modal_new_address_header"
                                data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
                                data-kt-scroll-offset="300px"
                              ></div>
                              <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-bold mb-2">
                                    Name :
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid border-dark"
                                    value={user?.name}
                                    name="name"
                                    readOnly
                                  />
                                </div>
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-bold mb-2">
                                    Phone Number :
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid border-dark"
                                    name="pho_number"
                                    value={user?.phone_number}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-bold mb-2">
                                    Email Address :
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control form-control-solid border-dark"
                                    value={user?.email}
                                    name="email"
                                    readOnly
                                  />
                                </div>
                                <div className="col-md-6 fv-row">
                                  <label className="fs-5 fw-bold mb-2">
                                    Branch name :
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid border-dark"
                                    value={user?.branch?.branch_name_EN}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column mb-5 fv-row">
                                <label className="fs-5 fw-bold mb-2">
                                  Enquiry Date :
                                </label>
                                <input
                                  className="form-control form-control-solid border-dark"
                                  value={moment(user?.created_at
                                    ).format("DD/MM/YYYY")}
                                  readOnly
                                />
                              </div>
                              <div className="d-flex flex-column mb-5 fv-row">
                                <label className="fs-5 fw-bold mb-2">
                                  Subject :
                                </label>
                                <input
                                  className="form-control form-control-solid border-dark"
                                  value={user?.subject}
                                  readOnly
                                />
                              </div>
                              <div className="d-flex flex-column mb-5 fv-row">
                                <label className="fs-5 fw-bold mb-2">
                                  Message :
                                </label>
                                <textarea
                                  id="question_description"
                                  className="tinymce form-control form-control-lg form-control-solid border-dark"
                                  name="description"
                                  rows={5}
                                  value={user?.message}
                                  readOnly
                                />
                              </div>
                              <div className="fv-row mb-5">
                                <label className="required fs-6 fw-bolder mb-2">
                                  Response :
                                </label>
                                <textarea
                                  id="question_description"
                                  className="tinymce form-control form-control-lg form-control-solid border-dark"
                                  name="description"
                                  rows={5}
                                  value={response}
                                  required
                                  onChange={(e) => setresponse(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-md-12"> {errorDiv} </div>
                            <div className="modal-footer flex-center p-0">
                              <button
                                type="submit"
                                className="btn btn-primary m-2"
                              >
                                Submit
                              </button>
                              <button
                                onClick={() =>
                                  history.push(
                                    "/group_admin/enquiry/pending_enquiry"
                                  )
                                }
                                className="btn btn-danger m-2"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
