import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../Comman/Header";
import GroupAdminSidebar from "../Comman/Sidebar";
import GroupAdminUperSide from "../Comman/UperSide";
import api from "../../CommonApi/axios";
import headers from "../../CommonApi/headers";
import $ from "jquery";
import useDrawer from "../../context/DrawerContext";
import { applyFilterMeHandlers } from "../Comman/Error/jqueryUtils";
import { showSuccessNotification } from "../Comman/Swal/Swal";
import { ErrorMessage } from "../Comman/Error/ErrorMessage";
import { validateEmail } from "../Comman/AddCommonFun/validation";
window.jquery = window.$ = $;

export default function GroupAdminProfile() {
 
  useEffect(()=>{
    applyFilterMeHandlers();
  },[])

  const [restaurant_name, setrestaurant_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [profile_pic, setprofile_pic] = useState(null);
  const [url, seturl] = useState("");

  const [current_password, setcurrent_password] = useState("");
  const [new_password, setnew_password] = useState("");
  const [Confirmpassword, setConfirmpassword] = useState("");

  const [picture, setPicture] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setprofile_pic(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);

  useEffect(() => {
    groupAdminDataGate();
  }, []);

  function groupAdminDataGate() {
    api
      .get(`/groupadmin/profile`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setrestaurant_name(tableData.restaurant_name);
        setphone_number(tableData.phone_number);
        setprofile_pic(tableData.profile_pic);
        seturl(tableData.url);
      })
      .catch((err) => {
        if (err.response) {
          // console.log(err?.response?.data?.error?.message);
          // if("Authentication Failed. Please login again." === err?.response?.data?.error?.message){
          //   localStorage.removeItem("customToken");

          //   alert("ss")
          //   localStorage.removeItem("RGALOGO");
          //   history.push("/");
          //   window.location.reload(true);
          // }
        }
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();

    if (
      restaurant_name.toString().trim().length < 0 ||
      restaurant_name.toString().trim().length < 3
    ) {
      $(".loader").hide();
      setError("Name Must Be at least 3 character Long");
    }
    else {
      const formData = new FormData();
      formData.append("restaurant_name", restaurant_name);
      formData.append("phone_number", phone_number);
      formData.append("profile_pic", picture);
      api
        .patch(`/groupadmin/profile`, formData, { headers })
        .then((res) => {
          $(".loader").hide();
          showSuccessNotification("Profile has been updated successfully.")
          window.location.reload(true);
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  function handleSubmitPassword(event) {
    event.preventDefault();
    setError1(null);

    if (
      !validateEmail(current_password) ||
      current_password.toString().trim().split(" ").join("").length < 6
    ) {
      setError1(
        "Old Password Must Be at least 6 character Long & doesn't contain any Space."
      );
    } else if (
      !validateEmail(new_password) ||
      new_password.toString().trim().split(" ").join("").length < 6
    ) {
      setError1(
        "New Password Must Be at least 6 character Long & doesn't contain any Space."
      );
    } else if (
      !validateEmail(Confirmpassword) ||
      Confirmpassword.toString().trim().split(" ").join("").length < 6
    ) {
      setError1(
        "Confirmpassword must be at least 6 character long & doesn't contain any Space."
      );
    } else if (new_password !== Confirmpassword) {
      setError1("New Password And Confirm Password Dose Not Match");
    } else {
      let items = {
        current_password: current_password,
        new_password: new_password,
      };
      api
        .patch(`/groupadmin/profile/changePassword`, items, { headers })
        .then((res) => {
          showSuccessNotification("Password has been changed successfully.");
          window.location.reload(true);
          // }, 1500);
        })
        .catch((err) => {
          if (err.response) {
            setError1(err.response?.data?.error?.message);
          } else if (err.request) {
            setError1(err.request);
          } else {
            setError1(err.message);
          }
        });
    }
  }

  function EdithandleSubmitImage(event) {
    event.preventDefault();

    setprofile_pic("");
    const formData = new FormData();
    formData.append("profile_pic", "");

    api
      .patch(`/groupadmin/profile/removephoto`, formData, {
        headers,
      })
      .then((res) => {
        //  window.location.reload(true);
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Profile Details</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_profile_details"
                      className="collapse show"
                    >
                      <form onSubmit={handleSubmit} className="form">
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                              Profile Image
                            </label>
                            <div className="col-lg-2">
                              <div className="text-center user-info mb-4 image-input-wrapper w-125px h-125px">
                                <div
                                  className="image-input image-input-outline"
                                  data-kt-image-input="true"
                                >
                                  <img
                                    className="image-input-wrapper w-125px h-125px"
                                    src={
                                      profile_pic
                                        ? profile_pic
                                        : "/assets/media/svg/3377061_food_hor_mok_thai_icon.png"
                                    }
                                    alt="profile_img"
                                    height="125px"
                                    width="125px"
                                  ></img>
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    // title="Change avatar"
                                  >
                                    <i className="bi bi-pencil-fill fs-7" />
                                    <input
                                      type="file"
                                      name="avatar"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={onChangePicture}
                                    />
                                  </label>
                                  {profile_pic && (
                                    <React.Fragment>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                        data-kt-image-input-action="cancel"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                      >
                                        <i
                                          className="bi bi-x fs-2"
                                          onClick={EdithandleSubmitImage}
                                        />
                                      </span>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                        data-kt-image-input-action="remove"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                        s
                                        title="Remove"
                                      >
                                        <i
                                          onClick={EdithandleSubmitImage}
                                          className="bi bi-x fs-2"
                                        />
                                      </span>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              <div className="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 required col-form-label fw-bold fs-6">
                              Restaurant Name
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Enter Restaurant Name"
                                value={restaurant_name}
                                required
                                onChange={(e) =>
                                  setrestaurant_name(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="row mb-6">
                            <label className="col-lg-4 required col-form-label fw-bold fs-6">
                              Phone Number
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="text"
                                placeholder="Enter Your Phone Number"
                                className="filterme form-control form-control-lg form-control-solid"
                                // maxLength="10"
                                // minLength="10"
                                value={phone_number}
                                required
                                onChange={(e) =>
                                  setphone_number(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                              Url Slug
                            </label>
                            <div className="col-lg-8 fv-row">
                              <button
                                style={{ fontSize: "18px" }}
                                type="button"
                                className="btn btn-secondary form-control form-control-lg form-control-solid text-start"
                                // target="_blank"
                                onClick={() => window.open(`http://${url}`)}
                              >
                                {url && `http://${url}`}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 text-center">
                          <br />
                          <div className="loader" style={{ display: "none" }}>
                            <img src="/assets/loader.gif" />
                          </div>
                        <ErrorMessage error={error}/>
                        </div>

                        <div className="card-footer d-flex justify-content-center py-6 px-9">
                          <button
                            type="reset"
                            onClick={()=>window.location.reload()}
                            className="btn btn-info me-2"
                          >
                            Discard
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  </main>
                </div>
              </div>

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Password Change</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_profile_details"
                      className="collapse show"
                    >
                      <form onSubmit={handleSubmitPassword} className="form">
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="required col-lg-4 col-form-label fw-bold fs-6">
                              Old Password
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="password"
                                className="noSpace form-control form-control-lg form-control-solid"
                                placeholder="Enter Your Old Password"
                                // value={current_password}
                                required
                                onChange={(e) =>
                                  setcurrent_password(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="required col-lg-4 col-form-label fw-bold fs-6">
                              New Password
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="password"
                                placeholder="Enter Your New Password"
                                className="noSpace form-control form-control-lg form-control-solid"
                                // value={new_password}
                                required
                                onChange={(e) =>
                                  setnew_password(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="required col-lg-4 col-form-label fw-bold fs-6">
                              Confirm Password
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="password"
                                className="noSpace form-control form-control-lg form-control-solid"
                                placeholder="Enter Your Confirm password"
                                // value={Confirmpassword}
                                required
                                onChange={(e) =>
                                  setConfirmpassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        <ErrorMessage error={error1}/>
                        </div>

                        <div className="card-footer d-flex justify-content-center py-6 px-9">
                          <button
                            type="reset"
                            onClick={()=>window.location.reload()}
                            className="btn btn-info me-2"
                          >
                            Discard
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
