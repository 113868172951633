import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const DashboardCards2 = ({ salesData, Type, startDate, endDate, drawerPos, CurrencyLabel }) => {

    const convertBestHourToDuration = (bestHour) => {
        const startOfDay = moment("00:00:00", "HH:mm:ss");
        const bestHourMoment = moment(bestHour, "HH:mm:ss");
        const duration = moment.duration(bestHourMoment.diff(startOfDay));

        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        return `${hours}h ${minutes}m`;
    };

    const start = new Date(startDate);
    const end = new Date(endDate ? endDate : new Date());
    const differenceInTime = Math.abs(end - start);
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    const BestHour = (Type === "Today" || Type === "Yesterday" || differenceInDays < 1) ? {
        value: salesData?.best_date_time_sales?.best_hour || 0,
        currencyValue: Number(salesData?.best_date_time_sales?.max_sales_hour || 0).toFixed(2)
    } : {
        value: salesData?.best_date_time_sales?.day ? `${moment(salesData?.best_date_time_sales?.day).format('DD/MM/YY')}` : 0,
        currencyValue: Number(salesData?.best_date_time_sales?.max_sales_day || 0).toFixed(2)
    };

    const CardData = [
        { title: `${Type} Revenue`, value: Number(salesData.total_revenue || 0).toFixed(2), currencyLabel: CurrencyLabel },
        { title: `${Type} Orders`, value: salesData.total_orders || 0, currencyLabel: '' },
        { title: `Revenue per cover`, value: salesData?.revenue_per_table || 0, currencyLabel: `${CurrencyLabel}/ cover` },
        { title: `Revenue per ticket`, value: salesData?.revenue_per_order || 0, currencyLabel: `${CurrencyLabel}/ ticket` },
        { title: 'Avg Table Occupancy', value: salesData?.average_table_occupancy || 0, currencyLabel: 'per table/ hour' },
        { title: `${(Type === "Today" || Type === "Yesterday" || differenceInDays < 1) ? "Best Hour" : "Best Day Ever"}`, value: BestHour?.value, currencyLabel: `${CurrencyLabel} ${BestHour?.currencyValue}` },
    ];


    return (
        <div className="row g-xl-12 mt-6 mx-1">
            {CardData?.map((card, index) => (
                <div className="col-xl-2" key={index}>
                    <Link to="#" className="card hoverable card-xl-stretch mb-xl-8">
                        <div className="frame8__">
                            <div className="overlap-group">
                                <div className={drawerPos === 1 ? "text-wrapper" : "text-wrapperNano" }>{card?.title}</div>
                            </div>
                            <p className="element-CHF">
                                <span className="span">{card?.value}</span>
                            </p>
                            <div className="text-wrapper-3">{card?.currencyLabel}</div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default DashboardCards2;
