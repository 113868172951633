import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { VOUCHERTEM, setVoucherTemList } from "../../actions";

function* callVoucherTemList() { 
  try {
      let item = {};
      const response = yield call(api.post, "/groupAdmin/available_voucher/List",item, { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setVoucherTemList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: VOUCHERTEM.CLEAR_VOUCHERTEM_LIST });
      }
    }
}


export function* watchVoucherTemList() {
  yield takeEvery(VOUCHERTEM.GET_VOUCHERTEM_LIST, callVoucherTemList);
}

