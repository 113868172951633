import { ADDONPROPERTIES } from "../action-types";


const getAddonList = () => ({
      type: ADDONPROPERTIES.GET_ADD_ON,
});


const setgetAddonList = (addonlist) => ({
      type: ADDONPROPERTIES.SET_ADD_ON,
      addonlist,
});


export {
      getAddonList, setgetAddonList,
};
