import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import api from "../../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import headers from "../../../../../../CommonApi/headers";
import $ from "jquery";
import useDrawer from "../../../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminEditAdditionChargesPickup() {
  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  let history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  //////////////////// Edit Edition Chargis /////////////////////

  const [charge_id, setcharge_id] = useState("");
  const [pos_id, setpos_id] = useState("");
  const [price, setprice] = useState("");
  const [name_DE, setname_DE] = useState("");
  const [name_EN, setname_EN] = useState("");
  const [name_FR, setname_FR] = useState("");
  const [name_IT, setname_IT] = useState("");
  const [name_AR, setname_AR] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  useEffect(() => {
    AdditionChargesDetailsGet();
  }, [location]);

  function AdditionChargesDetailsGet() {
    api
      .get(`/groupadmin/charges/pickup/${location.state.branch_id}`, {
        headers,
      })
      .then((res) => {
        const tableData = res?.data?.data?.charges?.filter(
          (inner) => inner._id === id
        );
        setpos_id(tableData[0]?.pos_id);
        setprice(tableData[0]?.price);
        setname_FR(tableData[0]?.name_FR);
        setname_EN(tableData[0]?.name_EN);
        setname_DE(tableData[0]?.name_DE);
        setname_IT(tableData[0]?.name_IT);
        setname_AR(tableData[0]?.name_AR);
      });
  }

  function EdithandleSubmit(event) {
    event.preventDefault();
    setError(null);

    // if (pos_id === "") {
    //     setError('Pos ID is required');
    // }
    // else
    if (price === "") {
      setError("Price is required");
    } else {
      let item = {
        charge_id: id,
        type: "pickup",
        charges: {
          pos_id: pos_id,
          price: price,
          name_EN: name_EN,
          name_DE: name_DE,
          name_FR: name_FR,
          name_IT: name_IT,
          name_AR: name_AR,
        },
      };

      api
        .patch(`/groupadmin/charges/${location.state.branch_id}`, item, {
          headers,
        })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Additional charges has been edited successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push(
            "/group_admin/settings/pickup/pickup_additional_charges"
          );
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  //////////////////////// Edit Edition Chargis /////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-7"
                      id="custom-form-control"
                    >
                      Edit Additional Charges
                    </h1>
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={EdithandleSubmit}>
                              <div className="mb-10">
                                <h6>AddCharges Details Edit</h6>
                              </div>
                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Name EN
                                  </label>
                                  <input
                                    type="text"
                                    required
                                    className="form-control form-control-solid"
                                    value={name_EN}
                                    onChange={(e) => setname_EN(e.target.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name AR</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={name_AR}
                                    onChange={(e) => setname_AR(e.target.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name GE</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={name_DE}
                                    onChange={(e) => setname_DE(e.target.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Name FR</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={name_FR}
                                    onChange={(e) => setname_FR(e.target.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6 ">
                                  <label className="form-label">Name IT</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={name_IT}
                                    onChange={(e) => setname_IT(e.target.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">Pos Id</label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    value={pos_id}
                                    onChange={(e) => setpos_id(e.target.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Price
                                  </label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    required
                                    value={price}
                                    onChange={(e) => setprice(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12"> {errorDiv} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() =>
                                    history.push(
                                      "/group_admin/settings/pickup/pickup_additional_charges"
                                    )
                                  }
                                >
                                  {" "}
                                  Cancel{" "}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
