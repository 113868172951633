import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { VOUCHERPURCHASED, setVoucherPurchasedList } from "../../actions";

function* callVoucherPurchasedList() { 
  try {
      let item = {};
      const response = yield call(api.post, "/groupAdmin/purchase_voucher/List",item, { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setVoucherPurchasedList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: VOUCHERPURCHASED.CLEAR_VOUCHERPURCHASED_LIST });
      }
    }
}


export function* watchVoucherPurchasedList() {
  yield takeEvery(VOUCHERPURCHASED.GET_VOUCHERPURCHASED_LIST, callVoucherPurchasedList);
}

