import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link, useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { Card, Col, Row } from "react-bootstrap";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminDiscountManagement() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  let history = useHistory();
  let id = useParams();

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[3];

  useEffect(() => {
    groupAdminVoucherList();
  }, []);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [voucher, serVoucher] = useState([]);
  const [_ID, set_ID] = useState("");
  const [voucher_category ,setvoucher_category] = useState("")
  const [voucher_title ,setvoucher_title] = useState("Gift Voucher");
  const [salutation_title ,setsalutation_title] = useState("Dear");
  const [VoucherOrderType ,setVoucherOrderType] = useState("")
  const [voucher_validity ,setvoucher_validity] = useState("");
  const [voucher_validity_type ,setvoucher_validity_type] = useState('');
  const [ID, setID] = useState("");


  function groupAdminVoucherList() {
    let item = {};

    api
      .post(`/groupAdmin/available_voucher/List`, item, { headers })
      .then((res) => {
        const tableData = res.data.data;
        serVoucher(tableData);
        console.log(tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  function groupAdminVoucherStatusUpdate(value ,ID) {
    // event.preventDefault()
    let item = {
      is_active  : value
    };
    console.log("item" ,item);

    api
      .patch(`/groupAdmin/available_voucher/${ID}`, item, { headers })
      .then((res) => {
        groupAdminVoucherList();
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: `Voucher has been ${value == true ? "Active" : "Inactive"} successfully`,
          showConfirmButton: false,
          timer: 1500,
        });
        // const tableData = res.data.data;
        // serVoucher(tableData);
        // console.log(tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }



  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

  
   
     if (voucher_category == "") {
      setError("Please select voucher category");
      return;
      }
      else if (VoucherOrderType == "") {
        setError("Please select voucher order type");
        return;
        }
     else if (voucher_title == "") {
      setError("Please enter voucher title");
      return;
      }
     else if (salutation_title == "") {
      setError("Please enter salutationa title");
      return;
      }
      else if (voucher_validity == "") {
        setError("Please select voucher validity");
        return;
      }
      else if (voucher_validity == "") {
        setError(`Please enter altest 1 ${voucher_validity_type}`);
        return;
      }
      else if (voucher_validity == 0) {
      setError(`Please enter altest 1 ${voucher_validity_type}`);
      return;
    }
    else {
      $(".loader").show();

      const item = new FormData();
      item.append("voucher_category", voucher_category);
      item.append("voucher_title", voucher_title);
      item.append("salutation_title", salutation_title);
      item.append("voucher_validity", Number(voucher_validity));
      item.append("voucher_validity_type", voucher_validity_type);
      item.append("order_type", VoucherOrderType)
      
      api
        .patch(`/groupAdmin/available_voucher/${_ID}`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Voucher has been edited succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload(true);
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }
    
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="content d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="d-flex flex-stack">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h1
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                         Discount Management
                      </h1>
                    </div>
                  </div>

                  <div className={isMobile ? "" : "rounded border p-10"}>
                    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                      <li className="nav-item">
                        <Link
                          className={
                            url === "discout_management"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/discount_and_tips_management/discout_management",
                          }}
                        >
                          Discount Management
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            url === "reasons_management"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/discount_and_tips_management/reasons_management",
                          }}
                        >
                          Reasons
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_1"
                        role="tabpanel"
                      >
                        <React.Fragment>
                             
                                    <Card>
                                    <Card.Body>
                                    <Card.Title> Discount Management</Card.Title>

                                    <Row className="my-1">
                                          <Col lg={3} md={3} xs={3}>
                                          <div className="rounded border p-5">
                                                <div className="mb-1">
                                                <input className="form-check-input" type="checkbox" />
                                                <label className="form-check-label mx-3">
                                                      <h6>Amount</h6>
                                                </label>
                                                </div>
                                                </div>
                                          </Col>
                                          <Col lg={3} md={3} xs={3}>
                                          <div className="rounded border p-5">
                                                <div className="mb-1">
                                                <input className="form-check-input" type="checkbox" />
                                                <label className="form-check-label mx-3">
                                                      <h6>Percentage</h6>
                                                </label>
                                                </div>
                                                </div>
                                          </Col>
                                          <Col lg={3} md={3} xs={3}>
                                          </Col>
                                          <Col lg={3} md={3} xs={3}>
                                          </Col>

                                    <Row className="mt-10">
                                          <Col lg={3} md={3} xs={3}>

                                          <label className="required form-label">
                                          Categories
                                          </label>
                                          <h3 className="mt-3">Food</h3>
                                          </Col>
                                           <Col lg={3} md={3} xs={3}>
                                          <label className="required form-label">
                                          Maximum Percentage
                                          </label>
                                                <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Enter Amount"
                                                required
                                                //   value={address}
                                                //   onChange={(e) => setaddress(e.target.value)}
                                                />                
                                          </Col>
                                          <Col lg={3} md={3} xs={3}>

                                          <label className="required form-label">
                                          Maximum Percentage
                                          </label>
                                                <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Enter Percentage"
                                                required
                                                //   value={address}
                                                //   onChange={(e) => setaddress(e.target.value)}
                                                />                
                                          </Col>
                                          <Col lg={3} md={3} xs={3}>
                                          </Col>
                                    </Row>
                                    
                                    <Row className="mt-10">
        <Col lg={3} md={3} xs={3}>
          <label className="required form-label">
            Categories
          </label>
          <h3 className="mt-3">Soft Drinks</h3>
        </Col>
        <Col lg={3} md={3} xs={3}>
          <label className="required form-label">
            Maximum Percentage
          </label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder="Enter Amount"
            required
          />
        </Col>
        <Col lg={3} md={3} xs={3}>
          <label className="required form-label">
            Maximum Percentage
          </label>
          <input
            type="text"
            className="form-control form-control-solid"
            placeholder="Enter Percentage"
            required
          />
        </Col>
        <Col lg={3} md={3} xs={3}></Col>
                                    </Row>

                                    {/* Alcoholic Drinks */}
                                    <Row className="mt-10">
                                    <Col lg={3} md={3} xs={3}>
                                    <label className="required form-label">
                                          Categories
                                    </label>
                                    <h3 className="mt-3">Alcoholic Drinks</h3>
                                    </Col>
                                    <Col lg={3} md={3} xs={3}>
                                    <label className="required form-label">
                                          Maximum Percentage
                                    </label>
                                    <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Amount"
                                          required
                                    />
                                    </Col>
                                    <Col lg={3} md={3} xs={3}>
                                    <label className="required form-label">
                                          Maximum Percentage
                                    </label>
                                    <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Percentage"
                                          required
                                    />
                                    </Col>
                                    <Col lg={3} md={3} xs={3}></Col>
                                    </Row>

                                    {/* All */}
                                    <Row className="mt-10">
                                    <Col lg={3} md={3} xs={3}>
                                    <label className="required form-label">
                                          Categories
                                    </label>
                                    <h3 className="mt-3">All</h3>
                                    </Col>
                                    <Col lg={3} md={3} xs={3}>
                                    <label className="required form-label">
                                          Maximum Percentage
                                    </label>
                                    <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Amount"
                                          required
                                    />
                                    </Col>
                                    <Col lg={3} md={3} xs={3}>
                                    <label className="required form-label">
                                          Maximum Percentage
                                    </label>
                                    <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Percentage"
                                          required
                                    />
                                    </Col>
                                    <Col lg={3} md={3} xs={3}></Col>
                                    </Row>
                                    </Row>
                                    

                              <div className="modal-footer flex-center mt-7">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => history.goBack()}
                                >
                                  {" "}
                                  Cancel{" "}
                                </button>
                              </div>
                                    </Card.Body>
                                    </Card>
                                    
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <label
                    
                    className="form-label"
                  >
                    Voucher Catagory
                  </label>
                  <Select
                            className="search-line"
                            placeholder="Select..."
                            value={[
                                  { label: `${voucher_category}` },
                                ]}
                                options={
                                  [
                                    { value: 'Best Wishes', label: 'Best Wishes' },
                                    { value: 'General', label: 'General' },
                                    { value: 'Birthday', label: 'Birthday' },
                                    { value: 'Wedding', label: 'Wedding' },
                                    { value: 'Festivals', label: 'Festivals' },
                                    { value: 'Love', label: 'Love' },
                                    { value: 'New Born', label: 'New Born' },
                                    { value: 'Housewarming', label: 'Housewarming' },
                                    { value: 'Retirement', label: 'Retirement' },
                                    { value: 'Thank You', label: 'Thank You' },
                                    { value: 'Congrats', label: 'Congrats' },
                                    { value: 'Anniversary', label: 'Anniversary' },

                                  ]
                                }
                                onChange={(opt) => setvoucher_category(opt.value)}
                              />
                </div>

                <div className="mb-5">
                  <label
                    
                    className="form-label"
                  >
                    Voucher Order type

                  </label>
                  <Select
                                 value={[
                                  { label: `${VoucherOrderType === "all" ? "All" : 
                                  VoucherOrderType === "delivery" ? "Delivery" : 
                                  VoucherOrderType === "pickup" ? "Pickup"  :"Dine In" }` },
                                ]}
                                className="search-line"
                                placeholder="Select..."
                                options={[
                                  {
                                    label: "All",
                                    value: "all",
                                  },
                                  {
                                    label: "Delivery",
                                    value: "delivery",
                                  },
                                  {
                                    label: "Pickup",
                                    value: "pickup",
                                  },
                                  {
                                    label: "Dine In",
                                    value: "dineIn",
                                  },
                                ]}
                                onChange={(opt) => setVoucherOrderType(opt.value)}
                              />
                </div>

                <div className="mb-5">
                  <label
                    
                    className="required form-label"
                  >
                    Voucher Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    // placeholder="Enter amount"
                    value={voucher_title}
                    onChange={(e) => setvoucher_title(e.target.value)}
                  />
                </div>
                <div className="mb-5">
                  <label
                    
                    className="required form-label"
                  >
                    Salutation Title
                  </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter validity"
                      value={salutation_title}
                      onChange={(e) => setsalutation_title(e.target.value)}
                      aria-describedby="basic-addon2"
                    />
                  
                </div>

                <div className="mb-5">
                  <label
                    
                    className="required form-label"
                  >
                    Voucher Validity
                  </label>
                  <Select
                              
                                className="search-line my-1"
                                placeholder="Select..."
                                value={[
                                  { label: `${voucher_validity_type === "days" ? "Day" : "Month"}` },
                                ]}
                                options={
                                  [
                                    { value: 'months', label: 'Month' },
                                    { value: 'days', label: 'Day' },
                                  ]
                                }
                                onChange={(opt) => setvoucher_validity_type(opt.value)}
                              />

                    <input
                      type="text"
                      className="filterme form-control mt-4"
                      placeholder="Enter validity"
                      value={voucher_validity}
                      onChange={(e) => setvoucher_validity(e.target.value)}
                      aria-describedby="basic-addon2"
                    />
                  
                </div>
                <div className="col-md-12 text-center">
                  <br />
                  <div className="loader" style={{ display: "none" }}>
                    <img src="/assets/loader.gif" />
                    {/* <img src="" alt="logo" /> */}
                  </div>
                </div>
                <div className="col-md-12"> {errorDiv} </div>
                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="btn btn-primary"
                  >
                    Edit Voucher
                  </button>
                  <button
                    type="button"
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


