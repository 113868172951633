import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { COUPON, setCouponList, } from "../../actions";

function* callCouponList() { 
  try {
      let item = {};
      const response = yield call(api.post, "/groupadmin/coupon/List" ,item, { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setCouponList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: COUPON.CLEAR_COUPON_LIST });
      }
    }
}


export function* watchCouponList() {
  yield takeEvery(COUPON.GET_COUPON_LIST, callCouponList);
}

