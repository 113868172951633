import React from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';


export default function BackButtonForProdctu({name}) {

      const {productid ,catagoryid ,menuId ,branchID } = useParams();


  let history = useHistory();

  const handleClick = () => {
    if (name === "Go Back") {
      history.push(`/group_admin/menu_management/menu_category/menu_products/${catagoryid}/${menuId}/${branchID}`);
    } else {
      history.push(`/group_admin/menu_management/menu_category/menu_products/product_details_information/${productid}/${catagoryid}/${menuId}/${branchID}`);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={name === "Go Back" ? "btn btn-primary ps-7" : "btn btn-danger m-2"}
    >
      {name}
    </button>
  );
}
