import { MENU } from "../action-types";


const getMenuList = () => ({
  type: MENU.GET_MENU_LIST,
});


const setMenuList = (menulist) => ({
  type: MENU.SET_MENU_LIST,
  menulist,
});


export {
      getMenuList , setMenuList
};
