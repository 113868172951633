import React from 'react'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function CommonDetailsPage() {

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[5];

  const {productid ,catagoryid ,menuId ,branchID } = useParams();


  return (
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_information/${productid}/${catagoryid}/${menuId}/${branchID}`,
            // state: {
            //   productID: location.state.productID,
            //   branchID: location.state.branchID,
            //   menuId: location.state.menuId,
            //   category_id: location.state.category_id,
            // },
          }}
          className={
            url === "product_details_information"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Information
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_discriptaion/${productid}/${catagoryid}/${menuId}/${branchID}`,
            // state: {
            //   productID: location.state.productID,
            //   branchID: location.state.branchID,
            //   menuId: location.state.menuId,
            //   category_id: location.state.category_id,
            // },
          }}
          className={
            url === "product_details_discriptaion"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Description
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_time_filter/${productid}/${catagoryid}/${menuId}/${branchID}`,
            // state: {
            //   productID: location.state.productID,
            //   branchID: location.state.branchID,
            //   menuId: location.state.menuId,
            //   category_id: location.state.category_id,
            // },
          }}
          className={
            url === "product_details_time_filter"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Time Filter
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_options/${productid}/${catagoryid}/${menuId}/${branchID}`,
            // state: {
            //   productID: location.state.productID,
            //   branchID: location.state.branchID,
            //   menuId: location.state.menuId,
            //   category_id: location.state.category_id,
            // },
          }}
          className={
            url === "product_details_options"
              ? "nav-link active"
              : "nav-link"
          }
        >
      Options
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_additional_products/${productid}/${catagoryid}/${menuId}/${branchID}`,
            // state: {
            //   productID: location.state.productID,
            //   branchID: location.state.branchID,
            //   menuId: location.state.menuId,
            //   category_id: location.state.category_id,
            // },
          }}
          className={
            url === "product_details_additional_products"
              ? "nav-link active"
              : "nav-link"
          }
        >
         Additional Products
        </Link>
      </li>
      {/* <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_recipe/${productid}/${catagoryid}/${menuId}/${branchID}`,
          
          }}
          className={
            url === "product_details_recipe"
              ? "nav-link active"
              : "nav-link"
          }
        >
         Recipe
        </Link>
      </li> */}
      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_category/menu_products/product_details_stock/${productid}/${catagoryid}/${menuId}/${branchID}`,
          }}
          className={
            url === "product_details_stock"
              ? "nav-link active"
              : "nav-link"
          }
        >
         Stock
        </Link>
      </li>
    </ul>
  )
}
