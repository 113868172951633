import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "../../Component/Setting/UserManagment/UserManagmentCommon";
import useDrawer from "../../../../context/DrawerContext";
import { getBranchList } from "../../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "../../../Comman/BranchList";


function GroupAdminViewUserManagment({ getBranchList, BranchList_ }) {

  const id = useParams();
  let history = useHistory();
  const [user, setuser] = useState("");

  useEffect(() => {
    getBranchList()
  }, [getBranchList])

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const filteredOptions = branchfilterAndMapData(BranchList_);

  const style = {
    color: "#344054",
    fontSize: "16px",
    fontWeight: 500
  }


  useEffect(() => {
    UserDetailsGet();
  }, [id]);

  function UserDetailsGet() {
    api.get(`/groupadmin/user/${id?.id}`, { headers }).then((res) => {
      const tableData = res.data.data;
      setuser(tableData);
      // console.log("sss", tableData)
    });
  }

  // Optimize the branch matching logic
  const matchedOptions = filteredOptions?.filter(opt => user?.branch_ids?.includes(opt.value)) || [];

  // Render the matched branches
  const BranchList = () => (
    <ul>
      {matchedOptions.length > 0 && (
        matchedOptions.map((branch, index) => (
          <li key={index}>{branch?.label}</li>
        ))
      )}
    </ul>
  );

  // Optimize user information mapping
  const userInfo = [
    { label: 'User Name', value: user?.name || "N/A" },
    { label: 'Phone Number', value: user?.phone_number || "---" },
    { label: 'Email', value: user?.email || "---" },
    { label: 'User Role', value: user?.role_name || "N/A" },
    { label: 'Branch', value: <BranchList /> || "---" },
  ];


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          User Management
                        </h1>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            history.push(
                              "/group_admin/settings/user_management"
                            )
                          }
                        >
                          Go Back
                        </button>
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <UserManagmentCommon />
                        <div className="row py-5">
                          <div
                            className="col-md-9"
                          >
                            <h3
                              className="anchor fw-bolder mb-5 "
                            >
                              User Details
                            </h3>
                          </div>
                          <div
                            className="col-md-3 text-end"
                          >
                            <button
                              className="btn btn-primary mx-2"
                              onClick={() =>
                                history.push(
                                  `/group_admin/settings/user_management/edit_user/${id?.id}`
                                )
                              }
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                        <div className="card card-docs mb-2">
                          <div className="card-body fs-6 pt-5 px-5 px-lg-15 text-gray-700">
                            <div className="row col-md-12">
                              {userInfo?.map((info, index) => (
                                <React.Fragment key={index}>
                                  <div className="col-md-3 my-9">
                                    <h5 style={{ color: "#344054", fontWeight: 500, fontSize: "14px" }}>{info.label}</h5>
                                  </div>
                                  <div style={style} className="col-md-9 my-8">
                                    {info.value}
                                  </div>
                                  {index < userInfo.length - 1 && <hr />}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminViewUserManagment);



