import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";

export default function GroupAdminGroupSetup() {
  let history = useHistory();
  const userPermissionType_ = userPermissionType === "groupadmin"

  /////////////// Get Custome Property ///////////////////

  const [logo, setlogo] = useState("");
  const [Icon, setIcon] = useState("");
  const [email, setemail] = useState("");
  const [welcome_copy_DE, setwelcome_copy_DE] = useState("");
  const [welcome_copy_EN, setwelcome_copy_EN] = useState("");
  const [welcome_copy_FR, setwelcome_copy_FR] = useState("");
  const [welcome_copy_IT, setwelcome_copy_IT] = useState("");
  const [welcome_copy_AR, setwelcome_copy_AR] = useState("");
  const [non_highlighted_text, setnon_highlighted_text] = useState("");
  const [button_colour, setbutton_colour] = useState("");
  const [custom_id, setcustom_id] = useState("");


  const [imglogo, setImglogo] = useState(null);
  const onChangelogo = (e) => {
    if (e.target.files[0]) {
      setImglogo(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setlogo(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [imgIcon, setImgIcon] = useState(null);
  const onChangeIcon = (e) => {
    if (e.target.files[0]) {
      setImgIcon(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setIcon(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    GroupAdminSetup();
  }, []);

  // useEffect(() => {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   link.href = 'https://stackoverflow.com/favicon.ico';
  // }, []);

  function GroupAdminSetup() {
    api
      .get(`/groupadmin/settings/setup/getDetails`, { headers })
      .then((res) => {
        const tableData = res?.data?.data?.setDoc;
        const custome = res?.data?.data?.customDoc
        console.log("custome", res?.data?.data)
        setwelcome_copy_AR(tableData?.welcome_copy_AR);
        setwelcome_copy_EN(tableData?.welcome_copy_EN);
        setwelcome_copy_DE(tableData?.welcome_copy_DE);
        setwelcome_copy_FR(tableData?.welcome_copy_FR);
        setwelcome_copy_IT(tableData?.welcome_copy_IT);
        setemail(tableData?.from_email);
        setlogo(tableData?.logo_url ? tableData?.logo_url : "");
        setbutton_colour(custome?.button_colour ? custome?.button_colour : '');
        setnon_highlighted_text(custome?.non_highlighted_text ? custome?.non_highlighted_text : '')
        setIcon(custome?.icon_url ? custome?.icon_url : '')
        setcustom_id(custome._id ? custome._id : "");
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data?.error?.message);
        }
      });
  }

  const [loader, setloader] = useState(false);
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // console.log("button_colour" ,button_colour)

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    setloader(true);

    if (!validateEmail(email)) {
      setError("Invalid Email");
      setloader(false);
      return;
    } else {
      const formData = new FormData();

      if (logo) {
        formData.append("logo", imglogo);
      }
      if (email) {
        formData.append("from_email", email);
      }
      formData.append("welcome_copy_EN", welcome_copy_EN);
      formData.append("welcome_copy_DE", welcome_copy_DE);
      formData.append("welcome_copy_AR", welcome_copy_AR);
      formData.append("welcome_copy_FR", welcome_copy_FR);
      formData.append("welcome_copy_IT", welcome_copy_IT);

      api
        .patch(`/groupadmin/settings/setup/editDetails`, formData, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          GroupAdminSetup();
          updateProfile();
          setloader(false);
        })
        .catch((err) => {
          setloader(false);
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });

      const item = new FormData();

      if (Icon) {
        item.append("icon", imgIcon);
      }
      if (non_highlighted_text) {
        item.append("non_highlighted_text", non_highlighted_text);
      }
      if (button_colour) {
        item.append("button_colour", button_colour);
      }
      if (custom_id) {
        api
          .patch(`/groupadmin/custom/${custom_id}`, item, { headers })
          .then((res) => {
            setloader(false);
            console.log(res.data);
          })
          .catch((err) => {
            if (err.response) {
              setError(err.response?.data?.error?.message);
            }
          });
      } else {
        api
          .post(`/groupadmin/custom`, item, { headers })
          .then((res) => {
            setloader(false);
          })
          .catch((err) => {
            setloader(false);
            if (err.response) {
              setError(err.response?.data?.error?.message);
            } setError(err.message);

          });
      }
    }
  }

  function updateProfile(logo) {
    const profile = {
      ...JSON.parse(localStorage.getItem("RGALOGO")),
      ...logo,
    };
    localStorage.setItem("RGALOGO", JSON.stringify(profile));
  }

  function EdithandleSubmitImage(event) {
    event.preventDefault();

    setlogo("");
    const formData = new FormData();
    formData.append("logo", "");

    api
      .patch(`/groupadmin/settings/setup/removeLogo`, formData, {
        headers,
      })
      .then((res) => {
        // localStorage.setItem(
        //   "RGALOGO",
        //   "/assets/media/logos/Kitchen Parameter Logo (2).png"
        // );

        window.location.reload(true);
      })
      .catch((err) => {
        if (err.response) {
          setError(err?.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} data={logo} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />
                    <div className="d-flex flex-stack my-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1 className="anchor fw-bolder" id="custom-form-control">
                          Group Setup
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.push("/group_admin/settings")}
                            className="btn btn-primary ps-7"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="py-5">
                      <form onSubmit={handleSubmit}>
                        <div className="card card-docs">
                          <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="rounded border p-10">
                                  <h6>Logo</h6>
                                  <div
                                    className="image-input image-input-outline mt-7 mb-20"
                                    data-kt-image-input="true"
                                  >
                                    <img
                                      className="image-input-wrapper w-125px h-125px"
                                      src={
                                        logo
                                          ? logo
                                          : "/assets/media/avatars/blank.png"
                                        // : "/assets/media/logos/Kitchen Parameter Logo (2)."

                                      }
                                      alt=""
                                      height="125"
                                      width="125"
                                    />
                                    <label
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="change"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                    >
                                      <i className="bi bi-pencil-fill fs-7" />
                                      <input
                                        type="file"
                                        name="file"
                                        onChange={onChangelogo}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </label>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="cancel"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                    >
                                      <i
                                        onClick={EdithandleSubmitImage}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="remove"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      title="Remove"
                                    >
                                      <i
                                        onClick={EdithandleSubmitImage}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <br />
                              </div>
                              <div className="col-md-8">
                                <div className="rounded border p-10">
                                  <div className="row col-md-12">
                                    <div className="col-md-6">
                                      <div className="mb-5">
                                        <label className="required form-label">
                                          Welcome message EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter welcome message EN"
                                          value={welcome_copy_EN}
                                          required
                                          onChange={(e) =>
                                            setwelcome_copy_EN(e.target.value)
                                          }
                                        />
                                      </div>{" "}
                                    </div>
                                    <div className="col-md-6">
                                      {" "}
                                      <div className="mb-5">
                                        <label className=" form-label">
                                          Welcome message AR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter welcome message AR"
                                          value={welcome_copy_AR}
                                          onChange={(e) =>
                                            setwelcome_copy_AR(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-5">
                                        <label className=" form-label">
                                          Welcome message DE
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter welcome message DE"
                                          value={welcome_copy_DE}
                                          onChange={(e) =>
                                            setwelcome_copy_DE(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      {" "}
                                      <div>
                                        <label className=" form-label">
                                          Welcome message FR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter welcome message FR"
                                          value={welcome_copy_FR}
                                          onChange={(e) =>
                                            setwelcome_copy_FR(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div>
                                        <label className=" form-label">
                                          Welcome message IT
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter welcome message IT"
                                          value={welcome_copy_IT}
                                          onChange={(e) =>
                                            setwelcome_copy_IT(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-md-12 my-2">
                                <div className="rounded border p-10">
                                  <div className="mb-5">
                                    <label className="required form-label">
                                      Email
                                    </label>
                                    <input
                                      required
                                      type="email"
                                      className="form-control form-control-solid"
                                      placeholder="Enter email address"
                                      value={email}
                                      onChange={(e) => setemail(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="row">
                              <div className="col-md-4 my-3">
                                <div className="rounded border p-10">
                                  <h6>Icon</h6>
                                  <div
                                    className="image-input image-input-outline mt-3"
                                    data-kt-image-input="true"
                                  >
                                    <img
                                      className="image-input-wrapper w-125px h-125px"
                                      src={
                                        Icon
                                          ? Icon
                                          : "/assets/media/avatars/blank.png"

                                      }
                                      alt=""
                                      height="125"
                                      width="125"
                                    />
                                    <label
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="change"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                    >
                                      <i className="bi bi-pencil-fill fs-7" />
                                      <input
                                        type="file"
                                        name="file"
                                        onChange={onChangeIcon}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </label>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="cancel"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                    >
                                      <i
                                        // onClick={EdithandleSubmitImage}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="remove"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      title="Remove"
                                    >
                                      <i
                                        // onClick={EdithandleSubmitImage}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <br />
                              </div>
                              <div className="col-md-8 my-3">
                                <div className="rounded border p-10">
                                  <div className="mb-10">
                                    <label className=" form-label">
                                      Text color
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={non_highlighted_text}
                                      onChange={(e) =>
                                        setnon_highlighted_text(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div>
                                    <label className="form-label">
                                      Button Colour
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={button_colour}
                                      onChange={(e) =>
                                        setbutton_colour(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-md-12"> {errorDiv} </div>
                          <div className="col-md-12 text-center">
                            {loader ? <CircularProgress /> : ""}
                          </div>
                          <div className="modal-footer flex-center">

                            {(userPermissionType_ ||
                              UserPermissionAllData?.role_details?.settings?.find(permission =>
                                permission?.key === "group_setup" &&
                                (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                              )) && (
                                <button type="submit" className="btn btn-primary">
                                  <span className="indicator-label">Save</span>
                                </button>)}
                            <button
                              onClick={() =>
                                history.push("/group_admin/settings")
                              }
                              className="btn btn-danger ps-7"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}
