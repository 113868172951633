import React from 'react';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const renderStatusChip = (status) => {
  let color = '';
  let label = '';
  let background = '';

  switch (status) {
    case 'completed':
      color = '#027A48';
      label = 'Completed';
      background = '#ECFDF3';
      break;
    case 'payment_approved':
      color = '#175CD3';
      label = 'Payment Approved';
      background = '#EFF8FF';
      break;
    case 'pending':
      background = '#FFFAEB';
      label = 'Pending';
      color = "#B54708";
      break;
    default:
      color = 'default';
      label = 'Unknown';
      break;
  }

  return (
    <Chip
      icon={<FiberManualRecordIcon style={{ color: color, fontSize: 13 }} />}
      label={label}
      style={{ color: color, background: background, fontSize: 13 }}
    />
  );
};

export default renderStatusChip;
