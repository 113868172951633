import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import NoteState from "../CommanBranch/NoteState";
import CommonDelivery from "./CommonDelivery";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { SubmitformButton } from "../../../../Comman/Error/SubmitformButton";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";

function GroupAdminDeliverySetting({ branchID }) {
  const history = useHistory();
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const [formState, setFormState] = useState({
    order_email: "",
    order_interval: "",
    order_per_15_mins: "",
    payment_method_online: false,
    payment_method_cash: false,
    order_days_in_advance: "",
    delivery_active: false,
    ordering_notes: false,
    automatic_order_approval: "auto" // use a single property to manage order approval
  });

  const [error, setError] = useState(null);
  const [ID, setID] = useState(null);

  useEffect(() => {
    applyFilterMeHandlers();
  }, []);

  useEffect(() => {
    if (branchID) {
      fetchSettings();
    }
  }, [branchID]);

  const fetchSettings = async () => {
    try {
      const res = await api.get(`/groupadmin/delivery_settings/${branchID}`, { headers });
      const data = res.data.data || {};

      setFormState({
        order_email: data.order_email || "",
        order_interval: data.order_interval || "",
        order_per_15_mins: data.order_per_15_mins || "",
        payment_method_online: data.payment_method_online || false,
        payment_method_cash: data.payment_method_cash || false,
        order_days_in_advance: data.order_days_in_advance || "",
        delivery_active: data.delivery_active || false,
        ordering_notes: data.order_notes_active || false,
        automatic_order_approval: data.automatic_order_approval !== undefined ? data.automatic_order_approval === true ? "auto" : "manual" : "auto" // fetch order approval state
      });
      setID(data._id || null);
    } catch (err) {
      console.error(err.response?.data?.error?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleApprovalChange = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      automatic_order_approval: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!branchID) {
      setError("Please Select Branch");
      return;
    }

    const payload = {
      branch_id: branchID,
      order_email: formState.order_email,
      order_days_in_advance: Number(formState.order_days_in_advance),
      order_interval: Number(formState.order_interval),
      order_per_15_mins: Number(formState.order_per_15_mins),
      order_notes_active: formState.ordering_notes,
      delivery_active: formState.delivery_active,
      payment_method_cash: formState.payment_method_cash,
      payment_method_online: formState.payment_method_online,
      automatic_order_approval: formState.automatic_order_approval === "auto" ? true : false  // include order approval in payload
    };

    // console.log("payload" ,payload);
    // return

    try {
      if (ID) {
        await api.patch(`/groupadmin/delivery_settings/${ID}`, payload, { headers });
      } else {
        await api.post(`/groupadmin/delivery_settings`, payload, { headers });
      }
      showSuccessNotification("Your work has been saved successfully");
      fetchSettings();
    } catch (err) {
      setError(err.response?.data?.error?.message || err.message);
    }
  };

  return (
    <React.Fragment>
      <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1 className="anchor fw-bolder mb-2" id="vertical-horizontal-scroll">
                      Settings
                    </h1>
                    <hr />
                    <div className="row col-md-12">
                      <div className="col-md-9 d-inline justify-content-end" data-kt-docs-table-toolbar="base">
                        <h3 className="anchor fw-bolder mb-2 d-inline" id="custom-form-control">
                          Delivery - Settings
                        </h3>
                      </div>
                      <div className="col-md-3 d-inline justify-content-end" data-kt-docs-table-toolbar="base">
                        <NoteState />
                      </div>
                    </div>
                    <div className="rounded border p-5">
                      <CommonDelivery />
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                          <div className="py-1">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-8">
                                      <div className="rounded border p-5">
                                        <div className="mb-10">
                                          <h6>Receive Orders Per E-mail</h6>
                                        </div>
                                        <div className="mb-10">
                                          <label className="required form-label">E-mail Address</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            name="order_email"
                                            value={formState.order_email}
                                            required
                                            onChange={handleChange}
                                          />
                                          <div className="my-4">
                                            Note : Use the comma in between as a separator to add multiple emails.
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                      <div className="rounded border p-10">
                                        <div className="mb-10">
                                          <h6>Restrictions</h6>
                                        </div>
                                        <div className="mb-10">
                                          <label className="required form-label">Ordering interval in minutes</label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            name="order_interval"
                                            value={formState.order_interval}
                                            required
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <div className="mb-10">
                                          <label className="required form-label">Orders per particular interval</label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            name="order_per_15_mins"
                                            value={formState.order_per_15_mins}
                                            required
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <div className="mb-10">
                                          <label className="required form-label">Order days in Advance</label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            name="order_days_in_advance"
                                            value={formState.order_days_in_advance}
                                            required
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="rounded border p-10">
                                        <div className="mb-7">
                                          <h6>Active</h6>
                                        </div>
                                        <div>
                                          <input
                                            className="form-check-input mx-3"
                                            type="checkbox"
                                            name="delivery_active"
                                            checked={formState.delivery_active}
                                            onChange={handleChange}
                                          />
                                          <label className="form-check-label">Delivery</label>
                                        </div>
                                      </div>
                                      <br />
                                      <div className="rounded border p-10">
                                        <div className="mb-7">
                                          <h6>Ordering Notes</h6>
                                        </div>
                                        <div className="mb-0">
                                          <input
                                            className="form-check-input mx-3"
                                            type="checkbox"
                                            name="ordering_notes"
                                            checked={formState.ordering_notes}
                                            onChange={handleChange}
                                          />
                                          <label className="form-check-label">Ordering Notes</label>
                                        </div>
                                      </div>
                                      <br />
                                      <div className="rounded border p-10">
                                        <div className="mb-9">
                                          <h6 className="required">Payment Method</h6>
                                        </div>
                                        <div className="mb-5">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="payment_method_online"
                                            checked={formState.payment_method_online}
                                            onChange={handleChange}
                                            required={!formState.payment_method_cash}
                                          />
                                          <label className="form-check-label mx-3">Online Payment</label>
                                        </div>
                                        <div className="mb-0">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="payment_method_cash"
                                            checked={formState.payment_method_cash}
                                            onChange={handleChange}
                                            required={!formState.payment_method_online}
                                          />
                                          <label className="form-check-label mx-3">Cash Payment</label>
                                        </div>
                                      </div>
                                      <br />
                                      <div className="rounded border p-10">
                                        <div className="mb-10">
                                          <h6 className="required">Order Approval</h6>
                                        </div>
                                        <div className="mb-5">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="automatic_order_approval"
                                            value="auto"
                                            checked={formState.automatic_order_approval === "auto"}
                                            onChange={handleApprovalChange}
                                          />
                                          <label className="form-check-label mx-3">Auto approval</label>
                                        </div>
                                        <div className="mb-0">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="automatic_order_approval"
                                            value="manual"
                                            checked={formState.automatic_order_approval === "manual"}
                                            onChange={handleApprovalChange}
                                          />
                                          <label className="form-check-label mx-3">Manual approval</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <ErrorMessage error={error} />

                                  
                                  <div className="modal-footer flex-center">

                                  {(userPermissionType === "groupadmin" ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "delivery" &&
                                            (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                          )) && (
                                            <button type="submit" className="btn btn-primary">
                                              <span className="indicator-label">Save</span>
                                            </button>)}
                                    <button type="button" className="btn btn-danger m-2" onClick={() => history.goBack()}>
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

export default connect(mapStateToProps)(GroupAdminDeliverySetting);
