import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import api from "../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../CommonApi/headers";
import useDrawer from "../../../context/DrawerContext";
import { submitEditForm } from "../../Comman/EditCommonFun/EditCommonFunction";
import useError from "../../Comman/Error/useError";
import { applyFilterMeHandlers } from "../../Comman/Error/jqueryUtils";
import { SubmitformButton } from "../../Comman/Error/SubmitformButton";
import { ErrorMessage } from "../../Comman/Error/ErrorMessage";
import { passwordValidation, validateEmail } from "../../Comman/AddCommonFun/validation";
import { HeaderSection } from "../../Comman/Error/HeaderSection";

export default function GroupAdminEditManager() {

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [name, setname] = useState("");
  const [phone_number, setphone_number] = useState("");

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const { error, showError, clearError } = useError();

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    ManagerDetailsGet();
  }, []);

  function ManagerDetailsGet() {
    api.get(`/groupadmin/manager/${id}`, { headers }).then((res) => {
      const tableData = res.data.data;
      setname(tableData.name);
      setemail(tableData.email);
      // setpassword(tableData.password);
      setphone_number(tableData.phone_number);
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    showError(null);

    if (
      name.toString().trim().length < 0 ||
      name.toString().trim().length < 3
    ) {
      showError("Manager Name Must Be at least 3 character Long");
    }
    // else if (phone_number.toString().trim().length < 0 || phone_number.toString().trim().length < 10) {
    //     showError('Phone Number Must Be at least 10 character Long');
    // }
    else if (!validateEmail(email)) {
      showError("Invalid Email");
    } else if (
      (password && password.toString().trim().length > 0 && !passwordValidation(password)) ||
      (password && password.toString().trim().split(" ").join("").length < 6)
    ) {
      showError("Password must be at least 6 characters long and not contain any spaces.");
      $(".loader").hide();
    }  else {

      const formData = new FormData();

        formData.append("branch_id", id);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone_number", phone_number);
        if(password){
          formData.append("password", password);
        }
      // console.log("item" , item)

      // api
      //   .patch(`/groupadmin/manager/${id}`, formData, { headers })
      //   .then((res) => {
         
      //     showSuccessNotification("Manager details has been edited successfully");

      //     history.goBack();
      //   })
      //   .catch((err) => {
      //     if (err.response) {
      //       showError(err.response?.data?.error?.message);
      //     } else if (err.request) {
      //       showError(err.request);
      //     } else {
      //       showError(err.message);
      //     }
      //   });

        submitEditForm(`/groupadmin/manager/${id}`, formData, history, showError, clearError, "Manager details has been edited successfully");

    }
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <HeaderSection title="Edit Manager" goBackHandler={() => history.goBack()} />
                  <div className="py-5">
                    <div className="card card-docs mb-2">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div className="rounded border p-10">
                          <form onSubmit={handleSubmit}>
                            <div className="row col-md-12">
                              <div className="mb-10">
                                <h6>Manager Details Edit</h6>
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="required form-label">
                                  Manager Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Manager Name"
                                  required
                                  value={name}
                                  onChange={(e) => setname(e.target.value)}
                                />
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="required form-label">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  className="filterme form-control form-control-solid"
                                  // maxLength="10"
                                  // minLength="10"
                                  placeholder="Phone Number"
                                  required
                                  value={phone_number}
                                  onChange={(e) =>
                                    setphone_number(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="required form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control form-control-solid"
                                  placeholder="Email"
                                  required
                                  value={email}
                                  onChange={(e) => setemail(e.target.value)}
                                />
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="form-label">
                                  Password
                                </label>
                                <input
                                  type="password"
                                  className="noSpace form-control form-control-solid"
                                  placeholder="Password"
                                  // value={password}
                                  onChange={(e) => setpassword(e.target.value)}
                                />
                              </div>
                            </div>

                            <ErrorMessage error={error} />
                             <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
