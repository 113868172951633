import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import Swal from "sweetalert2";
import useDrawer from "../../../../context/DrawerContext";
import CommonPage from "./CommonPage";
import BackButtonSimpal from "./BackButtonSimpal";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";

export default function GroupAdminTimeFilter() {
  let history = useHistory();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];
  let location = useLocation();

  const [use_time_filter, setuse_time_filter] = useState(false);
  const [mon_end_time, setmon_end_time] = useState("");
  const [mon_start_time, setmon_start_time] = useState("");
  const [sun_start_time, setsun_start_time] = useState("");
  const [sun_end_time, setsun_end_time] = useState("");
  const [tue_start_time, settue_start_time] = useState("");
  const [tue_end_time, settue_end_time] = useState("");
  const [wed_start_time, setwed_start_time] = useState("");
  const [wed_end_time, setwed_end_time] = useState("");
  const [thu_start_time, setthu_start_time] = useState("");
  const [thu_end_time, setthu_end_time] = useState("");
  const [fri_start_time, setfri_start_time] = useState("");
  const [fri_end_time, setfri_end_time] = useState("");
  const [sat_start_time, setsat_start_time] = useState("");
  const [sat_end_time, setsat_end_time] = useState("");

  const [data, setForm] = useState([]);
  const [timeList, setTimeList] = useState([]);

  ///////////////////////// Get Delivery prep time /////////////////////////

  useEffect(() => {
    groupAdminProductListById();
    // console.log("branchID", location.state.branchID);
    // console.log("Product", location.state.productID);
    // console.log("menuid", location.state.menuId);
    // console.log("catagory", location.state.category_id);
  }, [location]);

  const [user, setuser] = useState({});

  const [values, setValues] = useState({
    use_time_filter: "",
    mon_start_time: "",
    mon_end_time: "",
    tue_end_time: "",
    tue_end_time: "",
    wed_end_time: "",
    wed_start_time: "",
    thu_end_time: "",
    thu_start_time: "",
    fri_end_time: "",
    fri_start_time: "",
    sun_end_time: "",
    sun_start_time: "",
  });

  function groupAdminProductListById() {
    api
      .get(`/groupadmin/product/timefilter/${location.state.productID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        // console.log(tableData);
        // let arr = [];
        // if (tableData.mon_start_time) {
        //     arr.push({
        //         start_time: tableData.mon_start_time,
        //         end_time: tableData.mon_end_time,
        //         name: 'mon'
        //     })
        // }
        // if (tableData.tue_start_time) {
        //     arr.push({
        //         start_time: tableData.tue_start_time,
        //         end_time: tableData.tue_end_time,
        //         name: 'tue'
        //     })
        // }
        // if (tableData.wed_start_time) {
        //     arr.push({
        //         start_time: tableData.wed_start_time,
        //         end_time: tableData.wed_end_time,
        //         name: 'wed'
        //     })
        // }
        // if (tableData.thu_start_time) {
        //     arr.push({
        //         start_time: tableData.thu_start_time,
        //         end_time: tableData.thu_end_time,
        //         name: 'thu'
        //     })
        // }
        // if (tableData.fri_start_time) {
        //     arr.push({
        //         start_time: tableData.fri_start_time,
        //         end_time: tableData.fri_end_time,
        //         name: 'fri'
        //     })
        // }
        // if (tableData.sat_start_time) {
        //     arr.push({
        //         start_time: tableData.sat_start_time,
        //         end_time: tableData.sat_end_time,
        //         name: 'sat'
        //     })
        // }
        // if (tableData.sun_start_time) {
        //     arr.push({
        //         start_time: tableData.sun_start_time,
        //         end_time: tableData.sun_end_time,
        //         name: 'sun'
        //     })
        // }
        // console.log("Sgdetwtw", arr);
        // setTimeList(arr);
        setuser(tableData);
        setValues({
          use_time_filter: tableData.use_time_filter,
          mon_start_time: tableData.mon_start_time,
          mon_end_time: tableData.mon_end_time,
          tue_end_time: tableData.tue_end_time,
          tue_start_time: tableData.tue_start_time,
          wed_start_time: tableData.wed_start_time,
          wed_end_time: tableData.wed_end_time,
          thu_start_time: tableData.thu_start_time,
          thu_end_time: tableData.thu_end_time,
          fri_end_time: tableData.fri_end_time,
          fri_start_time: tableData.fri_start_time,
          sat_end_time: tableData.sat_end_time,
          sat_start_time: tableData.sat_start_time,
          sun_end_time: tableData.sun_end_time,
          sun_start_time: tableData.sun_start_time,
        });
        setuse_time_filter(tableData.use_time_filter);
        setmon_start_time(tableData.mon_start_time);
        setmon_end_time(tableData.mon_end_time);
        settue_end_time(tableData.tue_end_time);
        settue_start_time(tableData.tue_start_time);
        setwed_start_time(tableData.wed_start_time);
        setwed_end_time(tableData.wed_end_time);
        setthu_start_time(tableData.thu_start_time);
        setthu_end_time(tableData.thu_end_time);
        setfri_end_time(tableData.fri_end_time);
        setfri_start_time(tableData.fri_start_time);
        setsat_end_time(tableData.sat_end_time);
        setsat_start_time(tableData.sat_start_time);
        setsun_end_time(tableData.sun_end_time);
        setsun_start_time(tableData.sun_start_time);
      });
  }

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function EdithandleSubmit(event) {
    event.preventDefault();
    setError(null);
    // let item = {
    //             use_time_filter: use_time_filter,
    //             mon_end_time: mon_end_time,
    //             mon_start_time: mon_start_time,
    //             tue_start_time: mon_end_time,
    //             tue_end_time: tue_end_time,
    //             wed_start_time: wed_start_time,
    //             wed_end_time: mon_start_time,
    //             thu_start_time: thu_start_time,
    //             thu_end_time: thu_end_time,
    //             fri_start_time: fri_start_time,
    //             fri_end_time: fri_end_time,
    //             sat_start_time: sat_start_time,
    //             sat_end_time: sat_end_time,
    //             sun_start_time: sun_start_time,
    //             sun_end_time: sun_end_time,

    //         }
    // for (let [key, value] of item) {
    //     console.log(`${key}: ${value}`)
    // }

    var item2 = {};
    let key1;
    let key2;
    if (location.state.productID == undefined) {
      setError("Please add the product first before entering product timeing.");
      return;
    } else if (data.length == 0) {
      setError("Please Add Entry To Continue.");
      return;
    } else {
      for (let i = 0; i < data.length; i++) {
        var weekkeys = data[i].Week;
        var wkey = weekkeys.split("_");
        key1 = wkey[0] + "_start_time";
        key2 = wkey[0] + "_end_time";
        item2[key1] = data[i].start_time;
        item2[key2] = data[i].end_time;

        if (data[i].Week == "") {
          setError("No Any Field Empty");
          return;
        } else if (data[i].start_time == "") {
          setError("No Any Field Empty");
          return;
        } else if (data[i].end_time == "") {
          setError("No Any Field Empty");
          return;
        } else {
          if (data[i].start_time == data[i].end_time) {
            setError(
              wkey[1] + " Start Time And End Time it's Not allow to same"
            );
            return;
          } else if (data[i].start_time > data[i].end_time) {
            setError(
              wkey[1] + " Starting time must be lower than ending time."
            );
            return;
          } else if (data[i].end_time < data[i].start_time) {
            setError(
              wkey[1] + " Ending time must be greater than Starting time."
            );
            return;
          } else {
            continue;
          }
        }
      }
      function FormDataUnsafe() {
        this.dict = {};
      }

      FormDataUnsafe.prototype.append = function (key, value) {
        this.dict[key] = value;
      };

      FormDataUnsafe.prototype.contains = function (key) {
        return this.dict.hasOwnProperty(key);
      };

      FormDataUnsafe.prototype.getValue = function (key) {
        return this.dict[key];
      };

      FormDataUnsafe.prototype.valueOf = function () {
        var fd = new FormData();
        for (var key in this.dict) {
          if (this.dict.hasOwnProperty(key)) fd.append(key, this.dict[key]);
        }

        return fd;
      };

      FormDataUnsafe.prototype.safe = function () {
        return this.valueOf();
      };

      var item = new FormDataUnsafe();
      if (item2.mon_start_time) {
        item.append("mon_start_time", item2.mon_start_time);
      }
      if (item2.mon_end_time) {
        item.append("mon_end_time", item2.mon_end_time);
      }
      if (item2.tue_start_time) {
        item.append("tue_start_time", item2.tue_start_time);
      }
      if (item2.tue_end_time) {
        item.append("tue_end_time", item2.tue_end_time);
      }
      if (item2.wed_start_time) {
        item.append("wed_start_time", item2.wed_start_time);
      }
      if (item2.wed_end_time) {
        item.append("wed_end_time", item2.wed_end_time);
      }
      if (item2.thu_start_time) {
        item.append("thu_start_time", item2.thu_start_time);
      }
      if (item2.thu_end_time) {
        item.append("thu_end_time", item2.thu_end_time);
      }
      if (item2.fri_start_time) {
        item.append("fri_start_time", item2.fri_start_time);
      }
      if (item2.fri_end_time) {
        item.append("fri_end_time", item2.fri_end_time);
      }
      if (item2.sat_start_time) {
        item.append("sat_start_time", item2.sat_start_time);
      }
      if (item2.sat_end_time) {
        item.append("sat_end_time", item2.sat_end_time);
      }
      if (item2.sun_start_time) {
        item.append("sun_start_time", item2.sun_start_time);
      }
      if (item2.sun_end_time) {
        item.append("sun_end_time", item2.sun_end_time);
      }
      // for (let [key, value] of item) {
      //     console.log(`${key}: ${value}`)
      // }
      // return;

      item.append("use_time_filter", use_time_filter);
      // console.log("sdgwtetwt567", item.contains("sun_start_time"));
      if (mon_start_time && !item.contains("mon_start_time")) {
        item.append("mon_start_time", mon_start_time);
      }
      if (mon_end_time && !item.contains("mon_end_time")) {
        item.append("mon_end_time", mon_end_time);
      }
      if (tue_start_time && !item.contains("tue_start_time")) {
        item.append("tue_start_time", tue_start_time);
      }
      if (tue_end_time && !item.contains("tue_end_time")) {
        item.append("tue_end_time", tue_end_time);
      }
      if (wed_start_time && !item.contains("wed_start_time")) {
        item.append("wed_start_time", wed_start_time);
      }
      if (wed_end_time && !item.contains("wed_end_time")) {
        item.append("wed_end_time", wed_end_time);
      }
      if (thu_start_time && !item.contains("thu_start_time")) {
        item.append("thu_start_time", thu_start_time);
      }
      if (thu_end_time && !item.contains("thu_end_time")) {
        item.append("thu_end_time", thu_end_time);
      }
      if (fri_start_time && !item.contains("fri_start_time")) {
        item.append("fri_start_time", fri_start_time);
      }
      if (fri_end_time && !item.contains("fri_end_time")) {
        item.append("fri_end_time", fri_end_time);
      }
      if (sat_start_time && !item.contains("sat_start_time")) {
        item.append("sat_start_time", sat_start_time);
      }
      if (sat_end_time && !item.contains("sat_end_time")) {
        item.append("sat_end_time", sat_end_time);
      }
      if (sun_start_time && !item.contains("sun_start_time")) {
        item.append("sun_start_time", sun_start_time);
      }
      if (sun_end_time && !item.contains("sun_end_time")) {
        item.append("sun_end_time", sun_end_time);
      }
      // console.log("Sdgtwtuyuyuy", item.safe());
      // console.log(Object.fromEntries(item.safe().entries()));

      // let form = new FormData();
      // form.append(item)
      // return;

      api
        .post(
          `/groupadmin/product/timefilter/${location.state.productID}`,
          item.safe(),
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  /////////////////////////////  Edit time Filter //////////////////////////////

  //////////////////////// Delete Time Fitlet ///////////////////////
  function deleteProductTimeFilter(data) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(
            `/groupadmin/product/timefilter/${location.state.productID}`,
            { headers: headers, data: data }
          )
          .then((res) => {
            // console.log(res.data);
            groupAdminProductListById();
          });
      }
    });
  }

  /////////////////////// Delete Time Filter ///////////////////////////////

  //////////////////////////////  Order //////////////////////////////////

  const handleCheck = (index, e, selected) => {
    // console.log(index, e.target.value, selected);
    let temp = [...data];
    temp[index][selected] = e.target.value;
    setForm(temp);
  };

  const handleCheck2 = (index, e, selected) => {
    // console.log(index, e.target.value, selected);
    let temp = [...timeList];
    temp[index][selected] = e.target.value;
    setTimeList(temp);
  };

  const haldleNewRow = () => {
    let value = 0;
    if (sun_start_time) {
      value = value + 1;
    }
    if (mon_start_time) {
      value = value + 1;
    }
    if (tue_start_time) {
      value = value + 1;
    }
    if (wed_start_time) {
      value = value + 1;
    }
    if (thu_start_time) {
      value = value + 1;
    }
    if (fri_start_time) {
      value = value + 1;
    }
    if (sat_start_time) {
      value = value + 1;
    }
    if (data.length + value < 7) {
      setForm([
        ...data,
        {
          Week: "sun_Sunday",
          start_time: "",
          end_time: "",
        },
      ]);
    }
  };

  const handleremove = (index) => {
    // console.log(index)
    let list = [...data];
    list.splice(index, 1);
    setForm(list);
  };

  //////////////////////////////  Order //////////////////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  const DELETE = 
  (userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("delete")) &&
  <i className="fa fa-trash" />

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Product - Time Filter
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                         <BackButtonSimpal
                                    name={"Go Back"}
                                  />
                      </div>
                    </div>
                  </div>
                    <div className="rounded border p-5">
                    <CommonPage />
                      <br />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <form onSubmit={EdithandleSubmit}>
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="rounded border p-10">
                                  <div className="d-flex flex-stack my-5">
                                    <div
                                      className="d-flex justify-content-end"
                                      data-kt-docs-table-toolbar="base"
                                    >
                                      <dl>
                                        <dt>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={use_time_filter}
                                            onChange={() =>
                                              setuse_time_filter(
                                                !use_time_filter
                                              )
                                            }
                                          />
                                          <label className="form-check-label mx-2">
                                            Use time filter
                                          </label>
                                        </dt>
                                      </dl>
                                    </div>
                                    <div className="d-flex flex-stack">
                                      <div
                                        className="d-flex justify-content-end mx-2"
                                        data-kt-docs-table-toolbar="base"
                                      >
                                        <button
                                          type="button"
                                          onClick={haldleNewRow}
                                          className="btn btn-primary addtimes"
                                        >
                                          Add Entry
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <>
                                      {mon_start_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Monday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={mon_start_time}
                                              onChange={(e) =>
                                                setmon_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={mon_end_time}
                                              onChange={(e) =>
                                                setmon_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  mon_start_time: "00:00",
                                                  mon_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                      {tue_start_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Tuesday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={tue_start_time}
                                              onChange={(e) =>
                                                settue_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={tue_end_time}
                                              onChange={(e) =>
                                                settue_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  tue_start_time: "00:00",
                                                  tue_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                      {wed_end_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Wednesday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={wed_start_time}
                                              onChange={(e) =>
                                                setwed_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={wed_end_time}
                                              onChange={(e) =>
                                                setwed_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  wed_start_time: "00:00",
                                                  wed_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                      {thu_start_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Thursday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={thu_start_time}
                                              onChange={(e) =>
                                                settue_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={thu_end_time}
                                              onChange={(e) =>
                                                setthu_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  thu_start_time: "00:00",
                                                  thu_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                      {fri_start_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Friday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={fri_start_time}
                                              onChange={(e) =>
                                                setfri_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={fri_end_time}
                                              onChange={(e) =>
                                                setfri_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  fri_start_time: "00:00",
                                                  fri_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                      {sat_start_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Saturday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={sat_start_time}
                                              onChange={(e) =>
                                                setsat_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={sat_end_time}
                                              onChange={(e) =>
                                                setsat_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  sat_start_time: "00:00",
                                                  sat_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                      {sun_start_time && (
                                        <div className="row col-md-12">
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Day
                                              </strong>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control form-control-solid"
                                              value="Sunday"
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                Start Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={sun_start_time}
                                              onChange={(e) =>
                                                setsun_start_time(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <label className="form-label">
                                              <strong
                                                style={{ color: "black" }}
                                              >
                                                End Time
                                              </strong>
                                            </label>
                                            <input
                                              type="time"
                                              className="form-control form-control-solid"
                                              value={sun_end_time}
                                              onChange={(e) =>
                                                setsun_end_time(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="col-md-3 my-2">
                                            <a
                                              id="deletebtn"
                                              className="btn btn-danger1 remCF"
                                              onClick={(e) => {
                                                let data = {
                                                  sun_start_time: "00:00",
                                                  sun_end_time: "00:00",
                                                };
                                                deleteProductTimeFilter(data);
                                              }}
                                            >
                                              <br />
                                              {DELETE}
                                            </a>
                                          </div>
                                        </div>
                                      )}

                                      {data.length > 0 && (
                                        <div className="col-md-12">
                                          {data.map((details, index) => (
                                            <>
                                              <div
                                                className="row col-md-12"
                                                key={index}
                                              >
                                                <div className="col-md-3 my-2">
                                                  <label className="form-label">
                                                    <strong
                                                      style={{ color: "black" }}
                                                    >
                                                      Day
                                                    </strong>
                                                  </label>
                                                  <select
                                                    className="form-control"
                                                    required
                                                    // data-control="select2"
                                                    //data-placeholder="Select an option"
                                                    value={details.Week}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "Week"
                                                      )
                                                    }
                                                  >
                                                    <option disabled selected>
                                                      Week Days
                                                    </option>

                                                    <option value="sun_Sunday">
                                                      Sunday
                                                    </option>
                                                    <option value="mon_Monday">
                                                      Monday
                                                    </option>
                                                    <option value="tue_Tuesday">
                                                      Tuesday
                                                    </option>
                                                    <option value="wed_Wednesday">
                                                      Wednesday
                                                    </option>
                                                    <option value="thu_Thursday">
                                                      Thursday
                                                    </option>
                                                    <option value="fri_Friday">
                                                      Friday
                                                    </option>
                                                    <option value="sat_Saturday">
                                                      Saturday
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className="col-md-3 my-2">
                                                  <label className="form-label">
                                                    <strong
                                                      style={{ color: "black" }}
                                                    >
                                                      Start Time
                                                    </strong>
                                                  </label>
                                                  <input
                                                    type="time"
                                                    className="form-control form-control-solid"
                                                    value={details?.start_time}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "start_time"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-md-3 my-2">
                                                  <label className="form-label">
                                                    <strong
                                                      style={{ color: "black" }}
                                                    >
                                                      End Time
                                                    </strong>
                                                  </label>
                                                  <input
                                                    type="time"
                                                    className="form-control form-control-solid"
                                                    value={details?.end_time}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "end_time"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-md-3 my-2">
                                                  {/* {
                                                                                                        data.length !== 1 && */}
                                                  <a
                                                    id="deletebtn"
                                                    className="btn btn-danger1 remCF"
                                                    onClick={(e) =>
                                                      handleremove(index)
                                                    }
                                                  >
                                                    <br />
                                                    {DELETE}
                                                  </a>
                                                  {/* } */}
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  </div>

                                  <div className="col-md-12"> {errorDiv} </div>
                                </div>
                                <div className="modal-footer flex-center">
                                  <button
                                    type="submit"
                                    id="kt_modal_new_address_submit"
                                    className="btn btn-primary"
                                  >
                                    <span className="indicator-label">
                                      Save
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
