import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Editor } from "@tinymce/tinymce-react";
import api from "../../../../CommonApi/axios";
import Swal from "sweetalert2";
import $ from "jquery";
import TyniAMC from "../../../../CommonApi/TyniAmc";
import headers from "../../../../CommonApi/headers";
import "./ProductDetailsInformation.css"
import useDrawer from "../../../../context/DrawerContext";
import CommonPage from "./CommonPage";
import BackButtonSimpal from "./BackButtonSimpal";
window.jquery = window.$ = $;
const TinyMCE_Api_Key = TyniAMC.TyniAMCAPIKEy;
// const TinyMCE_Api_Key = "e7jtb3jx7jxq7f74tiheony2eaylpscng6b98z2egtnak345";

export default function GroupAdminDiscriptaion() {
  let history = useHistory();
  let location = useLocation();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];

  const [description_DE, setdescription_DE] = useState("");
  const [description_EN, setdescription_EN] = useState("");
  const [description_FR, setdescription_FR] = useState("");
  const [description_IT, setdescription_IT] = useState("");
  const [description_AR, setdescription_AR] = useState("");

  const editorRefEN = useRef(null);
  const editorRefFR = useRef(null);
  const editorRefIT = useRef(null);
  const editorRefGE = useRef(null);
  const editorRefAR = useRef(null);

  const [user, setuser] = useState();
  const [languange, setlanguange] = useState([]);

  useEffect(() => {
    groupAdminBranchList();
    groupAdminBranchDetilasByID();
    // console.log("branchID", location.state.branchID);
    // console.log("Product", location.state.productID);
    // console.log("menuid", location.state.menuId);
    // console.log("catagory", location.state.category_id);
  }, [location]);

  function groupAdminBranchDetilasByID() {
    api
      .get(`/groupadmin/branch/${location.state.branchID}`, { headers })
      .then((res) => {
        const tableData = res.data.data.resData;
        // console.log("tableData", tableData.languange);
        setlanguange(tableData.languange);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data);
        }
      });
  }

  function groupAdminBranchList() {
    api
      .get(`/groupadmin/product/description/${location.state.productID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setdescription_DE(tableData?.description_DE);
        setdescription_EN(tableData?.description_EN);
        setdescription_FR(tableData?.description_FR);
        setdescription_IT(tableData?.description_IT);
        setdescription_AR(tableData?.description_AR);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
        }
      });
  }

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [error2, setError2] = useState(null);
  const errorDiv2 = error2 ? (
    <div className="text-center alert alert-danger">{error2}</div>
  ) : (
    ""
  );

  const [error3, setError3] = useState(null);
  const errorDiv3 = error3 ? (
    <div className="text-center alert alert-danger">{error3}</div>
  ) : (
    ""
  );

  const [error4, setError4] = useState(null);
  const errorDiv4 = error4 ? (
    <div className="text-center alert alert-danger">{error4}</div>
  ) : (
    ""
  );

  const [error5, setError5] = useState(null);
  const errorDiv5 = error5 ? (
    <div className="text-center alert alert-danger">{error5}</div>
  ) : (
    ""
  );

  const onChangeEN = (content) => {
    setdescription_EN(content)
    // if (location.state.productID == undefined) {
    //   setError("Please add the product first before entering description.");
    // } else {
    //   const { value } = e.target.value;
    //   setdescription_EN((prevState) => ({
    //     ...prevState,
    //     [value]: value,
    //   }));
    // }
  };

  function logEN(event) {
    event.preventDefault();
    setError(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError("Please Fill");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        // description_DE: editorRef.current.getContent(),
        // description_FR: editorRef.current.getContent(),
        // description_IT: editorRef.current.getContent()
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminBranchList();
          // history.push("/group_admin/menu_management/menu_products/product_details_time_filter", { productID: location.state.productID, branchID: location.state.branchID })
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  function NExtlogEN(event) {
    event.preventDefault();
    setError(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError("Please Fill");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        // description_DE: editorRef.current.getContent(),
        // description_FR: editorRef.current.getContent(),
        // description_IT: editorRef.current.getContent()
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push(
            "/group_admin/menu_management/menu_products/product_details_time_filter",
            {
              productID: location.state.productID,
              branchID: location.state.branchID,
            }
          );
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  const onChangeFR = (content) => {
    setdescription_FR(content)
    // if (location.state.productID == undefined) {
    //   setError2("Please add the product first before entering description.");
    // } else {
    //   const { value } = e.target.value;
    //   setdescription_FR((prevState) => ({
    //     ...prevState,
    //     [value]: value,
    //   }));
    // }
  };

  function logFR(event) {
    event.preventDefault();
    setError2(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError2("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_FR: editorRefFR.current.getContent(),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminBranchList();
          // history.push("/group_admin/menu_management/menu_products/product_details_time_filter", { productID: location.state.productID, branchID: location.state.branchID })
        })
        .catch((err) => {
          if (err.response) {
            setError2(err.response?.data?.error?.message);
          } else if (err.request) {
            setError2(err.request);
          } else {
            setError2(err.message);
          }
        });
    }
  }

  function NextlogFR(event) {
    event.preventDefault();
    setError2(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError2("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_FR: editorRefFR.current.getContent(),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push(
            "/group_admin/menu_management/menu_products/product_details_time_filter",
            {
              productID: location.state.productID,
              branchID: location.state.branchID,
            }
          );
        })
        .catch((err) => {
          if (err.response) {
            setError2(err.response?.data?.error?.message);
          } else if (err.request) {
            setError2(err.request);
          } else {
            setError2(err.message);
          }
        });
    }
  }

  const onChangeIT = (content) => {
    // console.log(content);
    setdescription_IT(content)
    // if (location.state.productID == undefined) {
    //   setError3("Please add the product first before entering description.");
    // } else {
    //   const { value } = e.target.value;
    //   setdescription_IT((prevState) => ({
    //     ...prevState,
    //     [value]: value,
    //   }));
    // }
  };

  function logIT(event) {
    event.preventDefault();
    setError3(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError3("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_IT: editorRefIT.current.getContent(),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminBranchList();
          // history.push("/group_admin/menu_management/menu_products/product_details_time_filter", { productID: location.state.productID, branchID: location.state.branchID })
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError3(err.response?.data?.error?.message);
          } else if (err.request) {
            setError3(err.request);
          } else {
            setError3(err.message);
          }
        });
    }
  }

  function NextlogIT(event) {
    event.preventDefault();
    setError3(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError3("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_IT: editorRefIT.current.getContent(),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminBranchList();
          history.push(
            "/group_admin/menu_management/menu_products/product_details_time_filter",
            {
              productID: location.state.productID,
              branchID: location.state.branchID,
            }
          );
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError3(err.response?.data?.error?.message);
          } else if (err.request) {
            setError3(err.request);
          } else {
            setError3(err.message);
          }
        });
    }
  }

  const onChangeGE = (content) => {
    // console.log(content);
    setdescription_DE(content)
    // if (location.state.productID == undefined) {
    //   setError4("Please add the product first before entering description.");
    // } else {
    //   const { value } = e.target.value;
    //   setdescription_DE((prevState) => ({
    //     ...prevState,
    //     [value]: value,
    //   }));
    // }
  };

  function logGE(event) {
    event.preventDefault();

    setError4(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError4("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_DE: editorRefGE.current.getContent(),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminBranchList();
          // history.push("/group_admin/menu_management/menu_products/product_details_time_filter", { productID: location.state.productID, branchID: location.state.branchID })
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError4(err.response?.data?.error?.message);
          } else if (err.request) {
            setError4(err.request);
          } else {
            setError4(err.message);
          }
        });
    }
  }

  const onChangeAR = (content) => {
    // console.log(content);
    setdescription_AR(content)
    // if (location.state.productID == undefined) {
    //   setError4("Please add the product first before entering description.");
    // } else {
    //   const { value } = e.target.value;
    //   setdescription_AR((prevState) => ({
    //     ...prevState,
    //     [value]: value,
    //   }));
    // }
  };

  function logAR(event) {
    event.preventDefault();

    setError5(null);

    var editorContent = editorRefAR.current.getContent();
    if (editorContent == "") {
      setError5("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_AR: editorRefAR?.current?.getContent({ format: "text" }),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminBranchList();
          // history.push("/group_admin/menu_management/menu_products/product_details_time_filter", { productID: location.state.productID, branchID: location.state.branchID })
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError5(err?.response?.data?.error?.message);
          } else if (err.request) {
            setError5(err.request);
          } else {
            setError5(err.message);
          }
        });
    }
  }

  function NextlogGE(event) {
    event.preventDefault();

    setError4(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      setError4("Please Fill Description EN");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        description_DE: editorRefGE.current.getContent(),
      };

      api
        .post(
          `/groupadmin/product/description/${location.state.productID}`,
          item,
          { headers }
        )
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push(
            "/group_admin/menu_management/menu_products/product_details_time_filter",
            {
              productID: location.state.productID,
              branchID: location.state.branchID,
            }
          );
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError4(err.response?.data?.error?.message);
          } else if (err.request) {
            setError4(err.request);
          } else {
            setError4(err.message);
          }
        });
    }
  }

  function comxtmanNe(event) {
    event.preventDefault();

    history.push(
      "/group_admin/menu_management/menu_products/product_details_time_filter",
      {
        productID: location?.state?.productID,
        branchID: location?.state?.branchID,
        menuId: location?.state?.menuId,
        category_id: location?.state?.category_id,
      }
    );
    // window.location.reload(true);
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Product - Description
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                         <BackButtonSimpal
                                    name={"Go Back"}
                                  />
                      </div>
                    </div>
                  </div>
                    <div className="rounded border p-5">
                     <CommonPage/>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                              <div className="rounded border p-10">
                                {languange.includes("English") ? (
                                  <div className="mb-10">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Description EN
                                    </label>
                                    <div className="tinymce form-control form-control-lg form-control-solid border-dark">
                                      <Editor
                                        onInit={(evt, editor) =>
                                          (editorRefEN.current = editor)
                                        }
                                        apiKey={TinyMCE_Api_Key}
                                        value={description_EN}
                                        onEditorChange={onChangeEN}
                                        // onChange={(e) => setdescription_EN(e.target.value)}
                                        init={{
                                          height: 500,
                                          selector: ".tinymce",
                                          statusbar: false,
                                          toolbar:
                                            "bold italic forecolor | underline  |" +
                                            "removeformat | testBTN | ",
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          auto_focus: "main_editor",
                                          relative_urls: false,
                                          entity_encoding: "raw",
                                          menubar: false,
                                        }}
                                      />
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv}{" "}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={logEN}
                                      >
                                        Save
                                      </button>
                                      {/* <button type='button' className='btn btn-primary mx-2'
                                                                            onClick={NExtlogEN}
                                                                        >Next</button> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {languange.includes("arabic") ? (
                                  <div className="mb-10">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className=" form-label"
                                    >
                                      Description AR
                                    </label>
                                    <div className="tinymce form-control form-control-lg form-control-solid border-dark">
                                      <Editor
                                        onInit={(evt, editor) =>
                                          (editorRefAR.current = editor)
                                        }
                                        apiKey={TinyMCE_Api_Key}
                                        value={description_AR}
                                        onEditorChange={onChangeAR}
                                        init={{
                                          height: 500,
                                          selector: ".tinymce",
                                          statusbar: false,
                                          toolbar:
                                            "bold italic forecolor | underline  |" +
                                            "removeformat | testBTN | language ",
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          auto_focus: "main_editor",
                                          relative_urls: false,
                                          directionality: "rtl",
                                          language: "ar",
                                          entity_encoding: "raw",
                                          menubar: false,
                                        }}
                                      />
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv5}{" "}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={logAR}
                                      >
                                        Save
                                      </button>
                                      {/* <button type='button' className='btn btn-primary mx-2'
                                                                            onClick={NextlogGE}
                                                                        >Next</button> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {languange.includes("Deutsch") ? (
                                  <div className="mb-10">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className=" form-label"
                                    >
                                      Description DE
                                    </label>
                                    <div className="tinymce form-control form-control-lg form-control-solid border-dark">
                                      <Editor
                                        onInit={(evt, editor) =>
                                          (editorRefGE.current = editor)
                                        }
                                        apiKey={TinyMCE_Api_Key}
                                        value={description_DE}
                                        onEditorChange={onChangeGE}
                                        init={{
                                          height: 500,
                                          selector: ".tinymce",
                                          statusbar: false,
                                          toolbar:
                                            "bold italic forecolor | underline  |" +
                                            "removeformat | testBTN | ",
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          auto_focus: "main_editor",
                                          relative_urls: false,
                                          entity_encoding: "raw",
                                          menubar: false,
                                        }}
                                      />
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv4}{" "}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={logGE}
                                      >
                                        Save
                                      </button>
                                      {/* <button type='button' className='btn btn-primary mx-2'
                                                                            onClick={NextlogGE}
                                                                        >Next</button> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {languange.includes("français") ? (
                                  <div className="mb-10">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className=" form-label"
                                    >
                                      Description FR
                                    </label>
                                    <div className="tinymce form-control form-control-lg form-control-solid border-dark">
                                      <Editor
                                        onInit={(evt, editor) =>
                                          (editorRefFR.current = editor)
                                        }
                                        apiKey={TinyMCE_Api_Key}
                                        onEditorChange={onChangeFR}
                                        value={description_FR}
                                        init={{
                                          height: 500,
                                          selector: ".tinymce",
                                          statusbar: false,
                                          toolbar:
                                            "bold italic forecolor | underline  |" +
                                            "removeformat | testBTN | ",
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          auto_focus: "main_editor",
                                          relative_urls: false,
                                          entity_encoding: "raw",
                                          menubar: false,
                                        }}
                                      />
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv2}{" "}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={logFR}
                                      >
                                        Save
                                      </button>
                                      {/* <Link className='btn btn-primary mx-2' to={{
                                                                            pathname: "/group_admin/menu_management/menu_products/product_details_time_filter",
                                                                            // search: '?query=abc',
                                                                            state: { productID: location.state.productID, branchID: location.state.branchID }
                                                                        }}>Next</Link> */}
                                      {/* <button type='button' className='btn btn-primary mx-2'
                                                                            onClick={NextlogFR}
                                                                        >Next</button> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {languange.includes("Italiano") ? (
                                  <div className="mb-10">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className=" form-label"
                                    >
                                      Description IT
                                    </label>
                                    <div className="tinymce form-control form-control-lg form-control-solid border-dark">
                                      <Editor
                                        onInit={(evt, editor) =>
                                          (editorRefIT.current = editor)
                                        }
                                        apiKey={TinyMCE_Api_Key}
                                        value={description_IT}
                                        onEditorChange={onChangeIT}
                                        init={{
                                          height: 500,
                                          selector: ".tinymce",
                                          statusbar: false,
                                          toolbar:
                                            "bold italic forecolor | underline  |" +
                                            "removeformat | testBTN | ",
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                          auto_focus: "main_editor",
                                          relative_urls: false,
                                          entity_encoding: "raw",
                                          menubar: false,
                                        }}
                                        // value={description_EN}
                                        // onEditorChange={handleEditorChange}
                                      />
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv3}{" "}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={logIT}
                                      >
                                        Save
                                      </button>
                                      {/* <button type='button' className='btn btn-primary mx-2'
                                                                            onClick={NextlogIT}
                                                                        >Next</button> */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <br />
                              <hr />
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "12px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary mx-2"
                                  onClick={comxtmanNe}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
