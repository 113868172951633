import React, { useEffect, useState } from "react";
import {  useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import api from "../../../../../../CommonApi/axios";
import $ from "jquery";
import MaterialTable from "material-table";
import headers from "../../../../../../CommonApi/headers";
import { useStyles } from "../../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../../Comman/TableOption";
import useDrawer from "../../../../../../context/DrawerContext";
import CommonTourSetupSetting from "../CommonTourSetupSetting";
import { Tabletoursetup } from "../Tabletoursetup";
import {  UserPermissionAllData } from "../../../../../Comman/UserPermission";
import AddNewCommonButton from "../AddNewCommonButton";
window.jquery = window.$ = $;




function GroupAdminTourSetupCompanyList() {

    const [error, setError] = useState(null);
    let history = useHistory();
    let location = useLocation();
    const classes = useStyles();

    useEffect(() => {
        CompanyList();
    }, [location]);

    const [company, setCompany] = useState([]);

    async function CompanyList() {
        try {
            const response = await api.post(`/groupadmin/tourgroup/list_company`, {}, { headers });
            const tableData = response?.data?.data;
            setCompany(tableData);
        } catch (err) {
            if (err.response) {
                setError(err.response?.data?.error?.message);
            }
        }
    }

    const columns = [
        {
            title: "Sr.No.",
            render: (rowData) => {
                return <>{rowData?.tableData?.id + 1}</>;
            },
        },
        {
            title: "Name",
            field: "company_name",
        },
        {
            title: "Address",
            render: (rowData) => {
                return (
                    <>
                        <div>{rowData?.address1 || "---"}</div>
                        <div>{rowData?.address2}</div>
                        <div>{rowData?.address3}</div>
                    </>
                );
            },
        },
        {
            title: "Phone",
            render: (rowData) => {
                return (
                    <>
                        {rowData?.phone || "---"}

                    </>
                );
            },

        },
        {
            title: "Mobile",
            // field: "mobile",
            render: (rowData) => {
                return (
                    <>
                        {rowData?.mobile || "---"}
                    </>
                );
            },
        },
        {
            title: "Email",
            // field: "email",
            render: (rowData) => {
                return (
                    <>
                        {rowData?.email || "---"}
                    </>
                );
            },
        },

    ];

    const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                    <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                        <h1
                                            className="anchor fw-bolder mb-5"
                                            id="vertical-horizontal-scroll"
                                        >
                                            Settings
                                        </h1>
                                        <hr />

                                        <div className="row col-md-12 mb-10" style={{ alignItems: 'center', }}>
                                            <div
                                                className="col-md-9 "
                                                data-kt-docs-table-toolbar="base"
                                            >
                                                <h3
                                                    className="anchor fw-bolder mb-5 d-inline"
                                                    id="custom-form-control"
                                                >
                                                    Tour Setup - Company List
                                                </h3>
                                            </div>

                                            <div
                                                className="col-md-3 text-end"
                                                data-kt-docs-table-toolbar="base"
                                            >

                                                <AddNewCommonButton />

                                            </div>
                                        </div>

                                        <div className="py-5">
                                            <div className="rounded border p-10">
                                                <CommonTourSetupSetting />
                                                <div className="tab-content" id="myTabContent">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="kt_tab_pane_1"
                                                        role="tabpanel"
                                                    >
                                                        <div className="card card-docs mb-2">

                                                            <div className="card-body fs-6 p-0 text-gray-700">

                                                                <div className={classes.root}>
                                                                    <MaterialTable
                                                                        title=""
                                                                        data={company}
                                                                        columns={columns}
                                                                        options={Tableoptions}
                                                                        actions={Tabletoursetup(history, CompanyList, {
                                                                            editPath: "group_admin/settings/tourSteup/CompanyEdit",
                                                                            deletePath: "groupadmin/tourgroup/delete_company"
                                                                        }, {
                                                                            editTooltip: "Edit Company",
                                                                            deleteTooltip: "Delete Company"
                                                                        }, {
                                                                            deleteMessage: "Company has been deleted successfully."
                                                                        }, {
                                                                            access: UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                permission?.key === "tour_setup"),
                                                                        }
                                                                        )}

                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                        <GroupAdminUperSide />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}



export default GroupAdminTourSetupCompanyList;

