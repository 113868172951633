import { STOCK } from "../action-types";


const getStockList = () => ({
  type: STOCK.GET_STOCK_LIST,
});


const setStockList = (stocklist) => ({
  type: STOCK.SET_STOCK_LIST,
  stocklist,
});


export {
      getStockList ,setStockList
};
