import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./posCodeGenratepage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../../CommonApi/axios";
import { useEffect } from "react";
import { useState } from "react";

export default function PosCodeGenratPage() {
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const CODE = query.get("code");
  const branchId = query.get("state");
  const history = useHistory();

  // console.log("paramField", CODE, branchId);
  const [abcd, setabcd] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, []);

  function handleSubmit() {
    let item = {
      branch_id: branchId,
      pos_data: {
        authorised_code: CODE,
      },
    };
    // console.log("item", item);
    api
      .post(`/groupadmin/settings/POS/kseries/pos_authorised`, item)
      .then((res) => {
        // console.log("success", res.data);
        setabcd(true);
        // setTimeout(() => {}, 2000);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err?.response?.data?.error?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  const closeTab = () => {
    // window.opener = null;
    // window.open("", "_self");
    window.close();
    history.push("/");
  };

  return (
    <div>
      <ToastContainer />

      <div
        id="kt_header"
        className="header auth_header align-items-stretch px-8"
      >
        <div className="container-fluid d-flex align-items-stretch justify-content-between ">
          <div
            className="d-flex align-items-center ms-n1 me-2"
            title="Show aside menu"
          >
            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 px-2">
              {/* <Link to="/dashboard" className=""> */}
              <img
                alt="Logo"
                src="/assets/media/logos/logo.png"
                className="logo_height"
              />
              {/* </Link> */}
            </div>
          </div>
          <div className="d-flex align-items-stretch flex-shrink-0">
            {/* <div className="d-flex align-items-center ms-1 ms-lg-3">
              <Link
                to="/orderhistory"
                className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_activities_toggle"
              >
                <i className="fa-solid fa-clock-rotate-left fs-2"></i>
              </Link>
            </div> */}

            {/* <div className="d-flex align-items-stretch flex-shrink-0">
              <div
                className="d-flex align-items-center ms-1 ms-lg-3"
                id="kt_header_user_menu_toggle"
              >
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <img
                    src={"/assets/media/avatars/blank.png"}
                    height="110"
                    width="130"
                    alt="image"
                  />
                </div>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                  id="togall"
                  style={{
                    display: "none",
                    zIndex: 105,
                    position: "fixed",
                    inset: "0px 0px auto auto",
                    margin: 0,
                    transform: "translate(-30px, 65px)",
                  }}
                  data-popper-placement="bottom-end"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        <img
                          alt="Logo"
                          src={"/assets/media/avatars/blank.png"}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                          fsd
                        </div>
                        <div className="fw-bold text-muted text-hover-primary fs-7">
                          email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator my-2" />
                  <div className="menu-item">
                    <Link to="/profile">My Profile.</Link>
                  </div>
                  <div className="separator my-2" />
                  <div className="menu-item">
                    <div className="menu-link px-5">Logout.</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {abcd == false ? (
        <div className="position-absolute top-50 start-50 translate-middle d-flex aligns-items-center justify-content-center card text-center w-50 mx-auto">
          <div className="card-body">
            <h5 className="card-title">
              {" "}
              <div>
                <img
                  style={{ height: "100px", width: "100px" }}
                  src="/assets/media/loadnig.gif"
                  alt="loader"
                />
              </div>
            </h5>
            <h4 className="card-title">
              {" "}
              <div>
                Please wait a Moment, We are processing authentication with POS
                provider.
              </div>
            </h4>
            <p className="card-text mt-2">Do not close this window.</p>
            {/* <a href="#" className="btn btn-primary">
              Go somewhere
            </a>{" "} */}
          </div>
        </div>
      ) : (
        <div className="position-absolute top-50 start-50 translate-middle d-flex aligns-items-center justify-content-center card text-center w-50 mx-auto">
          {/* <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <a className="nav-link active" aria-current="true" href="#">
                Active
              </a>
            </li>
          </ul>
        </div> */}
          <div className="card-body">
            <h5 className="card-title">
              {" "}
              <div>
                <div className="mt-3 mb-4">
                  <img
                    style={{ height: "90px" }}
                    src="/assets/media/check.png"
                    alt="success_png"
                  />
                </div>
              </div>
            </h5>
            <br />
            <h4 className="card-title">
              {" "}
              <div>Your POS authentication process is completed.</div>
            </h4>

            <p className="card-text my-3">You can now close this page.</p>
            <Link onClick={closeTab} className="btn btn-primary mt-4">
              Close
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
