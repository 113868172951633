import { MENU } from "../../actions";

const menuListReducer = (state = [], action) => {
    switch (action.type) {
        case MENU.CLEAR_MENU_LIST:
            return [];
        case MENU.SET_MENU_LIST:
            return [...action?.menulist];
        default:
            return state;
    }
}

export default menuListReducer