import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";

export default function GroupAdminCustomProperties() {
  let history = useHistory();

  /////////////// Get Custome Property ///////////////////

  const [person, setperson] = useState([]);
  const [user, setuser] = useState([]);
  const [userType, setuserType] = useState([]);
  const [personType, setpersonType] = useState([]);


  useEffect(() => {
    CustomeProductPropertyGet();
    OrderProductPropertyGet();
  }, []);

  function CustomeProductPropertyGet() {
    api
      .get(`/groupAdmin/product_property`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setuser(tableData);
        console.log("tableData", tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }


  function OrderProductPropertyGet() {
    api
      .get(`/groupAdmin/product_order_type`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setuserType(tableData);
        console.log("tableData", tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const [data, setForm] = useState([]);
  const [dataType, setFormType] = useState([]);


  const haldleNewRow = () => {
    setForm([
      ...data,
      {
        name: "",
        type: "",
      },
    ]);
  };

  const haldleNewRowType = () => {
    setFormType([
      ...dataType,
      {
        name: "",
        type: "",
      },
    ]);
  };

  const [error, setError] = useState(null);
  const errorDiv = error && (
    <div className="text-center alert alert-danger">{error}</div>
  )
  const [errorType, setErrorType] = useState(null);
  const errorDivType = errorType && (
    <div className="text-center alert alert-danger">{errorType}</div>
  )

  const array3 = person.concat(data);
  const array3Type = personType.concat(dataType);

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);


    for (let i = 0; i < array3.length; i++) {
      var weekkeys = array3[i];
      if (array3[i].name == "") {
        setError("Please enter property name.");
        return;
      }
      if (array3[i].type == "") {
        setError("No Any Field Empty");
        return;
      }
    }
    {
      var item = {
        properties: data,
      };
    }

    // console.log("item" ,item)
    // return

    api
      .post(`/groupAdmin/product_property`, item, {
        headers,
      })
      .then((res) => {
        showSuccessNotification("Product custom property has been added successfully.")
        CustomeProductPropertyGet();
        setForm([]);
        setperson([]);
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  function handleSubmitType(event) {
    event.preventDefault();
    setErrorType(null);


    for (let i = 0; i < array3Type.length; i++) {
      var weekkeys = array3Type[i];
      if (array3Type[i].name == "") {
        setErrorType("Please enter property name.");
        return;
      }
      if (array3Type[i].type == "") {
        setErrorType("No Any Field Empty");
        return;
      }
    }
    {
      var item = {
        order_type: dataType,
      };
    }

    // console.log("item" ,item)
    // return

    api
      .post(`/groupAdmin/product_order_type`, item, {
        headers,
      })
      .then((res) => {
        showSuccessNotification("Product order type property has been added successfully.")
        OrderProductPropertyGet();
        setFormType([]);
        setpersonType([]);
      })
      .catch((err) => {
        if (err.response) {
          setErrorType(err.response?.data?.error?.message);
        } else if (err.request) {
          setErrorType(err.request);
        } else {
          setErrorType(err.message);
        }
      });
  }

  const handleremove = (index) => {
    // console.log("index", index);
    let list = [...data];
    list.splice(index, 1);
    setForm(list);
    setError(null)
  };

  const handleremoveType = (index) => {
    // console.log("index", index);
    let list = [...dataType];
    list.splice(index, 1);
    setFormType(list);
    setErrorType(null)
  };

  const handleCheck = (index, e, selected, type) => {
    let temp = [...data];
    temp[index][selected] = e.target.value;
    temp[index][type] = "custome";
    setForm(temp);
  };


  const handleCheckType = (index, e, selected, type) => {
    let temp = [...dataType];
    temp[index][selected] = e.target.value;
    temp[index][type] = "custome";
    setFormType(temp);
  };

  const CheckHandler = (index, e, selected) => {
    // let temp = [...user?.properties];
    // temp[index] = e.target.value;
    // setuser({ ...user, properties: temp });
    // // setForm(temp);
    // console.log("temp", temp, index, e, selected);
  };

  function handleremove_getItem(paramsid, property_id) {
    let item = {
      property_id: property_id,
    };
    deleteCommonFunction(`/groupAdmin/product_property/${paramsid}`,
      "Product property has been deleted successfully", () => CustomeProductPropertyGet(), item);
  }


  function handleremove_getItemType(paramsid, property_id) {
    let item = {
      type_id: property_id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("hey", paramsid);
        api
          .delete(`/groupAdmin/product_order_type/${paramsid}`, {
            headers: headers,
            data: item,
          })
          .then((result) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Product order property has been deleted successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            OrderProductPropertyGet();
          });
      }
    });
  }

  const [name, setname] = useState("");
  const [permasID, setpermasID] = useState("");
  const [property_ID, setproperty_ID] = useState("");


  const [nameType, setnameType] = useState("");
  const [permasIDType, setpermasIDType] = useState("");
  const [property_IDType, setproperty_IDType] = useState("");

  function handleSubmitEditName(event) {
    event.preventDefault();
    setError(null);

    let item = {
      property_id: property_ID,
      type: "custom",
      name: name,
    };

    api
      .patch(`/groupAdmin/product_property/${permasID}`, item, { headers })
      .then((res) => {
        showSuccessNotification("Product properties has been edited succesfully.")
        CustomeProductPropertyGet();
        setname("");
        setpermasID("");
        setproperty_ID("");
        window.location.reload(true);
        // setmenuId([]);
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }


  function handleSubmitEditNameType(event) {
    event.preventDefault();
    setErrorType(null);

    let item = {
      type_id: property_IDType,
      // type: "custom",
      name: nameType,
    };

    api
      .patch(`/groupAdmin/product_order_type/${permasIDType}`, item, { headers })
      .then((res) => {
        showSuccessNotification("Product order properties has been edited succesfully.")
        CustomeProductPropertyGet();
        setnameType("");
        setpermasIDType("");
        setproperty_IDType("");
        window.location.reload(true);
        // setmenuId([]);
      })
      .catch((err) => {
        if (err.response) {
          setErrorType(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const userPermissionType_ = userPermissionType === "groupadmin"



  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Properties Management
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.push("/group_admin/settings")}
                          className="btn btn-primary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                        >
                          Go Back
                        </button>
                        {/* {/* <NoteState /> */}
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <form onSubmit={handleSubmit}>
                            <div className="rounded border p-10">
                              <div className="row">
                                <div className="col-sm-10">
                                  <h3>Custom Properties</h3>
                                </div>
                                <div className="col-sm-2">

                                  {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "properties_management" &&
                                      permission?.access?.includes("create")
                                    )) && (
                                      <button
                                        type="button"
                                        onClick={haldleNewRow}
                                        className="btn btn-primary addtimes"
                                      >
                                        Add Property
                                      </button>)}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                {user?.properties?.map((main, index) => (
                                  <div className="mb-4" key={index}>
                                    <input
                                      className="form-input"
                                      type="text"
                                      value={main?.name}
                                      // readOnly={
                                      //   main?.type == "default" ? true : false
                                      // }
                                      readOnly
                                      onChange={(e) =>
                                        CheckHandler(index, e, main.name)
                                      }
                                    />
                                    {main?.type !== "default" && (
                                      <>
                                        {(userPermissionType_ ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "properties_management" &&
                                            permission?.access?.includes("edit")
                                          )) && (
                                            <Link to="#kt_modal_1" data-bs-toggle="modal">
                                              <EditIcon
                                                className="mx-4 text-black"
                                                onClick={(e) => {
                                                  setpermasID(user?._id);
                                                  setproperty_ID(main?._id);
                                                  setname(main?.name);
                                                }}
                                              />
                                            </Link>)}

                                        {(userPermissionType_ ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "properties_management" &&
                                            permission?.access?.includes("delete")
                                          )) && (
                                            <DeleteIcon
                                              className="mx-2 text-black"
                                              onClick={() => handleremove_getItem(user?._id, main?._id)}
                                            />)},
                                      </>
                                    )}

                                  </div>
                                ))}

                                <div className="mb-4" id="sdsad">
                                  {data.map((details, index) => (
                                    <React.Fragment>
                                      <div
                                        className="d-flex justify-content-start my-1"
                                        key={index}
                                      >
                                        <input
                                          className="form-input"
                                          value={details?.name}
                                          onChange={(e) =>
                                            handleCheck(index, e, "name", "type")
                                          }
                                          type="text"
                                        />

                                        <div
                                          id="deletebtn"
                                          className="btn btn-danger1 pt-0 remCF"
                                          onClick={() => handleremove(index)}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>

                              <div className="col-md-12"> {errorDiv} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type={array3?.length == 0 ? "" : "submit"}
                                  disabled={array3?.length == 0 ? true : false}
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() => history.push("/group_admin/settings")}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="py-5" >
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <form onSubmit={handleSubmitType}>
                            <div className="rounded border p-10">
                              <div className="row">
                                <div className="col-sm-10">
                                  <h3>Order Type Properties</h3>
                                </div>
                                <div className="col-sm-2">
                                  {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "properties_management" &&
                                      permission?.access?.includes("create")
                                    )) && (
                                      <button
                                        type="button"
                                        onClick={haldleNewRowType}
                                        className="btn btn-primary addtimes"
                                      >
                                        Add Property
                                      </button>)}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                {userType?.order_type?.map((main, index) => (
                                  <div className="mb-4" key={index}>
                                    <input
                                      className="form-input"
                                      type="text"
                                      style={{ textTransform: 'capitalize' }}
                                      value={main?.name}
                                      readOnly
                                      onChange={(e) =>
                                        CheckHandler(index, e, main.name)
                                      }
                                    />
                                  </div>
                                ))}

                                <div className="mb-4" id="sdsad">
                                  {dataType.map((details, index) => (
                                    <React.Fragment>
                                      <div
                                        className="d-flex justify-content-start my-1"
                                        key={index}
                                      >
                                        <input
                                          className="form-input"
                                          style={{ textTransform: 'capitalize' }}
                                          value={details?.name}
                                          onChange={(e) =>
                                            handleCheckType(index, e, "name", "type")
                                          }
                                          type="text"
                                        />

                                        <div
                                          id="deletebtn"
                                          className="btn btn-danger1 pt-0 remCF"
                                          onClick={() => handleremoveType(index)}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>

                              <div className="col-md-12"> {errorDivType} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type={array3Type?.length == 0 ? "" : "submit"}
                                  disabled={array3Type?.length == 0 ? true : false}
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() => history.push("/group_admin/settings")}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>


                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <form onSubmit={handleSubmit}>
                            <div className="rounded border p-10">
                              <div className="row">
                                <div className="col-sm-10">
                                  <h3>Add-on Properties</h3>
                                </div>
                                <div className="col-sm-2">
                                  {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "properties_management" &&
                                      permission?.access?.includes("create")
                                    )) && (
                                      <button
                                        type="button"
                                        onClick={haldleNewRow}
                                        className="btn btn-primary addtimes"
                                      >
                                        Add Property
                                      </button>)}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                {user?.properties?.map((main, index) => (
                                  <div className="mb-4" key={index}>
                                    <input
                                      className="form-input"
                                      type="text"
                                      value={main?.name}
                                      readOnly
                                      onChange={(e) =>
                                        CheckHandler(index, e, main.name)
                                      }
                                    />
                                    {main?.type !== "default" && (
                                      <>
                                        {(userPermissionType_ ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "properties_management" &&
                                            permission?.access?.includes("edit")
                                          )) && (
                                            <Link to="#kt_modal_1" data-bs-toggle="modal">
                                              <EditIcon
                                                className="mx-4 text-black"
                                                onClick={() => {
                                                  setpermasID(user?._id);
                                                  setproperty_ID(main?._id);
                                                  setname(main?.name);
                                                }}
                                              />
                                            </Link>)}

                                        {(userPermissionType_ ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "properties_management" &&
                                            permission?.access?.includes("delete")
                                          )) && (
                                            <DeleteIcon
                                              className="mx-2 text-black"
                                              onClick={() => handleremove_getItem(user?._id, main?._id)}
                                            />)}
                                      </>
                                    )}

                                  </div>
                                ))}

                                <div className="mb-4" id="sdsad">
                                  {data.map((details, index) => (
                                    <React.Fragment>
                                      <div
                                        className="d-flex justify-content-start my-1"
                                        key={index}
                                      >
                                        <input
                                          className="form-input"
                                          value={details?.name}
                                          onChange={(e) =>
                                            handleCheck(index, e, "name", "type")
                                          }
                                          type="text"
                                        />

                                        <div
                                          id="deletebtn"
                                          className="btn btn-danger1 pt-0 remCF"
                                          onClick={() => handleremove(index)}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>

                              <div className="col-md-12"> {errorDiv} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type={array3?.length == 0 ? "" : "submit"}
                                  disabled={array3?.length == 0 ? true : false}
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() => history.push("/group_admin/settings")}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmitEditName}>
                <h3>
                  Edit Custom Properties
                </h3>
                <div className="my-10">
                  <label
                    className="required form-label"
                  >
                    Product propertie name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Enter product propertie name"
                    value={name}
                    required
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <br />
                  <div className="loader" style={{ display: "none" }}>
                    <img src="/assets/loader.gif" />
                  </div>
                </div>
                <ErrorMessage error={errorDiv} />
                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Edit</span>
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      setname("");
                      setpermasID("");
                      setproperty_ID("");
                    }}
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>{" "}

      <div className="modal fade" tabIndex={-1} id="kt_modal_2">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmitEditNameType}>
                <h3>
                  Order Type Properties
                </h3>
                <div className="my-10">
                  <label
                    className="required form-label"
                  >
                    Product propertie name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Enter product propertie name"
                    value={nameType}
                    required
                    onChange={(e) => setnameType(e.target.value)}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <br />
                  <div className="loader" style={{ display: "none" }}>
                    <img src="/assets/loader.gif" />
                  </div>
                </div>
                <ErrorMessage error={errorDivType} />
                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Edit</span>
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      setnameType("");
                      setpermasIDType("");
                      setproperty_IDType("");
                    }}
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
