import { VOUCHERPURCHASED } from "../action-types";


const getVoucherPurchasedList = () => ({
  type: VOUCHERPURCHASED.GET_VOUCHERPURCHASED_LIST,
});


const setVoucherPurchasedList = (voucherpurlist) => ({
  type: VOUCHERPURCHASED.SET_VOUCHERPURCHASED_LIST,
  voucherpurlist,
});


export {
      getVoucherPurchasedList ,setVoucherPurchasedList
};
