import React, { useEffect, useMemo, useRef, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../NewTableCommon/tableFilter";
import useDrawer from "../../../../context/DrawerContext";
import { StockPermissions, userPermissionType } from "../../../Comman/UserPermission";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import CommonStock from "./CommonStock";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import useError from "../../../Comman/Error/useError";
import moment from "moment";
import { tableOptionStockViewList } from "../../../NewTableCommon/tableOption";
import GoBackButton from "../../../Comman/GoBackButton/GoBackButton";
// import { branchfilterAndMapData } from "../../../Comman/BranchList";
import { getBranchList } from "../../../../Store";


function ViewStockManagement({ getBranchList, BranchList_ }) {
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[3];
      const { id, branchId, catagoryId, productId, productName, productunit, branchcurrency } = useParams();

      let history = useHistory();
      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

      // const userPermissionType_ = userPermissionType === "groupadmin"
      const [Type, setType] = useState(sessionStorage.getItem("TypeStock") || "view_stock_list")
      const [stockList, setstockList] = useState([])

      // console.log("Type" ,Type)

      const { error, showError, clearError } = useError();


      const columnsDataString = sessionStorage.getItem(`${Type}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const [BranchId, setBranchID] = useState(branchName || "");
      const [isError, setIsError] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [isRefetching, setIsRefetching] = useState(false);
      const [columnFilters, setColumnFilters] = useState([]);
      const [globalFilter, setGlobalFilter] = useState('');
      const [sorting, setSorting] = useState([]);
      const [rowCount, setRowCount] = useState(0);
      const [pagination, setPagination] = useState("");
      const [quantity, setquantity] = useState("");
      const [price, setprice] = useState("")
      // const [stockList ,setstockList] = useState([])


      const [FilterData, SetFilterData] = useState("");

      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };

      // useEffect(()=>{
      //       getBranchList();
      // },[])

      const prevPaginationRef = useRef();

      useEffect(() => {
            if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
                  sessionStorage.setItem(`${Type}_paginationState`, JSON.stringify(pagination));
            }
            prevPaginationRef.current = pagination;
      }, [pagination]);

      const storedState = sessionStorage.getItem(`${Type}_paginationState`);

      useEffect(() => {
            if (storedState !== null) {
                  setPagination(JSON.parse(storedState));
            } else {
                  setPagination({ pageIndex: 0, pageSize: 10 });
            }
      }, [storedState]);


      // console.log("storedState", storedState);
      // console.log("globalFilter", globalFilter)

      const prevSorting = useRef([]);

      const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
      prevSorting.current = sorting;

      useEffect(() => {
            if (pagination.pageIndex, pagination.pageSize) {
                  getStockList();
            }
      }, [pagination.pageIndex, pagination.pageSize, globalFilter ?? '', BranchId?.value,
            sortingChanged, FilterData?.Status?.value, Type]);

      function arraysAreEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                  if (arr1[i] !== arr2[i]) return false;
            }
            return true;
      }


      const getStockList = async () => {
            setstockList([]);
            setIsLoading(true);
            setIsError(false)
            const val_ = Type === "view_stock_list" ? "added" : "used"
            let url = `/groupadmin/branch_stock/listStock/${id}/${val_}?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            if (BranchId?.value) {
                  url += `&branch_id=${BranchId?.value}`;
            }
            if (globalFilter) {
                  url += `&search=${globalFilter ?? ''}`;
            }

            if (FilterData) {
                  url += `&category_id=${FilterData?.Status?.value ?? ''}`;
            }

            if (sorting?.length > 0 && sorting[0]?.id) {
                  switch (sorting[0]?.id) {
                        case "branch":
                              url += `&sort_field=branch.branch_name_EN`;
                              break;
                        case "datetime":
                              url += `&sort_field=order_date`;
                              break;
                        // Add additional cases as needed
                        default:
                              url += `&sort_field=${sorting[0]?.id}`;
                              break;
                  }
            }

            if (sorting?.length > 0 && sorting[0]?.desc === true) {
                  url += `&sort_type=${'-1'}`;
            }
            if (sorting?.length > 0 && sorting[0]?.desc === false) {
                  url += `&sort_type=${'1'}`;
            }
            try {
                  const res = await api.get(url, { headers });
                  setRowCount(res.data.data?.count || 0);
                  setstockList(res.data.data?.stockData);
                  // console.log("ddd" ,res.data.data)
            } catch (err) {
                  if (err) {
                        setRowCount(0);
                        setIsError(true);
                  }
            } finally {
                  setIsLoading(false);
            }
      };



      // console.log("stockList" ,stockList)

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${Type}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      // console.log("dineInColumnsData" ,dineInColumnsData)

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };


      const handleChangeType = (newType) => {
            setType(newType); // Update the type state
            sessionStorage.setItem("TypeStock", newType);
            setUpdateColumns(prevState => !prevState)
      };

      const ColumnsStockList = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'quantity',
                        header: 'Quantity Added',
                        size: 250,
                        Cell: ({ row }) => `${row?.original?.quantity} ${row?.original?.unit}`,
                  },
                  {
                        accessorKey: 'remaining_quantity',
                        header: 'Remaining Quantity',
                        size: 250,
                        Cell: ({ row }) => `${row?.original?.remaining_quantity} ${row?.original?.unit}`,
                  },
                  {
                        accessorKey: 'total_price',
                        header: 'Price',
                        size: 250,
                        Cell: ({ row }) => `${branchcurrency} 
                        ${Number(row?.original?.total_price).toFixed(2)}`,
                  },
                  {
                        accessorKey: 'created_at',
                        header: 'Date & Time',
                        size: 250,
                        Cell: ({ row }) => `${moment?.(row?.original?.created_at)?.format("DD/MM/YYYY")} 
                        ${moment?.(row?.original?.created_at)?.format("h:mm A")}`,
                  },
                  {
                        accessorKey: 'created_by_username',
                        header: 'Added by',
                        size: 250,
                        Cell: ({ row }) => `${row?.original?.created_by_username}`,
                  },
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const ColumnsStockUsed = useMemo(() => {
            const columns = [

                  {
                        accessorKey: 'quantity',
                        header: 'Quantity',
                        size: 250,
                        Cell: ({ row }) => `${row?.original?.quantity} ${row?.original?.unit}`,
                  },
                  // {
                  //       accessorKey: 'price',
                  //       header: 'Price',
                  //       size: 250,
                  //       Cell: ({ row }) => Number(row?.original?.price).toFixed(2),
                  // },
                  {
                        accessorKey: 'message',
                        header: 'Description',
                        size: 250,
                        Cell: ({ row }) => (row?.original?.message),
                  },
                  {
                        accessorKey: 'created_at',
                        header: 'Date & Time',
                        size: 250,
                        Cell: ({ row }) => `${moment?.(row?.original?.created_at)?.format("DD/MM/YYYY")} 
                        ${moment?.(row?.original?.created_at)?.format("h:mm A")}`
                  },
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const tableStockList = useMaterialReactTable({
            columns: ColumnsStockList,
            data: stockList,
            ...tableOptionStockViewList({
                  getStockList,
                  url23,
                  handleClickColum_,
                  handleFilterClick,
                  windowHeight,
                  filterData,
                  DisplayCol,
                  isError,
                  isRefetching,
                  columnFilters,
                  globalFilter,
                  sorting,
                  setColumnFilters,
                  setGlobalFilter,
                  setPagination,
                  setSorting,
                  rowCount,
                  isLoading,
                  pagination,
            }),
      });

      const tableStockUsed = useMaterialReactTable({
            columns: ColumnsStockUsed,
            data: stockList,
            ...tableOptionStockViewList({
                  getStockList,
                  url23,
                  handleClickColum_,
                  handleFilterClick,
                  windowHeight,
                  filterData,
                  DisplayCol,
                  isError,
                  isRefetching,
                  columnFilters,
                  globalFilter,
                  sorting,
                  setColumnFilters,
                  setGlobalFilter,
                  setPagination,
                  setSorting,
                  rowCount,
                  isLoading,
                  pagination,
            }),
      });


      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

      const [show, setShow] = useState(false);

      const handleClose = () => {
            setShow(false);
            setprice("");
            setquantity("");
            showError(null)
      };

      const handleShow = () => setShow(true);

      function handleSubmitProduct(event) {
            event.preventDefault();
            showError(null);

            if (!quantity) {
                  showError("Please enter stock quantity.");
                  return;
            }
            if (!price) {
                  showError("Please enter stock price.");
                  return;
            }
            let item = {
                  inv_category_id: catagoryId,
                  inv_product_id: productId,
                  branch_id: branchId,
                  inv_product_name: productName,
                  unit: productunit,
                  price: Number(price),
                  quantity: Number(quantity)
            }

            api
                  .post(`/groupadmin/branch_stock/addStock/${id}`, item, { headers })
                  .then(() => {
                        showSuccessNotification("Stock has been added successfully.");
                        handleClose();
                        getStockList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              showError(err.response?.data?.error?.message);
                        }
                  });
      }

      return (
            <React.Fragment>
                  <ToastContainer
                        toastStyle={{
                              border: "1px solid black",
                              fontSize: "17px",
                        }}
                  />
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Stock Management : {productName}
                                                                              </h1>
                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >



                                                                              {(userPermissionType === "groupadmin" || StockPermissions?.access?.includes("create")) &&
                                                                                    Type === "view_stock_list" &&
                                                                                    <button
                                                                                          className="btn btn-primary me-3"
                                                                                          type='button'
                                                                                          onClick={handleShow}
                                                                                    >
                                                                                          Add Stock
                                                                                    </button>}
                                                                              {/* <GoBackButton /> */}
                                                                              <button
                                                                                    onClick={() => history.push("/group_admin/stock_management")}
                                                                                    className="btn btn-primary"
                                                                                    data-kt-menu-trigger="click"
                                                                                    data-kt-menu-attach="parent"
                                                                                    data-kt-menu-placement="bottom-end"
                                                                              >
                                                                                    Go Back
                                                                              </button>

                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="rounded border">
                                                                  <CommonStock Type={Type} handleChangeType={handleChangeType} />
                                                                  <div className="tab-content" id="myTabContent">
                                                                        <div
                                                                              className="tab-pane fade show active"
                                                                              id="kt_tab_pane_1"
                                                                              role="tabpanel"
                                                                        >

                                                                              <div className="mt-8">
                                                                                    <MaterialReactTable
                                                                                          table={Type === "view_stock_list" ? tableStockList : tableStockUsed}
                                                                                    /></div>
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>

                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={Type}
                  />

                  <TableFilter
                        // open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                  />
                  <Modal dialogClassName="modal-30w"
                        aria-labelledby="example-custom-modal-styling-title"
                        show={show} centered onHide={handleClose}>
                        <Modal.Body>
                              <Modal.Title className="mb-2 heading">
                                    Add Stock</Modal.Title>
                              <form onSubmit={handleSubmitProduct}>
                                    <div className="mb-5">
                                          <div className="discriptionline">
                                                Please enter appropriate details to add stock.
                                          </div>
                                    </div>
                                    <div className="mb-5">
                                          <label

                                                className="required form_label_"
                                          >
                                                Quantity
                                          </label>
                                          <div className="d-flex align-items-center">
                                                <input
                                                      type="number"
                                                      className="form-control form-control"
                                                      placeholder="Enter Stock Quantity"
                                                      value={quantity}
                                                      // onChange={(e) => setquantity(e.target.value)}

                                                      onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Prevent zero and allow only positive numbers
                                                            if (value !== "0") {
                                                                  setquantity(value);
                                                            }
                                                          }}
                                                />
                                                <span style={{ marginLeft: "-3rem", color: "#667085", fontSize: '14px', fontWeight: 400 }}>{productunit}</span>
                                          </div>
                                    </div>

                                    <div className="mb-5">
                                          <label className="required form_label_">
                                                Stock Price
                                          </label>
                                          <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Stock Price"
                                                value={price}
                                                // onChange={(e) => {
                                                //       // const trimmedValue = e.target.value.trimStart(); // Trim leading spaces only
                                                //       setprice(e.target.value);
                                                // }}

                                                onChange={(e) => {
                                                      const value = e.target.value;
                                                      // Prevent zero and allow only positive numbers
                                                      if (value !== "0") {
                                                            setprice(value);
                                                      }
                                                    }}
                                          />
                                    </div>
                                    <div className="col-md-12"> {error && <div className="text-center alert alert-danger">{error}</div>} </div>
                                    <div className="row mt-3 flex-center d-flex">
                                          <div className="col-md-6">
                                                <button type="submit" className="btn btn-primary w-100">
                                                      Save
                                                </button>
                                          </div>
                                          <div className="col-md-6">
                                                <button
                                                      type="button"
                                                      onClick={handleClose}
                                                      className="btn btn-danger w-100"
                                                >
                                                      Cancel
                                                </button>
                                          </div>
                                    </div>

                              </form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
      // stockList: state.stockList,
      // inventoryCategoryList_: state
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
            // getStockList: () => dispatch(getStockList()),
            // getInventoryCatList: () => dispatch(getInventoryCatList())
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStockManagement);

