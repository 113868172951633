import React, { useEffect, useState } from "react";
import { useHistory, } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import useDrawer from "../../../../../context/DrawerContext";
import GoBackButton from "../../../../Comman/GoBackButton/GoBackButton";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { submitForm } from "../../../../Comman/AddCommonFun/AddCommonFunction";
import useError from "../../../../Comman/Error/useError";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";


function AddonAddProperties(props) {

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
     
     
      const { error, showError, clearError } = useError();
      const [title, settitle] = useState("")
      let history = useHistory();
      const [subMenus, setSubMenus] = useState([]);



      const handleAddSubMenu = () => {
            setSubMenus([...subMenus, { name: '', price: '' }]);
      };

      useEffect(() => {
            applyFilterMeHandlers(); // Apply the jQuery handlers
          }, [subMenus?.length]);

      const handleRemoveSubMenu = (index) => {
            const updatedSubMenus = [...subMenus];
            updatedSubMenus.splice(index, 1);
            setSubMenus(updatedSubMenus);
      };


      const handleSubMenuChange = (index, field, value) => {
            const updatedSubMenus = [...subMenus];

            // Check for duplicates before updating the state
            if (field === 'seq_no') {
                  const newValue = value.replace(/^0/, "").replace(/\D/g, ""); // Remove leading 0
                  const isDuplicate = updatedSubMenus.some((submenu, i) => i !== index && submenu.seq_no === newValue);

                  if (isDuplicate) {
                        // Handle duplicate value, such as showing an error message
                        console.log("Duplicate value found");
                        return;
                  }
            }

            updatedSubMenus[index][field] = value;
            setSubMenus(updatedSubMenus);
      };



      function handleSubmit(event) {
            event.preventDefault();
            showError(null);

            let item = {
                  title: title,
                  items: subMenus.map(subMenu => ({
                        name: subMenu.name,
                        price: subMenu.price
                  }))
            };
            submitForm("/groupAdmin/options", item, history, showError, clearError, "Your work has been saved successfully.");

      }

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">

                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>

                                                            <h1
                                                                  className="anchor fw-bolder mb-5"
                                                                  id="vertical-horizontal-scroll"
                                                            >
                                                                  Settings
                                                            </h1>
                                                            <hr />

                                                            <div className="row col-md-12">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Options - Add
                                                                        </h3>
                                                                  </div>

                                                                  <div
                                                                        className="col-md-3 text-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <GoBackButton />
                                                                  </div>
                                                            </div>

                                                            <div className="py-5">
                                                                  <div className="rounded border p-10">
                                                                        <div className="tab-content" id="myTabContent">
                                                                              <div
                                                                                    className="tab-pane fade show active"
                                                                                    id="kt_tab_pane_1"
                                                                                    role="tabpanel"
                                                                              >
                                                                                    <div className="card card-docs mb-3">
                                                                                          <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                                                                <form onSubmit={handleSubmit}>
                                                                                                      <div className="rounded border p-10">
                                                                                                            <div className="row col-md-12 mb-8">
                                                                                                                  <div className="col-md-12">
                                                                                                                        <h3>
                                                                                                                              Add  Options
                                                                                                                        </h3>
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            <hr />
                                                                                                            <div className="row col-md-12 mt-8 mb-8">
                                                                                                                  <div className="col-md-3">
                                                                                                                        <p>Options Title</p>
                                                                                                                  </div>
                                                                                                                  <div className="col-md-4">
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              className="form-control form-control"
                                                                                                                              placeholder="Enter Options Title"
                                                                                                                              required
                                                                                                                              value={title}
                                                                                                                              onChange={(e) => settitle(e.target.value)}
                                                                                                                        />

                                                                                                                  </div>
                                                                                                                  <div className="col-md-4 mt-3">
                                                                                                                       

                                                                                                                  </div>

                                                                                                            </div>
                                                                                                            

                                                                                                            {subMenus.map((submenu, index) => (
                                                                                                                  <>
                                                                                                                  {index === 0 && <hr />}
                                                                                                                  <div key={index} className="row col-md-12 mt-8">
                                                                                                                        <div className="col-md-3">
                                                                                                                              <p>Options</p>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-4">
                                                                                                                              <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control form-control"
                                                                                                                                    placeholder="Enter Property Name"
                                                                                                                                    value={submenu.name}
                                                                                                                                    required
                                                                                                                                    onChange={(e) => handleSubMenuChange(index, 'name', e.target.value)}
                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-2">
                                                                                                                              <input
                                                                                                                                    type="number"
                                                                                                                                    className="form-control form-control filterme"
                                                                                                                                    value={submenu.price}
                                                                                                                                    required
                                                                                                                                    placeholder="Enter Price"
                                                                                                                                    onChange={(e) => handleSubMenuChange(index, 'price', e.target.value)}
                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-1">
                                                                                                                              <div className="col-md-3 text-end my-2">
                                                                                                                                    <DeleteIcon onClick={() => handleRemoveSubMenu(index)} className="fs-1" />
                                                                                                                              </div>
                                                                                                                        </div>

                                                                                                                  </div>
                                                                                                                  </>
                                                                                                            ))}
                                                                                                            <br />
                                                                                                            <div className="row col-md-12">
                                                                                                                  <div className="col-md-3">

                                                                                                                  </div>
                                                                                                                  <div className="col-md-9">
                                                                                                                        <button
                                                                                                                        style={{ fontWeight: 400 }}
                                                                                                                              type="button"
                                                                                                                              onClick={handleAddSubMenu}
                                                                                                                              className="btn btn-danger">
                                                                                                                              Add More                                                                                                                       </button>
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            <hr />
                                                                                                            <ErrorMessage error={error} />

                                                                                                            <div className="text-center">
                                                                                                                  <button
                                                                                                                        type="submit"
                                                                                                                        id="kt_modal_new_address_submit"
                                                                                                                        className="btn btn-primary"
                                                                                                                  >
                                                                                                                        <span className="indicator-label"> Save </span>
                                                                                                                  </button>
                                                                                                                  <button onClick={() =>
                                                                                                                        history.goBack()
                                                                                                                  } type="button" className="btn btn-danger mx-2">
                                                                                                                        Cancel
                                                                                                                  </button>
                                                                                                            </div>


                                                                                                      </div>
                                                                                                </form>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
      return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonAddProperties);

