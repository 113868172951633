import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import api from "../../../../../../CommonApi/axios";
import $ from "jquery";
import MaterialTable from "material-table";
import headers from "../../../../../../CommonApi/headers";
import { useStyles } from "../../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../../Comman/TableOption";
import useDrawer from "../../../../../../context/DrawerContext";
import CommonTourSetupSetting from "../CommonTourSetupSetting";
import { UserPermissionAllData } from "../../../../../Comman/UserPermission";
import { Tabletoursetup } from "../Tabletoursetup";
import AddNewCommonButton from "../AddNewCommonButton";
window.jquery = window.$ = $;




function GroupAdminTourSetupLocationList() {


    const [user, setuser] = useState([]);

    useEffect(() => {
        LocationList();
    }, []);

    const [error, setError] = useState(null);


    function LocationList() {
        api
            .post(`/groupadmin/tourgroup/list_location`, {}, { headers })
            .then((res) => {
                const tableData = res?.data?.data
                setuser(tableData)
            })
            .catch((err) => {
                if (err.response) {
                    setError(err.response?.data?.error?.message);
                }
            });
    }

    let history = useHistory();

    const classes = useStyles();

    const columns = [
        {
            title: "Sr.No.",
            render: (rowData) => {
                return <>{rowData.tableData.id + 1}</>;
            },
        },
        {
            title: "Locations",
            field: "location_name",
        },

    ];



  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                    <h1
                                        className="anchor fw-bolder mb-5"
                                        id="vertical-horizontal-scroll"
                                    >
                                        Settings
                                    </h1>
                                    <hr />

                                    <div className="row col-md-12 mb-10" style={{ alignItems: 'center', }}>
                                        <div
                                            className="col-md-9 "
                                            data-kt-docs-table-toolbar="base"
                                        >
                                            <h3
                                                className="anchor fw-bolder mb-5 d-inline"
                                                id="custom-form-control"
                                            >
                                                Tour Setup - Location List
                                            </h3>
                                        </div>

                                        <div
                                            className="col-md-3 text-end"
                                            data-kt-docs-table-toolbar="base"
                                        >
                    

                    <AddNewCommonButton />
                                        </div>
                                    </div>

                                    <div className="py-5">
                                        <div className="rounded border p-10">
                                        <CommonTourSetupSetting />
                                            <div className="tab-content" id="myTabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="kt_tab_pane_1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card card-docs mb-2">
                                                        <div className="card-body fs-6 p-0 text-gray-700">

                                                            <div className={classes.root}>
                                                                <MaterialTable
                                                                    title=""
                                                                    data={user}
                                                                    columns={columns}
                                                                    options={Tableoptions}
                                                                    actions={Tabletoursetup(history, LocationList, {
                                                                        editPath: "",
                                                                        deletePath: "groupadmin/tourgroup/delete_location"
                                                                    }, {
                                                                        editTooltip: "",
                                                                        deleteTooltip: "Delete Location"
                                                                    }, {
                                                                        deleteMessage: "Location has been deleted successfully."
                                                                    }, {
                                                                        access: UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                            permission?.key === "tour_setup"),
                                                                    }
                                                                    )}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                        <GroupAdminUperSide />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}



export default GroupAdminTourSetupLocationList;

