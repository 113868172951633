import { DASHBOARD } from "../../actions";


const initialState = {
    dashboardCount: {},
    loading: false,
  };
  
const dashboardCountReducer = (state = initialState, action) => {
    // switch (action.type) {
    //     case DASHBOARD.CLEAR_DASHBOARD_COUNT:
    //         return {};
    //     case DASHBOARD.SET_DASHBOARD_COUNT:
    //         return {...action?.dashboardcount};
    //     default:
    //         return state;
    // }

    switch (action.type) {
        case DASHBOARD.SET_DASHBOARD_COUNT:
          return { ...state, dashboardCount: action?.dashboardcount };
        case DASHBOARD.CLEAR_DASHBOARD_COUNT:
          return { ...state, dashboardCount: {} };
        case DASHBOARD.SET_LOADING:
          return { ...state, loading: action.payload };
        default:
          return state;
}}
  
export default dashboardCountReducer;
  