import { useState } from "react";

const useDrawer = () => {


  const [drawerPos, setDrawerPos] = useState(() => {
    const drawerPos_ = sessionStorage.getItem('drawerPos');
    return Number(drawerPos_) || 1; 
  });

  const handleDrawer = () => {
    const newPos = drawerPos === 1 ? 2 : 1;
    sessionStorage.setItem('drawerPos', newPos);
    setDrawerPos(newPos);
  };

  const getMainClass = () => {
    if (drawerPos === 1) {
      return ["mainMin"];
    } else if (drawerPos === 2) {
      return ["mainOpen"];
    } else {
      return [];
    }
  };

  const getMainClassCustome = () => {
    if (drawerPos === 1) {
      return ["mainMinCustome"];
    } else if (drawerPos === 2) {
      return ["mainOpenCustome"];
    } else {
      return [];
    }
  };

  const getViewDetails = () => {
    if (drawerPos === 1) {
      return ["mainMinDetails"];
    } else if (drawerPos === 2) {
      return ["mainOpenDetails"];
    } else {
      return [];
    }
  };

  const mainClass = getMainClass();
  const mainClassCustome = getMainClassCustome();
  const maingetViewDetails = getViewDetails();

  return { drawerPos, handleDrawer, mainClass, mainClassCustome ,maingetViewDetails };
};

export default useDrawer;
