let RESGroup = localStorage.getItem("RESGroup");

const UserPermission = JSON.parse(RESGroup);
const UserPermissionAllData = JSON.parse(RESGroup);




export const DashbordPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "dashboard")
export const BranchesPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "branch")
export const TablesPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "tables")
export const OrdersPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "orders")
export const MenuPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "menus")
export const StockPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "stock")


export const CouponsAndVouchersPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "coupons")
export const CouponsPermissions = UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "coupons")
export const VouchersPermissions = UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "vouchers")


export const TourGroupsPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "tour_groups")
export const EmailMarketingPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "email_marketing")
export const SubscriptionsPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "subscriptions")
export const EnquiryPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "enquiries")
export const KPGPTPermissions =  UserPermissionAllData?.role_details?.permissions?.find(item => item.key === "KPGPT")
export const ReportsPermissions =  UserPermissionAllData?.role_details?.reports?.filter(item => item.access.length > 0)
export const SettingsPermissions =  UserPermissionAllData?.role_details?.settings?.filter(item => item.access.length > 0)

export const userPermissionType = UserPermission?.user_type;
export { UserPermissionAllData };