import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { PRINTER, setPrinterCategoryList, setPrinterList} from "../../actions";

function* callPrinterList() { 
  try {
      const response = yield call(api.get, "/groupAdmin/branchPrinter/get-branch-printer" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setPrinterList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: PRINTER.CLEAR_PRINTER_LIST });
      }
    }
}


function* callPrinterCatagoryList() { 
  try {
      const response = yield call(api.get, "/groupadmin/printer-category/printer-list" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setPrinterCategoryList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: PRINTER.CLEAR_PRINTERCATEGORY_LIST });
      }
    }
}


export function* watchPrinterList() {
  yield takeEvery(PRINTER.GET_PRINTER_LIST, callPrinterList);
}


export function* watchPrinterCatagoryList() {
  yield takeEvery(PRINTER.GET_PRINTERCATEGORY_LIST, callPrinterCatagoryList);
}
