import { INVENTORY } from "../../actions";

const inventoryCategoryListReducer = (state = [], action) => {
  switch (action.type) {
    case INVENTORY.CLEAR_INVENTORY_CATEGORY:
      return [];
    case INVENTORY.SET_INVENTORY_CATEGORY:
      return [...action.inventoryCatList];
    default:
      return state;
  }
};

const inventoryProductListReducer = (state = [], action) => {
  switch (action.type) {
    case INVENTORY.CLEAR_INVENTORY_PRODUCT:
      return [];
    case INVENTORY.SET_INVENTORY_PRODUCT:
      return [...action.inventoryProList];
    default:
      return state;
  }
};

export { inventoryCategoryListReducer, inventoryProductListReducer };
