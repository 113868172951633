import React from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import api from "../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import { Link, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import headers from "../../../../../CommonApi/headers";
import Select from "react-select";
import { useState } from "react";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
window.jquery = window.$ = $;

export default function GroupAdminPosSettingsBusinessSetup() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[6];
  let history = useHistory();
  const location = useLocation();

  const userPermissionType_ = userPermissionType === "groupadmin"


  ///////////////////////////// Get Bussnes ////////////////////////

  const [getBussnes, setGetBusness] = useState([]);
  const [busId, setbusId] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );
  function handleSubmitGetBussnies(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();

    let item = {
      branch_id: location.state.branchID,
    };
    api
      .post(`/groupadmin/settings/POS/kseries/get_business`, item, {
        headers,
      })
      .then((res) => {
        const options =
          res.data?._embedded?.businessList[0]?.businessLocations?.map(
            (item) => ({
              label: item.blName,
              value: item.blId,
            })
          );
        setGetBusness(options);
      })
      .catch((err) => {
        if (err.response) {
          setError(err?.response?.data?.error?.message);
        }
      });
  }

  function SaveBussnies(event) {
    event.preventDefault();
    setError(null);

    if (busId == null || busId == "") {
      setError("Pleace Select Data");
    } else {
      $(".loader").show();

      let item = {
        branch_id: location.state.branchID,
        businesss_id: String(busId),
      };
      api
        .post(`/groupadmin/settings/POS/kseries/save_business`, item, {
          headers,
        })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Bussiness has been added succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          if (err.response) {
            console.log(err?.response?.data?.error?.message);
          }
        });
    }
  }

  ///////////////////////////// Get Bussnes ////////////////////////

  ///////////////////////////// Get Menu ////////////////////////////

  const [error1, setError1] = useState(null);
  const errorDiv1 = error1 ? (
    <div className="text-center alert alert-danger">{error1}</div>
  ) : (
    ""
  );

  const [menuId, setmenuId] = useState("");
  const [GetMenu, setGetMenu] = useState([]);

  function handleSubmitGetMenu(event) {
    event.preventDefault();
    setError1(null);

    if (busId == null || busId == "") {
      setError("Pleace Select business id");
    } else {
      $(".loader").show();

      let item = {
        branch_id: location.state.branchID,
        business_id: String(busId),
      };
      api
        .post(`/groupadmin/settings/POS/kseries/get_menu`, item, {
          headers,
        })
        .then((res) => {
          const options = res.data?.map((item) => ({
            label: item.menuName,
            value: item.ikentooMenuId,
          }));
          setGetMenu(options);
        })
        .catch((err) => {
          if (err.response) {
            setError1(err.response?.data?.error?.message);
          } else if (err.request) {
            setError1(err.request);
          } else {
            setError1(err.message);
          }
        });
    }
  }

  function SaveMenu(event) {
    event.preventDefault();
    setError1(null);

    if (menuId == null || menuId == "") {
      setError1("Pleace Select Data");
    } else {
      $(".loader").show();

      let item = {
        branch_id: location.state.branchID,
        menu_id: String(menuId),
      };
      console.log("item", item);
      api
        .post(`/groupadmin/settings/POS/kseries/save_menu`, item, {
          headers,
        })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Menu has been save succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          if (err.response) {
            setError1(err.response?.data?.error?.message);
          } else if (err.request) {
            setError1(err.request);
          } else {
            setError1(err.message);
          }
        });
    }
  }

  //////////////////////////////////////// Save Menu ////////////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        POS K-Series / <span>Pos Business setup</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() =>
                            history.push("/group_admin/settings/pos")
                          }
                          className="btn btn-primary"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="row rounded border p-10">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            className={
                              url === "auth" ? "nav-link active" : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/settings/K_Series/pos/pos_settings/auth",
                              state: {
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Auth
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url === "business_setup"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/settings/K_Series/pos/pos_settings/business_setup",
                              state: {
                                // menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Business Setup
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <br />
                        <div className="row col-sm-12">
                          <div className="col-sm-6">
                            <div
                              className="card card-docs mb-2"
                              style={{
                                boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                              }}
                            >
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="mb-10">
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={handleSubmitGetBussnies}
                                      className="btn btn-primary"
                                    >
                                      Get Business
                                    </button>
                                  </div>
                                </div>
                                <div className="mb-10">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="required form-label"
                                  >
                                    select
                                  </label>
                                  <Select
                                    className="search-line"
                                    placeholder="Search..."
                                    options={
                                      !getBussnes == ""
                                        ? getBussnes.map((sponsor) => ({
                                            label:
                                              sponsor.label +
                                              " " +
                                              sponsor.value,
                                            value: sponsor.value,
                                          }))
                                        : "No Option"
                                    }
                                    onChange={(opt) => {
                                      setbusId(opt.value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-12"> {errorDiv} </div>

                                <div className="mb-10">
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={SaveBussnies}
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="card card-docs mb-2"
                              style={{
                                boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                              }}
                            >
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="mb-10">
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={handleSubmitGetMenu}
                                      className="btn btn-primary"
                                    >
                                      Get Menu
                                    </button>
                                  </div>
                                </div>
                                <div className="mb-10">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="required form-label"
                                  >
                                    select
                                  </label>
                                  <Select
                                    className="search-line"
                                    placeholder="Search..."
                                    options={
                                      !GetMenu == ""
                                        ? GetMenu.map((sponsor) => ({
                                            label:
                                              sponsor.label +
                                              " " +
                                              sponsor.value,
                                            value: sponsor.value,
                                          }))
                                        : "No Option"
                                    }
                                    onChange={(opt) => {
                                      setmenuId(opt.value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-12"> {errorDiv1} </div>
                                <div className="mb-10">
                                  <div className="d-flex justify-content-center">
                                  {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "pos" &&
                                      (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                    )) && (
                                    <button
                                      type="button"
                                      onClick={SaveMenu}
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </button>)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
    </React.Fragment>
  );
}
