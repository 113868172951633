import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import headers from "../../../../CommonApi/headers";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import Swal from "sweetalert2";
import useDrawer from "../../../../context/DrawerContext";
import { branchfilterAndMapData } from "../../../Comman/BranchList";
import { getBranchList } from "../../../../Store";
import { connect } from "react-redux";
window.jquery = window.$ = $;

function GroupAdminAddNewMenu({getBranchList ,BranchList_}) {
  let history = useHistory();

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [branch_id, setbranch_id] = useState([]);

  const filteredOptions = branchfilterAndMapData(BranchList_);

  useEffect(() => {
    getBranchList();
  }, []);


  /////////////////////////////  Add New Menu ////////////////////////
  const [menu_name, setmenu_name] = useState("");
  const [picture, setPicture] = useState(null);
  
  const [image ,setimage] = useState("");
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPicture(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();
  
    if (menu_name == "") {
      setError("Please Filed Menu Name");
      $(".loader").hide();
    } else if (branch_id == "") {
      setError("Please Select Branch");
      $(".loader").hide();
    } else {
      $(".loader").show();
  
      const item = new FormData();
      item.append("branch_id", branch_id);
      item.append("menu_name", menu_name);
  
      if (image) {
        // Check if the uploaded file is an image and has a valid extension
        const allowedExtensions = ["image/png", "image/jpeg", "image/jpg"];
        if (allowedExtensions.includes(image.type)) {
          item.append("image", image);
        } else {
          setError("Invalid image format. Please upload a PNG, JPEG, or JPG file.");
          $(".loader").hide();
          return; // Stop further processing
        }
      }
  
      api
        .post(`/groupadmin/menu`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Menu has been added succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          history.goBack();
          // if (res.data.message == "Successfully created.") {
          // }
        })
        .catch((err) => {
          if (err.response) {
            $(".loader").hide();
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }
  /////////////////////////////  Add New Menu ////////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Add New Menu
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="mb-10">
                                <label className="required form-label">
                                  Menu Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Menu Name"
                                  required
                                  value={menu_name}
                                  onChange={(e) => setmenu_name(e.target.value)}
                                />
                              </div>

                              <div className="mb-10">
                                <label className="required form-label">
                                  Select Branch
                                </label>
                                <Select
                                  id="select2"
                                  required
                                  className="search-line"
                                  placeholder="Search..."
                                  options={filteredOptions}
                                  onChange={(opt) => setbranch_id(opt.value)}
                                />
                              </div>

                              <div className="mb-10">
                            <label className="col-lg-4 mb-2 col-form-label fw-bold fs-6">
                              Banner Image
                            </label>
                              <div className="text-center user-info mb-4 image-input-wrapper w-125px h-125px">
                                <div
                                  className="image-input image-input-outline"
                                  data-kt-image-input="true"
                                >
                                  
                                  <img
                                    className="image-input-wrapper w-125px h-125px"
                                    src={
                                      picture
                                        ? picture
                                        : "/assets/media/svg/3377061_food_hor_mok_thai_icon.png"
                                    }
                                    alt="profile_img"
                                    style={{height : 125 ,width : 125 ,objectFit : 'cover'}}
                                  ></img>
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    // title="Change avatar"
                                  >
                                    <i className="bi bi-pencil-fill fs-7" />
                                    <input
                                      type="file"
                                      name="avatar"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={onChangePicture}
                                    />
                                  </label>
                                  {picture ? (
                                    <React.Fragment>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                        data-kt-image-input-action="cancel"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                      >
                                        <i
                                          className="bi bi-x fs-2"
                                          onClick={()=>
                                            {setimage("")
                                            setPicture(null)
                                            onChangePicture()
                                          }
                                            
                                          }
                                        />
                                      </span>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                        data-kt-image-input-action="remove"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                        s
                                        title="Remove"
                                      >
                                        <i
                                          onClick={()=>
                                            {setimage("")
                                            setPicture(null)
                                            onChangePicture()
                                          }
                                            
                                          }
                                          className="bi bi-x fs-2"
                                        />
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                              </div>

                              <div className="col-md-12 text-center">
                                    <br />
                                    <div
                                      className="loader"
                                      style={{ display: "none" }}
                                    >
                                      <img src="/assets/loader.gif" />
                                    </div>
                                  </div>

                              <div className="col-md-12"> {errorDiv} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() => history.goBack()}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminAddNewMenu);
