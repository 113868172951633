import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDrawer from "../../../context/DrawerContext";
import { getBranchList } from "../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "../../Comman/BranchList";



 function GroupAdminMenuManagement({getBranchList ,BranchList_}) {
  ///////////////////// Menu List //////////////////////
  const [user, setuser] = useState([]);
  const [userMenu, setuserMenu] = useState([]);
  const [menu_name, setmenu_name] = useState("");
  const [menuId, setmenuId] = useState([]);
  const [menuer, setmenuer] = useState(null);
  const [error, setError] = useState(null);
  const [Filterbranch_id, setFilterbranch_id] = useState("");
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [erroractive, setErroractive] = useState(null);
  const errorDivactive = erroractive ? (
    <div className="text-center alert alert-danger">{erroractive}</div>
  ) : (
    ""
  );

  const filteredOptions = branchfilterAndMapData(BranchList_);


  useEffect(() => {
    groupAdminMenuList();
    getBranchList();
  }, []);
  


  function groupAdminMenuList() {
    api
      .get(`/groupadmin/menu/list`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        // console.log("tableData" ,tableData);
        setuser(tableData);
        setFilterbranch_id(tableData?.length > 0 && {
          label: "All",
          value: "All",
        })
        // var newList = tableData.filter((e) => e.is_active === true);
        setuserMenu(tableData);
      })
      .catch((err) => {
        if (err.response) {
          setmenuer("No Data Found.");
          // if (err.response.data.error.message) {
          //
          // }
        }
      });
  }
  ///////////////////////// Menu List /////////////////////////////

  ///////////////////////// Branch List //////////////////////////

  const [user1, setuser1] = useState([]);
  const [branch_id, setbranch_id] = useState([]);


  /////////////////////////////  Copy Menu ////////////////////////

  function handleSubmitCopyMenu(event) {
    event.preventDefault();
    setError(null);

    if (branch_id == "") {
      setError("Please select branch.");
    } else if (menuId == "") {
      setError("Please select menu.");
    } else if (menu_name == "") {
      setError("Please enter menu name.");
    } else {
      let item = {
        branch_id: branch_id,
        menu_name: menu_name,
        menu_id: menuId,
      };

      api
        .post(`/groupadmin/menu/copy`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Menu details has been copied succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminMenuList();
          setmenu_name("");
          window.location.reload(true);
          // setmenuId([]);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }
  /////////////////////////////  Copy Menu ////////////////////////

  /////////////////// Menu Active/Inactive Edit ///////////////////////////

  function handleSubmitActive(id, value) {
    setErroractive(null);

    let item = {
      is_active: value,
    };

    api
      .patch(`/groupadmin/menu/updateStatus/${id}`, item, { headers })
      .then((res) => {
        if (res.data.error) {
        }
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: `Menu status has been ${
            value ? "active" : "inactive"
          } succesfully.`,
          showConfirmButton: false,
          timer: 1500,
        });
        groupAdminMenuList();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response?.data?.error?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setErroractive(err.response?.data?.error?.message);
        } else if (err.request) {
          setErroractive(err.request);
        } else {
          setErroractive(err.message);
        }
      });
  }

  function deleteBranch(_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/groupadmin/menu/${_id}`, { headers })
          .then((result) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Menu has been deleted successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            groupAdminMenuList();
          })
          .catch((err) => {
            if (err.response) {
              // console.log(err?.response?.data?.error?.message);
            }
          });
      }
    });
  }
  /////////////////// Menu Active/Inactive Edit //////////////////////////
  
const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <ToastContainer
        toastStyle={{
          border: "1px solid black",
          fontSize: "17px",
        }}
      />
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>

                  <div className="row">
                      <div className="col-4">
                      <h1
                      className="anchor fw-bolder mb-5"
                      id="custom-form-control"
                    >
                      Menu Management
                    </h1>
                      </div>
                      <div className="col-4">
                      </div>
                      <div className="col-4">
                          <Select
                        className="search-line"
                        placeholder="Select branch ..."
                        value={Filterbranch_id}
                        options={[
                          {
                            label: "All",
                            value: "All",
                          },
                          ...filteredOptions,
                        ]}
                        onChange={(opt) => setFilterbranch_id(opt)}
                        />
                      
                      </div>
                     
                    </div>
                   
                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          All Menus
                        </h3>
                      </div>
                      <div className="d-flex flex-stack mb-5">

                      <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <Link
                           to="/group_admin/menu_management/generate_from_csv"
                           className="btn btn-primary"
                          >
                           Generate from CSV
                          </Link>
                        </div>

                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <Link
                            to="#kt_modal_1"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            onClick={()=>setError(null)}
                          >
                            Copy Menu
                          </Link>
                        </div>

                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <Link
                            to="/group_admin/menu_management/add_new_menu"
                            className="btn btn-primary"
                          >
                            Add New Menu
                          </Link>
                        </div>
                      </div>
                    </div>

                   

                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_1"
                        role="tabpanel"
                      >
                        {user.length > 0 ? (
                            user.map((main, index) => {
                              // Check if the branch ID matches the selectedBranchId
                              if (Filterbranch_id?.value !== "All" && main.branch._id !== Filterbranch_id?.value) {
                                return null; // Skip rendering if not a match
                              }
                              return (
                                <div key={index} className="py-5" style={{ clear: "both" }}>
                                  <div className="row col-md-12">
                                    <div className="card shadow-sm">
                                      <div className="card-body d-flex justify-content-between">
                                        <div className="col-md-8">
                                          <Link
                                            to={{
                                              pathname:
                                                "/group_admin/menu_management/menu_information",
                                              state: {
                                                menuId: main._id,
                                                branchID: main.branch._id,
                                              },
                                            }}
                                          >
                                            <h5>{main.menu_name}</h5>
                                            <p>{main.branch.branch_name_EN}</p>
                                          </Link>
                                        </div>
                                        <div className="col-md-4">
                                          <button
                                            type="button"
                                            className="btn btn-danger mx-2"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              deleteBranch(main._id)
                                            }}
                                          >
                                            Delete
                                          </button>
                                          {main.is_active === true ? (
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                              style={{ float: "right" }}
                                              onClick={() =>
                                                handleSubmitActive(main._id, false)
                                              }
                                            >
                                              Active
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-Inactive"
                                              style={{ float: "right" }}
                                              onClick={() =>
                                                handleSubmitActive(main._id, true)
                                              }
                                            >
                                              Inactive
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="card shadow-sm">
                              <div className="card-body text-center">
                                <h3>{menuer}</h3>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmitCopyMenu}>
                <div className="mb-10">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="required form-label"
                  >
                    Select Branch
                  </label>
                  <Select
                    id="select2"
                    required
                    className="search-line"
                    placeholder="Search... Branch"
                    options={[
                      // {
                      //   label: "All",
                      //   value: "All",
                      // },
                      ...user1,
                    ]}
                    onChange={(opt) => setbranch_id(opt.value)}
                  />
                </div>
                <div className="mb-10">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="required form-label"
                  >
                    Select Menu
                  </label>
                  <Select
                    id="select2"
                    required
                    className="search-line"
                    placeholder="Search... Menu"
                    options={
                      !userMenu == ""
                        ? userMenu.map((sponsor) => ({
                            label: sponsor.menu_name,
                            value: sponsor._id,
                          }))
                        : "No Option"
                    }
                    onChange={(opt) => setmenuId(opt.value)}
                  />
                </div>

                <div className="my-10">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="required form-label"
                  >
                    Menu Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Name"
                    // value={user[0] ? user[0].menu_name : ""}
                    value={menu_name}
                    onChange={(e) => setmenu_name(e.target.value)}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <br />
                  <div className="loader" style={{ display: "none" }}>
                    <img src="/assets/loader.gif" />
                  </div>
                </div>
                <div className="col-md-12"> {errorDiv} </div>
                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Copy Menu</span>
                  </button>
                  <button
                    type="button"
                    id="myAnchor"
                    onClick={()=>
                      {
                        setError(null)
                      }}
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminMenuManagement);

