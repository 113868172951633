import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GroupAdminSettingCommon from "./Common";
import CircularProgress from "@mui/material/CircularProgress";
import { getColorDetails } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";


function GroupAdminSettingColor({ getColorDetails, colorDetails }) {

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  let history = useHistory();
  const userPermissionType_ = userPermissionType === "groupadmin"

  const [menu_text_colour, setmenu_text_colour] = useState("")
  const [menu_hover_colour, setmenu_hover_colour] = useState("")
  const [header_footer_section_colour, setheader_footer_section_colour] = useState("");
  const [custom_id, setcustom_id] = useState("");
  const [loader, setloader] = useState(false);
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  useEffect(() => {
    getColorDetails();
  }, []);

  useEffect(() => {
    if (colorDetails) {
      const custome = colorDetails.customDoc
      setmenu_text_colour(custome?.menu_text_colour ? custome?.menu_text_colour : "");
      setmenu_hover_colour(custome?.menu_hover_colour ? custome?.menu_hover_colour : "");
      setheader_footer_section_colour(custome?.header_footer_section_colour ? custome?.header_footer_section_colour : '');
      setcustom_id(custome?._id ? custome?._id : "");
    }
  }, [colorDetails])


  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    setloader(true);

    const formData = new FormData();

    if (menu_text_colour) {
      formData.append("menu_text_colour", menu_text_colour);
    }
    if (menu_hover_colour) {
      formData.append("menu_hover_colour", menu_hover_colour);
    }
    if (header_footer_section_colour) {
      formData.append("header_footer_section_colour", header_footer_section_colour);
    }

    const apiCall = custom_id ?
      api.patch(`/groupadmin/custom/${custom_id}`, formData, { headers }) :
      api.post(`/groupadmin/custom`, formData, { headers });

    apiCall
      .then((res) => {
        setloader(false);
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Your work has been saved successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        getColorDetails();
      })
      .catch((err) => {
        setloader(false);
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }



  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      CMS Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          CMS Settings - Colors
                        </h3>
                      </div>

                      {/* <div
                      className="col-md-3 text-end"
                      data-kt-docs-table-toolbar="base"
                    >
                    <button
                              className="btn btn-primary"
                            >
                             Add Menu
                            </button>
                    </div> */}
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <GroupAdminSettingCommon />
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                <div className="rounded border p-10">
                                  <form onSubmit={handleSubmit}>
                                    <div className="row col-md-12">
                                      <div className="col-md-4">
                                        <h6>Menu-Text Color</h6>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-5">
                                          <input
                                            type="color"
                                            className="form-control form-control-solid"
                                            value={menu_text_colour}
                                            onChange={(e) => setmenu_text_colour(e.target.value)}
                                            style={{ height: "50px" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                      </div>
                                      <hr />
                                    </div>
                                    <div className="row col-md-12">
                                      <div className="col-md-4">
                                        <h6>Menu-Hover Color</h6>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-5">
                                          <input
                                            type="color"
                                            className="form-control form-control-solid"
                                            value={menu_hover_colour}
                                            onChange={(e) => setmenu_hover_colour(e.target.value)}
                                            style={{ height: "50px" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                      </div>
                                      <hr />
                                    </div>

                                    <div className="row col-md-12">
                                      <div className="col-md-4">
                                        <h6>Header/Footer Section Color</h6>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mb-5">
                                          <input
                                            type="color"
                                            className="form-control form-control-solid"
                                            value={header_footer_section_colour}
                                            onChange={(e) => setheader_footer_section_colour(e.target.value)}
                                            style={{ height: "50px" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                      </div>
                                      <hr />
                                    </div>
                                    <div className="col-md-12"> {errorDiv} </div>
                                    {loader ?
                                      <div className="col-md-12 text-center">
                                        <CircularProgress />
                                      </div>
                                      :
                                      <div className="text-center">

                                        {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.some(permission =>
                                          permission?.key === "cms_settings" &&
                                          (permission?.access?.includes("edit") || permission?.access?.includes("create"))
                                        )) && (
                                            <button
                                              type="submit"
                                              id="kt_modal_new_address_submit"
                                              className="btn btn-primary"
                                            >
                                              <span className="indicator-label"> Save </span>
                                            </button>)}
                                        <button onClick={() => history.push("/group_admin/settings")} type="button" className="btn btn-danger mx-2">
                                          Cancel
                                        </button>
                                      </div>}
                                  </form>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  colorDetails: state.colorDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getColorDetails: () => dispatch(getColorDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminSettingColor);

