import React from 'react'
import { Link } from "react-router-dom";


export default function UserManagmentCommon() {

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[3];

  return (
    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/settings/user_management",
          }}
          className={
            url === "user_management"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Users
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/settings/roles",
          }}
          className={
            url === "roles"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Roles
        </Link>
      </li>
    </ul>
  )
}
