import { COUPON } from "../../actions";



const couponListReducer = (state = [], action) => {
    switch (action.type) {
        case COUPON.CLEAR_COUPON_LIST:
            return [];
        case COUPON.SET_COUPON_LIST:
            return [...action?.couponlist];
        default:
            return state;
    }
}

export default couponListReducer