import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import { Box } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import useDrawer from "../../../../../context/DrawerContext";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";
import { connect } from "react-redux";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";

function GroupAdminVoucherReport({ getBranchList, BranchList_ }) {
  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState([]);
  const [VoucherData ,setVoucherData] = useState([]);
  const [loader, setloader] = useState(false);



  const [error, setError] = useState(null);
  const errorDiv = error ? (
     <div className="text-center alert alert-danger">{error}</div>
   ) : (
     ""
   );

   const filteredOptions = branchfilterAndMapData(BranchList_);

   const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);


  useEffect(() => {
    getBranchList();
      validDate();
    }, []);
  
    function validDate() {
      var today = new Date().toISOString().split("T")[0];
      document.getElementsByName("date")[0].setAttribute("max", today);
      document.getElementsByName("date1")[0].setAttribute("max", today);
    }

    function handalsubmit(event) {
      event.preventDefault();
      setError(null);
  
      if (branch_id == "") {
            setError("Please select branch.");
            return;
          }
        
          else if (start_date == "") {
            setError("Please select start date");
            return;
          }else{
            setloader(true);
            let item = {
                  branchIds : branch_id === "All" ? [] : [branch_id],
                startDate: start_date ? moment(start_date).format("YYYY-MM-DD") : "",
                endDate: end_date ? moment(end_date).format("YYYY-MM-DD") :"",
              };
      
        
              api
                .post(`/groupadmin/report/purchase-voucher-report`, item, { headers })
                .then((res) => {
                  const tableData = res.data.data
                  // console.log(res.data.data);
                  setloader(false);
                  setVoucherData(tableData);
                  

                })
                .catch((err) => {
                  setloader(false);
                  if (err.response) {
                    setError(err.response?.data?.error?.message);
                  }
                });
          }
        
    }

    function convertToCSV(data) {
      const headers = ["User Name","User Number","User Email", "Branch Name"  ,
        branch_id2?.value === "All" ? "Voucher Amount" : `Voucher Amount (${currencyLabel})`,
        branch_id2?.value === "All" ? "Purchased Amount (Discount)" : `Purchased Amount (Discount) ${currencyLabel}`,
        branch_id2?.value === "All" ? "Voucher Remaining Amount" : `Voucher Remaining Amount (${currencyLabel})` ,
           "Voucher Code" ,"Start Date" , "End Date" ,"Message" ];
      const csvData = [headers.join(",")];
    
      data?.purchaseVouchers.forEach((item) => {
        const { client_name, client_mobile_number, client_email ,branchName ,currency,
           voucher_amount , 
           discounted_amount , remaining_amount ,voucher_code, start_date ,end_date ,message  } = item;
          const voucher_amount_ = branch_id2?.value === "All" ? `${currency} ${Number(voucher_amount || 0).toFixed(2)}` :  Number(voucher_amount).toFixed(2)
          const discounted_amount_ = branch_id2?.value === "All" ? `${currency} ${Number(discounted_amount || 0).toFixed(2)}` :  Number(discounted_amount).toFixed(2)
          const remaining_amount_ = branch_id2?.value === "All" ? `${currency} ${Number(remaining_amount || 0).toFixed(2)}` :  Number(remaining_amount).toFixed(2)

        const formattedRow = 
        [client_name, client_mobile_number, client_email ,branchName , voucher_amount_ , discounted_amount_ || 0 , remaining_amount_ || 0 ,voucher_code, start_date ,end_date ,message  ].join(",");
        csvData.push(formattedRow);
      });

      
      // const totalBasePrice = data.reduce((total, item) => total + item.base_price, 0);
      const totalRow = ["", "","" ,"" ,`Total Amount (${currencyLabel}) :- ${Number(VoucherData?.total_voucher_amount).toFixed(2)}` ,`TotalAmount (Discount) (${currencyLabel}) :- ${Number(VoucherData?.total_discounted_amount).toFixed(2)}` ,
      `TotalAmount Remaining (${currencyLabel}) :- ${Number(VoucherData?.total_remaining_amount).toFixed(2)}`].join(",");
      csvData.push(totalRow);
      return csvData.join("\n");
    }

    function downloadCSV(data , file) {
      const csvContent = convertToCSV(data);
    
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: "text/csv" });
    
      // Create a download link for the Blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = file // Change the filename as needed
    
      // Append the link to the body and trigger the click event
      document.body.appendChild(a);
      a.click();
    
      // Clean up by removing the link and revoking the URL
      window.URL.revokeObjectURL(url);
    }

    const { drawerPos, handleDrawer , mainClassCustome } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7 p-0">
                <main style={{position : "initial"}} className={mainClassCustome.join(" ")}>

                  <div className="d-flex flex-stack mb-2 p-0">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h2
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                       Voucher Reports
                      </h2>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.push("/group_admin/report")}
                          className="btn btn-primary ps-7"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-2">
                  <Card style={{border : "1px solid #bdbcbc"}}>
                  <Card.Body>
                  <Row >
                  <Col lg={3} md={3} sx={3}> 
                  <label className="required form-label">
                                  Select Branch
                                </label>
                                <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  // options={[
                                  //   {
                                  //     label: "All",
                                  //     value: "All",
                                  //   },
                                  //   ...filteredOptions,
                                  // ]}
                                  options={getSelectOptions(filteredOptions)}
                                  //  menuPlacement="top"
                                  value={branch_id2}
                                  onChange={(opt) => {
                                    setbranch_id(opt.value);
                                    setbranch_id2(opt);
                                  }}
                                />
                  </Col>

                  <Col lg={2} md={2} sx={2}> 
                              <div >
                                <label className=" required form-label">
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  name="date"
                                  id="date" 
                                  className="form-control"
                                  value={start_date}
                                  onChange={(e) => {
                                    setstart_date(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                  <Col lg={2} md={2} sx={2}>
                              <div >
                                <label className="form-label">End Date</label>
                                <input
                                  type="date"
                                  name="date1"
                                  id="date1"
                                  className="form-control"
                                  value={end_date}
                                  onChange={(e) => {
                                    setend_date(e.target.value);
                                  }}
                                />
                              </div>
                  </Col>
                  
                  <Col lg={5} md={5} sx={5} className="mt-7 text-end">

                  {loader ? (
                                  <Box>
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                    <button
                                    onClick={handalsubmit}
                                      type="button"
                                      className="btn btn-secondary mx-3"
                                      >
                                      <span className="indicator-label">
                                      <i className="fa fa-eye fs-2"></i>&nbsp;  
                                                      Preview</span>
                                      </button>
                                      )}
                  
                           <React.Fragment>
                                                       
                                                      {/* <button
                                                      // onClick={handleConvertToPdf}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-file-pdf-o fs-3"></i>&nbsp;Download PDF</span>
                                                        </button>  */}
                                                        &nbsp;&nbsp;
                                                        <button
                                                        onClick={()=>downloadCSV(VoucherData ,`${"Voucher Report"} ${moment(new Date()).format(
                                                          "DD-MM-YYYY"
                                                        )}.csv` )}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        
                                                        <i className="fa fa-file-excel-o fs-3"></i>&nbsp;Download CSV</span>
                                                        </button>
                                                        </React.Fragment>
                  </Col>
                  </Row>
                 
                  <div className="col-md-12 my-3"> {errorDiv} </div>
                                    </Card.Body>
                                    </Card>
                  

                        {VoucherData?.purchaseVouchers?.length > 0 ? 
                        <div className={isMobile ? "" : "card card-docs mb-2 mt-10"}>
                          <div className={isMobile ? "" : "card-body"}>
                                                    <table id="kt_datatable_example_5"
                            className="table table-striped table-row-bordered gy-5 gs-7">
                          <thead>
                            <tr className="fw-bold fs-6 text-gray-800"
                              style={{ backgroundColor: "darkgray" }}>
                              <th style={{fontSize : 17}} scope="col">Code</th>
                              <th style={{fontSize : 17}} scope="col">Customer</th>
                              <th style={{fontSize : 17}} scope="col">Branch</th>
                              <th style={{fontSize : 17}} scope="col" className="text-center">
                              {branch_id2?.value === "All" ? 'Voucher Amount' : `Voucher Amount ${currencyLabel}`}  </th>
                              <th style={{fontSize : 17}} scope="col" className="text-center">
                              {branch_id2?.value === "All" ? 'Purchased Amount' : `Purchased Amount ${currencyLabel}`}
                                </th>
                              <th style={{fontSize : 17}} scope="col" className="text-center">
                              {branch_id2?.value === "All" ? 'Remaining Amount' : `Remaining Amount ${currencyLabel}`}
                                </th>
                              <th style={{fontSize : 17}} scope="col">Start Date</th>
                              <th style={{fontSize : 17}}  scope="col">End Date</th>
                            </tr>
                          </thead>
                          {VoucherData?.purchaseVouchers.map((main ,index)=>(
                        <tbody>
                        <tr key={index}>
                        <td className="">{main?.voucher_code}</td>
                        <th scope="row">{main?.client_name}
                        <br/>
                        {main?.client_mobile_number}
                        <br/>
                        {main?.client_email} </th>
                        <td className="text">{main?.branchName}</td>
                        <td className="text-center">
                        {branch_id2?.value === "All" ? `${main?.currency} ${Number(main?.voucher_amount || 0).toFixed(2)}` :  Number(main?.voucher_amount || 0).toFixed(2)}
                           </td>
                        <td className="text-center">
                        {branch_id2?.value === "All" ? `${main?.currency} ${Number(main?.discounted_amount || 0).toFixed(2)}` :  Number(main?.discounted_amount || 0).toFixed(2)}
                          </td>
                        <td className="text-center">
                        {branch_id2?.value === "All" ? `${main?.currency} ${Number(main?.remaining_amount || 0).toFixed(2)}` :  Number(main?.remaining_amount || 0).toFixed(2)}

                          </td>
                        <td>{moment(main?.start_date).format("DD/MM/YYYY")}</td>
                        <td>{moment(main?.end_date).format("DD/MM/YYYY")}</td>
                        </tr>
                        </tbody>
                          ))}
                          {/* <tr >
                          <th scope="row">
                          </th>
                          <th scope="row">
                          </th>
                          <th scope="row">
                          </th>
                          <td style={{fontSize : 17}}>Total Amount :  {Number(VoucherData?.total_voucher_amount).toFixed(2)}</td>
                          <td style={{fontSize : 17}}>Total Purchased Amount : {Number(VoucherData?.total_discounted_amount).toFixed(2)}</td>
                          <td style={{fontSize : 17}}>Total Remaining Amount : {Number(VoucherData?.total_remaining_amount).toFixed(2)}</td>
                          <td></td>
                          <td></td>
                          </tr> */}
                        </table>
                        <div className="row col-md-12">
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                          <table className="table table-bordered mx-auto" style={{ width: "100%",  }}>
                          <tbody>
                              <tr className="border-2" style={{ borderColor: "rgb(208, 213, 221)" }}>
                                  <td
                                      className="text-end"
                                      style={{
                                          width: "45%",
                                          fontSize: 16,
                                          color: "#181c32",
                                          fontWeight: 500
                                      }}
                                  >
                                      Total Amount
                                  </td>
                                  <td style={{ width: "15%" }}>{Number(VoucherData?.total_voucher_amount).toFixed(2)}</td>
                              </tr>
                              <tr className="border-2" style={{ borderColor: "rgb(208, 213, 221)" }}>
                                  <td
                                      className="text-end"
                                      style={{ fontSize: 16, color: "#181c32", fontWeight: 500 }}
                                  >
                                      Total Purchased Amount
                                  </td>
                                  <td>{Number(VoucherData?.total_discounted_amount).toFixed(2)}</td>
                              </tr>
                              <tr className="border-2" style={{ borderColor: "rgb(208, 213, 221)" }}>
                                  <td
                                      className="text-end"
                                      style={{ fontSize: 16, color: "#181c32", fontWeight: 500 }}
                                  >
                                      Total Remaining Amount
                                  </td>
                                  <td>{Number(VoucherData?.total_remaining_amount).toFixed(2)}</td>
                              </tr>
                          </tbody>
                          </table>
                          </div>
                          <div className="col-md-4"></div>

                      
                      </div>

                          </div>
                        </div> : 
                        <React.Fragment>
                          
                        <div style={{display : VoucherData?.totalData == 0 ? "" : "none"}} className={"card card-docs mb-2 mt-10"}>
                              <div className={"card-body"}>
                                  <h5 className="text-center">
                                    No data found.
                                  </h5>
                              </div></div>
                        </React.Fragment>
                         }          
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
        getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminVoucherReport);
