export const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };

export const passwordValidation = (password) => {
      var reg = /^\S*$/;
      return reg.test(password);
    };
    
export const validateForm = (data, setError) => {
      const { name, email, password } = data;
    
      if (!name || name.trim().length < 3) {
        setError("Manager Name Must Be at least 3 characters long");
        return false;
      }
      if (!validateEmail(email)) {
        setError("Invalid Email");
        return false;
      }
      if (!password || password.trim().length < 6) {
        setError("Password Must Be at least 6 characters long and not contain any space.");
        return false;
      }
      return true;
    };
    