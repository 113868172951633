import React, { useRef } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminFooter from "../../../Comman/Footer";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Helmet } from "react-helmet";
import { Editor } from "@tinymce/tinymce-react";
import TinyMCE from "react-tinymce";
import { useHistory } from "react-router-dom";

import $ from "jquery";
import { BuildTwoTone } from "@material-ui/icons";
window.jquery = window.$ = $;
const TinyMCE_Api_Key = "e7jtb3jx7jxq7f74tiheony2eaylpscng6b98z2egtnak345";

export default function GroupAdminEditAboutUs() {
  // $(document).ready(function () {
  //     $('textarea#tiny').tinymce({
  //         height: 500,
  //         menubar: false,
  //         plugins: [
  //             'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
  //             'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
  //             'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
  //         ],
  //         toolbar: 'undo redo | blocks | bold italic backcolor | ' +
  //             'alignleft aligncenter alignright alignjustify | ' +
  //             'bullist numlist outdent indent | removeformat | help'
  //     });
  // })

  // $(document).on('focusin', function (e) {
  //     if ($(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
  //         e.stopImmediatePropagation();
  //     }
  // });

  let history = useHistory();

  const editorRef = useRef(null);

  function handleEditorChange(e) {
    // console.log(e.target.getContent());
  }

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <div className="py-10">
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="custom-form-control"
                    >
                      CMS Management
                    </h1>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form>
                              <div className="mb-10">
                                <h6>About Us</h6>
                              </div>
                              <div className="mb-10">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="required form-label"
                                >
                                  Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Title"
                                />
                              </div>

                              <div className="tinymce form-control form-control-lg form-control-solid border-dark">
                                <Editor
                                  apiKey={TinyMCE_Api_Key}
                                  // onInit={(evt, editor) => editorRef.current = editor}
                                  init={{
                                    selector: ".tinymce",
                                    statusbar: false,
                                    height: "300px",
                                    width: "100%",
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "preview",
                                      "anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | blocks | " +
                                      "bold italic forecolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                    auto_focus: "main_editor",
                                    relative_urls: false,
                                    entity_encoding: "raw",
                                    menubar: false,
                                  }}
                                />
                              </div>
                              <div className="mb-10 my-6">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="required form-label"
                                >
                                  Image
                                </label>
                                <input
                                  type="file"
                                  className="form-control form-control-solid"
                                  placeholder="User Name"
                                />
                              </div>
                              <div className="col-md-12 d-flex justify-content-center m-5">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary m-2"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  onClick={() => history.goBack()}
                                  className="btn btn-danger m-2"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <GroupAdminFooter /> */}
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
      <Helmet>
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>
        <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
        <script src="/assets/js/custom/widgets.js"></script>
        <script src="/assets/js/custom/apps/chat/chat.js"></script>
        <script src="/assets/js/custom/modals/create-app.js"></script>
        <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
        <script src="/assets/plugins/custom/datatables/datatables.bundle.js"></script>
        <script src="/assets/js/custom/documentation/documentation.js"></script>
        <script src="/assets/js/custom/documentation/general/datatables/advanced.js"></script>
      </Helmet>
    </React.Fragment>
  );
}
