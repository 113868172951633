import React, { useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link, useHistory } from "react-router-dom";
import api from "../../../../CommonApi/axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialTable from "material-table";
import { useStyles } from "../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../Comman/TableOption";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import CommonVoucher from "./CommonVoucher";
import { getVoucherTemList } from "../../../../Store";
import {connect} from "react-redux"
import useDrawer from "../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { generateTableActions } from "../../../Comman/TableActions";
import Addnewdetails from "../../../Comman/AddNewDetails/Addnewdetails";
import { userPermissionType, VouchersPermissions } from "../../../Comman/UserPermission";
window.jquery = window.$ = $;

function GroupAdminVoucherListManagement({getVoucherTemList ,voucherTemList}) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  let history = useHistory();
  const userPermissionType_ = userPermissionType === "groupadmin"


  useEffect(() => {
    getVoucherTemList();
  }, []);

  function groupAdminVoucherStatusUpdate(value ,ID) {
    let item = {
      is_active  : value
    };
    // console.log("item" ,item);

    api
      .patch(`/groupAdmin/available_voucher/${ID}`, item, { headers })
      .then((res) => {
        getVoucherTemList();
        showSuccessNotification(`Voucher has been ${value == true ? "Active" : "Inactive"} successfully`)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }


  function deleteVoucher(_id) {
    deleteCommonFunction(`/groupadmin/available_voucher/${_id}`, "Voucher has been successfully deleted.",() => getVoucherTemList() );
  }

  const classes = useStyles();

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
      customSort: (a, b) => a.tableData.id - b.tableData.id,
    },
    {
      title: "Branch name",
      render: (rowData) => {
        return <>{rowData?.branch?.branch_name_EN}</>;
      },
      customSort: (a, b) => a?.branch?.branch_name_EN.localeCompare(b?.branch?.branch_name_EN),
    },
    {
      title: "Voucher Category",
      field: "voucher_category",
    },
    // {
    //   title: "Voucher Order type",
    //   field: "order_type",
    //   render: (rowData) => {
    //     return (
    //       <React.Fragment>
    //        <div style={{textTransform: "capitalize"}}>{rowData?.order_type}</div>
    //       </React.Fragment>
    //     );
    //   },
    // },
    {
      title: "Salutation Title",
      render: (rowData) => {
        return (
          <React.Fragment>
           <div style={{textTransform: "capitalize"}}>{rowData?.salutation_title}</div>
          </React.Fragment>
        );
      },
      customSort: (a, b) => {
        const salutationTitleA = (a.salutation_title || '').toLowerCase();
        const salutationTitleB = (b.salutation_title || '').toLowerCase();
    
        if (salutationTitleA < salutationTitleB) {
          return -1;
        }
        if (salutationTitleA > salutationTitleB) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Voucher Validity",
      render: (rowData) => {
        return (
          <React.Fragment>
           <div style={{textTransform: "capitalize"}}> {rowData?.voucher_validity} {rowData?.voucher_validity_type}</div>
          </React.Fragment>
        );
      },
      customSort: (a, b) => {
        const validityA = `${a.voucher_validity} ${a.voucher_validity_type}`.toLowerCase();
        const validityB = `${b.voucher_validity} ${b.voucher_validity_type}`.toLowerCase()
        if (validityA < validityB) {
          return -1;
        }
        if (validityA > validityB) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Status",
      render: (rowData) => {
        return (
          <div>
            {rowData.is_active == true ? (
              <button onClick={()=> groupAdminVoucherStatusUpdate(false , rowData?._id)} type="button" className="btn btn-success">
                Active
              </button>
            ) : (
              <button onClick={()=> groupAdminVoucherStatusUpdate(true ,rowData?._id)} type="button" className="btn btn-Inactive">
                Inactive
              </button>
            )}
          </div>
        );
      },
      sorting: false,
    },
    // {
    //   title: "Action",
    //   render: (rowData) => {
    //     return (
    //       <React.Fragment>
    //         <Link
    //           type="button"
    //           className={isMobile ? "mx-2" : "mx-3"}
    //           onClick={() => {
    //             history.push(`/group_admin/coupon_voucher_management/voucher_management/edit_voucher/${rowData._id}/${rowData?.branch?.currency}`)
    //           }}
    //         >
    //           <EditIcon style={{ fontSize: "27px", color: "black" }} />
    //         </Link>
    //         <DeleteIcon
    //           style={{ fontSize: "27px" }}
    //           onClick={() => deleteVoucher(rowData._id)}
    //         />
    //       </React.Fragment>
    //     );
    //   },
    // },
  ];
  
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="d-flex flex-stack">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h1
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Voucher Management - Voucher Templates
                      </h1>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >

                          {(userPermissionType_ || VouchersPermissions?.access?.includes("create")) && (
                                <Addnewdetails

                                url={"/group_admin/coupon_voucher_management/voucher_management/add_voucher"}
                                Name="Add Voucher" /> )}  
                        
                        <button
                          onClick={() =>
                            history.push(
                              "/group_admin/coupon_voucher_management"
                            )
                          }
                          className="btn btn-primary mx-3"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className={isMobile ? "" : "rounded border p-10"}>
                  <CommonVoucher />
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_1"
                        role="tabpanel"
                      >
                        <div className={isMobile ? "" : "card card-docs mb-2"}>
                          <div className={isMobile ? "" : "card-body"}>
                            <div className={classes.root}>
                              <MaterialTable
                                title=""
                                data={voucherTemList}
                                columns={columns}
                                options={Tableoptions}
                                actions={generateTableActions(history, getVoucherTemList, {
                                  editPath: "group_admin/coupon_voucher_management/voucher_management/edit_voucher",
                                  deletePath : "groupadmin/available_voucher/"
                                },{
                                  editTooltip: "Edit Voucher",
                                  deleteTooltip: "Delete Voucher"
                                }, {
                                  deleteMessage: "Voucher has been successfully deleted."
                                },{
                                  access: VouchersPermissions,
                                }
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  voucherTemList : state?.voucherTemList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVoucherTemList: () => dispatch(getVoucherTemList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminVoucherListManagement);
