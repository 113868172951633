import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminHeader from "../../../Comman/Header";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import Select from "react-select";
import useDrawer from "../../../../context/DrawerContext";
import { passwordValidation, validateEmail } from "../../../Comman/AddCommonFun/validation";
import { submitForm } from "../../../Comman/AddCommonFun/AddCommonFunction";
import useError from "../../../Comman/Error/useError";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { SubmitformButton } from "../../../Comman/Error/SubmitformButton";
import { connect } from "react-redux";
import { getBranchList } from "../../../../Store";
import { branchfilterAndMapData } from "../../../Comman/BranchList";
import { customStyles } from "./styles";


function GroupAdminAddUserManagment({ getBranchList, BranchList_ }) {

  let history = useHistory();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [name, setname] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [user, setUser] = useState([]);
  const [role, setRole] = useState("");
  const [Branch, setBranch] = useState("")
  const { error, showError, clearError } = useError();

  // console.log("Branch", Branch)

  const filteredOptions = branchfilterAndMapData(BranchList_);
  useEffect(() => {
    getBranchList();
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);

    if (
      name.toString().trim().length < 0 ||
      name.toString().trim().length < 3
    ) {
      showError("Full name must be at least 3 character Long");
    }
    else if (!validateEmail(email)) {
      showError("Invalid Email");
    } else if (
      !passwordValidation(password) ||
      password.toString().trim().split(" ").join("").length < 6
    ) {
      showError(
        "Password Must Be at least 6 character Long & doesn't contain any Space."
      );
    }
    else if (!role) {
      showError("Please select a user role");
    }
    else if (!Branch) {
      showError("Please select a branch");
    }
    else {
      const formData = {};

      formData.name = name;
      formData.email = email;
      formData.password = password;
      formData.role_id = role.value;
      formData.user_type = role.label;


      if (phone_number) {
        formData.phone_number = phone_number;
      }


      formData.branch_ids = Branch?.map(main => main?.value)

      // Proper handling of branch_ids
      // formData.branch_ids = Branch?.value === "all"
      //   ? filteredOptions?.map(main => main?.value)
      //   : [Branch?.value]      // if (Branch?.value) {
      //   formData.branch_id = Branch.value;
      // }
      // if (Branch?.label) {
      //   formData.branch_name = Branch.label;
      // }

      // console.log("formData", formData);



      // return
      submitForm("/groupadmin/user/add", formData, history, showError, clearError,
        "User has been added succesfully.");

    }
  }

  useEffect(() => {
    UserDetailsGet();
  }, []);

  function UserDetailsGet() {
    api.get(`/groupadmin/user/getroles`, { headers }).then((res) => {
      const tableData = res.data.data;
      setUser(tableData);
    });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack my-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1 className="anchor fw-bolder" id="custom-form-control">
                          Add User
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Enter Full Name"
                                    required
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="form-label">
                                    Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    className="filterme form-control form-control-solid"
                                    value={phone_number}
                                    onChange={(e) =>
                                      setphone_number(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control form-control-solid"
                                    placeholder="Enter Email"
                                    required
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Password
                                  </label>
                                  <input
                                    type="password"
                                    className="noSpace form-control form-control-solid"
                                    placeholder="Enter Password"
                                    required
                                    value={password}
                                    onChange={(e) => setpassword(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    User Role
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    // menuPlacement="top"
                                    placeholder="Select User Role"
                                    options={user?.map((main) => (
                                      {
                                        label: main?.name,
                                        value: main?._id,
                                      }
                                    ))}
                                    value={role}
                                    onChange={(opt) => {
                                      setRole(opt);
                                    }}
                                  />
                                </div>

                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Branch
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    className="search-line"
                                    placeholder="Select Branch"
                                    isMulti={true} // Enable multi-select
                                    options={[
                                      {
                                        label: "All",
                                        value: "all",
                                      },
                                      ...filteredOptions,
                                    ]}
                                    onChange={(selectedOptions) => {
                                      if (selectedOptions?.some((opt) => opt.value === "all")) {
                                        setBranch(filteredOptions); // Set all filtered options when "All" is selected
                                      } else {
                                        setBranch(selectedOptions); // Set the selected options (Array of selected objects)
                                      }
                                    }}
                                  />

                                </div>
                              </div>
                              <ErrorMessage error={error} />
                              <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminAddUserManagment);
