import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { useMediaQuery } from "react-responsive";

export default function GroupAdminUperSide() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <React.Fragment>
      {isMobile && (
        <>
          <br />
          <br />
          <br />
        </>
      )}
      <ScrollToTop smooth />
    </React.Fragment>
  );
}
