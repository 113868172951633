import React, { useState } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import headers from "../../../../../../CommonApi/headers";
import { toast } from "react-toastify";
import useDrawer from "../../../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminTourSetupMealAdd() {

    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        currency_name: '',
    });

    const [errorForm, setErrorForm] = useState({});

    const handleInputChange = (e, fieldName) => {
        const { name, value } = e.target;
        setErrorForm(prevErrorForm => ({ ...prevErrorForm, [name]: null }));
        setFormData({ ...formData, [name]: value });
    };

    const [apiError, setApiError] = useState('');
    const errorDiv = apiError ? (
        <div className="text-center alert alert-danger">{apiError}</div>
    ) : (
        ""
    );

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);
        setErrorForm(null);
        // Check for validation errors for each field
        const errors = {};

        if (formData.currency_name.trim() === '') {
            errors.currency_name = "Currency name is required.";
        }

        setErrorForm(errors);

        // If there are any errors, prevent form submission
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            return;
        }


        Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        api
            .post(`/groupadmin/tourgroup/add_currency`, formData, { headers })
            .then((res) => {

                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    title: "Currency has been added succesfully.",
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    setLoading(false);
                    window.location.href = '/group_admin/settings/tourSteup/CurrencyList';
                });
                // Redirect to the CompanyList page
                // history.push("/group_admin/settings/tourSteup/CompanyList");
            })
            .catch((err) => {
                setLoading(false);
                if (err.response) {
                    Swal.close()
                    toast.error(err?.response?.data?.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
    }


    const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                    <div className="py-10">
                                        <div className="d-flex flex-stack mb-7">
                                            <div
                                                className="d-flex justify-content-end"
                                                data-kt-docs-table-toolbar="base"
                                            >
                                                <h1
                                                    className="anchor fw-bolder"
                                                    id="custom-form-control"
                                                >
                                                    Add New Currency
                                                </h1>
                                            </div>
                                            <div className="d-flex flex-stack">
                                                <div
                                                    className="d-flex justify-content-end mx-2"
                                                    data-kt-docs-table-toolbar="base"
                                                >
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        className="btn btn-primary"
                                                        data-kt-menu-trigger="click"
                                                        data-kt-menu-attach="parent"
                                                        data-kt-menu-placement="bottom-end"
                                                    >
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-5">
                                            <div className="card card-docs mb-2">
                                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                    <div className="rounded border p-10">
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="mb-10">
                                                                <h4>Currency Details</h4>
                                                            </div>


                                                            <div className="mb-10">
                                                                <label className="required form-label">
                                                                    Currency Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Currency Name"
                                                                    name="currency_name"
                                                                    value={formData.currency_name}
                                                                    onChange={(e) => handleInputChange(e, 'currency_name')}
                                                                />
                                                                {errorForm && errorForm.currency_name && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.currency_name}</div>
                                                                )}
                                                            </div>


                                                            <div className="col-md-12 text-center">
                                                                <br />
                                                                <div
                                                                    className="loader"
                                                                    style={{ display: "none" }}
                                                                >
                                                                    <img src="/assets/loader.gif" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12"> {errorDiv} </div>
                                                            <div className="modal-footer flex-center">

                                                                <button
                                                                    type="submit"
                                                                    id="kt_modal_new_address_submit"
                                                                    className="btn btn-primary"
                                                                    disabled={loading}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => history.goBack()}
                                                                    id="kt_modal_new_max_discount_value_submit"
                                                                    className="btn btn-danger"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GroupAdminUperSide />
            </div>
        </React.Fragment>
    );
}
