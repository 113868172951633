import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function CommonTourSetupSetting() {
  const location = useLocation();
  const url = location?.pathname?.split("/").pop();

  const tabs = [
    { name: "Companies", path: "CompanyList" },
    { name: "Clients", path: "ClientList" },
    { name: "Meals", path: "MealList" },
    { name: "Locations", path: "LocationList" },
    { name: "Statuses", path: "StatusList" },
    { name: "Payment Statuses", path: "PaymentStatusList" },
    { name: "Currencies", path: "CurrencyList" },
    { name: "VATs", path: "VatList" },
    { name: "Invoice Setup", path: "InvoiceSetup" },
  ];

  return (
    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab.path}>
          <Link
            to={`/group_admin/settings/tourSteup/${tab.path}`}
            className={url === tab.path ? "nav-link active" : "nav-link"}
          >
            {tab.name}
          </Link>
        </li>
      ))}
    </ul>
  );
}
