import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAdminSettingCommon from "./Common";
import { getHeaderList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import Addnewdetails from "../../../../Comman/AddNewDetails/Addnewdetails";


function GroupAdminSettingHeader({ getHeaderList, headerList }) {

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const userPermissionType_ = userPermissionType === "groupadmin"

  useEffect(() => {
    getHeaderList();
  }, [getHeaderList]);

  const [Data, setData] = useState([]);


  useEffect(() => {
    setData(headerList); // Update Data state when headerList changes
  }, [headerList]);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [Index, setIndex] = useState("");

  let history = useHistory();

  const handleAddSubMenu = (mainIndex) => {
    const updatedData = [...Data];
    updatedData[mainIndex].sub_menu.push({ link: '', name: '', seq_no: '', is_new_tab: false });
    setData(updatedData);
  };

  const handleRemoveSubMenu = (mainIndex, subIndex) => {
    const updatedData = [...Data];
    updatedData[mainIndex].sub_menu.splice(subIndex, 1);
    setData(updatedData);
  };

  const handleSubMenuChange = (mainIndex, subIndex, key, value) => {
    const updatedData = [...Data];
    updatedData[mainIndex].sub_menu[subIndex][key] = value;
    setData(updatedData);
  };

  const handleMainChange = (index, key, value) => {
    const updatedData = [...Data];
    updatedData[index][key] = value;
    setData(updatedData);
  };


  function handleSubmit(event, main, index, ID) {
    event.preventDefault();
    setError(null);
    setIndex(index);

    let item = {
      seq_no: main.seq_no,
      link: main.link,
      name: main.name,
      is_new_tab: main.is_new_tab,
      sub_menu: main.sub_menu
    };

    api
      .patch(`/groupAdmin/cms/header/${ID}`, item, { headers })
      .then((res) => {
        getHeaderList();
        showSuccessNotification("Your work has been saved successfully.");
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  function deleteSUBheader(mainID, Sub_Menu_ID) {
    const requestData = {
      header_id: mainID,
      sub_menu_id: Sub_Menu_ID
    };
    deleteCommonFunction(`/groupAdmin/cms/header/submenu`, "Submenu has been successfully deleted.", () => getHeaderList(), requestData);
  }

  function deleteHeader(_id) {
    deleteCommonFunction(`/groupAdmin/cms/header/${_id}`, "Menu has been successfully deleted.", () => getHeaderList());
  }

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      CMS Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          CMS Settings - Header
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                        {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                          permission?.key === "cms_settings" &&
                          permission?.access?.includes("create")
                        )) && (
                            <Addnewdetails
                              url="/group_admin/settings/header_footer/add_header"
                              Name="Add Menu" />
                          )}
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <GroupAdminSettingCommon />
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            {Data?.length > 0 ?
                              Data?.map((main, index) => (
                                <form key={index} onSubmit={(event) => handleSubmit(event, main, index, main?._id)}>
                                  <div className="card card-docs mb-10">
                                    <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                      <div className="rounded border p-10" >
                                        <div className="row col-md-12 mb-8">
                                          <div className="col-md-8">
                                            <h3>
                                              Header Menu {main?.seq_no}
                                            </h3>
                                          </div>
                                          <div className="col-md-3 text-end">
                                            <div class="input-group">
                                              <span class="input-group-text">Sequence</span>
                                              <input
                                                type="text"
                                                class="form-control form-control"
                                                placeholder="Sequence Number"
                                                value={main?.seq_no}
                                                // onChange={(e) => handleMainChange(index, 'seq_no', e.target.value.replace(/\D/, ''))}
                                                required
                                                onChange={(e) => {
                                                  const newValue = e.target.value.replace(/^0/, "").replace(/\D/g, "") // Remove leading 0
                                                  handleMainChange(index, 'seq_no', newValue);
                                                }}
                                              />
                                            </div>

                                          </div>
                                          <div className="col-md-1 text-end">
                                            {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                              permission?.key === "cms_settings" &&
                                              permission?.access?.includes("delete")
                                            )) && (
                                                <DeleteIcon className="fs-1 my-3" onClick={() => deleteHeader(main?._id)} />
                                              )}</div>
                                        </div>
                                        <hr />
                                        <div className="row col-md-12 mt-8 mb-8">
                                          <div className="col-md-3">
                                            <p>Menu Details</p>
                                          </div>
                                          <div className="col-md-4">
                                            <input
                                              type="text"
                                              className="form-control form-control"
                                              placeholder="Enter Menu URL"
                                              required
                                              value={main?.link}
                                              onChange={(e) => handleMainChange(index, 'link', e.target.value)}
                                            />
                                          </div>
                                          <div className="col-md-3">
                                            <input
                                              type="text"
                                              className="form-control form-control"
                                              required
                                              placeholder="Enter Menu Name"
                                              value={main?.name}
                                              onChange={(e) => handleMainChange(index, 'name', e.target.value)}
                                            />
                                          </div>
                                          <div className="col-md-2  my-3">
                                            <div className="mb-1">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={main?.is_new_tab}
                                                onChange={(e) => handleMainChange(index, 'is_new_tab', e.target.checked)}
                                              />
                                              <label className="form-check-label mx-2">
                                                <h6 >New Tab</h6>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <hr />
                                        {main?.sub_menu?.length > 0 && main?.sub_menu?.map((submenu, subIndex) => (
                                          <div key={subIndex} className="row col-md-12 mt-8">
                                            <div className="col-md-3">
                                              {subIndex === 0 && (
                                                <p>Submenu Details</p>
                                              )}
                                            </div>
                                            <div className="col-md-4">
                                              <input
                                                type="text"
                                                className="form-control form-control"
                                                required
                                                value={submenu.link}
                                                onChange={(e) => handleSubMenuChange(index, subIndex, 'link', e.target.value)}
                                                placeholder="Enter Submenu URL"
                                              />
                                            </div>
                                            <div className="col-md-2">
                                              <input
                                                type="text"
                                                className="form-control form-control"
                                                required
                                                placeholder="Enter Submenu Name"
                                                value={submenu.name}
                                                onChange={(e) => handleSubMenuChange(index, subIndex, 'name', e.target.value)}
                                              />
                                            </div>
                                            <div className="col-md-1">
                                              <input
                                                type="text"
                                                className="form-control form-control"
                                                required
                                                placeholder="Seq Num"
                                                value={submenu.seq_no}
                                                onChange={(e) => {
                                                  const newValue = e.target.value.replace(/^0/, "").replace(/\D/g, "") // Remove leading 0
                                                  handleSubMenuChange(index, subIndex, 'seq_no', newValue)
                                                }}
                                              // onChange={(e) => handleSubMenuChange(index, subIndex, 'seq_no', e.target.value.replace(/\D/, ''))}
                                              />
                                            </div>
                                            <div className="col-md-2">
                                              <div className="row">
                                                <div className="col-md-9 my-3">
                                                  <div className="mb-1">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={submenu.is_new_tab}
                                                      onChange={(e) => handleSubMenuChange(index, subIndex, 'is_new_tab', e.target.checked)}
                                                    />
                                                    <label className="form-check-label mx-2">
                                                      <h6>New Tab</h6>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-3 text-end my-2">
                                                  <DeleteIcon
                                                    onClick={() => submenu?._id ?
                                                      (userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "cms_settings" &&
                                                        permission?.access?.includes("delete")
                                                      )) &&
                                                      deleteSUBheader(main?._id, submenu?._id)
                                                      :
                                                      handleRemoveSubMenu(index, subIndex)}
                                                    className="fs-1"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}

                                        <br />
                                        <div className="row col-md-12">
                                          <div className="col-md-3">

                                          </div>
                                          <div className="col-md-9">
                                            <button
                                              type="button"
                                              onClick={() => handleAddSubMenu(index)}
                                              className="btn btn-danger">
                                              Add Submenu
                                            </button>
                                          </div>
                                        </div>
                                        <hr />
                                        {Index === index &&
                                          <div className="col-md-12">{errorDiv} </div>}
                                        <div className="text-center">

                                          {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "cms_settings" &&
                                            permission?.access?.includes("edit")
                                          )) && (
                                              <button
                                                type="submit"
                                                id="kt_modal_new_address_submit"
                                                className="btn btn-primary"
                                              >
                                                <span className="indicator-label"> Save </span>
                                              </button>)}
                                          <button onClick={() => history.push("/group_admin/settings")} type="button" className="btn btn-danger mx-2">
                                            Cancel
                                          </button>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )) : <div className="card card-docs mb-10">
                                <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                  <h5 className="text-center">No any data available</h5>
                                </div></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  headerList: state.headerList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getHeaderList: () => dispatch(getHeaderList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminSettingHeader);

