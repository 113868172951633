import { EMAILMARKETING } from "../action-types";


const getEmailMarketingList = () => ({
  type: EMAILMARKETING.GET_EMAILMARKETING_LIST,
});


const setEmailMarketingList = (emailMarketionlist) => ({
  type: EMAILMARKETING.SET_EMAILMARKETING_LIST,
  emailMarketionlist,
});


export {
      getEmailMarketingList ,setEmailMarketingList
};
