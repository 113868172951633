import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import NoteState from "../CommanBranch/NoteState";
import { connect } from "react-redux";
import {  useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import headers from "../../../../../CommonApi/headers";
import $ from "jquery";
import CommonDinein from "./CommonDinein";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
window.jquery = window.$ = $;

function GroupAdminDineinSetting(props) {
  const { branchID } = props;
  let history = useHistory();

  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  ////////////////////////  Save Setting /////////////////////////////
  const [email, setemail] = useState("");
  const [order_interval, setorder_interval] = useState("");
  const [order_per_15_mins, setorder_per_15_mins] = useState("");
  const [payment_method_online, setpayment_method_online] = useState(false);
  const [payment_method_cash, setpayment_method_cash] = useState(false);
  const [order_days_in_advance, setorder_days_in_advance] = useState("");
  const [min_order_quantity, setmin_order_quantity] = useState("");
  const [dineIn_active, setdineIn_active] = useState(false);
  const [ordering_notes, setordering_notes] = useState(false);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (branchID == null) {
      setError("Please Select Branch");
    }
    // else if (!validateEmail(email)) {
    //   setError("Invalid Email");
    // }
    else {
      let item = {
        branch_id: branchID,
        order_email: email,
        order_notes_active: ordering_notes ? ordering_notes : false,
        dineIn_active: dineIn_active ? dineIn_active : false,
        payment_method_cash: payment_method_cash ? payment_method_cash : false,
        payment_method_online: payment_method_online
          ? payment_method_online
          : false,
      };

      console.log("item" ,item)

      api
        .post(`/groupadmin/dineIn_settings`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  ////////////////////////  Save Setting /////////////////////////////

  ////////////////////////// Setting  List /////////////////////

  const [ID, set_id] = useState(null);

  useEffect(() => {
    groupAdminSettingList();
  }, [branchID]);

  function groupAdminSettingList() {
    api
      .get(`/groupadmin/dineIn_settings/${branchID}`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        if (res.data.data?._id == undefined) {
          setorder_interval("");
          setorder_per_15_mins("");
          setorder_days_in_advance("");
          setdineIn_active("");
          setordering_notes("");
          setpayment_method_online("");
          setpayment_method_cash("");
          setemail("");
          setmin_order_quantity("");
          set_id(null);
          return;
        }
        set_id(tableData._id);
        setorder_interval(tableData.order_interval);
        setorder_per_15_mins(tableData.order_per_15_mins);
        setorder_days_in_advance(tableData.order_days_in_advance);
        setdineIn_active(tableData.dineIn_active);
        setordering_notes(tableData.order_notes_active);
        setpayment_method_online(tableData.payment_method_online);
        setpayment_method_cash(tableData.payment_method_cash);
        setemail(tableData.order_email);
        setmin_order_quantity(tableData.min_order_quantity);
        set_id(tableData?._id);
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }

  ///////////////////////// Edit Setting ////////////////////////

  function EdithandleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (branchID == null) {
      setError("Please Select Branch");
    }
    // else if (!validateEmail(email)) {
    //   setError("Invalid Email");
    // }
    else {
      let item = {
        branch_id: branchID,
        order_email: email,
        // order_days_in_advance: Number(order_days_in_advance),
        // order_interval: Number(order_interval),
        // order_per_15_mins: Number(order_per_15_mins),
        // min_order_quantity: Number(min_order_quantity),
        order_notes_active: ordering_notes ? ordering_notes : false,
        dineIn_active: dineIn_active ? dineIn_active : false,
        payment_method_cash: payment_method_cash ? payment_method_cash : false,
        payment_method_online: payment_method_online
          ? payment_method_online
          : false,
      };

      api
        .patch(`/groupadmin/dineIn_settings/${ID}`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });

          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="row col-md-12">
                    <div
                      className="col-md-9 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5 d-inline"
                        id="custom-form-control"
                      >
                        Dine In - Settings
                      </h3>
                    </div>

                    <div
                      className="col-md-3 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <NoteState />
                    </div>
                  </div>
                  
                    <div className="rounded border p-5">
                      <CommonDinein />

                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            <div className="py-5">
                              <div className="card card-docs mb-2">
                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                  <form onSubmit={ID == null ? handleSubmit : EdithandleSubmit}>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="rounded border p-10">
                                          <div className="mb-10">
                                            <h6>Receive Orders Per E-mail</h6>
                                          </div>
                                          <div className="mb-10">
                                            <label
                                              
                                              className="required form-label"
                                            >
                                              E-mail Address
                                            </label>
                                            <input
                                              type="eamil"
                                              required
                                              className="form-control form-control-solid"
                                              value={email}
                                              onChange={(e) =>
                                                setemail(e.target.value)
                                              }
                                            />
                                            <div className="my-4">
                                              Note : Use the comma in between as
                                              a separator to add multiple
                                              emails.
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <br />
                                        <div className="row col-md-12">
                                          <div className="col-md-4">
                                            <div className="rounded border p-10">
                                              <div className="mb-10">
                                                <h6>Active</h6>
                                              </div>
                                              <div className="mb-10">
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="checkbox"
                                                  checked={dineIn_active}
                                                  onChange={(e) =>
                                                    setdineIn_active(
                                                      !dineIn_active
                                                    )
                                                  }
                                                />
                                                <label className="form-check-label">
                                                  Dine In
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="rounded border p-10">
                                              <div className="mb-10">
                                                <h6>Ordering Notes</h6>
                                              </div>
                                              <div className="mb-10">
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="checkbox"
                                                  checked={ordering_notes}
                                                  onChange={(e) =>
                                                    setordering_notes(
                                                      !ordering_notes
                                                    )
                                                  }
                                                />
                                                <label className="form-check-label">
                                                  Ordering Notes
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-4">
                                            <div className="rounded border p-10">
                                              <div className="mb-10">
                                                <h6 className="required">
                                                  Payment Method
                                                </h6>
                                              </div>
                                              <div className="mb-10">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={
                                                    payment_method_online
                                                  }
                                                  onChange={() =>
                                                    setpayment_method_online(
                                                      !payment_method_online
                                                    )
                                                  }
                                                  required={
                                                    payment_method_cash
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                <label className="form-check-label mx-3">
                                                  Online Payment
                                                </label>
                                              </div>
                                              <div className="mb-10">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={payment_method_cash}
                                                  required={
                                                    payment_method_online
                                                      ? false
                                                      : true
                                                  }
                                                  onChange={() =>
                                                    setpayment_method_cash(
                                                      !payment_method_cash
                                                    )
                                                  }
                                                />
                                                <label className="form-check-label mx-3">
                                                  Cash Payment
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv}{" "}
                                    </div>

                                    <div className="modal-footer flex-center">

                                  {(userPermissionType === "groupadmin" ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "dine_in" &&
                                            (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                          )) && (
                                            <button type="submit" className="btn btn-primary">
                                              <span className="indicator-label">Save</span>
                                            </button>)}
                                    <button type="button" className="btn btn-danger m-2" onClick={() => history.goBack()}>
                                      Cancel
                                    </button>
                                  </div>
                                    
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                   
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAdminDineinSetting);
