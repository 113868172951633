import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import MaterialTable from "material-table";
import headers from "../../../../../CommonApi/headers";
import { ActionIcon, useStyles } from "../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../Comman/TableOption";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "./UserManagmentCommon";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Chip, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { getUserManagmentList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import AddIcon from '@mui/icons-material/Add';
import { PersonEditRegular ,DeleteRegular } from '@fluentui/react-icons';


function GroupAdminUserManagment({ getUserManagmentList, UserManagmentList }) {

  useEffect(() => {
    getUserManagmentList();
  }, []);

  let history = useHistory();

  const classes = useStyles();

  const columns = [
    {
      title: "User Name",
      field: "name",
      render: (rowData) => {
        return <div style={{ color: "#0037F8", cursor: "pointer" }} onClick={() => history.push(`/group_admin/settings/user_management/view_user/${rowData?._id}`)} >{rowData.name}</div>;
      },
      customSort: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: "Assigned Role",
      field: "role_name",
    },
    {
      field: "phone_number",
      title: "Phone Number",
      render: (rowData) => {
        return <div>{rowData?.phone_number || "---"}</div>;
      },
      sorting: false
    },
    {
      title: "Email",
      field: "email",

    },
    // {
    //   title: "Branch",
    //   field: "branch_name",
    // },
    {
      title: "Status",
      field: "is_active",
      render: (rowData) => {
        return (
          <div>
            {rowData.is_active == true ? (
              <button style={{background :'#12B76A' ,fontWeight :600 ,fontSize : "14px" ,color :"#fff"}} onClick={() => groupAdminStatusUpdate(false, rowData?._id)} type="button" className="btn btn-success">
                Active
              </button>
            ) : (
              <button onClick={() => groupAdminStatusUpdate(true, rowData?._id)} type="button" className="btn btn-Inactive">
                Inactive
              </button>
            )}
          </div>
        );
      },
      sorting: false,
    },

  ];

  function deleteBranch(_id) {
    setAnchorEl(null);
    setSelectedRow(null);
    deleteCommonFunction(`/groupadmin/user/${_id}`,
      "User has been deleted successfully.",
      () => {
        getUserManagmentList();
        setAnchorEl(null);
        setSelectedRow(null);
      });
  }

  function groupAdminStatusUpdate(value, ID) {
    let item = {
      is_active: value
    };
    // console.log("item" ,item);

    api
      .patch(`/groupadmin/user/${ID}`, item, { headers })
      .then((res) => {
        getUserManagmentList();
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: `User has been ${value == true ? "Active" : "Inactive"} successfully`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

   const [maxBodyHeight, setMaxBodyHeight] = useState(calculateMaxBodyHeight());

  // Calculate the max body height based on the window size
  function calculateMaxBodyHeight() {
    const windowHeight = window.innerHeight;
    const tableOffset = 100; // Adjust this offset as needed
    return `${windowHeight - tableOffset}px`;
  }

  useEffect(() => {
    const handleResize = () => {
      setMaxBodyHeight(calculateMaxBodyHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
              
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div  id="kt_content_container" className="mx-7">
                  <main  className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          User Management - Users List
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            history.push(
                              "/group_admin/settings/user_management/add_user"
                            )
                          }
                        >
                          <AddIcon className="me-2"/> Add User
                        </button>
                      </div>
                    </div>

                    <div className="rounded border p-5 mt-3">
                      <UserManagmentCommon />
                      <div 
                      // style={{overflowY :"auto" , maxHeight : maxBodyHeight}} 
                      className={classes.root}>
                        <MaterialTable
                          title={<div style={{ fontSize: "18px", color: "#101828", fontWeight: 500, letterSpacing: "0.3px" }}>
                            <span className="me-3">Users</span>
                            <Chip style={{ color: "#DF3223", background: "#FEF3F2", fontSize: 12, fontWeight: 500, letterSpacing: "0.3px" }} label={`${UserManagmentList.length} Records`} />
                          </div>}
                          data={UserManagmentList}
                          columns={columns}
                          options={{
                            ...Tableoptions, // Merge existing options
                            maxBodyHeight: '410px', // Add maxBodyHeight to the merged options
                          }}
                          actions={[
                            {
                              icon: () => <ActionIcon />,
                              tooltip: 'More',
                              onClick: (event, rowData) => handleMenuOpen(event, rowData)
                            }
                          ]}
                        />

                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          className="rounded border border-2"
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}

                        >
                          <MenuItem className="py-2 px-4"
                            onClick={() => history.push(`/group_admin/settings/user_management/edit_user/${selectedRow._id}`)}
                            style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListItemIcon>
                              <PersonEditRegular style={{fontSize : "21px"}} />
                            </ListItemIcon>
                            <ListItemText
                              primary={<span style={{ fontSize: '14px', fontFamily: 'inherit', fontWeight: 500 }}>Edit User</span>}
                            />
                          </MenuItem>
                          <MenuItem className="py-2 px-4" onClick={() => deleteBranch(selectedRow._id)} style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListItemIcon>
                            <DeleteRegular style={{fontSize : "21px" , color: '#D92D20'}} />

                            </ListItemIcon>
                            <ListItemText
                              primary={<span style={{ fontSize: '14px', color: '#D92D20', fontFamily: 'inherit', fontWeight: 500 }}>Delete User</span>}
                            />
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  UserManagmentList: state?.UserManagmentList
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserManagmentList: () => dispatch(getUserManagmentList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminUserManagment);

