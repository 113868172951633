import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import useDrawer from "../../../context/DrawerContext";
import { submitForm } from "../../Comman/AddCommonFun/AddCommonFunction";
import { passwordValidation, validateEmail } from "../../Comman/AddCommonFun/validation";
import useError from "../../Comman/Error/useError";
import { ErrorMessage } from "../../Comman/Error/ErrorMessage";
import { applyFilterMeHandlers } from "../../Comman/Error/jqueryUtils";
import { SubmitformButton } from "../../Comman/Error/SubmitformButton";
import { HeaderSection } from "../../Comman/Error/HeaderSection";

const FormField = ({ field, value, handleChange }) => (
  <div className="col-md-6 mb-10">
    <label className="required form-label">{field.label}</label>
    <input
      type={field.type}
      className={field.className}
      placeholder={field.placeholder}
      name={field.name}
      value={value}
      onChange={handleChange}
      required
    />
  </div>
);

export default function GroupAdminAddSatff() {
  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const { error, showError, clearError } = useError();
  const history = useHistory();
  const { id } = useParams();

  const formFields = [
    { name: 'name', type: 'text', placeholder: 'Satff Name', label: 'Satff Name', className: "form-control form-control-solid" },
    { name: 'phone_number', type: 'filterm text', placeholder: 'Phone Number', label: 'Phone Number', className: "form-control form-control-solid filterme" },
    { name: 'email', type: 'email', placeholder: 'Email', label: 'Email', className: "form-control form-control-solid" },
    { name: 'password', type: 'password', placeholder: 'Password', label: 'Password', className: "form-control form-control-solid" },
  ];

  const [formValues, setFormValues] = useState({
    name: '',
    phone_number: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    clearError(null);

    if (formValues.name.trim().length < 3) {
      showError("Satff name must be at least 3 characters long");
      return;
    }
    if (!validateEmail(formValues.email)) {
      showError("Invalid email");
      return;
    }
    if (!passwordValidation(formValues.password) || formValues.password.trim().length < 6) {
      showError("Password must be at least 6 characters long and should not contain spaces");
      return;
    }

    const newSatff = {
      branch_id: id,
      name: formValues.name,
      email: formValues.email,
      phone_number: formValues.phone_number,
      password: formValues.password,
    };

    submitForm("/groupadmin/kitchenuser", newSatff, history, showError, clearError, "Satff has been added successfully.");
  };

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  return (
    <React.Fragment>
      <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <HeaderSection title="Add Satff" goBackHandler={() => history.goBack()} />
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="row col-md-12">
                                {formFields.map((field, index) => (
                                  <FormField
                                    key={index}
                                    field={field}
                                    value={formValues[field.name]}
                                    handleChange={handleChange}
                                  />
                                ))}
                              </div>
                              <ErrorMessage error={error} />
                             <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
