import { LANGUAGEALL ,LANGUAGERES } from "../action-types";


const getLanguageAllList = () => ({
  type: LANGUAGEALL.GET_LANGUAGEALL_LIST,
});


const setLanguageAllList = (languageAlllist) => ({
  type: LANGUAGEALL.SET_LANGUAGEALL_LIST,
  languageAlllist,
});


const resLanguageGet = () => ({
  type: LANGUAGERES.GET_LANGUAGERES_LIST,
});


const setLanguageResList = (languageReslist) => ({
  type: LANGUAGERES.SET_LANGUAGERES_LIST,
  languageReslist,
});


export {
      getLanguageAllList ,setLanguageAllList ,resLanguageGet ,setLanguageResList
};
