import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import BarChart from "./Chart/BarChart";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Box, Typography } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from '@mui/material/Backdrop';
import useDrawer from "../../../../../context/DrawerContext";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import useError from "../../../../Comman/Error/useError";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { connect } from "react-redux";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";


 function GroupAdminOrderReports({ getBranchList, BranchList_ }) {

  const { error, showError, clearError } = useError();

  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const filteredOptions = branchfilterAndMapData(BranchList_);


  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState([]);
  const [Order_type, setOrder_type] = useState("");
  const [Order_type2, setOrder_type2] = useState([]);
  const [loader, setloader] = useState(false);
  const [bestSellingProduct , setbestSellingProduct] = useState("")
  const [Dowloader, setDowloader] = useState(false);
  const [PDFLogo , setPDFLogo] = useState("")

  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);
  // console.log(Order_type)

  useEffect(() => {
    getBranchList();
      validDate();
    }, []);
  
    function validDate() {
      var today = new Date().toISOString().split("T")[0];
      document.getElementsByName("date")[0].setAttribute("max", today);
      document.getElementsByName("date1")[0].setAttribute("max", today);
    }


    const productNamesEN = bestSellingProduct?.length > 0 && bestSellingProduct?.map(item => item.product_name_EN);
    const basePrices = bestSellingProduct?.length > 0 && bestSellingProduct?.map(item => item.totalQuantity);

    const [chartData3, setChartData3] = useState("");

      useEffect(()=>{
        
        const chartData3 = {
          labels: productNamesEN,
          datasets: [
          {
            label: Order_type,
            backgroundColor: "rgb(20, 201, 201)",
            borderColor: "rgb(8 245 245)",
            borderWidth: 1,
            stack: 1,
            barThickness : 40,
            hoverBorderColor: "rgb(25 251 251))",
            data: basePrices
          },
        ]
        }
        setChartData3(chartData3);
        }, [bestSellingProduct?.length > 0])
     

  

        const handleConvertToPdf = () => {
          setDowloader(true);

          const doc = new jsPDF();
          

           // Set PDF properties
            doc.setProperties({
              title: 'My PDF Document',
              subject: 'Adding a Border',
              author: 'Your Name',
              keywords: 'PDF, border, example',
            });



            

        // Add a border to the entire PDF
        doc.setDrawColor(0); // Set border color (black)
        doc.setLineWidth(0.3); // Set border line width (1 unit)

        // Add a rectangle to create a border around the entire page
        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);
        
        doc.addImage(PDFLogo ? PDFLogo : "/assets/media/logos/Logo_Dark.png", 'PNG', 165, 15, 20, 20);
          // Create an array to hold the table data
          const tableData = [];
        
          // Add the table header row
          tableData.push(['Category', 'Product', `Amount (${currencyLabel})`]);
        
          // Add data from your bestSellingProduct array
          bestSellingProduct?.forEach((item) => {
            tableData.push([
              item.category_name_EN,
              item.product_name_EN,
              Number(item.base_price).toFixed(2),
            ]);
          });
        
          // Define the table styling
          const tableConfig = {
            theme: 'grid',
            startY: 45, // Adjust the vertical starting position
            headStyles: {
              fillColor: [192, 192, 192], // Header background color
              textColor: [0, 0, 0], // Header text color
            },
          };
        
          // Add the table using autoTable
          doc.autoTable({
            head: [tableData[0]], // Use the first row as the table header
            body: tableData.slice(1), // Use the rest of the rows as table data
            ...tableConfig,
          });
        
          // Calculate the total amount
          const totalAmount = Number(
            bestSellingProduct?.length > 0
              ? bestSellingProduct
                  .map((item) => item?.base_price)
                  .reduce((sum, price) => sum + price, 0)
              : 0
          ).toFixed(2);
          doc.setFontSize(12);
          // Calculate the width of the table
          const tableWidth = doc.internal.pageSize.getWidth() - 70; // Adjust the left margin and right margin (20 + 20)
        
          // Calculate the position for the "Total Amount" text
          const xPosition = tableWidth - doc.getStringUnitWidth(`Total Amount: ${totalAmount}`) * 1; // Adjust the font size (12) if needed
          const yPosition = doc.autoTable.previous.finalY + 6; // Position it below the table with some spacing
        
          // Set the font style to bold and add the "Total Amount" text using text
          doc.setFont("Inter");
          doc.text(Order_type ,15,20)
          
          doc.text(
            `Filter: `,
            20,
            26,
          );
          doc.text(
            `Date : ${moment(start_date).format("DD.MM.YYYY")} - ${moment(
              end_date ? end_date : new Date()
            ).format("DD.MM.YYYY")}`,
            20,
            32, 
          );
          
          doc.text(`Total Amount: ${totalAmount}`, xPosition, yPosition, { align: 'left' });


          const chartElement = document.getElementById('contentToConvert');

          html2canvas(chartElement, { allowTaint: true }).then(function (canvas) {
            // Compress and resize the image before adding it to the PDF
            const screenshotWidth = 150; // Adjust to your desired width
            const screenshotHeight = (canvas.height / canvas.width) * screenshotWidth;
      
            // Convert the canvas to a JPEG data URL with reduced quality
            const chartImage = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality (0.8 is 80% quality)
      
            // Add the compressed and resized image to the PDF
            doc.addImage(chartImage, 'JPEG', 30, yPosition + 10, screenshotWidth, screenshotHeight);
      
            doc.output('save', `${Order_type} ${moment(new Date()).format(
              "DD-MM-YYYY"
            )}.pdf`, { compress: true });
      
            setDowloader(false); // Set loading to false when the conversion is complete
          });
          
        };



  // console.log("bestSellingProduct" ,bestSellingProduct)

    function handalsubmit(event){
      event.preventDefault();
      clearError(null);

    if (branch_id == "") {
      showError("Please select branch.");
      return;
    }
    else if (Order_type == "") {
      showError("Please select order type");
      return;
    }
    else if (start_date == "") {
      showError("Please select start date");
      return;
    }
    const result2 = moment(end_date).add(1, "days").toDate();
     if (Date.parse(result2) <= Date.parse(start_date)) {
      showError("End date should be greater than Start date");
      return;
    }
    else{
      setloader(true);

      const result2 = moment(end_date).add(1, "days").toDate();
      if(Order_type === "Best Selling Products by Orders"){
        setbestSellingProduct("")
        if(end_date !== ""){
      
          let url =  branch_id === "All" ?  `/groupadmin/report/bestSellingProduct?start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(result2).format(
            "YYYY-MM-DD"
          )}`  : `/groupadmin/report/bestSellingProduct?branch_id=${branch_id}&start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(result2).format(
            "YYYY-MM-DD"
          )}`;

          

          api
            .get(`${url}`, {
              headers,
            })
            .then((res) => {
              setloader(false);

                // Iterate through the JSON response and add base_price to the total
               
              // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
              setbestSellingProduct(res?.data?.data?.top5SellingProducts);
              setPDFLogo(res?.data?.data?.logo_base64);
              // setErrorNoData(res?.data?.data == undefined ? true : false);
              // console.log("res", res?.data?.data);
              
            })
            .catch((err) => {
              setloader(false);
              if (err.response) {
                showError(err.response?.data?.error?.message);
              }
            });
        }else{
           
        let url = branch_id === "All" ? `/groupadmin/report/bestSellingProduct?start_date=${moment(start_date).format(
          "YYYY-MM-DD"
        )}` : `/groupadmin/report/bestSellingProduct?branch_id=${branch_id}&start_date=${moment(start_date).format(
          "YYYY-MM-DD"
        )}`;
        
        api
          .get(`${url}`, {
            headers,
          })
          .then((res) => {
            setloader(false);
            // console.log(res?.data?.data)
            // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
            setbestSellingProduct(res?.data?.data?.top5SellingProducts);
            setPDFLogo(res?.data?.data?.logo_base64);
            // setErrorNoData(res?.data?.data == undefined ? true : false);
            // console.log("res", res?.data?.data);
          })
          .catch((err) => {
            setloader(false);
            if (err.response) {
              showError(err.response?.data?.error?.message);
            }
          });
        }

      }else if(Order_type === "Order by Order Type"){
        setloader(false);
        setbestSellingProduct([])

      }else if(Order_type === "Ordered Products by Date"){
        setloader(false);
        setbestSellingProduct([])

      }
      else{
        setbestSellingProduct([])
        setloader(false);
      }

    }
    }



    function convertToCSV(data) {
      const headers = ["Category", "Product", `Amount (${currencyLabel})`];
      const csvData = [headers.join(",")];
    
      data.forEach((item) => {
        const { category_name_EN, product_name_EN, base_price } = item;
        const formattedRow = [category_name_EN, product_name_EN, base_price].join(",");
        csvData.push(formattedRow);
      });

      
      const totalBasePrice = data.reduce((total, item) => total + item.base_price, 0);
      const totalRow = ["", "", `Total :- ${totalBasePrice.toFixed(2)}`].join(",");
      csvData.push(totalRow);

    
      return csvData.join("\n");
    }

    function downloadCSV(data , file) {
      const csvContent = convertToCSV(data);
    
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: "text/csv" });
    
      // Create a download link for the Blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = file // Change the filename as needed
    
      // Append the link to the body and trigger the click event
      document.body.appendChild(a);
      a.click();
    
      // Clean up by removing the link and revoking the URL
      window.URL.revokeObjectURL(url);
    }

    const { drawerPos, handleDrawer , mainClassCustome } = useDrawer();

     

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className={isMobile ? "mx-5 p-0" : "mx-7 p-0" } >
                <main style={{position : "initial"}} className={mainClassCustome.join(" ")}>

                  <div className="d-flex flex-stack mb-2 p-0">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h2
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                      Order Reports
                      </h2>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.push("/group_admin/report")}
                          className="btn btn-primary ps-7"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-2">
                  <Card style={{border : "1px solid #bdbcbc"}}>
                  <Card.Body>
                  <Row >
                  <Col lg={6} md={6} sx={6}> 
                  <label className="required form-label">
                                  Select Branch
                                </label>
                                <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  // options={[
                                  //   {
                                  //     label: "All",
                                  //     value: "All",
                                  //   },
                                  //   ...filteredOptions,
                                  // ]}

                                  // placeholder={getPlaceholder(filteredOptions)}
                                options={getSelectOptions(filteredOptions)}
                                  value={branch_id2}
                                  onChange={(opt) => {
                                    setbranch_id(opt.value);
                                    setbranch_id2(opt);
                                  }}
                                />
                  </Col>

                              <Col lg={6} md={6} sx={6}> 
                              <label className="required form-label">
                              Select Order Type
                                </label>
                              <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  options={[
                                    {
                                      label: "Best Selling Products by Orders",
                                      value: "Best Selling Products by Orders",
                                    },
                                    {
                                      label: "Order by Order Type",
                                      value: "Order by Order Type",
                                    },
                                    {
                                      label: "Ordered Products by Date",
                                      value: "Ordered Products by Date",
                                    },
                              
                                         
                                  ]}
                                  value={Order_type2}
                                  onChange={(opt) => {
                                    setOrder_type(opt.value);
                                    setOrder_type2(opt);
                                  }}
                              /></Col>
                          </Row>
            
                          <div className="row mt-7" >
                              <div className={isTabletOrMobile ? "col-md-4" :"col-md-2" } >
                                <label className="required form-label">
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  name="date"
                                  id="date"
                                  className="form-control"
                                  value={start_date}
                                  onChange={(e) => {
                                    setstart_date(e.target.value);
                                  }}
                                />
                              </div>
                              <div className={isTabletOrMobile ? "col-md-4" :"col-md-2" }>
                                <label className="form-label">End Date</label>
                                <input
                                  type="date"
                                  name="date1"
                                  id="date1"
                                  className="form-control"
                                  value={end_date}
                                  onChange={(e) => {
                                    setend_date(e.target.value);
                                  }}
                                />
                              </div>
                              <div className={isTabletOrMobile ? "col-md-4 mt-8 text-end" : "col-md-3 mt-8" } >
                              {loader ? (
                                  <Box>
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                  <button
                                                      onClick={handalsubmit}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-eye fs-2"></i>&nbsp;  
                                                                        Preview</span>
                                                        </button>
                                      )}
                              
                                                        </div>
                                                    <div className={isTabletOrMobile ? "col-md-12 mt-8 text-end" : "col-md-5 mt-9 text-end" }>
                                                      {bestSellingProduct?.length > 0 ?
                                                      <React.Fragment>
                                                        {Dowloader  ?  
                                                        <Backdrop
                                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                        open={true}
                                                        >
                                                        <CircularProgress color="inherit" />
                                                      </Backdrop> :
                                                      <button
                                                      onClick={handleConvertToPdf}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-file-pdf-o fs-3"></i>&nbsp;Download PDF</span>
                                                        </button> }
                                                        &nbsp;&nbsp;
                                                        {/* <CsvDownloadButton lassName="btn btn-secondary"   fields={fields} data={bestSellingProduct} > */}
                                                        <button
                                                        onClick={()=>downloadCSV(bestSellingProduct ,`${"Best Selling Products"} ${moment(new Date()).format(
                                                          "DD-MM-YYYY"
                                                        )}.csv` )}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        
                                                        <i className="fa fa-file-excel-o fs-3"></i>&nbsp;Download CSV</span>
                                                        </button>
                                                        {/* </CsvDownloadButton> */}
                                                        </React.Fragment> : ""}
                                                      </div>
                                                </div> 

                                                <ErrorMessage error={error} />

                                    </Card.Body>
                                    </Card>

                  
                                    {/* <Row className="text-center mt-15">
                                          <Col>
                                          <button
                                          style={{height : 70}}
                                                                        //   onClick={handleSubmitClose}
                                                                        type="button"
                                                                        className="btn btn-secondary mx-4"
                                                                        onClick={handalsubmit}
                                                                        >
                                                                        <span style={{fontSize : "22px"}} className="indicator-label">
                                                                        <i className="fa fa-eye" style={{fontSize : "4.55rem"}}></i> &nbsp; &nbsp; Show 
                                                                        preview</span>
                                                                        </button>
                                          </Col>
                                    </Row> */}

                                  {bestSellingProduct?.length > 0 ? 
                                  <div>
                                  {/* <Card className="mt-10">
                                    <Typography>
                                          <div className="my-3">
                                                  <div
                                                    style={{
                                                      overflowX: "auto",
                                                    }}
                                                  >
                                                            <React.Fragment>
                                                              <div>
                                                                <h1 className="my-5 mx-5">
                                                                Date : {moment(start_date).format("DD.MM.YYYY")}  - {moment(end_date ? end_date : new Date()).format("DD.MM.YYYY")}
                                                                </h1>
                                                              </div>
                                                              <table
                                                                id="kt_datatable_example_5"
                                                                className="table table-striped table-row-bordered gy-5 gs-7"
                                                              >
                                                                <thead>
                                                                  <tr
                                                                    className="fw-bold fs-6 text-gray-800"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "darkgray",
                                                                    }}
                                                                  >
                                                                    <th style={{ fontFamily: "Inter" ,fontSize : 18 }} className="min-w-25px">
                                                                    Category 
                                                                    </th>
                                                                    <th style={{ fontFamily: "Inter" ,fontSize : 18 }}  className="min-w-30px">
                                                                      Product 
                                                                    </th>
                                                                    <th style={{ fontFamily: "Inter" ,fontSize : 18 }}  className="min-w-30px">
                                                                      Amount
                                                                      (CHF)
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>

                                                                {bestSellingProduct?.length > 0 ? bestSellingProduct.map((item, index) => (
                                                                      <tr key={index}>
                                                                      <td style={{ fontFamily: "Inter" ,fontSize : 16 ,height: "73px" }} >{item.category_name_EN}</td>
                                                                      <td style={{ fontFamily: "Inter" ,fontSize : 16 ,height: "73px"}} >{item.product_name_EN}</td>
                                                                      <td style={{ fontFamily: "Inter",fontSize : 16 ,height: "73px"}} > {Number(item.base_price).toFixed(2)}</td>
                                                                      </tr>
                                                                      )) : <tr>
                                                                      <td  style={{ fontFamily: "Inter" }}  colSpan={3}> No Data Found </td>
                                                                      </tr>}
                                                                  <tr>
                                                                  <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                      <h6 style={{ fontFamily: "Inter" ,fontSize : 17 ,height: "40px" }} >
                                                                        Total
                                                                        Amount
                                                                         :{" "}
                                                                       {Number( bestSellingProduct?.length > 0 ? bestSellingProduct.map(item => item?.base_price)?.reduce((sum, price) => sum + price, 0) : 0).toFixed(2)}
                                                                      </h6>
                                                                    </td>
                                                                    
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                              <br />
                                                            </React.Fragment>
                                                  </div>
                                                </div>
                                    </Typography>
                                  </Card> */}

                                  <div  id="contentToConvert" >
                                  <BarChart currencyLabel={currencyLabel}  chartData={chartData3} />
                                  </div>
                                  
                                  </div>  : 
                                  
                                 ""
                                  }

                                {bestSellingProduct ? bestSellingProduct.length == 0 ?
                                <Card style={{border: "1px solid rgb(189, 188, 188)"}} className="mt-10 my-4 text-center">
                                    <Row className="my-5 text-center">
                                      <Col className="my-4">
                                      <h5>
                                      No Data for the selected Date or Filter. Please select a different Date or Filter.
                                      </h5>
                                      </Col>
                                    </Row>
                                    
                                  </Card> : "" : "" }

                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
        getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminOrderReports);