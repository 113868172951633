import { BRANCH } from "../../actions";

const branchDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case BRANCH.SETBRANCHDETAIL:
            return action.data;
        default:
            return state;
    }
}

export default branchDetailsReducer;