import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Helmet } from "react-helmet";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { useHistory, useLocation, Link } from "react-router-dom";

export default function GroupAdminMenuProducts() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[3];

  let history = useHistory();

  function addNewProduct() {
    history.push("/group_admin/menu_management/product/add_new_product");
  }

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const location = useLocation();

  //// ///////////////////// Prducts List /////////////////////////

  const [user, setuser] = useState([]);

  useEffect(() => {
    groupAdminProductListById();
    // console.log(location.state.menuId);
    // console.log("h", location.state.branchID);
  }, [location]);

  function groupAdminProductListById() {
    let data = JSON.stringify({
      menu_id: location.state.menuId,
    });

    api
      .post(`/groupadmin/category/${location.state.branchID}`, data, {
        headers: headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setuser(tableData);
      });
  }

  //// ///////////////////// Prducts List /////////////////////////

  //////////////////////////// Prducts Edit ///////////////////////

  function EditCatagory(_id) {
    history.push(
      `/group_admin/menu_management/category/edit_menu_category/${_id}`,
      { menuId: location.state.menuId, branchID: location.state.branchID }
    );
  }

  //////////////////////////// Catagori Edit ///////////////////////

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>

                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Menu - Products
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          className="btn btn-primary ps-7"
                          onClick={addNewProduct}
                        >
                          New Product
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_information"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            // to="/group_admin/menu_information"
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_information",
                              // search: '?query=abc',
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Information
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_category"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            // to="/group_admin/menu_category"
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_category",
                              // search: '?query=abc',
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Categories
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_products"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            // to="/group_admin/menu_category"
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_products",
                              // search: '?query=abc',
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Products
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5" style={{ clear: "both" }}>
                            <a href="/"></a>
                            <div className="card shadow-sm">
                              <a href="/"></a>
                              <div className="card-body">
                                <a href="/"></a>
                                <div className="col-md-12">
                                  <a href="/group_admin/product_details_information">
                                    <b style={{ color: "#000000" }}>
                                      Chocolate Cake
                                    </b>
                                    <b
                                      style={{
                                        color: "#000000",
                                        marginLeft: 100,
                                      }}
                                    >
                                      Price: 6.5
                                    </b>
                                    <b
                                      style={{
                                        color: "#000000",
                                        marginLeft: 100,
                                      }}
                                    >
                                      Printer:
                                    </b>
                                    <b
                                      style={{
                                        color: "#000000",
                                        marginLeft: 100,
                                      }}
                                    >
                                      POS ID:
                                    </b>
                                    <span></span>
                                  </a>
                                  <button
                                    href="javascript:void(0)"
                                    className="btn btn-success"
                                    style={{ float: "right" }}
                                  >
                                    Active
                                  </button>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
          <Helmet>
            <script src="/assets/plugins/global/plugins.bundle.js"></script>
            <script src="/assets/js/scripts.bundle.js"></script>
            <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
            <script src="/assets/js/custom/widgets.js"></script>
            <script src="/assets/js/custom/apps/chat/chat.js"></script>
            <script src="/assets/js/custom/modals/create-app.js"></script>
            <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
          </Helmet>
        </div>
      </div>
    </React.Fragment>
  );
}
