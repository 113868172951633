import React, { useEffect, useState, useMemo } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { getInventoryCatList } from "../../../../../../Store";
import useDrawer from "../../../../../../context/DrawerContext";
import LinkCommon from "../InventoryCommon/LinkCommon";
import { tableOptionInventory } from "../../../../../NewTableCommon/tableOption";
import useWindowHeight from "../../../../../NewTableCommon/useWindowsPosition";
import { UserPermissionAllData, userPermissionType } from "../../../../../Comman/UserPermission";
import api from "../../../../../../CommonApi/axios";
import headers from "../../../../../../CommonApi/headers";
import { HeaderStyle } from "../../../OrderManagement/TableCommonModule";
import ColumPopoverCommon from "../../../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../../../NewTableCommon/tableFilter";
import renderStatusChip from "../../../../../NewTableCommon/renderStatusChip";
import { Modal } from "react-bootstrap";
import "../inventory.css"
import { showSuccessNotification } from "../../../../../Comman/Swal/Swal";
import { showToastCommon } from "../../../../../Comman/Toastify/Toastify";


function InventoryCategoryList({ getInventoryCatList, inventoryCategoryList_ }) {

      // console.log("inventoryCategoryList" , )

      const inventoryCategoryList = inventoryCategoryList_?.inventoryCatagotyList

      useEffect(() => {
            getInventoryCatList();
      }, []);

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[4];

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));


      const userPermissionType_ = userPermissionType === "groupadmin"


      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      // console.log("columnsDataString" ,columnsDataString)
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const [EditId, setEditId] = useState("")
      const [category_name, setcategory_name] = useState("");
      const [error, setError] = useState(null);


      const [FilterData, SetFilterData] = useState("");
      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };


      // const filteredMenuList = []

      console.log("FilterData", FilterData);
      const filteredMenuList = useMemo(() => {
            const status = FilterData?.Status?.value;
            if (status === "Inactive") {
                  return inventoryCategoryList.filter(menu => menu.is_active === false);
            } else if (status === "Active") {
                  return inventoryCategoryList.filter(menu => menu.is_active);
            }
            return inventoryCategoryList;
      }, [inventoryCategoryList, FilterData?.Status?.value]);

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };


      function handleSubmitCatagory(event) {
            event.preventDefault();
            setError(null);
        
            if (!category_name) {
                setError("Please enter category name.");
                return;
            }
        
            const item = { category_name };
            const requestMethod = EditId ? 'patch' : 'post';
            const requestUrl = EditId
                ? `/groupadmin/inventory/updateCategory/${EditId}`
                : `/groupadmin/inventory/addCategory`;
            const successMessage = EditId
                ? "Category has been edited successfully."
                : "Category has been added successfully.";
        
            api[requestMethod](requestUrl, item, { headers })
                .then((res) => {
                    handleClose();
                    showSuccessNotification(successMessage);
                    getInventoryCatList();
                })
                .catch((err) => {
                    if (err.response) {
                        setError(err.response?.data?.error?.message);
                    }
                });
      }
        

      function handleSubmitActive(data) {

            let item = {
                  is_active: !data?.is_active,
            };
            api
                  .patch(`/groupadmin/inventory/updateCategory/${data._id}`, item, { headers })
                  .then((res) => {
                        if (res.data.error) {
                        }
                        showSuccessNotification(`Category status has been ${!data?.is_active ? "active" : "inactive"} succesfully.`)
                        getInventoryCatList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              showToastCommon(err?.response?.data?.error?.message)
                        }
                  });
      }

      function handleSubmitEdit(data) {
            handleShow();
            setEditId(data._id)
            setcategory_name(data?.category_name);
      }

      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'sr_no',
                        header: 'Sr.No',
                        size: 50,
                        Cell: ({ row }) => {
                              return (
                                    <span>
                                          {row.index + 1}
                                    </span>
                              );
                        },
                  },
                  {
                        accessorKey: 'category_name',
                        header: 'Category Name',
                        size: 800,
                        Cell: ({ row }) => row?.original?.category_name,
                  },
                  {
                        accessorKey: 'is_active',
                        header: 'Status',
                        size: 150,
                        Cell: ({ row }) => renderStatusChip(row?.original?.is_active),
                  }
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);


      const [show, setShow] = useState(false);

      const handleClose = () => {
            setcategory_name("");
            setEditId("");
            setError("")
            setShow(false)
      };

      const handleShow = () => setShow(true);

      // const customStyles = {
      //       fontSize: '18px',
      //       letterSpacing: '0.1px',
      //       color : "#101828",
      //       fontweight : 600,
      //       fontFamily: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
      // };


      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      const table = useMaterialReactTable({
            columns: Columns,
            data: filteredMenuList,
            ...tableOptionInventory({
                  url23,
                  handleShow,
                  handleClickColum_,
                  handleSubmitActive,
                  handleFilterClick,
                  handleSubmitEdit,
                  windowHeight,
                  filterData,
                  DisplayCol
            }),
      });


      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div

                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main style={{ marginTop: "-30px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-2"
                                                            >
                                                                  Settings
                                                            </h1>
                                                            <hr />

                                                            <div className="row ">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Inventory - Category
                                                                        </h3>
                                                                  </div>

                                                                  <div
                                                                        className="col-md-3 text-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                       


                                                                        {(userPermissionType === "groupadmin" || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                              permission?.key === "inventory" &&
                                                                              permission?.access?.includes("create")
                                                                        )) && (
                                                                              <button
                                                                              className="btn btn-primary"
                                                                              onClick={() => { handleShow() }
                                                                              }
                                                                        >
                                                                              Add Category
                                                                        </button>
                                                                              )}

                                                                  </div>
                                                            </div>

                                                            {/* <div className="rounded border p-5 mt-3"> */}
                                                            <LinkCommon />
                                                            <div className="mt-5">
                                                                       <MaterialReactTable
                                                                        table={table}
                                                                  /></div>
                                                            {/* </div> */}
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={url23}
                  />

                  <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                  />

                  <Modal dialogClassName="modal-30w"
                        aria-labelledby="example-custom-modal-styling-title"
                        show={show} centered onHide={handleClose}>
                        <Modal.Body>
                              <Modal.Title className="mb-2 heading">{EditId ? "Edit Category" : "Add New Category"}</Modal.Title>
                              <form onSubmit={handleSubmitCatagory}>
                                    <div className="mb-5">
                                          <div className="discriptionline">
                                                Please enter appropriate category.
                                          </div>
                                    </div>
                                    <div className="mb-5">
                                          <label className="required form_label_">
                                                New Category Name
                                          </label>
                                          <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter New Category Name"
                                                value={category_name}
                                                onChange={(e) => {
                                                      const trimmedValue = e.target.value.trimStart(); // Trim leading spaces only
                                                      setcategory_name(trimmedValue);
                                                    }}
                                          />
                                    </div>
                                    <div className="col-md-12"> {error && <div className="text-center alert alert-danger">{error}</div>} </div>
                                    <div className="row flex-center d-flex">
                                          <div className="col-md-6">
                                                <button type="submit" className="btn btn-primary w-100">
                                                      <span className="indicator-label">Save</span>
                                                </button>
                                          </div>
                                          <div className="col-md-6">
                                                <button
                                                      type="button"
                                                      onClick={handleClose}
                                                      className="btn btn-danger w-100"
                                                >
                                                      Cancel
                                                </button>
                                          </div>
                                    </div>

                              </form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      inventoryCategoryList_: state
    });

const mapDispatchToProps = (dispatch) => {
      return {
            getInventoryCatList: () => dispatch(getInventoryCatList())
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCategoryList);

