import { VOUCHERTEM } from "../../actions";



const voucherTemListReducer = (state = [], action) => {
    switch (action.type) {
        case VOUCHERTEM.CLEAR_VOUCHERTEM_LIST:
            return [];
        case VOUCHERTEM.SET_VOUCHERTEM_LIST:
            return [...action?.vouchertemlist];
        default:
            return state;
    }
}

export default voucherTemListReducer