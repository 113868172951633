import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminUperSide from "../../../Comman/UperSide";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import useDrawer from "../../../../context/DrawerContext";
import { getBranchList } from "../../../../Store";
import { branchfilterAndMapData } from "../../../Comman/BranchList";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";


function GroupAdminPaymentProvider({ getBranchList, BranchList_ }) {
  const [branchId, setBranchId] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [activePaymentId, setActivePaymentId] = useState("");

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  useEffect(() => {
    getBranchList();
  }, [getBranchList]);

  useEffect(() => {
    api.get(`/groupadmin/payment_types`, { headers })
      .then(res => setPaymentData(res.data.data))
      .catch(err => console.error(err.response?.data || err));
  }, []);

  useEffect(() => {
    if (branchId) {
      api.get(`/groupadmin/branch/paymentType/${branchId}`, { headers })
        .then(res => setActivePaymentId(res.data.data.payment_id))
        .catch(err => {
          setActivePaymentId("");
          toast.error(err.response?.data.error.message || "Error fetching data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  }, [branchId]);

  const filteredOptions = branchfilterAndMapData(BranchList_);

  const handleBranchChange = (option) => {
    setBranchId(option.value);
    setSelectedBranch(option);
  };

  const handleLinkClick = () => {
    if (!branchId) {
      Swal.fire({
        icon: "warning",
        title: "Please Select Branch",
      });
    }
  };

  return (
    <React.Fragment>
      <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">Settings</h1>
                    <hr />
                    <div className="row col-md-12">
                      <div className="col-md-9 d-inline justify-content-end" data-kt-docs-table-toolbar="base">
                        <h3 className="anchor fw-bolder mb-5 d-inline" id="custom-form-control">Payment Provider / Payment Provider List</h3>
                      </div>
                      <div className="col-md-3 d-inline justify-content-end" data-kt-docs-table-toolbar="base">
                        <Select
                          className="search-line"
                          placeholder="Search..."
                          options={filteredOptions}
                          value={selectedBranch}
                          onChange={handleBranchChange}
                        />
                      </div>
                    </div>
                    <div className="card card-docs mb-2 mt-5">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div className="rounded border p-10">
                          <div className="py-5">
                            <div className="row g-5 g-xl-8">
                              {paymentData.map((main, index) => (
                                <div className="col-sm-4" key={index}>
                                  <Link
                                    to={branchId ? {
                                      pathname: `/group_admin/settings/add_payment_provider/${main._id}/${branchId}/${main.name}`,
                                    } : "#"}
                                    onClick={handleLinkClick}
                                    className="card bg-white hoverable mb-xl-8"
                                    style={{ boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)" }}
                                  >
                                    <div className="card-body p-2" style={{ backgroundColor: "white" }}>
                                      <center>
                                        <div className="my-3">
                                          <img src={main.image_url} alt="paymentlogo" style={{ height: "65px" }} />
                                        </div>
                                        <div className="mb-2">
                                          <h2 className="fw-normal">{main.name}</h2>
                                        </div>
                                        {activePaymentId &&
                                        <div className="text-gray fw-bolder fs-2 mb-2 mt-5">
                                          <button className={`btn ${activePaymentId === main._id ? 'btn-success' : 'btn-primary'} status`}>
                                            {activePaymentId === main._id ? 'Active' : 'Inactive'}
                                          </button>
                                        </div>}
                                      </center>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchList: () => dispatch(getBranchList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPaymentProvider);
