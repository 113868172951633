import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "../../Component/Setting/UserManagment/UserManagmentCommon";
import useDrawer from "../../../../context/DrawerContext";
import { SubmitformButton1 } from "../../../Comman/Error/SubmitformButton";
import useError from "../../../Comman/Error/useError";
import { formatResponse, formatResponseSetting, formatResponseReport, reports, settings, style1, style2, style3, stylelabel, formatKey } from './styles';
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { submitEditForm } from "../../../Comman/EditCommonFun/EditCommonFunction";



export default function EditRoleManagment() {

  const { id } = useParams();
  let history = useHistory();
  const [roleName, setRoleName] = useState("");  // State for role name

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  const { error, showError, clearError } = useError();
  const [permissions, setPermissions] = useState([]); // State for selected permissions

  const [Settings, setSettings] = useState([]);

  const [Reports, setReports] = useState([]);

  useEffect(() => {
    UserDetailsGet();
  }, [id]);

  function UserDetailsGet() {
    api.get(`/groupadmin/role/${id}`, { headers }).then((res) => {
      const tableData = res.data.data;

      // Calculate Permissions
      setRoleName(tableData?.name || "")

      const ViewAllEnabled = tableData?.permissions?.some(item => item.key === "" && item.access.includes("view all"));
      const CreateAllEnabled = tableData?.permissions?.some(item => item.key === "" && item.access.includes("create all"));
      const EditAllEnabled = tableData?.permissions?.some(item => item.key === "" && item.access.includes("edit all"));
      const DeleteAllEnabled = tableData?.permissions?.some(item => item.key === "" && item.access.includes("delete all"));


      const calculatedPermissions = [
        {
          key: "",
          access: [
            { label: "View All", isCheck: ViewAllEnabled, disabled: false },
            { label: "Create All", isCheck: CreateAllEnabled, disabled: false },
            { label: "Edit All", isCheck: EditAllEnabled, disabled: false },
            { label: "Delete All", isCheck: DeleteAllEnabled, disabled: false }
          ],
        },
        ...tableData?.permissions?.slice(1)?.map((item) => {
          const isDashboard = item?.key === "dashboard";
          const isTables = item?.key === "tables";
          const isOrders = item?.key === "orders";
          const isEnquiries = item?.key === "enquiries";

          return {
            key: formatKey(item.key),
            access: [
              { label: "View", isCheck: item.access.includes("view"), disabled: false },
              { label: "Create", isCheck: item.access.includes("create"), disabled: isDashboard || isTables || isOrders || isEnquiries },
              { label: "Edit", isCheck: item.access.includes("edit"), disabled: isDashboard || isOrders || isEnquiries },
              { label: "Delete", isCheck: item.access.includes("delete"), disabled: isDashboard || isTables || isEnquiries },
            ],
          };
        })
      ];


      // Keys to check for and add if missing, with specific conditions
      const keysToCheck = [
        {
          key: "stock",
          insertAtIndex: 6, // Specify the index where it should be inserted
          access: [
            { label: "View", isCheck: false, disabled: false },
            { label: "Create", isCheck: false, disabled: false },
            { label: "Edit", isCheck: false, disabled: true }, // Disable Edit for stock
            { label: "Delete", isCheck: false, disabled: false },
          ]
        }
      ];

      // Check if the keys in `keysToCheck` exist, and if not, add them dynamically
      keysToCheck.forEach(({ key, insertAtIndex, access }) => {
        const keyExists = tableData?.permissions?.some(item => item.key === key);

        // console.log("key", keyExists)

        if (keyExists === false) {
          const newPermission = {
            key: formatKey(key),
            access
          };

          // Insert at the specified index, or append if index is -1 or greater than array length
          if (insertAtIndex >= 0 && insertAtIndex < calculatedPermissions.length) {
            calculatedPermissions.splice(insertAtIndex, 0, newPermission);
          } else {
            calculatedPermissions.push(newPermission);
          }
        }
      });


      setPermissions(calculatedPermissions);

      // Calculate Reports
      const updatedReports = [
        {
          title: "Reports",
          subcategories: reports.map((reportTitle) => {
            const correspondingReport = tableData?.reports?.find(
              (item) => formatKey(item.key) === reportTitle.toLowerCase().replace(/\s/g, "_")
            );

            return {
              report: reportTitle,
              access: [
                { label: "View", isCheck: correspondingReport?.access.includes("view"), disabled: false },
                { label: "Create", isCheck: false, disabled: true },
                { label: "Edit", isCheck: false, disabled: true },
                { label: "Delete", isCheck: false, disabled: true },
              ],
            };
          }),
        },
      ];
      setReports(updatedReports);
      // console.log('updatedReports' ,updatedReports)

      // Calculate Settings
      const updatedSettings = [
        {
          title: "Settings",
          subcategories: settings.map((settingTitle) => {
            const correspondingSetting = tableData?.settings?.find(
              (item) => formatKey(item.key) === settingTitle.toLowerCase().replace(/\s/g, "_")
            );

            return {
              setting: settingTitle,
              access: [
                { label: "View", isCheck: correspondingSetting?.access.includes("view"), disabled: false },
                { label: "Create", isCheck: correspondingSetting?.access.includes("create"), disabled: false },
                { label: "Edit", isCheck: correspondingSetting?.access.includes("edit"), disabled: false },
                { label: "Delete", isCheck: correspondingSetting?.access.includes("delete"), disabled: false },
              ],
            };
          }),
        },
      ];
      setSettings(updatedSettings);
    });
  }

  const handleRoleNameChange = (e) => {
    const value = e.target.value;
    // Prevent only spaces from being entered
    if (value.trim() === '' && value.length > 0) {
      return;
    }
    setRoleName(value);
  };


  const handleCheckboxChange = (sectionIndex, checkboxIndex) => {
    setPermissions(prevPermissions => {
      return prevPermissions.map((section, sIndex) => {

        // Handle "Select All" toggling for the "All_Value" section
        if (sectionIndex === 0 && sIndex !== 0) {
          const toggledLabel = prevPermissions[0].access[checkboxIndex].label;
          const isSelectAllToggled = ["View All", "Create All", "Edit All", "Delete All"].includes(toggledLabel);
          const isSelectAllChecked = !prevPermissions[0].access[checkboxIndex].isCheck;

          if (isSelectAllToggled) {
            return {
              ...section,
              access: section.access.map(checkbox =>
                checkbox.label.includes(toggledLabel.split(" ")[0]) && !checkbox.disabled
                  ? { ...checkbox, isCheck: isSelectAllChecked }
                  : checkbox
              )
            };
          }
        }

        // Handle normal checkbox toggling for any section
        if (sIndex === sectionIndex) {
          const updatedAccess = section.access.map((checkbox, cIndex) => {
            if (cIndex === checkboxIndex && !checkbox.disabled) {
              return { ...checkbox, isCheck: !checkbox.isCheck };
            }
            return checkbox;
          });

          // Ensure "Edit", "Create", or "Delete" toggles "View" as well, only if not disabled
          const isEditCreateDeleteChanged = ["Edit", "Create", "Delete"].includes(updatedAccess[checkboxIndex].label);
          if (isEditCreateDeleteChanged) {
            const isChecked = updatedAccess[checkboxIndex].isCheck;
            const anyEditCreateDeleteChecked = updatedAccess.some(checkbox =>
              ["Edit", "Create", "Delete"].includes(checkbox.label) && checkbox.isCheck
            );

            return {
              ...section,
              access: updatedAccess.map(checkbox =>
                checkbox.label === "View" && !checkbox.disabled
                  ? { ...checkbox, isCheck: isChecked || anyEditCreateDeleteChecked }
                  : checkbox
              )
            };
          }

          return {
            ...section,
            access: updatedAccess
          };
        }

        return section;
      });
    });
  };


  // const handleCheckboxChange = (sectionIndex, checkboxIndex) => {
  //   setPermissions(prevPermissions => {
  //     return prevPermissions.map((section, sIndex) => {

  //       // console.log("section" ,section)
  //       // Check if a "Select All" option was toggled in the "All_Value" section
  //       if (sectionIndex === 0 && sIndex !== 0) {
  //         const toggledLabel = prevPermissions[0].access[checkboxIndex].label;
  //         const isSelectAllToggled = ["View All", "Create All", "Edit All", "Delete All"].includes(toggledLabel);
  //         const isSelectAllChecked = !prevPermissions[0].access[checkboxIndex].isCheck;


  //         if (isSelectAllToggled) {
  //           return {
  //             ...section,
  //             access: section.access.map(checkbox =>
  //               checkbox.label.includes(toggledLabel.split(" ")[0]) && !checkbox.disabled
  //                 ? { ...checkbox, isCheck: isSelectAllChecked }
  //                 : checkbox
  //             )
  //           };
  //         }

  //       }

  //       // Handle normal checkbox toggling for any section
  //       if (sIndex === sectionIndex) {
  //         const updatedAccess = section.access.map((checkbox, cIndex) => {
  //           if (cIndex === checkboxIndex && !checkbox.disabled) {
  //             return { ...checkbox, isCheck: !checkbox.isCheck };
  //           }
  //           return checkbox;
  //         });

  //         // Ensure "Edit" toggles "View" as well, only if not disabled
  //         const isEditChanged = updatedAccess[checkboxIndex].label === "Edit";
  //         if (isEditChanged) {
  //           const isChecked = updatedAccess[checkboxIndex].isCheck;
  //           return {
  //             ...section,
  //             access: updatedAccess.map(checkbox =>
  //               checkbox.label === "View" && !checkbox.disabled
  //                 ? { ...checkbox, isCheck: isChecked }
  //                 : checkbox
  //             )
  //           };
  //         }

  //         return {
  //           ...section,
  //           access: updatedAccess
  //         };
  //       }
  //       return section;
  //     });
  //   });


  //   // const viewAllChecked = permissions[0]?.access.find(a => a.label === "View All")?.isCheck || false;
  //   // const createAllChecked = permissions[0]?.access.find(a => a.label === "Create All")?.isCheck || false;
  //   // const editAllChecked = permissions[0]?.access.find(a => a.label === "Edit All")?.isCheck || false;
  //   // const deleteAllChecked = permissions[0]?.access.find(a => a.label === "Delete All")?.isCheck || false;
  //   // // console.log("viewAllChecked" ,viewAllChecked)
  //   // // console.log("viewAllChecked" ,permissions[0]?.access.find(a => a.label === "View All")?.isCheck)
  //   //   setSettings(prevSettings => {
  //   //     const updatedSubcategories = prevSettings[0].subcategories.map(subcategory => ({
  //   //       ...subcategory,
  //   //       access: subcategory.access.map(accessItem => {
  //   //         if (viewAllChecked && accessItem.label === "View") {
  //   //           return { ...accessItem, isCheck: true };
  //   //         }
  //   //         if (createAllChecked && accessItem.label === "Create") {
  //   //           return { ...accessItem, isCheck: true };
  //   //         }
  //   //         if (editAllChecked && accessItem.label === "Edit") {
  //   //           return { ...accessItem, isCheck: true };
  //   //         }
  //   //         if (deleteAllChecked && accessItem.label === "Delete") {
  //   //           return { ...accessItem, isCheck: true };
  //   //         }
  //   //         // Handle deselection
  //   //         if (!viewAllChecked && accessItem.label === "View") {
  //   //           return { ...accessItem, isCheck: false };
  //   //         }
  //   //         if (!createAllChecked && accessItem.label === "Create") {
  //   //           return { ...accessItem, isCheck: false };
  //   //         }
  //   //         if (!editAllChecked && accessItem.label === "Edit") {
  //   //           return { ...accessItem, isCheck: false };
  //   //         }
  //   //         if (!deleteAllChecked && accessItem.label === "Delete") {
  //   //           return { ...accessItem, isCheck: false };
  //   //         }
  //   //         return accessItem;
  //   //       })
  //   //     }));
  //   //     return [{ title: "Settings", subcategories: updatedSubcategories }]
  //   //   });

  // };


  const handleCheckboxChangeReports = (sectionIndex, subIndex, label) => {
    setReports(prevReports => {
      return prevReports.map((section, secIndex) => {
        if (secIndex !== sectionIndex) return section;

        return {
          ...section,
          subcategories: section.subcategories.map((sub, subIdx) => {
            if (subIdx !== subIndex) return sub;

            // Toggle the checkbox's isCheck state
            const updatedAccess = sub.access.map(accessOption => {
              if (accessOption.label === label) {
                return { ...accessOption, isCheck: !accessOption.isCheck };
              }
              return accessOption;
            });

            return {
              ...sub,
              access: updatedAccess
            };
          })
        };
      });
    });
  };


  const handleCheckboxChangeSettings = (sectionIndex, subIndex, label) => {
    setSettings(prevSettings => {
      const updatedSettings = [...prevSettings];
      const subCategory = updatedSettings[sectionIndex].subcategories[subIndex];

      // Update the specific checkbox
      const access = subCategory.access.map(item => {
        if (item.label === label) {
          // Toggle the checked state of the current checkbox
          return { ...item, isCheck: !item.isCheck };
        }
        if (label === "Edit" && item.label === "View" && !item.isCheck) {
          // If "Edit" is checked, check "View"
          return { ...item, isCheck: true };
        }
        if (label === "Edit" && item.label === "View" && subCategory.access.find(i => i.label === "Edit").isCheck) {
          // If "Edit" is unchecked, uncheck "View"
          return { ...item, isCheck: false };
        }
        return item;
      });

      // Update the subcategory's access array
      updatedSettings[sectionIndex].subcategories[subIndex].access = access;

      return updatedSettings;
    });
  };

  // useEffect(() => {
  //   // Log the current permissions and checkbox values for debugging
  //   const checkAllStates = ['View All', 'Create All', 'Edit All', 'Delete All']?.reduce((acc, label) => {
  //     acc[label] = permissions[0]?.access.find(a => a.label === label)?.isCheck || false;
  //     return acc;
  //   }, {});

  //   // console.log('Check All States:', checkAllStates);
  //   setSettings(prevSettings => {
  //     const updatedSubcategories = prevSettings[0]?.subcategories?.map(subcategory => ({
  //       ...subcategory,
  //       access: subcategory?.access?.map(accessItem => {
  //         // Determine the corresponding 'All' checkbox
  //         const allLabel = `${accessItem.label} All`;

  //         // Set isCheck based on the corresponding 'All' checkbox state
  //         if (checkAllStates[allLabel] !== undefined) {
  //           return { ...accessItem, isCheck: checkAllStates[allLabel] };
  //         }
  //         return accessItem;
  //       })
  //     }));
  //     return [{ title: "Settings", subcategories: updatedSubcategories }];
  //   });

  //   setReports(prevSettings => {
  //     const updatedSubcategories = prevSettings[0]?.subcategories?.map(subcategory => ({
  //       ...subcategory,
  //       access: subcategory?.access?.map(accessItem => {
  //         // Determine the corresponding 'All' checkbox
  //         const allLabel = `${accessItem.label} All`;

  //         // Set isCheck based on the corresponding 'All' checkbox state
  //         if (checkAllStates[allLabel] !== undefined && !accessItem.disabled) {
  //           return { ...accessItem, isCheck: checkAllStates[allLabel] };
  //         }
  //         return accessItem;
  //       })
  //     }));
  //     console.log("ssss", updatedSubcategories);
  //     return [{ title: "Reports", subcategories: updatedSubcategories }];
  //   });
  // }, [permissions]);



  // console.log("permissions", permissions)
  // console.log("Reports", Reports);
  // console.log("Settings", Settings);

  // console.log(formatResponse(permissions));
  // console.log(formatResponseSetting(Settings?.length > 0 && Settings?.[0]?.subcategories));



  const handleSubmit = (event) => {
    event.preventDefault();
    clearError(null);

    if (roleName === "") {
      showError("Please enter role name");
      return;
    }
    const hasPermissionsWithAccess = formatResponse(permissions)?.some(permission => permission.access.length > 0);
    const hasReportsWithAccess = formatResponseReport(Reports?.[0]?.subcategories || [])?.some(report => report.access.length > 0);
    const hasSettingsWithAccess = formatResponseSetting(Settings?.[0]?.subcategories || [])?.some(setting => setting.access.length > 0);

    if (!hasPermissionsWithAccess && !hasReportsWithAccess && !hasSettingsWithAccess) {
      showError("Please select at least one option to create a role.");
      return;
    }
    else {
      const item = {
        name: roleName,
        permissions: formatResponse(permissions),
        reports: formatResponseReport(Reports?.length > 0 && Reports?.[0]?.subcategories),
        settings: formatResponseSetting(Settings?.length > 0 && Settings?.[0]?.subcategories),
      };

      // console.log("item", item);
      // return

      submitEditForm(`/groupadmin/role/${id}`, item, history, showError, clearError, "Role has been edited successfully.");
    }


  };


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="row col-md-12">
                      <div className="col-md-9" data-kt-docs-table-toolbar="base">
                        <h1 className="anchor fw-bolder mb-5 d-inline" id="custom-form-control">
                          User Management
                        </h1>
                      </div>

                      <div className="col-md-3 text-end" data-kt-docs-table-toolbar="base">
                        <button
                          className="btn btn-primary"
                          onClick={() => history.goBack()}
                        >
                          Go Back
                        </button>
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <UserManagmentCommon />
                        <div className="row col-md-12 py-5 mx-0">
                          <div className="col-md-9">
                            <h3 className="anchor fw-bolder mb-5">Edit Role</h3>
                          </div>
                          <div className="col-md-3 text-end"></div>
                        </div>

                        <div className="card card-docs mb-2 mx-5">
                          <div className="card-body fs-6 pt-5 px-5 px-lg-15 text-gray-700">
                            <form onSubmit={handleSubmit}>
                              <div className="row col-md-12 mt-3">
                                <div className="col-md-3">
                                  <h6 style={style1}>Role Name</h6>
                                </div>
                                <div className={"col-md-9"}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Role Name"
                                    value={roleName}
                                    onChange={handleRoleNameChange}
                                    required
                                  />
                                </div>
                              </div>
                              <hr className="my-8" />
                              {permissions.map((section, sectionIndex) => (
                                <div key={sectionIndex}>
                                  <div className="row col-md-12 mt-3">
                                    <div className="col-md-3">
                                      <h6 style={sectionIndex === 0 ? style1 : style2}>{section.key}</h6>
                                    </div>
                                    <div className={"col-md-9"}>
                                      {section.inputField ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Role Name"
                                          value={roleName}
                                          onChange={handleRoleNameChange}
                                          required
                                        />
                                      ) : (
                                        <div className="row col-md-12">
                                          {section.access.map((checkbox, checkboxIndex) => (
                                            <div key={checkboxIndex} className="col-md-2 form-check form-check-inline">
                                              <input
                                                className="form-check-input me-4"
                                                type="checkbox"
                                                // id={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}`}
                                                disabled={checkbox.disabled}
                                                checked={checkbox?.isCheck}
                                                onChange={() => handleCheckboxChange(sectionIndex, checkboxIndex)}
                                              />
                                              <label
                                                style={stylelabel}
                                                className="form-check-label"
                                              // htmlFor={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}`}
                                              >
                                                {checkbox.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <hr className="my-8" />
                                </div>
                              ))}
                              {Reports.map((section, sectionIndex) => (
                                <div key={sectionIndex}>
                                  <div className="row col-md-12 mt-3">
                                    <div className="col-md-3">
                                      <h6 style={sectionIndex === 0 ? style1 : style2}>{section.title}</h6>
                                    </div>
                                    {section.subcategories && (
                                      section.subcategories.map((sub, subIndex) => (
                                        <div key={subIndex} className="row col-md-12 my-6">
                                          <div className="col-md-3">
                                            <h6 style={style3}>{sub.report}</h6>
                                          </div>
                                          <div className="col-md-9">
                                            {sub.access.map((checkbox, checkboxIndex) => (
                                              <div key={checkboxIndex} className="col-md-2 form-check form-check-inline">
                                                <input
                                                  className="form-check-input me-4"
                                                  type="checkbox"
                                                  disabled={checkbox.disabled}
                                                  onChange={() => handleCheckboxChangeReports(sectionIndex, subIndex, checkbox.label)}
                                                  checked={checkbox.isCheck}
                                                />
                                                <label
                                                  style={stylelabel}
                                                  className="form-check-label"
                                                >
                                                  {checkbox.label}
                                                </label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                  <hr className="my-8" />
                                </div>
                              ))}
                              {Settings?.length > 0 &&
                                Settings.map((section, sectionIndex) => (
                                  <div key={sectionIndex}>
                                    <div className="row col-md-12 mt-3">
                                      <div className="col-md-3">
                                        <h6 style={sectionIndex === 0 ? style1 : style2}>{section.title}</h6>
                                      </div>
                                      {section.subcategories && (
                                        section.subcategories.map((sub, subIndex) => (
                                          <div key={subIndex} className="row col-md-12 my-6">
                                            <div className="col-md-3">
                                              <h6 style={style3}>{sub.setting}</h6>
                                            </div>
                                            <div className="col-md-9">
                                              {sub.access.map((checkbox, checkboxIndex) => (
                                                <div key={checkboxIndex} className="col-md-2 form-check form-check-inline">
                                                  <input
                                                    className="form-check-input me-4"
                                                    type="checkbox"
                                                    id={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}_${subIndex}`}
                                                    disabled={checkbox.disabled}
                                                    checked={checkbox.isCheck}
                                                    onChange={() => handleCheckboxChangeSettings(sectionIndex, subIndex, checkbox.label)}
                                                  />
                                                  <label
                                                    style={stylelabel}
                                                    className="form-check-label"
                                                    htmlFor={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}_${subIndex}`}
                                                  >
                                                    {checkbox.label}
                                                  </label>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        ))
                                      )}
                                    </div>
                                    <hr className="my-8" />
                                  </div>
                                ))}
                              <ErrorMessage error={error} />
                              <SubmitformButton1 onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}





