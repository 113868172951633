import { BRANCH1 } from "../action-types";


const getBranchList = () => ({
  type: BRANCH1.GET_BRANCH_LIST,
});


const setBranchList = (branchlist) => ({
  type: BRANCH1.SET_BRANCH_LIST,
  branchlist,
});


export {
      getBranchList ,setBranchList
};
