import React from "react";
import { Bar } from "react-chartjs-2";
import "./Common.css"
import { barchatOption, getBarchatOption } from "./ChartOption";

function  BarChat3({chartName ,ChartData3 ,Type }) {

  const sections = [
    {
      name: 'Delivery',
      backgroundColor: '#619CFF',
      value: ChartData3 ? ChartData3?.totals?.DeliveryOrderDataTotal : 0,
    },
    {
      name: 'Pickup',
      backgroundColor: '#FEC84B',
      value: ChartData3 ? ChartData3?.totals?.PickupOrderDataTotal : 0,
    },
    {
      name: 'DineIn',
      backgroundColor: '#F8766D',
      value: ChartData3 ? ChartData3?.totals?.DineInOrderDataTotal : 0,
    },
  ];

  // console.log("ChartData3" ,ChartData3 )

  // if (!ChartData3 || !ChartData3.labels || !ChartData3.datasets) {
  //   return <div>Loading chart...</div>; // Or any appropriate loading indicator
  // }

  

  return (
    <React.Fragment>
    <div className="frame8New2"  style={{height : 470}}>
          <div className="overlap-group">
              <div className="text-wrapper">{chartName}</div>
          </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
      {sections?.map((section, index) => (
        <div className="contan_div" key={index}>
          <div className="contan_dev_2" style={{ background: section.backgroundColor }}>
            {" "}
          </div>
          <div className="name_style" style={{ color: "var(--gray-500, #667085)" }}>
            {section.name}: {Number(section.value ? section.value : 0)}
          </div>
        </div>
      ))}
      </div>

      <div style={{ position: "relative", margin: "auto" ,height :"390px" }}>
        <Bar
          data={ChartData3}
          options={getBarchatOption(Type)}
        />
      </div>
    </div>

    </React.Fragment>
  );
}
export default BarChat3;
