import React from 'react'
import { Link } from "react-router-dom";


export default function LinkCommon() {

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];

  return (
    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/settings/inventory_management/productlist",
          }}
          className={
            url === "productlist"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Products
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/settings/inventory_management/categorylist",
          }}
          className={
            url === "categorylist"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Category
        </Link>
      </li>
    </ul>
  )
}
