import Swal from 'sweetalert2';

export function showSuccessNotification(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'success',
//     html: `<div style="font-size: 24px; font-weight: bold;">${title}</div>`,
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function showSuccessNotificationSendKitchen(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 2000,
  });
}

export function showErrorNotification(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'error',
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}