import React from "react";
import { Bar } from "react-chartjs-2";
import "./Common.css"
import { barchatOption, getBarchatOption } from "./ChartOption";



function BarChart4({
  ChartData4_,
  chartName,Type ,CurrencyLabel
}) {

  const sections = [
    {
      name: 'Delivery',
      backgroundColor: '#619CFF',
      value: ChartData4_?.totals?.DeliverySalesDataTotal,
    },
    {
      name: 'Pickup',
      backgroundColor: '#FEC84B',
      value: ChartData4_?.totals?.PickupSalesDataTotal,
    },
    {
      name: 'DineIn',
      backgroundColor: '#F8766D',
      value: ChartData4_?.totals?.DineInSalesDataTotal,
    },
  ];

// console.log("ChartData4_" ,ChartData4_)
  

  return (

    <React.Fragment>
     <div className="frame8New2" style={{height : 470}}>
          <div className="overlap-group">
              <div className="text-wrapper">{chartName}</div>
          </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
      {sections?.map((section, index) => (
        <div className="contan_div" key={index}>
          <div className="contan_dev_2" style={{ background: section.backgroundColor }}>
            {" "}
          </div>
          <div className="name_style" style={{ color: "var(--gray-500, #667085)" }}>
            {section.name}: {CurrencyLabel} {Number(section.value ? section.value : 0).toFixed(2)}
          </div>
        </div>
      ))}
      </div>

      <div style={{ position: "relative", margin: "auto" ,height :"390px" }} >
        <Bar
          data={ChartData4_}
          options={getBarchatOption(Type)}
        />
      </div>
    </div>
    </React.Fragment>
  );
}
export default BarChart4;
