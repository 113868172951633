import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import React, { useEffect, useState, useRef } from "react";
import "./KpGpt.css";
import useDrawer from "../../../../context/DrawerContext";

const API_KEY = "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";

const KpGpt = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const chatContainerRef = useRef(null);

  const scrollToLatestMessage = () => {
    if (chatContainerRef.current) {
      const latestMessage = chatContainerRef.current.lastElementChild;
      if (latestMessage) {
        latestMessage.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  // console.log(messages)
  
  useEffect(() => {
    scrollToLatestMessage();
  }, [messages]);

  const handleSendRequest = async () => {
    scrollToLatestMessage();
    if (userInput.trim() === "") return; // Don't send empty messages

    const newMessage = {
      message: userInput, // Use the user's input
      direction: "outgoing",
      sender: "user",
      loader: false,
    };

    const newMessage2 = {
      message: "",
      sender: "ChatGPT",
      loader: true,
    };

    const newChatList = [...messages, newMessage, newMessage2];
    setMessages(newChatList);
    scrollToLatestMessage();

    try {
      setUserInput("");
      setButtonDisabled(true);
      const response = await processMessageToChatGPT([...messages, newMessage]);
      
      // Check if response is defined before accessing its properties
      if (response && response.choices && response.choices[0] && response.choices[0].message) {
        const content = response.choices[0].message.content;
        
        if (content) {
          const chatGPTResponse = {
            message: content,
            sender: "ChatGPT",
            loader: false,
          };
          newChatList.pop();
          setMessages([...newChatList, chatGPTResponse]);
          scrollToLatestMessage();
        }
      } else {
        // Handle the case where the response or its properties are undefined
        console.error("Response or its properties are undefined.");
      }
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setUserInput(""); // Clear the user input field
      setButtonDisabled(false); 
    }
  };

  function handleInputKeyPress(event) {
    if (event.key === "Enter") {
      handleSendRequest();
    }
  }

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: "I'm a Student using ChatGPT for learning" },
        ...apiMessages,
      ],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY, 
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    return response.json();
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div
                    className="mb-5"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                      flexWrap: "wrap",
                    }}
                  >
                    <h1
                      style={{
                        flex: 1,
                        color: "#000",
                        fontFamily: "Inter",
                        fontSize: "32px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "38px",
                      }}
                    >
                      KPGPT
                    </h1>
                  </div>

                  <div className="chat-wrapper">
                    <div className="chat-container">
                      <div className="chat-header">
                        <h2 className="title">KP Assistant</h2>
                      </div>
                      <div className="chat-body" ref={chatContainerRef}>
                        {messages.map((chat, index) => (
                          <div className="message-item" key={index}>
                            {chat.sender !== "user" ? (
                              <img
                                src="/assets/media/svg/chatbot.svg"
                                className="img-chatbot"
                                alt="Chatbot"
                              />
                            ) : (
                              ""
                            )}

                            <div
                              className={
                                chat.sender === "user"
                                  ? "message sender-message "
                                  : "message receiver-message"
                              }
                            >
                              {chat.sender !== "user" && chat.loader ? (
                                <p
                                  className="typing-message"
                                  style={{ background: "#f5f5f5" }}
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </p>
                              ) : (
                                <p>{chat?.message ? chat?.message : "Hhh"}</p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="chat-footer" style={{ gap: "25px" }}>
                        <input
                          className="form-control"
                          type="text"
                          // value={message}
                          // disabled={isButtonDisabled}
                          placeholder="Type your message"
                          // onChange={(e) => setMessage(e.target.value)}
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          style={{ height: "44.5px" }}
                          onKeyPress={isButtonDisabled ? "" : handleInputKeyPress}
                        />
                        <div>
                          <button
                            onClick={handleSendRequest}
                            disabled={isButtonDisabled}
                            style={{
                              display: "flex",
                              padding: "10px 0px",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "8px",
                              borderRadius: "8px",
                              border: "1px solid #F25042",
                              background:`${isButtonDisabled ? "rgb(191 59 47)" : "#F25042" }`,
                              color: "#fff",
                              boxShadow:
                                "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                              fontSize: "15px",
                              width: "180px",
                            }}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </>
  );
};

export default KpGpt;