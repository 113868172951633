import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import Select from "react-select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDrawer from "../../../../context/DrawerContext";
import useError from "../../../Comman/Error/useError";
import headers from "../../../../CommonApi/headers";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { SubmitformButton1 } from "../../../Comman/Error/SubmitformButton";
import { getInventoryProList } from "../../../../Store";
import { submitEditForm } from "../../../Comman/EditCommonFun/EditCommonFunction";
import convert from 'convert-units';



function StockProductDetailsEdit() {
      const history = useHistory();
      const { error, showError, clearError } = useError();
      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
      const { productid, catagoryid, menuId, branchID, id } = useParams();

      // console.log("productid" ,productid)
      // console.log("catagoryid" ,catagoryid)
      // console.log("menuId" ,menuId)
      // console.log("id" ,id)
      // console.log("branchID" ,branchID)


      const commonSelectStyles = {
            control: (provided) => ({
                  ...provided,
                  borderRadius: "8px",
                  height: '41px',  // Adjust this value to your desired height
                  minHeight: '41px',
                  border: "1px solid #e4e6ef"
            }),
            menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
            })
      };

      const [Data, setData] = useState("")

      // console.log("data" ,Data)

      useEffect(() => {
            getStockDetails();
      }, [id && productid]);


      async function getStockDetails() {
            if (!id) return;
          
            try {
              // Fetch product stock details
              const res = await api.get(`/groupadmin/product/stock/${productid}/${id}`, { headers });
              setData(res?.data);
          
              const tableData = res?.data?.items || [];

              
              // Map over tableData to structure the product list
              const result = tableData.filter(main => main?.is_active).map(main => ({
                _id: main?._id,
                setunit: convert().from(main?.unit).possibilities().map(unit => ({
                  label: `${unit} (${unit})`,
                  value: unit
                })),
                quantity: main?.quantity,
                is_active: main?.is_active,
                name: main?.name,
                unit: main?.unit
              }));

            //   console.log("result" ,result)
              // Remove the fetched product _ids from branch stock
              const idsToRemove = tableData.filter(main => main?.is_active).map(main => main?._id);

            //   console.log("idsToRemove" ,idsToRemove)
          
              // Fetch branch stock details
              let url = `/groupadmin/branch_stock/listProduct?branch_id=${branchID}&category_id=${id}`;

            //   const url = `/groupadmin/branch_stock/listProduct?category_id=${id}`;
              const res1 = await api.get(url, { headers });
          
              const stockData = res1?.data?.data?.stockData || [];
          
              // Filter out the products with matching _ids
              const updatedData = stockData.filter(item => !idsToRemove.includes(item._id));
            //   console.log("updatedData" ,updatedData)
          

              const resultUpdate = updatedData?.filter(main => main?.total_quantity > 0).map(main => ({
                  _id: main?._id,
                  setunit : convert().from(main?.unit).possibilities().map(unit => ({
                        label: `${unit} (${unit})`,
                        value: unit
                  })),
                  quantity: "",
                  is_active: false,
                  name: main?.inv_product_name,
                  unit : ""
                  }));
                  

            setProductList(result.concat(resultUpdate));


            //   console.log("Updated Data without selected IDs:", result.concat(resultUpdate));
      
            } catch (err) {
              console.error(err?.response?.data || err.message);
            }
          }
          

      const [ProductList, setProductList] = useState([]);

      // console.log("ProductList", ProductList)



      const handleInputChange = (index, key, value) => {
            setProductList(prevState =>
                  prevState.map((item, i) =>
                        i === index ? { ...item, [key]: value } : item
                  )
            );
      };


      const handleSubmit = async (e) => {
            e.preventDefault();
            clearError();
          
            let hasError = false; // Track if there are any errors
          
            // Create a copy of ProductList to store filtered items later
            const updatedProductList = ProductList.map(item => ({ ...item }));

            const activeProducts = updatedProductList.filter((item) => item.is_active);
          
            // Check if there are any active products
            if (activeProducts.length === 0) {
              showError("Please select product");
              return;
            }

          
            for (let i = 0; i < activeProducts.length; i++) {
              const item = activeProducts[i];
          
              if (item.is_active) {
                // Validate unit
                if (!item.unit) {
                  showError(`Please select a unit for item "${item.name}"`);
                  hasError = true;
                  break; // Exit the loop if an error is found
                }
          
                // Validate quantity
                if (!item.quantity || item.quantity.trim() === "") {
                  showError(`Please enter a quantity for item "${item.name}"`);
                  hasError = true;
                  break; // Exit the loop if an error is found
                }
              }
            }
          
            // If there are errors, stop the form submission
            if (hasError) {
              return false;
            }
          
            // If no errors, create the payload and submit
            const result = activeProducts.map(main => ({
              _id: main?._id,
              quantity: main?.quantity,
              is_active: main?.is_active,
              name: main?.name,
              unit: main?.unit
            }));
          
            if (activeProducts.length > 0) {
              const payload = {
                category_name: Data?.category_name,
                _id: id,
                items: result?.filter(main => main?.is_active),
              };
          
            //   console.log("payload", payload);
            //   return
          
              // Uncomment the submit function once validation passes
              submitEditForm(`/groupadmin/product/stock/${productid}`, payload, history, showError, clearError, "Stock has been edited successfully.");
            }
          };
          

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                          <GroupAdminHeader />
                                          <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">
                                                                  Menu Management
                                                            </h1>
                                                            <hr />
                                                            <div className="d-flex flex-stack mb-5">
                                                                  <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                        <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                                                                              Product - Edit Stock
                                                                        </h3>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                                                                              <button onClick={() => history.goBack()} className="btn btn-primary ps-7">
                                                                                    Go Back
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="rounded border p-5">
                                                                  <div className="tab-content" id="myTabContent">
                                                                        <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                                                                              <div className="card card-docs mb-2">
                                                                                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                                                          <form onSubmit={handleSubmit}>
                                                                                                <div className="rounded border p-10">
                                                                                                      <div className="row mb-8">
                                                                                                            <div className="col-md-9">
                                                                                                                  <h3>Edit Stock</h3>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />

                                                                                                      <div className="row mt-8">
                                                                                                            <div className="col-md-3">
                                                                                                                  <p>Stock Details</p>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  {/* <label className="form-label required">Category</label> */}
                                                                                                                  <input
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Name"
                                                                                                                        value={Data?.category_name}
                                                                                                                        required
                                                                                                                        readOnly
                                                                                                                  />
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      {ProductList?.length > 0 &&
                                                                                                            ProductList?.map((item, index) => (
                                                                                                                  <div className="row mt-8" key={index}>
                                                                                                                        <div className="col-md-3"></div>
                                                                                                                        <div className="col-md-2 d-flex align-items-center">
                                                                                                                              <input
                                                                                                                                    className="form-check-input me-4"
                                                                                                                                    type="checkbox"
                                                                                                                                    checked={item.is_active}
                                                                                                                                    isSearchable={false}
                                                                                                                                    onChange={(e) =>
                                                                                                                                          handleInputChange(index, "is_active", e.target.checked)
                                                                                                                                    }
                                                                                                                              />

                                                                                                                              <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="Enter Name"
                                                                                                                                    value={item.name}
                                                                                                                                    required
                                                                                                                                    readOnly
                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-2">
                                                                                                                              <Select
                                                                                                                                    isSearchable={false}
                                                                                                                                    className="search-line"
                                                                                                                                    placeholder="Select Unit"
                                                                                                                                    value={item?.setunit?.find(option => option?.value === item.unit)} 
                                                                                                                                    options={item.setunit}
                                                                                                                                    menuPosition="fixed"   // Fixes the position of the menu to the viewport
                                                                                                                                    menuPlacement="auto"
                                                                                                                                    styles={commonSelectStyles}
                                                                                                                                    onChange={(opt) => {
                                                                                                                                          handleInputChange(index, "unit", opt?.value)
                                                                                                                                    }}
                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-2">


                                                                                                                              <div className="d-flex align-items-center">
                                                                                                                                    <input
                                                                                                                                          type="number"
                                                                                                                                          className="form-control form-control"
                                                                                                                                          placeholder="Enter Quantity"
                                                                                                                                          value={item.quantity}

                                                                                                                                          onChange={(e) => {
                                                                                                                                                const value = e.target.value;
                                                                                                                                                // Prevent zero and allow only positive numbers
                                                                                                                                                if (value !== "0") {
                                                                                                                                                      handleInputChange(index, "quantity", value);
                                                                                                                                                }
                                                                                                                                              }}
                                                                                                                                          // onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                                                                                                                                    />
                                                                                                                                    <span style={{ marginLeft: "-2.9rem", color: "#667085", fontSize: '14px', fontWeight: 400 }} className="">
                                                                                                                                          {item.unit}
                                                                                                                                    </span>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                            ))}
                                                                                                      <br />
                                                                                                      <hr />
                                                                                                      <ErrorMessage error={error} />
                                                                                                      {ProductList?.length > 0 &&
                                                                                                      <SubmitformButton1 handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                                                                                                      }

                                                                                                </div>
                                                                                          </form>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                          <GroupAdminUperSide />
                                    </div>
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}



export default StockProductDetailsEdit;


