import React, { useEffect, useState } from 'react';
import { Popover, Button, } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import Select from "react-select";

const TableFilter = ({ open, anchorEl, handleClose, handleFilter, Type, url23, StockCategoryList }) => {

  // var pathname = window.location.pathname;
  // var pathnamesplits = pathname.split("/");
  // var url23 = pathnamesplits[2];

  const [Status, setStatus] = useState('');
  const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));


  useEffect(() => {
    setStatus(filterData?.Status || '');
  }, [Type]);

  const handleSubmit = () => {
    handleFilter({ Status });
    sessionStorage.setItem(`${url23}_filterData`, JSON.stringify({ Status }));
  };


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   sessionStorage.setItem(`${url23}_${Type}filterData`, JSON.stringify({ orderId, orderStatus, startDate, endDate }));
  //   handleFilter({ orderId, orderStatus, startDate, endDate });
  // };


  const handleSubmitClear = () => {
    handleFilter({});
    setStatus("");
    sessionStorage.removeItem(`${url23}_filterData`);
  };

  useEffect(() => {
    if (Status) {
      handleSubmit();
    }
  }, [Status]);

  useEffect(() => {
    if (Type && filterData === null) {
      handleSubmitClear();
    }
  }, [Type && filterData === null]);


  // console.log("StockCategoryList", StockCategoryList)

  let label;
  switch (url23) {
    case "menu_management":
      label = "Menu Status";
      break;
    case "email_marketing":
      label = "Status";
      break;
    case "productlist":
      label = "Status";
      break;
    case "categorylist":
      label = "Status";
      break;
    case "stock_management":
        label = "Category";
        break;
    default:
      label = "Status";
  }

  // const options = url23 === "menu_management"
  //   ? [
  //     { value: "Active", label: "Active" },
  //     { value: "Inactive", label: "Inactive" }
  //   ]
  //   : url23 === "email_marketing" ? [
  //     { value: "Subscribed", label: "Subscribed" },
  //     { value: "Unsubscribed", label: "Unsubscribed" }
  //   ] : url23 === "productlist" ? [
  //     { value: "Active", label: "Active" },
  //     { value: "Inactive", label: "Inactive" }
  //   ] : url23 === "categorylist" ? [
  //     { value: "Active", label: "Active" },
  //     { value: "Inactive", label: "Inactive" }
  //   ] : ""



  let options;

  switch (url23) {
    case "menu_management":
    case "productlist":
    case "categorylist":
      options = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" }
      ];
      break;
    case "email_marketing":
      options = [
        { value: "Subscribed", label: "Subscribed" },
        { value: "Unsubscribed", label: "Unsubscribed" }
      ];
      break;
    case "stock_management":
      options = StockCategoryList?.filter((main) => main?.is_active)?.map((sponsor) => ({
        value: sponsor?._id,
        label: sponsor?.category_name,
      }));
      break;
    default:
      options = "";
      break;
  }



  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        handleClose();
        setStatus("");
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: 3,
          width: 250,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 35,
            height: 35,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 45,
            width: 13,
            height: 13,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Container className='mt-5 mb-3'>
        <Row >
          <Col sm={6} lg={6} md={6}>
            <h3>Filter</h3>
          </Col>
          <Col sm={6} lg={6} md={6} className="text-end">
            <h5 onClick={(e) => {
              handleSubmitClear();
            }} style={{ color: "#F25042", cursor: "pointer" }}> Clear Filters</h5>
          </Col>
        </Row>
      </Container>
      <form onSubmit={() => handleSubmit()}>
        <Container>
          <Row className="mb-10">
            <Col sm={12} lg={12} md={12}>
              <label className="form-label fs-6">{label}</label>
              <Select
                value={Status}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '41px',
                    borderRadius: "6px",
                    width: "100%",
                    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', // Corrected syntax
                  }),
                }}
                onChange={(opt) => setStatus(opt)}
                options={options}
                placeholder={`Select ${label}`}
              />


              {/* <Select
                placeholder="Select Products Category"
                value={category}
                options={
                  StockCategoryList?.filter((main) => main?.is_active)?.map((sponsor) => ({
                    label: sponsor.category_name,
                    value: sponsor._id,
                  }))
                }
                menuPlacement="top"
                onChange={(opt) => setcategory(opt)}
                isDisabled={EditId}
              /> */}

            </Col>
          </Row>
          {/* <Button type="submit" style={{
            fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
            textTransform: "none", fontSize: 15, fontWeight: 500,
            color: "#12B76A", cursor: "pointer"
          }}>Apply Filters</Button> */}
        </Container>
      </form>
    </Popover>
  );
};

export default TableFilter;
