import { UserPermissionAllData } from "../GroupAdmin/Comman/UserPermission";

let customToken = localStorage.getItem("customToken");


let headers = {
  Authorization: "Bearer " + UserPermissionAllData?.customToken,
  "Content-Type": "application/json",
};

export default headers;
