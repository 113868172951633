import { PRINTER } from "../../actions";

const printerListReducer = (state = [], action) => {
    switch (action.type) {
        case PRINTER.CLEAR_PRINTER_LIST:
            return [];
        case PRINTER.SET_PRINTER_LIST:
            return [...action?.printerlist];
        default:
            return state;
    }
}

const printerCatagoryListReducer = (state = [], action) => {
    switch (action.type) {
        case PRINTER.CLEAR_PRINTERCATEGORY_LIST:
            return [];
        case PRINTER.SET_PRINTERCATEGORY_LIST:
            return [...action?.printerCategorylist];
        default:
            return state;
    }
}

export { printerListReducer , printerCatagoryListReducer }