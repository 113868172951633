import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { useEffect } from "react";
import $ from "jquery";
import { showSuccessNotification } from "../../Comman/Swal/Swal";
import { applyFilterMeHandlers } from "../../Comman/Error/jqueryUtils";
import useDrawer from "../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminAddTable() {
  let history = useHistory();
  let id = useParams();

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const [user ,setuser] = useState("")

  useEffect(() => {
    BranchDetailsViewData();
  }, [id.id]);  

  function BranchDetailsViewData() {
    if(id.id){
      api.get(`/groupadmin/branch/${id.id}`, { headers }).then((res) => {
        setuser( res.data.data);
      });
    }
  }

  // const [table_number, settable_number] = useState("");
  // const [pos_table_number, setpos_table_number] = useState([]);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  var pathname = window.location.pathname;
  var url = pathname.split("/")
  var NewTableNumber = Number(url[5]);
  // var OldTableNumber = Number(url[6]);



  
  // const [data, setForm] = useState([...Array(NewTableNumber)]?.map((_, i) => {
  //   return {table_number : i + 1 , pos_table_number : ""} ;
  // }));

  const [data, setForm] = useState(
    [...Array(Number(NewTableNumber))]?.map((_, i) => 
    {
    // return  {table_number : i + Number(OldTableNumber > 0 ? OldTableNumber + 1 : 1)  , pos_table_number : ""} ;
    return  {table_number : ""  , pos_table_number : ""} ;
  }));

  // console.log("data" ,data)



  // const handleCheck = (index, e, selected) => {
  //   let temp = [...data];
  //   console.log("temp" , index ,  selected)
  //   temp[index][selected] =
  //      e.target.value;
  //   setForm(temp);
  // };

  const handleCheck = (index, e, selected) => {
    let temp = [...data];
    const newValue = e.target.value; // Store the new value
  
    temp[index][selected] = newValue;
    setForm(temp);
    // console.log("temp" ,temp)
  };

  // console.log("data", data);

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    for (let i = 0; i < data.length; i++) {
      // if (data[i].ingredient == "") {
      //   setError("No Any Field Empty");
      //   return;
      // }

      if (data[i].pos_table_number == "") {
        setError("Please enter table number ");
        return;
      }
      else if(data[i].pos_table_number == 0){
        setError("Table number does not enter zero.");
        return; 
      }
    }


  let item =  {
      branch_id : id.id,
      tableData : data.map((item) => ({
        table_number: item?.pos_table_number, // Copy pos_table_number to table_number
        pos_table_number: item?.pos_table_number // Keep pos_table_number as is
      })),
    }

    // console.log("item" ,item);
    // return


    // if (table_number == 0) {
    //   setError("Table number does not enter zero.");
    // }
    // else if (pos_table_number === "") {
    //   setError("Please select POS table ID");
    // }
    // else {
      api
        .post(`/groupadmin/table/add`, item, { headers })
        .then((res) => {
          showSuccessNotification("Table has been created succesfully.");
          history.push(`/group_admin/branch_management/table_management/${id.id}`);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    // }
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  // const [POSTable, setPOSTable] = useState([]);
  // const [pos_status_active, setpos_status_active] = useState(false);
  // const [pos_name, setpos_name] = useState("");
  // const [abc, setabc] = useState(true);

  // useEffect(() => {
  //   GetPosIDDetails();
  // }, []);

  // function GetPosIDDetails() {
  //   api
  //     .get(`/groupadmin/settings/POS/getFloorPlanDetails/${id.id}`, {
  //       headers,
  //     })
  //     .then((res) => {
  //       const tableData = res?.data?.data[0];
  //       setPOSTable(tableData?.table_list ? tableData?.table_list : []);
  //       setpos_name(tableData?.pos_name ? tableData?.pos_name : "");
  //       setpos_status_active(
  //         tableData?.pos_status_active ? tableData?.pos_status_active : false
  //       );
  //     })
  //     .catch((error) => {
  //       setabc(false);
  //       console.log(error?.response?.data?.error?.message);
  //     });
  // }

  let branch_name_EN;

  // console.log("pos_name", pos_name);

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${mainClassCustome.join(" ")}`}>
                <div className="d-flex flex-stack">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h1
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Table Management
                      </h1>
                    </div>
                  </div>

                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      {user?.resData?.branch_name_EN ? 
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Table List - {user?.resData?.branch_name_EN}
                      </h3> : "" }
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                           onClick={() => history.push(`/group_admin/branch_management/table_management/${id.id}`)}
                           className="btn btn-primary"
                           data-kt-menu-trigger="click"
                           data-kt-menu-attach="parent"
                           data-kt-menu-placement="bottom-end"  
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="py-10">
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit} >
                            {NewTableNumber && (
                              <div className="row col-md-12">
                                {data.map((details, index) => (
                                  <React.Fragment key={index}>
                                <div className="col-md-6" style={{display  :"none"}}>
                                  <div className="mb-10">
                                    <label className="required form-label">
                                      Table Number
                                    </label>
                                    <input
                                      type="text"
                                      className="filterme form-control form-control"
                                      placeholder="Table Number 1"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="mb-10">
                                    <label className="required form-label">
                                    Table Number
                                    </label>
                                    <input
                                          type="text"
                                          className="filterme form-control form-control"
                                          placeholder="Table Number"
                                          value={details?.pos_table_number}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "pos_table_number"
                                                      )
                                                    }
                                        />
                                  </div>
                                </div>
                               
                                </React.Fragment>
                                ))}
                             

                             
                              <div className="col-md-12"> {errorDiv} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() => history.push(`/group_admin/branch_management/table_management/${id.id}`)}
                                >
                                  {" "}
                                  Cancel{" "}
                                </button>
                              </div>
                              </div>
                               )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
