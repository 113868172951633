import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAdminSettingCommon from "./Common";
import { getFooterList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";


function GroupAdminSettingFooter({ getFooterList, footerList }) {

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  const userPermissionType_ = userPermissionType === "groupadmin"

  const [Section1Data, setSection1Data] = useState([]);
  const [Section2Data, setSection2Data] = useState([]);
  const [Section3Data, setSection3Data] = useState([]);

  const [sectionTitle, setSectionTitle] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [description, setdescription] = useState("");
  const [SectionTitle2, setSectionTitle2] = useState("");
  const [SectionTitle3, setSectionTitle3] = useState("");
  const [Section1Active, setSection1Active] = useState("");
  const [Section2Active, setSection2Active] = useState("");
  const [Section3Active, setSection3Active] = useState("");


  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );


  const [error1, setError1] = useState(null);
  const errorDiv1 = error1 ? (
    <div className="text-center alert alert-danger">{error1}</div>
  ) : (
    ""
  );


  const [error2, setError2] = useState(null);
  const errorDiv2 = error2 ? (
    <div className="text-center alert alert-danger">{error2}</div>
  ) : (
    ""
  );



  const [subMenus, setSubMenus] = useState([{ link: '', name: '', seq_no: "", is_new_tab: false, _id: "" }]);

  const handleAddSubMenu = () => {
    setSubMenus([...subMenus, { link: '', name: '', seq_no: "", is_new_tab: false, _id: "" }]);
  };

  const handleRemoveSubMenu = (index) => {
    const updatedSubMenus = [...subMenus];
    updatedSubMenus.splice(index, 1);
    setSubMenus(updatedSubMenus);
  };

  const handleSubMenuChange = (index, field, value) => {
    const updatedSubMenus = [...subMenus];
    updatedSubMenus[index][field] = value;
    setSubMenus(updatedSubMenus);
  };

  const [subMenus3, setSubMenus3] = useState([{ link: '', name: '', seq_no: "", is_new_tab: false, _id: "" }]);

  const handleAddSubMenu3 = () => {
    setSubMenus3(prevState => [...prevState, { link: '', name: '', seq_no: "", is_new_tab: false, _id: "" }]);
  };

  const handleRemoveSubMenu3 = (index) => {
    const updatedSubMenus = [...subMenus3];
    updatedSubMenus.splice(index, 1);
    setSubMenus3(updatedSubMenus);
  };


  const handleSubMenuChange3 = (index, field, value) => {
    const updatedSubMenus = [...subMenus3];
    updatedSubMenus[index][field] = value;
    setSubMenus3(updatedSubMenus);
  };


  function handleSubmit(event) {
    event.preventDefault();
    setError(null);


    const socials = [];

    // Push social media links to the array if they are not empty
    if (facebookUrl) socials.push({ type: 'facebook', link: facebookUrl });
    if (instagramUrl) socials.push({ type: 'instagram', link: instagramUrl });
    if (twitterUrl) socials.push({ type: 'twitter', link: twitterUrl });
    if (linkedinUrl) socials.push({ type: 'linkedin', link: linkedinUrl });

    let formData = new FormData();

    formData.append('section_number', 1);
    if (sectionTitle) {
      formData.append('title', sectionTitle);
    }
    formData.append('description', description);
    if (Section1Data[0]?._id) {
      formData.append('section_id', Section1Data[0]?._id);
    }
    formData.append("is_active", Section1Active);
    socials.forEach((social, index) => {
      formData.append(`socials[${index}][type]`, social.type);
      formData.append(`socials[${index}][link]`, social.link);
    });

    api
      .post(`/groupAdmin/cms/footer`, formData, { headers })
      .then((res) => {
        getFooterList();
        showSuccessNotification("Your work has been saved successfully.")
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.description);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.description);
        }
      });
  }

  function handleSubmitSection2(event) {
    event.preventDefault();
    setError1(null);

    let item = {
      section_number: 2,
      title: SectionTitle2,
      menu: subMenus.map(subMenu => ({
        link: subMenu.link,
        name: subMenu.name,
        is_new_tab: subMenu.is_new_tab,
        seq_no: subMenu.seq_no
      }))
    };

    let item1 = {
      section_number: 2,
      title: SectionTitle2,
      section_id: Section2Data[0]?._id,
      is_active: Section2Active,
      menu: subMenus.map(subMenu => ({
        link: subMenu.link,
        name: subMenu.name,
        is_new_tab: subMenu.is_new_tab,
        seq_no: subMenu.seq_no,
        _id: subMenu._id
      }))
    };

    let formData = new FormData();

    // Append section_number, title, and section_id to the form data
    formData.append('section_number', 2);
    formData.append('title', SectionTitle2);
    if (Section2Data[0]?._id) {
      formData.append('section_id', Section2Data[0]?._id);
    }
    formData.append('is_active', Section2Active);

    // Append menu items to the form data
    subMenus.forEach((subMenu, index) => {
      formData.append(`menu[${index}][link]`, subMenu.link);
      formData.append(`menu[${index}][name]`, subMenu.name);
      formData.append(`menu[${index}][is_new_tab]`, Boolean(subMenu.is_new_tab));
      formData.append(`menu[${index}][seq_no]`, subMenu.seq_no);
      if (subMenu._id) {
        formData.append(`menu[${index}][_id]`, subMenu._id);
      }
    });

    console.log(Object.fromEntries(formData))
    // return

    // console.log("item", item);
    // return

    api
      .post(`/groupAdmin/cms/footer`, Section2Data[0]?._id ? item1 : item, { headers })
      .then((res) => {
        getFooterList();
        setError1(null);
        showSuccessNotification("Your work has been saved successfully.")
      })
      .catch((err) => {
        if (err.response) {
          setError1(err.response?.data?.error?.description);
        } else if (err.request) {
          setError1(err.request);
        } else {
          setError1(err.description);
        }
      });
  }

  function handleSubmitSection3(event) {
    event.preventDefault();
    setError2(null);

    let item = {
      section_number: 3,
      title: SectionTitle3,
      menu: subMenus.map(subMenu => ({
        link: subMenu.link,
        name: subMenu.name,
        is_new_tab: subMenu.is_new_tab,
        seq_no: subMenu.seq_no
      }))
    };

    let item1 = {
      section_number: 3,
      title: SectionTitle3,
      section_id: Section3Data[0]?._id,
      is_active: Section3Active,
      menu: subMenus3.map(subMenu => ({
        link: subMenu.link,
        name: subMenu.name,
        is_new_tab: subMenu.is_new_tab,
        seq_no: subMenu.seq_no,
        _id: subMenu._id
      }))
    };

    // let formData = new FormData();

    //   // Append section_number, title, and section_id to the form data
    //   formData.append('section_number', 2);
    //   formData.append('title', SectionTitle2);
    //   if(Section2Data[0]?._id){
    //     formData.append('section_id', Section2Data[0]?._id);
    //   }
    //   formData.append('is_active', Section2Active);

    //   // Append menu items to the form data
    //   subMenus.forEach((subMenu, index) => {
    //     formData.append(`menu[${index}][link]`, subMenu.link);
    //     formData.append(`menu[${index}][name]`, subMenu.name);
    //     formData.append(`menu[${index}][is_new_tab]`, Boolean(subMenu.is_new_tab));
    //     formData.append(`menu[${index}][seq_no]`, subMenu.seq_no);
    //     if(subMenu._id){
    //       formData.append(`menu[${index}][_id]`, subMenu._id); 
    //     }
    //   });

    //   console.log(Object.fromEntries(formData))
    // return

    // console.log("item", item);
    // return

    api
      .post(`/groupAdmin/cms/footer`, Section3Data[0]?._id ? item1 : item, { headers })
      .then((res) => {
        getFooterList();
        setError1(null);
        showSuccessNotification("Your work has been saved successfully.")
      })
      .catch((err) => {
        if (err.response) {
          setError1(err.response?.data?.error?.description);
        } else if (err.request) {
          setError1(err.request);
        } else {
          setError1(err.description);
        }
      });
  }

  useEffect(() => {
    getFooterList()
  }, [getFooterList]);


  useEffect(() => {
    if (footerList) {
      const tableData = footerList;
      const section1Data = tableData.filter(item => item.section_number === 1);
      const section2Data = tableData.filter(item => item.section_number === 2);
      const section3Data = tableData.filter(item => item.section_number === 3);
      if (section1Data) {
        setSectionTitle(section1Data[0]?.title);
        section1Data[0]?.socials.forEach(item => {
          switch (item.type) {
            case 'facebook':
              setFacebookUrl(item.link);
              break;
            case 'instagram':
              setInstagramUrl(item.link);
              break;
            case 'twitter':
              setTwitterUrl(item.link);
              break;
            case 'linkedin':
              setLinkedinUrl(item.link);
              break;
            default:
              break;
          }
        });
        setSection1Active(section1Data[0]?.is_active);
        setdescription(section1Data[0]?.description)
      }
      if (section2Data[0]) {
        setSectionTitle2(section2Data[0]?.title);
        setSection2Active(section2Data[0]?.is_active);
        setSubMenus(section2Data[0]?.menu);
        // console.log('ss' ,section2Data)
      }
      if (section3Data[0]) {
        setSectionTitle3(section3Data[0]?.title);
        setSection3Active(section3Data[0]?.is_active);
        setSubMenus3(section3Data[0]?.menu);
        // console.log('ss' ,section3Data);
      }
      setSection1Data(section1Data);
      setSection2Data(section2Data);
      setSection3Data(section3Data);
    }
  }, [footerList])

  function getFooterList1() {
    api
      .get(`/groupAdmin/cms/footer`, { headers })
      .then((res) => {
        const tableData = res?.data?.data;
        console.log("tableData", tableData)
        const section1Data = tableData.filter(item => item.section_number === 1);
        const section2Data = tableData.filter(item => item.section_number === 2);
        const section3Data = tableData.filter(item => item.section_number === 3);
        if (section1Data) {
          setSectionTitle(section1Data[0]?.title);
          section1Data[0]?.socials.forEach(item => {
            switch (item.type) {
              case 'facebook':
                setFacebookUrl(item.link);
                break;
              case 'instagram':
                setInstagramUrl(item.link);
                break;
              case 'twitter':
                setTwitterUrl(item.link);
                break;
              case 'linkedin':
                setLinkedinUrl(item.link);
                break;
              default:
                break;
            }
          });
          setSection1Active(section1Data[0]?.is_active);
          setdescription(section1Data[0]?.description)
        }

        if (section2Data[0]) {
          setSectionTitle2(section2Data[0]?.title);
          setSection2Active(section2Data[0]?.is_active);
          setSubMenus(section2Data[0]?.menu);
          // console.log('ss' ,section2Data)
        }


        if (section3Data[0]) {
          setSectionTitle3(section3Data[0]?.title);
          setSection3Active(section3Data[0]?.is_active);
          setSubMenus3(section3Data[0]?.menu);
          // console.log('ss' ,section3Data);
        }
        // Set state for each section
        setSection1Data(section1Data);
        setSection2Data(section2Data);
        setSection3Data(section3Data);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.description);
        }
      });
  }

  let history = useHistory();

  function deleteSection2(section_id, menu_id) {
    const requestData = {
      menu_id: menu_id
    };
    deleteCommonFunction(`/groupAdmin/cms/footer/menu/${section_id}`, "Submenu has been successfully deleted.", () => getFooterList(), requestData);
  }


  const SAVE = (userPermissionType_ || UserPermissionAllData?.role_details?.settings?.some(permission =>
    permission?.key === "cms_settings" &&
    (permission?.access?.includes("edit") || permission?.access?.includes("create"))
  )) && (
      <button
        type="submit"
        id="kt_modal_new_address_submit"
        className="btn btn-primary"
      >
        <span className="indicator-label"> Save </span>
      </button>
    );




  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      CMS Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          CMS Settings - Footer
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <GroupAdminSettingCommon />
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            <div className="card card-docs mb-10">
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmit}>
                                  <div className="rounded border p-10">
                                    <div className="row col-md-12">
                                      <h3>
                                        <div className="mb-1">
                                          <input className="form-check-input" type="checkbox"
                                            checked={Section1Active}
                                            onChange={() => setSection1Active(!Section1Active)} />
                                          <label className="form-check-label mx-2">
                                            <h4>Section 1</h4>
                                          </label>
                                        </div>
                                      </h3>
                                    </div>
                                    <hr />
                                    {/* <div className="row col-md-12 mb-7 mt-7">
                                    <div className="col-md-3">
                                          <p>Section Title</p>
                                    </div>
                                    <div className="col-md-9">
                                    <input
                                          type="text"
                                          className="form-control form-control"
                                          placeholder="Enter Section Title"
                                          value={sectionTitle}
                                          onChange={(e)=>setSectionTitle(e.target.value)}
                                    />
       
                                    </div>
                                  
                              </div>
                              <hr/> */}

                                    <div className="row col-md-12 mb-7 mt-5">
                                      <div className="col-md-3">
                                        <p>Footer Icon</p>
                                      </div>
                                      <div className="col-md-9">
                                        <img
                                          className="image-input-wrapper w-125px h-125px"
                                          src={Section1Data[0]?.logo_url || "/assets/media/avatars/blank.png"}
                                          alt=""
                                          height={125}
                                          width={125}
                                        />
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="row col-md-12 mb-7 mt-5">
                                      <div className="col-md-3">
                                        <p>Description</p>
                                      </div>
                                      <div className="col-md-9">
                                        <textarea
                                          rows="4" cols="50"
                                          className="form-control form-control"
                                          placeholder="Enter a description..."
                                          value={description?.substring(0, 250)}
                                          onChange={(e) => setdescription(e.target.value)}
                                        />

                                        {description?.length >= 250 ?
                                          <div style={{ color: description?.length >= 250 ? "red" : "" }} className="description_upto mt-3">
                                            description upto 250 characters
                                          </div> :
                                          <div style={{ color: description?.length >= 250 ? "red" : "" }} className="description_upto mt-3">
                                            description upto 250 characters  {description?.length > 0 ? ", number of characters remaining" : ""} {description?.length > 0 ? 250 - Number(description?.length) : ""}
                                          </div>}
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="row col-md-12 mb-7 mt-5">
                                      <div className="col-md-3 mb-5">
                                        <p>Socials</p>
                                      </div>
                                      <div className="col-md-9 mb-5">
                                        <div class="input-group">
                                          <span class="input-group-text"><img
                                            alt="Facebook"
                                            loading="lazy"
                                            src="/assets/media/avatars/Facebook.png"
                                            style={{ height: 17, marginTop: 3 }}
                                          />
                                          </span>
                                          <input
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Enter Facebook Profile URL"
                                            value={facebookUrl}
                                            onChange={(e) => setFacebookUrl(e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-5">
                                      </div>
                                      <div className="col-md-9 mb-5">
                                        <div class="input-group">
                                          <span class="input-group-text"><img
                                            alt="Insta"
                                            loading="lazy"
                                            src="/assets/media/avatars/Insta.png"
                                            style={{ height: 17, marginTop: 3 }}
                                          /></span>
                                          <input
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Enter Instagram Profile URL"
                                            value={instagramUrl}
                                            onChange={(e) => setInstagramUrl(e.target.value)}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-3 mb-5">
                                      </div>
                                      <div className="col-md-9 mb-5">
                                        <div class="input-group">
                                          <span class="input-group-text"><img
                                            alt="Twitter"
                                            loading="lazy"
                                            src="/assets/media/avatars/Twitter.png"
                                            style={{ height: 17, marginTop: 3 }}
                                          /></span>
                                          <input
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Enter X/Twitter Profile URL"
                                            value={twitterUrl}
                                            onChange={(e) => setTwitterUrl(e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-5">
                                      </div>
                                      <div className="col-md-9 mb-5">
                                        <div class="input-group">
                                          <span class="input-group-text"><img
                                            alt="Twitter"
                                            loading="lazy"
                                            src="/assets/media/avatars/Linkd.png"
                                            style={{ height: 17, marginTop: 3 }}
                                          /></span>
                                          <input
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Enter LinkedIn Profile URL"
                                            value={linkedinUrl}
                                            onChange={(e) => setLinkedinUrl(e.target.value)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-12"> {errorDiv} </div>
                                    <div className="text-center">

                                      {SAVE}
                                      <button onClick={() => history.push("/group_admin/settings")} type="button" className="btn btn-danger mx-2">
                                        Cancel
                                      </button>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="card card-docs mb-10">
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmitSection2}>
                                  <div className="rounded border p-10">
                                    <div className="row col-md-12">
                                      <h3>
                                        <div className="mb-1">
                                          <input className="form-check-input" type="checkbox"
                                            checked={Section2Active}
                                            onChange={() => setSection2Active(!Section2Active)} />
                                          <label className="form-check-label mx-2">
                                            <h4>Section 2</h4>
                                          </label>
                                        </div>
                                      </h3>
                                    </div>
                                    <hr />
                                    <div className="row col-md-12  mb-8 mt-8">
                                      <div className="col-md-3">
                                        <p>Section Title</p>
                                      </div>
                                      <div className="col-md-9">
                                        <input
                                          type="text"
                                          className="form-control form-control"
                                          value={SectionTitle2}
                                          required
                                          placeholder="Enter Section Title"
                                          onChange={(e) => setSectionTitle2(e.target.value)}
                                        />

                                      </div>
                                    </div>
                                    <hr />


                                    {subMenus.map((submenu, index) => (
                                      <div key={index} className="row col-md-12 mt-8">
                                        <div className="col-md-3">
                                          <p>Menu Details</p>
                                        </div>
                                        <div className="col-md-4">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder="Enter Menu URL"
                                            value={submenu.link}
                                            required
                                            onChange={(e) => handleSubMenuChange(index, 'link', e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            value={submenu.name}
                                            required
                                            placeholder="Enter Menu Name"
                                            onChange={(e) => handleSubMenuChange(index, 'name', e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder="Seq Num"
                                            value={submenu.seq_no}
                                            required
                                            onChange={(e) => handleSubMenuChange(index, 'seq_no', e.target.value.replace(/\D/, ''))}
                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <div className="row">
                                            <div className="col-md-9 my-3">
                                              <div className="mb-1">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  onChange={(e) => handleSubMenuChange(index, 'is_new_tab', e.target.checked)}
                                                  checked={Boolean(submenu.is_new_tab)}
                                                />
                                                <label className="form-check-label mx-2">
                                                  <h6>New Tab</h6>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-3 text-end my-3">
                                              <DeleteIcon onClick={() => submenu?._id ? 
                                              (userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                permission?.key === "cms_settings" &&
                                                permission?.access?.includes("delete")
                                              )) &&
                                                deleteSection2(Section2Data[0]?._id, submenu?._id) : handleRemoveSubMenu(index)} className="fs-1" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <br />
                                    <div className="row col-md-12">
                                      <div className="col-md-3">

                                      </div>
                                      <div className="col-md-9">
                                        <button
                                          type="button"
                                          onClick={handleAddSubMenu}
                                          className="btn btn-danger">
                                          Add Menu
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-12"> {errorDiv1} </div>
                                    <div className="text-center">
                                      {SAVE}
                                      <button type="button" onClick={() => history.push("/group_admin/settings")} className="btn btn-danger mx-2">
                                        Cancel
                                      </button>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="card card-docs mb-10">
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmitSection3}>
                                  <div className="rounded border p-10">
                                    <div className="row col-md-12">
                                      <h3>
                                        <div className="mb-1">
                                          <input className="form-check-input" type="checkbox"
                                            checked={Section3Active}
                                            onChange={() => setSection3Active(!Section3Active)} />
                                          <label className="form-check-label mx-2">
                                            <h4>Section 3</h4>
                                          </label>
                                        </div>
                                      </h3>
                                    </div>
                                    <hr />
                                    <div className="row col-md-12 mb-8 mt-8">
                                      <div className="col-md-3">
                                        <p>Section Title</p>
                                      </div>
                                      <div className="col-md-9">
                                        <input
                                          type="text"
                                          className="form-control form-control"
                                          placeholder="Enter Section Title"
                                          required
                                          value={SectionTitle3}
                                          onChange={(e) => setSectionTitle3(e.target.value)}
                                        />

                                      </div>
                                    </div>
                                    <hr />

                                    {subMenus3.map((submenu, index) => (
                                      <div key={index} className="row col-md-12 my-2 mt-8">
                                        <div className="col-md-3">
                                          <p>Menu Details</p>
                                        </div>
                                        <div className="col-md-4">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            value={submenu.link}
                                            placeholder="Enter Menu URL"
                                            required
                                            onChange={(e) => handleSubMenuChange3(index, 'link', e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            value={submenu.name}
                                            placeholder="Enter Menu Name"
                                            required
                                            onChange={(e) => handleSubMenuChange3(index, 'name', e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            value={submenu.seq_no}
                                            placeholder="Seq Num"
                                            required
                                            onChange={(e) => handleSubMenuChange3(index, 'seq_no', e.target.value.replace(/\D/, ''))}
                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <div className="row">
                                            <div className="col-md-9 my-3">
                                              <div className="mb-1">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={submenu.is_new_tab}
                                                  onChange={(e) => handleSubMenuChange3(index, 'is_new_tab', e.target.checked)}
                                                />
                                                <label className="form-check-label mx-2">
                                                  <h6>New Tab</h6>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-3 text-end my-2">
                                              <DeleteIcon onClick={() => submenu?._id ?
                                                (userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                  permission?.key === "cms_settings" &&
                                                  permission?.access?.includes("delete")
                                                )) &&
                                                deleteSection2(Section3Data[0]?._id, submenu?._id) :
                                                handleRemoveSubMenu3(index)} className="fs-1" />
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    ))}
                                    <br />
                                    <div className="row col-md-12">
                                      <div className="col-md-3">
                                      </div>
                                      <div className="col-md-9">
                                        <button
                                          type="button"
                                          onClick={handleAddSubMenu3}
                                          className="btn btn-danger">
                                          Add Menu
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-12"> {errorDiv2} </div>
                                    <div className="text-center">
                                      {SAVE}
                                      <button type="button" onClick={() => history.push("/group_admin/settings")} className="btn btn-danger mx-2">
                                        Cancel
                                      </button>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  footerList: state.footerList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFooterList: () => dispatch(getFooterList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminSettingFooter);

