import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import api from "../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import headers from "../../../CommonApi/headers";
import Swal from "sweetalert2";
import $ from "jquery";
window.jquery = window.$ = $;

export default function GroupAdminCustomManagement() {
  const [highlighted_text, sethighlighted_text] = useState("");
  const [non_highlighted_text, setnon_highlighted_text] = useState("");
  const [button_colour, setbutton_colour] = useState("");
  const [highlighted_button_colour, sethighlighted_button_colour] =
    useState("");
  const [logo, setlogo] = useState("");
  const [icon, seticon] = useState("");

  const [imglogo, setImglogo] = useState(null);
  const onChangelogo = (e) => {
    if (e.target.files[0]) {
      setImglogo(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setlogo(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [imgicon, setImgicon] = useState(null);
  const onChangeicon = (e) => {
    if (e.target.files[0]) {
      setImgicon(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        seticon(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  /////////////////////////////////// Custom Managment ////////////////////////////

  const [editCustom, seteditCustom] = useState("");
  const [custom_id, setcustom_id] = useState("");
  const [logo_url, setlogo_url] = useState(null);
  const [icon_url, seticon_url] = useState(null);

  const [values, setValues] = useState({
    highlighted_text: "",
    non_highlighted_text: "",
    button_colour: "",
    highlighted_button_colour: "",
    logo_url: "",
    icon_url: "",
  });

  useEffect(() => {
    CustomeGetData();
  }, []);

  function CustomeGetData() {
    api.get(`/groupadmin/custom`, { headers }).then((res) => {
      const tableData = res.data.data[0];
      if (tableData._id != "") {
        seteditCustom(tableData);
        setcustom_id(tableData._id);
        setValues({
          button_colour: tableData.button_colour,
          highlighted_text: tableData.highlighted_text,
          non_highlighted_text: tableData.non_highlighted_text,
          highlighted_button_colour: tableData.highlighted_button_colour,
          logo_url: tableData.logo_url,
          icon_url: tableData.icon_url,
        });
        setbutton_colour(tableData.button_colour);
        sethighlighted_text(tableData.highlighted_text);
        setnon_highlighted_text(tableData.non_highlighted_text);
        sethighlighted_button_colour(tableData.highlighted_button_colour);
        setlogo_url(tableData.logo_url);
        seticon_url(tableData.icon_url);
      } else {
        seteditCustom("");
      }
    });
  }

  /////////////////////////// Custome Managment //////////////////////////////

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const history = useHistory();

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();

    const formData = new FormData();

    if (logo) {
      formData.append("logo", imglogo);
    }
    if (icon) {
      formData.append("icon", imgicon);
    }
    if (highlighted_text) {
      formData.append("highlighted_text", highlighted_text);
    }
    if (non_highlighted_text) {
      formData.append("non_highlighted_text", non_highlighted_text);
    }
    if (highlighted_button_colour) {
      formData.append("highlighted_button_colour", highlighted_button_colour);
    }
    if (button_colour) {
      formData.append("button_colour", button_colour);
    }

    api
      .post(`/groupadmin/custom`, formData, { headers })
      .then((res) => {
        $(".loader").hide();
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Custom data has been added successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          setError(err.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  const [imglogo_url, setImglogo_url] = useState(null);
  const onChangelogo_url = (e) => {
    if (e.target.files[0]) {
      setImglogo_url(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setlogo_url(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [imgicon_url, setImgicon_url] = useState(null);
  const onChangeicon_url = (e) => {
    if (e.target.files[0]) {
      setImgicon_url(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        seticon_url(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function EdithandleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();

    const formData = new FormData();

    if (logo_url) {
      formData.append("logo", imglogo_url);
    }
    if (icon_url) {
      formData.append("icon", imgicon_url);
    }
    if (highlighted_text) {
      formData.append("highlighted_text", highlighted_text);
    }
    if (non_highlighted_text) {
      formData.append("non_highlighted_text", non_highlighted_text);
    }
    if (highlighted_button_colour) {
      formData.append("highlighted_button_colour", highlighted_button_colour);
    }
    if (button_colour) {
      formData.append("button_colour", button_colour);
    }

    // console.log(Object.fromEntries(formData.entries()));

    api
      .patch(`/groupadmin/custom/${custom_id}`, formData, { headers })
      .then((res) => {
        $(".loader").hide();
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Custom data has been edited successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(res.data);
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          setError(err.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                    style={{ float: "left" }}
                  >
                    Custom Management
                  </h1>
                  <br />
                  <br />
                  <hr />
                  <br />
                  <br />

                  {editCustom == "" ? (
                    <div className="py-5">
                      <form onSubmit={handleSubmit}>
                        <div className="card card-docs mb-2">
                          <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="rounded border p-10">
                                  <h6>Logo</h6>
                                  <br />
                                  <div
                                    className="image-input image-input-outline mb-17"
                                    data-kt-image-input="true"
                                    // style={{
                                    //     backgroundImage: "url(/../assets/media/avatars/blank.png)"
                                    // }}
                                  >
                                    <img
                                      className="image-input-wrapper w-125px h-125px"
                                      src={
                                        logo
                                          ? logo
                                          : "/assets/media/avatars/blank.png"
                                      }
                                      alt=""
                                      height="125"
                                      width="125"
                                    />
                                    <label
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="change"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      // title="Change avatar"
                                    >
                                      <i className="bi bi-pencil-fill fs-7" />
                                      <input
                                        type="file"
                                        name="file"
                                        onChange={onChangelogo}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </label>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="cancel"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                    >
                                      <i
                                        onClick={(e) => setlogo("")}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="remove"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      title="Remove"
                                    >
                                      <i
                                        onClick={(e) => setlogo("")}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <br />
                                {/* <div className="rounded border p-10">
                                  <h6>Icon</h6>
                                  <br />
                                  <div
                                    className="image-input image-input-outline"
                                    data-kt-image-input="true"
                                    // style={{
                                    //     backgroundImage: "url(/../assets/media/avatars/blank.png)"
                                    // }}
                                  >
                                    <img
                                      className="image-input-wrapper w-125px h-125px"
                                      src={
                                        icon
                                          ? icon
                                          : "/assets/media/avatars/blank.png"
                                      }
                                      alt=""
                                      height="125"
                                      width="125"
                                    />
                                    <label
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="change"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      // title="Change avatar"
                                    >
                                      <i className="bi bi-pencil-fill fs-7" />
                                      <input
                                        type="file"
                                        name="file"
                                        onChange={onChangeicon}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </label>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="cancel"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                    >
                                      <i
                                        onClick={(e) => seticon("")}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                    <span
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="remove"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      title="Remove"
                                    >
                                      <i
                                        onClick={(e) => seticon("")}
                                        className="bi bi-x fs-2"
                                      />
                                    </span>
                                  </div>
                                </div> */}
                              </div>
                              <div className="col-md-6">
                                <div className="rounded border p-10">
                                  <div className="mb-10">
                                    <h6>Colors</h6>
                                  </div>
                                  {/* <div className="mb-10">
                                    <label
                                      
                                      className=" form-label"
                                    >
                                      Highlighted Text
                                    </label>
                                    <input
                                      type="color"
                                      value={highlighted_text}
                                      onChange={(e) =>
                                        sethighlighted_text(e.target.value)
                                      }
                                      className="form-control form-control-solid"
                                    />
                                  </div> */}
                                  <div className="mb-10">
                                    <label className=" form-label">
                                      Text color
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={non_highlighted_text}
                                      onChange={(e) =>
                                        setnon_highlighted_text(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label className=" form-label">
                                      Button Colour
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={button_colour}
                                      onChange={(e) =>
                                        setbutton_colour(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12"> {errorDiv} </div>
                          <div className="col-md-12 text-center">
                            <br />
                            <div className="loader" style={{ display: "none" }}>
                              <img src="/assets/loader.gif" />
                            </div>
                          </div>
                          <div className="modal-footer flex-center">
                            <button
                              type="submit"
                              id="kt_modal_new_address_submit"
                              className="btn btn-primary"
                            >
                              <span className="indicator-label">
                                Add Custom
                              </span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger m-2"
                              onClick={() => history.goBack()}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="py-5">
                      <form onSubmit={EdithandleSubmit}>
                        <div className="card card-docs mb-2">
                          <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="rounded border p-10">
                                  <h6>Logo</h6>
                                  <br />
                                  <div
                                    className="image-input image-input-outline mb-17"
                                    data-kt-image-input="true"
                                    // style={{
                                    //     backgroundImage: "url(/../assets/media/avatars/blank.png)"
                                    // }}
                                  >
                                    <img
                                      className="image-input-wrapper w-125px h-125px"
                                      src={
                                        logo_url
                                          ? logo_url
                                          : "/assets/media/avatars/blank.png"
                                      }
                                      alt=""
                                      height="125"
                                      width="125"
                                    />
                                    <label
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="change"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      // title="Change avatar"
                                    >
                                      <i className="bi bi-pencil-fill fs-7" />
                                      <input
                                        type="file"
                                        name="file"
                                        onChange={onChangelogo_url}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <br />
                                {/* <div className="rounded border p-10">
                                  <h6>Icon</h6>
                                  <br />
                                  <div
                                    className="image-input image-input-outline"
                                    data-kt-image-input="true"
                                    // style={{
                                    //     backgroundImage: "url(/../assets/media/avatars/blank.png)"
                                    // }}
                                  >
                                    <img
                                      className="image-input-wrapper w-125px h-125px"
                                      src={
                                        icon_url
                                          ? icon_url
                                          : "/assets/media/avatars/blank.png"
                                      }
                                      alt=""
                                      height="125"
                                      width="125"
                                    />
                                    <label
                                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                      data-kt-image-input-action="change"
                                      data-bs-toggle="tooltip"
                                      data-bs-dismiss="click"
                                      // title="Change avatar"
                                    >
                                      <i className="bi bi-pencil-fill fs-7" />
                                      <input
                                        type="file"
                                        name="file"
                                        onChange={onChangeicon_url}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </label>
                                  </div>
                                </div> */}
                              </div>
                              <div className="col-md-6">
                                <div className="rounded border p-10">
                                  <div className="mb-10">
                                    <h6>Colors</h6>
                                  </div>
                                  {/* <div className="mb-10">
                                    <label
                                      
                                      className=" form-label"
                                    >
                                      Highlighted Text
                                    </label>
                                    <input
                                      type="color"
                                      value={highlighted_text}
                                      onChange={(e) =>
                                        sethighlighted_text(e.target.value)
                                      }
                                      className="form-control form-control-solid"
                                    />
                                  </div> */}
                                  <div className="mb-10">
                                    <label className=" form-label">
                                      Text color
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={non_highlighted_text}
                                      onChange={(e) =>
                                        setnon_highlighted_text(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label className=" form-label">
                                      Button Colour
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={button_colour}
                                      onChange={(e) =>
                                        setbutton_colour(e.target.value)
                                      }
                                    />
                                  </div>
                                  {/* <div className="mb-10">
                                    <label
                                      
                                      className=" form-label"
                                    >
                                      Highlighted Button Colour
                                    </label>
                                    <input
                                      type="color"
                                      className="form-control form-control-solid"
                                      value={highlighted_button_colour}
                                      onChange={(e) =>
                                        sethighlighted_button_colour(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12"> {errorDiv} </div>
                          <div className="col-md-12 text-center">
                            <br />
                            <div className="loader" style={{ display: "none" }}>
                              <img src="/assets/loader.gif" />
                              {/* <img src="" alt="logo" /> */}
                            </div>
                          </div>
                          <div className="modal-footer flex-center">
                            <button
                              type="submit"
                              id="kt_modal_new_address_submit"
                              className="btn btn-primary"
                            >
                              <span className="indicator-label">
                                Save Custom
                              </span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger m-2"
                              onClick={() => history.goBack()}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
