// dataUtils.js

import { userPermissionType } from "./UserPermission";


export const branchfilterAndMapData = (responseData) => {
  const filterData = responseData?.filter(branch => branch?.is_active);
  const options = filterData?.map((item) => ({
    label: item.branch_name_EN,
    value: item._id,
    currency : item?.currency
  }));
  return options;
};

export const getSelectOptions = (filteredOptions) => {
  if (userPermissionType === "groupadmin") {
    return [{ label: "All Locations", value: "All" }, ...filteredOptions];
  } else if (filteredOptions.length >= 2) {
    return [{ label: "All Locations", value: "All" }, ...filteredOptions];
  } else {
    return [...filteredOptions];
  }
};

export const getPlaceholder = (filteredOptions) => {
  if (userPermissionType === "groupadmin" || filteredOptions.length >= 2) {
    return "All Locations";
  } else {
    return "Select Location";
  }
};


export const ALLLOCATIONSTYLE = {
  control: (provided) => ({
    ...provided,
    borderRadius: "8px",
    height: "30px",
    marginTop: "5px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};




    