import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import headers from "../../../../../CommonApi/headers";
import { useStyles } from "../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../Comman/TableOption";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import NoteState from "../CommanBranch/NoteState";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPrinterCategoryList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


function GroupAdminPrinterCategory({ getPrinterCategoryList, printerCatagoryList }) {

  const userPermissionType_ = userPermissionType === "groupadmin"

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );
  const [user, setuser] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [categoryId, setcategoryId] = useState("");


  useEffect(() => {
    getPrinterCategoryList();
  }, []);


  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];

  const classes = useStyles();
  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Category Name",
      field: "name",
    },

    // {
    //   title: "Branch Status",
    //   render: (rowData) => {
    //     return (
    //       <div>
    //         {rowData.is_active == true ? (
    //           <button type="button" className="btn btn-success">
    //             Active
    //           </button>
    //         ) : (
    //           <button type="button" className="btn btn-Inactive">
    //             Inactive
    //           </button>
    //         )}
    //       </div>
    //     );
    //   },
    // },

  ];


  function handleSubmit(event) {
    event.preventDefault();
    setError(null);


    if (CategoryName == "") {
      setError(`Please enter category name`);
      return;
    }
    else {

      let item = {
        name: CategoryName,
      }
      // console.log("item" ,item);
      api
        .post(`/groupadmin/printer-category`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Printer category added  successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getPrinterCategoryList();
          setCategoryName("")
          const modal = document.getElementById('kt_modal_1');

          if (modal) {
            // If the modal element is found, hide and remove the event listener
            modal.classList.remove('show');
            modal.style.display = 'none';
            // modal.removeEventListener('click', closeModal);

            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
              modalBackdrop.parentNode.removeChild(modalBackdrop);
            }
          }
          window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            // console.log("ss" ,err.response)
            if (err.response?.data?.error) {
              setError(err.response?.data?.error);

            }
          }
        });
    }
  }


  function handleSubmitEdit(event) {
    event.preventDefault();
    setError(null);


    if (CategoryName == "") {
      setError(`Please enter category name`);
      return;
    }
    else {

      let item = {
        name: CategoryName,
        categoryId: categoryId
      }
      // console.log("item" ,item);
      api
        .patch(`/groupAdmin/printer-category/edit-printer`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Printer category name edited successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          setCategoryName("");
          getPrinterCategoryList();
          const modal = document.getElementById('kt_modal_2');

          if (modal) {
            // If the modal element is found, hide and remove the event listener
            modal.classList.remove('show');
            modal.style.display = 'none';
            modal.removeEventListener('click', closeModal);

            // Remove the modal backdrop
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
              modalBackdrop.parentNode.removeChild(modalBackdrop);
            }
          }
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response?.data?.error) {
              setError(err.response?.data?.error);
            }
          }
        });
    }
  }

  function closeModal() {
    const modal1 = document.getElementById('kt_modal_1');
    const modal2 = document.getElementById('kt_modal_2');

    setCategoryName("");
    setError(null);
    if (modal1) {
      // If the modal1 element is found, hide and remove the event listener
      modal1.classList.remove('show');
      modal1.style.display = 'none';
      modal1.removeEventListener('click', closeModal);
    }
    if (modal2) {
      // If the modal2 element is found, hide and remove the event listener
      modal2.classList.remove('show');
      modal2.style.display = 'none';
      modal2.removeEventListener('click', closeModal);
    }
  }


  function deleteCategory(_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let item = {
          categoryId: _id
        }
        api
          .delete(`/groupAdmin/printer-category/delete-printer-data`, {
            data: item, headers
          })
          .then((result) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Category has been deleted successfully. ",
              showConfirmButton: false,
              timer: 1500,
            });
            getPrinterCategoryList();
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err?.response?.data?.error?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
      }
    });
  }


  

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();




  const DELETE =  userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
      permission?.key === "printer")?.access?.includes("delete")  

  const EDIT = userPermissionType_ ||  UserPermissionAllData?.role_details?.settings?.find(permission =>
        permission?.key === "printer")?.access?.includes("edit")    

        console.log("EDIT" ,EDIT)
        console.log("DELETE" ,DELETE)


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Printer - Printer Category
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                        {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                          permission?.key === "printer" &&
                          permission?.access?.includes("create")
                        )) && (
                            <Link
                              to="#kt_modal_1"
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setError(null);
                                setCategoryName("");
                              }}
                            >

                              Add Category
                            </Link>)}
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                          <li className="nav-item">
                            <Link
                              to={{
                                pathname:
                                  "/group_admin/settings/printer/printerList",
                              }}
                              className={
                                url === "printerList"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              Printer List
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerSettings",
                              
                            }}
                            className={
                              url === "printerSettings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                          Printer Settings
                          </Link>
                        </li> */}

                          <li className="nav-item">
                            <Link
                              to={{
                                pathname:
                                  "/group_admin/settings/printer/printerCategory",
                              }}
                              className={
                                url === "printerCategory"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              Printer Category
                            </Link>
                          </li>

                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            <div className="d-flex flex-stack mb-7">
                              <div
                                className="d-flex justify-content-end"
                                data-kt-docs-table-toolbar="base"
                              >
                                <h1
                                  className="anchor fw-bolder"
                                  id="custom-form-control"
                                >
                                </h1>
                              </div>
                              <div className="d-flex flex-stack">
                                <div
                                  className="d-flex justify-content-end mx-2"
                                  data-kt-docs-table-toolbar="base"
                                >



                                </div>
                              </div>
                            </div>
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">

                                <div className={classes.root}>
                                  <MaterialTable
                                    title=""
                                    data={printerCatagoryList}
                                    columns={columns}
                                    options={Tableoptions}
                                    actions={[
                                      {
                                        icon: "edit",
                                        tooltip: "Edit Branch",
                                        onClick: (event, rowData) => {
                                          console.log(rowData);
                                          setCategoryName(rowData?.name);
                                          setcategoryId(rowData?._id);
                                          const modal = document.getElementById('kt_modal_2');
                                          if (modal) {
                                            modal.classList.add('show');
                                            modal.style.display = 'block';
                                          }
                                        },
                                        hidden : EDIT ? false : true 
                                      },
                                      {
                                        icon: "delete",
                                        tooltip: "Delete Branch",
                                        onClick: (event, rowData) => deleteCategory(rowData._id),
                                        hidden : DELETE ? false : true
                                      }
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-body">
              <div className="row mt-6">
                <h1>Add New Category</h1>
              </div>


              <div className="row mt-2">
                <p style={{ fontSize: 16 }}>
                  Please enter appropriate printer category.</p>
              </div>
              <form
                onSubmit={handleSubmit}
              >
                <div className="my-5">
                  <label
                    className="required form-label"
                  >
                    New Category Name
                  </label>

                  <input
                    type="text"
                    className=" form-control"
                    placeholder="Enter New Category"
                    value={CategoryName}
                    onChange={(e) => {
                      const inputValue = e.target?.value;
                      const trimmedValue = inputValue.trim();
                      if (trimmedValue === '' && inputValue.length > 0) {
                        return;
                      }
                      setCategoryName(inputValue);
                    }}
                    aria-describedby="basic-addon2"
                  />

                </div>
                <div className="col-md-12 text-center">
                  <br />
                  <div className="loader" style={{ display: "none" }}>
                    <img src="/assets/loader.gif" />
                  </div>
                </div>
                <div className="col-md-12"> {errorDiv} </div>
                <div className="container">
                  <div className="row ">
                    <div className="row col-6">
                      <button
                        type="submit"
                        id="kt_modal_new_address_submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                    <div className="row col-6 mx-2">
                      <button
                        onClick={closeModal}
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" tabIndex={-1} id="kt_modal_2">
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-body">
              <div className="row mt-6">
                <h1>Edit Category</h1>
              </div>


              <div className="row mt-2">
                <p style={{ fontSize: 16 }}>
                  Please enter appropriate printer category.</p>
              </div>
              <form
                onSubmit={handleSubmitEdit}
              >
                <div className="my-5">
                  <label
                    className="form-label"
                  >
                    Edit Category Name
                  </label>

                  <input
                    type="text"
                    className="required form-control"
                    placeholder="Enter Category Name"
                    value={CategoryName}
                    onChange={(e) => {
                      const inputValue = e.target?.value;
                      const trimmedValue = inputValue.trim();
                      if (trimmedValue === '' && inputValue.length > 0) {
                        // If it becomes an empty string, do not update the state
                        return;
                      }
                      setCategoryName(inputValue);
                    }}

                    // onChange={(e) => setCategoryName(e.target?.value)}
                    aria-describedby="basic-addon2"
                  />

                </div>
                <div className="col-md-12 text-center">
                  <br />
                  <div className="loader" style={{ display: "none" }}>
                    <img src="/assets/loader.gif" />
                  </div>
                </div>
                <div className="col-md-12"> {errorDiv} </div>
                <div className="container">
                  <div className="row ">
                    <div className="row col-6">
                      <button
                        type="submit"
                        id="kt_modal_new_address_submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                    <div className="row col-6 mx-2">
                      <button
                        onClick={closeModal}
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  printerCatagoryList: state.printerCatagoryList
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPrinterCategoryList: () => dispatch(getPrinterCategoryList()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPrinterCategory);

