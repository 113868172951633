import React, { useState } from "react";
import { useHistory, } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import useDrawer from "../../../../../context/DrawerContext";


function GroupAdminSettingAddHeader(props) {

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [name, setname] = useState('');
  const [link, setLink] = useState("")
  const [seq_no, setseq_no] = useState('');
  const [is_new_tab, setis_new_tab] = useState(false);
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  let history = useHistory();


  const [subMenus, setSubMenus] = useState([]);

  const handleAddSubMenu = () => {
    setSubMenus([...subMenus, { link: '', name: '', seq_no: "", is_new_tab: false, _id: "" }]);
  };

  const handleRemoveSubMenu = (index) => {
    const updatedSubMenus = [...subMenus];
    updatedSubMenus.splice(index, 1);
    setSubMenus(updatedSubMenus);
  };

  const handleSubMenuChange = (index, field, value) => {
    const updatedSubMenus = [...subMenus];

    // Check for duplicates before updating the state
    if (field === 'seq_no') {
      const newValue = value.replace(/^0/, "").replace(/\D/g, ""); // Remove leading 0
      const isDuplicate = updatedSubMenus.some((submenu, i) => i !== index && submenu.seq_no === newValue);

      if (isDuplicate) {
        // Handle duplicate value, such as showing an error message
        console.log("Duplicate value found");
        return;
      }
    }

    updatedSubMenus[index][field] = value;
    setSubMenus(updatedSubMenus);
  };



  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    let item = {
      seq_no: seq_no,
      link: link,
      name: name,
      is_new_tab: is_new_tab,
      sub_menu: subMenus.map(subMenu => ({
        link: subMenu.link,
        name: subMenu.name,
        is_new_tab: subMenu.is_new_tab,
        seq_no: subMenu.seq_no
      }))
    };

    // console.log("item", item);
    //   return

    api
      .post(`/groupAdmin/cms/header`, item, { headers })
      .then((res) => {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Your work has been saved successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/group_admin/settings/header_footer/header")
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">

                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          CMS Settings - Add Header
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            history.push(
                              "/group_admin/settings/header_footer/header"
                            )
                          }
                        >
                          Go back
                        </button>
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            <div className="card card-docs mb-3">
                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmit}>
                                  <div className="rounded border p-10">
                                    <div className="row col-md-12 mb-8">
                                      <div className="col-md-9">
                                        <h3>
                                          Add Header
                                        </h3>
                                      </div>
                                      <div className="col-md-3 text-end">
                                        <div class="input-group">
                                          <span class="input-group-text">Sequence</span>
                                          <input
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Enter Seq number"
                                            required
                                            value={seq_no}
                                            // onChange={(e)=>setseq_no(e.target.value)}
                                            onChange={(e) => {
                                              const newValue = e.target.value.replace(/^0/, "").replace(/\D/g, "") // Remove leading 0
                                              setseq_no(newValue);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="row col-md-12 mt-8 mb-8">
                                      <div className="col-md-3">
                                        <p>Menu Details</p>
                                      </div>
                                      <div className="col-md-4">
                                        <input
                                          type="text"
                                          className="form-control form-control"
                                          placeholder="Enter Menu URL"
                                          required
                                          value={link}
                                          onChange={(e) => setLink(e.target.value)}
                                        />

                                      </div>
                                      <div className="col-md-3">
                                        <input
                                          type="text"
                                          className="form-control form-control"
                                          placeholder="Enter Menu Name"
                                          required
                                          value={name}
                                          onChange={(e) => setname(e.target.value)}
                                        />
                                      </div>
                                      <div className="col-md-2 my-3">
                                        <div className="mb-1">
                                          <input className="form-check-input" type="checkbox"
                                            checked={is_new_tab}
                                            onChange={() => setis_new_tab(!is_new_tab)}
                                          />
                                          <label className="form-check-label mx-2">
                                            <h6>New Tab</h6>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />

                                    {subMenus.map((submenu, index) => (
                                      <div key={index} className="row col-md-12 mt-8">
                                        <div className="col-md-3">
                                          <p>Submenu Details</p>
                                        </div>
                                        <div className="col-md-4">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder="Enter Submenu URL"
                                            value={submenu.link}
                                            required
                                            onChange={(e) => handleSubMenuChange(index, 'link', e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            value={submenu.name}
                                            required
                                            placeholder="Enter Submenu Name"
                                            onChange={(e) => handleSubMenuChange(index, 'name', e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder="Seq Num"
                                            value={submenu.seq_no}
                                            required
                                            onChange={(e) => {
                                              const newValue = e.target.value.replace(/^0/, "").replace(/\D/g, "") // Remove leading 0
                                              handleSubMenuChange(index, 'seq_no', newValue)
                                            }}

                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <div className="row">
                                            <div className="col-md-9 my-3">
                                              <div className="mb-1">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  onChange={(e) => handleSubMenuChange(index, 'is_new_tab', e.target.checked)}
                                                  checked={Boolean(submenu.is_new_tab)}
                                                />
                                                <label className="form-check-label mx-2">
                                                  <h6 >New Tab</h6>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-3 text-end my-2">
                                              <DeleteIcon onClick={() => handleRemoveSubMenu(index)} className="fs-1" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <br />
                                    <div className="row col-md-12">
                                      <div className="col-md-3">

                                      </div>
                                      <div className="col-md-9">
                                        <button
                                          type="button"
                                          onClick={handleAddSubMenu}
                                          className="btn btn-danger">
                                          Add Submenu
                                        </button>
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="col-md-12"> {errorDiv} </div>
                                    <div className="text-center">
                                      <button
                                        type="submit"
                                        id="kt_modal_new_address_submit"
                                        className="btn btn-primary"
                                      >
                                        <span className="indicator-label"> Save </span>
                                      </button>
                                      <button onClick={() =>
                                        history.push(
                                          "/group_admin/settings/header_footer/header"
                                        )
                                      } type="button" className="btn btn-danger mx-2">
                                        Cancel
                                      </button>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminSettingAddHeader);

