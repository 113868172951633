import { USERMANAGMENT } from "../action-types";


const getUserManagmentList = () => ({
  type: USERMANAGMENT.GET_USERMANAGMENT_LIST,
});


const setUserManagmentList = (userlist) => ({
  type: USERMANAGMENT.SET_USERMANAGMENT_LIST,
  userlist,
});


export {
      getUserManagmentList ,setUserManagmentList
};
