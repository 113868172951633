import { EMAILMARKETING } from "../../actions";



const emailMarketingListReducer = (state = [], action) => {
    switch (action.type) {
        case EMAILMARKETING.CLEAR_EMAILMARKETING_LIST:
            return [];
        case EMAILMARKETING.SET_EMAILMARKETING_LIST:
            return [...action?.emailMarketionlist];
        default:
            return state;
    }
}

export default emailMarketingListReducer