import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { MENU, setMenuList } from "../../actions";

function* callMenuList() { 
  try {
      const response = yield call(api.get, "/groupadmin/menu/list" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setMenuList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: MENU.CLEAR_MENU_LIST });
      }
    }
}


export function* watchMenuList() {
  yield takeEvery(MENU.GET_MENU_LIST, callMenuList);
}

