import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import Swal from "sweetalert2";
import headers from "../../../../CommonApi/headers";
import $ from "jquery";
import useDrawer from "../../../../context/DrawerContext";
import CommonPage from "./CommonPage";
import BackButtonSimpal from "./BackButtonSimpal";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import BackButtonForProdctu from "./BackButtonForProdctu";
import DeleteIcon from '@mui/icons-material/Delete';
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { deleteCommonFunction } from "../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useError from "../../../Comman/Error/useError";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";

window.jquery = window.$ = $;

export default function Additionaloptions() {
  // const { productid,catagoryid, catagoryid, branchID } = useParams();
  let location = useLocation();

  const [data, setData] = useState([{ title: "", name: "", additional_products_id: "", product_list: [] }]);
  const [title_for_additional_products, settitle_for_additional_products] = useState("");
  const [description_for_additional_products, setdescription_for_additional_products] = useState("");

  // console.log("location" ,location)

  const productid = location?.state?.productID
  const catagoryid = location?.state?.category_id
  const menuId = location?.state?.menuId
  const branchID = location?.state?.branchID

  useEffect(() => {
      getDetails();
  }, [productid]);


  function getDetails() {
    if(productid)
      api
          .get(`/groupadmin/product/productAdditionalProducts/${productid}`, { headers })
          .then((res) => {
              const tableData = res?.data?.additional_products;
              console.log(res?.data?.additional_products)
              setData(tableData);
              settitle_for_additional_products(res?.data?.title_for_additional_products || "");
              setdescription_for_additional_products(res?.data?.description_for_additional_products || "")
              // console.log("tableData", tableData);
          })
          .catch((err) => {
              if (err.response) {
                  console.log(err?.response?.data);
              }
          });
  }

  let history = useHistory();
  const { error, showError, clearError } = useError();
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const handleInputChange = (mainIndex, field, value, productIndex = null) => {
    const newData = [...data];

    if (productIndex !== null) {
        // Handle nested product list updates
        newData[mainIndex].product_list[productIndex][field] = value;
    } else {
        // Handle top-level data updates
        newData[mainIndex][field] = value;
    }

    setData(newData);
};


  function deleteassociatedCategory(additional_products_id) {
      let item = {
          additional_products_id: additional_products_id
      }

      deleteCommonFunction(`/groupadmin/product/productAdditionalProducts/${productid}`, 
          "Item has been successfully deleted.", 
          () => getDetails(), item);
  }

  //     console.log("id", ID_); // Ensure id is printed correctly
  const [errors, setErrors] = useState([]);

  const handleSubmit = (e, mainIndex) => {
      e.preventDefault();

      const result = data && data?.[mainIndex];
      if (result) {
          // console.log("result" ,result , mainIndex);
          // console.log("data" ,data , mainIndex);
          // return
          // return
          api
              .patch(`/groupadmin/product/productAdditionalProducts/${productid}`, result, { headers })
              .then((res) => {
                  getDetails();
                  showSuccessNotification("Your work has been saved successfully.");
                  const newErrors = [...errors];
          newErrors[mainIndex] = null; // Clear the error for this section
          setErrors(newErrors);
              })
              .catch((err) => {
                  if (err.response) {
                    const newErrors = [...errors];
                    newErrors[mainIndex] = err.response?.data?.error?.message; // Set the error for this section
                    setErrors(newErrors);
                  }
              });
      }
  };

  const [errorMessage ,seterrorMessage] = useState("");

  const handleSubmitForTitle = (e) => {
      e.preventDefault();
      clearError(null)

      const item = {
          title_for_additional_products: title_for_additional_products,
          // description_for_additional_products: description_for_additional_products

      }

      if (description_for_additional_products) {
        item["description_for_additional_products"] = description_for_additional_products;
      }
      // console.log("item" ,item);
      // return
      // return
      api
          .patch(`/groupadmin/product/productTitle/${productid}`, item, { headers })
          .then((res) => {
              getDetails();
              showSuccessNotification("Additional Product title has been saved successfully.");
          })
          .catch((err) => {
              if (err.response) {
                seterrorMessage(err.response?.data?.error?.message);
              }
          });
  };

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Product - Additional Products
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                         <BackButtonSimpal
                                    name={"Go Back"}
                                  />
                      </div>
                    </div>
                  </div>
                
                    <div className="rounded border p-10">
                      <CommonPage />
                      <br />
              
                      <div className="d-flex flex-stack mb-10 mt-10">
                        <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                          <h3>Additional Products Title</h3>
                        </div>
                        <div className="d-flex flex-stack mb-5">
                          <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">

                          </div>
                        </div>
                      </div>
                      <div className="card card-docs mb-10">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <form onSubmit={handleSubmitForTitle}>
                            <div className="rounded border p-10">
                              <React.Fragment>
                                <div className="row mt-8 mb-10">
                                  <div className="col-md-3">
                                    <p>Additional Products Title</p>
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-label required">Title</label>
                                    <input
                                      type="text"
                                      className="form-control form-control"
                                      placeholder="Enter Title"
                                      required
                                      value={title_for_additional_products}
                                      onChange={(e) => settitle_for_additional_products(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-label">Description</label>
                                    <input
                                      type="text"
                                      className="form-control form-control"
                                      placeholder="Enter Description"
                                      value={description_for_additional_products}
                                      onChange={(e) => setdescription_for_additional_products(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <hr />
                                <ErrorMessage error={errorMessage} />

                                <div className="text-center mt-4">
                                  <button type="submit" className="btn btn-primary">
                                    <span
                                      // onClick={()=>setID_(main?.additional_products_id)} 
                                      className="indicator-label"> Save </span>
                                  </button>
                                  <button
                                    onClick={() => history.goBack()}
                                    type="button"
                                    className="btn btn-danger mx-2"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </React.Fragment>


                            </div>
                          </form>
                        </div>
                      </div>



                      <div className="d-flex flex-stack mb-5">
                        <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                          <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                            Popular Menu Items
                          </h3>
                        </div>
                        <div className="d-flex flex-stack mb-5">
                          <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                          {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) &&  (
                            <button
                              onClick={() => 
                                history.push(`/group_admin/menu_management/menu_category/menu_products/product_details_add_additional_products/${productid}/${catagoryid}/${menuId}/${branchID}`)}
                              className="btn btn-primary ps-7"
                            >
                              Add new
                            </button>                                    )}
                          
                          </div>
                        </div>
                      </div>
                      {data?.length > 0 ? (
                        data?.map((main, mainIndex) => (
                          <div key={mainIndex} className="card card-docs mb-10">
                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                              <form onSubmit={(e) => handleSubmit(e, mainIndex)}>
                                <div className="rounded border p-10">
                                  <div className="row mb-8">
                                    <div className="col-md-9">
                                      <h3>{main.title}</h3>
                                    </div>
                                    <div className="col-md-3 text-end">
                                      {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) && (
                                      <DeleteIcon onClick={() => deleteassociatedCategory(main?.additional_products_id)} />
                                      )}
                                    </div>
                                  </div>
                                  <hr />
                                  <React.Fragment>
                                    <div className="row mt-8 mb-4">
                                      <div className="col-md-3">
                                        <p>Popular  Items Details</p>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="form-label required">Title</label>
                                        <input
                                          type="text"
                                          name="title" // Set name to match the field in the object
                                          className="form-control form-control"
                                          placeholder="Enter Title"
                                          value={main.title}
                                          required
                                          onChange={(e) => handleInputChange(mainIndex, e.target.name, e.target.value)}
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <label className="form-label">Description</label>
                                        <input
                                          type="text"
                                          name="description" // Set name to match the field in the object
                                          className="form-control form-control"
                                          placeholder="Enter Description"
                                          value={main.description}
                                          onChange={(e) => handleInputChange(mainIndex, e.target.name, e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    {/* <hr /> */}
                                    <div className="row mt-8">
                                      <div className="col-md-3">
                                        {/* <p>Popular Menu Details</p> */}
                                      </div>
                                      <div className="col-md-3">
                                      <label className="form-label">Category</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Name"
                                          value={main.name}
                                          disabled
                                        />
                                      </div>
                                    </div>

                                    {main?.product_list?.length > 0 &&
                                      main?.product_list.map((product, productIndex) => (
                                        <div key={productIndex} className="row mt-8">
                                          <div className="col-md-3"></div>
                                          <div className="col-md-3 d-flex align-items-center">
                                            <input
                                              className="form-check-input me-4"
                                              type="checkbox"

                                              checked={product.is_active}
                                              onChange={(e) =>
                                                handleInputChange(mainIndex, "is_active", e.target.checked, productIndex)
                                            }
                                            />
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              value={product.name}
                                            disabled
                                              // onChange={(e) =>
                                              //     handleInputChange(mainIndex, "name", e.target.value ,productIndex)
                                              // }
                                            />
                                          </div>
                                          <div className="col-md-3">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Price"
                                              value={product.price}
                                              // disabled
                                              required
                                              onChange={(e) =>
                                                  handleInputChange(mainIndex, "price", e.target.value ,productIndex)
                                              }
                                            />
                                          </div>
                                        </div>
                                      ))}
                                  </React.Fragment>

                                  <br />
                                  <hr />
                                  <ErrorMessage  error={errors[mainIndex]} />

                                  <div className="text-center">
                                    <button type="submit" className="btn btn-primary">
                                      <span 
                                      // onClick={() => setID_(main?.additional_products_id)} 
                                      className="indicator-label"> Save </span>
                                    </button>
                                    <button
                                      onClick={() => history.goBack()}
                                      type="button"
                                      className="btn btn-danger mx-2"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="card card-docs mb-10">
                          <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                            <h5 className="text-center">No data available.</h5>
                          </div></div>
                      )}
                  
                    </div>
                  </main>
                </div>
                
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
