import React from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import { Link } from "react-router-dom";

export default function GroupAdminCMSManagement() {
  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <div className="pt-10">
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                      style={{ float: "left" }}
                    >
                      CMS Management
                    </h1>
                  </div>
                  <div className="py-5" style={{ clear: "both" }}>
                    <div className="card card-docs mb-2">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div style={{ overflowX: "auto" }}>
                          <table
                            id="kt_datatable_example_5"
                            className="table table-striped table-row-bordered gy-5 gs-7"
                          >
                            <thead>
                              <tr
                                className="fw-bold fs-6 text-gray-800"
                                style={{ backgroundColor: "darkgray" }}
                              >
                                <th className="min-w-40px">Sr No.</th>
                                <th className="min-w-80px">CMS Pages</th>
                                <th className="min-w-40px">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Terms and Conditions</td>
                                <td>
                                  {/* <i className="bi bi-eye fs-1 mx-2" /> */}
                                  &nbsp;{" "}
                                  <Link to="/group_admin/cms_management/edit_terms_and_conditions">
                                    <i className="bi bi-pencil-square fs-1" />
                                  </Link>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Privacy Policy</td>
                                <td>
                                  {/* <i className="bi bi-eye fs-1 mx-2" /> */}
                                  &nbsp;{" "}
                                  <Link to="/group_admin/cms_management/edit_privacy_policy">
                                    <i className="bi bi-pencil-square fs-1" />
                                  </Link>
                                  &nbsp;
                                </td>
                              </tr>
                              {/* <tr>
                              <td>3</td>
                              <td>About Us</td>
                              <td>
                                &nbsp;{" "}
                                <Link to="/group_admin/cms_management/edit_about_us">
                                  <i className="bi bi-pencil-square fs-1" />
                                </Link>
                                &nbsp;
                              </td>
                            </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
