import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import Swal from "sweetalert2";

import $ from "jquery";
window.jquery = window.$ = $;

export default function GroupAdminPickupPreparationTime() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  var url2 = pathnamesplits[3];

  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  ///////////////////////// Branch List //////////////////////////

  const [user, setuser] = useState();
  const [branch_id, setbranch_id] = useState([]);
  const [branch_id2, setbranch_id2] = useState([]);

  useEffect(() => {
    groupAdminBranchList();
  }, []);

  function groupAdminBranchList() {
    api.get(`/groupadmin/branch/restaurantwise`, { headers }).then((res) => {
      const options = res.data.data.map((item) => ({
        label: item.address,
        value: item._id,
      }));
      setbranch_id(res.data.data[0]._id);
      setbranch_id2(options[0]);
      setuser(options);
      // groupAdminPickupPreTime();
    });
  }
  ///////////////////////// Branch List //////////////////////////

  ///////////////////////// Get Pickup prep time //////////////////////////

  const [PickupPreTime, setPickupPreTime] = useState({});
  const [errorListDe, setErrorListDl] = useState(null);
  const errorDivLis = errorListDe ? (
    <div className="card shadow-sm">
      <div
        className="card-body"
        style={{ textAlign: "center", background: "#C0C0C0" }}
      >
        <div className="col-md-12">
          <a>
            <b style={{ color: "#000000", fontSize: "20px" }}>
              {" "}
              {errorListDe}{" "}
            </b>
            <span></span>
          </a>
        </div>
      </div>
    </div>
  ) : (
    ""
  );

  useEffect(() => {
    groupAdminPickupPreTime();
  }, [branch_id]);

  function groupAdminPickupPreTime() {
    setErrorListDl(null);

    api
      .get(`/groupadmin/settings/prepration_time/pickup/${branch_id}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setPickupPreTime(tableData);
      })
      .catch((err) => {
        if (err.response) {
          setErrorListDl(err.response.data.error.message);
          if (err.response.data.error.message) {
            setPickupPreTime({});
          }
        }
      });
  }
  ///////////////////////// Get Pickup prep time //////////////////////////

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  let history = useHistory();

  //////////////////////////////   Preparation time Form Submit Poppup //////////////////////////////////

  const [day, setday] = useState("");
  const [start_time, setstart_time] = useState("");
  const [end_time, setend_time] = useState("");
  const [duration, setduration] = useState("");

  function handleSubmit123(event) {
    event.preventDefault();
    setError(null);

    if (branch_id == "") {
      setError("Please Select Branch");
    } else if (day == "") {
      setError("Please Select Day");
    } else if (start_time == "") {
      setError("Please Select Start Time");
    } else if (end_time == "") {
      setError("Please Select End Time");
    } else if (duration == "") {
      setError("Please Select Duration");
    } else {
      if (start_time == end_time) {
        setError("Start Time And End Time it's Not allow to same");
      } else if (start_time > end_time) {
        setError("Enter Start time less then end time");
      } else if (end_time < start_time) {
        setError("Enter End time garter then start time");
      } else {
        let item = {
          branch_id: branch_id,
          day: day,
          start_time: start_time,
          end_time: end_time,
          type: "pickup",
          duration: duration,
        };

        api
          .post(`/groupadmin/settings/prepration_time`, item, { headers })
          .then((res) => {
            setday("");
            setduration("");
            setend_time("");
            setstart_time("");
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Your work has been saved successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            groupAdminPickupPreTime();
            // if (res.data.message === "Sucessfully created.") {
            //     // history.goBack();
            //     window.location.reload(true);
            // }
          })
          .catch((err) => {
            if (err.response) {
              setError(err.response.data.error.message);
            } else if (err.request) {
              setError(err.request);
            } else {
              setError(err.message);
            }
          });
      }
    }
  }

  //////////////////////////////   Preparation time Form Submit Poppup //////////////////////////////////

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="row col-md-12">
                    <div
                      className="col-md-9 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5 d-inline"
                        id="custom-form-control"
                      >
                        Pickup - Preparation Time
                      </h3>
                    </div>

                    <div
                      className="col-md-3 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <Select
                        id="select2"
                        required
                        className="search-line"
                        placeholder="Search..."
                        options={
                          !user == ""
                            ? user.map((sponsor) => ({
                                label: sponsor.label,
                                value: sponsor.value,
                              }))
                            : "No Option"
                        }
                        // onChange={opt => setbranch_id(opt.value)}
                        value={branch_id2}
                        onChange={(opt) => {
                          setbranch_id(opt.value);
                          setbranch_id2(opt);
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "order_time"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/pickup/order_time"
                          >
                            Order Time
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "pickup_preparation_time"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/pickup/pickup_preparation_time"
                          >
                            Preparation Time
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "pickup_additional_charges"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/pickup/pickup_additional_charges"
                          >
                            Additional Charges
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "pickup_setting"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/pickup/pickup_setting"
                          >
                            Settings
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="rounded border p-10">
                                  <div className="d-inline flex-stack mb-5">
                                    <div
                                      className="d-inline justify-content-end"
                                      data-kt-docs-table-toolbar="base"
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary addtimes"
                                        style={{ float: "right" }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_1"
                                        // onClick={haldleNewRow}
                                      >
                                        Add New
                                      </button>
                                    </div>

                                    <div className="mb-10 d-inline">
                                      <h6>
                                        Select the time that staff needs to
                                        prepare orders.
                                      </h6>
                                    </div>
                                    <br />
                                    <br />

                                    <div className="col-md-12">
                                      {" "}
                                      {errorDivLis}{" "}
                                    </div>

                                    {PickupPreTime != ""
                                      ? Object.values(PickupPreTime).map(
                                          (details, index) => {
                                            return (
                                              <>
                                                <div
                                                  className="row col-md-12"
                                                  key={index}
                                                >
                                                  <div className="col-md-3 my-2">
                                                    <label className="form-label">
                                                      <strong
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        Day
                                                      </strong>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control form-control-solid"
                                                      readOnly
                                                      value={
                                                        details.day === "sunday"
                                                          ? "Sunday"
                                                          : details.day ===
                                                            "monday"
                                                          ? "Monday"
                                                          : details.day ===
                                                            "tuesday"
                                                          ? "Tuesday"
                                                          : details.day ===
                                                            "wednesday"
                                                          ? "Wednesday"
                                                          : details.day ===
                                                            "thursday"
                                                          ? "Thursday"
                                                          : details.day ===
                                                            "friday"
                                                          ? "Friday"
                                                          : "Saturday"
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-2 my-2">
                                                    <label className="form-label">
                                                      <strong
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        Start Time
                                                      </strong>
                                                    </label>
                                                    <input
                                                      type="time"
                                                      readOnly
                                                      className="form-control form-control-solid"
                                                      value={details.start_time}
                                                    />
                                                  </div>
                                                  <div className="col-md-2 my-2">
                                                    <label className="form-label">
                                                      <strong
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        End Time
                                                      </strong>
                                                    </label>
                                                    <input
                                                      type="time"
                                                      readOnly
                                                      className="form-control form-control-solid"
                                                      value={details.end_time}
                                                    />
                                                  </div>
                                                  <div className="col-md-3 my-2">
                                                    <label className="form-label">
                                                      <strong
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        Duration
                                                      </strong>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control form-control-solid filterme"
                                                      value={details.duration}
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmit123}>
                <h5 className="modal-title">Select Day</h5>

                <select
                  className="form-control my-3"
                  required
                  onChange={(e) => setday(e.target.value)}
                >
                  <option disabled selected>
                    Week Days
                  </option>
                  <option value="sunday">Sunday</option>
                  <option value="monday">Monday</option>
                  <option value="tuesday">Tuesday</option>
                  <option value="wednesday">Wednesday</option>
                  <option value="thursday">Thursday</option>
                  <option value="friday">Friday</option>
                  <option value="saturday">Saturday</option>
                </select>
                <h5 className="modal-title">Start Time</h5>
                <input
                  type="time"
                  className="form-control form-control-solid my-3"
                  value={start_time}
                  onChange={(e) => setstart_time(e.target.value)}
                />
                <h5 className="modal-title">End Time</h5>
                <input
                  type="time"
                  className="form-control form-control-solid my-3"
                  value={end_time}
                  onChange={(e) => setend_time(e.target.value)}
                />
                <h5 className="modal-title">Duration</h5>
                <input
                  type="text"
                  class="form-control form-control-solid filterme my-3"
                  value={duration}
                  onChange={(e) => setduration(e.target.value)}
                />

                <div className="col-md-12"> {errorDiv} </div>

                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="modal-footer btn btn-primary"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>
        <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
        <script src="/assets/js/custom/widgets.js"></script>
        <script src="/assets/js/custom/apps/chat/chat.js"></script>
        <script src="/assets/js/custom/modals/create-app.js"></script>
        <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
        <script src="/assets/plugins/custom/datatables/datatables.bundle.js"></script>
        <script src="/assets/js/custom/documentation/documentation.js"></script>
        <script src="/assets/js/custom/documentation/general/datatables/advanced.js"></script>
        <script src="/assets/js/custom/authentication/sign-in/general.js"></script>
      </Helmet>
    </React.Fragment>
  );
}
