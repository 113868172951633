import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import api from "../../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../../CommonApi/headers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getLanguageAllList, resLanguageGet } from "../../../../../Store";
import { connect } from "react-redux";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";

window.jquery = window.$ = $;

function GroupAdminLanguage({getLanguageAllList ,languagesAllList , resLanguageGet , languagesResList}) {
  const userPermissionType_ = userPermissionType === "groupadmin"

  const allLanguage = languagesAllList
  const [selectedLanguages, setSelectedLanguages] = useState(languagesResList);
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const history = useHistory();

  useEffect(() => {
    getLanguageAllList();
    resLanguageGet();
  }, []);



  const handleCheckboxChange = (_id) => {
    const isSelected = selectedLanguages?.includes(_id);
    if (isSelected) {
      setSelectedLanguages(selectedLanguages?.filter((langId) => langId !== _id));
    } else {
      setSelectedLanguages([...selectedLanguages, _id]);
    }
  };

  const handleSave = () => {
  // Get the complete language objects for the selected languages
  const selectedLanguageObjects = allLanguage?.filter((lang) =>
    selectedLanguages?.includes(lang?._id)
  );

  const filteredLanguages = allLanguage?.filter(language => language?.code === "EN");
  
  const hasEnglish = selectedLanguageObjects?.some((lang) => lang.code === "EN");
      if (!hasEnglish) {
        selectedLanguageObjects?.unshift(...filteredLanguages);
      }
  let item = {
    lang : selectedLanguageObjects
  }

  // console.log("item" ,item);
  // return

  api
      .post(`/groupadmin/lang`, item, { headers })
      .then((res) => {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Language has been added succesfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        getLanguageAllList();
        resLanguageGet();
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });

};

const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="row col-md-12">
                    <div className="col-md-9" data-kt-docs-table-toolbar="base">
                      <h3
                        className="anchor fw-bolder mb-5 d-inline"
                        id="custom-form-control"
                      >
                        Language Settings 
                      </h3>
                    </div>
                    <div className="col-md-3" data-kt-docs-table-toolbar="base">
                    </div>
                  </div>
                
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="py-5">
                            <div
                              id="kt_content_container"
                              className="container-xxl"
                            >
                              {/* {allLanguage?.length === 0 ? 
                              <div className="row text-center">
                                <div className="col-md-12">
                                  <h5>
                                    No any data available
                                  </h5>
                                </div>
                              </div>
                              : */}
                              <div className="row g-5 g-xl-8 justify-content-center">
                                <div className="row  col-md-12">
                                {allLanguage?.length > 0 && allLanguage?.map((language, index) => {
                                      // Check if the _id of the language exists in array1
                                      const isChecked = selectedLanguages?.some(item => item?._id === language?._id);

                                      return (
                                        <React.Fragment key={index}>
                                          <div className="col-md-4 my-4">
                                            {language?.code === "EN" ?
                                            <input
                                              style={{ marginTop: 7 }}
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={true}
                                              readOnly={true}
                                            /> :
                                            <input
                                              style={{ marginTop: 7 }}
                                              className="form-check-input"
                                              type="checkbox"
                                              onChange={() => handleCheckboxChange(language?._id)}
                                              checked={isChecked || selectedLanguages.includes(language?._id)}
                                            /> }
                                            <img
                                              style={{ height: 32, width: 42 }}
                                              loading="lazy"
                                              src={language?.image}
                                              alt={`${language?.name} flag`}
                                              className="mx-4"
                                            />
                                            <span style={{ fontSize: 20, fontWeight: 500 }}>
                                              {language?.name}
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                </div> 
                                <hr/>
                                <div className="col-md-12"> {errorDiv} </div>
                                <div className="text-center">

                                {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.some(permission =>
                                          permission?.key === "language_settings" &&
                                          permission?.access?.includes("edit")
                                        )) && (
                                  <button
                                    type="submit"
                                    id="kt_modal_new_address_submit"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                  >
                                    <span className="indicator-label"> Save </span>
                                  </button>)}
                                  <button 
                                  onClick={()=>history.push("/group_admin/settings")} 
                                  type="button" className="btn btn-danger mx-2">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                              {/* } */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  languagesAllList : state.languagesAllList,
  languagesResList : state.languagesResList
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguageAllList: () => dispatch(getLanguageAllList()),
    resLanguageGet: () => dispatch(resLanguageGet()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminLanguage);
