import { Popover, List, ListItem, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./ColumPopover.css";

export default function ColumPopover({anchorEl, handleCloseColum, onColumnDataChange , Type ,BranchId ,currencyLabel}) {
  const [columnData, setColumnData] = useState({});
  const [Array_ ,setArray] = useState("")

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url23 = pathnamesplits[2];

  // console.log("BranchId" ,BranchId , currencyLabel)

  const columns = [
    {
      header:  'Invoice Code',
    },
    {
      header: 'Branch',
    },
    {
      header: 'Order Numbers',
    },
    {
      header: 'Date & Time',
    },
    {
      header: 'Status',
    },
    {
      header: 'Customer',
    },
    {
      header: 'Table No',
    },
    {
      header: BranchId === "" ? "Price" : `Price (${currencyLabel})`
    },
  ];


  const columnsPickupAndDelivery = [
 {
   header: 'Invoice Code',
 },
 {
   header: 'Branch',
 },
 {
   header: 'Date & Time',
 },
 {
   header: 'Status',
 },
 {
   header: 'Customer',
 },
 {
   header: 'Type',
 },
 {
   header:  BranchId === "" ? "Price" : `Price (${currencyLabel})`,
 },
  ]   

  useEffect(() => {
    const storedData = sessionStorage.getItem(Type === "dinein" ? `${url23}_columnData` : `${url23}_${Type}_columnData`);
    // if (storedData === null && BranchId) {
      // If sessionStorage does not have the data, initialize it
      const initialData = Object.fromEntries(columns.map(column => [column.header, true]));
      const initialData1 = Object.fromEntries(columnsPickupAndDelivery.map(column => [column.header, true]));
      if(Type === "dinein"){
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialData));
        setColumnData(initialData);
      }else{
        sessionStorage.setItem(`${url23}_${Type}_columnData`, JSON.stringify(initialData1));
        setColumnData(initialData1);
        // console.log("pickup")
      } 
    // } 
    // else {
    //   const parsedData = JSON.parse(storedData);
    //   setColumnData(parsedData);
    // }
    setArray(Type === "dinein" ? columns : columnsPickupAndDelivery)
    onColumnDataChange()
  }, [Type ,BranchId]);

  const handleCheckboxClick = (columnName, isChecked) => {
    const updatedColumnData = {
      ...columnData,
      [columnName]: isChecked
    };
    if(Type === "dinein"){
      sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData));
    }else{
      sessionStorage.setItem(`${url23}_${Type}_columnData`, JSON.stringify(updatedColumnData));
    }
    setColumnData(updatedColumnData);
    onColumnDataChange(updatedColumnData);
  };

  const isColumnChecked = (columnName) => {
    return columnData[columnName] === true;
  };

 

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseColum}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius  : 3,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 35,
            height: 35,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 85,
            width: 13,
            height: 13,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Typography variant="h6" sx={{ px: 2, py: 1 }}>
        Display Columns
      </Typography>
      <List sx={{ px: 1 }}>
        {Array_ !== "" && Array_?.map((column, index) => (
          <ListItem sx={{ py: 0 }} key={index} 
          style={{display  : column?.header === "Invoice Code" 
          ? "none" : column?.header === "Order No" ? "none" : ""}}>
            <FormControlLabel
              control={
                <Checkbox 
                  className="checkbox" 
                  checked={isColumnChecked(column.header)}
                  onClick={(e) => handleCheckboxClick(column.header, e.target.checked)} />
              }
              label={
                <Typography  
                 className="checkboxname">{column.header}</Typography> 
              }
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}
