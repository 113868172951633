import React from 'react'
import { Link } from "react-router-dom";


export default function CommonDinein() {
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url2 = pathnamesplits[4];
    
  return (
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          className={
            url2 === "restaurant_time_management"
              ? "nav-link active"
              : "nav-link"
          }
          to="/group_admin/settings/dinein/restaurant_time_management"
        >
          Restaurant time management
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={
            url2 === "dinein_additional_charges"
              ? "nav-link active"
              : "nav-link"
          }
          to="/group_admin/settings/dinein/dinein_additional_charges"
        >
          Additional Charges
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={
            url2 === "dinein_setting"
              ? "nav-link active"
              : "nav-link"
          }
          to="/group_admin/settings/dinein/dinein_setting"
        >
          Settings
        </Link>
      </li>
    </ul>
  )
}
