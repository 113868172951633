import { ORDER } from "../action-types";


const getOrderDetails = (id , Type ,branchId) => 
  ({
  type: ORDER.GET_ORDER_DETAILS,
  id,
  Type,
  branchId
});


const setOrderDetails = (orderdetails) => ({
  type: ORDER.SET_ORDER_DETAILS,
  orderdetails,
});


export {
      getOrderDetails , setOrderDetails
};
