import { PRINTER } from "../action-types";


const getPrinterList = () => ({
  type: PRINTER.GET_PRINTER_LIST,
});


const setPrinterList = (printerlist) => ({
  type: PRINTER.SET_PRINTER_LIST,
  printerlist,
});


const getPrinterCategoryList = () => ({
  type: PRINTER.GET_PRINTERCATEGORY_LIST,
});


const setPrinterCategoryList = (printerCategorylist) => ({
  type: PRINTER.SET_PRINTERCATEGORY_LIST,
  printerCategorylist,
});


export {
      getPrinterList ,setPrinterList ,getPrinterCategoryList ,setPrinterCategoryList
};
