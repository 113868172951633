import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import $ from "jquery";
import useDrawer from "../../../../context/DrawerContext";
import Select from "react-select";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { SubmitformButton1 } from "../../../Comman/Error/SubmitformButton";
import { getAddonList } from "../../../../Store";
import { connect } from "react-redux";
import useError from "../../../Comman/Error/useError";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { submitForm } from "../../../Comman/AddCommonFun/AddCommonFunction";
import DeleteIcon from '@mui/icons-material/Delete';


window.jquery = window.$ = $;

function AddOnPropertyDaynamic({ getAddonList, addonList }) {
      let history = useHistory();
      let location = useLocation();
      let { productID } = useParams();

      const [title, setTitle] = useState("");
      const [AddonID, setAddonID] = useState("");
      const [restaurant_id, setrestaurant_id] = useState("")
      const [isRequired, setIsRequired] = useState(false);
      const [multipleChoices, setMultipleChoices] = useState(false);
      const [supplementaryItems, setSupplementaryItems] = useState([
            { name: "", price: "", is_active: true },
      ]);
      const [data, setForm] = useState([]);
      const { error, showError, clearError } = useError();
      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      const handleAddSubMenu = () => {
            setSupplementaryItems([
                  ...supplementaryItems,
                  { name: "", price: "", is_active: true }
            ]);
      };

      const handleRemoveSubMenu = (index) => {
            setSupplementaryItems(supplementaryItems.filter((_, i) => i !== index));
      };

      useEffect(() => {
            applyFilterMeHandlers(); // Apply the jQuery handlers
            groupAdminAddOnListById();
            getAddonList();
      }, [location, getAddonList]);

      function groupAdminAddOnListById() {
            api
                  .get(`/groupadmin/product/customProperty/${location.state.productID}`, { headers })
                  .then((res) => {
                        const tableData = res.data.data?.custom_property;
                        setForm(tableData);
                  })
                  .catch((err) => {
                        if (err.response) {
                              console.log(err.response?.data?.error?.message);
                        }
                  });
      }

      const addonOptions = addonList.map((addon) => ({
            label: addon.title,
            value: addon._id,
            items: addon.items,
            restaurant_id: addon.restaurant_id
      }));

      const handleCategoryChange = (selectedOption) => {
            setAddonID(selectedOption.value);
            setTitle(selectedOption.label);
            setrestaurant_id(selectedOption.restaurant_id)
            const updatedItems = selectedOption.items.map(item => ({
                  ...item,
                  is_active: true,
            }));
            clearError(null);
            setSupplementaryItems(updatedItems);
      };

      const handleInputChange = (index, field, value) => {
            const newItems = [...supplementaryItems];
            newItems[index][field] = value;
            setSupplementaryItems(newItems);
      };

      function handleSubmit(event) {
            event.preventDefault();
            showError(null);

            const item = {
                  "options": AddonID ? [
                        {
                              title: title,
                              items: supplementaryItems,
                              is_required: isRequired,
                              multiple_choices: multipleChoices,
                              _id: AddonID,
                              restaurant_id: restaurant_id
                        }
                  ] : [
                        {
                              title: title,
                              items: supplementaryItems,
                              is_required: isRequired,
                              multiple_choices: multipleChoices,
                              restaurant_id: restaurant_id
                        }
                  ]
            };

            submitForm(`/groupadmin/product/options/${productID}`, item, history, showError, clearError,
                  "Your work has been saved successfully");
            console.log("add", item);
      }

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-5"
                                                                  id="vertical-horizontal-scroll"
                                                            >
                                                                  Menu Management
                                                            </h1>
                                                            <hr />
                                                            <div className="d-flex flex-stack mb-5">
                                                                  <div
                                                                        className="d-flex justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Options - Add
                                                                        </h3>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div
                                                                              className="d-flex justify-content-end mx-2"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <button
                                                                                    onClick={() => history.goBack()}
                                                                                    className="btn btn-primary ps-7"
                                                                              >
                                                                                    Go Back
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="rounded border p-10">
                                                                  <br />

                                                                  <div className="card card-docs mb-3">
                                                                        <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                                              <form onSubmit={handleSubmit}>
                                                                                    <div className="rounded border p-10">
                                                                                          <div className="row mb-8">
                                                                                                <div className="col-md-3">
                                                                                                <label className="form-label">Options</label>
                                                                                                      <Select
                                                                                                            placeholder="Select options..."
                                                                                                            options={addonOptions}
                                                                                                            onChange={handleCategoryChange}
                                                                                                      />
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr />
                                                                                          <div className="row mt-8 mb-8">
                                                                                                <div className="col-md-3">
                                                                                                      <p>Options Title</p>
                                                                                                </div>

                                                                                                <div className="col-md-3">
                                                                                                      <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            placeholder="Enter Title"
                                                                                                            value={title}
                                                                                                            onChange={(e) => setTitle(e.target.value)}
                                                                                                            required
                                                                                                      />
                                                                                                </div>
                                                                                                

                                                                                          <div className="col-md-4 mt-4">
                                                                                                      <input
                                                                                                            className="form-check-input "
                                                                                                            type="checkbox"
                                                                                                            id="checkbox1"
                                                                                                            checked={isRequired}
                                                                                                            onChange={(e) => setIsRequired(e.target.checked)}
                                                                                                      />
                                                                                                      <label className="form-check-label mx-2 me-4" htmlFor="checkbox1">
                                                                                                            Required
                                                                                                      </label>
                                                                                                      <input
                                                                                                            className="form-check-input mx-2"
                                                                                                            type="checkbox"
                                                                                                            id="checkbox2"
                                                                                                            checked={multipleChoices}
                                                                                                            onChange={(e) => setMultipleChoices(e.target.checked)}
                                                                                                      />
                                                                                                      <label className="form-check-label" htmlFor="checkbox2">
                                                                                                            Multiple Choices
                                                                                                      </label>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr />
                                                                                          <div className="row mt-8">
                                                                                                <div className="col-md-3">
                                                                                                      <p>Options</p>
                                                                                                </div>
                                                                                                <div className="col-md-9">
                                                                                                      {supplementaryItems.map((item, index) => (
                                                                                                            <div className="row" key={index}>
                                                                                                                  <div className="col-md-6 mb-5 d-flex align-items-center">
                                                                                                                        <input
                                                                                                                              className="form-check-input me-4"
                                                                                                                              type="checkbox"
                                                                                                                              checked={item.is_active}
                                                                                                                              onChange={(e) => handleInputChange(index, "is_active", e.target.checked)}
                                                                                                                        />
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              className="form-control"
                                                                                                                              placeholder="Enter Name"
                                                                                                                              value={item.name}
                                                                                                                              onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                                                                                                              required
                                                                                                                        />
                                                                                                                  </div>
                                                                                                                  <div className="col-md-3 mb-5 ">
                                                                                                                        <input
                                                                                                                              type="number"
                                                                                                                              className="form-control filterme"
                                                                                                                              placeholder="Enter Price"
                                                                                                                              value={item.price}
                                                                                                                              onChange={(e) => handleInputChange(index, "price", e.target.value)}
                                                                                                                              required
                                                                                                                        />
                                                                                                                  </div>
                                                                                                                  <div className="col-md-1 mb-5  d-flex align-items-center">
                                                                                                                        {!item?._id &&
                                                                                                                              <DeleteIcon
                                                                                                                                    onClick={() => handleRemoveSubMenu(index)}
                                                                                                                                    className="fs-1"
                                                                                                                              />}
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      ))}
                                                                                                </div>
                                                                                                <div className="row col-md-12 mt-5">
                                                                                                      <div className="col-md-3"></div>
                                                                                                      <div className="col-md-9">
                                                                                                            <button
                                                                                                                  style={{ fontWeight: 400 }}
                                                                                                                  type="button"
                                                                                                                  onClick={handleAddSubMenu}
                                                                                                                  className="btn btn-danger"
                                                                                                            >
                                                                                                                  Add More
                                                                                                            </button>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <br />
                                                                                          <hr />
                                                                                          <ErrorMessage error={error} />
                                                                                          <SubmitformButton1
                                                                                                handleSubmit={handleSubmit}
                                                                                                onCancel={() => history.goBack()}
                                                                                          />
                                                                                    </div>
                                                                              </form>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      addonList: state.addonList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getAddonList: () => dispatch(getAddonList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnPropertyDaynamic);
