import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import {CMS, setColorDetails, setFooterList, setHeaderList } from "../../actions";

function* callHeaderList() { 
  try {
      const response = yield call(api.get, "/groupAdmin/cms/header" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setHeaderList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type:CMS.CLEAR_HEADER_LIST });
      }
    }
}


function* callFooterList() { 
      try {
          const response = yield call(api.get, "/groupAdmin/cms/footer" , { headers: headers });
          const  tableData = response?.data?.data;
          yield put(setFooterList(tableData?.length > 0 ? tableData : []));
        } catch (error) {
          if (error.response) {
            yield put({ type: CMS.CLEAR_FOOTER_LIST });
          }
        }
    }


function* callColorDetails() { 
      try {
          const response = yield call(api.get, "/groupadmin/settings/setup/getDetails" , { headers: headers });
          const  tableData = response?.data?.data;
          yield put(setColorDetails(tableData));
        } catch (error) {
          if (error.response) {
            yield put({ type: CMS.CLEAR_COLOR });
          }
        }
    }

export function* watchHeaderList() {
  yield takeEvery(CMS.GET_HEADER_LIST, callHeaderList);
}


export function* watchFooterList() {
      yield takeEvery(CMS.GET_FOOTER_LIST, callFooterList);
}

export function* watchColorDetails() {
      yield takeEvery(CMS.GET_COLOR, callColorDetails);
}

