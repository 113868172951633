import moment from "moment";

export const generateUrl = (Type, branch_id) => {
      const baseUrl = `/groupAdmin/dashboard/getSalesChart`;
      const queryParams = new URLSearchParams();
  
      if (Type) {
          if (Type.startDate) {
              queryParams.append('type', 'FormTo');

              const adjustedEndDate = moment(Type.endDate).add(1, "days").format('YYYY-MM-DD');
              queryParams.append('start_date', Type.startDate);
              queryParams.append('end_date', adjustedEndDate);

            //   queryParams.append('start_date', Type.startDate);
            //   queryParams.append('end_date', Type.endDate);
          } 
        //   if(Type.startDate === Type.endDate) {
        //     // If startDate is equal to endDate, add one day to endDate
        //     const adjustedEndDate = moment(Type.endDate).add(1, "days").format('YYYY-MM-DD');
        //     queryParams.append('start_date', Type.startDate);
        //     queryParams.append('end_date', adjustedEndDate);
        //   }
          else {
              queryParams.append('type', Type);
          }
      }
  
      if (branch_id && branch_id !== "All") {
          queryParams.append('branch_id', branch_id);
      }
  
      return `${baseUrl}?${queryParams.toString()}`;
  };

  export const generateUrl1 = (branch_id) => {
      const baseUrl = `/groupAdmin/dashboard/revenueChart`;
      const queryParams = new URLSearchParams();
  
    //   if (Type) {
    //       if (Type?.startDate) {
    //           queryParams.append('type', 'FormTo');
    //           queryParams.append('start_date', Type.startDate);
    //           queryParams.append('end_date', Type.endDate);
    //       } else {
    //           queryParams.append('type', Type);
    //       }
    //   }
  
      if (branch_id && branch_id !== "All") {
          queryParams.append('branch_id', branch_id);
      }
  
      return `${baseUrl}?${queryParams.toString()}`;
  };
  