
export const SubmitformButton = ({ handleSubmit, onCancel }) => (
  <div className="modal-footer flex-center">
    <button type="submit" id="kt_modal_new_address_submit" className="btn btn-primary" 
    // onClick={handleSubmit}
    >
      Save
    </button>
    <button type="button" className="btn btn-danger m-2" onClick={onCancel}>
      Cancel
    </button>
  </div>
);



export const SubmitformButton1 = ({ handleSubmit, onCancel }) => (
  <div className="flex-center d-flex">
    <button type="submit" id="kt_modal_new_address_submit" className="btn btn-primary" 
    // onClick={handleSubmit}
    >
      Save
    </button>
    <button type="button" className="btn btn-danger m-2" onClick={onCancel}>
      Cancel
    </button>
  </div>
);
 
