import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
    Circle
} from "react-google-maps";
// import Map from 'react-map-gl';
import * as turf from '@turf/turf';



const Map = props => {

    // console.log(props);
    // console.log(props.longitude);
    // useEffect(() => {
    // }, [props.latitude, props.longitude]);
    const [viewState, setViewState] = React.useState({
        longitude: props.longitude,
        latitude: props.latitude,
        zoom: 3.5
    });


    // const GEOFENCE = turf.circle([viewState.latitude, viewState.longitude], 5, { units: 'miles' });


    const onMove = React.useCallback(({ viewState }) => {
        const newCenter = [viewState.longitude, viewState.latitude];
        // Only update the view state if the center is inside the geofence
        if (turf.booleanPointInPolygon(newCenter)) {
            setViewState(newCenter);
        }
    }, [])

    return (
        <GoogleMap
            defaultCenter={{ lat: 21.1702, lng: 72.8311 }}
            defaultZoom={13}
            center={{ lat: parseFloat(props.center.lat), lng: parseFloat(props.center.lng) }}
        >
            <Marker
                position={{
                    lat: parseFloat(props.center.lat),
                    lng: parseFloat(props.center.lng)
                }}
            />
            {props.places.map(place => {
                {/* console.log("sdfsetwewt", place); */ }
                return (
                    <Fragment key={place.id}>
                        <Marker
                            onMove={onMove}
                            position={{
                                lat: parseFloat(place.latitude),
                                lng: parseFloat(place.longitude)
                            }}
                        />
                        {place.circle && <Circle
                            {...viewState}
                            onMove={onMove}
                            defaultCenter={{
                                lat: parseFloat(place.latitude),
                                lng: parseFloat(place.longitude)
                            }}

                            radius={parseFloat(place.circle.radius)}
                            options={place.circle.options}
                        />}
                    </Fragment>
                );
            })}
        </GoogleMap>
    );
}

export default withScriptjs(withGoogleMap(Map));