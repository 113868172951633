import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "../../Component/Setting/UserManagment/UserManagmentCommon";
import useDrawer from "../../../../context/DrawerContext";
import { formatKey } from "./styles";



export default function GroupAdminViewRoleManagmnet() {

      const id = useParams();
      let history = useHistory();
      const [user, setuser] = useState("")

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


      const style = {
            color: "#344054",
            fontSize: "16px",
            fontWeight  :500
      }

      const styleHeader =  {
            color: "#181C31",
            letterspacing: "0.2px",
            lineheight: "27.3px"
        }


      useEffect(() => {
            UserDetailsGet();
      }, [id]);

      function UserDetailsGet() {
            api.get(`/groupadmin/role/${id?.id}`, { headers }).then((res) => {
                  const tableData = res.data.data;
                  setuser(tableData);
                  // console.log("sss", tableData)
            });
      }

      const capitalizeFirstLetter = (string) => {
            return string?.charAt(0)?.toUpperCase() + string.slice(1).toLowerCase();
      };

      const formatString = (str) => {
            return str
                  ?.replace(/_/g, ' ') // Replace underscores with spaces
                  ?.split(' ') // Split the string into words
                  ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()) // Capitalize the first letter of each word
                  ?.join(' '); // Join the words back into a single string
      };




      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <div className="row col-md-12">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h1
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              User Management
                                                                        </h1>
                                                                  </div>

                                                                  <div
                                                                        className="col-md-3 text-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <button
                                                                              className="btn btn-primary"
                                                                              onClick={() =>
                                                                                    history.push(
                                                                                          "/group_admin/settings/roles"
                                                                                    )
                                                                              }
                                                                        >
                                                                              Go Back
                                                                        </button>
                                                                  </div>
                                                            </div>

                                                            <div className="py-5">
                                                                  <div className="rounded border p-10">
                                                                        <UserManagmentCommon />
                                                                        <div className="row  py-5">
                                                                              <div
                                                                                    className="col-md-9"
                                                                              >
                                                                                    <h3 style={styleHeader}
                                                                                          className="anchor fw-bolder mb-5 "
                                                                                    >
                                                                                          Role Details
                                                                                    </h3>
                                                                              </div>
                                                                              <div
                                                                                    className="col-md-3 text-end"
                                                                              >
                                                                                    <button
                                                                                          className="btn btn-primary mx-2"
                                                                                          onClick={() =>
                                                                                                history.push(
                                                                                                      `/group_admin/settings/roles/edit_roles/${id?.id}`
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          Edit
                                                                                    </button>
                                                                              </div>
                                                                        </div>

                                                                        {(user?.permissions?.length > 0 || user?.reports?.length > 0 || user?.settings?.length > 0) &&
                                                                              <div className="card card-docs mb-2">
                                                                                    <div className="card-body fs-6 pt-5 px-5 px-lg-15 text-gray-700">
                                                                                          <div className="row col-md-12">
                                                                                                <div className="col-md-3 my-9">
                                                                                                      <h5 style={{color :"#344054" , fontWeight : 500 ,fontSize : "14px"}}>Role Name</h5>
                                                                                                </div>
                                                                                                <div style={style} className="col-md-9 my-8">
                                                                                                      <h5>{user?.name}</h5>
                                                                                                </div>
                                                                                                <hr  style={{opacity : 0.1}}/>
                                                                                                {user?.permissions?.length > 0 &&
                                                                                                      user?.permissions?.slice(1)?.filter(main => main?.access?.length > 0) // Filter items where access length is greater than 0
                                                                                                            ?.map((main, index) => (
                                                                                                                  <React.Fragment key={index}>
                                                                                                                         
                                                                                                                        <div className="col-md-3 my-9">
                                                                                                                              <h5 style={{color :"#344054" , fontWeight : 500 ,fontSize : "14px"}} >{formatKey(main?.key)}</h5>
                                                                                                                        </div>
                                                                                                                        <div style={style} className="col-md-9 my-8">
                                                                                                                              {main?.access
                                                                                                                                    .map(access => capitalizeFirstLetter(access)) // Capitalize the first letter of each access
                                                                                                                                    .join(', ')} {/* Join the formatted permissions as a comma-separated string */}
                                                                                                                        </div>
                                                                                                                        <hr style={{opacity : 0.1}}/>
                                                                                                                  </React.Fragment>
                                                                                                            ))}

                                                                                                {user?.reports?.filter(main => main.access.length > 0) // Filter items where access length is greater than 0
                                                                                                      ?.map((main, index) => (
                                                                                                            <React.Fragment key={index}>
                                                                                                                  {index === 0 && <h5>Report</h5>}
                                                                                                                  <div className="col-md-3 my-3">
                                                                                                                        <h5 style={{ marginLeft: "10px" ,color :"#344054" , fontWeight : 500 ,fontSize : "14px" }}>{formatString(main?.key)}</h5>
                                                                                                                  </div>
                                                                                                                  <div style={style} className="col-md-9 my-2">
                                                                                                                        {main?.access
                                                                                                                              .map((main, index) => (
                                                                                                                                    <div key={index}>
                                                                                                                                          {main === "view" ? "View" : ""}
                                                                                                                                    </div>
                                                                                                                              ))
                                                                                                                        }
                                                                                                                  </div>
                                                                                                            </React.Fragment>
                                                                                                      ))}
                                                                                                      <hr  style={{opacity : 0.1}}/> 

                                                                                                {user?.settings?.filter(main => main.access.length > 0) // Filter items where access length is greater than 0
                                                                                                      ?.map((main, index) => (
                                                                                                            <React.Fragment key={index}>
                                                                                                                  {index === 0 && <h5>Setting</h5>}
                                                                                                                  <div className="col-md-3 my-3">
                                                                                                                        <h5 style={{ marginLeft: "10px" ,color :"#344054" , fontWeight : 500 ,fontSize : "14px" }}>{formatString(main?.key)}</h5>
                                                                                                                  </div>
                                                                                                                  <div style={style} className="col-md-9 my-2">
                                                                                                                        {main?.access
                                                                                                                              .map(access => capitalizeFirstLetter(access)) // Capitalize the first letter of each access
                                                                                                                              .join(', ')} {/* Join the formatted permissions as a comma-separated string */}
                                                                                                                  </div>
                                                                                                            </React.Fragment>
                                                                                                      ))}
                                                                                                      {/* <hr style={{opacity : 0.1}} /> */}
                                                                                               

                                                                                          </div>
                                                                                    </div>
                                                                              </div> 

                                                                              // <div className="card shadow-sm">
                                                                              //       <div className="card-body" style={{ textAlign: "center" }}>
                                                                              //             <div className="col-md-12">
                                                                              //                   <a>
                                                                              //                         <b style={{ color: "rgb(0, 0, 0)", fontSize: 20 }}> No Data Found. </b>
                                                                              //                         <span />
                                                                              //                   </a>
                                                                              //             </div>
                                                                              //       </div>
                                                                              // </div>
                                                                              
                                                                              }
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}



