import { LANGUAGEALL ,LANGUAGERES } from "../../actions";

const languagesAllListReducer = (state = [], action) => {
    switch (action.type) {
        case LANGUAGEALL.CLEAR_LANGUAGEALL_LIST:
            return [];
        case LANGUAGEALL.SET_LANGUAGEALL_LIST:
            return [...action?.languageAlllist];
        default:
            return state;
    }
}

const languagesResListReducer = (state = [], action) => {
    switch (action.type) {
        case LANGUAGERES.CLEAR_LANGUAGERES_LIST:
            return [];
        case LANGUAGERES.SET_LANGUAGERES_LIST:
            return [...action?.languageReslist];
        default:
            return state;
    }
}

export { languagesAllListReducer , languagesResListReducer }