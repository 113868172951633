import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import headers from "../../../../../CommonApi/headers";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import { getBranchList } from "../../../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";
import {
  exportToXlsxForDetailReport,
  exportXlsxSummrayForReport,
  exportPDFforDetailReport,
  exportPDFforSummrayReport
} from "./ReportCommon/ReportCommon";
import useDrawer from "../../../../../context/DrawerContext";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";

function GroupAdminGroupReport({ getBranchList, BranchList_ }) {
  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });


  const filteredOptions = branchfilterAndMapData(BranchList_);
  useEffect(() => {
    getBranchList();
  }, [getBranchList]);

  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState([]);
  const [Order_type, setOrder_type] = useState("");
  const [Order_type2, setOrder_type2] = useState([]);
  const [Report_type, setReport_type] = useState("");


  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);

  console.log("currencyLabel" ,currencyLabel ,branch_id2)


  useEffect(() => {
    validDate();
  }, []);

  function validDate() {
    var today = new Date().toISOString().split("T")[0];
    document.getElementsByName("date")[0].setAttribute("max", today);
    document.getElementsByName("date1")[0].setAttribute("max", today);
  }

  const [loader, setloader] = useState(false);
  const [FilterData, setFilterData] = useState([]);
  const [CSVDATA, setCSVDATA] = useState([]);

  const [ErrorNoData, setErrorNoData] = useState(false);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    setloader(true);

    if (start_date === "") {
      setError("Please select start date");
      setloader(false);
      return;
    }
    if (branch_id === "") {
      setError("Please select branch.");
      setloader(false);
      return;
    }
    if (Report_type === "") {
      setError("Please select Report type.");
      setloader(false);
      return;
    }

    let url = `/groupadmin/report`;

    if (Report_type.value === "report by summary") {
      url += "/summary";
    }

    url += `?start_date=${moment(start_date).format("YYYY-MM-DD")}`;

    if (branch_id !== "All") {
      url += `&branch_id=${branch_id}`;
    }

    if (end_date !== "") {
      const result2 = moment(end_date).add(1, "days").toDate();
      if (Date.parse(result2) <= Date.parse(start_date)) {
        setError("End date should be greater than Start date");
        setloader(false);
        return;
      }
      url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
    }

    if (Order_type !== "All") {
      url += `&order_type=${Order_type}`;
    }

    api.get(url, { headers })
      .then((res) => {
        setloader(false);
        const branchesWithOrders = res?.data?.data?.filter(branch => branch?.order_list?.length > 0);
        console.log("Res", branchesWithOrders)

        // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
        setCSVDATA(branchesWithOrders);
        if (branchesWithOrders?.length === 0) {
          setError("No order data found.");
        }
      })
      .catch((err) => {
        setloader(false);
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7 p-0">
                  <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>
                    <div className="d-flex flex-stack mb-2">
                      <div
                        className="d-flex justify-content-end p-0"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h2
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          General Reports
                        </h2>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.push("/group_admin/report")}
                            className="btn btn-primary ps-7"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="py-2">
                      <form onSubmit={handleSubmit}>
                        <div className="card card-docs">
                          <div
                            className={
                              isMobile
                                ? "card-body fs-6 py-15 px-3 py-lg-15  text-gray-700"
                                : "card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700"
                            }
                          >
                            <div
                              className={isMobile ? "" : "rounded border p-10"}
                            >
                              <div className="row">
                                <div className="col-md-2">
                                  <label className="required form-label">
                                    Start Date
                                  </label>
                                  <input
                                    type="date"
                                    name="date"
                                    id="date"
                                    className="form-control"
                                    value={start_date}
                                    onChange={(e) => {
                                      setstart_date(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label className="form-label">End Date</label>
                                  <input
                                    type="date"
                                    name="date1"
                                    id="date1"
                                    className="form-control"
                                    value={end_date}
                                    onChange={(e) => {
                                      setend_date(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label className="form-label">
                                    Select Order type
                                  </label>
                                  <Select
                                    styles={selectStylesReport}
                                    className="search-line"
                                    placeholder="Search..."
                                    options={[
                                      {
                                        label: "All",
                                        value: "All",
                                      },
                                      {
                                        label: "Delivery",
                                        value: "delivery",
                                      },
                                      {
                                        label: "Pickup",
                                        value: "pickup",
                                      },
                                      {
                                        label: "DineIn",
                                        value: "dineIn",
                                      },
                                    ]}
                                    value={Order_type2}
                                    onChange={(opt) => {
                                      setOrder_type(opt.value);
                                      setOrder_type2(opt);
                                    }}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label className="required form-label">
                                    Select Branch
                                  </label>
                                  {/* <BranchListDropDown onBranchChange={handleBranchChange}/> */}
                                  <Select
                                    styles={selectStylesReport}
                                    className="search-line"
                                    placeholder="Search..."
                                    // options={[
                                    //   {
                                    //     label: "All",
                                    //     value: "all",
                                    //   },
                                    //   ...filteredOptions,
                                    // ]}

                                    options={getSelectOptions(filteredOptions)}
                                    value={branch_id2}
                                    onChange={(opt) => {
                                      setbranch_id(opt.value);
                                      setbranch_id2(opt);
                                    }}
                                  />
                                </div>


                                <div className="col-md-3">
                                  <label className="required form-label">
                                    Select Report type
                                  </label>
                                  <Select

                                    styles={selectStylesReport}
                                    className="search-line"
                                    placeholder="Search..."
                                    options={[
                                      {
                                        label: "Report By Detail",
                                        value: "report by detail",
                                      },
                                      {
                                        label: "Report By Summary",
                                        value: "report by summary",
                                      },
                                    ]}
                                    value={Report_type}
                                    onChange={(opt) => {
                                      setReport_type(opt);
                                    }}
                                  />
                                </div>

                                <div className="row col-md-12 text-center mt-5">
                                  {loader ? (
                                    <Box>
                                      <CircularProgress />
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-md-12 my-3"> {errorDiv} </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4 text-center mt-3">
                                  <button

                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                  {/* <button
                                  type="button"
                                  onClick={() => {
                                    setend_date("");
                                    setstart_date("");
                                    setbranch_id("");
                                    setbranch_id2([]);
                                    setError(null);
                                    setOrder_type("");
                                    setOrder_type2([]);
                                  }}
                                  className="btn btn-danger mx-2"
                                >
                                  Cancel
                                </button> */}
                                </div>
                                <div className="col-md-4"></div>
                              </div>
                              <hr className="mt-9" />

                              {CSVDATA?.length > 0 &&
                                <div className="row col-md-12 mt-6">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4"></div>

                                  <div className="col-md-4 text-end">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        Report_type?.value === "report by summary"
                                          ? exportPDFforSummrayReport(CSVDATA, start_date, end_date ,currencyLabel) :
                                          exportPDFforDetailReport(CSVDATA)}
                                      className="btn btn-success mx-3"
                                    >
                                      Download PDF
                                    </button>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        Report_type?.value === "report by summary" ?
                                          exportXlsxSummrayForReport(CSVDATA, start_date, end_date ,currencyLabel)
                                          : exportToXlsxForDetailReport(CSVDATA)}
                                      className="btn btn-success"
                                    >
                                      <span className="indicator-label">
                                        Download XLSX
                                      </span>
                                    </button>
                                  </div>
                                </div>}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminGroupReport);
