import React, { useEffect, useRef, useState } from 'react'
import GroupAdminHeader from '../../../Comman/Header';
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { useHistory, useParams } from "react-router-dom";
import MaterialTable from "material-table";
import { useStyles } from '../../../Comman/TableuseStyles';
import { Tableoptions } from '../../../Comman/TableOption';
import { useMediaQuery } from "react-responsive";
import { getBranchDetaisById } from '../../../../Store';
import { connect } from "react-redux";
import BranchCommon from '../../Page/BranchCommon';
import useDrawer from '../../../../context/DrawerContext';
import { deleteCommonFunction } from '../../../Comman/DeleteCommonFun/DeleteCommonFunction';
import Addnewdetails from '../../../Comman/AddNewDetails/Addnewdetails';
import { generateTableActions } from '../../../Comman/TableActions';
import { BranchesPermissions, userPermissionType } from '../../../Comman/UserPermission';


function GroupAdminUserList({ getBranchDetaisById, branchDetaislByID }) {

  const userPermissionType_ = userPermissionType === "groupadmin"


  const scrollRef = useRef();
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ bihavior: "smooth" });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [user, setuser] = useState([]);
  const [comman, setcomman] = useState("");
  const [Staff, setStaff] = useState([])
  const [Waiter, setWaiter] = useState([]);

  useEffect(() => {
    if (id) {
      getBranchDetaisById(id);
    }
  }, [id]);

  useEffect(() => {
    if (branchDetaislByID) {
      setcomman(branchDetaislByID);
      setuser(branchDetaislByID?.managerData);
      setStaff(branchDetaislByID?.staffData);
      setWaiter(branchDetaislByID?.waiterData)
    }
  }, [branchDetaislByID])

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Manager Name",
      field: "name",
    },
    {
      title: "Phone Number",
      field: "phone_number",
    },
    {
      title: "Email",
      field: "email",
    },
  ];

  function deleteManager(_id) {
    deleteCommonFunction(`/groupadmin/manager/${_id}`, "Manager has been successfully deleted.", () => getBranchDetaisById(id));
  }

  function updateManager(id) {
    history.push(`/group_admin/branch_management/edit_manager_details/${id}`);
  }

  const columns_staff = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Staff Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
  ];

  function deletStaff(_id) {
    deleteCommonFunction(`/groupadmin/kitchenuser/${_id}`, "Staff has been successfully deleted.", () => getBranchDetaisById(id));
  }

  function updateStaff(id) {
    history.push(`/group_admin/branch_management/edit_staff_details/${id}`);
  }

  const columns_Waiter = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Service Staff Name",
      field: "name",
    },
    {
      title: "Phone Number",
      // field: "phone_number",
      render: (rowData) => {
        return <>{rowData.phone_number ? rowData?.phone_number : "---"}</>;
      },
    },
    // {
    //   title: "Email",
    //   field: "email",
    // },
    {
      title: "Staff Id",
      field: "staff_id",
    },
    {
      title: "Pin Code",
      field: "pin",
    },
  ];

  function deletWaiter(_id) {
    deleteCommonFunction(`/groupadmin/waiter/${_id}`, "Service staff has been successfully deleted.", () => getBranchDetaisById(id));
  }

  function updateWaiter(id) {
    history.push(`/group_admin/branch_management/edit_servicestaff_details/${id}`);
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  return (

    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div ref={scrollRef} className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Branches
                        </h1>
                      </div>
                    </div>

                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        {comman?.resData?.branch_name_EN ?
                          <h3
                            className="anchor fw-bolder mb-5"
                            id="custom-form-control"
                          >
                            User List - {comman?.resData?.branch_name_EN}
                          </h3> : ""}
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >

                        </div>
                      </div>
                    </div>
                    <div className={isMobile ? "" : "rounded border p-10"}>
                      <BranchCommon />

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className={isMobile ? "" : "card card-docs mb-2"}>
                            <div className={isMobile ? "" : "card-body"}>

                              <div className="d-flex flex-stack mb-5">
                                <div
                                  className="d-flex justify-content-end"
                                  data-kt-docs-table-toolbar="base"
                                >
                                  <h1
                                    className="anchor fw-bolder"
                                    id="custom-form-control"
                                    style={{ fontSize: 22 }}
                                  >
                                    Manager List
                                  </h1>
                                </div>
                                <div className="d-flex flex-stack">
                                  <div
                                    className="d-flex justify-content-end mx-2"
                                    data-kt-docs-table-toolbar="base"
                                  >

                                    {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) && (
                                      <Addnewdetails
                                        url={`/group_admin/branch_management/add_manager/${id}`}
                                        Name="Add Manager" />
                                    )}


                                  </div>
                                </div>
                              </div>
                              <div className={classes.root}>
                                <MaterialTable
                                  title=""
                                  data={user}
                                  columns={columns}
                                  options={Tableoptions}
                                  // actions={[
                                  //   {
                                  //     icon: "edit",
                                  //     tooltip: "Edit Manager",
                                  //     onClick: (event, rowData) =>
                                  //       updateManager(rowData._id),
                                  //   },
                                  //   {
                                  //     icon: "delete",
                                  //     tooltip: "Delete Manager",
                                  //     onClick: (event, rowData) =>
                                  //       deleteManager(rowData._id),
                                  //   },
                                  // ]}

                                  actions={generateTableActions(history, () => getBranchDetaisById(id), {
                                    viewPath: "",
                                    editPath: "group_admin/branch_management/edit_manager_details",
                                    deletePath: "groupadmin/manager"
                                  }, {
                                    editTooltip: "Edit Manager",
                                    deleteTooltip: "Delete Manager"
                                  }, {
                                    deleteMessage: "Manager has been successfully deleted."
                                  }, {
                                    access: BranchesPermissions,
                                  }
                                  )}
                                />

                              </div>



                              <div className="pt-20">
                                <div className="d-flex flex-stack mb-5">
                                  <div
                                    className="d-flex justify-content-end"
                                    data-kt-docs-table-toolbar="base"
                                  >
                                    <h1
                                      className="anchor fw-bolder"
                                      id="custom-form-control"
                                      style={{ fontSize: 22 }}
                                    >
                                      Kitchen Staff List
                                    </h1>
                                  </div>
                                  <div className="d-flex flex-stack">
                                    <div
                                      className="d-flex justify-content-end mx-2"
                                      data-kt-docs-table-toolbar="base"
                                    >

                                      {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) && (
                                        <Addnewdetails
                                          url={`/group_admin/branch_management/add_staff/${id}`}
                                          Name="Add Staff" />
                                      )}


                                    </div>
                                  </div>
                                </div>
                                <div className={classes.root}>
                                  <MaterialTable
                                    title=""
                                    data={Staff}
                                    columns={columns_staff}
                                    options={Tableoptions}
                                    // actions={[
                                    //   {
                                    //     icon: "edit",
                                    //     tooltip: "Edit Staff",
                                    //     onClick: (event, rowData) =>
                                    //       updateStaff(rowData._id),
                                    //   },
                                    //   {
                                    //     icon: "delete",
                                    //     tooltip: "Delete Staff",
                                    //     onClick: (event, rowData) =>
                                    //       deletStaff(rowData._id),
                                    //   },
                                    // ]}

                                    actions={generateTableActions(history, () => getBranchDetaisById(id), {
                                      viewPath: "",
                                      editPath: "group_admin/branch_management/edit_staff_details",
                                      deletePath: "groupadmin/kitchenuser"
                                    }, {
                                      editTooltip: "Edit Staff",
                                      deleteTooltip: "Delete Staff"
                                    }, {
                                      deleteMessage: "Staff has been successfully deleted."
                                    }, {
                                      access: BranchesPermissions,
                                    }
                                    )}
                                  />

                                </div>
                              </div>


                              <div className="pt-20">
                                <div className="d-flex flex-stack mb-5">
                                  <div
                                    className="d-flex justify-content-end"
                                    data-kt-docs-table-toolbar="base"
                                  >
                                    <h1
                                      className="anchor fw-bolder"
                                      id="custom-form-control"
                                      style={{ fontSize: 22 }}
                                    >
                                      Service Staff List
                                    </h1>
                                  </div>
                                  <div className="d-flex flex-stack">
                                    <div
                                      className="d-flex justify-content-end mx-2"
                                      data-kt-docs-table-toolbar="base"
                                    >
                                      {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) && (
                                        <Addnewdetails
                                          url={`/group_admin/branch_management/add_servicestaff/${id}`}
                                          Name="Add Service Staff"
                                        />
                                      )}


                                    </div>
                                  </div>
                                </div>
                                <div className={classes.root}>
                                  <MaterialTable
                                    title=""
                                    data={Waiter}
                                    columns={columns_Waiter}
                                    options={Tableoptions}
                                    // actions={[
                                    //   {
                                    //     icon: "edit",
                                    //     tooltip: "Edit Service Staff",
                                    //     onClick: (event, rowData) =>
                                    //       updateWaiter(rowData._id),
                                    //   },
                                    //   {
                                    //     icon: "delete",
                                    //     tooltip: "Delete Service Staff",
                                    //     onClick: (event, rowData) =>
                                    //       deletWaiter(rowData._id),
                                    //   },
                                    // ]}

                                    actions={generateTableActions(history, () => getBranchDetaisById(id), {
                                      viewPath: "",
                                      editPath: "group_admin/branch_management/edit_servicestaff_details",
                                      deletePath: "groupadmin/waiter"
                                    }, {
                                      editTooltip: "Edit Service Staff",
                                      deleteTooltip: "Delete Service Staff"
                                    }, {
                                      deleteMessage: "Service Staff has been successfully deleted."
                                    }, {
                                      access: BranchesPermissions,
                                    }
                                    )}

                                  />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />



      </div>

    </React.Fragment>
  )
}


const mapStateToProps = (state) => ({
  branchDetaislByID: state?.branch?.branchDetaislByID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDetaisById: (id) => dispatch(getBranchDetaisById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminUserList);
