export const Tableoptions = {
  sorting: true,
  search: true,
  searchFieldAlignment: "right",
  searchAutoFocus: true,
  searchFieldVariant: "standard",
  paging: true,
  pageSizeOptions: [2, 5, 10, 20, 25, 50, 100],
  pageSize: 5,
  emptyRowsWhenPaging: false,
  paginationType: "stepped",
  showFirstLastPageButtons: false,
  paginationPosition: "bottom",
  actionsColumnIndex: -1,
  draggable: false,
  headerStyle: {
    background: "darkgray",
    width: "100%",
    color: "#fff",
    fontSize: "15px",
  },
};
