import { INVENTORY } from "../action-types";

const getInventoryCatList = () => ({
  type: INVENTORY.GET_INVENTORY_CATEGORY,
});

const setInventoryCatList = (inventoryCatList) => ({
  type: INVENTORY.SET_INVENTORY_CATEGORY,
  inventoryCatList,
});

const getInventoryProList = () => ({
  type: INVENTORY.GET_INVENTORY_PRODUCT,
});

const setInventoryProList = (inventoryProList) => ({
  type: INVENTORY.SET_INVENTORY_PRODUCT,
  inventoryProList,
});

export {
  getInventoryCatList,
  setInventoryCatList,
  getInventoryProList,
  setInventoryProList,
};
