export const HeaderStyle = {
      headerTitle: {
        background: "#F2F4F7", 
        border: "1px solid #EAECF0",
        borderRedius : 0,
        fontSize: "14px", 
        padding  : "14px",
        fontWeight: 500,
        color : "#344054",
        fontFamily:"'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", 
      },
      headerCell: {
            border: "1px solid #EAECF0",
            borderRedius : "0px 0px 0px 0xp", 
            fontSize: "14px", 
            fontWeight: 500, 
            padding  : "16px 16px 16px 16px", 
            color :"#101828",
            fontFamily:"'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
      uniqueCodeHeader: {
            color: "#F25042",
            cursor :"pointer" ,
            fontFamily:"'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          }
    };
    