import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import { BRANCH1, setBranchList } from "../../actions";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";

function* callBranchList() { 
  try {
      const response = yield call(api.get, "/groupadmin/branch/restaurantwise", { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setBranchList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: BRANCH1.CLEAR_BRANCH_LIST });
      }
    }
}


export function* watchBranchList() {
  yield takeEvery(BRANCH1.GET_BRANCH_LIST, callBranchList);
}

