import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { BRANCHID, setBranchDetaisById } from "../../actions";

function* callBranchDetailsById({id}) { 
      if(id){
        try {
          const response = yield call(api.get, `/groupadmin/branch/${id}`, { headers: headers });
          const  tableData = response?.data?.data;
          // console.log('sss' ,tableData)
          yield put(setBranchDetaisById(tableData));
        } catch (error) {
          if (error.response) {
            yield put({ type: BRANCHID.CLEAR_BRANCHDETAILS_BY_ID });
          }
        }
      }else{
        yield put({ type: BRANCHID.CLEAR_BRANCHDETAILS_BY_ID });
      }
}


export function* watchBranchDetailsById() {
  yield takeEvery(BRANCHID.GET_BRANCHDETAILS_BY_ID, callBranchDetailsById);
}

