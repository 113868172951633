import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import { USERMANAGMENT, setUserManagmentList } from "../../actions";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";

function* callUserManagmentList() { 
  try {
      const response = yield call(api.get, "/groupadmin/user", { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setUserManagmentList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: USERMANAGMENT.CLEAR_USERMANAGMENT_LIST });
      }
    }
}


export function* watchserManagmentList() {
  yield takeEvery(USERMANAGMENT.GET_USERMANAGMENT_LIST, callUserManagmentList);
}

