import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import NoteState from "../CommanBranch/NoteState";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import CommonPickup from "./CommonPickup";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { SubmitformButton } from "../../../../Comman/Error/SubmitformButton";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";

function GroupAdminPickupSetting(props) {
  const history = useHistory();

  const { branchID, branchData } = props;

  const currencyLabel = getCurrencyLabel(branchData && branchData?.currency);
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const [settings, setSettings] = useState({
    order_email: "",
    order_interval: "",
    order_per_15_mins: "",
    order_days_in_advance: "",
    min_order_quantity: "",
    payment_method_online: false,
    payment_method_cash: false,
    pickup_active: false,
    ordering_notes: false,
    automatic_order_approval: "auto" // Default to "auto"
  });

  const [error, setError] = useState(null);
  const [ID, setID] = useState(null);

  useEffect(() => {
    applyFilterMeHandlers();
    if (branchID) {
      fetchSettings();
    }
  }, [branchID]);

  const fetchSettings = async () => {
    try {
      const res = await api.get(`/groupadmin/pickup_settings/${branchID}`, { headers });
      const data = res.data.data || {};
      setSettings({
        order_email: data.order_email || "",
        order_interval: data.order_interval || "",
        order_per_15_mins: data.order_per_15_mins || "",
        order_days_in_advance: data.order_days_in_advance || "",
        min_order_quantity: data.min_order_quantity || "",
        payment_method_online: data.payment_method_online || false,
        payment_method_cash: data.payment_method_cash || false,
        pickup_active: data.pickup_active || false,
        ordering_notes: data.order_notes_active || false,
        automatic_order_approval: data.automatic_order_approval !== undefined ? data.automatic_order_approval === true ? "auto" : "manual" : "auto" // Ensure existing setting or default to "auto"
      });
      setID(data._id || null);
    } catch (err) {
      handleError(err);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleApprovalChange = (e) => {
    const { value } = e.target;
    setSettings((prevState) => ({
      ...prevState,
      automatic_order_approval: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!branchID) {
      setError("Please Select Branch");
      return;
    }

    const payload = {
      branch_id: branchID,
      ...settings,
      order_days_in_advance: Number(settings.order_days_in_advance),
      order_interval: Number(settings.order_interval),
      order_per_15_mins: Number(settings.order_per_15_mins),
      min_order_quantity: Number(settings.min_order_quantity),
      automatic_order_approval: settings.automatic_order_approval === "auto" ? true : false
    };

    // console.log('ss' ,payload);
    // return

    try {
      if (ID) {
        await api.patch(`/groupadmin/pickup_settings/${ID}`, payload, { headers });
      } else {
        await api.post(`/groupadmin/pickup_settings`, payload, { headers });
      }
      showSuccessNotification("Your work has been saved successfully")
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err) => {
    if (err.response) {
      setError(err.response?.data?.error?.message);
    } else if (err.request) {
      setError(err.request);
    } else {
      setError(err.message);
    }
  };

  return (
    <React.Fragment>
      <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">Settings</h1>
                    <hr />
                    <div className="row col-md-12">
                      <div className="col-md-9 d-inline justify-content-end" data-kt-docs-table-toolbar="base">
                        <h3 className="anchor fw-bolder mb-5 d-inline" id="custom-form-control">Pickup - Settings</h3>
                      </div>
                      <div className="col-md-3 d-inline justify-content-end" data-kt-docs-table-toolbar="base">
                        <NoteState />
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="rounded border p-10">
                        <CommonPickup />
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                            <div className="py-5">
                              <div className="card card-docs mb-2">
                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                  <form onSubmit={handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-8">
                                        <div className="rounded border p-5">
                                          <div className="mb-10">
                                            <h6>Receive Orders Per E-mail</h6>
                                          </div>
                                          <div className="mb-10">
                                            <label htmlFor="email" className="required form-label">E-mail Address</label>
                                            <input
                                              type="text"
                                              id="order_email"
                                              name="order_email"
                                              required
                                              className="form-control form-control-solid"
                                              value={settings.order_email}
                                              onChange={handleChange}
                                            />
                                            <div className="my-4">
                                              Note : Use the comma in between as a separator to add multiple emails.
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <div className="rounded border p-10">
                                          <div className="mb-10">
                                            <h6>Restrictions</h6>
                                          </div>
                                          <div className="mb-10">
                                            <label htmlFor="order_interval" className="required form-label">Ordering interval in minutes</label>
                                            <input
                                              type="text"
                                              id="order_interval"
                                              name="order_interval"
                                              required
                                              className="filterme form-control form-control-solid"
                                              value={settings.order_interval}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="mb-10">
                                            <label htmlFor="order_per_15_mins" className="required form-label">Orders per particular interval.</label>
                                            <input
                                              type="text"
                                              id="order_per_15_mins"
                                              name="order_per_15_mins"
                                              required
                                              className="filterme form-control form-control-solid"
                                              value={settings.order_per_15_mins}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="mb-10">
                                            <label htmlFor="order_days_in_advance" className="required form-label">Order days in Advance</label>
                                            <input
                                              type="text"
                                              id="order_days_in_advance"
                                              name="order_days_in_advance"
                                              required
                                              className="filterme form-control form-control-solid"
                                              value={settings.order_days_in_advance}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label htmlFor="min_order_quantity" className="form-label">Minimum order quantity.</label>
                                            <div className="input-group">
                                              <input
                                                type="text"
                                                id="min_order_quantity"
                                                name="min_order_quantity"
                                                className="filterme form-control form-control-solid"
                                                value={settings.min_order_quantity}
                                                onChange={handleChange}
                                              />
                                              <div className="input-group-append">
                                                <span style={{ fontSize: "15px" }} className="input-group-text mx-2">{currencyLabel}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="rounded border p-10">
                                          <div className="mb-10">
                                            <h6>Active</h6>
                                          </div>
                                          <div className="mb-0">
                                            <input
                                              className="form-check-input mx-3"
                                              type="checkbox"
                                              id="pickup_active"
                                              name="pickup_active"
                                              checked={settings.pickup_active}
                                              onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="pickup_active">Pickup</label>
                                          </div>
                                        </div>
                                        <br />
                                        <div className="rounded border p-10" style={{ marginTop: 20 }}>
                                          <div className="mb-10">
                                            <h6>Ordering Notes</h6>
                                          </div>
                                          <div className="mb-0">
                                            <input
                                              className="form-check-input mx-3"
                                              type="checkbox"
                                              id="ordering_notes"
                                              name="ordering_notes"
                                              checked={settings.ordering_notes}
                                              onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="ordering_notes">Ordering Notes</label>
                                          </div>
                                        </div>
                                        <br />

                                        <div className="rounded border p-10" style={{ marginTop: 15 }}>
                                          <div className="mb-10">
                                            <h6 className="required">Payment Method</h6>
                                          </div>
                                          <div className="mb-5">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="payment_method_online"
                                              name="payment_method_online"
                                              checked={settings.payment_method_online}
                                              onChange={handleChange}
                                              required={!settings.payment_method_cash}
                                            />
                                            <label className="form-check-label mx-3" htmlFor="payment_method_online">Online Payment</label>
                                          </div>
                                          <div className="mb-0">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="payment_method_cash"
                                              name="payment_method_cash"
                                              checked={settings.payment_method_cash}
                                              onChange={handleChange}
                                              required={!settings.payment_method_online}
                                            />
                                            <label className="form-check-label mx-3" htmlFor="payment_method_cash">Cash Payment</label>
                                          </div>
                                        </div>
                                        <br /><br />
                                        <div className="rounded border p-10">
                                          <div className="mb-10">
                                            <h6 className="required">Order Approval</h6>
                                          </div>
                                          <div className="mb-5">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="automatic_order_approval"
                                              value="auto"
                                              checked={settings.automatic_order_approval === "auto"}
                                              onChange={handleApprovalChange}
                                            />
                                            <label className="form-check-label mx-3">Auto approval</label>
                                          </div>
                                          <div className="mb-0">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="automatic_order_approval"
                                              value="manual"
                                              checked={settings.automatic_order_approval === "manual"}
                                              onChange={handleApprovalChange}
                                            />
                                            <label className="form-check-label mx-3">Manual approval</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                    <ErrorMessage error={error} />
                                    

                                    <div className="modal-footer flex-center">

                                      {(userPermissionType === "groupadmin" ||
                                        UserPermissionAllData?.role_details?.settings?.find(permission =>
                                          permission?.key === "pickup" &&
                                          (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                        )) && (
                                          <button type="submit" className="btn btn-primary">
                                            <span className="indicator-label">Save</span>
                                          </button>)}
                                      <button type="button" className="btn btn-danger m-2" onClick={() => history.goBack()}>
                                        Cancel
                                      </button>
                                    </div>                                 </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
              <GroupAdminUperSide />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
  branchData: state.branch.branchData,
});

export default connect(mapStateToProps)(GroupAdminPickupSetting);
