import React, { useState, useEffect, useRef } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import api from "../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import "./AddVoucher.css"
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useDrawer from "../../../../context/DrawerContext";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { getCurrencyLabel } from "../../../Comman/currencyUtils/currencyUtils";
import useError from "../../../Comman/Error/useError";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
window.jquery = window.$ = $;

export default function GroupAdminEditVoucher() {

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  const { error, showError, clearError } = useError();

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  let history = useHistory();

  const { id , currency } = useParams();
  const currencyLabel = getCurrencyLabel(currency);

  const [branch_id, setbranch_id] = useState([]);
  const [voucher_category ,setvoucher_category] = useState("")
  const [voucher_title ,setvoucher_title] = useState("Gift Voucher");
  const [salutation_title ,setsalutation_title] = useState("Dear");
  const [voucher_amount ,setvoucher_amount] = useState("");
  const [VoucherOrderType ,setVoucherOrderType] = useState("")
  const [voucher_validity ,setvoucher_validity] = useState("");
  const [voucher_validity_type ,setvoucher_validity_type] = useState('');
  const [imgData, setImgData] = useState(null);
  const [image, setimage] = useState("");
  const [_ids, set_ids] = useState([]);
  const [percentage_discount ,setpercentage_discount] = useState("");

  
  useEffect(() => {
    groupAdminVoucherDetails();
  }, []);

  function groupAdminVoucherDetails() {
    api
      .get(`/groupAdmin/available_voucher/${id}`, { headers })
      .then((res) => {

      const rowData = res.data.data
      setimage(rowData?.header_image);
      setImgData(rowData?.header_image)
      setVoucherOrderType(rowData?.order_type);
      setsalutation_title(rowData?.salutation_title);
      setvoucher_category(rowData?.voucher_category);
      setvoucher_validity_type(rowData?.voucher_validity_type);
      setvoucher_validity(rowData?.voucher_validity);
      setvoucher_title(rowData?.voucher_title);
      setpercentage_discount(rowData?.percentage_discount);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
        }
      });
  }



  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Check if the selected file is an image (PNG, JPG, or JPEG)
      if (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/jpg') {
        setImgData(selectedFile);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setimage(reader.result);
        });
        reader.readAsDataURL(selectedFile);
      } else {
        // Handle the case when a non-image file is selected
        showError('Please select a PNG, JPG, or JPEG file.');
      }
    }};

  
  const concatenatedArray = branch_id?.reduce((acc, item) => {
        if (Array.isArray(item?.value)) {
            acc = acc.concat(item?.value?.map(subItem => subItem.value));
        } else {
            acc.push(item?.value);
        }
        return acc;
      }, [branch_id?.length > 0]);

  const filteredArray = concatenatedArray?.filter(item => typeof item !== 'boolean');

  const cardRef = useRef(null);

  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ bihavior: "smooth" });
  }, []);

  function handleSubmit(event) {
      event.preventDefault();
      clearError(null);
  
      if (imgData === null && image === "") {
            showError("Please upload your image.");
            return;
      } 
       else if (voucher_category === "") {
        showError("Please select voucher category");
        return;
        }
        // else if (VoucherOrderType == "") {
        //   showError("Please select voucher order type");
        //   return;
        //   }
       else if (voucher_title === "") {
        showError("Please enter voucher title");
        return;
        }
       else if (salutation_title === "") {
        showError("Please enter salutation title");
        return;
        }
        else if (voucher_validity_type === "") {
          showError("Please select voucher validity");
          return;
        }
        else if (voucher_validity === "") {
          showError(`Please enter atleast 1 ${voucher_validity_type === "months" ? "month" : "day"}`);
          return;
        }
        else if (voucher_validity === 0) {
        showError(`Please enter atleast 1 ${voucher_validity_type === "months" ? "month" : "day"}`);
        return;
      }
      else if (percentage_discount >= 100){
        showError(`Maximum discount you can apply is 99 percent.`);
        return; 
      }
      else {
        $(".loader").show();
  
        const item = new FormData();
        item.append("voucher_category", voucher_category);
        item.append("voucher_title", voucher_title);
        item.append("salutation_title", salutation_title);
        item.append("voucher_validity", Number(voucher_validity));
        item.append("voucher_validity_type", voucher_validity_type);
        // item.append("order_type", VoucherOrderType);
        item.append("header_image", imgData);
        item.append('percentage_discount' , percentage_discount ? percentage_discount : 0)
        
        api
          .patch(`/groupAdmin/available_voucher/${id}`, item, { headers })
          .then((res) => {
            $(".loader").hide();
            showSuccessNotification("Voucher has been edited succesfully.",)
            // groupAdminVoucherDetails();
            history.push("/group_admin/coupon_voucher_management/voucher_management/voucher_templates")
            // window.location.reload(true);
          })
          .catch((err) => {
            $(".loader").hide();
            if (err.response) {
              showError(err.response?.data?.error?.message);
            }
          });
      }
      
    }
  

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div ref={scrollRef} className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${mainClassCustome.join(" ")}`}>
                  <div className="d-flex flex-stack mb-2">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h1  className="anchor fw-bolder" id="custom-form-control">
                       <KeyboardBackspaceIcon onClick={() => history.push("/group_admin/coupon_voucher_management/voucher_management/voucher_templates")} style={{fontSize : 30 , cursor : "pointer"}} className="mx-3" /> Edit Voucher
                      </h1>
                    </div>
                    <div className="d-flex flex-stack">
                    
                    </div>
                  </div>
                  <div className="py-5">
                    <div className="row">
                      <div className={isTabletOrMobile ? "col-md-6" : 'col-sm-12 col-md-5 mb-5'}>
                      <div ref={cardRef} className='card' style={{border : "1px solid #bfbdbd" ,  borderRadius : "19px 19px 19px 19px" }}>
                        <div className="card-header p-0" style={{height : "200px"}}> 
                        <div className="container_gride_view image-box">
                            <div className="img-container parent" >
                              {image ? 
                              <img
                              variant="top"
                              loading="lazy"
                              className="image1"
                              alt="voucher_img" src={image ? image : '/assets/Rectangle 15.png'}
                              style={{ maxWidth: '100%', maxHeight: '100%' , borderBottom: "16px solid #8C0B04" }}
                            /> :
                              <img
                              variant="top"
                                    loading="lazy"
                                    alt="voucher_img" src='/assets/Rectangle 15.png'
                                    style={{ maxWidth: '100%', maxHeight: '100%' , borderBottom: "16px solid #8C0B04" }}
                              /> }
                            </div>
                           
                          </div>
                        </div>
                       
                        <div className='card-body'  style={{marginTop  :0 , position  :"relative"}}>
                        <img
                          alt="voucher_img" src="/assets/Ri.png"
                          style={{
                            display: "-webkit-fill-available",
                              marginTop: 0,
                              // zIndex: 999,
                              position: "absolute",
                              left: "0",
                              right: "0",
                              marginLeft: "auto",
                              maxWidth: "90px",
                              width: "100%",
                              // FONT-VARIANT: JIS04;
                              top: "-46px"
                          }}
                        />
                        <div className="row">
                          <h5 className="gift_voucher">{voucher_title}</h5>
                        </div>
                        <div className="row" >
                          <div className="col-6">
                          <h5 className="dear_name"> {salutation_title} , Your Name</h5>
                          </div>
                          <div className="col-6 text-end">
                          <h3 className="CHF_">{currencyLabel} {Number(voucher_amount).toFixed(2) }</h3>
                        </div>
                        {/* <div className="row"> */}
                          <div className="col-6">
                            <h6 className="your_message">
                              Your Message...
                            </h6>
                          </div>
                          <div className="col-6">
                            <h6 className="code">Code: XXXX-XXXX </h6>
                            <h6 className="validity">Valid till DD-MM-YYYY </h6>
                          </div>
                        {/* </div> */}
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className={isTabletOrMobile ? "col-md-6" : 'col-sm-12 col-md-7'}>
                      <div className='card'>
                        <form onSubmit={handleSubmit}>
                      <div className='card-body'>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 my-2">
                          <h6 className="Branch_selection required">Your photo</h6>
                          <p className="common_line">Upload your image.</p>
                          </div>
                          <div className="col-md-6 col-sm-12 my-2 text-center my-2">
                          <div class="rounded border p-5">
                        <label onChange={handleFileChange}>
                          <input
                                            type="file"
                                            name="avatar"
                                            accept=".png, .jpg, .jpeg"
                                            style={{display : "none"}}
                                            id="fileInput"
                                          onChange={handleFileChange}
                                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={30}
                            height={30}
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_388_483)">
                              <path
                                d="M13.8326 13.3332L10.4992 9.9999M10.4992 9.9999L7.1659 13.3332M10.4992 9.9999V17.4999M17.4909 15.3249C18.3037 14.8818 18.9458 14.1806 19.3158 13.3321C19.6858 12.4835 19.7627 11.5359 19.5344 10.6388C19.3061 9.7417 18.7855 8.94616 18.0548 8.37778C17.3241 7.80939 16.425 7.50052 15.4992 7.4999H14.4492C14.197 6.52427 13.7269 5.61852 13.0742 4.85073C12.4215 4.08295 11.6033 3.47311 10.681 3.06708C9.7587 2.66104 8.75636 2.46937 7.74933 2.50647C6.7423 2.54358 5.75679 2.80849 4.86688 3.28129C3.97697 3.7541 3.20583 4.42249 2.61142 5.23622C2.01701 6.04996 1.61481 6.98785 1.43505 7.9794C1.25529 8.97095 1.30266 9.99035 1.57358 10.961C1.8445 11.9316 2.33194 12.8281 2.99923 13.5832"
                                stroke="#475467"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_388_483">
                                <rect width={20} height={20} fill="white" transform="translate(0.5)" />
                              </clipPath>
                            </defs>
                            
                          </svg>
                          <p className="my-4"><strong onChange={handleFileChange}   style={{color : "#F25042"}}>Click to upload</strong> or drag and drop 
                           <p>PNG, JPG or JPEG (max. 400x200px)</p>  </p>
                          </label>
                          </div>
                          </div>
                        </div>
                        <hr style={{border : "1px solid rgb(191 192 195)"}} />
                        <div className="row">
                        <div className="col-md-6 col-sm-12 my-2">
                        <h6 className="Branch_selection required">Voucher Category</h6>
                        <div className="common_line">Select the voucher category.</div>
                        </div>
                          <div className="col-md-6 col-sm-12 my-2">
                          <Select
                                className="search-line"
                                placeholder="Select..."
                                value={[
                                    { label: `${voucher_category}` },
                                  ]}
                                options={
                                  [
                                    { value: 'Best Wishes', label: 'Best Wishes' },
                                    { value: 'General', label: 'General' },
                                    { value: 'Birthday', label: 'Birthday' },
                                    { value: 'Wedding', label: 'Wedding' },
                                    { value: 'Festivals', label: 'Festivals' },
                                    { value: 'Love', label: 'Love' },
                                    { value: 'New Born', label: 'New Born' },
                                    { value: 'Housewarming', label: 'Housewarming' },
                                    { value: 'Retirement', label: 'Retirement' },
                                    { value: 'Thank You', label: 'Thank You' },
                                    { value: 'Congrats', label: 'Congrats' },
                                    { value: 'Anniversary', label: 'Anniversary' },

                                  ]
                                }
                                onChange={(opt) => setvoucher_category(opt.value)}
                              />
                          </div>
                        </div>
                        <hr style={{border : "1px solid rgb(191 192 195)"}} />
                        <div className="row">
                        <div className="col-md-6 col-sm-12 my-2">
                        <h6 className="Branch_selection required">Voucher Title</h6>
                        <div className="common_line">Enter the voucher title.</div>
                        </div>
                          <div className="col-md-6 col-sm-12 my-2">
                          <input
                                type="text"
                                className="form-control"
                                value={voucher_title}
                                onChange={(e) => setvoucher_title(e.target.value)}
                              />
                          </div>
                        </div>
                        <hr style={{border : "1px solid rgb(191 192 195)"}} />
                        <div className="row">
                        <div className="col-md-6 col-sm-12 my-2">
                        <h6 className="Branch_selection required">Salutation Title</h6>
                        <div className="common_line">Enter the salutation title.</div>
                        </div>
                          <div className="col-md-6 col-sm-12 my-2">
                          <input
                                type="text"
                                className="form-control"
                                // placeholder=""
                                value={salutation_title}
                                onChange={(e) => setsalutation_title(e.target.value)}
                              />
                          </div>
                        </div>
                        
                        <hr style={{border : "1px solid rgb(191 192 195)"}} />
                        <div className="row">
                        <div className="col-md-6 col-sm-12 my-2">
                        <h6 className="Branch_selection required">Voucher Validity</h6>
                        <div className="common_line">Enter voucher validity (Month/Day).</div>
                        </div>
                          <div className="col-md-6 col-sm-12 my-2">
                            <div className="row">
                              <div className="col-md-5 col-sm-12 my-2">
                              <Select
                                 value={[
                                    { label: `${voucher_validity_type === "days" ? "Day" : "Month"}` },
                                  ]}
                                className="search-line"
                                placeholder="Select..."
                                options={
                                  [
                                    { value: 'months', label: 'Month' },
                                    { value: 'days', label: 'Day' },
                                  ]
                                }
                                onChange={(opt) => setvoucher_validity_type(opt.value)}
                              />
                              </div>
                              <div className="col-md-7 col-sm-12 my-2">
                            <input
                                type="text"
                                style={{height : "38px"}}
                                className="filterme form-control"
                                placeholder= {`Number of ${voucher_validity_type ? voucher_validity_type : "months"}`}
                                value={voucher_validity}
                                onChange={(e) => setvoucher_validity(e.target.value.replace(/[^0-9]/g, ""))}
                                aria-describedby="basic-addon2"
                              />
                            </div>
                            </div>
                            
                            
                          </div>
                        </div>

                        <hr style={{border : "1px solid rgb(191 192 195)"}} />
                        <div className="row mb-12">
                        <div className="col-md-6 col-sm-12 my-2">
                        <h6 className="Branch_selection" >Voucher Discount  
                        <Tooltip title="Please Enter Value Below 100" className="mx-1" placement="right-start">
                        <InfoOutlinedIcon /> 
                      </Tooltip> </h6>
                        <div className="common_line">Enter voucher discount (%).</div>
                        </div>
                          <div className="col-md-6 col-sm-12 my-2">
                            <div className="row">
                             <div className="input-group">
                                         
                                          <input
                                           type="text"
                                           style={{height : "43px"}}
                                           className="filterme form-control"
                                           placeholder= {`Voucher discount `}
                                           value={percentage_discount}
                                           onChange={(e) => {
                                            const newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                            if (newValue === "" || (newValue >= 0 && newValue < 100)) {
                                              setpercentage_discount(newValue);
                                            }
                                          }}
                                           aria-describedby="basic-addon2"
                                          />
                                           <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Discount in (%)
                                            </span>
                                          </div>
                                        </div>
                            </div>
                            
                            
                          </div>
                        </div>
                        <div className="col-md-12 text-center">
                              <br />
                              <div
                                className="loader"
                                style={{ display: "none" }}
                              >
                                <img src="/assets/loader.gif" />
                              </div>
                            </div>
                            <ErrorMessage error={error} />
                        <div
                        className="d-flex justify-content-center mx-2"
                      >

                      <button
                        style={{width : "110px"}}
                        type="submit"
                          // onClick={handleSubmit}
                          className="btn btn-primary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                        >
                          Save
                        </button>
                        <button
                          onClick={(e) => {
                            window.location.reload(true);
                          
                          }}
                          style={{width : "110px"}}
                          className="btn btn-danger mx-2"
                          type="button"
                        >
                          Cancel
                        </button>
                        
                      </div>
                        </div>
                        </form>
                        </div>
                        {/* <button onClick={handleConvert}>Convert to Image</button>
                       <img src={image1} />  */}
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}


