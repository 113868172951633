import React from 'react';
import { getUrlWithPrefix } from '../Comman/CommonFunction/CommonFunction';
import { useIsMobile } from '../Comman/MediaQueries/mediaQueries';

const RedirectLink = ({ dashboardURl }) => {
  const isMobile = useIsMobile();

  return (
    <>
      {dashboardURl && (
        <div className={isMobile ? 'my-2' : 'mx-4'} style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              padding: '10px 12px 10px 14px',
              alignItems: 'center',
              borderRadius: '8px 0px 0px 8px',
              border: '1px solid var(--gray-300, #D0D5DD)',
            }}
          >
            <span
              style={{
                color: 'var(--gray-500, #667085)',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
              }}
            >
              URL Slug
            </span>
          </div>

          <div
            style={{
              padding: '10px 14px',
              alignItems: 'center',
              alignSelf: 'stretch',
              borderRadius: '0px 8px 8px 0px',
              border: '1px solid var(--gray-300, #D0D5DD)',
              background: 'var(--White, #FFF)',
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                flex: '1 0 0',
                color: 'var(--gray-900, #101828)',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
              }}
              onClick={() => window.open(getUrlWithPrefix(dashboardURl))}
            >
              {dashboardURl && getUrlWithPrefix(dashboardURl)}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default RedirectLink;
