import React, { useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import api from "../../../../../CommonApi/axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import moment from "moment";
import headers from "../../../../../CommonApi/headers";
import Swal from "sweetalert2";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
window.jquery = window.$ = $;

export default function GroupAdminPosSettings() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[6];

  const userPermissionType_ = userPermissionType === "groupadmin"


  /////////////////////////////// Pos Provider Add /////////////////////////
  let history = useHistory();
  const location = useLocation();

  // "goldenindia-6bae-45a1-a058-f371cbbec87c";
  const [client_id, setclient_id] = useState("");

  // "d9eac058-bc9d-4dd6-8709-090a6b3ffa8e";
  const [client_secret, setclient_secret] = useState("");
  const [business_id, setbusiness_id] = useState("");
  const [menu_id, setmenu_id] = useState("");
  const [delivery_account_profile_code, setdelivery_account_profile_code] =
    useState("delivery");
  const [pickup_account_profile_code, setpickup_account_profile_code] =
    useState("takeaway");
  const [dinein_account_profile_code, setdinein_account_profile_code] =
    useState("dinein");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function handleSubmit(event) {
    event.preventDefault();
    $(".loader").show();
    setError(null);

    const formData = new FormData();
    formData.append("branch_id", location?.state?.branchID);
    formData.append("client_id", client_id);
    formData.append("client_secret", client_secret);
    formData.append("pos_id", location?.state?.POSID);
    formData.append("business_id", business_id);
    formData.append("menu_id", menu_id);

    if (delivery_account_profile_code) {
      formData.append(
        "delivery_account_profile_code",
        delivery_account_profile_code
      );
    }
    if (dinein_account_profile_code) {
      formData.append(
        "dinein_account_profile_code",
        dinein_account_profile_code
      );
    }
    if (pickup_account_profile_code) {
      formData.append(
        "pickup_account_profile_code",
        pickup_account_profile_code
      );
    }

    api
      .post(`/groupadmin/settings/POS/kseries/add_pos_data`, formData, {
        headers,
      })
      .then((res) => {
        // window.open(
        //   `https://api.lsk.lightspeed.app/oauth/authorize?response_type=code&client_id=goldenindia-6bae-45a1-a058-f371cbbec87c&redirect_uri=https://group.kitchenparameter.com/posauth&scope=orders-api&state=${location.state.branchID}`
        // );
        window.open(
          process.env.REACT_APP_BASE_URL === "https://goldenindia-restaurantgroupapi.creatoro.in"
            ? `https://api.trial.lsk.lightspeed.app/oauth/authorize?client_id=goldenIndiaTrial-ee44-4be4-94eb-93032d8de1fc&response_type=code&redirect_uri=https://localhost/&scope=orders-api&state=${location.state.branchID}`
            : process.env.REACT_APP_BASE_URL ===
              "https://groupapi.kitchenparameter.com"
            ? `https://api.lsk.lightspeed.app/oauth/authorize?response_type=code&client_id=goldenindia-6bae-45a1-a058-f371cbbec87c&redirect_uri=https://group.kitchenparameter.com/posauth&scope=orders-api&state=${location.state.branchID}`
            : process.env.REACT_APP_BASE_URL ===
              "https://groupapidemo.kitchenparameter.com"
            ? `https://api.trial.lsk.lightspeed.app/oauth/authorize?client_id=goldenIndiaTrial-ee44-4be4-94eb-93032d8de1fc&response_type=code&redirect_uri=https://localhost/&scope=orders-api&state=${location.state.branchID}`
            : `https://api.trial.lsk.lightspeed.app/oauth/authorize?client_id=goldenIndiaTrial-ee44-4be4-94eb-93032d8de1fc&response_type=code&redirect_uri=https://localhost/&scope=orders-api&state=${location.state.branchID}`
        );
        // console.log("sdA", res.data);
      })
      .catch((err) => {
        if (err.response) {
          setError(err?.response?.data?.error?.message);
        }
      });
  }

  // let item = {
  //   client_id: client_id,
  //   client_secret: client_secret,
  // };
  // if ((item.client_id != "") & (item.client_secret != "")) {
  // window.open(
  //   `https://api.lsk.lightspeed.app/oauth/authorize?response_type=code&client_id=goldenindia-6bae-45a1-a058-f371cbbec87c&redirect_uri=https://www.goldenindia.ch&scope=orders-api&state=${location.state.branchID}`,
  //   "_self"
  // );
  // window.open(
  //   process.env.REACT_APP_BASE_URL === "http://174.138.21.74:9981"
  //     ? `https://api.trial.lsk.lightspeed.app/oauth/authorize?response_type=code&client_id=goldenindia-6bae-45a1-a058-f371cbbec87c&redirect_uri=https://www.goldenindia.ch&scope=orders-api&state=${location.state.branchID}`
  //     : process.env.REACT_APP_BASE_URL ===
  //       "https://groupapi.kitchenparameter.com"
  //     ? `https://api.lsk.lightspeed.app/oauth/authorize?response_type=code&client_id=goldenindia-6bae-45a1-a058-f371cbbec87c&redirect_uri=https://www.goldenindia.ch&scope=orders-api&state=${location.state.branchID}`
  //     : `https://api.trial.lsk.lightspeed.app/oauth/authorize?response_type=code&client_id=goldenindia-6bae-45a1-a058-f371cbbec87c&redirect_uri=https://www.goldenindia.ch&scope=orders-api&state=${location.state.branchID}`
  // );
  // window.open(
  //   `https://api.trial.lsk.lightspeed.app/oauth/authorize?client_id=goldenIndiaTrial-ee44-4be4-94eb-93032d8de1fc&response_type=code&redirect_uri=https://localhost/&scope=orders-api&state=${location.state.branchID}`
  // );
  // }
  // setclient_id("");
  // setclient_secret("");
  // }

  /////////////////////////// pos provaider /////////////////////////

  /////////////////// Get Details Fro Secont From ////////////////////////
  const [posDataById, setposDataById] = useState(null);
  const [pos_status_active, setpos_status_active] = useState(false);
  const [AuthButton, setAuthButton] = useState(true);

  useEffect(() => {
    POSListbyiD();
  }, [location?.state?.branchID]);

  function POSListbyiD() {
    api
      .get(`/groupadmin/branch/posType/${location.state.branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res?.data?.data?.pos_fields[0];
        // console.log("res", res?.data);
        setpos_status_active(
          res?.data?.data?.pos_status_active
            ? res?.data?.data?.pos_status_active
            : false
        );
        setAuthButton(res?.data?.data?.pos_name === "G Series" ? true : false);
        setposDataById(tableData);
        setclient_id(tableData?.client_id ? tableData?.client_id : "");
        setclient_secret(
          tableData?.client_secret ? tableData?.client_secret : ""
        );
        setbusiness_id(tableData?.business_id ? tableData?.business_id : "");
        setmenu_id(tableData?.menu_id ? tableData?.menu_id : "");
        setdelivery_account_profile_code(
          tableData?.delivery_account_profile_code
            ? tableData?.delivery_account_profile_code
            : "delivery"
        );
        setdinein_account_profile_code(
          tableData?.dinein_account_profile_code
            ? tableData?.dinein_account_profile_code
            : "dinein"
        );
        setpickup_account_profile_code(
          tableData?.pickup_account_profile_code
            ? tableData?.pickup_account_profile_code
            : "takeaway"
        );
      })
      .catch((err) => {
        setposDataById("");
        setAuthButton(true);
        if (err.response) {
          toast(err?.response?.data?.error?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  function handleSubmitEdit(event, value) {
    event.preventDefault();

    let item1 = {
      is_active: value,
    };

    // console.log("item", item1);

    api
      .patch(
        `/groupadmin/settings/POS/updatePOSStatus/${location.state.branchID}`,
        item1,
        {
          headers,
        }
      )
      .then((res) => {
        POSListbyiD();
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: `Pos has been ${value ? "Active" : "Inactive"} succesfully.`,
          showConfirmButton: false,
          timer: 1500,
        });
        // console.log("sdA", res?.data);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data?.error?.message);
        }
      });
  }

  // console.log("AuthButton", AuthButton);

  /////////////////// Get Details Fro Secont From ////////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        POS K - Series / <span>Pos Auth</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() =>
                            history.push("/group_admin/settings/pos")
                          }
                          className="btn btn-primary"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-5">
                    <div
                      className="row rounded border p-5"
                      style={{ border: "3px solid #eff2f5" }}
                    >
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        ></div>
                        <div className="d-flex flex-stack">
                          <div
                            className="d-flex justify-content-end "
                            data-kt-docs-table-toolbar="base"
                          >
                            {pos_status_active ? (
                              <button
                                onClick={(e) => handleSubmitEdit(e, false)}
                                className="btn btn-success"
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                onClick={(e) => handleSubmitEdit(e, true)}
                                className="btn btn-danger"
                              >
                                Inactive
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            style={{ marginLeft: "14px" }}
                            className={
                              url === "auth" ? "nav-link active" : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/settings/K_Series/pos/pos_settings/auth",
                              state: {
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Auth
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            className={
                              url === "business_setup"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/settings/K_Series/pos/pos_settings/business_setup",
                              state: {
                                // menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Business Setup
                          </Link>
                        </li> */}
                      </ul>

                      <div className="tab-content" id="myTabContent">
                        <br />
                        <div className="row col-sm-12">
                          <div className="col-sm-6">
                            <form onSubmit={handleSubmit}>
                              <div
                                className="card card-docs mb-2"
                                style={{
                                  boxShadow:
                                    "0 0 20px 0 rgb(123 123 123 / 46%)",
                                }}
                              >
                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                  <div className="mb-10">
                                    <label className="required form-label">
                                      Client ID
                                    </label>
                                    <input
                                      type="text"
                                      value={client_id}
                                      // readOnly={AuthButton ? false : true}
                                      required
                                      onChange={(e) =>
                                        setclient_id(e.target.value)
                                      }
                                      className="form-control form-control-solid"
                                      placeholder="Enter Client Id"
                                    />
                                  </div>
                                  <div className="mb-10">
                                    <label className="required form-label">
                                      Client Secret
                                    </label>
                                    <input
                                      type={client_secret ? "password" : ""}
                                      value={client_secret}
                                      required
                                      // readOnly={AuthButton ? false : true}
                                      onChange={(e) =>
                                        setclient_secret(e.target.value)
                                      }
                                      className="form-control form-control-solid"
                                      placeholder="Enter Client Secret"
                                    />
                                  </div>
                                  <div className="row col-md-12">
                                    <div className="col-md-6">
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Business location Id
                                        </label>
                                        <input
                                          type="text"
                                          value={business_id}
                                          required
                                          // readOnly={AuthButton ? false : true}
                                          onChange={(e) =>
                                            setbusiness_id(e.target.value)
                                          }
                                          className="form-control form-control-solid"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Menu Id
                                        </label>
                                        <input
                                          type="text"
                                          value={menu_id}
                                          required
                                          // readOnly={AuthButton ? false : true}
                                          onChange={(e) =>
                                            setmenu_id(e.target.value)
                                          }
                                          className="form-control form-control-solid"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row col-md-12">
                                    <div className="col-md-6">
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          Delivery Account Profile Code
                                        </label>
                                        <input
                                          type="text"
                                          value={delivery_account_profile_code}
                                          // readOnly={AuthButton ? false : true}
                                          onChange={(e) =>
                                            setdelivery_account_profile_code(
                                              e.target.value
                                            )
                                          }
                                          className="form-control form-control-solid"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          Pickup Account Profile Code
                                        </label>
                                        <input
                                          type="text"
                                          value={pickup_account_profile_code}
                                          // readOnly={AuthButton ? false : true}
                                          onChange={(e) =>
                                            setpickup_account_profile_code(
                                              e.target.value
                                            )
                                          }
                                          className="form-control form-control-solid"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row col-md-12">
                                    <div className="col-md-6">
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          Dine-in Account Profile Code
                                        </label>
                                        <input
                                          type="text"
                                          value={dinein_account_profile_code}
                                          // readOnly={AuthButton ? false : true}
                                          onChange={(e) =>
                                            setdinein_account_profile_code(
                                              e.target.value
                                            )
                                          }
                                          className="form-control form-control-solid"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12"> {errorDiv} </div>

                                  <div className="d-flex justify-content-center">
                                    {/* {AuthButton ? ( */}

                                    {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "pos" &&
                                      (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                    )) && (
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Authentication
                                    </button>
                                    )}
                                    {/* ) : (
                                      ""
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="card card-docs mb-2"
                              style={{
                                boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                              }}
                            >
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="mb-10">
                                  <label className="form-label">
                                    Access Token
                                  </label>
                                  <input
                                    type="text"
                                    value={posDataById?.access_token}
                                    className="form-control form-control-solid"
                                    placeholder="Access token"
                                  />
                                </div>
                                <div className="mb-10">
                                  <label className=" form-label">
                                    Last activated date & time
                                  </label>
                                  {!posDataById?.access_token_generation_time ? (
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="last activated date & time"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      value={moment(
                                        posDataById?.access_token_generation_time
                                      ).format("MMMM Do YYYY, h:mm:ss a")}
                                      className="form-control form-control-solid"
                                      placeholder="last activated date & time"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
