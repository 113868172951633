import { CMS } from "../action-types";


const getHeaderList = () => ({
  type: CMS.GET_HEADER_LIST,
});


const setHeaderList = (headerlist) => ({
  type: CMS.SET_HEADER_LIST,
  headerlist,
});


const getFooterList = () => ({
  type: CMS.GET_FOOTER_LIST,
});


const setFooterList = (footerlist) => ({
  type: CMS.SET_FOOTER_LIST,
  footerlist,
});

const getColorDetails = () => ({
      type: CMS.GET_COLOR,
    });
    
    
const setColorDetails = (colordetails) => ({
      type: CMS.SET_COLOR,
      colordetails,
});


export {
      getHeaderList ,setHeaderList ,getFooterList ,setFooterList,
      getColorDetails ,setColorDetails
};
