import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { useParams } from "react-router-dom";
import headers from "../../../../CommonApi/headers";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./PurchersVoucherDetails.css"
import useDrawer from "../../../../context/DrawerContext";
import { getCurrencyLabel } from "../../../Comman/currencyUtils/currencyUtils";

export default function GroupAdminPurchersVoucherDetails() {
  let history = useHistory();

  const { id , currency } = useParams();
  const [user, setuser] = useState("");

  useEffect(() => {
      api
      .get(`/groupadmin/purchase_voucher/purchase-voucher-details/${id}`, { headers })
      .then((res) => {
        const options = res.data.data;
        setuser(options);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }, [id.id]);


  const currencyLabel = getCurrencyLabel(currency);


  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  
  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header pt-5 border-1">
                        <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Voucher Details
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                        </div>
                        <div className="d-flex flex-stack mb-7">
                      </div>
                        <br />
                        <div className="card-body pt-0">
                          <div className="rounded border p-10">
                          <div className="row my-4 col-md-12 mx-2">
                              <div className="col-md-4">
                                   <div className="title"> Customer Name: <span className="Name_">{user ? user.client_name : "No data"} </span></div>
                              </div>
                              <div className="col-md-4">
                                   <div className="title"> Customer Phone Number: <span className="Name_">{user ? user.client_mobile_number : "No data"} </span></div>
                              </div>
                              <div className="col-md-4">
                                   <div className="title"> Customer Email: <span className="Name_">{user ? user.client_email : "No data"} </span></div>
                              </div>
                              </div>

                              <div className="row my-4 col-md-12 mx-2">
                              <div className="col-md-4">
                                   <div className="title"> Branch Name: <span className="Name_">{user ? user.branchName : "No data"} </span></div>
                              </div>
                              <div className="col-md-4">
                                   <div className="title"> Voucher Code: <span className="Name_">{user ? user.voucher_code : "No data"} </span></div>
                              </div>
                              <div className="col-md-4">
                                   <div className="title"> Voucher Amount ({currencyLabel}): <span className="Name_"> {user
                                      ? Number(user.voucher_amount || 0).toFixed(2) 
                                      : "No Data."} </span></div>
                              </div>
                              </div>

                              <div className="row  my-4 col-md-12 mx-2">
                              <div className="col-md-4">
                                   <div className="title"> Purchased Amount (Discount) {currencyLabel}	: <span className="Name_"> {user
                                      ? Number(user.discounted_amount || 0).toFixed(2)
                                      : "No Data."} </span></div>
                              </div>
                              <div className="col-md-4">
                                   <div className="title"> Voucher Remaining Amount ({currencyLabel})	: <span className="Name_">{user
                                      ? Number( user.remaining_amount || 0).toFixed(2) 
                                      : "No Data."} </span></div>
                              </div>
                              <div className="col-md-4">
                                   <div className="title"> Start Date: <span className="Name_"> {user
                                      ? moment(user.start_date).format("DD-MM-YYYY") 
                                      : "No Data."} </span></div>
                              </div> </div>

                              <div className="row my-4 col-md-12 mx-2">
                              <div className="col-md-4">
                                   <div className="title"> End Date: <span className="Name_"> {user
                                      ? moment(user.end_date).format("DD-MM-YYYY") 
                                      : "No Data."}</span></div>
                              </div>
                              {user?.message &&
                              <div className="col-md-8">
                                   <div className="title"> Message: <span className="Name_">{user ? user?.message : "No data"} </span></div>
                              </div>}
                              
                              </div>
                        </div>
                        <br/>
                        <br/>
                        <div>
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Voucher History
                        </h1> 
                        </div>
                        <br/>
                          <div className="rounded border p-10">
                          <table style={{border : "1px solid #dee2e6" }} className="table">
                             <thead style={{fontSize : 20 , color :"#667085" }} className="table-light table-header">
                                              <tr>
                                                <th>Redeemed Amount</th>
                                                <th>Redeemed At</th>
                                                <th>Order No</th>
                                                <th>Redeemed By</th>
                                              </tr>
                                            </thead>
                                            <tbody style={{fontSize : 16 , color:"#101828"}}>
                                            {user && user?.voucher_history?.length > 0 ? (
                                              user?.voucher_history?.map((item, index) => (
                                                <tr key={index} style={{ borderBottom: '1px solid #dee2e6' }}>
                                                  <td style={{ borderBottom: '1px solid #dee2e6' }}>{Number(item?.reedem_amount || 0).toFixed(2)}</td>
                                                  <td style={{ borderBottom: '1px solid #dee2e6' }}>
                                                    {moment.utc(item?.reedemed_at).tz(user?.branchTimeZone).format('h:mm A')},  {moment.utc(item?.reedemed_at).tz(user?.branchTimeZone).format('DD-MM-YYYY')} 
                                            
                                                    {/* {moment(item?.reedemed_at).format('h:mm A')},   */}
                                                    {/* {moment(item?.reedemed_at).format('DD-MM-YYYY')} */}
                                                    </td>
                                                  <td style={{ borderBottom: '1px solid #dee2e6' }}>{item?.order_no ? item?.order_no : "---"}</td>
                                                  <td style={{ borderBottom: '1px solid #dee2e6' }}>{`${item?.reedem_by ? item?.reedem_by : ""} (${item?.remark})`}</td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan="4" style={{ textAlign: "center" }}>No voucher history.</td>
                                              </tr>
                                            )}
                                      </tbody>
                            </table>
                            </div>
                            </div>                         
                        <br />
                       
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
