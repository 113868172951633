import { combineReducers } from "@reduxjs/toolkit";
import branchDetailsReducer from "./branch-details";
import branchNameReducer from "./branch-name";
import branchListReducer from "./branch-list";
import branchDetaislByIDReducer from "./branchdetails-id";

const branchReducer = combineReducers({
  branchID: branchNameReducer,
  branchData: branchDetailsReducer,
  branchList : branchListReducer, 
  branchDetaislByID : branchDetaislByIDReducer
});

export { branchReducer };
