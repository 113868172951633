import React from 'react';
import { Nav } from 'react-bootstrap';

const tabItems = [
    { eventKey: "link-1", label: "Today" },
    { eventKey: "link-2", label: "Yesterday" },
    { eventKey: "link-3", label: "Week" },
    { eventKey: "link-4", label: "Current Month" },
    { eventKey: "link-5", label: "Last Month" },
    { eventKey: "link-6", label: "Current Year" },
    { eventKey: "link-7", label: "From - To" },
];

const TabNavigation2 = ({ handleTabClick ,handleTabClickShow}) => {



    return (
        <>
            <Nav variant="tabs" 
            // defaultActiveKey={storedNavTab ? storedNavTab : "link-1"} 
            defaultActiveKey={"link-1"} 
            className="custom-nav">
                {tabItems?.map((item) => (
                    <Nav.Item key={item.eventKey}>
                        <Nav.Link
                            eventKey={item.eventKey}
                            onClick={() => {
                                    handleTabClick(item.label);
                                    handleTabClickShow(item.label)
                                }
                            }
                        >
                            {item.label}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </>
    );
};

export default TabNavigation2;
