import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { LANGUAGEALL, LANGUAGERES, setLanguageAllList, setLanguageResList, } from "../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* callLanguageAllList() { 
  try {
      const response = yield call(api.get, "/groupadmin/lang/all" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setLanguageAllList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: LANGUAGEALL.CLEAR_LANGUAGEALL_LIST });
        toast(error?.response?.data?.error?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
      }
    }
}


function* callLanguageResList() { 
  try {
      const response = yield call(api.get, "/groupadmin/lang" , { headers: headers });
      const tableData = response?.data?.data?.length > 0 ? response?.data?.data[0]?.lang?.map((main)=>main?._id) : [] 
      yield put(setLanguageResList(tableData));
    } catch (error) {
      if (error.response) {
        yield put({ type: LANGUAGERES.CLEAR_LANGUAGERES_LIST });
        toast(error?.response?.data?.error?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
      }
    }
}


export function* watchLanguageAllList() {
  yield takeEvery(LANGUAGEALL.GET_LANGUAGEALL_LIST, callLanguageAllList);
}


export function* watchLanguageResList() {
  yield takeEvery(LANGUAGERES.GET_LANGUAGERES_LIST, callLanguageResList);
}
