import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import $ from "jquery";
import MaterialTable from "material-table";
import headers from "../../../../../CommonApi/headers";
import { ActionIcon, useStyles } from "../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../Comman/TableOption";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "./UserManagmentCommon";
import useDrawer from "../../../../../context/DrawerContext";
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import { Chip, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import AddIcon from '@mui/icons-material/Add';
import { DeleteRegular } from '@fluentui/react-icons';


window.jquery = window.$ = $;


function GroupAdminUserRoleManagment() {

  let history = useHistory();
  const [user, setUser] = useState([])

  const classes = useStyles();

  const columns = [
    {
      title: "Role Name",
      field: "name",
      render: (rowData) => {
        return <div
          style={{ color: "#0037F8", cursor: "pointer" }}
          onClick={() => history.push(`/group_admin/settings/roles/view_role/${rowData?._id}`)}
        >{rowData.name}</div>;
      },
      customSort: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: "Created On",
      field: "created_at",
      render: (rowData) => {
        return `${moment(rowData.created_at).format('DD/MM/YY')}, ${moment(rowData.created_at).format('LT')}`
      }
    },
    {
      title: "Last Active",
      field: "created_at",
      render: (rowData) => {
        return `${moment(rowData.created_at).format('DD/MM/YY')}, ${moment(rowData.created_at).format('LT')}`
      }
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };



  useEffect(() => {
    UserDetailsGet();
  }, []);

  function UserDetailsGet() {
    api.get(`/groupadmin/user/getroles`, { headers }).then((res) => {
      const tableData = res.data.data;
      setUser(tableData);
    });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  function deleteRole(_id) {
    setAnchorEl(null);
    setSelectedRow(null);
    deleteCommonFunction(`/groupadmin/role/${_id}`,
      "Role has been deleted successfully.",
      () => {
        UserDetailsGet();
        setAnchorEl(null);
        setSelectedRow(null);
      });
  }

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          User Management - Roles List
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                        <button
                          className="btn btn-primary me-3"
                          onClick={() =>
                            history.push("/group_admin/settings/roles/add_roles")
                          }
                        >
                         <AddIcon className="me-2"/>  Add Role
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            history.push("/group_admin/settings/user_management")
                          }
                        >
                          Go Back
                        </button>
                      </div>
                    </div>

                    <div className="rounded border p-5">
                      <UserManagmentCommon />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className={classes.root}>
                            <MaterialTable
                              title={<div style={{ fontSize: "18px", color: "#101828", fontWeight: 500, letterSpacing: "0.3px" }}>
                                <span className="me-3">Roles</span>
                                <Chip style={{ color: "#DF3223", background: "#FEF3F2", fontSize: 12, fontWeight: 500, letterSpacing: "0.3px" }} label={`${user.length} Records`} />
                              </div>}
                              data={user}
                              columns={columns}
                              options={{
                                ...Tableoptions, // Merge existing options
                                maxBodyHeight: '410px', // Add maxBodyHeight to the merged options
                              }}
                              actions={[
                                {
                                  icon: () => <ActionIcon />,
                                  tooltip: 'More',
                                  onClick: (event, rowData) => handleMenuOpen(event, rowData)
                                }
                              ]}
                            />

                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              className="rounded border border-2"
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}

                            >
                              <MenuItem className="py-2 px-4" onClick={() => deleteRole(selectedRow._id)} style={{ display: 'flex', flexDirection: 'row' }}>
                                <ListItemIcon>
                                <DeleteRegular style={{fontSize : "21px" , color: '#D92D20'}} />
                                </ListItemIcon>
                                <ListItemText
                                  primary={<span style={{ fontSize: '14px', color: '#D92D20', fontFamily: 'inherit', fontWeight: 500 }}>Delete Role</span>}
                                />
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}



export default GroupAdminUserRoleManagment;

