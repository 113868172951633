import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminTourInvoiceGenerateTourDetails() {
  $(document).ready(function () {
    $(".phone").keypress(function (eve) {
      var allowedCharacters = /[0-9+\-.()]/;
      var inputValue = String.fromCharCode(eve.which);

      if (!allowedCharacters.test(inputValue)) {
        eve.preventDefault();
      }

      $(".phone").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });

    $(".number").keypress(function (eve) {
      var inputValue = String.fromCharCode(eve.which);
      if (!/^\d+$/.test(inputValue)) {
        eve.preventDefault();
      }
    });

    $(".decimal").keypress(function (eve) {
      var inputValue = String.fromCharCode(eve.which);
      if (!/^\d+$/.test(inputValue) && inputValue != ".") {
        eve.preventDefault();
      }
    });
  });

  useEffect(() => {
    AllList();
  }, []);

  let history = useHistory();
  const date = new Date();

  // Get the next month's date
  const nextMonthDate = new Date(date);
  nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

  // Format the dates as ISO strings
  const todayDate = date.toISOString().split("T")[0];
  const dueDate = nextMonthDate.toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    tourids: [],
    currency: "",
    logo_url: "",
    invoice_no: "",
    invoice_no_with_prefix: "",
    invoice_header: "",
    invoice_footer_company: "",
    invoice_footer_payment: "",
    invoice_date: todayDate,
    invoice_due_date: dueDate,
    notes: "",
    total_gross: 0.0,
    total_vat: 0.0,
    total_net: 0.0,
    companyinfo: {},
    clientinfo: {},
    invoicedata: [],
  });

  const [vatList, setVatList] = useState([]);
  // const [tourids, setTourids] = useState([]);

  async function AllList() {
    try {
      const touridsString = localStorage.getItem("tourids"); // Retrieve tourids as a string

      const touridsArray = touridsString.split(",");

      const tourData = await api.post(
        `/groupadmin/tourgroup/invoice_generate`,
        { tourids: touridsArray },
        { headers }
      );
      const TourDetails = tourData?.data?.data;

      const tnet = Number(TourDetails.total_gross) - Number(formData.total_vat);

      // Incrementing id field by one for each item in invoicedata
      const IdupdatedInvoicedata = TourDetails.invoicedata.map(
        (item, index) => ({
          ...item,
          id: index + 1, // Increment id by 1 based on the index
        })
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        tourids: TourDetails.tourids,
        currency: TourDetails.currency,
        logo_url: TourDetails.logo_url,
        invoice_no: TourDetails.invoice_no,
        invoice_no_with_prefix: TourDetails.invoice_no_with_prefix,
        invoice_header: TourDetails.invoice_header,
        invoice_footer_company: TourDetails.invoice_footer_company,
        invoice_footer_payment: TourDetails.invoice_footer_payment,
        companyinfo: TourDetails.companyinfo,
        clientinfo: TourDetails.clientinfo,
        invoicedata: IdupdatedInvoicedata,
        total_gross: TourDetails.total_gross,
        total_net: tnet.toFixed(2),
      }));
    } catch (err) {}

    try {
      const response = await api.post(
        "/groupadmin/tourgroup/list_vat",
        {},
        { headers }
      ); // Adjust the endpoint URL as per your API
      const vatListData = response?.data?.data; // Assuming the response contains an array of VAT data
      setVatList(vatListData); // Assuming you have a state variable to store the VAT list
    } catch (error) {
      console.error("Error fetching VAT list:", error);
    }
  }

  const handleInputChange = (fieldName, value) => {
    if (fieldName === "invoice_date") {
      const nextMonthDate = moment(value).add(1, "months").format("YYYY-MM-DD");
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
        invoice_due_date: nextMonthDate,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleDeleteRow = (index, event) => {
    // Prevent default form submission
    event.preventDefault();

    const updatedInvoicedata = formData.invoicedata.filter(
      (data, dataIndex) => dataIndex !== index
    );
    updatedInvoicedata.forEach((row, rowIndex) => {
      const { vat, total_price } = row;
      const vatPrice =
        (Number(vat) * Number(total_price)) / (100 + Number(vat));
      updatedInvoicedata[rowIndex].vat_price = vatPrice.toFixed(2);
    });

    // Calculate total_gross
    const totalGross = updatedInvoicedata
      .reduce((acc, curr) => acc + parseFloat(curr.total_price || 0), 0)
      .toFixed(2); // Treat empty values as 0 and round to 2 decimal places
    const totalvat = updatedInvoicedata
      .reduce((acc, curr) => acc + parseFloat(curr.vat_price || 0), 0)
      .toFixed(2);

    // Calculate total_net
    const totalNet = (totalGross - totalvat).toFixed(2);

    setFormData((prevState) => ({
      ...prevState,
      invoicedata: updatedInvoicedata,
      total_gross: totalGross,
      total_net: totalNet,
      total_vat: totalvat,
    }));
  };

  // const handleSurcharge = (index, event) => {
  //     // Prevent default form submission
  //     event.preventDefault();

  //     const selectedRowData = formData.invoicedata[index];
  //     const updatedInvoicedata = [...formData.invoicedata];

  //     // Remove Surcharge button and add Deleted button
  //     const copiedRowData = { ...selectedRowData, surcharge: true };
  //     updatedInvoicedata.push(copiedRowData);
  //     updatedInvoicedata[updatedInvoicedata.length - 1].unit_price = '0.00'
  //     updatedInvoicedata[updatedInvoicedata.length - 1].total_price = '0.00'
  //     setFormData(prevState => ({
  //         ...prevState,
  //         invoicedata: updatedInvoicedata
  //     }));
  // };

  const handleSurcharge = (index, id, event) => {
    // Prevent default form submission
    event.preventDefault();

    const selectedRowData = formData.invoicedata[index];
    const updatedInvoicedata = [...formData.invoicedata];

    // Find the index of the last occurrence of the given id
    let lastIndexOfId = -1;
    for (let i = updatedInvoicedata.length - 1; i >= 0; i--) {
      if (updatedInvoicedata[i].id === id) {
        lastIndexOfId = i;
        break;
      }
    }

    // If found, push the new data after that position
    if (lastIndexOfId !== -1) {
      const copiedRowData1 = { ...selectedRowData, surcharge: true };
      const copiedRowData2 = {
        ...copiedRowData1,
        surcharge_text: "Service Surcharge",
      };
      updatedInvoicedata.splice(lastIndexOfId + 1, 0, copiedRowData2);
      updatedInvoicedata[lastIndexOfId + 1].unit_price = "0.00";
      updatedInvoicedata[lastIndexOfId + 1].total_price = "0.00";
      updatedInvoicedata[lastIndexOfId + 1].vat = 0;

      updatedInvoicedata.forEach((row, rowIndex) => {
        const { vat, total_price } = row;
        updatedInvoicedata[rowIndex].vat = vat;
        const vatPrice =
          (Number(vat) * Number(total_price)) / (100 + Number(vat));
        updatedInvoicedata[rowIndex].vat_price = vatPrice.toFixed(2);
      });

      console.log("ss", updatedInvoicedata);

      // Calculate total_gross
      const totalGross = updatedInvoicedata
        .reduce((acc, curr) => acc + parseFloat(curr.total_price || 0), 0)
        .toFixed(2); // Treat empty values as 0 and round to 2 decimal places
      const totalvat = updatedInvoicedata
        .reduce((acc, curr) => acc + parseFloat(curr.vat_price || 0), 0)
        .toFixed(2);

      // Calculate total_net
      const totalNet = (totalGross - totalvat).toFixed(2);

      setFormData((prevState) => ({
        ...prevState,
        invoicedata: updatedInvoicedata,
        total_gross: totalGross,
        total_net: totalNet,
        total_vat: totalvat,
      }));
    }
  };

  const handleTotalPriceChange = (index, event) => {
    const { value } = event.target;
    // const roundedValue = parseFloat(value || 0).toFixed(2); // Treat empty value as 0 and round to 2 decimal places
    const updatedInvoicedata = [...formData.invoicedata];
    updatedInvoicedata[index].unit_price = value;
    updatedInvoicedata[index].total_price = value;

    updatedInvoicedata.forEach((row, rowIndex) => {
      const { vat, total_price } = row;
      const vatPrice =
        (Number(vat) * Number(total_price)) / (100 + Number(vat));
      updatedInvoicedata[rowIndex].vat_price = vatPrice.toFixed(2);
    });

    // Calculate total_gross
    const totalGross = updatedInvoicedata
      .reduce((acc, curr) => acc + parseFloat(curr.total_price || 0), 0)
      .toFixed(2); // Treat empty values as 0 and round to 2 decimal places
    const totalvat = updatedInvoicedata
      .reduce((acc, curr) => acc + parseFloat(curr.vat_price || 0), 0)
      .toFixed(2);

    // Calculate total_net
    const totalNet = (totalGross - totalvat).toFixed(2);

    setFormData((prevState) => ({
      ...prevState,
      invoicedata: updatedInvoicedata,
      total_gross: totalGross,
      total_net: totalNet,
      total_vat: totalvat,
    }));
  };

  const handleVatChange = (index, selectedVat) => {
    const newVat = Number(selectedVat).toFixed(2);
    const updatedInvoicedata = formData.invoicedata.map((data, dataIndex) => {
      if (index === dataIndex) {
        return { ...data, vat: selectedVat }; // Update the VAT value of the selected row
      }
      return data;
    });

    updatedInvoicedata.forEach((row, rowIndex) => {
      const { vat, total_price } = row;
      const vatPrice =
        (Number(vat) * Number(total_price)) / (100 + Number(vat));
      updatedInvoicedata[rowIndex].vat_price = vatPrice.toFixed(2); // Round to 2 decimal places
    });

    // Recalculate total_gross
    const totalGross = updatedInvoicedata
      .reduce((acc, curr) => acc + parseFloat(curr.total_price), 0)
      .toFixed(2); // Round to 2 decimal places
    // Recalculate total_vat
    const totalvat = updatedInvoicedata
      .reduce((acc, curr) => acc + parseFloat(curr.vat_price), 0)
      .toFixed(2); // Round to 2 decimal places

    // Recalculate total_net
    const totalNet = (totalGross - totalvat).toFixed(2); // Round to 2 decimal places

    // Update formData with the updated invoicedata and totals
    setFormData((prevState) => ({
      ...prevState,
      invoicedata: updatedInvoicedata,
      total_gross: totalGross,
      total_net: totalNet,
      total_vat: totalvat,
    }));
  };

  const handleSurchargeChange = (index, dataText) => {
    const updatedInvoicedata = formData.invoicedata.map((data, dataIndex) => {
      if (index === dataIndex) {
        return { ...data, surcharge_text: dataText }; // Update the VAT value of the selected row
      }
      return data;
    });

    setFormData((prevState) => ({
      ...prevState,
      invoicedata: updatedInvoicedata,
    }));
  };

  const [errorForm, setErrorForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const errorDiv = apiError ? (
    <div className="text-center alert alert-danger">{apiError}</div>
  ) : (
    ""
  );

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErrorForm(null);

    // Check for validation errors for each field
    const errors = {};

    // Update formErrors state with individual field errors
    setErrorForm(errors);

    // If there are any errors, prevent form submission
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      return;
    }

    Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    api
      .post(`/groupadmin/tourgroup/invoice_save`, formData, { headers })
      .then((res) => {
        // $(".loader").hide();
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Invoice has been created succesfully.",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          setLoading(false);
          history.push("/group_admin/tour");
          // window.location.href = '/group_admin/tour/list';
        });
        // Redirect to the CompanyList page
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          Swal.close();
          toast.error(err?.response?.data?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" pt-0 d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="pb-10">
                    <div className="d-flex flex-stack mb-7">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder mb-0"
                          id="custom-form-control"
                        >
                          Tour Detail
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-10 px-15 py-lg-10 px-lg-15 text-gray-700">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-12 pb-10 pb-lg-10 text-center">
                                <div className="invoice-header-logo">
                                  {formData.logo_url !== "" && (
                                    <img
                                      src={formData.logo_url}
                                      style={{ width: "25%" }}
                                      alt="Invoice Logo"
                                    />
                                  )}
                                </div>
                                <div className="invoice-header-content mt-8">
                                  {formData.invoice_header && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: formData.invoice_header,
                                      }}
                                    ></div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="col-12 py-10 py-lg-10 "
                                style={{
                                  borderTop: "2px solid #D0D5DD",
                                  borderBottom: "2px solid #D0D5DD",
                                }}
                              >
                                <div className="row">
                                  <h2
                                    className="fs-1"
                                    style={{ color: "#2F3592" }}
                                  >
                                    Invoice
                                  </h2>
                                  <div className="mb-10 col-md-2 col-sm-4 col-12">
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Date"
                                      required
                                      name="invoice_date"
                                      value={formData.invoice_date}
                                      min={todayDate}
                                      onChange={(e) =>
                                        handleInputChange(
                                          "invoice_date",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <h3
                                      className="fs-4"
                                      style={{ color: "#344054" }}
                                    >
                                      Invoice for
                                    </h3>
                                    {formData.clientinfo.client_name && (
                                      <h3
                                        className="text-gray-600 pb-1 fs-4"
                                        style={{ fontWeight: 500 }}
                                      >
                                        {formData.clientinfo.client_name}
                                      </h3>
                                    )}
                                    {formData.clientinfo.address1 && (
                                      <h3
                                        className="text-gray-600 pb-1 fs-4"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {formData.clientinfo.address1}
                                      </h3>
                                    )}
                                    {formData.clientinfo.address2 && (
                                      <h3
                                        className="text-gray-600 pb-1 fs-4"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {formData.clientinfo.address2}
                                      </h3>
                                    )}
                                    {formData.clientinfo.address3 && (
                                      <h3
                                        className="text-gray-600 pb-1 fs-4"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {formData.clientinfo.address3}
                                      </h3>
                                    )}
                                    {formData.clientinfo.email && (
                                      <h3
                                        className="text-gray-600 pb-1 fs-4"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {formData.clientinfo.email}
                                      </h3>
                                    )}
                                    {formData.clientinfo.mobile && (
                                      <h3
                                        className="text-gray-600 pb-1 fs-4"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {formData.clientinfo.mobile}
                                      </h3>
                                    )}
                                  </div>
                                  <div className="col-12 col-md-6 text-end">
                                    <div className="mb-15">
                                      <h3
                                        className="fs-4"
                                        style={{ color: "#344054" }}
                                      >
                                        Invoice #
                                      </h3>
                                      {formData.invoice_no_with_prefix && (
                                        <h3
                                          className="text-gray-600 pb-1 fs-4"
                                          style={{ fontWeight: 400 }}
                                        >
                                          {formData.invoice_no_with_prefix}
                                        </h3>
                                      )}
                                    </div>
                                    <div className="due-date">
                                      <h3
                                        className="fs-4"
                                        style={{ color: "#344054" }}
                                      >
                                        Due Date
                                      </h3>
                                      {formData.invoice_due_date && (
                                        <h3
                                          className="text-gray-600 pb-1 fs-4"
                                          style={{ fontWeight: 400 }}
                                        >
                                          {moment(
                                            formData.invoice_due_date
                                          ).format("DD/MM/YYYY")}
                                        </h3>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 py-10 py-lg-10 ">
                                <div className="table-responsive">
                                  <table
                                    className="table table-striped"
                                    style={{ width: "100%" }}
                                  >
                                    <thead
                                      className="border-bottom border-top"
                                      style={{ borderColor: "#EAECF0" }}
                                    >
                                      <tr
                                        style={{
                                          fontSize: "16px",
                                          color: "#2A3990",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <td>Group Reference</td>
                                        <td>Date</td>
                                        <td>Location</td>
                                        <td>Meal</td>
                                        <td>VAT</td>

                                        <td>Pax</td>

                                        <td>Type of Pax</td>
                                        <td>Unit Price</td>
                                        <td>Total Price</td>
                                        <td>Actions</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formData.invoicedata.map(
                                        (data, index) => (
                                          <tr
                                            key={index}
                                            className="border-bottom"
                                            style={{ borderColor: "#EAECF0" }}
                                          >
                                            {!data.surcharge ? (
                                              <>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.reference}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {moment(
                                                    data.tour_date
                                                  ).format("DD/MM/YYYY")}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.dinning_location}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.meal_name}
                                                </td>
                                                <td>
                                                  <select
                                                    value={data.vat}
                                                    onChange={(event) =>
                                                      handleVatChange(
                                                        index,
                                                        event.target.value
                                                      )
                                                    }
                                                    className="form-select"
                                                    required
                                                  >
                                                    <option
                                                      key={index}
                                                      value="0.00"
                                                    >
                                                      0.00
                                                    </option>
                                                    {vatList.map(
                                                      (vat, index) => (
                                                        <option
                                                          key={index}
                                                          value={vat.vat}
                                                        >
                                                          {vat.vat}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.pax}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.typeofpax}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.currency}&nbsp;
                                                  {data.unit_price}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.currency}&nbsp;
                                                  {data.total_price}
                                                </td>
                                                <td>
                                                  <button
                                                    onClick={(event) =>
                                                      handleSurcharge(
                                                        index,
                                                        data.id,
                                                        event
                                                      )
                                                    }
                                                    className="btn btn-sm btn-outline btn-outline-secondary text-dark"
                                                    style={{
                                                      borderWidth: "2px",
                                                      borderColor: "#EAECF0",
                                                    }}
                                                  >
                                                    Surcharge
                                                  </button>
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Surcharge"
                                                    required
                                                    name="surcharge_text"
                                                    value={data.surcharge_text}
                                                    onChange={(e) =>
                                                      handleSurchargeChange(
                                                        index,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {moment(
                                                    data.tour_date
                                                  ).format("DD/MM/YYYY")}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.dinning_location}
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.meal_name}
                                                </td>
                                                <td>
                                                  <select
                                                    value={data.vat}
                                                    onChange={(event) =>
                                                      handleVatChange(
                                                        index,
                                                        event.target.value
                                                      )
                                                    }
                                                    className="form-select"
                                                    required
                                                  >
                                                    <option
                                                      key={index}
                                                      value="0.00"
                                                    >
                                                      0.00
                                                    </option>
                                                    {vatList.map(
                                                      (vat, index) => (
                                                        <option
                                                          key={index}
                                                          value={vat.vat}
                                                        >
                                                          {vat.vat}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                ></td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                ></td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    className="decimal form-control"
                                                    placeholder="Total Price"
                                                    name="total_price"
                                                    value={data.total_price}
                                                    style={{ width: "85px" }}
                                                    onChange={(event) =>
                                                      handleTotalPriceChange(
                                                        index,
                                                        event
                                                      )
                                                    }
                                                    required
                                                  />
                                                </td>
                                                <td
                                                  style={{
                                                    paddingRight: "15px",
                                                  }}
                                                >
                                                  {data.currency}&nbsp;
                                                  {data.total_price}
                                                </td>
                                                <td>
                                                  <button
                                                    onClick={(event) =>
                                                      handleDeleteRow(
                                                        index,
                                                        event
                                                      )
                                                    }
                                                    className="btn btn-sm btn-outline btn-outline-secondary text-danger"
                                                  >
                                                    Delete
                                                  </button>
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-12 pb-10 pb-lg-10 ">
                                <table
                                  class="table table-bordered"
                                  style={{ width: "100%" }}
                                >
                                  <tbody>
                                    <tr
                                      className="border-2"
                                      style={{ borderColor: "#D0D5DD" }}
                                    >
                                      <td rowspan="3" style={{ width: "50%" }}>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          placeholder="Notes"
                                          value={formData.notes}
                                          onChange={(e) =>
                                            handleInputChange(
                                              "notes",
                                              e.target.value
                                            )
                                          }
                                          rows="5"
                                          name="notes"
                                        ></textarea>
                                      </td>
                                      <td
                                        className="text-end"
                                        style={{
                                          width: "30%",
                                          fontSize: "16px",
                                          color: "#2A3990",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Gross
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        {formData.total_gross}
                                      </td>
                                    </tr>
                                    <tr
                                      className="border-2"
                                      style={{ borderColor: "#D0D5DD" }}
                                    >
                                      <td
                                        className="text-end"
                                        style={{
                                          fontSize: "16px",
                                          color: "#2A3990",
                                          fontWeight: 500,
                                        }}
                                      >
                                        VAT
                                      </td>
                                      <td>{formData.total_vat}</td>
                                    </tr>
                                    <tr
                                      className="border-2"
                                      style={{ borderColor: "#D0D5DD" }}
                                    >
                                      <td
                                        className="text-end"
                                        style={{
                                          fontSize: "16px",
                                          color: "#2A3990",
                                          fontWeight: 500,
                                        }}
                                      >
                                        NET
                                      </td>
                                      <td>{formData.total_net}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-12 pb-10 pb-lg-10 ">
                                <div className="row mx-1">
                                  <div className="col-12 col-md-6 px-0 border border-dark border-2 border-end-0">
                                    <h3
                                      className="mb-0 py-2 px-2 fs-4 "
                                      style={{
                                        fontWeight: 400,
                                        background: "#f0f8ff",
                                      }}
                                    >
                                      Company Details
                                    </h3>
                                    <div className="px-2 py-2 border-top border-2 border-dark">
                                      {formData.invoice_footer_company && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              formData.invoice_footer_company,
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 px-0 border border-dark border-2">
                                    <h3
                                      className="mb-0 py-2 px-2 fs-4"
                                      style={{
                                        fontWeight: 400,
                                        background: "#f0f8ff",
                                      }}
                                    >
                                      Payment Details
                                    </h3>
                                    <div className="px-2 py-2 border-top border-2 border-dark">
                                      {formData.invoice_footer_payment && (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              formData.invoice_footer_payment,
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-12 py-10 py-lg-10 text-center"
                                style={{ borderTop: "2px solid #D0D5DD" }}
                              >
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary me-4"
                                  disabled={loading}
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  onClick={() => history.goBack()}
                                  id="kt_modal_new_max_discount_value_submit"
                                  className="btn btn-danger"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
