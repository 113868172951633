import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData } from "../../../../Comman/BranchList";
import useDrawer from "../../../../../context/DrawerContext";

function GroupAdminPOS({ getBranchList, BranchList_ }) {
  const [branchId, setBranchId] = useState("");
  const [branchId2, setBranchId2] = useState([]);
  const [posData, setPosData] = useState([]);
  const [posDataById, setPosDataById] = useState("");
  const [posStatusActive, setPosStatusActive] = useState("");

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  useEffect(() => {
    getBranchList();
    fetchPosList();
  }, []);

  useEffect(() => {
    if (branchId) fetchPosListById();
  }, [branchId]);

  const filteredOptions = branchfilterAndMapData(BranchList_);

  const fetchPosList = async () => {
    try {
      const res = await api.get("/groupadmin/POS_Types", { headers });
      setPosData(res.data.data || []);
    } catch (err) {
      console.error(err.response?.data);
    }
  };

  const fetchPosListById = async () => {
    try {
      const res = await api.get(`/groupadmin/branch/posType/${branchId}`, { headers });
      const { pos_id, pos_status_active } = res.data.data;
      setPosDataById(pos_id);
      setPosStatusActive(pos_status_active);
    } catch (err) {
      setPosDataById("");
      setPosStatusActive("");
      toast(err.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleBranchChange = (opt) => {
    setBranchId(opt.value);
    setBranchId2(opt);
  };

  const handleLinkClick = (e) => {
    if (!branchId) {
      e.preventDefault();
      Swal.fire({
        icon: "warning",
        title: "Please Select Branch",
      });
    }
  };

  const renderPosCards = () =>
    posData.map((main, index) => (
      <div className="col-sm-4" key={index}>
         <Link
          to={{
            pathname: main?.name === "G Series"
              ? "/group_admin/settings/G_Series/pos/pos_settings/auth"
              : "/group_admin/settings/K_Series/pos/pos_settings/auth",
            state: { branchID: branchId, POSID: main._id },
          }}
          onClick={handleLinkClick}
          className="card bg-white hoverable mb-xl-8"
          style={{ boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)" }}
        >
          <div
            className="card-body p-2"
            style={{ backgroundColor: "white" }}
          >
            <center>
              <div className="my-3">
                <img src={main?.image_url} alt="poslogo" style={{ height: "50px", width: "165px" }} />
              </div>
              <div className="mb-2">
                <h2 className="fw-normal">{main.name}</h2>
              </div>
              <div className="text-gray fw-bolder fs-2 mb-2 mt-5">
                {posDataById &&
                  <div className="text-gray fw-bolder fs-2 mb-2 mt-5">
                    <button className={`btn ${posDataById === main._id ? 'btn-success' : 'btn-primary'} status`}>
                      {posDataById === main._id ? 'Active' : 'Inactive'}
                    </button>
                  </div>}
              </div>
            </center>
          </div>
        </Link>
      </div>
    ));

  return (
    <React.Fragment>
      <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">Settings</h1>
                    <hr />
                    <div className="row col-md-12 mb-7">
                      <div className="col-md-9" data-kt-docs-table-toolbar="base">
                        <h3 className="anchor fw-bolder mb-5 d-inline" id="custom-form-control">POS</h3>
                      </div>
                      <div className="col-md-3" data-kt-docs-table-toolbar="base">
                        <Select
                          className="search-line"
                          placeholder="Search..."
                          options={filteredOptions}
                          value={branchId2}
                          onChange={handleBranchChange}
                        />
                      </div>
                    </div>
                    <div className="rounded border p-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="py-5">
                            <div id="kt_content_container" className="container-xxl">
                              <div className="row g-5 g-xl-8 justify-content-center">
                                {branchId ? renderPosCards() : renderPosCards()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = {
  getBranchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPOS);
