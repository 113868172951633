import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: "0px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0);",
    },
    "& .MuiTableSortLabel-icon ": {
      opacity: 1,
    },
  },

}));


export const  ActionIcon = () => {
  return (
      <button
          className="btn btn-outline btn-outline-secondary px-2 py-1"

      >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.28149 4.7998C2.42233 4.7998 1.96315 5.81172 2.52891 6.45831L6.94637 11.5068C7.50415 12.1443 8.49581 12.1443 9.05359 11.5068L13.4711 6.45831C14.0368 5.81173 13.5776 4.7998 12.7185 4.7998H3.28149Z" fill="#667085" />
          </svg>
      </button>
  );
};
