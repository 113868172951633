import React from 'react';
import { useHistory } from 'react-router-dom';

const Addnewdetails = ({url ,Name}) => {
      const history = useHistory();
      return (
            <button
                  className="btn btn-primary"
                  type='button'
                  onClick={() =>
                        history.push(url)
                  }
            >
                  {Name}
            </button>
      );
};

export default Addnewdetails;
