import React from 'react'
import { useLocation ,Link} from 'react-router-dom/cjs/react-router-dom.min';

export default function CommonPage() {

let location = useLocation();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];
  return (
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/menu_management/product/add_new_product",
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "add_new_product"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Information
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/menu_management/menu_products/product_details_discriptaion",
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "product_details_discriptaion"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Description
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/menu_management/menu_products/product_details_time_filter",
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "product_details_time_filter"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Time Filter
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/menu_management/menu_products/options",
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "options"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Options
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/menu_management/menu_products/additional_products",
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "additional_products"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Additional Products
        </Link>
      </li>
      {/* <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/menu_management/menu_products/recipe",
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "recipe"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Recipe
        </Link>
      </li> */}
      <li className="nav-item">
        <Link
          to={{
            pathname:
              `/group_admin/menu_management/menu_products/stock`,
            state: {
              branchID: location?.state?.branchID,
              productID: location?.state?.productID,
              menuId: location?.state?.menuId,
              category_id: location?.state?.category_id,
            },
          }}
          className={
            url === "stock"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Stock
        </Link>
      </li>
    </ul>
  )
}
