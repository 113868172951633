import { BRANCH } from "./action-types";

const setBranch = (branch) => ({
  type: BRANCH.SETBRANCH,
  branch,
});

const setBranchDetail = (data) => ({
  type: BRANCH.SETBRANCHDETAIL,
  data,
});

export { setBranch, setBranchDetail };
