import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import headers from '../../../../CommonApi/headers';
import api from '../../../../CommonApi/axios';

const deleteOrder = async (_id, OrdermanagementList, pagination, fetchData1 ,fetchData2 ,fetchData3 ,fetchData4) => {
  try {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (confirmResult.isConfirmed) {
      const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Provide a valid reason for deleting this order',
        inputPlaceholder: 'Type your message here...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason';
          }
        },
      });

      if (text) {
        await api.delete(`/groupadmin/order/${_id}`, { headers, data: { delete_note: text } }); 
         fetchData1();
        // await fetchData2();
         fetchData3();
         fetchData4();
         OrdermanagementList(pagination.pageIndex, pagination.pageSize);
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Order has been successfully deleted.",
          showConfirmButton: false,
          timer: 1500,
        });
       
      }
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.error.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};

export default deleteOrder;
