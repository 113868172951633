import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Link, useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import NoteState from "../CommanBranch/NoteState";
import { connect } from "react-redux";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Swal from "sweetalert2";
import $ from "jquery";
import "./Dine.css";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import CommonDinein from "./CommonDinein";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
window.jquery = window.$ = $;

function GroupAdminDinein(props) {
  const { branchID } = props;

  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var minDate = year + "-" + month + "-" + day;

    $("#txtDate").attr("min", minDate);
    $("#txtDate1").attr("min", minDate);
  });

  ///////////////////////// filterme //////////////////////////

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  useEffect(() => {
    if (branchID != null) {
      groupAdminDineInList();
      groupAdminDineInHolidayList();
    }
    setForm([
      {
        Week: "",
        start_time: "",
        end_time: "",
        prep_time: "",
      },
    ])
  }, [branchID]);

  ////////////////////////// Pickup Order List /////////////////////

  const [dineInList, setdineInList] = useState([]);
  const [errorListDe, setErrorListDl] = useState(null);
  const errorDivLis = errorListDe ? (
    <div className="card shadow-sm">
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="col-md-12">
          <a>
            <b style={{ color: "#000000", fontSize: "20px" }}>
              {" "}
              {errorListDe}{" "}
            </b>
            <span></span>
          </a>
        </div>
      </div>
    </div>
  ) : (
    ""
  );

  function groupAdminDineInList() {
    setErrorListDl(null);

    api
      .get(`/groupadmin/settings/order_time/dineIn/${branchID}`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setErrorListDl(null);
        setdineInList(tableData);
      })
      .catch((err) => {
        if (err.response) {
          setErrorListDl("No Data found.");
          setdineInList([]);
        }
      });
  }

  ////////////////////////// Pickup Order List /////////////////////

  ////////////////////////// Pickup Holiday Order List /////////////////////

  const [DineInHolidayList, setDineInHolidayList] = useState([]);
  const [errorListHo, setErrorListHo] = useState(null);
  const errorDivLisHo = errorListHo ? (
    <div className="card shadow-sm">
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="col-md-12">
          <a>
            <b style={{ color: "#000000", fontSize: "20px" }}>
              {" "}
              {errorListHo}{" "}
            </b>
            <span></span>
          </a>
        </div>
      </div>
    </div>
  ) : (
    ""
  );

  function groupAdminDineInHolidayList() {
    setErrorListHo(null);

    api
      .get(`/groupadmin/settings/order_time/holiday/dineIn/${branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setDineInHolidayList(tableData);
        setErrorListHo(null);
      })
      .catch((err) => {
        if (err.response) {
          setErrorListHo("No Data found.");
          setDineInHolidayList([]);
        }
      });
  }

  ////////////////////////// Pickup Holiday Order List /////////////////////

  function deleteHoliday(_id) {
  
    const item = {}

    deleteCommonFunction(`/groupadmin/settings/order_time/holiday/${_id}`,
       "Holiday has been deleted successfully.", () => {
        groupAdminDineInHolidayList();
    }  ,item)
  }

  //////////////////////////////  Order //////////////////////////////////

  const [data, setForm] = useState([
    {
      Week: "",
      start_time: "",
      end_time: "",
      prep_time: "",
    },
  ]);

  const handleCheck = (index, e, selected) => {
    let temp = [...data];
    temp[index][selected] = e.target.value;
    setForm(temp);
  };

  const handleremove = (index) => {
    let list = [...data];
    list.splice(index, 1);
    setForm(list);
  };

  const [value, setValue] = useState();
  const [value1, setValue1] = useState();

  // const customDates = ['22-12-2022', '23-12-2022'];
  const [customDates, setcustomDates] = useState([]);
  const [customDates1, setcustomDates1] = useState([]);
  const disableCustomDt = (current) => {
    return customDates.includes(current.format("DD-MM-YYYY"));
  };

  const disableCustomDt1 = (current) => {
    // console.log("erererer", current);
    return customDates1.includes(current.format("DD-MM-YYYY"));
  };
  // console.log(moment(new Date(value)).format("DD-MM-YYYY"));
  const today = new Date();

  const handleSubmitHoliday = (event) => {
    event.preventDefault();
    setError1(null);

    if (branchID === null) {
      setError1("Please Select Branch");
      return;
    } else if (value === undefined) {
      setError1("please select start date.");
      $(".loader1").hide();
      return;
    } else if (value1 === undefined) {
      setError1("please select end date.");
      $(".loader1").hide();
      return;
    } else if (value > value1) {
      setError1(`Starting date is either equal to or lower than ending date.`);
      $(".loader1").hide();
      return;
    } else if (value1 < value) {
      setError1("End date is either equal to or greater then Start date");
      $(".loader1").hide();
      return;
    } else {
      $(".loader").show();
      var item = {};
      item["branch_id"] = branchID;
      item["type"] = "dineIn";
      item["start_date"] = moment(new Date(value)).format("YYYY-MM-DD");
      item["end_date"] = moment(new Date(value1)).format("YYYY-MM-DD");

      api
        .post(`/groupadmin/settings/order_time/holiday`, item, { headers })
        .then((res) => {
          $(".loader1").hide();

          showSuccessNotification( "DineIn holiday has been added successfully.")
          setValue("");
          setValue1("");
          groupAdminDineInHolidayList();
          // if (res.data.message === "Sucessfully created.") {
          //     window.location.reload(true);
          // }
        })
        .catch((err) => {
          $(".loader1").hide();
          if (err.response) {
            setError1(err.response?.data?.error?.message);
          } else if (err.request) {
            setError1(err.request);
          } else {
            setError1(err.message);
          }
        });
    }
  };

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (branchID == null) {
      setError("Please Select Branch");
    } else if (data.length === 0) {
      setError("Please Select Time");
    } else {
      $(".loader").show();

      let monday = [];
      let tuesday = [];
      let wednesday = [];
      let thursday = [];
      let friday = [];
      let saturday = [];
      let sunday = [];

      for (let i = 0; i < data.length; i++) {
        var weekkeys = data[i].Week;
        var wkey = weekkeys.split("_");

        var key1 = wkey[0] + "_start_time";
        var key2 = wkey[0] + "_end_time";

        if (data[i].Week == "") {
          setError("Empty fields are not allowed.");
          $(".loader").hide();
          return;
        } else if (data[i].start_time == "") {
          setError("Empty fields are not allowed.");
          $(".loader").hide();
          return;
        } else if (data[i].end_time == "") {
          setError("Empty fields are not allowed.");
          $(".loader").hide();
          return;
        } else if (data[i].start_time == data[i].end_time) {
          setError(
            wkey[0] + " Starting Time And Closing Time. it's Not allow to same"
          );
          $(".loader").hide();
          return;
        } else if (data[i].start_time > data[i].end_time) {
          setError(wkey[0] + " Starting time must be lower than ending time.");
          $(".loader").hide();
          return;
        } else if (data[i].end_time < data[i].start_time) {
          setError(
            wkey[0] + " Ending time must be greater than Starting time."
          );
          $(".loader").hide();
          return;
        } else if (data[i].prep_time == "") {
          setError(" Prepration Time Does Not allowed Empty.");
          $(".loader").hide();
          return;
        } else {
          if (wkey[0] == "sun") {
            sunday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "mon") {
            monday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "tue") {
            tuesday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "wed") {
            wednesday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "thu") {
            thursday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "fri") {
            friday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "sat") {
            saturday.push({
              [key1]: data[i].start_time,
              [key2]: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "weekend") {
            saturday.push({
              sat_start_time: data[i].start_time,
              sat_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
            sunday.push({
              sun_start_time: data[i].start_time,
              sun_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else if (wkey[0] == "weekdays") {
            monday.push({
              mon_start_time: data[i].start_time,
              mon_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
            tuesday.push({
              tue_start_time: data[i].start_time,
              tue_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
            wednesday.push({
              wed_start_time: data[i].start_time,
              wed_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
            thursday.push({
              thu_start_time: data[i].start_time,
              thu_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
            friday.push({
              fri_start_time: data[i].start_time,
              fri_end_time: data[i].end_time,
              prep_time: Number(data[i].prep_time),
            });
          } else {
          }
        }
      }

      var item = {
        branch_id: branchID,
        type: "dineIn",
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        saturday: saturday,
        sunday: sunday,
      };

      // console.log("item" ,item);
      // return

      api
        .post(`/groupadmin/settings/order_time`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          setForm([
            {
              Week: "",
              start_time: "",
              end_time: "",
              prep_time: "",
            },
          ])
          showSuccessNotification("DineIn has been added successfully.")
          groupAdminDineInList();
          // console.log(res.data.message);
          // window.location.reload(true);
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  };

  //////////////////////////////  Order //////////////////////////////////

  //////////////////////////////  Holiday //////////////////////////////////
  const [error1, setError1] = useState(null);
  const errorDiv1 = error1 ? (
    <div className="text-center alert alert-danger">{error1}</div>
  ) : (
    ""
  );

  const [DataHoliday, setDataHoliday] = useState([
    {
      start_date: "",
      end_date: "",
    },
  ]);

  const handleCheckHoliday = (index, e, selected) => {
    // console.log(index, e.target.value, selected);
    let temp = [...DataHoliday];
    temp[index][selected] = e.target.value;
    setDataHoliday(temp);
  };

  const haldleNewRowHoliday = () => {
    setDataHoliday([
      ...DataHoliday,
      {
        start_date: "",
        end_date: "",
      },
    ]);
  };

  const handleremoveHoliday = (index) => {
    // console.log(index)
    let list = [...DataHoliday];
    list.splice(index, 1);
    setDataHoliday(list);
  };

  //////////////////////////////  Holiday //////////////////////////////////

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url2 = pathnamesplits[4];

  function deleteOrder(branch_id, day_id, days) {
    let items = {
      branch_id: branch_id,
      type: "dine_in",
      day_id: day_id,
      day: days,
    };
    deleteCommonFunction(`/groupadmin/settings/order_time/${branch_id}`, 
      "Time has been deleted successfully.", () => {
        groupAdminDineInList();
    }  ,items)
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  const DeleteButton =  (userPermissionType === "groupadmin" ||
    UserPermissionAllData?.role_details?.settings?.find(permission =>
      permission?.key === "dine_in" &&
      (permission?.access?.includes("delete"))
    )) && (
      <i className="fa fa-trash" />)
  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="row col-md-12">
                    <div
                      className="col-md-9 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5 d-inline"
                        id="custom-form-control"
                      >
                        Dine In - Restaurant Time Management.
                      </h3>
                    </div>

                    <div
                      className="col-md-3 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <NoteState />
                    </div>
                  </div>

                  
                    <div className="rounded border p-5">
                     <CommonDinein />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmit}>
                                  <div className="rounded border p-10">
                                    <div className="d-inline flex-stack mb-5">
                                      <div
                                        className="d-inline justify-content-end"
                                        data-kt-docs-table-toolbar="base"
                                      ></div>
                                      <div className="mb-10 d-inline">
                                        <h6>
                                          Select the times during which guest
                                          can order.
                                        </h6>
                                      </div>

                                      {data.map((details, index) => (
                                        <>
                                          <div
                                            className="row col-md-12 my-6"
                                            key={index}
                                          >
                                            <div className="col-md-3 my-2">
                                              <label
                                                for="exampleFormControlInput1"
                                                className="required form-label"
                                              >
                                                Select Day
                                              </label>
                                              <select
                                                className="form-select"
                                                required
                                                value={details.Week}
                                                onChange={(e) =>
                                                  handleCheck(index, e, "Week")
                                                }
                                              >
                                               <option disabled selected value=''>
                                                  Select
                                                </option>
                                                <option value="sun_Sunday">
                                                  Sunday
                                                </option>
                                                <option value="mon_Monday">
                                                  Monday
                                                </option>
                                                <option value="tue_Tuesday">
                                                  Tuesday
                                                </option>
                                                <option value="wed_Wednesday">
                                                  Wednesday
                                                </option>
                                                <option value="thu_Thursday">
                                                  Thursday
                                                </option>
                                                <option value="fri_Friday">
                                                  Friday
                                                </option>
                                                <option value="sat_Saturday">
                                                  Saturday
                                                </option>
                                                <option value="weekdays">
                                                  Week Days
                                                </option>
                                                <option value="weekend">
                                                  Week End
                                                </option>
                                              </select>
                                            </div>
                                            <div className="col-md-3 my-2">
                                              <label
                                                for="exampleFormControlInput1"
                                                className="required form-label"
                                              >
                                                Opening Time.
                                              </label>
                                              <input
                                                type="time"
                                                className="form-control form-control-solid"
                                                value={details?.start_time}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    index,
                                                    e,
                                                    "start_time"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3 my-2">
                                              <label
                                                for="exampleFormControlInput1"
                                                className="required form-label"
                                              >
                                                Closing Time.
                                              </label>
                                              <input
                                                type="time"
                                                className="form-control form-control-solid"
                                                value={details?.end_time}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    index,
                                                    e,
                                                    "end_time"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3 my-2">
                                              <label
                                                for="exampleFormControlInput1"
                                                className="required form-label"
                                              >
                                                {" "}
                                                Avg Making Time.
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control filterme form-control-solid"
                                                placeholder="Avg Making Time."
                                                value={details?.prep_time}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    index,
                                                    e,
                                                    "prep_time"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3 my-2">
                                              {data.length !== 1 && (
                                                <a
                                                  id="deletebtn"
                                                  className="btn btn-danger1 remCF"
                                                  onClick={() =>
                                                    handleremove(index)
                                                  }
                                                >
                                                  {DeleteButton}
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv}{" "}
                                    </div>

                                    <div className="col-md-12">
                                      {" "}
                                      {errorDivLis}{" "}
                                    </div>

                                    <div className="my-5">
                                      {dineInList != ""
                                        ? Object.values(dineInList).map(
                                            (details, index) => {
                                              return (
                                                <>
                                                  {details.monday != ""
                                                    ? details.monday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Monday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.mon_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.mon_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className="form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "monday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}

                                                  {details.tuesday != ""
                                                    ? details.tuesday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Tuesday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.tue_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.tue_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className=" form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "tuesday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}

                                                  {details.wednesday != ""
                                                    ? details.wednesday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Wednesday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.wed_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.wed_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className=" form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "wednesday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}

                                                  {details.thursday != ""
                                                    ? details.thursday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Thursday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.thu_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.thu_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className=" form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "thursday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}

                                                  {details.friday != ""
                                                    ? details.friday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Friday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.fri_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.fri_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className=" form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "friday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}

                                                  {details.saturday != ""
                                                    ? details.saturday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Saturday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.sat_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.sat_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className=" form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "saturday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}

                                                  {details.sunday != ""
                                                    ? details.sunday.map(
                                                        (mo, index) => {
                                                          return (
                                                            <div
                                                              className="row col-md-12"
                                                              key={index}
                                                            >
                                                              <div className="col-md-3 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Day
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value="Sunday"
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Opening
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.sun_start_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <label className="form-label">
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    Closing
                                                                    Time.
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control form-control-solid"
                                                                  value={
                                                                    mo.sun_end_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-3 my-2">
                                                                <label
                                                                  for="exampleFormControlInput1"
                                                                  className=" form-label"
                                                                >
                                                                  <strong
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Avg. Making
                                                                    Time.{" "}
                                                                  </strong>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control filterme form-control-solid"
                                                                  readOnly
                                                                  value={
                                                                    mo.prep_time
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-md-2 my-2">
                                                                <a
                                                                  onClick={() =>
                                                                    deleteOrder(
                                                                      details.branch_id,
                                                                      mo._id,
                                                                      "sunday"
                                                                    )
                                                                  }
                                                                  id="deletebtn"
                                                                  className="btn btn-danger1 remCF"
                                                                  // onClick={() => handleremove(index)}
                                                                >
                                                                  <label className="form-label">
                                                                    <strong
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <br />
                                                                    </strong>
                                                                  </label>
                                                                  <br />
                                                                  {DeleteButton}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    : ""}
                                                </>
                                              );
                                            }
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="modal-footer flex-center">
                                  {(userPermissionType === "groupadmin" ||
                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                        permission?.key === "dine_in" &&
                                        (permission?.access?.includes("create"))
                                      )) && (
                                        <button type="submit" className="btn btn-primary">
                                          <span className="indicator-label">Save</span>
                                        </button>)}
                                    <button
                                      type="button"
                                      className="btn btn-danger m-2"
                                      onClick={() => history.goBack()}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </form>
                                <br />

                                <form onSubmit={handleSubmitHoliday}>
                                  <div className="rounded border p-10">
                                    <div className="d-inline flex-stack mb-5">
                                      <div
                                        className="d-inline justify-content-end"
                                        data-kt-docs-table-toolbar="base"
                                      >
                                        {/* <button type="button" className="btn btn-primary addtimes" onClick={haldleNewRowHoliday} style={{ float: "right" }}>
                                                                                    Add More
                                                                                </button> */}
                                      </div>
                                      <div className="mb-10 d-inline">
                                        <h6>Closed Days: Ex. Holidays</h6>
                                      </div>
                                      <br />
                                      {DataHoliday.map((details, index) => (
                                        <>
                                          <div
                                            className="row col-md-12"
                                            key={index}
                                          >
                                            <div className="col-md-4 my-2">
                                            <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  Start Date
                                                </strong>
                                              </label>
                                              {/* <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <DatePicker
                                                  className="form-control form-control-solid"
                                                  label="Start Date"
                                                  value={value}
                                                  minDate={today}
                                                  // shouldDisableDate={
                                                  //   disableCustomDt
                                                  // }
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) => {
                                                    setValue(newValue);
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField {...params} />
                                                  )}
                                                />
                                              </LocalizationProvider> */}
                                              <input id='txtDate' type="date" 
                                              className="form-control form-control-solid" 
                                              value={value}
                                              min={today} 
                                              onChange={(e) => setValue(e.target.value)} 
                                              />
                                            </div>
                                            <div className="col-md-4 my-2">
                                            <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  End Date
                                                </strong>
                                              </label>
                                              {/* <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <DatePicker
                                                  className="form-control form-control-solid"
                                                  label="End Date"
                                                  minDate={today}
                                                  value={value1}
                                                  shouldDisableDate={
                                                    disableCustomDt1
                                                  }
                                                
                                                  inputFormat="DD/MM/YYYY"
                                                  onChange={(newValue) => {
                                                    setValue1(newValue);
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField {...params} />
                                                  )}
                                                />
                                              </LocalizationProvider> */}
                                              <input id='txtDate1'
                                               type="date"
                                               min={today}
                                                className="form-control form-control-solid" 
                                                value={value1}
                                                onChange={(e) => setValue1(e.target.value)}
                                                 />
                                            </div>
                                            <div className="col-md-4 my-2">
                                              {DataHoliday.length !== 1 && (
                                                <a
                                                  id="deletebtn"
                                                  className="btn btn-danger1 remCF"
                                                  onClick={() =>
                                                    handleremoveHoliday(index)
                                                  }
                                                >
                                                  {DeleteButton}
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                    <div className="col-md-12 text-center">
                                      <br />
                                      <div
                                        className="loader1"
                                        style={{ display: "none" }}
                                      >
                                        <img src="/assets/loader.gif" />
                                        {/* <img src="" alt="logo" /> */}
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv1}{" "}
                                    </div>

                                    <div className="col-md-12">
                                      {" "}
                                      {errorDivLisHo}{" "}
                                    </div>

                                    {DineInHolidayList != ""
                                      ? DineInHolidayList.map(
                                          (details1, index) => {
                                            return (
                                              <>
                                                <div
                                                  className="row col-md-12"
                                                  key={index}
                                                >
                                                  <div className="col-md-4 my-2">
                                                    <input
                                                      type="text"
                                                      className="form-control form-control-solid"
                                                      value={moment(
                                                        details1.start_date
                                                      ).format("DD/MM/YYYY")}
                                                    />
                                                  </div>
                                                  <div className="col-md-4 my-2">
                                                    <input
                                                      type="text"
                                                      className="form-control form-control-solid"
                                                      value={moment(
                                                        details1.end_date
                                                      ).format("DD/MM/YYYY")}
                                                    />
                                                  </div>
                                                  {/* <div className='col-md-4 my-2'><input type="date" className="form-control form-control-solid" value={moment(details1.end_date).format('YYYY/MM/DD')} /></div> */}
                                                  <div className="col-md-4 my-2">
                                                    <button
                                                      type="button"
                                                      onClick={(e) =>
                                                        deleteHoliday(
                                                          details1._id
                                                        )
                                                      }
                                                      id="deletebtn"
                                                      className="btn btn-danger1 remCF"
                                                    >
                                                      {DeleteButton}
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )
                                      : ""}
                                  </div>
                                  <div className="modal-footer flex-center">
                                  {(userPermissionType === "groupadmin" ||
                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                        permission?.key === "dine_in" &&
                                        (permission?.access?.includes("create"))
                                      )) && (
                                        <button type="submit" className="btn btn-primary">
                                          <span className="indicator-label">Save</span>
                                        </button>)}
                                    <button
                                      type="button"
                                      className="btn btn-danger m-2"
                                      onClick={() => history.goBack()}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminDinein);
