import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminUperSide from "../../../Comman/UperSide";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import useDrawer from "../../../../context/DrawerContext";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { UserPermissionAllData, userPermissionType } from "../../../Comman/UserPermission";

window.jquery = window.$ = $;

export default function GroupAdminPaymentProviderForm() {
  const { id, branchid, name } = useParams();
  const history = useHistory();

  const [customer_id, setCustomerId] = useState("");
  const [terminal_id, setTerminalId] = useState("");
  const [auth_token, setAuthToken] = useState("");
  const [api_key, setApiKey] = useState("");
  const [api_secret, setApiSecret] = useState("");
  const [paymentDataById, setPaymentDataByID] = useState([]);
  const [merchant_id, setmerchant_id] = useState("")
  const [error, setError] = useState(null);

  useEffect(() => {
    PaymentProviderListbyID();
  }, [branchid]);

  function PaymentProviderListbyID() {
    api.get(`/groupadmin/branch/paymentType/${branchid}`, { headers })
      .then((res) => {
        const tableData = res.data.data.payment_fields[0];
        setPaymentDataByID(tableData);
        if (name === "ANZ-Worldline-saferpay") {
          setApiKey(tableData.api_key || "");
          setApiSecret(tableData.api_secret || "");
          setmerchant_id(tableData.merchant_id || "");
        } else {
          setCustomerId(tableData.customer_id);
          setTerminalId(tableData.terminal_id);
          setAuthToken(tableData.auth_token.replace("Basic ", ""));
        }
      })
      .catch((err) => {
        setPaymentDataByID("");
        setCustomerId("");
        setTerminalId("");
        setAuthToken("");
        if (err.response) {
          toast.error(err.response.data.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();

    let item;
    if (name === "ANZ-Worldline-saferpay") {
      item = {
        branch_id: branchid,
        payment_data: {
          api_key: api_key,
          api_secret: api_secret,
          merchant_id: merchant_id
        },
        payment_id: String(id),
      };
    } else {
      item = {
        branch_id: branchid,
        payment_data: {
          customer_id: customer_id,
          terminal_id: terminal_id,
          auth_token: `Basic ${auth_token}`,
        },
        payment_id: String(id),
      };
    }

    api.post(`/groupadmin/settings/payment`, item, { headers })
      .then((res) => {
        $(".loader").hide();
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Payment details have been added successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/group_admin/settings/payment_provider");
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          setError(err.response.data.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : null;

  const userPermissionType_ = userPermissionType === "groupadmin"


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">
                      Settings
                    </h1>
                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                        <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                          Payment Provider
                        </h3>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                          <button onClick={() => history.goBack()} className="btn btn-primary">
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card card-docs mb-2">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div className="rounded border p-10">
                          <div className="py-5">
                            <div className="row g-5 g-xl-8">
                              <form onSubmit={handleSubmit}>
                                <div className="row col-md-12">
                                  {name === "ANZ-Worldline-saferpay" ? (
                                    <>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          API Key
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter API Key"
                                          required
                                          value={api_key}
                                          onChange={(e) => setApiKey(e.target.value)}
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Merchant Id
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Merchant Id"
                                          required
                                          value={merchant_id}
                                          onChange={(e) => setmerchant_id(e.target.value)}
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          API Secret
                                        </label>
                                        <input
                                          type="password"
                                          className="form-control form-control-solid"
                                          placeholder="Enter API Secret"
                                          required
                                          value={api_secret}
                                          onChange={(e) => setApiSecret(e.target.value)}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Customer Id
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Customer Id"
                                          required
                                          value={customer_id}
                                          onChange={(e) => setCustomerId(e.target.value)}
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Terminal Id
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Terminal Id"
                                          required
                                          value={terminal_id}
                                          onChange={(e) => setTerminalId(e.target.value)}
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          Auth Token
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Enter Auth Token"
                                          required
                                          value={auth_token}
                                          onChange={(e) => setAuthToken(e.target.value)}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="col-md-12 text-center">
                                  <br />
                                  <div className="loader" style={{ display: "none" }}>
                                    <img src="/assets/loader.gif" alt="Loading..." />
                                  </div>
                                </div>
                                <div className="col-md-12"> {errorDiv} </div>
                                <div className="modal-footer flex-center">
                                  {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "payment_provider" &&
                                      (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                    )) && (
                                      <button type="submit" className="btn btn-primary">
                                        <span className="indicator-label">
                                          Save
                                        </span>
                                      </button>
                                    )}

                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}
