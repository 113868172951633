import { BRANCH1 } from "../../actions";



const branchListReducer = (state = [], action) => {
    switch (action.type) {
        case BRANCH1.CLEAR_BRANCH_LIST:
            return [];
        case BRANCH1.SET_BRANCH_LIST:
            return [...action?.branchlist];
        default:
            return state;
    }
}

export default branchListReducer