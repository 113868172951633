import React, { useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "./BranchList";
import { getBranchList } from "../../Store";

function BranchListDropDown({ getBranchList, BranchList_, onBranchChange, branchId }) {

      const filteredOptions = branchfilterAndMapData(BranchList_);
      
      useEffect(() => {
            getBranchList();
      }, []);


      const handleBranchChange = (selectedOption) => {
            onBranchChange(selectedOption);
      };


      return (
            <React.Fragment>
                  <Select
                        placeholder="Select Branch"
                        options={filteredOptions}
                        onChange={handleBranchChange}
                        value={branchId}
                  />
            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchListDropDown);
