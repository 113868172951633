import React from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link } from "react-router-dom";
import headers from "../../../../CommonApi/headers";
import $ from "jquery";
import { useState } from "react";
import { useEffect } from "react";
import api from "../../../../CommonApi/axios";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminEnquriy() {
  const [user, setUser] = useState("");
const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  useEffect(() => {
    api
      .get(`/groupAdmin/inquiry/count`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setUser(tableData);
      })
      .catch((err) => {});
  }, []);

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader /> 
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                <div className="col-md-12 mb-7">
                <h1 >Enquiry Management</h1>
                  </div>
                  <div className="row g-5 g-xl-8">
                    <div className="col-sm-3">
                      <Link
                        to="/group_admin/enquiry/completed_enquiry"
                        className="card bg-success hoverable card-xl-stretch mb-xl-8"
                        style={{
                          boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                        }}
                      >
                        <div className="card-body p-2">
                          <center>
                            <div className="my-3">
                              <h4 className="text-white fw-normal fs-2x">
                                {user?.totalcompletedInquiryCount
                                  ||
                                  0}
                              </h4>
                            </div>
                            <div className="mb-2">
                              <h2 className="text-white fw-normal fs-2x">
                                Completed
                              </h2>
                            </div>
                          </center>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-3">
                      <Link
                        to="/group_admin/enquiry/pending_enquiry"
                        className="card bg-warning hoverable card-xl-stretch mb-xl-8"
                        style={{
                          boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                        }}
                      >
                        <div className="card-body p-2">
                          <center>
                            <div className="my-3">
                              <h4 className="text-white fw-normal fs-2x">
                                {" "}
                                {user?.totalpenidngInquiryCount
                                 || 0}
                              </h4>
                            </div>
                            <div className="mb-2">
                              <h2 className="text-white fw-normal fs-2x">
                                Pending
                              </h2>
                            </div>
                          </center>
                        </div>
                      </Link>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
      
    </React.Fragment>
  );
}
