import React from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import headers from "../../../../CommonApi/headers";

import $ from "jquery";
import { useEffect } from "react";
import { useState } from "react";
window.jquery = window.$ = $;

export default function GroupAdminEditPrivacyPolicy() {
  let history = useHistory();

  useEffect(() => {
    groupAdminPrivacyPolicy();
  }, []);

  const [image, setimage] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function groupAdminPrivacyPolicy() {
    api
      .get(`/groupAdmin/cms/privacy_policy`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setimage(tableData?.image);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data);
        }
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();
  
    const formData = new FormData();
  
    if (image[0]) {
      const selectedFile = image[0];
      
      // Check if the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        formData.append("image", selectedFile);
        
        api
          .post(
            `/groupAdmin/cms/privacy_policy`,
            formData,
            { headers }
          )
          .then((res) => {
            $(".loader").hide();
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Your work has been saved successfully",
              showConfirmButton: false,
              timer: 1000,
            });
          })
          .catch((err) => {
            $(".loader").hide();
            if (err.response) {
              setError(err.response?.data?.error?.message);
            }
          });
      } else {
        $(".loader").hide();
        setError("Please select a PDF file.");
      }
    }
  }
  

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header  pt-5 border-1">
                          <h1 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder fs-3 mb-1">
                              Privacy Policy
                            </span>
                          </h1>
                        </div>

                        <div className="card-body pt-1">
                          <form onSubmit={handleSubmit}>
                            <div className="col-md-12 mb-10 my-6">
                              <label
                                
                                className="form-label"
                              >
                                Privacy Policy
                              </label>
                              {image ? (
                                <input
                                  className="form-control form-control-solid mb-3"
                                  value={
                                    image[0]?.name ? image[0]?.name : image
                                  }
                                />
                              ) : (
                                ""
                              )}
                              <input
                                className="form-control form-control-solid"
                                placeholder="Select PDF Menu."
                                type="file"
                                name="file"
                                accept=".pdf"
                                onChange={(e) => setimage(e.target.files)}
                              />
                              {/* {image ? (
                                <Link onClick={EdithandleSubmitMenu}>
                                  <div className="my-3 mx-2">
                                    Remove Privacy Policy
                                  </div>
                                </Link>
                              ) : (
                                ""
                              )} */}
                              <div
                                className="form-text my-3 mx-2"
                                style={{ color: "black" }}
                              >
                                Allowed file types: pdf.
                              </div>
                            </div>
                            <div className="col-md-12 text-center">
                              <br />
                              <div
                                className="loader"
                                style={{ display: "none" }}
                              >
                                <img src="/assets/loader.gif" />
                              </div>
                            </div>
                            <div className="col-md-12"> {errorDiv} </div>

                            <div className="modal-footer flex-center">
                              <button
                                type="submit"
                                id="kt_modal_new_address_submit"
                                className="btn btn-primary"
                              >
                                <span className="indicator-label">Save</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => history.goBack()}
                              >
                                Cancel{" "}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
