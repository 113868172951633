import { STOCK } from "../../actions";

const stockListReducer = (state = [], action) => {
  switch (action.type) {
    case STOCK.CLEAR_STOCK_LIST:
      return [];
    case STOCK.SET_STOCK_LIST:
      return [...action.stocklist];
    default:
      return state;
  }
};


export { stockListReducer };
