

import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import capitalizeFirstLetter from '../../../../../Comman/UpperCase';
import moment from "moment";
import { data } from 'jquery';



export const exportToXlsxForDetailReport = (data) => {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Iterate through each branch
  data.forEach(branchData => {
    const branch = branchData;

    // Sort order list by order_date
    branch.order_list.sort((a, b) => new Date(a.order_date).getTime() - new Date(b.order_date).getTime());

    // Create a worksheet for the current branch
    const ws = XLSX.utils.aoa_to_sheet([
      ["Order Date", "Order Type", "Gross Total", "Total Discount",
        "Gross Total with TAX (Less Discount)", "Food", "Alcohol Drinks", "Soft Drinks",
        "VAT Food", "VAT Alcoholic Drinks", "VAT Soft Drinks", "Total VAT", "Net Total without Tax"],
      ...branch.order_list.map(order => [
        order.order_date, order.order_type, order.gross_total, order.total_discount,
        order.gross_total_with_tax, order.food, order.alcoholic_drinks, order.soft_drinks,
        order.vat_food, order.vat_alcohol_drinks, order.vat_soft_drinks, order.total_vat,
        order.net_total_without_tax
      ])
    ]);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, branch.branch_name);
  });

  // Write the workbook to a file
  XLSX.writeFile(wb, "OrderDetails.xlsx");
};


export const exportXlsxSummrayForReport = (jsonData, start_date, end_date ,currencyLabel) => {
  const wb = XLSX.utils.book_new();

  const Header1 = [{
    v: `Generated by Gurjinder Singh ${(moment(new Date()).format('DD-MM-YYYY h:mm:ss a'))}. All Figures in ${currencyLabel} except where noted`,
  }];
  const Header2 = [];

  const Header4 = [];
  const Header5 = [`Business report for ${moment(start_date).format('D MMMM YYYY')} ${moment(end_date || new Date()).format('D MMMM YYYY')}`];

  const Header8 = [];
  const Header9 = ['Sales'];
  const secondRowHeader = [
    "Order Type",
    "Gross Total",
    "Total Discount",
    "Gross Total with TAX (Less Discount)",
    "Food Tax",
    "Alcohol Drinks Tax",
    "Soft Drinks Tax",
    "Total Tax",
    "Net Total without Tax"
  ];

  jsonData.forEach(branch => {
    const Header3 = [`${branch?.branch_address} - ${branch?.branch_name}`];
    const Header6 = [`Date of first receipt : ${moment(branch?.first_date).format("DD-MM-YYYY h:mm A")  || "No Data"}`];
    const Header7 = [`Date of last receipt : ${moment(branch?.last_date).format("DD-MM-YYYY h:mm A") || "No Data"}`];

    const wsData = [
      Header1, Header2, Header3, Header4, Header5, Header6, Header7, Header8, Header9, secondRowHeader
    ];

    let subtotalRow = null;

    branch.order_list.forEach(order => {
      if (order.order_type === "Total") {
        // If it's a "Total" order type, save it as the subtotal row
        subtotalRow = [
          capitalizeFirstLetter(order.order_type),
          order.order_details.total_gross_total,
          order.order_details.total_total_discount,
          order.order_details.total_gross_total_with_tax,
          order.order_details.total_food_tax,
          order.order_details.total_alcoholic_drinks_tax,
          order.order_details.total_soft_drinks_tax,
          order.order_details.total_total_tax,
          order.order_details.total_net_total_without_tax
        ];
      } else {
        // If it's not a "Total" order type, push the regular order details row
        const rowData = [
          capitalizeFirstLetter(order.order_type),
          order.order_details.gross_total,
          order.order_details.total_discount,
          order.order_details.gross_total_with_tax,
          order.order_details.food_tax,
          order.order_details.alcoholic_drinks_tax,
          order.order_details.soft_drinks_tax,
          order.order_details.total_tax,
          order.order_details.net_total_without_tax
        ];
        wsData.push(rowData);
      }
    });

    // Push the subtotal row after all regular order details
    if (subtotalRow !== null) {
      wsData.push(subtotalRow);
    }

    // Create a worksheet for the current branch
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Add the worksheet to the workbook with branch name as sheet name
    XLSX.utils.book_append_sheet(wb, ws, branch.branch_name);
  });

  XLSX.writeFile(wb, 'OrderSummary.xlsx');
}

export const exportPDFforDetailReport = (data) => {
  const headers = ["Order Date", "Order Type", "Gross Total", "Total Discount",
    "Gross Total with TAX (Less Discount)", "Food", "Alcohol Drinks", "Soft Drinks",
    "VAT Food", "VAT Alcoholic Drinks", "VAT Soft Drinks", "Total VAT", "Net Total without Tax"];

  // Initialize jspdf
  const doc = new jsPDF({ orientation: "landscape" });

  // Iterate through each branch
  data.forEach((branch, index) => {
    // Sort order list by order_date
    branch.order_list.sort((a, b) => new Date(a.order_date).getTime() - new Date(b.order_date).getTime());

    // Add page for each branch except the first one
    if (index !== 0) {
      doc.addPage();
    }

    // Add branch name as title
    doc.setFontSize(14);
    doc.text(10, 10, `Branch Name: ${branch.branch_name}`);

    // Initialize array to hold data rows for current branch
    const pdfData = [headers];

    // Iterate through each order in the branch
    branch.order_list.forEach(order => {
      // Extract data for each order
      const {
        order_date, order_type, gross_total, total_discount,
        gross_total_with_tax, food, alcoholic_drinks, soft_drinks,
        vat_food, vat_alcohol_drinks, vat_soft_drinks, total_vat,
        net_total_without_tax
      } = order;

      // Create a row for the order
      const row = [
        order_date, order_type, gross_total, total_discount,
        gross_total_with_tax, food, alcoholic_drinks, soft_drinks,
        vat_food, vat_alcohol_drinks, vat_soft_drinks, total_vat,
        net_total_without_tax
      ];

      // Add the row to the data array
      pdfData.push(row);
    });

    // Convert the data to PDF format
    doc.autoTable({
      head: [headers],
      body: pdfData.slice(1), // Exclude headers from body
      margin: { top: 20 }, // Add margin from top
      startY: 20, // Start Y position
      theme: "grid", // Add grid theme (borders)
      styles: {
        fontSize: 7, // Font size for header
      },
      headerStyles: {
        fillColor: "darkgray",
        textColor: "#000",
        halign: "center",
      },
      bodyStyles: {
        fontSize: 6,
        textColor: [0, 0, 0], // Font size for body
      },
    });
  });

  // Save the PDF
  const todayDate = moment().format("DD-MM-YYYY");
  const fileName = `OrderDetails${todayDate}.pdf`;

  // Save the PDF
  doc.save(fileName);
};

export const exportPDFforSummrayReport = (data ,start_date , end_date ,currencyLabel) => {

  const Mainheaders = [
    { 
      content: 'Sale', 
      styles: { 
        halign: 'center', 
        fontStyle: 'bold', 
        lineWidth: 0.2 , // Border thickness
        lineColor: [0, 0, 0], // Border color (black in this example)
        // lineRadius: 1, 
        fontSize : 12,
        fillColor: "darkgray",
        textColor: "#000",// Border radius
      }, 
      colSpan: 9
    }
  ];

  const headers = [
    "Order Type", "Gross Total", "Total Discount", "Gross Total with TAX (Less Discount)",
    "Food Tax", "Alcohol Drinks Tax", "Soft Drinks Tax", "Total Tax", "Net Total without Tax"
  ];
  const headerStyles = {
    halign: "center",
    fontStyle: 'bold',
    fillColor: "darkgray",
    textColor: "#000", // White background
    // lineWidth: 0.2,
    fontSize : 8,
    // lineColor: [0, 0, 0], // Border color (black in this example)
    };

  const headersWithStyles = headers.map(header => ({ content: header, styles: headerStyles }));


  const doc = new jsPDF({ orientation: "landscape" });

  data.forEach((branch, index) => {
    branch.order_list.sort((a, b) => new Date(a.order_date).getTime() - new Date(b.order_date).getTime());

    if (index !== 0) {
      doc.addPage();
    }

    doc.setFontSize(11);

    doc.text(10, 10, `Generated by Gurjinder Singh ${moment(new Date()).format('DD-MM-YYYY h:mm:ss a')}. All Figures in ${currencyLabel} except where noted`);
    
    doc.setFontSize(11);
    doc.text(10, 20, `${branch?.branch_address} - ${branch?.branch_name}`);

    doc.setFontSize(10);
    doc.text(10, 30, `Business report for ${moment(start_date).format('D MMMM YYYY')} ${moment(end_date || new Date()).format('D MMMM YYYY')}`);
    if(branch?.first_date){
      doc.text(10, 35, `Date of first receipt: ${moment(branch?.first_date).format("DD-MM-YYYY h:mm A")   || "---"}`);
      doc.text(10, 40, `Date of last receipt: ${moment(branch?.last_date).format("DD-MM-YYYY h:mm A")  || "---"}`);
    }
    // Add branch name as title
    doc.setFontSize(14);
    doc.text(10, branch?.first_date ? 50 : 40, `Branch Name: ${branch.branch_name}`);

    // Initialize array to hold data rows for current branch
    const pdfData = [];

    branch.order_list.forEach(order => {
      // Capitalize the order type
      const capitalizedOrderType = capitalizeFirstLetter(order.order_type);
    
      // Extract data based on order_type
      let row;
      if (order.order_type === "Total") {
        const {
          total_gross_total,
          total_total_discount,
          total_gross_total_with_tax,
          total_food_tax,
          total_alcoholic_drinks_tax,
          total_soft_drinks_tax,
          total_total_tax,
          total_net_total_without_tax
        } = order.order_details;
        row = [
          capitalizedOrderType,
          total_gross_total,
          total_total_discount,
          total_gross_total_with_tax,
          total_food_tax,
          total_alcoholic_drinks_tax,
          total_soft_drinks_tax,
          total_total_tax,
          total_net_total_without_tax
        ];
      } else {
        const {
            gross_total,
            total_discount,
            gross_total_with_tax,
            food_tax,
            alcoholic_drinks_tax,
            soft_drinks_tax,
            total_tax,
            net_total_without_tax
        } = order.order_details;
        row = [
          capitalizedOrderType,
          gross_total,
          total_discount,
          gross_total_with_tax,
          food_tax,
          alcoholic_drinks_tax,
          soft_drinks_tax,
          total_tax,
          net_total_without_tax
        ];
      }

      pdfData.push(row);
    });

    // Convert the data to PDF format
    doc.autoTable({
      head: [headersWithStyles], // Pass headers here
      body: pdfData, // Pass pdfData directly
      margin: { top: branch?.first_date ? 60 : 50 }, // Add margin from top
      startY: branch?.first_date ? 60 : 50 , // Start Y position
      theme: "grid", // Add grid theme (borders)
      styles: {
        fontSize: 7, // Font size for header
      },
      // headerStyles: {
      //   fillColor: "darkgray",
      //   textColor: "#000",
      //   halign: "center",
      // },
      bodyStyles: {
        fontSize: 8,
        textColor: [0, 0, 0], // Font size for body
      },
    });
  });

  // Save the PDF
  const todayDate = moment().format("DD-MM-YYYY");
  const fileName = `OrderSummary${todayDate}.pdf`;

  // Save the PDF
  doc.save(fileName);
}
