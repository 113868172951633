import React, { useEffect, } from "react";
import { Link } from "react-router-dom";
import { ReportsPermissions, SettingsPermissions, UserPermissionAllData, userPermissionType } from "./UserPermission";
import "./sidebar.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  HomeRegular, LocationRegular, GiftCardMultipleRegular, MoneySettingsRegular, SettingsRegular,
  WindowRegular, GridDotsFilled, NotebookRegular, PeopleTeamRegular, ChatBubblesQuestionRegular,
  DocumentDataRegular, ReceiptRegular, MailCopyRegular,BoxRegular
} from '@fluentui/react-icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 15,
  },
}));

export default function GroupAdminSidebar({ drawerPos, handleDrawer }) {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];

  const UserPermissionAllData_ = UserPermissionAllData && UserPermissionAllData?.role_details
  // console.log("UserPermissionAllData_", UserPermissionAllData_)


  useEffect(() => {
    handleClickScroll();
  }, [url]);

  const handleClickScroll = () => {
    const element = document.getElementById(
      url === "settings"
        ? "section-12"
        : "");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  const menuItemsArray = [
    {
      section: "dashboard",
      label: "Dashboard",
      icon: <HomeRegular />,
      compare: "dashboard"
    },
    {
      section: "branch_management",
      label: "Branches",
      icon: <LocationRegular />,
      compare: "branch"
    },
    {
      section: "table_management",
      label: "Tables",
      icon: <GridDotsFilled />,
      compare: "tables"
    },
    {
      section: "order_management",
      label: "Orders",
      icon: <ReceiptRegular />,
      compare: "orders"
    },
    {
      section: "menu_management",
      label: "Menus",
      icon: <NotebookRegular />,
      compare: "menus"
    },
    {
      section: "stock_management",
      label: "Stock Management",
      icon: <BoxRegular />,
      compare: "stock"
    },
    {
      section: "coupon_voucher_management",
      label: "Coupons & Vouchers",
      icon: <GiftCardMultipleRegular />,
      compare: ["coupons", "vouchers"]
    },
    {
      section: "tour",
      label: "Tour Groups",
      icon: <PeopleTeamRegular />,
      compare: "tour_groups"
    },
    {
      section: "email_marketing",
      label: "Email Marketing",
      icon: <MailCopyRegular />,
      compare: "email_marketing"
    },
    {
      section: "subscription_management",
      label: "Subscriptions",
      icon: <MoneySettingsRegular />,
      compare: "subscriptions"
    },
    {
      section: "enquiry",
      label: "Enquiries",
      icon: <ChatBubblesQuestionRegular />,
      compare: "enquiries"
    },
    {
      section: "report",
      label: "Reports",
      icon: <DocumentDataRegular />,
      compare: "report"
    },
    {
      section: "kpgpt",
      label: "KPGPT",
      icon: <WindowRegular />,
      compare: "KPGPT"
    },
    {
      section: "settings",
      label: "Settings",
      icon: <SettingsRegular />,
      compare: "settings"
    },
  ];


  function formatKey(key) {
    return key.toLowerCase().replace(/\s+/g, '_'); // Example implementation
  }

  function filterMenuItems(permissions, menuItemsArray) {
    // Create a set of sections that have any access permissions
    const permissionsMap = new Set(
      permissions
        ?.filter(item => item.access.length > 0)
        ?.map(item => formatKey(item.key))
    );




    // Filter menuItemsArray based on the permissionsMap
    const filteredMenuItems = menuItemsArray?.filter(menuItem => {
      if (menuItem.section === "coupon_voucher_management") {
        // Special case for "coupon_voucher_management"
        return permissionsMap?.has("coupons") || permissionsMap?.has("vouchers");
      }
      if (menuItem.section === "report") {
        // Special case for "coupon_voucher_management"
        return ReportsPermissions?.length > 0;
      }
      if (menuItem.section === "settings") {
        // Special case for "coupon_voucher_management"
        return SettingsPermissions?.length > 0;
      }

      // General case for all other sections
      return permissionsMap?.has(formatKey(menuItem.compare));
    });



    return filteredMenuItems;
  }

  // Example usage
  const filteredMenuItems__ = filterMenuItems(UserPermissionAllData_?.permissions, menuItemsArray);
  // console.log(filteredMenuItems__);

  let filteredMenuItems = userPermissionType === "groupadmin" ? menuItemsArray : filteredMenuItems__
  // if (userPermissionType === "TGA") {
  //   filteredMenuItems = filteredMenuItems.filter(item => item.section.includes("tour") ||
  //     item.section.includes("settings") ||
  //     item.section.includes("report")
  //   );
  // }


  let drawerClass = [];
  if (drawerPos === 1) {
    drawerClass.push("drawerMin");
  } else if (drawerPos === 2) {
    drawerClass.push("drawerOpen");
  } else {
    drawerClass = [];
  }

  // console.log("drawerPos" ,drawerPos)


  return (
    <React.Fragment>
      <aside>
        <div
          id="kt_aside"
          className={`${drawerClass.join(" ")} ${"aside pb-5 pt-5 pt-lg-0"}`}
        >
          <div style={{ borderRight: "1px solid #C7D0D7" }} className="aside-menu flex-column-fluid" id="kt_aside_menu">
            <div
              className=" my-0 my-lg-5 pe-lg-n1"
              id="kt_aside_menu_wrapper"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
              data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
              data-kt-scroll-offset="5px"
            >

              <div
                style={{ height: "80vh", position: "relative", overflowY: "auto" }}
                className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
                id="#kt_aside_menu"
                data-kt-menu="true"

              >
                {filteredMenuItems?.length > 0 && drawerPos === 1 ? filteredMenuItems?.map((menuItem, index) => (
                  <div className="menu-item my-0" key={index} id={`section-${index + 1}`}>
                    <LightTooltip

                      title={menuItem.label} placement="right" arrow>
                      <Link
                        className={url === menuItem.section ? "menu-link active_ menu-center" : "menu-link padding_ menu-center"}
                        to={`/group_admin/${menuItem.section}`}
                        data-bs-trigger="hover"
                        data-bs-dismiss="click"
                        data-bs-placement="right"
                      >
                        <span className={url === menuItem.section ? "icon_active" : "icon_s"}>
                          {menuItem.icon}
                        </span>
                      </Link>
                    </LightTooltip>
                  </div>
                )) :

                  filteredMenuItems?.map((menuItem, index) => (
                    <div className="menu-item my-0" key={index} id={`section-${index + 1}`}>
                      <Link
                        className={url === menuItem.section ? "menu-link active menu-center" : "menu-link menu-center"}
                        to={`/group_admin/${menuItem.section}`}
                        data-bs-trigger="hover"
                        data-bs-dismiss="click"
                        data-bs-placement="right"
                      >
                        <span className={url === menuItem.section ? "icon_active" : "icon_s"}>
                          {menuItem.icon} </span> <span className="mx-3">{menuItem.label} </span>
                      </Link>

                    </div>
                  ))}
              </div>

            </div>
            <div >
              {drawerPos === 1 ? (
                <div onClick={handleDrawer} className="text-center my-2 arrowDown_">
                  <ArrowForwardIosIcon style={{ fontSize: 16, cursor: "pointer", color: "#5A5F72" }} />
                </div>
              ) : (
                <div className="row my-2 arrowDown_Hide">
                  <div className="text-start col-md-10 hide">Hide</div>
                  <div onClick={handleDrawer} className="text-end col-md-2">
                    <ArrowBackIosIcon style={{ fontSize: 16, cursor: "pointer", color: "#5A5F72" }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
}
