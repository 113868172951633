import { COUPON } from "../action-types";


const getCouponList = () => ({
  type: COUPON.GET_COUPON_LIST,
});


const setCouponList = (couponlist) => ({
  type: COUPON.SET_COUPON_LIST,
  couponlist,
});


export {
      getCouponList ,setCouponList
};
