import { VOUCHERPURCHASED } from "../../actions";



const voucherPurchasedListReducer = (state = [], action) => {
    switch (action.type) {
        case VOUCHERPURCHASED.CLEAR_VOUCHERPURCHASED_LIST:
            return [];
        case VOUCHERPURCHASED.SET_VOUCHERPURCHASED_LIST:
            return [...action?.voucherpurlist];
        default:
            return state;
    }
}

export default voucherPurchasedListReducer