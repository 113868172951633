import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAddonList, getHeaderList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal"
import useError from "../../../../Comman/Error/useError";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { showToastCommon } from "../../../../Comman/Toastify/Toastify";
import Swal from "sweetalert2";
import Addnewdetails from "../../../../Comman/AddNewDetails/Addnewdetails";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";


function AddonProperties({ getAddonList, addonList }) {

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
      // console.log("addonList" ,addonList)

      const userPermissionType_ = userPermissionType === "groupadmin"


      useEffect(() => {
            getAddonList();
      }, [getAddonList]);

      const [Data, setData] = useState([]);


      useEffect(() => {
            setData(addonList); // Update Data state when headerList changes
      }, [addonList]);

      const { error, showError, clearError } = useError();

      const [Index, setIndex] = useState("");

      let history = useHistory();

      const handleAddSubMenu = (mainIndex) => {
            const updatedData = [...Data];
            updatedData[mainIndex].items.push({ name: '', price: '' });
            setData(updatedData);
      };

      const handleRemoveSubMenu = (mainIndex, subIndex) => {
            const updatedData = [...Data];
            updatedData[mainIndex].items.splice(subIndex, 1);
            setData(updatedData);
      };

      const handleRemoveSubMenuApi = (main, id) => {
            const updatedItems = main?.items?.filter(item => item._id !== id);

            const updatedMain = {
                  ...main,
                  items: updatedItems
            };

            Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                  if (result.isConfirmed) {
                        api
                              .patch(`/groupAdmin/options/${updatedMain?._id}`, updatedMain, { headers })
                              .then(() => {
                                    showSuccessNotification("Add-on propertie has been successfully deleted.")
                                    getAddonList();
                              })
                              .catch((err) => {
                                    if (err.response) {
                                          showToastCommon(err?.response?.data?.error?.message);
                                    }
                              });
                  }
            });
      };


      const handleSubMenuChange = (mainIndex, subIndex, key, value) => {
            const updatedData = [...Data];
            updatedData[mainIndex].items[subIndex][key] = value;
            setData(updatedData);
      };


      useEffect(() => {
            applyFilterMeHandlers(); // Apply the jQuery handlers
      }, [Data?.length]);

      const handleMainChange = (index, key, value) => {
            const updatedData = [...Data];
            updatedData[index][key] = value;
            setData(updatedData);
      };


      function handleSubmit(event, main, index, ID) {
            event.preventDefault();
            clearError(null);
            setIndex(index);

            let item = {
                  title: main?.title,
                  items: main?.items
            };

            api
                  .patch(`/groupAdmin/options/${ID}`, item, { headers })
                  .then((res) => {
                        showError(null)
                        getAddonList();
                        showSuccessNotification("Your work has been saved successfully.");
                        getHeaderList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              showError(err.response?.data?.error?.message);
                        }
                  });
      }



      function deleteHeader(_id) {
            deleteCommonFunction(`/groupAdmin/options/${_id}`, "Add-on Configurations has been successfully deleted.", () => getAddonList());
      }

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <ToastContainer />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-5"
                                                                  id="vertical-horizontal-scroll"
                                                            >
                                                                  Settings
                                                            </h1>
                                                            <hr />

                                                            <div className="row col-md-12">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Options
                                                                        </h3>
                                                                  </div>

                                                                  <div
                                                                        className="col-md-3 text-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                              permission?.key === "options" &&
                                                                              permission?.access?.includes("create")
                                                                        )) && (
                                                                                    <Addnewdetails url="/group_admin/settings/options/add-options" Name="Add Options" />
                                                                              )}


                                                                  </div>
                                                            </div>

                                                            <div className="py-5">
                                                                  <div className="rounded border p-10">
                                                                        <div className="tab-content" id="myTabContent">
                                                                              <div
                                                                                    className="tab-pane fade show active"
                                                                                    id="kt_tab_pane_1"
                                                                                    role="tabpanel"
                                                                              >
                                                                                    {Data?.length > 0 ?
                                                                                          Data?.map((main, index) => (
                                                                                                <form key={index} onSubmit={(event) => handleSubmit(event, main, index, main?._id)}>
                                                                                                      <div className="card card-docs mb-10">
                                                                                                            <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                                                                                  <div className="rounded border p-10" >
                                                                                                                        <div className="row col-md-12 mb-8">
                                                                                                                              <div className="col-md-8">
                                                                                                                                    <h3>
                                                                                                                                          {main?.title}
                                                                                                                                    </h3>
                                                                                                                              </div>

                                                                                                                              <div className="col-md-4 text-end">
                                                                                                                                    {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                                                                          permission?.key === "options" &&
                                                                                                                                          permission?.access?.includes("delete")
                                                                                                                                    )) &&
                                                                                                                                          <DeleteIcon className="fs-1 my-3" onClick={() => deleteHeader(main?._id)} />
                                                                                                                                    }
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                        <hr />
                                                                                                                        <div className="row col-md-12 mt-8 mb-8">
                                                                                                                              <div className="col-md-3">
                                                                                                                                    <p>Options Title</p>
                                                                                                                              </div>
                                                                                                                              <div className="col-md-4">
                                                                                                                                    <input
                                                                                                                                          type="text"
                                                                                                                                          className="form-control form-control"
                                                                                                                                          placeholder="Enter Title"
                                                                                                                                          required
                                                                                                                                          value={main?.title}
                                                                                                                                          onChange={(e) => handleMainChange(index, 'title', e.target.value)}
                                                                                                                                    />
                                                                                                                              </div>


                                                                                                                        </div>
                                                                                                                        <hr />
                                                                                                                        {main?.items?.length > 0 && main?.items?.map((submenu, subIndex) => (
                                                                                                                              <div key={subIndex} className="row col-md-12 mt-8">
                                                                                                                                    <div className="col-md-3">
                                                                                                                                          {subIndex === 0 && (
                                                                                                                                                <p>Options</p>
                                                                                                                                          )}
                                                                                                                                    </div>
                                                                                                                                    <div className="col-md-4">
                                                                                                                                          <input
                                                                                                                                                type="text"
                                                                                                                                                className="form-control form-control"
                                                                                                                                                required
                                                                                                                                                value={submenu.name}
                                                                                                                                                onChange={(e) => handleSubMenuChange(index, subIndex, 'name', e.target.value)}
                                                                                                                                                placeholder="Enter Property Name"
                                                                                                                                          />
                                                                                                                                    </div>
                                                                                                                                    <div className="col-md-2">
                                                                                                                                          <input
                                                                                                                                                type="number"
                                                                                                                                                className="form-control form-control filterme"
                                                                                                                                                required
                                                                                                                                                placeholder="Enter Price"
                                                                                                                                                value={submenu.price}
                                                                                                                                                onChange={(e) => handleSubMenuChange(index, subIndex, 'price', e.target.value)}
                                                                                                                                          />
                                                                                                                                    </div>

                                                                                                                                    <div className="col-md-2 mt-3">
                                                                                                                                          <DeleteIcon
                                                                                                                                                onClick={() => submenu?._id ?

                                                                                                                                                      (userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                                                                                            permission?.key === "options" &&
                                                                                                                                                            permission?.access?.includes("delete")
                                                                                                                                                      )) &&
                                                                                                                                                      handleRemoveSubMenuApi(main, submenu?._id) :
                                                                                                                                                      handleRemoveSubMenu(index, subIndex)}
                                                                                                                                                className="fs-1"
                                                                                                                                          />
                                                                                                                                    </div>
                                                                                                                              </div>
                                                                                                                        ))}

                                                                                                                        <br />
                                                                                                                        <div className="row col-md-12">
                                                                                                                              <div className="col-md-3">

                                                                                                                              </div>
                                                                                                                              <div className="col-md-9">
                                                                                                                                    <button
                                                                                                                                          type="button"
                                                                                                                                          style={{ fontWeight: 400 }}
                                                                                                                                          onClick={() => handleAddSubMenu(index)}
                                                                                                                                          className="btn btn-danger">
                                                                                                                                          Add More
                                                                                                                                    </button>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                        <hr />
                                                                                                                        {Index === index &&
                                                                                                                              <ErrorMessage error={error} />
                                                                                                                        }
                                                                                                                        <div className="text-center">

                                                                                                                              {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                                                                          permission?.key === "options" &&
                                                                                                                                          permission?.access?.includes("edit")
                                                                                                                                    )) && 
                                                                                                                              <button
                                                                                                                                    type="submit"
                                                                                                                                    id="kt_modal_new_address_submit"
                                                                                                                                    className="btn btn-primary"
                                                                                                                              >
                                                                                                                                    <span className="indicator-label"> Save </span>
                                                                                                                              </button> }
                                                                                                                              <button onClick={() => history.push("/group_admin/settings")} type="button" className="btn btn-danger mx-2">
                                                                                                                                    Cancel
                                                                                                                              </button>
                                                                                                                        </div>

                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </form>
                                                                                          )) : <div className="card card-docs mb-10">
                                                                                                <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                                                                      <h5 className="text-center">No data available.</h5>
                                                                                                </div></div>}
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      addonList: state.addonList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getAddonList: () => dispatch(getAddonList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonProperties);

