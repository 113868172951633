import React, { useEffect, useState, useMemo } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { getInventoryCatList, getInventoryProList } from "../../../../../../Store";
import useDrawer from "../../../../../../context/DrawerContext";
import LinkCommon from "../InventoryCommon/LinkCommon";
import { tableOptionInventory } from "../../../../../NewTableCommon/tableOption";
import useWindowHeight from "../../../../../NewTableCommon/useWindowsPosition";
import { UserPermissionAllData, userPermissionType } from "../../../../../Comman/UserPermission";
import api from "../../../../../../CommonApi/axios";
import headers from "../../../../../../CommonApi/headers";
import "react-toastify/dist/ReactToastify.css";
import ColumPopoverCommon from "../../../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../../../NewTableCommon/tableFilter";
import renderStatusChip from "../../../../../NewTableCommon/renderStatusChip";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { showSuccessNotification } from "../../../../../Comman/Swal/Swal";
import { showToastCommon } from "../../../../../Comman/Toastify/Toastify";


function InventoryProductList({ getInventoryProList, inventoryproductList_, getInventoryCatList, inventoryCategoryList_ }) {


      useEffect(() => {
            getInventoryProList();
            getInventoryCatList();
      }, []);

      const inventoryCategoryList = inventoryCategoryList_?.inventoryCatagotyList
      const inventoryproductList = inventoryproductList_?.inventoryproductList


      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[4];

      // console.log('inventoryproductList_' ,inventoryproductList_?.inventoryproductList)
      // 

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      // console.log("columnsDataString" ,columnsDataString)
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const [product_name, setprodcut_name] = useState("");
      const [category, setcategory] = useState("");
      const [error, setError] = useState(null);
      const [EditId, setEditId] = useState("")

      const [FilterData, SetFilterData] = useState("");

      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };


      const filteredMenuList = useMemo(() => {
            
            const FilterProductArray = inventoryproductList?.length > 0 && inventoryproductList?.filter((main) => main?.products?.length > 0);
            const transformedResponse = FilterProductArray?.length > 0 && FilterProductArray.flatMap(category =>
                  category?.products?.length > 0 &&
                  category?.products?.map(product => ({
                        "_id": category?._id,
                        "restaurant_id": category?.restaurant_id,
                        "category_name": category?.category_name,
                        "is_active": category?.is_active,
                        "product_id": product?._id,
                        "product_name": product?.name,
                        "product_is_active": product?.is_active,
                        "created_at": category?.created_at
                  }))
            );

            const status = FilterData?.Status?.value;
            if (status === "Inactive") {
                  return transformedResponse.filter(menu => menu.product_is_active === false);
            } else if (status === "Active") {
                  return transformedResponse.filter(menu => menu.product_is_active);
            }
            return transformedResponse;
      }, [inventoryproductList, FilterData?.Status?.value]);

      // console.log("filteredMenuList", filteredMenuList)

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };


      function handleSubmitProduct(event) {
            event.preventDefault();
            setError(null);

            if (!category?.value) {
                  setError("Please select category.");
                  return;
            }
            if (!product_name) {
                  setError("Please enter product name.");
                  return;
            }

            const abc = EditId ? "name" : product_name

            const item = { category_id: category?.value, product_name: product_name };
            const requestMethod = EditId ? 'patch' : 'post';
            const requestUrl = EditId
                  ? `/groupadmin/inventory/updateProduct/${EditId}`
                  : `/groupadmin/inventory/addProduct`;
            const successMessage = EditId
                  ? "Products has been edited successfully."
                  : "Products has been added successfully.";

            api[requestMethod](requestUrl, item, { headers })
                  .then((res) => {
                        handleClose();
                        showSuccessNotification(successMessage);
                        getInventoryProList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              setError(err.response?.data?.error?.message);
                        }
                  });
      }


      function handleSubmitActive(data) {

            // console.log("data", data);
            // return

            const item = { category_id: data?._id, is_active: !data?.product_is_active, };
            api
                  .patch(`/groupadmin/inventory/updateProduct/${data?.product_id}`, item, { headers })
                  .then((res) => {
                        if (res.data.error) {
                        }
                        showSuccessNotification(`Products status has been ${!data?.is_active ? "active" : "inactive"} succesfully.`)
                        getInventoryProList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              showToastCommon(err?.response?.data?.error?.message)
                        }
                  });
      }

      function handleSubmitEdit(data) {
            handleShow();
            setError("")
            setEditId(data.product_id)
            setprodcut_name(data?.product_name);
            setcategory({
                  value
                        : data?._id,
                  label: data?.category_name
            });
      }


      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'sr_no',
                        header: 'Sr.No',
                        size: 50,
                        Cell: ({ row }) => {
                              return (
                                    <span>
                                          {row.index + 1}
                                    </span>
                              );
                        },
                  },
                  {
                        accessorKey: 'product_name',
                        header: 'Product Name',
                        size: 400,
                        Cell: ({ row }) => row?.original?.product_name,

                  },
                  {
                        accessorKey: 'category_name',
                        header: 'Category Name',
                        size: 400,
                        Cell: ({ row }) => row?.original?.category_name,

                  },
                  {
                        accessorKey: 'product_is_active',
                        header: 'Status',
                        size: 150,
                        Cell: ({ row }) => renderStatusChip(row?.original?.product_is_active),
                  }
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);


      const [show, setShow] = useState(false);

      const handleClose = () => {
            setprodcut_name("");
            setcategory("");
            setShow(false)
            setEditId("");
      };

      const handleShow = () => setShow(true);

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      const table = useMaterialReactTable({
            columns: Columns,
            data: filteredMenuList,
            ...tableOptionInventory({
                  url23,
                  handleShow,
                  handleClickColum_,
                  handleSubmitActive,
                  handleFilterClick,
                  handleSubmitEdit,
                  windowHeight,
                  filterData,
                  DisplayCol
            }),
      });


      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div

                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main style={{ marginTop: "-30px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-2"
                                                            >
                                                                  Settings
                                                            </h1>
                                                            <hr />

                                                            <div className="row">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Inventory - Products
                                                                        </h3>
                                                                  </div>


                                                                  <div
                                                                        className="col-md-3 text-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >

                                                                        {(userPermissionType === "groupadmin" || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                              permission?.key === "inventory" &&
                                                                              permission?.access?.includes("create")
                                                                        )) && (
                                                                              <button
                                                                              className="btn btn-primary"
                                                                              onClick={() => { handleShow() }}
                                                                        >
                                                                              Add Product
                                                                        </button>
                                                                              )}

                                                                       
                                                                  </div>
                                                            </div>

                                                            {/* <div className="rounded border p-5 mt-3"> */}
                                                            {/* <br/> */}
                                                            <LinkCommon />
                                                            <div className="mt-5">
                                                                  <MaterialReactTable
                                                                        table={table}
                                                                  /></div>
                                                            {/* </div> */}
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={url23}
                  />

                  <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                  />

                  <Modal dialogClassName="modal-30w"
                        aria-labelledby="example-custom-modal-styling-title"
                        show={show} centered onHide={handleClose}>
                        <Modal.Body>
                              <Modal.Title className="mb-2 heading">
                                    {EditId ? "Edit Product" : "Add New Product"}</Modal.Title>
                              <form onSubmit={handleSubmitProduct}>
                                    <div className="mb-5">
                                          <div className="discriptionline">
                                                Please enter appropriate product.
                                          </div>
                                    </div>
                                    <div className="mb-5">
                                          <label

                                                className="required form_label_"
                                          >
                                                Product Category
                                          </label>
                                          <Select
                                                placeholder="Select Products Category"
                                                value={category}
                                                options={
                                                      inventoryCategoryList?.filter((main) => main?.is_active)?.map((sponsor) => ({
                                                            label: sponsor.category_name,
                                                            value: sponsor._id,
                                                      }))
                                                }
                                                menuPlacement="top"
                                                onChange={(opt) => setcategory(opt)}
                                                isDisabled={EditId}
                                          />
                                    </div>

                                    <div className="mb-5">
                                          <label className="required form_label_">
                                                New Products Name
                                          </label>
                                          <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter New Products Name"
                                                value={product_name}
                                                onChange={(e) => {
                                                      const trimmedValue = e.target.value.trimStart(); // Trim leading spaces only
                                                      setprodcut_name(trimmedValue);
                                                }}
                                          />
                                    </div>
                                    <div className="col-md-12"> {error && <div className="text-center alert alert-danger">{error}</div>} </div>
                                    <div className="row flex-center d-flex">
                                          <div className="col-md-6">
                                                <button type="submit" className="btn btn-primary w-100">
                                                      Save
                                                </button>
                                          </div>
                                          <div className="col-md-6">
                                                <button
                                                      type="button"
                                                      onClick={handleClose}
                                                      className="btn btn-danger w-100"
                                                >
                                                      Cancel
                                                </button>
                                          </div>
                                    </div>

                              </form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      inventoryproductList_: state,
      inventoryCategoryList_: state,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getInventoryProList: () => dispatch(getInventoryProList()),
            getInventoryCatList: () => dispatch(getInventoryCatList())
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryProductList);

