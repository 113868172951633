import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation ,useParams } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import $ from "jquery";
import Select from "react-select";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import headers from "../../../../../CommonApi/headers";
import { useStyles } from "../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../Comman/TableOption";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import NoteState from "../CommanBranch/NoteState";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import useDrawer from "../../../../../context/DrawerContext";
window.jquery = window.$ = $;




function GroupAdminPrinterEdit(props) {
      let id = useParams();

      const { branchID } = props;

  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [user1 ,setuser1] = useState([]);
  const [user, setuser] = useState("");
  const [BranchList ,setBranchList] = useState([])
  const [BranchList__ ,setBranchList__] = useState([])
  const [printer_name ,setprinter_name] = useState("");
  const [ip_address ,setip_address] = useState("");
  const [printer_type ,setprinter_type] = useState("");
  const [printer_category ,setprinter_category] = useState("");
  const [enable_invoice ,setenable_invoice] = useState("");
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

   
  useEffect(() => {
      groupadminCouponDetaisView();
      groupAdminBranchList();
    }, []);
  
  

    function groupAdminBranchList() {
      api
        .get(`/groupadmin/branch/restaurantwise`, { headers })
        .then((res) => {
          const options = res.data.data.map((item) => ({
            label: item.branch_name_EN,
            value: item._id,
          }));
          setBranchList(options);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err?.response?.data);
          }
        });
    }
  
    function groupadminCouponDetaisView() {
      api
        .get(`/groupAdmin/branchPrinter/${id.id}`, { headers })
        .then((res) => {
          const options = res.data.data;
      //     setuser(options);
      // console.log("ss" ,options)
          setenable_invoice(options?.enable_invoice);
          setip_address(options?.ip_address);
          setprinter_category({
            label: options?.printer_category_name,
            value: options?.printer_category,
          } );
          setprinter_name(options?.printer_name);
          setprinter_type({
            label: `${options.printer_type_name}  -  ${options?.printer_model_number}`,
            value: options?.printer_type,
          } );
          setBranchList__({
            label: options?.branch_name_EN,
            value: options?.branch_id,
          } )
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response?.data?.error?.message);
          }
        });
    }
  

    // console.log("enable_invoice" ,enable_invoice)


  let history = useHistory();
  let location = useLocation();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];


  const classes = useStyles();

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Branch Name",
      field: "branch_name_EN",
    },
    {
      title: "Printer Name",
      field: "address",
    },
    {
      title: "Printer Category",
      field: "phone_number",
    },
    {
      title: "Branch Status",
      render: (rowData) => {
        return (
          <div>
            {rowData.is_active == true ? (
              <button type="button" className="btn btn-success">
                Active
              </button>
            ) : (
              <button type="button" className="btn btn-Inactive">
                Inactive
              </button>
            )}
          </div>
        );
      },
    },
   
  ];


  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (printer_name === "") {
      setError("Please enter the printer name.");
    }
    else if (ip_address === "") {
      setError("Please enter the IP address.");
    }
    else if (!(/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(ip_address))) {
      setError("You have entered an invalid IP address!");
    }
    else if (printer_type === "") {
      setError("Please select the printer type.");
    }
    else if (printer_category === "") {
      setError("Please select the printer category.");
    }
    else if (enable_invoice === "") {
      setError("Please select the print receipt.");
    }
    
    else {
      $(".loader").show();
      let item = {
          printer_name:printer_name,
          ip_address: ip_address,
          printer_type: printer_type?.value,
          printer_category: printer_category?.value,
          enable_invoice: enable_invoice,
          branch_id: BranchList__?.value    
      };
      // console.log("SS" ,item);
      // return

      api
        .patch(`/groupAdmin/branchPrinter/${id.id}`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Printer has been edited succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          setenable_invoice("");
          setip_address("");
          setprinter_category("");
          setprinter_name("");
          setprinter_type("");
          history.push("/group_admin/settings/printer/printerList");
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }


  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      maxHeight: 500,
    },
    [`& .${tooltipClasses.tooltip} .${tooltipClasses.tooltipSpan}`]: {
      fontSize: '16px',
      letterSpacing: '2px', // Adjust the font size as needed
    },
  });

  useEffect(() => {
      printerCategoryList();
    }, []);
  
    function printerCategoryList() {
      api
        .get(`/groupadmin/printer-category/printer-list`, { headers })
        .then((res) => {
          // console.log("taboe" ,res.data.data)
          const options = res.data.data.map((item) => ({
            label: item.name,
            value: item._id,
          }));
          setuser(options);
  
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response?.data?.error?.message);
          }
        });
        api
        .get(`/groupAdmin/branchPrinter/get-printer-name`, { headers })
        .then((res) => {
          // console.log("taboe" ,res.data.data);
          const options = res.data.data.map((item) => ({
            label: `${item.name}  -  ${item?.model_number}`,
            value: item._id,
          }));
          setuser1(options);
  
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response?.data?.error?.message);
          }
        });
        
    }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
              
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="d-flex flex-stack my-3">
                  <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                    <h1 className="anchor fw-bolder" id="custom-form-control">
                    Edit Printer 
                    </h1>
                  </div>
                  <div className="d-flex flex-stack">
                    <div
                      className="d-flex justify-content-end mx-2"
                      data-kt-docs-table-toolbar="base"
                    >
                      <button
                        className="btn btn-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                        onClick={()=>history.goBack()}
                      >
                        Go Back
                      </button>
                    </div>
                  </div>
                </div>
                    <div className="rounded border p-5">
                      
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="row col-sm-12">
                          <form 
                            onSubmit={handleSubmit}
                          >
                            <div
                              className="card card-docs mb-2"
                              style={{
                                boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                              }}
                            >
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="row col-md-12">
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                       Printer Name
                                      </label>
                                      <input
                                        type="text"
                                        value={printer_name}
                                        // readOnly={AuthButton ? false : true}
                                         onChange={(e) =>
                                          setprinter_name(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter Printer Name"
                                      />
                                    
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className=" form-label">
                                      IP Address <span style={{color : "red"}}> *</span>
                                        <CustomWidthTooltip style={{color : "gray" }} className="mx-1" title="Ip address like this 115.42.150.37" placement="right-start">
                                        <InfoIcon />
                                        </CustomWidthTooltip>
                                      </label>
                                      
                                      <input
                                        type="text"
                                        value={ip_address}
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          setip_address(e.target.value.replace(/[^0-9.]/g, ''))
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter IP Address"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row col-md-12">
                                  <div className="col-md-4">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                       Printer Type
                                      </label>

                                      
                                      <Select
                                          className="search-line"
                                          placeholder="Enter Printer Type"
                                          options={user1}
                                          // defaultValue={0}
                                          value={printer_type} 
                                          onChange={(opt) => {
                                          // setbranch_id(opt.value);
                                          setprinter_type(opt);
                                          }}
                                    />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Printer Category
                                      </label>
                                      <Select
                                          className="search-line"
                                          placeholder="Select Printer Category"
                                          options={user}
                                          value={printer_category}
                                          onChange={(opt) => {
                                          setprinter_category(opt);
                                          }}
                                    />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                      Select Branch
                                      </label>
                                      <Select
                                        className="search-line"
                                        placeholder="Search..."
                                        options={
                                          BranchList
                                        }
                                        // defaultValue={0}
                                        value={BranchList__}
                                        onChange={(opt) => {
                                          // setbranch_id(opt.value);
                                          setBranchList__(opt);
                                        }}
                                        // onChange={setbranch_id}
                                      />
                                    </div>
                                  </div>

                                 
                                </div>

                                <div className="row col-md-12">
                        <div className="mb-10">
                        <label className="required form-label">
                              Print Receipt
                        </label>
                        <div>
                              <label>
                              <input
                              type="radio"
                              className="mx-2"
                              name="deliveryOption"
                              // value="yes"
                              // value={enable_invoice == true}
                              checked={enable_invoice}
                              onChange={()=> setenable_invoice(true)}
                              />
                              Yes
                              </label>
                              &nbsp; 
                              &nbsp;
                              &nbsp;
                              &nbsp;
                                
                              <label>
                                
                              <input 
                              type="radio"
                              className="mx-2"
                              name="deliveryOption"
                              checked={!enable_invoice}
                              onChange={()=> setenable_invoice(false)}
                              /> 
                              No
                              </label> 
                        </div>
                        </div>
                              </div>


                              <div className="col-md-12 text-center">
                              <br />
                              <div
                                className="loader"
                                style={{ display: "none" }}
                              >
                                <img src="/assets/loader.gif" />
                              </div>
                            </div>
                            <div className="col-md-12"> {errorDiv} </div>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-4"
                                  >
                                    Save
                                  </button>
                                  <button
                                onClick={()=>history.push("/group_admin/settings/printer/printerList")}
                                  type="button"
                                  className="btn btn-danger"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
branchID: state.branch.branchID,
});
    
const mapDispatchToProps = (dispatch) => {
return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPrinterEdit);

