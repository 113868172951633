import React from 'react';
import { useHistory } from 'react-router-dom';

const GoBackButton = () => {
  const history = useHistory();

  return (
    <button
      onClick={() => history.goBack()}
      className="btn btn-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end"
    >
      Go Back
    </button>
  );
};

export default GoBackButton;
