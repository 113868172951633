import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import Map from "./Map";
import Select from "react-select";
import Swal from "sweetalert2";
import $ from "jquery";
window.jquery = window.$ = $;

export default function GroupAdminDeliveryZones() {
  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  ///////////////////////// Branch List //////////////////////////
  let history = useHistory();

  const [user, setuser] = useState();
  const [branch_id, setbranch_id] = useState([]);

  useEffect(() => {
    groupAdminBranchList();
  }, []);

  function groupAdminBranchList(res) {
    try {
      let customToken = localStorage.getItem("customToken");
      if (customToken) {
        let headers = {
          Authorization: "Bearer " + customToken,
          "Content-Type": "application/json",
        };
        api
          .get(`/groupadmin/branch/restaurantwise`, { headers })
          .then((res) => {
            const tableData = res.data.data;
            // console.log(tableData);
            // setuser(tableData);
            const options = res.data.data.map((item) => ({
              label: item.address,
              value: item._id,
              branch_lat: item.lat,
              branch_long: item.long,
            }));
            setuser(options);
          });
      }
    } catch (err) {
      res.status(404).json({ success: false, message: err.message });
    }
  }

  ///////////////////////// Branch List //////////////////////////

  // const google = window.google;

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url2 = pathnamesplits[3];

  const google = (window.google = window.google ? window.google : {});

  useEffect(() => {
    initialize();
  }, [google, branch_id.branch_lat, branch_id.branch_long]);

  const data = [
    {
      id: 1,
      name: "Park Slope",
      latitude: branch_id.branch_lat,
      longitude: branch_id.branch_long,
      circle: {
        radius: 3000,
        options: {
          strokeColor: "#FF0000",
        },
      },
    },
  ];

  function initialize() {
    var latlng = new google.maps.LatLng(
      branch_id.branch_lat ? branch_id.branch_lat : 22.9869754,
      branch_id.branch_long ? branch_id.branch_long : 72.4948776
    );
    var map = new google.maps.Map(document.getElementById("map"), {
      center: latlng,
      zoom: 13,
      circle: {
        radius: 3000,
        options: {
          strokeColor: "#FF0000",
        },
      },
    });

    var marker = new google.maps.Marker({
      map: map,
      position: latlng,
      draggable: true,
      circle: {
        radius: 3000,
        options: {
          strokeColor: "#FF0000",
        },
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
    var input = document.getElementById("searchInput");
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    var geocoder = new google.maps.Geocoder();
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo("bounds", map);
    var infowindow = new google.maps.InfoWindow();
    autocomplete.addListener("place_changed", function () {
      infowindow.close();
      marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        window.alert("Autocomplete's returned place contains no geometry");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      bindDataToForm(
        place.formatted_address,
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
      infowindow.setContent(place.formatted_address);
      infowindow.open(map, marker);
    });
    // this function will work on marker move event into map
    google.maps.event.addListener(marker, "dragend", function () {
      geocoder.geocode(
        { latLng: marker.getPosition() },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              bindDataToForm(
                results[0].formatted_address,
                marker.getPosition().lat(),
                marker.getPosition().lng()
              );
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);
            }
          }
        }
      );
    });
  }

  function bindDataToForm(address, lat, lng) {
    document.getElementById("location").value = address;
    document.getElementById("searchInput").value = address;
    document.getElementById("lat").value = lat;
    document.getElementById("lng").value = lng;
  }
  google.maps.event.addDomListener(window, "load", initialize);

  /////////////////////////////////   Add Zone ///////////////////////////////

  const [zone_radius, setzone_radius] = useState("");
  const [zone_radius1, setzone_radius1] = useState("");
  const [zone_radius2, setzone_radius2] = useState("");
  const [zone_radius3, setzone_radius3] = useState("");
  const [zone_radius4, setzone_radius4] = useState("");
  const [zone_radius5, setzone_radius5] = useState("");
  const [zone_radius6, setzone_radius6] = useState("");
  const [zone_zip, setzone_zip] = useState("");
  const [min_order_qty, setmin_order_qty] = useState("");
  const [min_order_qty1, setmin_order_qty1] = useState("");
  const [min_order_qty2, setmin_order_qty2] = useState("");
  const [min_order_qty3, setmin_order_qty3] = useState("");
  const [min_order_qty4, setmin_order_qty4] = useState("");
  const [min_order_qty5, setmin_order_qty5] = useState("");
  const [min_order_qty6, setmin_order_qty6] = useState("");
  const [min_order_qtyzip, setmin_order_qtyzip] = useState("");
  const [delivery_cost, setdelivery_cost] = useState("");
  const [delivery_cost1, setdelivery_cost1] = useState("");
  const [delivery_cost2, setdelivery_cost2] = useState("");
  const [delivery_cost3, setdelivery_cost3] = useState("");
  const [delivery_cost4, setdelivery_cost4] = useState("");
  const [delivery_cost5, setdelivery_cost5] = useState("");
  const [delivery_cost6, setdelivery_cost6] = useState("");
  const [delivery_costzip, setdelivery_costzip] = useState("");
  const [no_delivery_cost_from, setno_delivery_cost_from] = useState("");

  const [no_delivery_cost_from1, setno_delivery_cost_from1] = useState("");
  const [no_delivery_cost_from2, setno_delivery_cost_from2] = useState("");
  const [no_delivery_cost_from3, setno_delivery_cost_from3] = useState("");
  const [no_delivery_cost_from4, setno_delivery_cost_from4] = useState("");
  const [no_delivery_cost_from5, setno_delivery_cost_from5] = useState("");
  const [no_delivery_cost_from6, setno_delivery_cost_from6] = useState("");
  const [no_delivery_cost_fromzip, setno_delivery_cost_fromzip] = useState("");
  const [zone_nozip, setzone_nozip] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [error2, setError2] = useState(null);
  const errorDiv2 = error2 ? (
    <div className="text-center alert alert-danger">{error2}</div>
  ) : (
    ""
  );

  const [error3, setError3] = useState(null);
  const errorDiv3 = error3 ? (
    <div className="text-center alert alert-danger">{error3}</div>
  ) : (
    ""
  );

  const [error4, setError4] = useState(null);
  const errorDiv4 = error4 ? (
    <div className="text-center alert alert-danger">{error4}</div>
  ) : (
    ""
  );

  const [error5, setError5] = useState(null);
  const errorDiv5 = error5 ? (
    <div className="text-center alert alert-danger">{error5}</div>
  ) : (
    ""
  );

  const [error6, setError6] = useState(null);
  const errorDiv6 = error6 ? (
    <div className="text-center alert alert-danger">{error6}</div>
  ) : (
    ""
  );

  const [errorzip, setErrorzip] = useState(null);
  const errorDivzip = errorzip ? (
    <div className="text-center alert alert-danger">{errorzip}</div>
  ) : (
    ""
  );

  ///////////////////////// Zone List //////////////////////////

  const [userZone, setuserZone] = useState();
  const [userZoneByZip, setuserZoneByZip] = useState("radius");

  console.log(userZone);

  useEffect(() => {
    groupAdminuserZoneList();
  }, [branch_id.value, userZoneByZip === "zip"]);

  function groupAdminuserZoneList(res) {
    try {
      let customToken = localStorage.getItem("customToken");
      if (customToken) {
        let headers = {
          Authorization: "Bearer " + customToken,
          "Content-Type": "application/json",
        };
        api
          .get(
            `/groupadmin/settings/zone/${branch_id.value}?type=${userZoneByZip}`,
            { headers }
          )
          .then((res) => {
            const tableData = res.data.data;
            setuserZone(tableData);

            // const options = res.data.data.map(item => ({
            //     label: item.address,
            //     value: item._id,
            // }))
            // setuserZone(options)
          });
      }
    } catch (err) {
      res.status(404).json({ success: false, message: err.message });
    }
  }

  ///////////////////////// Zone List //////////////////////////

  //////////////////////////////////// Radius Form ////////////////////////

  function setmin_order_qty_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].min_order_qty = Number(e.target.value);
    // userZone_[position].no_delivery_cost_from = e.target.value;
    // userZone_[position].delivery_cost = e.target.value;
    // userZone_[position].type = "radius";

    // for (let person in userZone_) {
    //     if (person.id === id) {
    //         person.name = newName;
    //     }
    // }
    setuserZone(userZone_);
    // let arr = userZone[position];
    // console.log("Dgdfgdfhf", { ...userZone, zone_radius: e.target.value })
    // setuserZone({ ...userZone, zone_radius: e.target.value })
  }

  function setuserZone_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].zone_radius = Number(e.target.value);
    setuserZone(userZone_);
  }

  function setdelivery_cost_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].delivery_cost = Number(e.target.value);

    setuserZone(userZone_);
  }

  function setno_delivery_cost_from1_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].no_delivery_cost_from = Number(e.target.value);

    setuserZone(userZone_);
  }

  // function LastTimeForm(event) {
  const LastTimeForm = (pos) => (event) => {
    event.preventDefault();
    setError(null);

    if (branch_id.length === 0) {
      setError("Please Select Branch");
    } else {
      // let item = {
      //     branch_id: branch_id.value,
      //     branch_lat: branch_id.branch_lat,
      //     branch_long: branch_id.branch_long,
      //     min_order_qty: Number(min_order_qty),
      //     zone_radius: Number(zone_radius),
      //     delivery_cost: Number(delivery_cost),
      //     no_delivery_cost_from: Number(no_delivery_cost_from),
      //     zone_no: 1,
      //     type: "radius"
      // }
      let item = userZone[pos];
      // item.zone_no = pos + 1;
      item.type = "radius";
      let customToken = localStorage.getItem("customToken");
      if (customToken) {
        let headers = {
          Authorization: "Bearer " + customToken,
          "Content-Type": "application/json",
        };
        api
          .post(`/groupadmin/settings/zone`, item, { headers })
          .then((res) => {
            window.location.reload(true);
          })
          .catch((err) => {
            if (err.response) {
              setError(err.response.data.error.message);
            } else if (err.request) {
              setError(err.request);
            } else {
              setError(err.message);
            }
          });
      }
    }
  };

  ////////////////////////////////////// Radius Form ///////////////////////

  ////////////////////////////////////// Zip Form ///////////////////////

  function setuserZoneZip_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].zone_zip = String(e.target.value);

    setuserZone(userZone_);
  }

  function setmin_order_qtyZip_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].min_order_qty = Number(e.target.value);

    setuserZone(userZone_);
  }

  function setzonenoZip_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].zone_no = Number(e.target.value);

    setuserZone(userZone_);
  }

  function setdelivery_costZip_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].delivery_cost = Number(e.target.value);

    setuserZone(userZone_);
  }

  function setno_delivery_cost_fromZip_(e, position) {
    const userZone_ = [...userZone];
    userZone_[position].no_delivery_cost_from = Number(e.target.value);

    setuserZone(userZone_);
  }

  const LastTimeZip = (pos) => (event) => {
    event.preventDefault();
    setError(null);

    if (branch_id.length === 0) {
      setError("Please Select Branch");
    } else {
      let item = userZone[pos];
      // item.zone_no = pos + 1;
      item.type = "zip";
      console.log(item);

      let customToken = localStorage.getItem("customToken");
      if (customToken) {
        let headers = {
          Authorization: "Bearer " + customToken,
          "Content-Type": "application/json",
        };
        api
          .post(`/groupadmin/settings/zone`, item, { headers })
          .then((res) => {
            window.location.reload(true);
          })
          .catch((err) => {
            if (err.response) {
              setError(err.response.data.error.message);
            } else if (err.request) {
              setError(err.request);
            } else {
              setError(err.message);
            }
          });
      }
    }
  };

  ////////////////////////////////////// Zip Form ///////////////////////

  /////////////////////////// Delete Zone ////////////////////////

  function deleteZone(_id, res) {
    try {
      let customToken = localStorage.getItem("customToken");
      if (customToken) {
        let headers = {
          Authorization: "Bearer " + customToken,
          "Content-Type": "application/json",
        };
        if (customToken) {
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
              api
                .delete(`/groupadmin/settings/zone/${_id}`, { headers })
                .then((res) => {
                  groupAdminuserZoneList();
                });
            }
          });
        }
      }
    } catch (err) {
      res.status(404).json({ success: false, message: err.message });
    }
    groupAdminuserZoneList();
  }

  //////////////////////// Delete Zone ///////////////////////////////////

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="row col-md-12">
                    <div
                      className="col-md-9 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5 d-inline"
                        id="custom-form-control"
                      >
                        Delivery - Zone
                      </h3>
                    </div>

                    <div
                      className="col-md-3 d-inline justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <Select
                        id="select2"
                        required
                        className="search-line"
                        placeholder="Search..."
                        options={
                          !user == ""
                            ? user.map((sponsor) => ({
                                label: sponsor.label,
                                value: sponsor.value,
                                branch_lat: sponsor.branch_lat,
                                branch_long: sponsor.branch_long,
                              }))
                            : "No Option"
                        }
                        onChange={(opt) => setbranch_id(opt)}
                      />
                    </div>
                  </div>

                  <br />
                  <br />
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "order_time"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/delivery/order_time"
                          >
                            Order Time
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "delivery_preparation_time"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/delivery/delivery_preparation_time"
                          >
                            Preparation Time
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "delivery_additional_charges"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/delivery/delivery_additional_charges"
                          >
                            Additional Charges
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "delivery_zones"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/delivery/delivery_zones"
                          >
                            Zones
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            className={
                              url2 === "delivery_setting"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/group_admin/delivery/delivery_setting"
                          >
                            Setting
                          </Link>
                        </li>
                      </ul>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="row">
                                  <div div className="col-md-12">
                                    <div className="rounded border p-10">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control input-controls"
                                            id="searchInput"
                                            placeholder="Enter Your Address"
                                            name="address"
                                            required=""
                                          />
                                        </div>
                                        <Map
                                          center={{
                                            lat: 22.9869754,
                                            lng: 72.4948776,
                                          }}
                                          zoom={12}
                                          places={data}
                                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAl5i_Lv4MQwX4rz890fRzMIElAaHBDrDw&libraries=places"
                                          loadingElement={
                                            <div style={{ height: `100%` }} />
                                          }
                                          containerElement={
                                            <div style={{ height: `400px` }} />
                                          }
                                          mapElement={
                                            <div style={{ height: `100%` }} />
                                          }
                                        />
                                        <div
                                          className="map"
                                          id="map"
                                          style={{ width: "100%", height: 400 }}
                                        />
                                        <div className="form_area">
                                          <input
                                            type="hidden"
                                            name="location"
                                            id="location"
                                          />
                                          <input
                                            type="hidden"
                                            name="lat"
                                            id="lat"
                                          />
                                          <input
                                            type="hidden"
                                            name="lng"
                                            id="lng"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                  </div>

                                  <div div className="col-md-12">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={"radius"}
                                          onChange={(e) =>
                                            setuserZoneByZip(e.target.value)
                                          }
                                        />
                                        <label className="form-check-label mx-3">
                                          <h3>Delivery Zone By Radius</h3>
                                        </label>
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label"
                                        >
                                          GPS LAT
                                        </label>
                                        <input
                                          type="text"
                                          className="filterme form-control form-control-solid"
                                          value={branch_id.branch_lat}
                                          // onChange={(e) => setbranch_id(e.target.value)}
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label"
                                        >
                                          GPS LONG
                                        </label>
                                        <input
                                          type="text"
                                          className="filterme form-control form-control-solid"
                                          value={branch_id.branch_long}
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        <div className="row">
                                          {!userZone == "" &&
                                          userZone[0].type === "radius"
                                            ? userZone.map((look, index) => (
                                                <div
                                                  className="col-md-6"
                                                  key={index}
                                                >
                                                  <form
                                                    onSubmit={LastTimeForm(
                                                      index
                                                    )}
                                                  >
                                                    <div className="rounded border p-10">
                                                      <div className="mb-10">
                                                        <h6>
                                                          Zone {look.zone_no}
                                                        </h6>
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          Radius (In Meter)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            look.zone_radius
                                                          }
                                                          onChange={(e) =>
                                                            setuserZone_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          Min. Order Quantity
                                                          (In CHF)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            look.min_order_qty
                                                          }
                                                          onChange={(e) =>
                                                            setmin_order_qty_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          Delivery Costs (In
                                                          CHF)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            look.delivery_cost
                                                          }
                                                          onChange={(e) =>
                                                            setdelivery_cost_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          No Delivery Cost From
                                                          (In CHF)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            look.no_delivery_cost_from
                                                          }
                                                          onChange={(e) =>
                                                            setno_delivery_cost_from1_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-md-12">
                                                        {" "}
                                                        {errorDiv}{" "}
                                                      </div>
                                                      <div className="modal-footer flex-center">
                                                        <button
                                                          type="submit"
                                                          className="btn btn-primary m-2"
                                                        >
                                                          {" "}
                                                          Save{" "}
                                                        </button>
                                                        {userZone.length !==
                                                          1 && (
                                                          <button
                                                            type="button"
                                                            className="btn btn-danger m-2"
                                                            onClick={() =>
                                                              deleteZone(
                                                                look._id
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            Delete{" "}
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <br />
                                                  </form>
                                                </div>
                                              ))
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="row">
                                  <div>
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value="zip"
                                          onChange={(e) =>
                                            setuserZoneByZip(e.target.value)
                                          }
                                        />
                                        <label className="form-check-label mx-3">
                                          <h3>Delivery Zone By Zip</h3>
                                        </label>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="row">
                                          {!userZone == "" &&
                                          userZone[0].type === "zip"
                                            ? userZone.map((lookzip, index) => {
                                                return (
                                                  <div className="col-md-6 rounded border p-10">
                                                    <form
                                                      onSubmit={LastTimeZip(
                                                        index
                                                      )}
                                                    >
                                                      <div className="row col-md-12">
                                                        <div className="row col-md-6">
                                                          <div className="mb-10">
                                                            <label
                                                              htmlFor="exampleFormControlInput1"
                                                              className="form-label"
                                                            >
                                                              ZIP
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="filterme form-control form-control-solid"
                                                              value={
                                                                lookzip.zone_zip
                                                              }
                                                              onChange={(e) =>
                                                                setuserZoneZip_(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                          <div className="mb-10">
                                                            <label
                                                              htmlFor="exampleFormControlInput1"
                                                              className="form-label"
                                                            >
                                                              Delivery Zone
                                                            </label>
                                                            <select
                                                              className="form-select"
                                                              data-control="select2"
                                                              data-placeholder="Select an option"
                                                              value={
                                                                lookzip.zone_no
                                                              }
                                                              onChange={(e) =>
                                                                setzonenoZip_(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              {/* <option >Select Zone</option> */}
                                                              <option value="1">
                                                                Zone 1
                                                              </option>
                                                              <option value="2">
                                                                Zone 2
                                                              </option>
                                                              <option value="3">
                                                                Zone 3
                                                              </option>
                                                              <option value="4">
                                                                Zone 4
                                                              </option>
                                                              <option value="5">
                                                                Zone 5
                                                              </option>
                                                              <option value="6">
                                                                Zone 6
                                                              </option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          Min. Order Quantity
                                                          (In CHF)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            lookzip.min_order_qty
                                                          }
                                                          // onChange={(e) => setuserZoneZip_(e, index)}
                                                          onChange={(e) =>
                                                            setmin_order_qtyZip_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          Delivery Costs (In
                                                          CHF)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            lookzip.delivery_cost
                                                          }
                                                          onChange={(e) =>
                                                            setdelivery_costZip_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="mb-10">
                                                        <label
                                                          htmlFor="exampleFormControlInput1"
                                                          className="form-label"
                                                        >
                                                          No Delivery Cost From
                                                          (In CHF)
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="filterme form-control form-control-solid"
                                                          value={
                                                            lookzip.no_delivery_cost_from
                                                          }
                                                          onChange={(e) =>
                                                            setno_delivery_cost_fromZip_(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>

                                                      <div className="col-md-12">
                                                        {" "}
                                                        {errorDivzip}{" "}
                                                      </div>
                                                      <div className="modal-footer flex-center">
                                                        <button
                                                          type="submit"
                                                          className="btn btn-primary m-2"
                                                        >
                                                          {" "}
                                                          Save{" "}
                                                        </button>
                                                        {userZone.length !==
                                                          1 && (
                                                          <button
                                                            type="button"
                                                            className="btn btn-danger m-2"
                                                            onClick={() =>
                                                              deleteZone(
                                                                lookzip._id
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            Delete{" "}
                                                          </button>
                                                        )}
                                                      </div>
                                                    </form>
                                                  </div>
                                                );
                                              })
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <Helmet>
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>
        <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
        <script src="/assets/js/custom/widgets.js"></script>
        <script src="/assets/js/custom/apps/chat/chat.js"></script>
        <script src="/assets/js/custom/modals/create-app.js"></script>
        <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
        <script src="/assets/plugins/custom/datatables/datatables.bundle.js"></script>
        <script src="/assets/js/custom/documentation/documentation.js"></script>
        <script src="/assets/js/custom/documentation/general/datatables/advanced.js"></script>
        <script src="/assets/js/custom/authentication/sign-in/general.js"></script>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAl5i_Lv4MQwX4rz890fRzMIElAaHBDrDw&libraries=places"></script>
      </Helmet>
    </React.Fragment>
  );
}
