import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

export const LoadingBackdrop = ({ open }) => {
    const theme = useTheme();
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
