import React from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link } from "react-router-dom";
import useDrawer from "../../../../context/DrawerContext";
import { CouponsPermissions, VouchersPermissions, userPermissionType } from "../../../Comman/UserPermission";

export default function GroupAdminCoupon_Voucher() {
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const userPermissionType_ = userPermissionType === "groupadmin";

  const cardData = [
    {
      to: "/group_admin/coupon_voucher_management/coupon_management",
      iconClass: "bi bi-cash-coin",
      title: "Coupon Management",
      permissionCheck: CouponsPermissions?.access?.length > 0,
    },
    {
      to: "/group_admin/coupon_voucher_management/voucher_management/voucher_templates",
      iconClass: "fa-regular fa-gift-card",
      title: "Voucher Management",
      permissionCheck: VouchersPermissions?.access?.length > 0,
    },
  ];

  console.log("VouchersPermissions" ,VouchersPermissions)

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="col-md-12 mb-7">
                      <h1>Coupons & Vouchers</h1>
                    </div>
                    <div className="row g-6">
                      {cardData
                        .filter((card) => userPermissionType_ || card.permissionCheck)
                        .map((card, index) => (
                          <div className="col-md-3" key={index}>
                            <Link
                              to={card.to}
                              className="card bg-white hoverable mb-xl-8"
                              style={{
                                boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                              }}
                            >
                              <div className="card-body p-2">
                                <center>
                                  <div className="my-3">
                                    <i className={card.iconClass + " text-dark fs-5x"}></i>
                                  </div>
                                  <div className="mb-2">
                                    <h2 className="fw-normal">{card.title}</h2>
                                  </div>
                                </center>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
