import Swal from "sweetalert2";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { showToastCommon } from "../Toastify/Toastify";
import { showSuccessNotification } from "../../Comman/Swal/Swal";

export function submitEditForm(url, item, history, showError, clearError, successMessage, callback) {
  api
    .patch(url, item, { headers })
    .then(() => {
      showSuccessNotification(successMessage);
      if (callback) {
        callback();
      } else {
        history.goBack();
      }
    })
    .catch((err) => {
      if (err.response) {
            showError(err.response?.data?.error?.message);
      } else if (err.request) {
            showError(err.request);
      } else {
            showError(err.message);
      }
    });
}
