import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import { connect } from "react-redux";
import NoteState from "../CommanBranch/NoteState";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import $ from "jquery";
import headers from "../../../../../CommonApi/headers";
import { useStyles } from "../../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../../Comman/TableOption";
import CommonDelivery from "./CommonDelivery";
import useDrawer from "../../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
window.jquery = window.$ = $;

function GroupAdminDeliveryAdditionalCharges(props) {

  const { branchID } = props;

  // console.log("branchID", branchID)

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url2 = pathnamesplits[4];
  let history = useHistory();

  ////////////////////////// Addition Charges Order List /////////////////////

  const [AdditionCharges, setAdditionCharges] = useState({});
  const [ADD, setADD] = useState([]);

  useEffect(() => {
    if (branchID !== null) {
      groupAdminAdditionChargesList();
    }
  }, [branchID]);

  function groupAdminAdditionChargesList() {
    api
      .get(`/groupadmin/charges/delivery/${branchID}`, { headers })
      .then((res) => {
        $("#myTabContent1").show();
        $("#myTabContent").hide();
        const tableData = res.data.data;
        const tabledata = res.data.data.charges;

        console.log("ss", tableData)
        setADD(tabledata);
        setAdditionCharges(tableData);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.error?.message) {
            setAdditionCharges({});
            setADD([]);
            $("#myTabContent").show();
            $("#myTabContent1").hide();
          }
        }
      });
  }

  const classes = useStyles();

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Pos ID",
      field: "pos_id",
    },
    {
      title: "Price",
      field: "price",
    },
    {
      title: "Name EN",
      field: "name_EN",
    },
    {
      title: "Name AR",
      field: "name_AR",
    },
    {
      title: "Name GE",
      field: "name_DE",
    },

    {
      title: "Name FR",
      field: "name_FR",
    },
    {
      title: "Name IT",
      field: "name_IT",
    },
  ];

  function deleteAdditionCharges(_id) {
    let items = {
      charge_id: _id,
      type: "delivery",
    };
    deleteCommonFunction(`/groupadmin/charges/${branchID}`, "Charges has been successfully deleted.", () => groupAdminAdditionChargesList(), items);
  }

  ////////////////////////// Addition Charges Order List /////////////////////

  /////////////////////////////// Addition Charges ////////////////////////

  const [charges_heading_name_DE, setcharges_heading_name_DE] = useState("");
  const [charges_heading_name_EN, setcharges_heading_name_EN] = useState("");
  const [charges_heading_name_FR, setcharges_heading_name_FR] = useState("");
  const [charges_heading_name_IT, setcharges_heading_name_IT] = useState("");
  const [charges_heading_name_AR, setcharges_heading_name_AR] = useState("");

  const [data, setForm] = useState([
    // {
    //     pos_id: '',
    //     price: '',
    //     name_DE: "",
    //     name_EN: '',
    //     name_FR: "",
    //     name_IT: '',
    // }
  ]);

  const handleCheck = (index, e, selected) => {
    let temp = [...data];
    temp[index][selected] = e.target.value;
    setForm(temp);
  };

  const haldleNewRow = () => {
    setForm([
      ...data,
      {
        pos_id: "",
        price: "",
        name_DE: "",
        name_EN: "",
        name_FR: "",
        name_IT: "",
        name_AR: "",
      },
    ]);
  };

  const handleremove = (index) => {
    let list = [...data];
    list.splice(index, 1);
    setForm(list);
  };

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (branchID == null) {
      setError("Please Select Branch");
    } else {
      var item = {};

      item["branch_id"] = branchID;
      item["type"] = "delivery";
      item["charges"] = data;
      if (charges_heading_name_EN) {
        item["charges_heading_name_EN"] = charges_heading_name_EN;
      }
      if (charges_heading_name_DE) {
        item["charges_heading_name_DE"] = charges_heading_name_DE;
      }
      if (charges_heading_name_FR) {
        item["charges_heading_name_FR"] = charges_heading_name_FR;
      }
      if (charges_heading_name_IT) {
        item["charges_heading_name_IT"] = charges_heading_name_IT;
      }
      if (charges_heading_name_AR) {
        item["charges_heading_name_AR"] = charges_heading_name_AR;
      }

      api
        .post(`/groupadmin/charges`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminAdditionChargesList();

          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  };
  //////////////////////////////  Addition Charges //////////////////////////////////

  function EditDetails(_id) {
    history.push(
      `/group_admin/settings/delivery/edit_additional_charges/${_id}`,
      { branch_id: branchID }
    );
  }

  const [error3, setError3] = useState(null);
  const errorDiv3 = error3 ? (
    <div className="text-center alert alert-danger">{error3}</div>
  ) : (
    ""
  );

  function EditHeading() {
    if (branchID == null) {
      setError3("Please Select Branch");
    } else {
      history.push(
        `/group_admin/settings/delivery/edit_additional_charges_heading/${branchID}`
      );
    }
  }

  function AddCharges() {
    if (branchID === null) {
      setError3("Please Select Branch");
    } else {
      history.push(
        `/group_admin/settings/delivery/add_additional_charges/${branchID}`
      );
    }
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  const languages = [
    { label: "Naming EN", key: "charges_heading_name_EN" },
    { label: "Naming AR", key: "charges_heading_name_AR", placeholder: "Enter header charges AR" },
    { label: "Naming GE", key: "charges_heading_name_DE", placeholder: "Enter header charges GE" },
    { label: "Naming FR", key: "charges_heading_name_FR", placeholder: "Enter header charges FR" },
    { label: "Naming IT", key: "charges_heading_name_IT", placeholder: "Enter header charges IT" }
  ];

  const checkPermission = (key, action) => {
    return userPermissionType === "groupadmin" ||
      UserPermissionAllData?.role_details?.settings?.find(permission =>
        permission?.key === key && permission?.access?.includes(action)
      );
  };


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Delivery - Addition Charges
                        </h3>
                      </div>

                      <div
                        className="col-md-3 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <NoteState />
                      </div>
                    </div>
                    <div className="rounded border p-10">
                      <CommonDelivery />

                      {/* {(AdditionCharges._id === branchID) ? "" : */}
                      <div>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_1"
                            role="tabpanel"
                          >
                            <div className="py-1">
                              <div className="card card-docs mb-2">
                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                  <div className="mb-10">
                                    <h6>
                                      Additional charges for Packaging options.
                                    </h6>
                                  </div>
                                  <form onSubmit={handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            Naming EN
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            value={charges_heading_name_EN}
                                            placeholder="Enter header charges EN"
                                            required
                                            onChange={(e) =>
                                              setcharges_heading_name_EN(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Naming AR
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            value={charges_heading_name_AR}
                                            placeholder="Enter header charges AR"
                                            onChange={(e) =>
                                              setcharges_heading_name_AR(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Naming GE
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Enter header charges GE"
                                            className="form-control form-control-solid"
                                            value={charges_heading_name_DE}
                                            onChange={(e) =>
                                              setcharges_heading_name_DE(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Naming FR
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            value={charges_heading_name_FR}
                                            placeholder="Enter header charges FR"
                                            onChange={(e) =>
                                              setcharges_heading_name_FR(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Naming IT
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            value={charges_heading_name_IT}
                                            placeholder="Enter header charges IT"
                                            onChange={(e) =>
                                              setcharges_heading_name_IT(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <hr />

                                      {data.length > 0 && (
                                        <div className="row col-md-12">
                                          {data.map((details, index) => (
                                            <>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="required form-label">
                                                    Name English
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    required
                                                    value={details?.name_EN}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "name_EN"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="form-label">
                                                    Name Arabic
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={details?.name_AR}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "name_AR"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="form-label">
                                                    Name German
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={details?.name_DE}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "name_DE"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="form-label">
                                                    Name France
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={details?.name_FR}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "name_FR"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="form-label">
                                                    Name italian
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={details?.name_IT}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "name_IT"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="required form-label">
                                                    Price (CHF)
                                                  </label>
                                                  <input
                                                    type="number"
                                                    className="form-control form-control-solid"
                                                    required
                                                    value={details?.price}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "price"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-5">
                                                  <label className="form-label">
                                                    Pos Id
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={details?.pos_id}
                                                    onChange={(e) =>
                                                      handleCheck(
                                                        index,
                                                        e,
                                                        "pos_id"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className="col-md-6"
                                                style={{
                                                  marginTop: "25px",
                                                }}
                                              >
                                                <div className="mb-10">

                                                  <button
                                                    type="button"
                                                    id="kt_modal_new_address_submit"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                      handleremove(index)
                                                    }
                                                  >
                                                    <span className="indicator-label">
                                                      Delete
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>

                                              <hr />
                                            </>
                                          ))}
                                        </div>
                                      )}

                                      <div className="col-md-12" id="adc">
                                        <div className="mb-10">
                                          <button
                                            onClick={haldleNewRow}
                                            type="button"
                                            id="kt_modal_new_address_submit"
                                            className="btn btn-primary"
                                          >
                                            <span className="indicator-label">
                                              Add Option
                                            </span>
                                          </button>{" "}
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        {" "}
                                        {errorDiv}{" "}
                                      </div>

                                      <div className="modal-footer flex-center">

                                        {(userPermissionType === "groupadmin" ||
                                          UserPermissionAllData?.role_details?.settings?.find(permission =>
                                            permission?.key === "delivery" &&
                                            (permission?.access?.includes("create"))
                                          )) && (
                                            <button type="submit" className="btn btn-primary">
                                              <span className="indicator-label">Add Charges</span>
                                            </button>)}

                                        <button
                                          className="btn btn-danger m-2"
                                          onClick={() => history.goBack()}
                                        >
                                          {" "}
                                          Cancel{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* } */}

                      <div
                        style={{ display: "none" }}
                        className="tab-content"
                        id="myTabContent1"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-1">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="mb-10">
                                  <h4>Additional charges List</h4>
                                </div>
                                <div className="my-5">
                                  {languages.map(({ label, key, placeholder }) => (
                                    <div className="col-md-12" key={key}>
                                      <div className="mb-10">
                                        <label className={label === "Naming EN" ? "required form-label" : "form-label"}>{label}</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          value={AdditionCharges[key] || ""}
                                          placeholder={placeholder || ""}
                                        />
                                      </div>
                                    </div>
                                  ))}

                                  <div className="col-md-12">{errorDiv3}</div>

                                  <div className="modal-footer flex-center">
                                    {checkPermission("delivery", "edit") && (
                                      <button onClick={EditHeading} type="submit" className="btn btn-primary">
                                        <span className="indicator-label">Edit</span>
                                      </button>
                                    )}

                                    <button className="btn btn-danger m-2" onClick={() => history.goBack()}>
                                      Cancel
                                    </button>
                                  </div>

                                  <div className={classes.root}>
                                    <MaterialTable
                                      title=""
                                      data={ADD}
                                      columns={columns}
                                      options={Tableoptions}
                                      actions={[
                                        checkPermission("delivery", "edit") && {
                                          icon: "edit",
                                          tooltip: "Edit Details",
                                          onClick: (event, rowData) => EditDetails(rowData._id)
                                        },
                                        checkPermission("delivery", "create") && {
                                          icon: () => (
                                            <button
                                              onClick={AddCharges}
                                              type="button"
                                              className="btn btn-primary addtimes"
                                              style={{ fontSize: "10px" }}
                                            >
                                              Add
                                            </button>
                                          ),
                                          tooltip: "Add Charges",
                                          isFreeAction: true
                                        },
                                        checkPermission("delivery", "delete") && {
                                          icon: "delete",
                                          tooltip: "Delete",
                                          onClick: (event, rowData) => deleteAdditionCharges(rowData._id)
                                        }
                                      ].filter(Boolean)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAdminDeliveryAdditionalCharges);
