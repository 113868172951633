import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import api from "../../CommonApi/axios";
import Swal from "sweetalert2";

export default function ForgatePassword() {
  let history = useHistory();

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var CodOrderID = String(pathnamesplits[1]);
  var CodOrderCode = String(pathnamesplits[2]);

  const [password, setpassword] = useState("");
  const [Confirmpassword, setConfirmpassword] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (
      password.toString().toLocaleLowerCase() !==
      Confirmpassword.toString().toLocaleLowerCase()
    ) {
      setError("New password and confirm password does not match");
      return;
    } else {
      let item = {
        password: password,
      };
      api
        .post(`/groupadmin/auth/forgotpassword/code/${CodOrderCode}`, item)
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Forgot password has been done succesfully.",
            showConfirmButton: false,
            timer: 2000,
          });
          history.push("/");
        })
        .catch((err) => {
          if (err.response) {
            var ermes = err.response.data.error.message;
            setError(ermes);
          }
        });
    }
  }

  const toggleIsPasswordShowValue = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);

  return (
    <React.Fragment>
      <div id="kt_body" className="bg-body">
        <div className="d-flex flex-column flex-root">
          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid"
            style={{ minHeight: "100vh" }}
          >
            <div className="row col-md-12">
              <div className="col-md-5">
                {" "}
                <img
                  alt="Logo"
                  style={{ height: "100vh", width: "100%", objectFit: "fill" }}
                  src="/assets/media/illustrations/sketchy-1/beth-d--lw_lvc9hq8-unsplash.jpg"
                />
              </div>
              <div className="col-md-7">
                <div className="d-flex flex-column flex-lg-row-fluid py-10">
                  <div className="d-flex flex-center flex-column flex-column-fluid">
                    <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                      <form className="form w-100" onSubmit={handleSubmit}>
                        <div className="text-center">
                          <img
                            style={{
                              height: "115px",
                            }}
                            alt="Logo"
                            src={
                              "/assets/media/illustrations/sketchy-1/Kitchen Parameter Logo (3).png"
                            }
                            // className="h-80px mt-5 logo"
                          />
                        </div>
                        <div className="text-center mb-10">
                          <h1
                            className="text-dark mb-3"
                            style={{ fontSize: "30px" }}
                          >
                            Restaurant Group Admin
                          </h1>
                        </div>

                        <div className="d-flex align-items-center mb-10">
                          <div className="border-bottom border-gray-300 w-25 mx-5"></div>
                          <span className="fw-bold text-black-400 fs-7 mx-3">
                            Forgot Password
                          </span>
                          <div className="border-bottom border-gray-300 w-25 mx-5"></div>
                        </div>

                        <div className="fv-row">
                          <label className="form-label required fs-6 fw-bolder text-dark mb-2">
                            New Password
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control form-control-lg form-control-solid border-dark mb-10"
                              placeholder="Enter new  password"
                              minLength={6}
                              type={isPasswordShow ? "text" : "password"}
                              value={password}
                              required
                              onChange={(e) => setpassword(e.target.value)}
                            />
                            {password && (
                              <span
                                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                data-kt-password-meter-control="visibility"
                                onClick={toggleIsPasswordShowValue}
                              >
                                <i className="bi bi-eye-slash fs-2" />
                                <i className="bi bi-eye fs-2 d-none" />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="fv-row">
                          <label className="form-label required fs-6 fw-bolder text-dark mb-2">
                            Confirm Password
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control form-control-lg form-control-solid border-dark mb-10"
                              minLength={6}
                              type={isConfirmPasswordShow ? "text" : "password"}
                              value={Confirmpassword}
                              required
                              placeholder="Enter confirm password"
                              onChange={(e) =>
                                setConfirmpassword(e.target.value)
                              }
                            />
                            {Confirmpassword && (
                              <span
                                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                data-kt-password-meter-control="visibility"
                                onClick={() =>
                                  setIsConfirmPasswordShow(
                                    !isConfirmPasswordShow
                                  )
                                }
                              >
                                <i className="bi bi-eye-slash fs-2" />
                                <i className="bi bi-eye fs-2 d-none" />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12"> {errorDiv} </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary w-100 mb-5"
                          >
                            <span className="indicator-label">Login</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>
        <script src="/assets/js/custom/authentication/sign-in/general.js"></script>
      </Helmet>
    </React.Fragment>
  );
}
