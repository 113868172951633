import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import Swal from "sweetalert2";
import headers from "../../../../CommonApi/headers";
import $ from "jquery";
import useDrawer from "../../../../context/DrawerContext";
import CommonPage from "./CommonPage";
import BackButtonSimpal from "./BackButtonSimpal";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import BackButtonForProdctu from "./BackButtonForProdctu";
import DeleteIcon from '@mui/icons-material/Delete';
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { deleteCommonFunction } from "../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { showToastCommon } from "../../../Comman/Toastify/Toastify";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";

window.jquery = window.$ = $;

export default function GroupAdminAddOn() {
  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);
  let history = useHistory();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];
  let location = useLocation();

  const [data, setForm] = useState([]);

  ///////////////////////// Get Delivery prep time /////////////////////////
  const [user, setuser] = useState([]);

  // console.log("location", location?.state?.productID);
  useEffect(() => {
    groupAdminAddOnListById();
  }, [location?.state?.productID]);

  function groupAdminAddOnListById() {
    api
      .get(`/groupadmin/product/${location?.state?.productID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res?.data?.data[0]?.options;
        setuser(tableData);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const [error, setError] = useState(null);

  const handleRemoveSubMenu = (mainIndex, itemIndex) => {
    const newUser = [...user];
    newUser[mainIndex].items = newUser[mainIndex].items.filter((_, i) => i !== itemIndex);
    setuser(newUser);
  };



  // const handleItemChange = (mainIndex, itemIndex, field, value) => {
  //   const newUser = [...user];
  //   newUser[mainIndex].items[itemIndex][field] = value;
  //   setuser(newUser);
  // };

  const handleItemChange = (mainIndex, itemIndex, field, value) => {
    const newUser = [...user];
    newUser[mainIndex].items[itemIndex][field] = field === "price" ? parseFloat(value) : value;
    setuser(newUser);
  };

  const handleAddSubMenu = (mainIndex) => {
    const newUser = [...user];
    if (!newUser[mainIndex].items) {
      newUser[mainIndex].items = [];
    }
    newUser[mainIndex].items.push({ name: "", price: "", is_active: true });
    setuser(newUser);
  };

  function handleInputChange(index, field, value) {
    const newUser = [...user];
    newUser[index][field] = value;
    setuser(newUser);
    if (field === "title" && value.trim() !== "") {
      setError(null);
    }
  }

  function handleSubmit(id) {
    setError(null);

    const item = user.find((u) => u._id === id);

    if (!item) {
      setError("Item not found.");
      return;
    }

    if (item.title.trim() === "") {
      setError("Title cannot be empty.");
      return;
    }


    for (let i = 0; i < item.items.length; i++) {
      const currentItem = item.items[i];
      if (currentItem.name.trim() === "") {
        setError(`Name cannot be empty.`);
        return;
      }
      if (String(currentItem.price).trim() === "") {  // Convert price to string
        setError(`Price cannot be empty.`);
        return;
      }
    }
    // console.log("item", item);
    // return

    api
      .patch(
        `/groupadmin/product/options/${location.state.productID}`,
        item,
        { headers }
      )
      .then((res) => {
        showSuccessNotification("Your work has been saved successfully");
        groupAdminAddOnListById();
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }


  function delete_(id_) {
    let item = { _id: id_ };

    deleteCommonFunction(
      `/groupadmin/product/customization/${location.state.productID}`,
      "Associated category configurations have been successfully deleted.",
      () => groupAdminAddOnListById(),
      item
    );
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  const handleRemoveSubMenuApi = (main, id) => {
    const updatedItems = main?.items?.filter(item => item._id !== id);

    const updatedMain = {
      ...main,
      items: updatedItems
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`/groupAdmin/product/options/${location.state.productID}`, updatedMain, { headers })
          .then(() => {
            showSuccessNotification("Options has been successfully deleted.")
            groupAdminAddOnListById();
          })
          .catch((err) => {
            if (err.response) {
              showToastCommon(err?.response?.data?.error?.message);
            }
          });
      }
    });
  };

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Menu Management
                    </h1>
                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Product - Options
                        </h3>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <BackButtonSimpal
                            name={"Go Back"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="rounded border p-10">
                      <CommonPage />
                      <br />
                      <div className="d-flex flex-stack my-5">
                        <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                          <dl>
                            <dt>
                              <h3 className="form-check-label mx-2">Options</h3>
                            </dt>
                          </dl>
                        </div>
                        <div className="d-flex flex-stack">
                          <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                            {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) && (
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(
                                    `/group_admin/menu_management/menu_category/menu_products/options/${location.state.productID}`,
                                    {
                                      // menuId: menuId,
                                      // branchID: branchID,
                                      // category_id: catagoryid,
                                    }
                                  )
                                }
                                className="btn btn-primary addtimes"
                              >
                                Add New
                              </button>
                            )}

                          </div>
                        </div>
                      </div>
                      {user?.length > 0 ? (
                        user.map((main, mainIndex) => (
                          <div className="rounded border p-5" key={mainIndex}>
                            <div className="card card-docs mb-10">
                              <div className="rounded border p-10">
                                <div className="row mb-8">
                                  <div className="col-md-4">
                                    <h4>{main?.title}</h4>
                                  </div>
                                  <div className="col-md-8 text-end">
                                    {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) &&(

                                    <DeleteIcon onClick={() => delete_(main?._id)} />
                                    )}
                                  </div>
                                </div>
                                <hr />
                                <div className="row mt-8 mb-8">
                                  <div className="col-md-3">
                                    <p>Options Title</p>
                                  </div>
                                  <div className="col-md-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Title"
                                      value={main?.title}
                                      onChange={(e) => handleInputChange(mainIndex, "title", e.target.value)}
                                    />
                                  </div>
                                  <div className="col-md-4 mt-4">
                                    <input
                                      className="form-check-input "
                                      type="checkbox"
                                      id="checkbox1"
                                      checked={main?.is_required}
                                      onChange={(e) => handleInputChange(mainIndex, "is_required", e.target.checked)}
                                    />
                                    <label className="form-check-label mx-1 me-4" htmlFor="checkbox1">
                                      Required
                                    </label>

                                    <input
                                      className="form-check-input mx-1"
                                      type="checkbox"
                                      id="checkbox2"
                                      checked={main?.multiple_choices}
                                      onChange={(e) => handleInputChange(mainIndex, "multiple_choices", e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="checkbox2">
                                      Multiple Choices
                                    </label>
                                  </div>
                                </div>
                                <hr />
                                <div className="row mt-8">
                                  <div className="col-md-3">
                                    <p>Options</p>
                                  </div>
                                  <div className="col-md-9">
                                    {main?.items?.map((item, itemIndex) => (
                                      <div className="row" key={itemIndex}>
                                        <div className="col-md-6 mb-5 d-flex align-items-center">
                                          <div className="col-md-1">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={item.is_active}
                                              onChange={(e) => handleItemChange(mainIndex, itemIndex, "is_active", e.target.checked)}
                                            />
                                          </div>
                                          <div className="col-md-11 mb-5">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Name"
                                              value={item.name}
                                              required
                                              onChange={(e) => handleItemChange(mainIndex, itemIndex, "name", e.target.value)}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 mb-5">
                                          <input
                                            type="number"
                                            className="form-control filterme"
                                            placeholder="Enter Price"
                                            value={item.price}
                                            required
                                            onChange={(e) => handleItemChange(mainIndex, itemIndex, "price", e.target.value)}
                                          />
                                        </div>
                                        <div className="col-md-1 d-flex align-items-center">
                                          <DeleteIcon
                                            // onClick={() => handleRemoveSubMenu(mainIndex, itemIndex)}
                                            onClick={() => item?._id ?
                                              (userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) &&
                                              handleRemoveSubMenuApi(main, item?._id) : handleRemoveSubMenu(mainIndex, itemIndex)}
                                            className="fs-1"
                                          />
                                        </div>
                                      </div>
                                    ))}

                                  </div>
                                </div>
                                <div className="row col-md-12 mt-5">
                                  <div className="col-md-3"></div>
                                  <div className="col-md-9">
                                    <button
                                      style={{ fontWeight: 400 }}
                                      type="button"
                                      onClick={() => handleAddSubMenu(mainIndex)}
                                      className="btn btn-danger"
                                    >
                                      Add More
                                    </button>
                                  </div>
                                </div>
                                <br />
                                <hr />
                                <ErrorMessage error={error} />
                                <div className="flex-center d-flex">
                                  <button type="button" id="kt_modal_new_address_submit" className="btn btn-primary"
                                    onClick={() => handleSubmit(main._id)}
                                  >
                                    Save
                                  </button>
                                  <BackButtonForProdctu
                                    name={"Cancel"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="card card-docs mb-10">
                          <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                            <h5 className="text-center">No data available.</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </main>
                </div>

              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
