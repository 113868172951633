import React from "react";
import { Bar } from "react-chartjs-2";
import "./BarChart.css"
import "./Common.css"
import { getBarchatOption } from "./ChartOption";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function BarChart({ chartName, chartData, Type, CurrencyLabel }) {



  // console.log("chartData" ,chartData)

  const sections = [
    {
      name: 'Sales',
      backgroundColor: '#FB923C',
      value: chartData && Number(chartData?.totals?.totalSales || 0).toFixed(2),
      prefix: CurrencyLabel,
    },
    {
      name: 'Order Count',
      backgroundColor: '#9A3412',
      value: chartData && Number(chartData?.totals?.totalOrders || 0),
      prefix: '',
    },
  ];


  return (
    <React.Fragment>
      <div className="frame8New2">
        <div className="overlap-group">
          <div className="text-wrapper">{chartName}</div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {sections?.map((section, index) => (
            <div className="contan_div" key={index}>
              <div className="contan_dev_2" style={{ background: section.backgroundColor }}>
                {" "}
              </div>
              <div className="name_style" style={{ color: "var(--gray-500, #667085)" }}>
                {section.name}: {section?.prefix} {Number(section.value)}
              </div>
            </div>
          ))}
        </div>

        <div style={{ position: "relative", margin: "auto", height: "280px" }}>
          <Bar
            data={chartData}
            options={getBarchatOption(Type)}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default BarChart;
