import React, { useState, useEffect } from "react";
import {  useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import DeleteIcon from '@mui/icons-material/Delete';
import $ from "jquery";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import useError from "../../../../Comman/Error/useError";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import CommonCatagory from "./Catagory/CommonCatagory";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { MenuPermissions, userPermissionType } from "../../../../Comman/UserPermission";

window.jquery = window.$ = $;


function PopularChoices() {
    const { id, menuId, branchID } = useParams();

    const [data, setData] = useState([{ title: "", name: "", additional_products_id: "", product_list: [] }]);
    const [title_for_additional_products, settitle_for_additional_products] = useState("");
    const [description_for_additional_products, setdescription_for_additional_products] = useState("");


    useEffect(() => {
        getDetails();
    }, [id]);

    function getDetails() {
        api
            .get(`/groupadmin/category/additionalProducts/${id}`, { headers })
            .then((res) => {
                const tableData = res?.data?.data?.additional_products;
                setData(tableData);
                settitle_for_additional_products(res?.data?.data?.title_for_additional_products || "");
                setdescription_for_additional_products(res?.data?.data?.description_for_additional_products || "")
                // console.log("tableData", tableData);
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err?.response?.data);
                }
            });
    }

    let history = useHistory();
    const { error, showError, clearError } = useError();
    const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

    const handleInputChange = (mainIndex, field, value, productIndex = null) => {
        const newData = [...data];
    
        if (productIndex !== null) {
            // Handle nested product list updates
            newData[mainIndex].product_list[productIndex][field] = value;
        } else {
            // Handle top-level data updates
            newData[mainIndex][field] = value;
        }
    
        setData(newData);
    };


    function deleteassociatedCategory(additional_products_id) {
        let item = {
            additional_products_id: additional_products_id
        }

        deleteCommonFunction(`/groupadmin/category/additionalProducts/${id}`, 
            "Your work has been successfully deleted.", 
            () => getDetails(), item);
    }

    const handleSubmit = (e ,mainIndex) => {
        e.preventDefault();
        clearError(null);
        const result = data && data?.[mainIndex];
        if (result) {
            api
                .patch(`/groupadmin/category/additionalProducts/${id}`, result, { headers })
                .then((res) => {
                    getDetails();
                    showSuccessNotification("Your work has been saved successfully.");
                })
                .catch((err) => {
                    if (err.response) {
                        showError(err.response?.data?.error?.message);
                    }
                });
        }
    };

    const handleSubmitForTitle = (e) => {
        e.preventDefault();

        const item = {
            title_for_additional_products: title_for_additional_products,
            // description_for_additional_products: description_for_additional_products

        }

        if (description_for_additional_products) {
            item["description_for_additional_products"] = description_for_additional_products;
          }
        // console.log("item" ,item);
        // return
        // return
        api
            .patch(`/groupadmin/category/categoryTitle/${id}`, item, { headers })
            .then((res) => {
                getDetails();
                showSuccessNotification("Additional Category has been saved successfully.");
            })
            .catch((err) => {
                if (err.response) {
                    showError(err.response?.data?.error?.message);
                }
            });
    };


    return (
        <React.Fragment>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <GroupAdminHeader />
                            <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                                <div id="kt_content_container" className="mx-7">
                                    <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                        <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">
                                            Menu Management
                                        </h1>
                                        <hr />
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                                                    Category - Additional Category
                                                </h3>
                                            </div>
                                            <div className="d-flex flex-stack mb-5">
                                                <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                                                    <button
                                                        onClick={() =>
                                                            history.push(
                                                                "/group_admin/menu_management/menu_category",
                                                                {
                                                                    menuId: menuId,
                                                                    branchID: branchID,
                                                                }
                                                            )
                                                        }
                                                        className="btn btn-primary ps-7"
                                                    >
                                                        Go Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rounded border p-5">
                                            <CommonCatagory is_featured={false} />


                                            <div className="d-flex flex-stack mb-10 mt-10">
                                                <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <h3>Additional Category</h3>
                                                </div>
                                                <div className="d-flex flex-stack mb-5">
                                                    <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-docs mb-10">
                                                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                    <form onSubmit={handleSubmitForTitle}>
                                                        <div className="rounded border p-10">
                                                            <React.Fragment>
                                                                <div className="row mt-8 mb-10">
                                                                    <div className="col-md-3">
                                                                        <p>Additional Category</p>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="form-label required">Title</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control"
                                                                            placeholder="Enter Title"
                                                                            required
                                                                            value={title_for_additional_products}
                                                                            onChange={(e) => settitle_for_additional_products(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="form-label">Description</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control"
                                                                            placeholder="Enter Description"
                                                                            value={description_for_additional_products}
                                                                            onChange={(e) => setdescription_for_additional_products(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <ErrorMessage error={error} />

                                                                <div className="text-center mt-4">
                                                                {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("edit")) &&  (
                                                                    <button type="submit" className="btn btn-primary">
                                                                        <span
                                                                            // onClick={()=>setID_(main?.additional_products_id)} 
                                                                            className="indicator-label"> Save </span>
                                                                    </button>
                                                                )}
                                                                    <button
                                                                        onClick={() => history.goBack()}
                                                                        type="button"
                                                                        className="btn btn-danger mx-2"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </React.Fragment>


                                                        </div>
                                                    </form>
                                                </div>
                                            </div>



                                            <div className="d-flex flex-stack mb-5">
                                                <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                                                        Popular Menu Items
                                                    </h3>
                                                </div>
                                                <div className="d-flex flex-stack mb-5">
                                                    <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                                                    {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) &&  (
                                                        <button
                                                            onClick={() => history.push(`/group_admin/menu_management/category/additional-category/add/${id}/${menuId}/${branchID}`)}
                                                            className="btn btn-primary ps-7"
                                                        >
                                                            Add new
                                                        </button>)}
                                                    </div>
                                                </div>
                                            </div>
                                            {data?.length > 0 ? (
                                                data?.map((main, mainIndex) => (
                                                    <div key={mainIndex} className="card card-docs mb-10">
                                                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                            <form onSubmit={(e) => handleSubmit(e, mainIndex)}>
                                                                <div className="rounded border p-10">
                                                                    <div className="row mb-8">
                                                                        <div className="col-md-9">
                                                                            <h3>{main.title}</h3>
                                                                        </div>
                                                                        <div className="col-md-3 text-end">
                                                                        {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("delete")) &&  (
                                                                            <DeleteIcon onClick={() => deleteassociatedCategory(main?.additional_products_id)} />
                                                                        )}
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <React.Fragment>
                                                                        <div className="row mt-8 mb-4">
                                                                            <div className="col-md-3">
                                                                                <p>Popular Menu Details</p>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label className="form-label required">Title</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="title" // Set name to match the field in the object
                                                                                    className="form-control form-control"
                                                                                    placeholder="Enter Title"
                                                                                    value={main.title}
                                                                                    required
                                                                                    onChange={(e) => handleInputChange(mainIndex, e.target.name, e.target.value)}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label className="form-label">Description</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="description" // Set name to match the field in the object
                                                                                    className="form-control form-control"
                                                                                    placeholder="Enter Description"
                                                                                    value={main.description}
                                                                                    onChange={(e) => handleInputChange(mainIndex, e.target.name, e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* <hr /> */}
                                                                        <div className="row mt-8">
                                                                            <div className="col-md-3">
                                                                                {/* <p>Popular Menu Details</p> */}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                            <label className="form-label">Category</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Name"
                                                                                    value={main.name}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {main?.product_list?.length > 0 &&
                                                                            main?.product_list.map((product, productIndex) => (
                                                                                <div key={productIndex} className="row mt-8">
                                                                                    <div className="col-md-3"></div>
                                                                                    <div className="col-md-3 d-flex align-items-center">
                                                                                        <input
                                                                                            className="form-check-input me-4"
                                                                                            type="checkbox"
                                                                                            checked={product.is_active}
                                                                                            onChange={(e) =>
                                                                                                handleInputChange(mainIndex, "is_active", e.target.checked, productIndex)
                                                                                            }
                                                                                        />
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Enter Name"
                                                                                            value={product.name}
                                                                                            disabled
                                                                                        // disabled
                                                                                        //   onChange={(e) =>
                                                                                        //       handleInputChange(mainIndex, productIndex, "name", e.target.value)
                                                                                        //   }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Enter Price"
                                                                                            value={Number(product.price).toFixed(2)}
                                                                                            disabled
                                                                                        //   onChange={(e) =>
                                                                                        //       handleInputChange(mainIndex, productIndex, "price", e.target.value)
                                                                                        //   }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </React.Fragment>

                                                                    <br />
                                                                    <hr />
                                                                    <ErrorMessage error={error} />

                                                                    <div className="text-center">
                                                                    {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("edit")) &&  (
                                                                        <button type="submit" className="btn btn-primary">
                                                                            Save
                                                                        </button>)}
                                                                        <button
                                                                            onClick={() => history.goBack()}
                                                                            type="button"
                                                                            className="btn btn-danger mx-2"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="card card-docs mb-10">
                                                    <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                        <h5 className="text-center">No data available.</h5>
                                                    </div></div>
                                            )}

                                        </div>


                                    </main>
                                </div>
                            </div>
                        </div>
                        <GroupAdminUperSide />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PopularChoices;
