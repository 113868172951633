import React from 'react';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const renderchipsEmailMarketing = (status) => {
  let color = '';
  let label = '';
  let background = '';

  switch (status) {
    case true:
      color = '#175CD3'; // You can use any color from MUI's palette
      label = 'Subscribed';
      background = '#EFF8FF';
      break;
    case false:
      background = '#FFFAEB'; // You can use any color from MUI's palette
      label = 'Unsubscribed';
      color = "#B54708";
      break;
    default:
      color = 'default'; // You can use any color from MUI's palette
      label = 'Unknown';
      break;
  }

  return (
    <Chip
      icon={<FiberManualRecordIcon style={{ color: color, fontSize: 13 }} />}
      label={label}
      style={{ color: color, background: background, fontSize: 13 }}
    />
  );
};

export default renderchipsEmailMarketing;
