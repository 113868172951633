import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Box } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import RevenuBaseChart from "./Chart/RevenuBaseChart";
import Backdrop from '@mui/material/Backdrop';
import useDrawer from "../../../../../context/DrawerContext";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import useError from "../../../../Comman/Error/useError";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import {  selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";
import { connect } from "react-redux";


function GroupAdminRevenueBasedReports({ getBranchList, BranchList_ }) {
  const { error, showError, clearError } = useError();

  const filteredOptions = branchfilterAndMapData(BranchList_);

  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState("");
  const [Order_type, setOrder_type] = useState("");
  const [Order_type2, setOrder_type2] = useState([]);
  const [loader, setloader] = useState(false);
  const [REvenuBaseReport_, setREvenuBaseReport_] = useState("")
  const [Dowloader, setDowloader] = useState(false);

  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);
  // console.log("branch_id2" ,branch_id2)

  useEffect(() => {
    getBranchList();
    validDate();
  }, []);

  function validDate() {
    var today = new Date().toISOString().split("T")[0];
    document.getElementsByName("date")[0].setAttribute("max", today);
    document.getElementsByName("date1")[0].setAttribute("max", today);
  }

  const productNamesEN = REvenuBaseReport_?.length > 0 && REvenuBaseReport_?.map(item => item.branch_name_EN);
  const basePrices = REvenuBaseReport_?.length > 0 && REvenuBaseReport_?.map(item => item.total_amount);

  const deliveryTotalAmounts = REvenuBaseReport_?.length > 0 && REvenuBaseReport_.map(item => {
    const deliveryOrder = item.order_type.find(order => order.order_type === "delivery");
    return deliveryOrder ? deliveryOrder.total_amount.total_amount : 0;
  });

  const pickupTotalAmounts = REvenuBaseReport_?.length > 0 && REvenuBaseReport_.map(item => {
    const deliveryOrder = item.order_type.find(order => order.order_type === "pickup");
    return deliveryOrder ? deliveryOrder.total_amount.total_amount : 0;
  });

  const dineInTotalAmounts = REvenuBaseReport_?.length > 0 && REvenuBaseReport_.map(item => {
    const deliveryOrder = item.order_type.find(order => order.order_type === "dineIn");
    return deliveryOrder ? deliveryOrder.total_amount.total_amount : 0;
  });



  const [chartData3, setChartData3] = useState("");



  useEffect(() => {

    const chartData3 = {
      labels: productNamesEN,

      datasets: [
        {
          label: 'Delivery',
          data: deliveryTotalAmounts,
          backgroundColor: 'rgb(127, 86, 217)',
          barThickness: 30,
        },
        {
          label: 'Pickup',
          data: pickupTotalAmounts,
          backgroundColor: 'rgb(247, 144, 9)',
          barThickness: 30,
        },
        {
          label: 'DineIn',
          data: dineInTotalAmounts,
          backgroundColor: 'rgb(20, 201, 201)',
          barThickness: 30,
        },
      ],
    }
    setChartData3(chartData3);
  }, [REvenuBaseReport_?.length > 0])




  const handleConvertToPdf = () => {
    setDowloader(true);

    const doc = new jsPDF();

    doc.setProperties({
      title: 'My PDF Document',
      subject: 'Adding a Border',
      author: 'Your Name',
      keywords: 'PDF, border, example',
    });

    // Add a border to the entire PDF
    // doc.setDrawColor(0); // Set border color (black)
    // doc.setLineWidth(0.3); // Set border line width (1 unit)

    // Add a rectangle to create a border around the entire page
    // doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);



    doc.addImage(REvenuBaseReport_[0]?.logo_base64 ? REvenuBaseReport_[0]?.logo_base64 : "/assets/media/logos/Logo_Dark.png", 'PNG', 165, 15, 20, 20, 'FAST');

    // Create an array to hold the table data
    const tableData = [];

    // Add the table header row
    tableData.push([
      {
        content: 'Branch Name',
        styles: {
          fontSize: 14, // Change the font size here
          font: 'bold',
          alignment: 'end',
          fontFamily: "Inter"  // Optionally make it bold
        }
      },
      {
        content: 'Order Type',
        styles: {
          fontSize: 14, // Change the font size here
          font: 'bold',
          alignment: 'end',
          fontFamily: "Inter", // Optionally make it bold
        }
      },
      {
        content: branch_id2?.value === "All" ? "Amount" : `Amount (${currencyLabel})`,
        styles: {
          fontSize: 14, // Change the font size here
          font: 'bold',
          alignment: 'end',
          fontFamily: "Inter",
          magin: { top: 2, right: 0, bottom: 2, left: 8 },  // Optionally make it bold
        }
      }
    ]);

    // Add data from your REvenuBaseReport_ array
    REvenuBaseReport_?.forEach((item, pageIndex) => {


      // Add the "Filter" and "Date" text only on the first page
      if (pageIndex === 0) {
        doc.setFontSize(12);
        doc.text(Order_type, 15, 20);
        doc.text(`Filter: `, 20, 26);
        doc.text(`Date : ${moment(start_date).format("DD.MM.YYYY")} - ${moment(
          end_date ? end_date : new Date()
        ).format("DD.MM.YYYY")}`, 20, 32);
      }

      // Add a row for branch_name_EN
      tableData.push([
        {
          content: item.branch_name_EN,
          styles: {
            fontSize: 12,
            alignment: 'end',
            fontFamily: "Inter",
          }
        }
      ]);

      // Iterate over the order_type array and add a row for each order type
      item.order_type.forEach((order) => {
        tableData.push([
          '',
          {
            content: order.order_type === "delivery" ? "Delivery" : order.order_type === "pickup" ? "Pickup"
              : order.order_type === "dineIn" ? "DineIn" : "",
            styles: {
              fontSize: 12,
              fontFamily: "Inter",
              cellPadding: { top: 2, right: 0, bottom: 2, left: 8 },
              // Adjust padding if needed
            }
          },
          {
            content: branch_id2?.value === "All" ? `${item?.currency} ${Number(order.total_amount.total_amount).toFixed(2)}` : Number(order.total_amount.total_amount).toFixed(2),
            styles: {
              fontSize: 12,
              fontFamily: "Inter",
              cellPadding: { top: 2, right: 0, bottom: 2, left: 16 },
              // Adjust padding if needed
            }
          }
        ]);
      });

      tableData.push(['', '', {
        content: `Total Amount : ${Number(item?.total_amount).toFixed(2)}`,
        styles: {
          textColor: [0, 0, 0],
          fontSize: 13,
          fontFamily: "Inter",
          cellPadding: { top: 2, right: 0, bottom: 2, left: 8, }, // Adjust padding if needed
        }
      }]);
    });

    // Define the table styling
    const tableConfig = {
      theme: 'grid',
      startY: 45, // Adjust the vertical starting position
      headStyles: {
        fillColor: [192, 192, 192], // Header background color
        textColor: [0, 0, 0], // Header text color
      },
    };

    // Add the table using autoTable
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the rest of the rows as table data
      ...tableConfig,
    });

    doc.setFontSize(12);
    // Calculate the width of the table
    const tableWidth = doc.internal.pageSize.getWidth() - 70; // Adjust the left margin and right margin (20 + 20)

    // Calculate the position for the "Total Amount" text
    const yPosition = doc.autoTable.previous.finalY + 6; // Position it below the table with some spacing

    const chartElement = document.getElementById('contentToConvert');

    html2canvas(chartElement, { allowTaint: true }).then(function (canvas) {
      // Compress and resize the image before adding it to the PDF
      const screenshotWidth = 150; // Adjust to your desired width
      const screenshotHeight = (canvas.height / canvas.width) * screenshotWidth;

      // Convert the canvas to a JPEG data URL with reduced quality
      const chartImage = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality (0.8 is 80% quality)

      // Add the compressed and resized image to the PDF
      doc.addImage(chartImage, 'JPEG', 30, yPosition + 10, screenshotWidth, screenshotHeight);

      doc.output('save', `${Order_type} ${moment(new Date()).format(
        "DD-MM-YYYY"
      )}.pdf`, { compress: true });

      setDowloader(false); // Set loading to false when the conversion is complete
    });

    // Apply CSS-like styles to the border
    const borderWidth = 0.5; // Border width
    const borderColor = [0, 0, 0]; // Border color (red in this example)
    const margin = 7;
    for (
      let pageNumber = 1;
      pageNumber <= doc.getNumberOfPages();
      pageNumber++
    ) {
      doc.setPage(pageNumber);

      // Set border color
      doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);

      // Set border width
      doc.setLineWidth(borderWidth);

      // Calculate the position and size of the border rectangle with margins
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const rectX = margin;
      const rectY = margin;
      const rectWidth = pageWidth - 2 * margin;
      const rectHeight = pageHeight - 2 * margin;

      // Draw the border rectangle with margins
      doc.rect(rectX, rectY, rectWidth, rectHeight);
    }


  };


  // console.log("Order_type" ,Order_type)

  function handalsubmit(event) {
    event.preventDefault();
    clearError(null);

    if (branch_id === "") {
      showError("Please select branch.");
      return;
    }
    if (Order_type === "") {
      showError("Please revenue type");
      return;
    }
    else if (start_date === "") {
      showError("Please select start date");
      return;
    }
    const result2 = moment(end_date).add(1, "days").toDate();
    if (Date.parse(result2) <= Date.parse(start_date)) {
      showError("End date should be greater than Start date");

      return;
    }
    else {
      setloader(true);
      const result2 = moment(end_date).add(1, "days").toDate();

      if (Order_type === "Revenue by Products and Payment Method") {
        setloader(false);
        setREvenuBaseReport_([])
      }
      else if (Order_type === "Revenue by Date") {
        setREvenuBaseReport_("")
        if (end_date) {
          let url = branch_id === "All" ? `/groupadmin/report/revenue?start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(result2).format(
            "YYYY-MM-DD"
          )}` :
            `/groupadmin/report/revenue?branch_id=${branch_id}&start_date=${moment(start_date).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(result2).format(
              "YYYY-MM-DD"
            )}`

          api
            .get(`${url}`, {
              headers,
            })
            .then((res) => {
              setloader(false);

              // Iterate through the JSON response and add base_price to the total

              // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
              setREvenuBaseReport_(res?.data?.data?.length > 0 ? res?.data?.data?.sort((a, b) => a?.branch_name_EN?.localeCompare(b?.branch_name_EN)) : [])
              // setErrorNoData(res?.data?.data == undefined ? true : false);
              // console.log("res", res?.data?.data.sort((a, b) => a.branch_name_EN.localeCompare(b.branch_name_EN)));
            })
            .catch((err) => {
              setloader(false);
              if (err.response) {
                showError(err.response?.data?.error?.message);
              }
            });
        } else {

          let url = branch_id === "All" ? `/groupadmin/report/revenue?start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}` : `/groupadmin/report/revenue?branch_id=${branch_id}&start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}`;

          api
            .get(`${url}`, {
              headers,
            })
            .then((res) => {
              setloader(false);
              // console.log(res?.data?.data)
              // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
              setREvenuBaseReport_(res?.data?.data?.length > 0 ? res?.data?.data?.sort((a, b) => a?.branch_name_EN?.localeCompare(b?.branch_name_EN)) : [])
              // setErrorNoData(res?.data?.data == undefined ? true : false);
              // console.log("res", res?.data?.data);
            })
            .catch((err) => {
              setloader(false);
              if (err.response) {
                showError(err.response?.data?.error?.message);
              }
            });
        }
      }
      else if (Order_type === "Revenue In Detailed") {
        setloader(false);
        setREvenuBaseReport_([])
      }
      else if (Order_type === "Discount By Name") {
        setloader(false);
        setREvenuBaseReport_([])
      }
      else if (Order_type === "Employee Tip by Employee and Day") {
        setloader(false);
        setREvenuBaseReport_([])
      } else {
        setloader(false);
        setREvenuBaseReport_([])
      }
    }
  }



  function convertToCSV(data) {
    const headers = ["Branch Name", "Order Type", branch_id2?.value === "All" ? "Amount" : `Amount (${currencyLabel})`];
    const csvData = [headers.join(",")];

    data.forEach((item) => {
      const { branch_name_EN, order_type, total_amount ,currency } = item;


      // Loop through the order_type array and add rows for each type
      order_type.forEach((order) => {
        const formattedRow = [
          branch_name_EN,
          order.order_type === "delivery" ? "Delivery" : order.order_type === "pickup" ? "Pickup"
            : order.order_type === "dineIn" ? "DineIn" : "",
            branch_id2?.value === "All" ? `${currency} ${Number(order.total_amount.total_amount).toFixed(2)}` : Number(order.total_amount.total_amount).toFixed(2),
        ].join(",");
        csvData.push(formattedRow);

      });
      const totalRow = ["", "", `Total :- ${total_amount.toFixed(2)}`].join(",");
      csvData.push(totalRow);
    });

    // const totalBasePrice = data.reduce(
    //   (total, item) =>
    //     total +
    //     item.order_type.reduce(
    //       (typeTotal, order) => typeTotal + order.total_amount.total_amount,
    //       0
    //     ),
    //   0
    // );

    // const totalRow = ["", "", `Total :- ${totalBasePrice.toFixed(2)}`].join(",");
    // csvData.push(totalRow);

    return csvData.join("\n");
  }

  function downloadCSV(data, file) {
    const csvContent = convertToCSV(data);

    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = file

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();



  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className={isMobile ? "mx-5 p-0" : "mx-7 p-0"}>
                  <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>
                    <div className="d-flex flex-stack mb-2 p-0">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h2
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Revenue Reports
                        </h2>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.push("/group_admin/report")}
                            className="btn btn-primary ps-7"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="py-2">
                      <Card style={{ border: "1px solid #bdbcbc" }}>
                        <Card.Body>
                          <Row >
                            <Col lg={6} md={6} sx={6}>
                              <label className="required form-label">
                                Select Branch
                              </label>
                              <Select
                                // styles={{ backgroundColor: "#f5f8fa" }}
                                className="search-line"
                                placeholder="Search..."
                                // options={[
                                //   {
                                //     label: "All",
                                //     value: "All",
                                //   },
                                //   ...filteredOptions,
                                // ]}

                                // placeholder={getPlaceholder(filteredOptions)}
                                options={getSelectOptions(filteredOptions)}
                                value={branch_id2}
                                onChange={(opt) => {
                                  setbranch_id(opt.value);
                                  setbranch_id2(opt);
                                }}
                                styles={selectStylesReport}
                              />
                            </Col>

                            <Col lg={6} md={6} sx={6}>
                              <label className="required form-label">
                                Select Revenue Type
                              </label>
                              <Select
                                styles={selectStylesReport}
                                className="search-line"
                                placeholder="Search..."
                                options={[
                                  {
                                    label: "Revenue by Products and Payment Method",
                                    value: "Revenue by Products and Payment Method",
                                  },
                                  {
                                    label: "Revenue by Date",
                                    value: "Revenue by Date",
                                  },
                                  {
                                    label: "Revenue In Detailed",
                                    value: "Revenue In Detailed",
                                  },
                                  {
                                    label: "Discount By Name",
                                    value: "Discount By Name",
                                  },
                                  {
                                    label: "Employee Tip by Employee and Day",
                                    value: "Employee Tip by Employee and Day",
                                  },
                                ]}
                                value={Order_type2}
                                onChange={(opt) => {
                                  setOrder_type(opt.value);
                                  setOrder_type2(opt);
                                }}
                              /></Col>

                          </Row>

                          <div className="row mt-7" >
                            <div className={isTabletOrMobile ? "col-md-4" : "col-md-2"} >
                              <label className="required form-label">
                                Start Date
                              </label>
                              <input
                                type="date"
                                name="date"
                                id="date"
                                className="form-control"
                                value={start_date}
                                onChange={(e) => {
                                  setstart_date(e.target.value);
                                }}
                              />
                            </div>
                            <div className={isTabletOrMobile ? "col-md-4" : "col-md-2"}>
                              <label className="form-label">End Date</label>
                              <input
                                type="date"
                                name="date1"
                                id="date1"
                                className="form-control"
                                value={end_date}
                                onChange={(e) => {
                                  setend_date(e.target.value);
                                }}
                              />
                            </div>
                            <div className={isTabletOrMobile ? "col-md-4 mt-8 text-end" : "col-md-3 mt-8"} >
                              {loader ? (
                                <Box>
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <button
                                  onClick={handalsubmit}
                                  type="button"
                                  className="btn btn-secondary"
                                >
                                  <span className="indicator-label">
                                    <i className="fa fa-eye fs-2"></i> &nbsp;
                                    Preview</span>
                                </button>
                              )}

                            </div>
                            <div className={isTabletOrMobile ? "col-md-12 mt-8 text-end" : "col-md-5 mt-9 text-end"}>
                              {REvenuBaseReport_?.length > 0 ?
                                <React.Fragment>
                                  {Dowloader ?
                                    <Backdrop
                                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                      open={true}
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop>
                                    :
                                    <button
                                      onClick={handleConvertToPdf}
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      <span className="indicator-label">
                                        <i className="fa fa-file-pdf-o fs-2"></i>&nbsp;Download PDF</span>
                                    </button>}
                                  &nbsp;&nbsp;
                                  <button
                                    onClick={() => downloadCSV(REvenuBaseReport_, `${Order_type} ${moment(new Date()).format(
                                      "DD-MM-YYYY"
                                    )}.csv`)}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    <span className="indicator-label">

                                      <i className="fa fa-file-excel-o fs-2"></i>&nbsp;Download CSV</span>
                                  </button>
                                </React.Fragment> : ""}
                            </div>
                          </div>

                          <ErrorMessage error={error} />

                        </Card.Body>
                      </Card>




                      {REvenuBaseReport_?.length > 0 ?
                        <div>

                          <div id="contentToConvert" >
                            <RevenuBaseChart chartData={chartData3} daynamicName={Order_type} />
                          </div>

                        </div> :

                        ""
                      }

                      {REvenuBaseReport_ ? REvenuBaseReport_.length == 0 ?
                        <Card style={{ border: "1px solid rgb(189, 188, 188)" }} className="mt-10 my-4 text-center">
                          <Row className="my-5 text-center">
                            <Col className="my-4">
                              <h5>
                                No Data for the selected Date or Filter. Please select a different Date or Filter.
                              </h5>
                            </Col>
                          </Row>

                        </Card> : "" : ""}

                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
        getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminRevenueBasedReports);


