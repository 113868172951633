import React from "react";
import { useHistory, Link } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Helmet } from "react-helmet";

import $ from "jquery";
window.jquery = window.$ = $;

export default function GroupAdminProductDetailsDeclaration() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];

  let history = useHistory();
  function ProductForInformation() {
    history.push("/group_admin/product_details_information");
  }

  function ProductForDescription() {
    history.push("/group_admin/product_details_discriptaion");
  }

  function ProductForDeclaration() {
    history.push("/group_admin/product_details_declaration");
  }

  function ProductForTimeFilter() {
    history.push("/group_admin/product_details_time_filter");
  }

  $(document).ready(function () {
    function addProvisionalDiagnosis() {
      $("#sdsad").append(
        '<div class="mb-4"><input class="form-check-input" type="checkbox" value="" /><input class="form-input mx-3" type="text" value=""/></div>'
      );
    }

    $(document).on("click", ".addtimes", function () {
      addProvisionalDiagnosis();
    });

    $("#sdsad").on("click", ".remCF", function () {
      $(this).parent().parent().remove();
    });
  });

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Choco Lava
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button href="#" className="btn btn-primary ps-7">
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            to="/group_admin/menu_management/menu_category/menu_products/product_details_discriptaion"
                            className={
                              url === "product_details_discriptaion"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Description
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            to="/group_admin/menu_management/menu_category/menu_products/product_details_time_filter"
                            className={
                              url === "product_details_time_filter"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Time Filter
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                              <div className="rounded border p-10">
                                <div className="row">
                                  <div className="col-sm-10">
                                    <h3>Properties</h3>
                                  </div>
                                  <div className="col-sm-2">
                                    <button
                                      type="button"
                                      className="btn btn-primary addtimes"
                                    >
                                      Add Property
                                    </button>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                    />
                                    <label className="form-check-label mx-2">
                                      Vegetarian
                                    </label>
                                  </div>
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                    />
                                    <label className="form-check-label mx-2">
                                      Vegan
                                    </label>
                                  </div>
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                    />
                                    <label className="form-check-label mx-2">
                                      Gluten Free
                                    </label>
                                  </div>
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                    />
                                    <label className="form-check-label mx-2">
                                      Lactose Free
                                    </label>
                                  </div>
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                    />
                                    <label className="form-check-label mx-2">
                                      Spicy
                                    </label>
                                  </div>
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultValue=""
                                    />
                                    <label className="form-check-label mx-2">
                                      Contains Alcohol
                                    </label>
                                  </div>
                                  <div className="mb-4" id="sdsad"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
          <Helmet>
            <script src="/assets/plugins/global/plugins.bundle.js"></script>
            <script src="/assets/js/scripts.bundle.js"></script>
            <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
            <script src="/assets/js/custom/widgets.js"></script>
            <script src="/assets/js/custom/apps/chat/chat.js"></script>
            <script src="/assets/js/custom/modals/create-app.js"></script>
            <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
          </Helmet>
        </div>
      </div>
    </React.Fragment>
  );
}
