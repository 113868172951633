import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import {ADDONPROPERTIES, setgetAddonList } from "../../actions";

function* calladdOnList() { 
  try {
      const response = yield call(api.get, "/groupAdmin/options" , { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setgetAddonList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type:ADDONPROPERTIES.CLEAR_ADD_ON });
      }
    }
}

export function* watchAddonList() {
  yield takeEvery(ADDONPROPERTIES.GET_ADD_ON, calladdOnList);
}



