import React, { useEffect, useRef, useState } from 'react';
import { Popover, TextField, Typography, Grid } from '@mui/material';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Select from "react-select";
import { Button } from '@material-ui/core';


const FilterPopover = ({ open, anchorEl, handleClose, handleFilter, Type }) => {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url23 = pathnamesplits[2];


  const filterData = JSON.parse(sessionStorage.getItem(`${url23}_${Type}filterData`));
  const filterDatapickupAndDelivery = JSON.parse(sessionStorage.getItem(`${url23}_${Type}filterData`));
  // const { orderId, orderStatus, startDate, endDate } = filterData; 

  // console.log("filterDatapickupAndDelivery" ,filterDatapickupAndDelivery)

  const [orderId, setOrderId] = useState("");
  const [orderStatus, setorderStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  useEffect(() => {
    if (Type === "dinein") {
      setOrderId(filterData?.orderId || '');
      setorderStatus(filterData?.orderStatus || '');
      setStartDate(filterData?.startDate || '');
      setEndDate(filterData?.endDate || '');
      handleFilter({
        orderId: filterData?.orderId,
        orderStatus: filterData?.orderStatus,
        startDate: filterData?.startDate,
        endDate: filterData?.endDate
      });
    } else {
      setOrderId(filterDatapickupAndDelivery?.orderId || '');
      setorderStatus(filterDatapickupAndDelivery?.orderStatus || '');
      setStartDate(filterDatapickupAndDelivery?.startDate || '');
      setEndDate(filterDatapickupAndDelivery?.endDate || '');
      handleFilter({
        orderId: filterData?.orderId,
        orderStatus: filterData?.orderStatus,
        startDate: filterData?.startDate,
        endDate: filterData?.endDate
      });
    }
  }, [Type]);


  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem(`${url23}_${Type}filterData`, JSON.stringify({ orderId, orderStatus, startDate, endDate }));
    handleFilter({ orderId, orderStatus, startDate, endDate });
  };




  const handleSubmitClear = () => {
    if (Type === "dinein") {
      sessionStorage.removeItem(`${url23}_${Type}filterData`);
      handleFilter({});
      setOrderId("");
      setEndDate("");
      setStartDate("");
      setorderStatus("");
    } else {
      sessionStorage.removeItem(`${url23}_${Type}filterData`);
      handleFilter({});
      setOrderId("");
      setEndDate("");
      setStartDate("");
      setorderStatus("");
    }
  };

  useEffect(() => {
    if (Type && filterData === null) {
      handleSubmitClear();
    }
  }, [Type && filterData === null]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        handleClose();
        if (filterData === null) {
          setOrderId("");
          setEndDate("");
          setStartDate("");
          setorderStatus("");
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: 3,
          width: 420,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 35,
            height: 35,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 85,
            width: 13,
            height: 13,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Container className='mt-5 mb-3'>
        <Row >
          <Col sm={6} lg={6} md={6}>
            <h3>Filter</h3>
          </Col>
          <Col sm={6} lg={6} md={6} className="text-end">
            <h5 onClick={(e) => {
              handleSubmitClear();
            }} style={{ color: "#F25042", cursor: "pointer" }}> Clear Filters</h5>
          </Col>
        </Row>
      </Container>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row className="mb-5">
            <Col sm={6} lg={6} md={6}>
              <label className="form-label fs-6">{Type === "dinein" ? "Invoice Code" : "Order ID"}</label>
              <input
                className="form-control"
                type="text"
                placeholder={Type === "dinein" ? "Enter Invoice Code" : "Enter Order ID"}
                value={orderId}
                onChange={(e) => {
                  setOrderId(e.target.value);
                }}
              />
            </Col>
            <Col sm={6} lg={6} md={6}>
              <label className="form-label fs-6">Order Status</label>
              <Select
                value={orderStatus}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '41px',
                    borderRadius: "6px",
                    width: "100%",
                    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', // Corrected syntax
                  }),
                }}
                onChange={(opt) => {
                  setorderStatus(opt);
                }}
                options={Type === "dinein" ? [
                  { value: 'completed', label: 'Completed' },
                  { value: 'payment_approved', label: 'Payment Approved' },
                  { value: 'pending', label: 'Pending' }
                ] : [
                  { value: 'pending', label: 'Pending' },
                  { value: 'payment_approved', label: 'Payment Approved' },
                ]}
                placeholder='Select Order Status'
              />

            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={6} lg={6} md={6}>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col sm={6} lg={6} md={6}>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row className='text-center mb-4'>
          <Col sm={3} lg={3} md={3}>
            </Col>
            <Col sm={6} lg={6} md={6}>
            <button
              type="submit"
              className='btn btn-primary'
            >
              Apply Filters
            </button>
            </Col>
            <Col sm={3} lg={3} md={3}>
            </Col>
          </Row>
        </Container>
      </form>
    </Popover>
  );
};

export default FilterPopover;
