import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import { useParams } from "react-router-dom";
import headers from "../../../../../CommonApi/headers";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDrawer from "../../../../../context/DrawerContext";

export default function GroupAdminPrinterViewDetails() {
  let id = useParams();
  useEffect(() => {
    groupadminCouponDetaisView();
  }, []);
  let history = useHistory();


  const [user, setuser] = useState("");

  function groupadminCouponDetaisView() {
    api
      .get(`/groupAdmin/branchPrinter/${id.id}`, { headers })
      .then((res) => {
        const options = res.data.data;
        setuser(options);
        console.log("options" ,options)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                       

                    <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header pt-5 border-1">
                        <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                         Printer Details
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                        </div>
                        </div>
                        <div className="card-body">
                          <div className="row p-3 pt-0">
                            <table
                              className="table table-bordered border rounded"
                              style={{ width: "100%" }}
                            >
                              <tbody>
                                
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Branch Name
                                  </th>
                                  <td>
                                    {user
                                      ? user?.branch_name_EN
                                      : "No data"}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Printer Name
                                  </th>
                                  <td>
                                    {user?.printer_name
                                      ? user?.printer_name
                                      : "No data"}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                   Printer Type
                                  </th>
                                  <td>
                                    {user?.printer_type_name
                                      ? user.printer_type_name 
                                      : "No Data."}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Printer Model
                                  </th>
                                  <td>{user?.printer_model_number ? user.printer_model_number : "No data"}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Printer Category
                                  </th>
                                  <td>
                                    {user?.printer_category_name
                                      ? user.printer_category_name
                                      : "No Data."}
                              
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                   Print Receipt
                                  </th>
                                  <td>
                                    {user
                                      ? user?.enable_invoice === true ? 'Yes' : "No"
                                      : "No Data."}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                   Print Ip Address
                                  </th>
                                  <td>
                                    {user
                                      ? user?.ip_address
                                      : "No Data."}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                  Printer Status
                                  </th>
                                  <td>
                                    {user ? user?.is_active == true ? "Active" : "Inactive" : "No Data."}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                  Default Printer 
                                  </th>
                                  <td>
                                    {user ? user?.default_status == true ? "Yes" : "No" : "No Data."}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
