import React from 'react'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'

export default function CommonCatagory({is_featured}) {

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url = pathnamesplits[4];

      const { id, menuId, branchID } = useParams();


      return (
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                  <li className="nav-item">
                        <Link
                              className={
                                    url === "edit_menu_category"
                                          ? "nav-link active"
                                          : "nav-link"
                              }
                              to={{
                                    pathname: `/group_admin/menu_management/category/edit_menu_category/${id}/${menuId}/${branchID}`,

                              }}
                        >
                              Information
                        </Link>
                  </li>
                  {is_featured ? "" :
                        <li className="nav-item">
                              <Link
                                    className={
                                          url === "menu_products"
                                                ? "nav-link active"
                                                : "nav-link"
                                    }
                                    to={{
                                          pathname:
                                                `/group_admin/menu_management/menu_category/menu_products/${id}/${menuId}/${branchID}`,

                                    }}
                              >
                                    Products
                              </Link>
                        </li>}

                  <li className="nav-item">
                        <Link
                              className={
                                    url === "additional-category"
                                          ? "nav-link active"
                                          : "nav-link"
                              }
                              to={{
                                    pathname:
                                          `/group_admin/menu_management/category/additional-category/${id}/${menuId}/${branchID}`,

                              }}
                        >
                              Additional Category
                        </Link>
                  </li>
            </ul>
      )
}
