import React from "react";
import { Bar } from 'react-chartjs-2';

function RevenuBaseChart({ chartData ,daynamicName }) {

      // console.log("daynamicName" ,daynamicName)

      const options = {
            indexAxis: "y",
            plugins: {
              title: {
                display: true,
                text: daynamicName,
              },
              legend: {
                  display: true,
                  labels: {
                        font : {
                              size : 20,
                        }// Adjust the font size of the legend
                      },
                     
                },
                
            },
            scales: {
              x: {
            position: "top",
                stacked: true,
                ticks: {
                  font: {
                    size: 20, // Change the font size for y-axis ticks
                    weight: 'bold',
                    family: "Helvetica", 
                  },
                },
                
              },
              y: {
                stacked: true,
                ticks: {
                  font: {
                    size: 20, // Change the font size for y-axis ticks
                    weight: 'bold',
                    family: "Helvetica", 
                  },
                },
              },
            },
          };

  return (
    
      // <Card className="mt-10" style={{
      //   borderRadius: "10px",
      //   border: "1px solid #D0D5DD",
      //   marginBottom: "25px",
      // }} >

    <div
    className="mt-10"
      style={{
        borderRadius: "10px",
        border: "1px solid var(--gray-300, #D0D5DD)",
        marginBottom: "25px",
      }}
    >
      <div
        style={{
          padding: "16.5px 0px 16.5px 16px",
          alignItems: "center",
          borderRadius: "10px 10px 0px 0px",
          border: "1px solid #D0D5DD",
          background: "#F9FAFB",
        }}
      >
        <span
          style={{
            color: "#667085",
            fontFamily: "Inter",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "40px",
          }}
        >
          {daynamicName}
        </span>
      </div>

      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            width: "18px",
            height: "18px",
            flexShrink: "0",
            borderRadius: "4px",
            background: "#7F56D9",
          }}
        >
          {" "}
        </div>
        <div
          style={{
            color: "var(--gray-500, #667085)",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineGight: "18px",
            lineHeight: "18px",
          }}
        >
          Sales: {}
        </div>
      </div> */}

      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: "17px",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            width: "22px",
            height: "22px",
            flexShrink: "0",
            borderRadius: "4px",
            background: "#9ca3af",
          }}
        >
          {" "}
        </div>
        <div
          style={{
            color: "var(--gray-500, #667085)",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: "500",
            lineGight: "18px",
            lineHeight: "24px",
            fontWeight : "bold"
          }}
        >
           Product Amount CHF
        </div>
      </div> */}
      <div className="mt-3 mx-10">
        <Bar
          data={chartData}
          options={options} className="chart"
        />
      </div>
    </div>
    
      // </Card>
  );
}
export default RevenuBaseChart;