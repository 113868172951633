import { USERMANAGMENT } from "../../actions";



const UserManagmentListReducer = (state = [], action) => {
    switch (action.type) {
        case USERMANAGMENT.CLEAR_USERMANAGMENT_LIST:
            return [];
        case USERMANAGMENT.SET_USERMANAGMENT_LIST:
            return [...action?.userlist];
        default:
            return state;
    }
}

export default UserManagmentListReducer