import React from 'react'
import { Link} from "react-router-dom";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function BranchCommon() {

      const { id } = useParams();

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[3];

  return (
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          className={
            url23 === "branch_detail_view"
              ? "nav-link active"
              : "nav-link"
          }
          to={{
            pathname:
              `/group_admin/branch_management/branch_detail_view/${id}`,
          }}
        >
          Branch Details
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={
            url23 === "table_management"
              ? "nav-link active"
              : "nav-link"
          }
          to={{
            pathname:
              `/group_admin/branch_management/table_management/${id}`,
          }}
        >
         Table List 
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={
            url23 === "user_list"
              ? "nav-link active"
              : "nav-link"
          }
          to={{
            pathname:
              `/group_admin/branch_management/user_list/${id}`,
          }}
        >
        User List
        </Link>
      </li>
    </ul>
  )
}
