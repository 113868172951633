export const getBarchatOption = (titleText) => ({
  indexAxis: "x",
  plugins: {
    title: {
      display: true,
      text: "",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      title: {
        display: true,
        text: titleText === "Today"  ? "Hours" : titleText === "Yesterday" ? "Hours" : "",
        font: {
          size: 15
      }
      },
      stacked: true,
      grid: {
        display: false, // Hide the x-axis grid lines
      },
    },
    y: {
      title: {
        display: true,
        // text: "Sections",
      },
      stacked: true,
      grid: {
        display: true, // Hide the x-axis grid lines
      },
    },
  },
    responsive: true,
    maintainAspectRatio: false
});
