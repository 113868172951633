import { VOUCHERTEM } from "../action-types";


const getVoucherTemList = () => ({
  type: VOUCHERTEM.GET_VOUCHERTEM_LIST,
});


const setVoucherTemList = (vouchertemlist) => ({
  type: VOUCHERTEM.SET_VOUCHERTEM_LIST,
  vouchertemlist,
});


export {
      getVoucherTemList ,setVoucherTemList
};
