import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { ORDER, setOrderDetails } from "../../actions";

function* callOrderDetails({id ,Type ,branchId}) { 
  if(id){
  try {
      const response = yield call(api.get, `/groupadmin/order/new/${id}/${Type}/${branchId}`, { headers: headers });
      const  tableData = response?.data?.data;
      yield put(setOrderDetails(tableData));
    } catch (error) {
      if (error.response) {
        yield put({ type: ORDER.CLEAR_ORDER_DETAILS });
      }
    }}else{
      yield put({ type: ORDER.CLEAR_ORDER_DETAILS });
    }
}


export function* watchOrderDetails() {
  yield takeEvery(ORDER.GET_ORDER_DETAILS, callOrderDetails);
}

