import React from 'react'
import { Link } from "react-router-dom";


export default function GroupAdminSettingCommon() {

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url = pathnamesplits[4];

  return (
    <React.Fragment>
       <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                              "/group_admin/settings/header_footer/header",                             
                            }}
                            className={
                              url === "header"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                           Header
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/header_footer/footer",                             
                            }}
                            className={
                              url === "footer"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Footer
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/header_footer/color",                             
                            }}
                            className={
                              url === "color"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                           Colors
                          </Link>
                        </li>
                      </ul>
    </React.Fragment>
  )
}
