import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import api from "../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../CommonApi/headers";
import useDrawer from "../../../context/DrawerContext";
import { showSuccessNotification } from "../../Comman/Swal/Swal";
window.jquery = window.$ = $;



export default function GroupAdminEditWaiter() {


  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [name, setname] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [pin ,setpin] = useState("");
  const [staff_id , setstaff_id] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    WaiterDetailsGet();
  }, []);

  function WaiterDetailsGet() {
    api.get(`/groupadmin/waiter/${id}`, { headers }).then((res) => {
      const tableData = res?.data?.data;
      setname(tableData.name);
      setemail(tableData.email);
      setpin(tableData?.pin)
      setstaff_id(tableData.staff_id ? tableData?.staff_id : "");
      setphone_number(tableData.phone_number);
    });
  }

  const passwordValidation1 = (password) => {
    var reg = /^\S*$/;
    return reg.test(password);
  };

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (
      name.toString().trim().length < 0 ||
      name.toString().trim().length < 3
    ) {
      setError("Waiter Name Must Be at least 3 character Long");
    }
    // else if (!validateEmail(email)) {
    //   setError("Invalid Email");
    // } 
    // else if (
    //   (password && password.toString().trim().length > 0 && !passwordValidation1(password)) ||
    //   (password && password.toString().trim().split(" ").join("").length < 6)
    // ) {
    //   setError("Password must be at least 6 characters long and not contain any spaces.");
    // } 
    else {
      const formData = new FormData();

        formData.append("branch_id", id);
        formData.append("name", name);
        // formData.append("email", email);
        formData.append("phone_number", phone_number);
        // if(password){
        //   formData.append("password", password);
        // }
        if(pin){
          formData.append("pin" ,pin)
        }
        formData.append("staff_id" ,staff_id)

      // console.log("item" , item)

      api
        .patch(`/groupadmin/waiter/${id}`, formData, { headers })
        .then((res) => {
          showSuccessNotification("Service Staff details has been edited successfully.");

          history.goBack();
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="d-flex flex-stack my-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h1 className="anchor fw-bolder" id="custom-form-control">
                        Edit Service Staff
                      </h1>
                    </div>
                    <div className="d-flex flex-stack">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.goBack()}
                          className="btn btn-primary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="bottom-end"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="py-5">
                    <div className="card card-docs mb-2">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div className="rounded border p-10">
                          <form onSubmit={handleSubmit}>
                            <div className="row col-md-12">
                              <div className="mb-10">
                                <h6>Service Staff Details Edit</h6>
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="required form-label">
                                Service Staff Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Service Staff Name"
                                  required
                                  value={name}
                                  onChange={(e) => setname(e.target.value)}
                                />
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className=" form-label">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  className="filterme form-control form-control-solid"
                                  // maxLength="10"
                                  // minLength="10"
                                  placeholder="Phone Number"
                                  // required
                                  value={phone_number}
                                  onChange={(e) =>
                                    setphone_number(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="required form-label">
                                Staff Id 
                                </label>
                                <input
                                  type="text"
                                  placeholder="Staff Id"
                                  className="filterme form-control form-control-solid"
                                  maxLength="4"
                                  minLength="4"
                                  required
                                  value={staff_id}
                                  readOnly={true}
                                  // onChange={(e) =>
                                  //   setstaff_id(e.target.value)
                                  // }
                                />
                              </div><div className="col-md-6 mb-10">
                                <label className="required form-label">
                                  Pin Code
                                </label>
                                <input
                                  type="text"
                                  placeholder="Pin Code"
                                  className="filterme form-control form-control-solid"
                                  maxLength="4"
                                  minLength="4"
                                  required
                                  value={pin}
                                  onChange={(e) =>
                                    setpin(e.target.value)
                                  }
                                />
                              </div>
                              {/* <div className="col-md-6 mb-10">
                                <label className="required form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control form-control-solid"
                                  placeholder="Email"
                                  required
                                  value={email}
                                  onChange={(e) => setemail(e.target.value)}
                                />
                              </div>
                              <div className="col-md-6 mb-10">
                                <label className="form-label">
                                  Password
                                </label>
                                <input
                                  type="password"
                                  className="noSpace form-control form-control-solid"
                                  placeholder="Password"
                                  // value={password}
                                  onChange={(e) => setpassword(e.target.value)}
                                />
                              </div> */}
                            </div>

                            <div className="col-md-12"> {errorDiv} </div>

                            <div className="modal-footer flex-center">
                              <button
                                type="submit"
                                id="kt_modal_new_address_submit"
                                className="btn btn-primary"
                              >
                                <span className="indicator-label">Save</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => history.goBack()}
                              >
                                {" "}
                                Cancel{" "}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
