import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import useDrawer from "../../../../../context/DrawerContext";
import useError from "../../../../Comman/Error/useError";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import headers from "../../../../../CommonApi/headers";
import Select from "react-select";
import { submitForm } from "../../../../Comman/AddCommonFun/AddCommonFunction";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { SubmitformButton1 } from "../../../../Comman/Error/SubmitformButton";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function AddPopularChoices() {
      const history = useHistory();
      const { error, showError, clearError } = useError();
      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
      const { id, menuId, branchID } = useParams();


      useEffect(() => {
            applyFilterMeHandlers(); // Apply the jQuery handlers
      }, []);

      const [title, setTitle] = useState("");
      const [description ,setdescription] = useState("")
      const [categoryID, setCategoryID] = useState("");

      // console.log("supplementaryItems", supplementaryItems);
      const [CatagoryList, setCatagoryList] = useState([]);
      const [ProductList ,setProductList] = useState([]);

      useEffect(() => {
            groupAdminCategoryListById();
      }, [menuId && branchID]);

      useEffect(() => {
            setProductList([])
            productListById();
      }, [categoryID?.value]);

      function groupAdminCategoryListById() {
            let data = { menu_id: menuId };

            api
                  .post(`/groupadmin/category/${branchID}`, data, {
                        headers: headers,
                  })
                  .then((res) => {
                        const tableData = res.data.data;
                        
                        const addonOptions = tableData?.length > 0 ? tableData
                        .filter(addon => addon?.is_active && !addon?.is_featured && addon?._id !== id)
                        .map(addon => ({
                          label: addon.name_EN,
                          value: addon._id,
                        })) : [];                      
                        setCatagoryList(addonOptions);
                  })
                  .catch((err) => {
                        showError("No Category Found.");
                        // if (err.response) {
                        //     // if (err.response.data.error.message) {
                        //     //     console.log(err.response.data.error.message);
                        //     // }
                        // }
                  });
      }

      function productListById() {
            if(categoryID?.value){
            let data = { menu_id: menuId ,category_id : categoryID?.value };

            api
                  .post(`/groupadmin/product/${branchID}`, data, {
                        headers: headers,
                  })
                  .then((res) => {
                        const tableData = res.data.data;
                        const addonOptions = tableData?.length > 0 ? tableData
                        .filter(addon => addon?.is_active)
                        .map(addon => ({
                          name: addon?.name_EN,
                          _id: addon?._id,
                          price : addon?.base_price,
                          is_active : true
                        })) : [];  
                                           
                        setProductList(addonOptions);
                        // setCategoryID("");
                  })
                  .catch((err) => {
                        if (err.response) {
                              // setCategoryID("");
                            // if (err.response.data.error.message) {
                            //     console.log(err.response.data.error.message);
                            // }
                        }
                  });
            }
      }


      const handleCategoryChange = (selectedOption) => {
            setCategoryID(selectedOption);
            clearError(null)
      };


      const handleInputChange = (index, field, value) => {
            const newItems = [...ProductList];
            newItems[index][field] = value;
            setProductList(newItems)
      };

      console.log("ProductList" ,ProductList)

      const handleSubmit = async (e) => {
            e.preventDefault();
            clearError();

            if (title === "") {
                  showError("Please enter additional category items title");
                  return;
            }
            // if (description === "") {
            //       showError("Please enter additional category items titel");
            //       return;
            // }
            if (categoryID ===  "") {
                  showError("please select category");
                  return;
            }

            if(ProductList?.length > 0){
            const payload = {
                  title: title,
                  name: categoryID?.label,
                  additional_products_id: categoryID?.value,
                  product_list: ProductList,
                  ...(description && { description }), // Include description only if it's not empty
              };
          
            // console.log("palylod" ,payload);
            // return

            submitForm(`/groupadmin/category/additionalProducts/${id}`, payload, history, showError, clearError, "Additional Category item has been added successfully.");
            }
      };

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                          <GroupAdminHeader />
                                          <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">
                                                                  Menu Management
                                                            </h1>
                                                            <hr />
                                                            <div className="d-flex flex-stack mb-5">
                                                                  <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                        <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                                                                              Category - Add Additional Category
                                                                        </h3>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                                                                              <button onClick={() => history.goBack()} className="btn btn-primary ps-7">
                                                                                    Go Back
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="rounded border p-5">
                                                                  <div className="tab-content" id="myTabContent">
                                                                        <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                                                                              <div className="card card-docs mb-2">
                                                                                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                                                          <form onSubmit={handleSubmit}>
                                                                                                <div className="rounded border p-10">
                                                                                                      <div className="row mb-8">
                                                                                                            <div className="col-md-9">
                                                                                                                  <h3>Additional Category</h3>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />
                                                                                                      <div className="row mt-8 mb-8">
                                                                                                            <div className="col-md-3">
                                                                                                                  <p>Additional Category Items Details</p>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  <label className="form-label required">Title</label>
                                                                                                                  <input
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Title"
                                                                                                                        value={title}
                                                                                                                        // required
                                                                                                                        onChange={(e) => setTitle(e.target.value)}
                                                                                                                  />
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  <label className="form-label">Description</label>
                                                                                                                  <input
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Description"
                                                                                                                        value={description}
                                                                                                                        // required
                                                                                                                        onChange={(e) => setdescription(e.target.value)}
                                                                                                                  />
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      {/* <hr /> */}
                                                                                                      <div className="row mt-8">
                                                                                                            <div className="col-md-3">
                                                                                                                  {/* <p>Additional Category Items Details</p> */}
                                                                                                            </div>
                                                                                                            <div className="col-md-3">
                                                                                                            <label className="form-label required">Category</label>
                                                                                                                  <Select
                                                                                                                        placeholder="Select Category..."
                                                                                                                        options={CatagoryList}
                                                                                                                        onChange={handleCategoryChange}
                                                                                                                  />
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      {ProductList?.length > 0 && ProductList.map((item, index) => (
                                                                                                            <div className="row mt-8" key={index}>
                                                                                                                  <div className="col-md-3"></div>
                                                                                                                  <div className="col-md-3 d-flex align-items-center">
                                                                                                                        <input
                                                                                                                              className="form-check-input me-4"
                                                                                                                              type="checkbox"
                                                                                                                              checked={item.is_active}

                                                                                                                              onChange={(e) =>
                                                                                                                                    handleInputChange(index, "is_active", e.target.checked)
                                                                                                                              }
                                                                                                                        />
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              className="form-control"
                                                                                                                              placeholder="Enter Name"
                                                                                                                              value={item.name}
                                                                                                                              required
                                                                                                                              readOnly
                                                                                                                        // onChange={(e) =>
                                                                                                                        //       handleInputChange(index, "name", e.target.value)
                                                                                                                        // }
                                                                                                                        />
                                                                                                                  </div>
                                                                                                                  <div className="col-md-3">
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              className="form-control filterme"
                                                                                                                              placeholder="Enter Price"
                                                                                                                              value={Number(item?.price || 0).toFixed(2)}
                                                                                                                              required
                                                                                                                              readOnly
                                                                                                                        // onChange={(e) =>
                                                                                                                        //       handleInputChange(index, "price", e.target.value)
                                                                                                                        // }
                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      ))}
                                                                                                      <br />
                                                                                                      <hr />
                                                                                                      <ErrorMessage error={error} />
                                                                                                      <SubmitformButton1 handleSubmit={handleSubmit} onCancel={() => history.goBack()} />


                                                                                                </div>
                                                                                          </form>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                          <GroupAdminUperSide />
                                    </div>
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}

export default AddPopularChoices;
