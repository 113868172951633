import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";

import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import headers from "../../../../../../CommonApi/headers";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import useDrawer from "../../../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminTourSetupCompanyEdit() {

    let history = useHistory();
    const { Company_Id } = useParams();
    const [loading, setLoading] = useState(false);
    const [errorForm, setErrorForm] = useState({});

    $(".filterme").keypress(function (eve) {
        var allowedCharacters = /[0-9+\-.()]/;
        var inputValue = String.fromCharCode(eve.which);

        if (!allowedCharacters.test(inputValue)) {
            eve.preventDefault();
        }

        $(".filterme").keyup(function (eve) {
            if ($(this).val().indexOf(".") == 0) {
                $(this).val($(this).val().substring(1));
            }
        });
    });

    const [formData, setFormData] = useState({
        company_id: '',
        company_name: '',
        email: '',
        phone: '',
        mobile: '',
        address1: '',
        address2: '',
        address3: '',
    });


    useEffect(() => {

        async function fetchCompanyDetails() {

            try {
                const response = await api.post(`/groupadmin/tourgroup/detail_company`, { company_id: Company_Id }, { headers });

                const CompanyDetails = response?.data?.data;
                setFormData(prevFormData => ({
                    ...prevFormData,
                    company_id: CompanyDetails._id,
                    company_name: CompanyDetails.company_name,
                    email: CompanyDetails.email,
                    phone: CompanyDetails.phone,
                    mobile: CompanyDetails.mobile,
                    address1: CompanyDetails.address1,
                    address2: CompanyDetails.address2,
                    address3: CompanyDetails.address3,

                }));


            } catch (err) {
                if (err.response) {
                    setApiError(err.response?.data?.error?.message);
                }
            }
        }
        // Call the async function immediately inside the useEffect hook
        fetchCompanyDetails();
    }, []);

    const handleInputChange = (e, fieldName) => {
        const { name, value } = e.target;
        setErrorForm(prevErrorForm => ({ ...prevErrorForm, [name]: null }));
        setFormData({ ...formData, [name]: value });
    };
    const [apiError, setApiError] = useState('');
    const errorDiv = apiError ? (
        <div className="text-center alert alert-danger">{apiError}</div>
    ) : (
        ""
    );


    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        setErrorForm(null);

        // Check for validation errors for each field
        const errors = {};

        if (formData.company_name.trim() === '') {
            errors.company_name = "Company name is required.";
        }

        if (formData.email.trim() === '') {
            // errors.email = "Email is required.";
        } else {
            // Email validation regex pattern
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Check email format
            if (!emailPattern.test(formData.email)) {
                errors.email = "Please enter valid email address.";
            }
        }

        // if (formData.mobile.trim() === '') {
        //     errors.mobile = "Mobile is required.";
        // }

        // if (formData.address1.trim() === '') {
        //     errors.address1 = "Address1 is required.";
        // }

        // Update formErrors state with individual field errors
        setErrorForm(errors);

        // If there are any errors, prevent form submission
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            return;
        }

        Swal.fire({
            title: 'Please wait...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        api
            .post(`/groupadmin/tourgroup/edit_company`, formData, { headers })
            .then((res) => {
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    title: "Comapny details updated successfully.",
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    setLoading(false);
                    // history.push("/group_admin/settings/tourSteup/CompanyList");
                    window.location.href = '/group_admin/settings/tourSteup/CompanyList';
                });
                // Redirect to the CompanyList page
            })
            .catch((err) => {
                setLoading(false);
                if (err.response) {
                    Swal.close()
                    toast.error(err?.response?.data?.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
    }

    const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


    return (
        <React.Fragment>
            <div
                id="kt_body"
                className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
                        <div
                            className="wrapper d-flex flex-column flex-row-fluid"
                            id="kt_wrapper"
                        >
                            <GroupAdminHeader />
                            <div
                                className=" d-flex flex-column flex-column-fluid pt-0"
                                id="kt_content"
                            >
                                <div id="kt_content_container" className="mx-7">
                                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                    <div className="d-flex flex-stack mb-7">
                                        <div
                                            className="d-flex justify-content-end"
                                            data-kt-docs-table-toolbar="base"
                                        >
                                            <h1
                                                className="anchor fw-bolder mb-0"
                                                id="custom-form-control"
                                            >
                                                Edit Company
                                            </h1>
                                        </div>
                                        <div className="d-flex flex-stack">
                                            <div
                                                className="d-flex justify-content-end mx-2"
                                                data-kt-docs-table-toolbar="base"
                                            >
                                                <button
                                                    onClick={() => history.goBack()}
                                                    className="btn btn-primary"
                                                    data-kt-menu-trigger="click"
                                                    data-kt-menu-attach="parent"
                                                    data-kt-menu-placement="bottom-end"
                                                >
                                                    Go Back
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-5">
                                        <div className="card card-docs mb-2">
                                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                <div className="rounded border p-10">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="mb-10">
                                                            <h4>Company Details</h4>
                                                        </div>

                                                        <div className="row col-md-12">
                                                            <div className="col-md-6 mb-10">
                                                                <label className="required form-label">
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Company Name"
                                                                    name="company_name"
                                                                    value={formData.company_name}
                                                                    onChange={(e) => handleInputChange(e, 'company_name')}
                                                                />
                                                                {errorForm && errorForm.company_name && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.company_name}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6 mb-10">
                                                                <label className=" form-label">
                                                                    Address 1
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Address Line 1"
                                                                    name="address1"
                                                                    value={formData.address1}
                                                                    onChange={(e) => handleInputChange(e, 'address1')}
                                                                />
                                                                {errorForm && errorForm.address1 && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.address1}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row col-md-12">
                                                            <div className="col-md-6 mb-10">
                                                                <label className="form-label">
                                                                    Address 2
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Address Line 2"
                                                                    name="address2"
                                                                    value={formData.address2}
                                                                    onChange={(e) => handleInputChange(e, 'address2')}
                                                                />
                                                                {errorForm && errorForm.address2 && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.address2}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6 mb-10">
                                                                <label className="form-label">
                                                                    Address 3
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Address Line 3"
                                                                    name="address3"
                                                                    value={formData.address3}
                                                                    onChange={(e) => handleInputChange(e, 'address3')}
                                                                />
                                                                {errorForm && errorForm.address3 && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.address3}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row col-md-12">
                                                            <div className="col-md-6 mb-10">
                                                                <label className="form-label">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Phone Number"
                                                                    name="phone"
                                                                    value={formData.phone}
                                                                    onChange={(e) => handleInputChange(e, 'phone')}
                                                                />
                                                                {errorForm && errorForm.phone && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.phone}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6 mb-10">
                                                                <label className=" form-label">
                                                                    Mobile
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Mobile Number"
                                                                    name="mobile"
                                                                    value={formData.mobile}
                                                                    onChange={(e) => handleInputChange(e, 'mobile')}
                                                                />
                                                                {errorForm && errorForm.mobile && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.mobile}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row col-md-12">
                                                            <div className="col-md-6 mb-10">
                                                                <label className=" form-label">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Email ID"
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={(e) => handleInputChange(e, 'email')}
                                                                />
                                                                {errorForm && errorForm.email && (
                                                                    <div className="text-danger fs-5 mt-2">{errorForm.email}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 text-center">
                                                            <br />
                                                            <div
                                                                className="loader"
                                                                style={{ display: "none" }}
                                                            >
                                                                <img src="/assets/loader.gif" />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12"> {errorDiv} </div>
                                                        <div className="modal-footer flex-center">
                                                            <button
                                                                type="submit"
                                                                id="kt_modal_new_address_submit"
                                                                className="btn btn-primary"
                                                                disabled={loading}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => history.goBack()}
                                                                id="kt_modal_new_max_discount_value_submit"
                                                                className="btn btn-danger"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <GroupAdminUperSide />
            </div>
        </React.Fragment >
    );
}
