import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  capitalize,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InputBase from "@mui/material/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useStyles } from "../../../Comman/TableuseStyles";
import headers from "../../../../CommonApi/headers";
import $, { event } from "jquery";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function   GroupAdminOrderManagement() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();

  /////////////////////////////// Branch List /////////////////////////

  useEffect(() => {
    groupAdminBranchList();
  }, []);

  const [user1, setuser1] = useState([]);

  function groupAdminBranchList() {
    api
      .get(`/groupadmin/branch/restaurantwise`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setuser1(tableData);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }



//   function timeConvertor(datetime, timezone) {
//     const date = new Date(datetime);
//     const options = {
//         year: "numeric",
//         month: "2-digit",
//         day: "2-digit",
//         hour: "2-digit",
//         minute: "2-digit",
//         //second: "2-digit",
//         hour12: true,
//         timeZone: timezone
//     };

//     const formattedDate = date.toLocaleString("en-US", options);

//     return formattedDate;
// }
  


  ////////////////////// Branch List ///////////////////////////////////

  const [passengersList, setPassengersList] = useState([]);
  const [passengersList2, setPassengersList2] = useState([]);
  const [passengersCount, setPassengersCount] = useState(0);
  // const [passengersCount2, setPassengersCount2] = useState(0);
  const [filter, setFilter] = useState([]);
  const [BranchID_ , setBranchID_] = useState("")
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20,
    isLoading: false,
  });

  const [controllerBranchWise, setControllerBranchWise] = useState({
    page: 0,
    rowsPerPage: 20,
    isLoading: false,
  });



  let history = useHistory();

  useEffect(() => {
    OrdermanagementList();
  }, [controller]);

  useEffect(() => {
    OrdermanagementListByBranchID();
  }, [controllerBranchWise, BranchID_ ]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };



  const handlePageChangeBranchWise = (event, newPage) => {
    setControllerBranchWise({
      ...controllerBranchWise,
      page: newPage,
    });
  };

  const handleChangeRowsPerPageBranchWise= (event) => {
    setControllerBranchWise({
      ...controllerBranchWise,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = passengersList2.filter((row) => {
      return (
        row.order_no
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row.full_name
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row.email
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.phone_number
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.order_type
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.email
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase()) ||
        row?.total_amount
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedVal?.toString()?.toLowerCase())
      );
    });
    if (searchedVal?.length < 1) {
      setPassengersList(passengersList2);
    } else {
      setPassengersList(filteredRows);
    }
  };

  // const [branchID, setbranchID] = useState('');

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function OrdermanagementList(id) {
    setPassengersCount(0);
    let item = {
      branch_id: id,
    };

    let url = `/groupadmin/order/list?page=${controller.page}&per_page=${controller.rowsPerPage}`;

    api
      .get(`${url}`, { headers })
      .then((res) => {
        // const tableData = res.data.data;

        const tableData = res.data.data?.orderData;
        // console.log("taba", tableData)
        setPassengersList(tableData);
        setPassengersList2(tableData);
        setPassengersCount(res.data.data?.totalOrders);
        setFilter(tableData);

        if (res.data.data) {
          setError(null);
        }
        // console.log("Branch without ID Vadu", tableData);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.data?.error?.message) {
            setPassengersList([]);
            setError(err.response?.data?.error?.message);
          }
        }
      });
  }

  function OrdermanagementListByBranchID() {
    setPassengersCount(0)
    setError(null);

    let item = {
      branch_id: BranchID_,
    };
      let url = `/groupadmin/order/list?page=${controllerBranchWise.page}&per_page=${controllerBranchWise.rowsPerPage}`;

    if(BranchID_){
      api
      .post(`${url}`, item, { headers })
      .then((res) => {
        const tableData = res.data.data?.orderData;
        setPassengersList(tableData);
        setPassengersList2(tableData);
        setPassengersCount(res.data.data?.totalOrders);
        setFilter(tableData);
        // $("#kt_tab_pane_1").hide();
        // $("#BranchIDWith").show();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.data?.error?.message) {
            // BranchWithOut([]);
            setPassengersList([]);
            setError(err.response?.data?.error?.message);
            $("#BranchIDWith").hide();
          }
        }
      }); 
    }
      
  }

  const classes = useStyles();

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...passengersList].sort((a, b) => {
        return (
          a[sortField]
            ?.toString()
            ?.localeCompare(b[sortField]?.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setPassengersList(sorted);
    }
  };


  function deleteBranch(_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: text } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Provide a valid reason for delete this order',
          inputPlaceholder: 'Type your message here...',
          inputAttributes: {
            'aria-label': 'Type your message here'
          },
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Please enter a reason';
            }
          },
        });
        if (text) {
          api
            .delete(`/groupadmin/order/${_id}`, { headers, data: { delete_note: text } })
            .then((res) => {
              OrdermanagementList();
              OrdermanagementListByBranchID();
              Swal.fire({
                position: "center-center",
                icon: "success",
                title: "Order has been successfully deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              
            })
            .catch((err) => {
              if (err.response) {
                console.log(err.response.data.error.message);
              }
            });
        }
      }
    });
  }



  function handleSendEmail(_ID){ 
    // setError(null);
    //  
    // console.log("_ID" ,_ID);
      api
        .get(`/groupAdmin/order/send-order-email/${_ID}`, { headers })
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Email sent successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            // setError(err.response?.data?.error?.message);
            toast?.error(err.response?.data?.error?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        });
  }
  
  function handleSendEmailPOS(_ID){ 
      // setError(null);
       
      // console.log("_ID" ,_ID);
        api
          .get(`/groupAdmin/order/set-pos-order/${_ID}`, { headers })
          .then((res) => {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "Email sent successfully.",
              showConfirmButton: false,
              timer: 1500,
            });
            // window.location.reload(true);
          })
          .catch((err) => {
            if (err.response) {
              toast?.error(err.response?.data?.error?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
              // setError(err.response?.data?.error?.message);
            }
          });
    }
  

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                    style={{ float: "left" }}
                  >
                    Order Management
                  </h1>

                  <br />
                  <br />
                  <hr />
                  <br />
                  <div className="py-5">
                    <div className="card card-docs mb-2">
                      <div
                        className={
                          isMobile
                            ? "card-body fs-6 py-15 px-2 py-lg-15 text-gray-700"
                            : "card-body fs-6 py-15 px-10 py-lg-15 text-gray-700"
                        }
                      >
                        <div
                          className={
                            isMobile
                              ? "rounded border p-3"
                              : "rounded border p-5"
                          }
                        >
                          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                data-bs-toggle="tab"
                                onClick={(e) => {
                                  setBranchID_("")
                                  OrdermanagementList(e)
                                } }
                                to="#kt_tab_pane_1"
                              >
                                All Location
                              </Link>
                            </li>
                            {user1?.length > 0
                              ? user1?.map((main, index) => (
                                  <li className="nav-item" key={index}>
                                    <Link
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      // value={main._id}
                                      onClick={(e) =>
                                        {
                                          setBranchID_(main._id)
                                          // OrdermanagementListByBranchID(main._id) 
                                        }
                                      }
                                      // to={`#kt_tab_pane_${index}`}
                                      to="#kt_tab_pane_2"
                                    >
                                      {main.branch_name_EN}
                                    </Link>
                                  </li>
                                ))
                              : ""}
                          </ul>
                          <div className="col-md-12"> {errorDiv} </div>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              role="tabpanel"
                            >
                              <div className={classes.root}>
                                <Card style={{ overflowX: "auto" }}>
                                  {error ? (
                                    " "
                                  ) :  (
                                    BranchID_ ? 
                                    <TablePagination
                                      component="div"
                                      rowsPerPageOptions={[]}
                                      backIconButtonProps={{
                                        "aria-label": "Previous Page",
                                      }}
                                      nextIconButtonProps={{
                                        "aria-label": "Next Page",
                                      }}
                                      onPageChange={handlePageChangeBranchWise}
                                      page={controllerBranchWise.page}
                                      count={passengersCount}
                                      rowsPerPage={controllerBranchWise.rowsPerPage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPageBranchWise
                                      }
                                    /> :  <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    backIconButtonProps={{
                                      "aria-label": "Previous Page",
                                    }}
                                    nextIconButtonProps={{
                                      "aria-label": "Next Page",
                                    }}
                                    onPageChange={handlePageChange}
                                    page={controller.page}
                                    count={passengersCount}
                                    rowsPerPage={controller.rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                  /> 
                                  )}
                                  <hr />
                                  {error ? (
                                    " "
                                  ) : (
                                    <Search className="d-flex justify-content-end">
                                      <SearchIconWrapper>
                                        <SearchIcon style={{ fontSize: 22 }} />
                                      </SearchIconWrapper>
                                      <StyledInputBase
                                        style={{ fontSize: 22 }}
                                        placeholder="Search…"
                                        onChange={(e) =>
                                          requestSearch(e.target.value)
                                        }
                                        inputProps={{ "aria-label": "search" }}
                                      />
                                    </Search>
                                  )}
                                  <Table>
                                    <TableHead
                                      style={{
                                        background: "darkgray",
                                        color: "#fff",
                                        fontSize: 30,
                                      }}
                                    >
                                      <TableRow>
                                        
                                        <TableCell style={{ fontSize: "13px" }}>
                                          Order ID
                                        </TableCell>
                                          <TableCell
                                            style={{ fontSize: "13px" }}
                                          >
                                            Unique Code
                                          </TableCell>
                                
                                        {!BranchID_ ? (
                                          <TableCell
                                            style={{ fontSize: "13px" }}
                                          >
                                            Branch Name
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        <TableCell
                                          onClick={() =>
                                            handleSortingChange("order_date")
                                          }
                                          style={{
                                            fontSize: "13px",
                                            width: "15%",
                                            headerStyle:{ width: "15%" },
                                          }}
                                        >
                                          Date & Time{" "}
                                          {order === "asc" &&
                                          sortField === "order_date" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          onClick={() =>
                                            handleSortingChange("full_name")
                                          }
                                          style={{ fontSize: "13px" }}
                                        >
                                          Customer Details{" "}
                                          {order === "asc" &&
                                          sortField === "full_name" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "13px" }}>
                                          Location(Address)
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontSize: "13px",
                                            // width: "8%",
                                            // headerStyle: { width: "8%" },
                                          }}
                                          // onClick={handleHeaderClick}
                                          onClick={() =>
                                            handleSortingChange("order_type")
                                          }
                                        >
                                          Type
                                          {order === "asc" &&
                                          sortField === "order_type" ? (
                                            <ArrowUpwardIcon />
                                          ) : (
                                            <ArrowDownwardIcon />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontSize: "13px",
                                            // width: "8%",
                                            // headerStyle: { width: "8%" },
                                          }}
                                        >
                                          Price(CHF)
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            fontSize: "13px",
                                            // width: "10%",
                                            // headerStyle: { width: "10%" },
                                          }}
                                        >
                                          Emailing
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontSize: "13px",
                                            // width: "8%",
                                            // headerStyle: { width: "8%" },
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {passengersList?.map(
                                        (passenger, index) => (
                                          <TableRow key={index}>
                                            
                                            <TableCell
                                              style={{ fontSize: "13px" }}
                                            >
                                              <div
                                                style={{
                                                  color: "blue",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  history.push(
                                                    `/group_admin/order_management/order_detail_view/${passenger?._id}`
                                                  )
                                                }
                                              >
                                                {passenger?.order_no}
                                              </div>
                                            </TableCell>
                                            <TableCell
                                                style={{ fontSize: "13px" , textAlign : "center" }}
                                              >
                                              {passenger?.unique_code ? passenger?.unique_code : "---"}
                                              </TableCell>
                                            {!BranchID_ ? (
                                              <TableCell
                                                style={{ fontSize: "13px" }}
                                              >
                                                {
                                                  passenger?.branch
                                                    ?.branch_name_EN
                                                }
                                              </TableCell>
                                            ) : (
                                              ""
                                            )}
                                            <TableCell
                                              style={{
                                                fontSize: "13px",
                                                // width: "15%",
                                                // cellStyle: { width: "15%" },
                                              }}
                                            >
                                             <div>
                                              
                                              {passenger?.order_type === "dineIn" ?  
                                              moment.utc(passenger?.order_date).tz(passenger?.branch?.branchTimeZone).format("DD/MM/YYYY")
                                              // timeConvertor(passenger?.order_date , passenger?.branch?.branchTimeZone)
                                               : moment.utc(passenger?.order_date).format("DD/MM/YYYY")}
                                             
                                              </div> 
                                             <div>
                                             {passenger?.order_type === "dineIn" ? 
                                             moment.utc(passenger?.order_date).tz(passenger?.branch?.branchTimeZone).format("h:mm A") : 
                                             passenger?.order_time}
                                             </div>
                                              
                                            </TableCell>
                                            <TableCell
                                              style={{ fontSize: "13px" }}
                                            >
                                              <div>
                                                {passenger?.full_name} <br />
                                                {passenger?.phone_number}
                                                <br />
                                                {passenger?.email}
                                              </div>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "13px",
                                                textAlign: "center",
                                                 width: "15%",
                                                cellStyle: { width: "15%" },
                                              }}
                                            >
                                              {passenger?.order_type === "dineIn" ? 
                                              <div>
                                                Table No :- {passenger?.table_number}
                                              </div> :
                                              
                                              
                                              <div>
                                                {passenger?.address_line1
                                                  ? passenger?.address_line1
                                                  : "---"}
                                                <br />
                                                {passenger?.address_line2
                                                  ? passenger?.address_line2
                                                  : ""}
                                              </div> }
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "13px",
                                                // width: "8%",
                                                // cellStyle: { width: "8%" },
                                              }}
                                            >
                                              {
                                                <div style={{ textTransform : "capitalize"}}>
                                                  {passenger?.order_type}
                                                </div>
                                              }
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "13px",
                                                // width: "8%",
                                                // cellStyle: { width: "8%" },
                                              }}
                                            >
                                              {Number(
                                                passenger?.total_amount_without_Discount || 0
                                              ).toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                //  width: "10%",
                                                // cellStyle: { width: "10%" },
                                              }}
                                            >
                                            
                                        <Button className="my-2"  style={{textTransform: "capitalize" , width : "100px" ,  border : "1px solid #F25042" , color : "#F25042"}} variant="outlined" 
                                          onClick={()=> handleSendEmail(passenger._id)} // startIcon={<RedeemIcon />}
                                          >
                                          Send Email
                                        </Button> 
                                        <br/>
                                        <Button style={{textTransform: "capitalize" , width : "100px" , border : "1px solid #F25042" , color : "#F25042"}} variant="outlined" 
                                          onClick={()=> handleSendEmailPOS(passenger._id)} // startIcon={<RedeemIcon />}
                                          >
                                          Send POS
                                        </Button> 
                                              
                                            </TableCell>
                                            <TableCell
                                              style={{ fontSize: "13px" }}
                                            >
                                              <Tooltip title="Delete Order">
                                                <IconButton
                                                  aria-label="Details"
                                                  onClick={()=> deleteBranch(passenger._id)}
                                                >
                                                  <DeleteIcon    className="fs-1" />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                  {error ? (
                                    " "
                                  ) :  (
                                    BranchID_ ? 
                                    <TablePagination
                                      component="div"
                                      rowsPerPageOptions={[]}
                                      backIconButtonProps={{
                                        "aria-label": "Previous Page",
                                      }}
                                      nextIconButtonProps={{
                                        "aria-label": "Next Page",
                                      }}
                                      onPageChange={handlePageChangeBranchWise}
                                      page={controllerBranchWise.page}
                                      count={passengersCount}
                                      rowsPerPage={controllerBranchWise.rowsPerPage}
                                      onRowsPerPageChange={
                                        handleChangeRowsPerPageBranchWise
                                      }
                                    /> :  <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    backIconButtonProps={{
                                      "aria-label": "Previous Page",
                                    }}
                                    nextIconButtonProps={{
                                      "aria-label": "Next Page",
                                    }}
                                    onPageChange={handlePageChange}
                                    page={controller.page}
                                    count={passengersCount}
                                    rowsPerPage={controller.rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                  /> 
                                  )}
                                </Card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
