import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminFooter from "../../../../Comman/Footer";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Helmet } from "react-helmet";
import headers from "../../../../../CommonApi/headers";
import api from "../../../../../CommonApi/axios";
import Swal from "sweetalert2";

export default function GroupAdminMenuCategoryEditProduct() {
  const location = useLocation();
  const id = useParams();

  useEffect(() => {
    // console.log(location.state.menuId);
    // console.log(location.state.branchID);
  }, [location]);

  let history = useHistory();

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [name_EN, setname_EN] = useState("");
  const [name_FR, setname_FR] = useState("");
  const [name_IT, setname_IT] = useState("");
  const [name_DE, setname_DE] = useState("");
  const [description_EN, setdescription_EN] = useState("");
  const [description_FR, setdescription_FR] = useState("");
  const [description_IT, setdescription_IT] = useState("");
  const [description_DE, setdescription_DE] = useState("");
  const [is_active, setis_active] = useState("");
  const [image, setimage] = useState("");

  useEffect(() => {
    CatagoryDetailsGet();
  }, []);

  function CatagoryDetailsGet() {
    api.get(`/groupadmin/category/${id.id}`, { headers }).then((res) => {
      const tableData = res.data.data;

      setname_EN(tableData.name_EN);
      setname_FR(tableData.name_FR);
      setname_IT(tableData.name_IT);
      setname_DE(tableData.name_DE);
      setdescription_EN(tableData.description_EN);
      setdescription_FR(tableData.description_FR);
      setdescription_IT(tableData.description_IT);
      setdescription_DE(tableData.description_DE);
      setis_active(tableData.is_active);
      setimage(tableData.image ? tableData.image : "");
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    const formData = new FormData();
    formData.append("name_EN", name_EN);
    formData.append("name_FR", name_FR);
    formData.append("name_IT", name_IT);
    formData.append("name_DE", name_DE);
    formData.append("description_EN", description_EN);
    formData.append("description_FR", description_FR);
    formData.append("description_IT", description_IT);
    formData.append("description_DE", description_DE);
    formData.append("is_active", is_active);
    formData.append("image", image[0]);
    // formData.append('wallpaper', wallpaper[0]);
    formData.append("fileName", image.name);
    // formData.append('fileName', wallpaper.name);

    // for (let [key, value] of formData) {
    //     console.log(`${key}: ${value}`)
    // }

    api
      .patch(`/groupadmin/category/${id.id}`, formData, { headers })
      .then((res) => {
        setTimeout(() => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/group_admin/menu_management/menu_category", {
            menuId: location.state.menuId,
            branchID: location.state.branchID,
          });
        });
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.error.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Product - Edit Product
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.goBack()}
                          className="btn btn-primary ps-7"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="py-5">
                    <div className="rounded border p-10">
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <h1
                                      style={{
                                        marginBottom: "35px",
                                        marginTop: "-28px",
                                      }}
                                    >
                                      Edit Category
                                    </h1>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6>Product Name</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="required form-label"
                                        >
                                          EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="English"
                                          value={name_EN}
                                          required
                                          onChange={(e) =>
                                            setname_EN(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className=" form-label"
                                        >
                                          FR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="French"
                                          value={name_FR}
                                          onChange={(e) =>
                                            setname_FR(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className=" form-label"
                                        >
                                          IT
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Italian"
                                          value={name_IT}
                                          onChange={(e) =>
                                            setname_IT(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className=" form-label"
                                        >
                                          DE
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="German"
                                          value={name_DE}
                                          onChange={(e) =>
                                            setname_DE(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <br />
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6>Description</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="required form-label"
                                        >
                                          EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="English"
                                          value={description_EN}
                                          required
                                          onChange={(e) =>
                                            setdescription_EN(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className="form-label"
                                        >
                                          FR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="French"
                                          value={description_FR}
                                          onChange={(e) =>
                                            setdescription_FR(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className=" form-label"
                                        >
                                          IT
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Italian"
                                          value={description_IT}
                                          onChange={(e) =>
                                            setdescription_IT(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label
                                          htmlFor="exampleFormControlInput1"
                                          className=" form-label"
                                        >
                                          DE
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="German"
                                          value={description_DE}
                                          onChange={(e) =>
                                            setdescription_DE(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={() =>
                                            setis_active(!is_active)
                                          }
                                          checked={is_active}
                                          //
                                        />
                                        <label className="form-check-label mx-3">
                                          <h6>Active</h6>
                                        </label>
                                      </div>
                                    </div>
                                    <br />
                                    <div className="rounded border p-10">
                                      <h6>Image</h6>
                                      <br />

                                      <div
                                        className="image-input image-input-outline"
                                        data-kt-image-input="true"
                                        style={{
                                          backgroundImage:
                                            "url(/../assets/media/avatars/blank.png)",
                                        }}
                                      >
                                        <div
                                          className="image-input-wrapper w-125px h-125px"
                                          style={{
                                            backgroundImage:
                                              "url(../assets/media/svg/3377061_food_hor_mok_thai_icon.png)",
                                          }}
                                        >
                                          <img
                                            src={
                                              image
                                                ? image
                                                : "/assets/media/svg/3377061_food_hor_mok_thai_icon.png"
                                            }
                                            alt=""
                                            height="125"
                                            width="125"
                                          />
                                        </div>
                                        <label
                                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                          data-kt-image-input-action="change"
                                          data-bs-toggle="tooltip"
                                          data-bs-dismiss="click"
                                          // title="Change avatar"
                                        >
                                          <i className="bi bi-pencil-fill fs-7" />
                                          <input
                                            type="file"
                                            name="avatar"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) =>
                                              setimage(e.target.files)
                                            }
                                          />
                                        </label>
                                        <span
                                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                          data-kt-image-input-action="cancel"
                                          data-bs-toggle="tooltip"
                                          data-bs-dismiss="click"
                                        >
                                          <i className="bi bi-x fs-2" />
                                        </span>
                                        <span
                                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                          data-kt-image-input-action="remove"
                                          data-bs-toggle="tooltip"
                                          data-bs-dismiss="click"
                                          title="Remove"
                                        >
                                          <i
                                            onClick={(e) => setimage("")}
                                            className="bi bi-x fs-2"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                  <div className="col-md-12"> {errorDiv} </div>

                                  <div className="modal-footer flex-center">
                                    <button
                                      type="submit"
                                      id="kt_modal_new_address_submit"
                                      className="btn btn-primary"
                                    >
                                      <span className="indicator-label">
                                        Save Category
                                      </span>
                                    </button>
                                    <button
                                      className="btn btn-danger m-2"
                                      onClick={() => history.goBack()}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <GroupAdminFooter /> */}
            </div>
            <GroupAdminUperSide />
          </div>
          <Helmet>
            <script src="/assets/plugins/global/plugins.bundle.js"></script>
            <script src="/assets/js/scripts.bundle.js"></script>
            <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
            <script src="/assets/js/custom/widgets.js"></script>
            <script src="/assets/js/custom/apps/chat/chat.js"></script>
            <script src="/assets/js/custom/modals/create-app.js"></script>
            <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
          </Helmet>
        </div>
      </div>
    </React.Fragment>
  );
}
