import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { deleteCommonFunction } from "./DeleteCommonFun/DeleteCommonFunction";
import { userPermissionType } from "./UserPermission";

/**
 * Generates table action handlers.
 * 
 * @param {object} history - The history object from react-router-dom.
 * @param {function} refreshData - The function to call for delete operations.
 * @param {object} paths - An object containing dynamic paths for view, edit, and delete actions.
 * 
 * @returns {Array} - An array of action configurations.
 * 
 */

const abca = window?.location?.pathname?.split("/");
const url = abca?.[2];

const userPermissionType_ = userPermissionType === "groupadmin"

// console.log("url" ,url )

export function generateTableActions(history, refreshData, paths, tooltips, messages, permission) {
  const actions = [];

  // console.log("printer" ,permission)
  // If userPermissionType_ is true, add all actions regardless of permissions
  if (userPermissionType_) {
    if(paths.viewPath){
        actions.push(
          {
            icon: () => <VisibilityIcon />,
            tooltip: tooltips.viewTooltip,
            onClick: (event, rowData) => {
              history.push(`/${paths.viewPath}/${rowData._id}`);
            },
          },
        )
    }
    if(paths.editPath){
      actions.push(
        {
          icon: "edit",
          tooltip: tooltips.editTooltip,
          onClick: (event, rowData) => {
            if (rowData?.branch?.currency) {
              history.push(`/${paths.editPath}/${rowData._id}/${rowData?.branch?.currency}`);
            } else {
              history.push(`/${paths.editPath}/${rowData._id}`);
            }
          },
        },
      )
    }
    if(paths.deletePath){
    actions.push(
      {
        icon: "delete",
        tooltip: tooltips.deleteTooltip,
        onClick: (event, rowData) => {
          deleteCallback(rowData._id, paths.deletePath, refreshData, messages.deleteMessage);
        },
      }
    )}
  } else {
    // Add actions based on individual permissions
    if (permission?.access?.access?.includes("view")) {
      if(paths.viewPath){
      actions.push({
        icon: () => <VisibilityIcon />,
        tooltip: tooltips.viewTooltip,
        onClick: (event, rowData) => {
          history.push(`/${paths.viewPath}/${rowData._id}`);
        },
      });
    }
    }

    if (permission?.access?.access?.includes("edit")) {
      if(paths.editPath){
      actions.push({
        icon: "edit",
        tooltip: tooltips.editTooltip,
        onClick: (event, rowData) => {
          if (rowData?.branch?.currency) {
            history.push(`/${paths.editPath}/${rowData._id}/${rowData?.branch?.currency}`);
          } else {
            history.push(`/${paths.editPath}/${rowData._id}`);
          }
        },
      });
    }
    }

    if (permission?.access?.access?.includes("delete")) {
      if(paths.deletePath){
      actions.push({
        icon: "delete",
        tooltip: tooltips.deleteTooltip,
        onClick: (event, rowData) => {
          deleteCallback(rowData._id, paths.deletePath, refreshData, messages.deleteMessage);
        },
      });
    }
    }
  }

  return actions;
}



/**
 * A common delete function for all table actions.
 * 
 * @param {string} id - The ID of the item to be deleted.
 * @param {string} url - The API endpoint for the delete operation.
 * @param {function} refreshData - The function to refresh the table data after deletion.
 */
export function deleteCallback(id, url, refreshData , messages) {
  deleteCommonFunction(`${url}/${id}`, messages, refreshData);
}
