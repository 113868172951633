import { ADDONPROPERTIES } from "../../actions";

const addonListReducer = (state = [], action) => {
    switch (action.type) {
        case ADDONPROPERTIES.CLEAR_ADD_ON:
            return [];
        case ADDONPROPERTIES.SET_ADD_ON:
            return [...action?.addonlist];
        default:
            return state;
    }
}


export { addonListReducer }