import React from 'react';
import QRCode from 'qrcode.react';

const downloadQR = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
        .toDataURL()
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "URLSlugQR.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const QRCodeDownload = ({ dashboardURl }) => (
    <>
        <div style={{ display: "none" }}>
            {dashboardURl && (
                <QRCode
                    id="123456"
                    value={dashboardURl}
                    size={256}
                    style={{
                        height: "auto",
                        maxWidth: "40%",
                        width: "40%",
                    }}
                    level={"H"}
                    includeMargin={true}
                />
            )}
        </div>

        {dashboardURl && (
            <div>
                <span
                    style={{
                        display: "flex",
                        padding: "10px 0px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        borderRadius: "8px",
                        border: "1px solid #F25042",
                        background: "#F25042",
                        color: "white",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        fontSize: "15px",
                        width: "180px",
                        cursor: "pointer",
                    }}
                    onClick={downloadQR}
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="QR Code">
                            <path
                                id="Shape"
                                d="M6 4H4V6H6V4ZM1 3.25C1 2.00736 2.00736 1 3.25 1H6.75C7.99264 1 9 2.00736 9 3.25V6.75C9 7.99264 7.99264 9 6.75 9H3.25C2.00736 9 1 7.99264 1 6.75V3.25ZM3.25 2.5C2.83579 2.5 2.5 2.83579 2.5 3.25V6.75C2.5 7.16421 2.83579 7.5 3.25 7.5H6.75C7.16421 7.5 7.5 7.16421 7.5 6.75V3.25C7.5 2.83579 7.16421 2.5 6.75 2.5H3.25ZM4 14H6V16H4V14ZM1 13.25C1 12.0074 2.00736 11 3.25 11H6.75C7.99264 11 9 12.0074 9 13.25V16.75C9 17.9926 7.99264 19 6.75 19H3.25C2.00736 19 1 17.9926 1 16.75V13.25ZM3.25 12.5C2.83579 12.5 2.5 12.8358 2.5 13.25V16.75C2.5 17.1642 2.83579 17.5 3.25 17.5H6.75C7.16421 17.5 7.5 17.1642 7.5 16.75V13.25C7.5 12.8358 7.16421 12.5 6.75 12.5H3.25ZM16 4H14V6H16V4ZM13.25 1C12.0074 1 11 2.00736 11 3.25V6.75C11 7.99264 12.0074 9 13.25 9H16.75C17.9926 9 19 7.99264 19 6.75V3.25C19 2.00736 17.9926 1 16.75 1H13.25ZM12.5 3.25C12.5 2.83579 12.8358 2.5 13.25 2.5H16.75C17.1642 2.5 17.5 2.83579 17.5 3.25V6.75C17.5 7.16421 17.1642 7.5 16.75 7.5H13.25C12.8358 7.5 12.5 7.16421 12.5 6.75V3.25ZM11 11H13.75V13.75H11V11ZM16.25 13.75H13.75V16.25H11V19H13.75V16.25H16.25V19H19V16.25H16.25V13.75ZM16.25 13.75V11H19V13.75H16.25Z"
                                fill="white"
                            />
                        </g>
                    </svg>
                    Download QR
                </span>
            </div>
        )}
    </>
);

export default QRCodeDownload;
