import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import MaterialTable from "material-table";
import { useStyles } from "../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../Comman/TableOption";
import useDrawer from "../../../../context/DrawerContext";
import { deleteCommonFunction } from "../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { UserPermissionAllData, userPermissionType } from "../../../Comman/UserPermission";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
window.jquery = window.$ = $;

export default function GroupAdminReasonsManagement() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const classes = useStyles();
  const userPermissionType_ = userPermissionType === "groupadmin"


  let history = useHistory();

  useEffect(() => {
    groupAdminReasonList();
  }, []);



  const [voucher, serVoucher] = useState([]);
  const [Id__, setId] = useState("");

  // console.log("id" ,Id__)

  function groupAdminReasonList() {

    api
      .get(`/groupadmin/discount`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        serVoucher(tableData);
        console.log(tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }



  const [reason, setreason] = useState("")
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );


  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (reason === "") {
      setError("Please enter reason.");
      return;
    }
    else {
      $(".loader").show();

      const item = new FormData();
      item.append("reason", reason);


      api
        .post(`/groupadmin/discount`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          groupAdminReasonList();
          showSuccessNotification("Discount reason has been added succesfully.")
          handleClose();
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }

  }

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Reason",
      field: "discount_reason",
    },

    // {
    //   title: "Sequence No",
    //   field: "seq_no",
    //   render: (rowData) => {
    //     return (
    //       <div className="text-center" style={{fontSize : "16px"}}>
    //        {rowData?.seq_no}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Branch Status",
    //   render: (rowData) => {
    //     return (
    //       <div>
    //         {rowData.is_active == true ? (
    //           <button type="button" className="btn btn-success">
    //             Active
    //           </button>
    //         ) : (
    //           <button type="button" className="btn btn-Inactive">
    //             Inactive
    //           </button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  function deleteBranch(_id) {
    deleteCommonFunction(`/groupadmin/discount/${_id}`, "Discount reasons has been successfully deleted.", groupAdminReasonList);
  }


  function handleSubmitEdit(event) {
    event.preventDefault();
    setError(null);
    if (reason === "") {
      setError("Please enter reason.");
      return;
    }
    else {
      $(".loader").show();
      const item = new FormData();
      item.append("reason", reason);
      api
        .patch(`/groupadmin/discount/${Id__}`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          groupAdminReasonList();
          setreason("");
          showSuccessNotification("Discount reason has been edited succesfully.")
          handleClose();
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }

  }

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setError(null);
    setId("");
    setShow(false)
  }

  const handleShow = () => setShow(true);
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="content d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>

                    <div className="d-flex flex-stack mb-7">
                      <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                        <h1 className="anchor fw-bolder" id="custom-form-control">
                          Discount Management
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button onClick={() => history.push("/group_admin/settings/discount_and_tips_management")} className="btn btn-primary">Go Back</button>
                        </div>
                      </div>
                    </div>


                    <div className={isMobile ? "" : "rounded border p-10"}>
                      {/* <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                      <li className="nav-item">
                        <Link
                          className={
                            url === "discout_management"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/discount_and_tips_management/discout_management",
                          }}
                        >
                          Discount Management
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            url === "reasons_management"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "//group_admin/discount_and_tips_management/reasons_management",
                          }}
                        >
                          Reasons
                        </Link>
                      </li>
                    </ul> */}
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <React.Fragment>
                            <Card>
                              <Card.Body>
                                <div className="d-flex flex-stack mb-5">
                                  <div
                                    className="d-flex justify-content-end"
                                    data-kt-docs-table-toolbar="base"
                                  >
                                    <h2
                                      className="anchor fw-bolder mb-5"
                                      id="custom-form-control"
                                    >
                                      Discount Reasons
                                    </h2>
                                  </div>
                                  <div className="d-flex flex-stack mb-5">
                                    <div
                                      className="d-flex justify-content-end mx-2"
                                      data-kt-docs-table-toolbar="base"
                                    >
                                      {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                        permission?.key === "discount" &&
                                        permission?.access?.includes("create")
                                      )) && (
                                          <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                              handleShow();
                                              setreason("");
                                              setId('');
                                            }
                                            }
                                          >
                                            Add Reason
                                          </button>)}

                                    </div>
                                  </div>
                                </div>
                                <div className="my-5">
                                  <div className={classes.root}>
                                    <MaterialTable
                                      title=""
                                      data={voucher}
                                      columns={columns}
                                      options={Tableoptions}
                                      actions={
                                        userPermissionType_ ?
                                          [
                                            {
                                              icon: "edit",
                                              tooltip: "Edit Reason",
                                              onClick: (event, rowData) => {
                                                setreason(rowData?.discount_reason);
                                                setId(rowData._id);
                                                handleShow();
                                              }
                                            },
                                            {
                                              icon: "delete",
                                              tooltip: "Delete Reason",
                                              onClick: (event, rowData) =>
                                                deleteBranch(rowData._id),
                                            },
                                          ] :

                                          [
                                            {
                                              icon: () => {
                                                const hasDeletePermission = UserPermissionAllData?.role_details?.settings?.some(permission =>
                                                  permission?.key === "discount" &&
                                                  permission?.access?.includes("edit")
                                                );
                                                return hasDeletePermission ? <EditIcon /> : null;
                                              },
                                              tooltip: "Edit Reason",
                                              onClick: (event, rowData) => {
                                                setreason(rowData?.discount_reason);
                                                setId(rowData._id);
                                                handleShow();
                                              }
                                            },
                                            {
                                              icon: () => {
                                                const hasDeletePermission = UserPermissionAllData?.role_details?.settings?.some(permission =>
                                                  permission?.key === "discount" &&
                                                  permission?.access?.includes("delete")
                                                );
                                                return hasDeletePermission ? <DeleteIcon /> : null;
                                              },
                                              tooltip: "Delete Reason",
                                              onClick: (event, rowData) =>
                                                deleteBranch(rowData._id),
                                            },
                                          ]}
                                    />
                                  </div>
                                </div>

                              </Card.Body>
                            </Card>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>

                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h1>{Id__ ? "Edit Reason" : "Add Reason"} </h1>
          <Form onSubmit={Id__ ? handleSubmitEdit : handleSubmit}>
            <Form.Group className="mb-3 mt-3" >
              <Form.Label>Please enter an appropriate reason. <span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={reason}
                onChange={(e) => setreason(e.target.value.replace(/^\s/, ''))}
              />
            </Form.Group>


            <div className="col-md-12 text-center">
              <br />
              <div className="loader" style={{ display: "none" }}>
                <img src="/assets/loader.gif" />
              </div>
            </div>
            <div className="col-md-12"> {errorDiv} </div>
            <Row className="text-center justify-content-center mx-1">
              <Col lg={6} md={6} xs={6}>
                <Button style={{ width: "100%" }} variant="primary" type="submit">
                  {Id__ ? "Edit" : "Save"}
                </Button>
              </Col>
              <Col lg={6} md={6} xs={6}>
                <Button style={{ width: "100%" }} variant="secondary" type="button" className="btn btn-danger"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>



    </React.Fragment>
  );
}


