import axios from "axios";

const api = axios.create({
  // baseURL: "https://groupapi.kitchenparameter.com", //// Client Server //////
  // baseURL: "http://3.12.248.21:9981",
  // baseURL: "http://192.168.1.22:9982",

  baseURL: process.env.REACT_APP_BASE_URL,
});

if (process.env.REACT_APP_BASE_URL === 'https://groupapi.kitchenparameter.com') {
  const noop = () => { }
  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ].forEach((method) => {
    window.console[method] = noop
  })
}

// console.log("base Url - ", process.env.REACT_APP_BASE_URL);
// console.log("base Url1 - ", window?.env?.API_URL);
// console.log(process.env.REACT_APP_(API_URL));

//// http://goldenindia-restaurantgroup.creatoro.in
//  http://www.resgroup.mypreview.xyz
// http://group.kitchenparameter.com

export default api;
