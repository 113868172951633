import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import api from '../../../../CommonApi/axios';
import headers from '../../../../CommonApi/headers';
import { validateEmail } from '../../../Comman/AddCommonFun/validation';
import useError from '../../../Comman/Error/useError';
import { ErrorMessage } from '../../../Comman/Error/ErrorMessage';
import { showSuccessNotification } from '../../../Comman/Swal/Swal';
import Loader from '../../../Comman/Loader/Loader';

export default function SendEmailModel({show , OrderData, handleClose}) {

      const [email ,setemail] = useState("");
      const [show1 ,setshow1] = useState(false)
 
      const { error, showError, clearError } = useError();
     
      useEffect(()=>{
        setemail(OrderData?.email)
      },[OrderData?.email])

      // console.log("OrderData" ,OrderData?.email)

      function handleSendEmail(event){ 
            event.preventDefault();
            clearError(null);
            setshow1(true)
            let item = {
                  email  : email
            }

            if (!validateEmail(email)) {
                  showError("Invalid Email");
                  setshow1(false)
                  return
            } else {
           
              api
                .post(`/groupAdmin/order/send-order-email/${OrderData?._id}`,item, { headers })
                .then((res) => {
                  showError(null);
                  setshow1(false)
                  showSuccessNotification("Email sent successfully.")
                  handleClose();
                  setemail("")
                  // window.location.reload(true);
                })
                .catch((err) => {
                  if (err.response) {
                    setshow1(false)
                    showError(err.response?.data?.error?.message);
                  }
                });
            }
      }

  return (
    <>
      <Modal show={show} onHide={()=> {
        handleClose();
        setemail("");
        showError(null);
      }}>
      <Modal.Body>
        <form onSubmit={handleSendEmail}>
        <h2 className="mb-4">Send Email</h2>
            <hr />
        <div className="mx-4">
                  <div className="my-5">
                    <label className="required form-label">Email</label>
                    <input
                      className="form-control form-control-lg"
                      placeholder="Enter email"
                      required
                      value={email}
                        type='text'
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>

                      <ErrorMessage error={error}/>
                    <div style={{marginTop  :"25px"}} className="row mx-4">
                      <div className="row col-6 me-6">
                      {show1 ? 
                        <Loader />
                       :
                      <button
                        type="submit"
                        id="kt_modal_new_address_submit"
                        className="btn btn-primary"
                      >
                        Send Email
                      </button> }
                    </div> 
                    <div className="row col-6">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={()=> {
                          handleClose();
                          setemail("");
                          showError(null);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>

         
            </form>
      </Modal.Body>
    </Modal>
    </>
  )
}
