import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownWideShort,
  faBars,
  faBarsStaggered,
  faColumns,
  faCompress,
  faEllipsisH,
  faEllipsisVertical,
  faExpand,
  faEyeSlash,
  faFilter,
  faFilterCircleXmark,
  faGripLines,
  faSearch,
  faSearchMinus,
  faSquareCaretDown,
  faSortDown,
  faThumbTack,
} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
// config.autoAddCss = false;


    export const fontAwesomeIcons = {
      SearchIcon: () => <FontAwesomeIcon icon={faSearch} />,
      ArrowDownwardIcon: (props) => <FontAwesomeIcon icon={faSortDown} {...props} />,
      ClearAllIcon: () => <FontAwesomeIcon icon={faBarsStaggered} />,
      DensityLargeIcon: () => <FontAwesomeIcon icon={faGripLines} />,
      DensityMediumIcon: () => <FontAwesomeIcon icon={faBars} />,
      DensitySmallIcon: () => <FontAwesomeIcon icon={faBars} />,
      DragHandleIcon: () => <FontAwesomeIcon icon={faGripLines} />,
      FilterListIcon: (props) =>  <i className="fa-light fa-filter fs-2"  {...props}></i>,
      FilterListOffIcon: () => <i className="fa-light fa-filter-circle-xmark fs-2"></i>,
      FullscreenExitIcon: () => <FontAwesomeIcon icon={faCompress} />,
      FullscreenIcon: () => <FontAwesomeIcon icon={faExpand} />,
      SearchOffIcon: () => <FontAwesomeIcon icon={faSearchMinus} />,
      ViewColumnIcon: () => <FontAwesomeIcon icon={faColumns} />,
      MoreVertIcon: () => <FontAwesomeIcon icon={faEllipsisVertical} />,
      MoreHorizIcon: () => <button style={{ border : "1px solid rgb(58 58 59)"}} 
      className="btn btn-outline btn-outline-secondary px-2 py-1">
      <svg style={{color :"#344054"}} width="16" height="16" viewBox="0 0 16 16" fill="#344054" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.28149 4.7998C2.42233 4.7998 1.96315 5.81172 2.52891 
          6.45831L6.94637 11.5068C7.50415 12.1443 8.49581 12.1443 9.05359 11.5068L13.4711 
          6.45831C14.0368 5.81173 13.5776 4.7998 12.7185 4.7998H3.28149Z" fill="#344054" />
      </svg>
    </button>,
      SortIcon: (props) => <FontAwesomeIcon icon={faArrowDownWideShort} {...props} />,
      PushPinIcon: (props) => <FontAwesomeIcon icon={faThumbTack} {...props} />,
      VisibilityOffIcon: () => <FontAwesomeIcon  icon={faEyeSlash} />,
    };  