import { BRANCH } from "../../actions";

const branchNameReducer = (state = null, action) => {
    switch (action.type) {
        case BRANCH.SETBRANCH:
            return action.branch;
        default:
            return state;
    }
}

export default branchNameReducer;