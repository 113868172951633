import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserPermissionAllData, userPermissionType } from '../../../../Comman/UserPermission';

export default function AddNewCommonButton() {
  const history = useHistory();
  const abca = window.location.pathname.split("/");
  const urlPa = abca[4];

  const pathRedirects = {
    "ClientList": {
      path: "/group_admin/settings/tourSteup/ClientAdd",
      name: "New Client"
    },
    "MealList": {
      path: "/group_admin/settings/tourSteup/MealAdd",
      name: "New Meal"
    },
    "CompanyList": {
      path: "/group_admin/settings/tourSteup/CompanyAdd",
      name: "New Company"
    },
    "LocationList": {
      path: "/group_admin/settings/tourSteup/LocationAdd",
      name: "New Location"
    },
    "StatusList": {
      path: "/group_admin/settings/tourSteup/StatusAdd",
      name: "New Status"
    },
    "PaymentStatusList": {
      path: "/group_admin/settings/tourSteup/PaymentStatusAdd",
      name: "New Payment Status"
    },
    "CurrencyList": {
      path: "/group_admin/settings/tourSteup/CurrencyAdd",
      name: "New Currency"
    },
    "VatList": {
      path: "/group_admin/settings/tourSteup/VatAdd",
      name: "New VAT"
    },
  };

  const { path, name } = pathRedirects[urlPa] || { path: "/default-path", name: "New Item" };

  return (
    <div>
       {(userPermissionType === "groupadmin" || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                    permission?.key === "tour_setup" &&
                                                    permission?.access?.includes("create")
                                                )) && (
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => history.push(path)}
      >
        {name}
      </button>)}
    </div>
  );
}
