import { DASHBOARD } from "../action-types";


const getDashboardCount = (Type ,branch_id) => ({
  type: DASHBOARD.GET_DASHBOARD_COUNT,
  Type,
  branch_id
});


const setDashboardCount = (dashboardcount) => ({
  type: DASHBOARD.SET_DASHBOARD_COUNT,
  dashboardcount,
});

const setLoading = (loading) => ({
      type: DASHBOARD.SET_LOADING,
      payload: loading,
});


export {
      getDashboardCount ,setDashboardCount ,setLoading
};
