import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import headers from "../../../../CommonApi/headers";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import Swal from "sweetalert2";
import { getBranchList } from "../../../../Store";
import useDrawer from "../../../../context/DrawerContext";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "../../../Comman/BranchList";
window.jquery = window.$ = $;


 function GroupAdminGenerateFromPDF({getBranchList ,BranchList_}) {
  let history = useHistory();

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  ///////////////////////// Branch List //////////////////////////

  const [branch_id, setbranch_id] = useState([]);

  const filteredOptions = branchfilterAndMapData(BranchList_);
  useEffect(() => {
    getBranchList();
  }, []);

  
  ///////////////////////// Branch List //////////////////////////

  /////////////////////////////  Add New Menu ////////////////////////
  const [menu_name, setmenu_name] = useState("");
  const [PDF_menu ,setPDF_menu] = useState("");
  const [Show, setShow] = useState(false)
  

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);
    $(".loader").show();


     if (branch_id == "") {
      setError("Please select branch");
      $(".loader").hide();
      }
      else if (PDF_menu == "") {
      setError("Please upload PDF.");
      $(".loader").hide();
      }
      else if (menu_name == "") {
      setError("Please filed menu name");
      $(".loader").hide();
      }
      
      else {
      $(".loader").hide();
      setShow(true)

      // const item = new FormData();
      // item.append("branch_id", branch_id);
      // item.append("menu_name", menu_name);
      // item.append("pdf" , PDF_menu[0])

      // // console.log(Object.fromEntries(item.entries()));

      // api
      //   .post(`/groupadmin/menu`, item, { headers })
      //   .then((res) => {
      //     $(".loader").hide();

      //     if (res.data.message == "Sucessfully created.") {
      //       history.goBack();
      //     }
      //   })
      //   .catch((err) => {
      //     if (err.response) {
      //       $(".loader").hide();

      //       setError(err.response?.data?.error?.message);
      //     } else if (err.request) {
      //       setError(err.request);
      //     } else {
      //       setError(err.message);
      //     }
      //   });
    }
  }

  const jsonData = 
      [
            {
                "category": "FRESH JUICES / SODA",
                "name": "Apple",
                "price": "140"
            },
            {
                "category": "FRESH JUICES / SODA",
                "name": "Pineapple",
                "price": "130"
            },
            {
                "category": "FRESH JUICES / SODA",
                "name": "Sweet Lime",
                "price": "130"
            },
            {
                "category": "MOCKTAILS",
                "name": "Kiwi Classic",
                "price": "135"
            },
            {
                "category": "MOCKTAILS",
                "name": "Pink Lady",
                "price": "135"
            },
            {
                "category": "MOCKTAILS",
                "name": "Fruit Punch",
                "price": "150"
            },
            {
                "category": "MILK SHAKES",
                "name": "Vanilla Milkshake",
                "price": "145"
            },
            {
                "category": "MILK SHAKES",
                "name": "Strawberry Milkshake",
                "price": "145"
            },
            {
                "category": "MILK SHAKES",
                "name": "Chocolate Milkshake",
                "price": "145"
            },
            {
                "category": "HOT SOUPS & SHORBA",
                "name": "Cream of Tomato Soup",
                "price": "105"
            },
            {
                "category": "HOT SOUPS & SHORBA",
                "name": "Cheese Corn Tomato Soup",
                "price": "125"
            },
            {
                "category": "HOT SOUPS & SHORBA",
                "name": "Minestrone Soup",
                "price": "125"
            },
            {
                "category": "HOT SOUPS & SHORBA",
                "name": "Lemon Coriander Soup",
                "price": "120"
            },
            {
                "category": "HOT SOUPS & SHORBA",
                "name": "Cream of Spinach Soup",
                "price": "120"
            },
            {
                "category": "SOUP KE SATH",
                "name": "Fry Papad",
                "price": "33"
            },
            {
                "category": "SOUP KE SATH",
                "name": "Roasted Papad",
                "price": "28"
            },
            {
                "category": "SOUP KE SATH",
                "name": "Masala Papad",
                "description": "Roasted / Fry",
                "price": "48"
            },
            {
                "category": "CHATS",
                "name": "Papdi Chat",
                "price": "130"
            },
            {
                "category": "CHATS",
                "name": "Aloo Chat",
                "price": "120"
            },
            {
                "category": "SALAD & RAITA",
                "name": "Waldorf Salad",
                "price": "160"
            },
            {
                "category": "SALAD & RAITA",
                "name": "Russian Salad",
                "price": "160"
            },
            {
                "category": "SALAD & RAITA",
                "name": "Fresh Garden Green Salad",
                "price": "90"
            },
            {
                "category": "INDIAN STARTERS",
                "name": "Special Milestone Plater",
                "price": "310"
            },
            {
                "category": "INDIAN STARTERS",
                "name": "Peshavari Paneer Tikka",
                "price": "210"
            },
            {
                "category": "INDIAN STARTERS",
                "name": "Tandoori Paneer Tikka",
                "price": "210"
            },
            {
                "category": "MEXICAN & THAI STARTER",
                "name": "Baby Corn Cottage with Pesto Sauce",
                "price": "220"
            },
            {
                "category": "MEXICAN & THAI STARTER",
                "name": "Mexican Tikki with Salsa Sauce",
                "price": "200"
            },
            {
                "category": "MEXICAN & THAI STARTER",
                "name": "Veg",
                "price": "200"
            },
            {
                "category": "BAKED DISH",
                "name": "Baked Macaroni Pineapple",
                "price": "230"
            },
            {
                "category": "BAKED DISH",
                "name": "Burmese Spaghetti",
                "price": "230"
            },
            {
                "category": "BAKED DISH",
                "name": "Baked Veg",
                "price": "245 "
            },
            {
                "category": "PIZZA",
                "name": "Italian Pizza",
                "price": "170"
            },
            {
                "category": "PIZZA",
                "name": "Mexican Pizza",
                "price": "175"
            },
            {
                "category": "SIZZLERS",
                "name": "Special Milestone Sizzler",
                "price": "380"
            }
          ]

function confirm(){
      Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Menu has been generated succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
      history.push("/group_admin/menu_management");
}

  /////////////////////////////  Add New Menu ////////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Generate from CSV
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                                    <div className="row">
                              <div className="col-md-6 col-sx-12">
                              <div className="mb-5">
                                <label className="required form-label">
                                  Select Branch
                                </label>
                                <Select
                                  className="search-line"
                                  placeholder="Select branch..."
                                  options={filteredOptions}
                                  onChange={(opt) => setbranch_id(opt.value)}
                                />
                              </div>
                              </div>
                              <div className="col-md-6 col-sx-12">
                              <div className="mb-5">                                
                              <label className="required form-label">
                                  Upload CSV
                                </label>
                                    <input
                                      type="file"
                                      name="avatar"
                                      className="form-control form-control-solid"
                                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      onChange={(e) => setPDF_menu(e.target.files)}
                                    />
                                  
                              <div className="form-text">
                                Allowed file types: CSV
                              </div>
                              </div>
                              </div>
                                    </div>
                              <div className="mb-5">
                                <label className="required form-label">
                                  Menu Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Menu Name"
                                  value={menu_name}
                                  onChange={(e) => setmenu_name(e.target.value)}
                                />
                              </div>
                              <div className="col-md-12 text-center">
                                    <br />
                                    <div
                                      className="loader"
                                      style={{ display: "none" }}
                                    >
                                      <img src="/assets/loader.gif" />
                                    </div>
                                  </div>

                              <div className="col-md-12"> {errorDiv} </div>

                              <div className="modal-footer flex-center">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">Save</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger m-2"
                                  onClick={() => history.goBack()}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>


                                    
                          </div>

                              {Show  ? 
                              <React.Fragment>
                          <Row className="mt-5">
                        <Typography >
                              <div className="rounded border p-10" >
                        <div style={{ overflowX: 'auto' }}>
                        <table className="table table-striped table-row-bordered gy-5 gs-7">
                        <thead className="text-start">
                              <tr>
                              <th>Category</th>
                              <th>Name</th>
                              <th>Amount (CHF)</th>
                              </tr>
                        </thead>
                        <tbody className="text-start">
                              {jsonData.map((item, index) => (
                              <tr key={index}>
                              <td>{item.category}</td>
                              <td>{item.name}</td>
                              <td>{item.price}</td>
                              </tr>
                              ))}
                        </tbody>
                          </table>
                               </div>
                        </div>
                                    </Typography>
                        </Row>
                        <Row className="text-center mt-4">
                              <Col>
                              <button
                              type="button"
                              onClick={confirm}
                            className="btn btn-primary"
                          >
                            Confirm
                          </button>
                              </Col>
                        </Row> 
                        </React.Fragment>: "" }
                        
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminGenerateFromPDF);
