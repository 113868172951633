import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Link } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import headers from "../../../../../CommonApi/headers";
import $ from "jquery";
import useDrawer from "../../../../../context/DrawerContext";
import { BranchesPermissions, MenuPermissions, userPermissionType } from "../../../../Comman/UserPermission";
window.jquery = window.$ = $;

export default function GroupAdminMenuInformation() {
  const location = useLocation();
  let history = useHistory();

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[3];

  //// ///////////////////// Menu List by Id /////////////////////////

  const [menu_name, setmenu_name] = useState("");
  const [picture, setPicture] = useState(null);
  
  const [image ,setimage] = useState("");
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPicture(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );



  useEffect(() => {
    groupAdminMenuListById();
    // console.log(location.state.menuId);
    // console.log(location.state.branchID);
  }, [location]);

  function groupAdminMenuListById() {
    api
      .get(`/groupadmin/menu/${location.state.menuId}`, { headers })
      .then((res) => {
        const tableData = res?.data?.data[0];
        setmenu_name(tableData?.menu_name);
        setPicture(tableData?.image)
        // console.log(res.data.data)
      });
  }

  //// ///////////////////// Menu List by Id /////////////////////////

  /////////////////// Menu Name Edit ///////////////////////////

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    $(".loader").show();

    const item = new FormData();

      item.append("menu_name", menu_name);
      if (image) {
        // Check if the uploaded file is an image and has a valid extension
        const allowedExtensions = ["image/png", "image/jpeg", "image/jpg"];
        if (allowedExtensions.includes(image.type)) {
          item.append("image", image);
        } else {
          setError("Invalid image format. Please upload a PNG, JPEG, or JPG file.");
          $(".loader").hide();
          return; // Stop further processing
        }
      }

      // console.log(Object.fromEntries(item.entries()));

    api
      .patch(`/groupadmin/menu/${location.state.menuId}`, item, { headers })
      .then((res) => {
        $(".loader").hide();

        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Menu details has been edited succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          setError(err.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }


  function EdithandleSubmitImage(event) {
    event.preventDefault();

    setPicture("");
    const formData = new FormData();
    formData.append("image", "");

    api
      .patch(`/groupadmin/menu/removeImage/${location.state.menuId}`, formData, {
        headers,
      })
      .then((res) => {
        //  window.location.reload(true);
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }

  /////////////////// Menu Name Edit ///////////////////////////

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Menu Management
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        Menu - Menu Information
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() =>
                            history.push("/group_admin/menu_management")
                          }
                          className="btn btn-primary"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                    <div className="rounded border p-6">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_information"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_information",
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Information
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_category"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_category",
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Categories
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="rounded border p-10">
                                  <div className="mb-10">
                                    <form onSubmit={handleSubmit}>
                                      <label className="required form-label">
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-solid"
                                        placeholder="Name"
                                        // value={user[0] ? user[0].menu_name : ""}
                                        value={menu_name}
                                        onChange={(e) =>
                                          setmenu_name(e.target.value)
                                        }
                                      />
                                       <div className="mb-10 mt-5">
                            <label className="col-lg-4 mb-0 col-form-label fw-bold fs-6">
                              Banner Image
                            </label>
                              <div className="text-center user-info mb-4 image-input-wrapper w-125px h-125px">
                                <div
                                  className="image-input image-input-outline"
                                  data-kt-image-input="true"
                                >
                                  <img
                                    className="image-input-wrapper w-125px h-125px"
                                    src={
                                      picture
                                        ? picture
                                        : "/assets/media/svg/3377061_food_hor_mok_thai_icon.png"
                                    }
                                    alt="profile_img"
                                    style={{height : 125 ,width : 125 ,objectFit : 'cover'}}
                                  ></img>
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    // title="Change avatar"
                                  >
                                    <i className="bi bi-pencil-fill fs-7" />
                                    <input
                                      type="file"
                                      name="avatar"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={onChangePicture}
                                    />
                                  </label>
                                  {picture ? (
                                    <React.Fragment>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                        data-kt-image-input-action="cancel"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                      >
                                        <i
                                          className="bi bi-x fs-2"
                                          onClick={EdithandleSubmitImage}
                                        />
                                      </span>
                                      <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                        data-kt-image-input-action="remove"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                        s
                                        title="Remove"
                                      >
                                        <i
                                          onClick={EdithandleSubmitImage}
                                          className="bi bi-x fs-2"
                                        />
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                              </div>
                                      <br />

                                      <div className="col-md-12 text-center">
                          <br />
                          <div className="loader" style={{ display: "none" }}>
                            <img src="/assets/loader.gif" />
                          </div>
                        </div>
                        <div className="col-md-12"> {errorDiv} </div>


                                      <div className="modal-footer flex-center">

                                      {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("edit")) && (
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Save
                                      </button>
                                    )}
                                        <button
                                          type="button"
                                          className="btn btn-danger m-2"
                                          onClick={() => history.goBack()}
                                        >
                                          {" "}
                                          Cancel{" "}
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
