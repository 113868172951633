import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { INVENTORY, setInventoryCatList, setInventoryProList } from "../../actions";

function* callInventoryCategoryList() { 
  try {
    const response = yield call(api.get, "/groupadmin/inventory/getCategory", { headers });
    const tableData = response?.data?.data;
    yield put(setInventoryCatList(tableData?.length > 0 ? tableData : []));
  } catch (error) {
    if (error.response) {
      yield put({ type: INVENTORY.CLEAR_INVENTORY_CATEGORY });
    }
  }
}

function* callInventoryProductList() { 
  try {
    const response = yield call(api.get, "/groupadmin/inventory/getProducts", { headers });
    const tableData = response?.data?.data;
    yield put(setInventoryProList(tableData?.length > 0 ? tableData : []));
  } catch (error) {
    if (error.response) {
      yield put({ type: INVENTORY.CLEAR_INVENTORY_PRODUCT });
    }
  }
}

export function* watchInventoryCategoryList() {
  yield takeEvery(INVENTORY.GET_INVENTORY_CATEGORY, callInventoryCategoryList);
}

export function* watchInventoryProductList() {
  yield takeEvery(INVENTORY.GET_INVENTORY_PRODUCT, callInventoryProductList);
}
