import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Box, Typography } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import useDrawer from "../../../../../context/DrawerContext";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { getSelectOptions } from "../../../../Comman/BranchList";


export default function GroupAdminBills() {
  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [user, setuser] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState([]);
  const [Order_type, setOrder_type] = useState("");
  const [Order_type2, setOrder_type2] = useState([]);

  useEffect(() => {
      groupAdminBranchList();
      validDate();
    }, []);
  
    function validDate() {
      var today = new Date().toISOString().split("T")[0];
      document.getElementsByName("date")[0].setAttribute("max", today);
      document.getElementsByName("date1")[0].setAttribute("max", today);
    }
  
    function groupAdminBranchList() {
      api
        .get(`/groupadmin/branch/restaurantwise`, { headers })
        .then((res) => {
          const options = res.data.data.map((item) => ({
            label: item.branch_name_EN,
            value: item._id,
          }));
          setuser(options);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err?.response?.data);
          }
        });
    }

    const { drawerPos, handleDrawer , mainClassCustome } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7 p-0">
                <main style={{position : "initial"}} className={mainClassCustome.join(" ")}>
                  <div className="d-flex flex-stack mb-2 p-0">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h2
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                       Billing Reports
                      </h2>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.push("/group_admin/report")}
                          className="btn btn-primary ps-7"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-2">
                  <Card style={{border : "1px solid #bdbcbc"}}>
                  <Card.Body>
                  <Row >
                  <Col lg={6} md={6} sx={6}> 
                  <label className="required form-label">
                                  Select Branch
                                </label>
                                <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  // options={[
                                  //   {
                                  //     label: "All",
                                  //     value: "all",
                                  //   },
                                  //   ...user,
                                  // ]}
                                  options={getSelectOptions(user)}
                                  value={branch_id2}
                                  onChange={(opt) => {
                                    setbranch_id(opt.value);
                                    setbranch_id2(opt);
                                  }}
                                />
                  </Col>

                              <Col lg={6} md={6} sx={6}> 
                              <label className="required form-label">
                              Select 
                                </label>
                              <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  options={[
                                    {
                                      label: "Screen As per light speed shared module",
                                      value: "Screen As per light speed shared module",
                                    },
                                    
                                         
                                  ]}
                              //     value={Order type2}
                                  onChange={(opt) => {
                                    // setOrder_type(opt.value);
                                    // setOrder_type2(opt);
                                  }}
                              /></Col>
                  </Row>
                  <div className="row mt-7" >
                              <div className={isTabletOrMobile ? "col-md-4" :"col-md-2" } >
                                <label className="required form-label">
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  name="date"
                                  id="date"
                                  className="form-control"
                                  value={start_date}
                                  onChange={(e) => {
                                    setstart_date(e.target.value);
                                  }}
                                />
                              </div>
                              <div className={isTabletOrMobile ? "col-md-4" :"col-md-2" }>
                                <label className="form-label">End Date</label>
                                <input
                                  type="date"
                                  name="date1"
                                  id="date1"
                                  className="form-control"
                                  value={end_date}
                                  onChange={(e) => {
                                    setend_date(e.target.value);
                                  }}
                                />
                              </div>
                              <div className={isTabletOrMobile ? "col-md-4 mt-8 text-end" : "col-md-3 mt-8" } >
                             
                                  <button
                                                      // onClick={handalsubmit}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-eye fs-2"></i>&nbsp;  
                                                                        Preview</span>
                                                        </button>
                                      
                              
                                                        </div>
                                                    <div className={isTabletOrMobile ? "col-md-12 mt-8 text-end" : "col-md-5 mt-9 text-end" }>
                                                     
                                                      <React.Fragment>
                                                       
                                                      <button
                                                      // onClick={handleConvertToPdf}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-file-pdf-o fs-3"></i>&nbsp;Download PDF</span>
                                                        </button> 
                                                        &nbsp;&nbsp;
                                                        <button
                                                        // onClick={()=>downloadCSV(bestSellingProduct ,`${"Best Selling Products"} ${moment(new Date()).format(
                                                        //   "DD-MM-YYYY"
                                                        // )}.csv` )}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        
                                                        <i className="fa fa-file-excel-o fs-3"></i>&nbsp;Download CSV</span>
                                                        </button>
                                                        </React.Fragment>
                                                      </div>
                                                </div> 
                                    </Card.Body>
                                    </Card>
                  
                                    
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}
