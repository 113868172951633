import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Link } from "react-router-dom";
import headers from "../../../../../CommonApi/headers";
import api from "../../../../../CommonApi/axios";
import useDrawer from "../../../../../context/DrawerContext";
import { MenuPermissions, userPermissionType } from "../../../../Comman/UserPermission";

export default function GroupAdminMenuCategory() {
  let history = useHistory();
  const location = useLocation();

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[3];
  const [error, setError] = useState(null);

  //// ///////////////////// Catagori List /////////////////////////

  const [user, setuser] = useState([]);

  useEffect(() => {
    groupAdminCategoryListById();
  }, [location]);

  function groupAdminCategoryListById() {
    let data = JSON.stringify({ menu_id: location.state.menuId });

    api
      .post(`/groupadmin/category/${location.state.branchID}`, data, {
        headers: headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setuser(tableData);
      })
      .catch((err) => {
        setError("No Category Found.");
        // if (err.response) {
        //     // if (err.response.data.error.message) {
        //     //     console.log(err.response.data.error.message);
        //     // }
        // }
      });
  }

  //// ///////////////////// Catagori List /////////////////////////

  //////////////////////////// Catagori Edit ///////////////////////

  function EditCatagory(_id) {
    history.push(
      `/group_admin/menu_management/category/edit_menu_category/${_id}/${location.state.menuId}/${location.state.branchID}`,
    );
  }

  //////////////////////////// Catagori Edit ///////////////////////

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Menu Management
                    </h1>
                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h2
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Category
                        </h2>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >

                          {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) && (
                            <Link
                              to={{
                                pathname:
                                  "/group_admin/menu_management/category/add_new_menu_category",
                                state: {
                                  menuId: location.state.menuId,
                                  branchID: location.state.branchID,
                                },
                              }}
                              className="btn btn-primary ps-7"
                            >
                              Add Category
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <form>
                              <div className="my-10">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="required form-label"
                                >
                                  Copy Menu
                                </label>
                                <select
                                  className="form-select form-select-solid"
                                  aria-label="Select example"
                                >
                                  <option>Open this select menu</option>
                                  <option value={1}>One</option>
                                  <option value={2}>Two</option>
                                  <option value={3}>Three</option>
                                </select>
                              </div>
                              <div className="col-md-12"> </div>
                              <div className="modal-footer flex-center">
                                <button
                                  type="submit"
                                  id="kt_modal_new_address_submit"
                                  className="btn btn-primary"
                                >
                                  <span className="indicator-label">
                                    Copy Menu
                                  </span>
                                </button>
                                <button className="btn btn-danger m-2">
                                  {" "}
                                  Cancel{" "}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rounded border p-5">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_information"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_information",
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Information
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              url === "menu_category"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={{
                              pathname:
                                "/group_admin/menu_management/menu_category",
                              state: {
                                menuId: location.state.menuId,
                                branchID: location.state.branchID,
                              },
                            }}
                          >
                            Categories
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          {user != "" ? (
                            user.map((main) => (
                              <div className="py-5" style={{ clear: "both" }}>
                                <Link

                                  to={{
                                    pathname: `/group_admin/menu_management/category/edit_menu_category/${main._id}/${location.state.menuId}/${location.state.branchID}`,

                                  }}
                                >
                                  <div className="card shadow-sm">
                                    <div className="card-body">
                                      <div className="col-md-12">
                                        <b
                                          style={{
                                            color: "#000000",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {main.name_EN}
                                        </b>


                                        {main.is_active == true ? (
                                          <button
                                            type="button"
                                            className="btn btn-success"
                                            style={{ float: "right" }}
                                            onClick={() =>
                                              EditCatagory(main._id)
                                            }
                                          >
                                            Active
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-Inactive"
                                            style={{ float: "right" }}
                                            onClick={() =>
                                              EditCatagory(main._id)
                                            }
                                          >
                                            Inactive
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))
                          ) : (
                            <div className="card shadow-sm">
                              <div
                                className="card-body"
                                style={{ textAlign: "center" }}
                              >
                                <div className="col-md-12">
                                  <>
                                    <b
                                      style={{
                                        color: "#000000",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {error}{" "}
                                    </b>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
