import React, { useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import api from "../../../../../CommonApi/axios";
import { useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Swal from "sweetalert2";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
window.jquery = window.$ = $;

export default function PosG_SeriesAuth() {
  /////////////////////////////// Pos Provider Add /////////////////////////
  let history = useHistory();
  const location = useLocation();

  const userPermissionType_ = userPermissionType === "groupadmin"

  // "goldenindia-6bae-45a1-a058-f371cbbec87c";
  const [business_unit_id, setbusiness_unit_id] = useState("");

  // "d9eac058-bc9d-4dd6-8709-090a6b3ffa8e";
  const [token, settoken] = useState("");
  const [waiter_id, setwaiter_id] = useState("");
  const [tender_id, settender_id] = useState("");
  const [pickup_table_no, setpickup_table_no] = useState("");
  const [delivery_table_no, setdelivery_table_no] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  function handleSubmit(event) {
    event.preventDefault();
    $(".loader").show();
    setError(null);

    let item1 = {
      branch_id: location.state.branchID,
      pos_id: location.state.POSID,
      token: token,
      business_unit_id: business_unit_id,
      waiter_id: waiter_id,
      tender_id: tender_id,
      delivery_table_no: delivery_table_no,
      pickup_table_no: pickup_table_no,
    };

    // return;
    api
      .post(`/groupadmin/settings/POS/Gseries/add_pos_data`, item1, {
        headers,
      })
      .then((res) =>
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Your work has been save successfully.",
          showConfirmButton: false,
          timer: 1500,
        })
      )
      .catch((err) => {
        if (err.response) {
          setError(err?.response?.data?.error?.message);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }
  /////////////////////////// pos provaider /////////////////////////

  /////////////////// Get Details Fro Secont From ////////////////////////
  const [posDataById, setposDataById] = useState(null);
  const [pos_status_active, setpos_status_active] = useState(false);
  const [AuthButton, setAuthButton] = useState(true);

  useEffect(() => {
    POSListbyiD();
  }, [location?.state?.branchID]);

  function POSListbyiD() {
    api
      .get(`/groupadmin/branch/posType/${location.state.branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data?.data?.pos_fields[0];
        console.log("tab", res.data.data);
        setAuthButton(res?.data?.data?.pos_name === "K Series" ? true : false);
        setpos_status_active(
          res?.data?.data?.pos_status_active
            ? res?.data?.data?.pos_status_active
            : false
        );
        setposDataById(tableData);
        setbusiness_unit_id(
          tableData?.business_unit_id ? tableData?.business_unit_id : ""
        );
        settoken(tableData?.token ? tableData?.token : "");
        settender_id(tableData?.tender_id ? tableData?.tender_id : "");
        setwaiter_id(tableData?.waiter_id ? tableData?.waiter_id : "");
        setdelivery_table_no(
          tableData?.delivery_table_no ? tableData?.delivery_table_no : ""
        );
        setpickup_table_no(
          tableData?.pickup_table_no ? tableData?.pickup_table_no : ""
        );
      })
      .catch((err) => {
        setposDataById("");
        setAuthButton(true);
        if (err.response) {
          toast(err?.response?.data?.error?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  /////////////////// Get Details Fro Secont From ////////////////////////

  function handleSubmitEdit(event, value) {
    event.preventDefault();

    let item1 = {
      is_active: value,
    };

    //  console.log("item", item1);

    api
      .patch(
        `/groupadmin/settings/POS/updatePOSStatus/${location.state.branchID}`,
        item1,
        {
          headers,
        }
      )
      .then((res) => {
        POSListbyiD();
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: `Pos has been ${value ? "Active" : "Inactive"} succesfully.`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <h1
                    className="anchor fw-bolder mb-5"
                    id="vertical-horizontal-scroll"
                  >
                    Settings
                  </h1>
                  <hr />
                  <div className="d-flex flex-stack mb-5">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h3
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                        POS G - Series / <span>Pos Auth</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() =>
                            history.push("/group_admin/settings/pos")
                          }
                          className="btn btn-primary"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                    <div className="row rounded border p-5">
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        ></div>
                        <div className="d-flex flex-stack">
                          <div
                            className="d-flex justify-content-end "
                            data-kt-docs-table-toolbar="base"
                          >
                            {pos_status_active ? (
                              <button
                                onClick={(e) => handleSubmitEdit(e, false)}
                                className="btn btn-success"
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                onClick={(e) => handleSubmitEdit(e, true)}
                                className="btn btn-danger"
                              >
                                Inactive
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <br />
                        <div className="row col-sm-12">
                          <form onSubmit={handleSubmit}>  
                            <div
                              className="card card-docs mb-2"
                              style={{
                                boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                              }}
                            >
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <div className="row col-md-12">
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Business Unit Id
                                      </label>
                                      <input
                                        type="text"
                                        value={business_unit_id}
                                        required
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          setbusiness_unit_id(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter Business Unit Id"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Token
                                      </label>
                                      <input
                                        type="text"
                                        value={token}
                                        required
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          settoken(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter Token"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row col-md-12">
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Waiter Id
                                      </label>
                                      <input
                                        type="text"
                                        value={waiter_id}
                                        required
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          setwaiter_id(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter Waiter Id"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Tender Id
                                      </label>
                                      <input
                                        type="text"
                                        value={tender_id}
                                        required
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          settender_id(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter Tender Id"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row col-md-12">
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Delivery table no
                                      </label>
                                      <input
                                        type="text"
                                        value={delivery_table_no}
                                        required
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          setdelivery_table_no(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter delivery table no"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Pickup table no
                                      </label>
                                      <input
                                        type="text"
                                        value={pickup_table_no}
                                        required
                                        // readOnly={AuthButton ? false : true}
                                        onChange={(e) =>
                                          setpickup_table_no(e.target.value)
                                        }
                                        className="form-control form-control-solid"
                                        placeholder="Enter pickup table no"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12"> {errorDiv} </div>
                                {/* {AuthButton ? ( */}
                               
                                <div className="d-flex justify-content-center">
                                {(userPermissionType_ ||
                                    UserPermissionAllData?.role_details?.settings?.find(permission =>
                                      permission?.key === "pos" &&
                                      (permission?.access?.includes("create") || permission?.access?.includes("edit"))
                                    )) && (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>)}
                                </div>
                                {/* ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
